import React, { memo, useState, useEffect } from 'react';
import { parseMoney } from '../utils';
import LoaderGraphic from '../utils/LoaderGraphic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const MappingTable = ({ fdu_id, fiscalYearEnd, years, map_list, assets_list, liabilities_list, equity_list, revenues_list, expenses_list, updateRows, dragListener }) => {
  const [lastFdr, setLastFdr] = useState(1);
  const [fdrs, setFdrs] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [firstFetch, setFirstFetch] = useState(true);
  const [tableSort, setTableSort] = useState({
    account_number: '',
    account_type: '',
    extracted_account: '',
    historic_matched: '',
    xlsx_matched: '',
    n_bayes_matched: '',
    final_account: '',
  })
  const [tableSortYears, setTableSortYears] = useState({
    year_2010: '',
    year_2011: '',
    year_2012: '',
    year_2013: '',
    year_2014: '',
    year_2015: '',
    year_2016: '',
    year_2017: '',
    year_2018: '',
    year_2019: '',
    year_2020: '',
    year_2021: '',
    year_2022: '',
    year_2023: '',
    year_2024: '',
    year_2025: '',
  })
  const [bestNbayesMatches, setBestNbayesMatches] = useState({
    rowId: null,
    display: false,
    data: {}
  })

  const sortCols = {
    'account_number': 'Account',
    'account_type': 'Given Type',
    'extracted_account': 'Given',
    'historic_matched': 'Historical',
    'xlsx_matched': 'Best Match',
    'n_bayes_matched': 'Alternatives',
    'final_account': 'Final'
  }

  const handleRowSort = async (type) => {
    let lastRow = lastFdr ? lastFdr : ''
    let direction = ''
    if (type.includes('year')) {
      direction = tableSortYears[type]
      if (direction === 'desc') {
        direction = ''
      } else if (direction === 'asc') {
        direction = 'desc'
      } else if (direction === '') {
        direction = 'asc'
      }
    } else {
      direction = tableSort[type]
      if (direction === 'asc') {
        direction = ''
      } else if (direction === 'desc') {
        direction = 'asc'
      } else if (direction === '') {
        direction = 'desc'
      }
    }

    const response = await fetch(`/mapping_approvals/rows/?id=${fdu_id}&last_fdr=${lastRow}&sort=${type}&direction=${direction}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });
    const { last_fdr, fdrs } = await response.json();

    if (type.includes('year')) {
      setTableSortYears(prevState => {
        let newSortData = { ...prevState }
        Object.keys(tableSortYears).forEach(key => {
          if (key === type) {
            newSortData[type] = direction
          } else {
            newSortData[key] = ''
          }
        })
        return newSortData
      })
    } else {
      setTableSort(prevState => {
        let newSortData = { ...prevState }
        Object.keys(tableSort).forEach(key => {
          if (key === type) {
            newSortData[type] = direction
          } else {
            newSortData[key] = ''
          }
        })
        return newSortData
      })
    }
    setIsFetching(false);
    setLastFdr(last_fdr);
    setFdrs(fdrs);
  }

  async function fetchRows(lastFdr) {
    const response = await fetch(`/mapping_approvals/rows/${fdu_id}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        last_fdr: lastFdr
      })
    });
    const { last_fdr, fdrs } = await response.json();
    setIsFetching(false);
    setLastFdr(last_fdr);
    setFdrs(prevFdrs => prevFdrs.concat(fdrs));
  };

  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight - 600)) {
      setIsFetching(true);
    };
  };

  useEffect(() => {
    if (lastFdr) window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastFdr]);

  useEffect(() => {
    async function handleFetch() {
      if (isFetching) {
        await fetchRows(lastFdr);
      };
    };
    handleFetch();
  }, [isFetching, lastFdr, firstFetch]);

  const accountTypeColors = {
    'Asset': 'asset-cell',
    'A': 'asset-cell',
    'Liability': 'liability-cell',
    'L': 'liability-cell',
    'Equity': 'equity-cell',
    'Q': 'equity-cell',
    'Revenue': 'revenue-cell',
    'R': 'revenue-cell',
    'Expense': 'expense-cell',
    'E': 'expense-cell',
    'Balance Sheet': 'balance-sheet-cell',
    'Income Statement': 'pnl-cell',
    'P and L': 'pnl-cell',
    'Profit and Loss': 'pnl-cell'
  }

  const mapListAccountTypeColor = (accountName, selected = false) => {
    let backgroundColor = ''
    if (accountName && assets_list.includes(accountName)) {
      backgroundColor = accountTypeColors['Asset']
    } else if (accountName && liabilities_list.includes(accountName)) {
      backgroundColor = accountTypeColors['Liability']
    } else if (accountName && equity_list.includes(accountName)) {
      backgroundColor = accountTypeColors['Equity']
    } else if (accountName && revenues_list.includes(accountName)) {
      backgroundColor = accountTypeColors['Revenue']
    } else if (accountName && expenses_list.includes(accountName)) {
      backgroundColor = accountTypeColors['Expense']
    }
    // '-selected' CSS class are darker colors
    return selected ? backgroundColor + '-selected' : backgroundColor
  }

  const MapList = ({ id, selected, map_list, updateFdr }) => (
    <select fdr-id={id} value={selected} className='map-selector' id='map-selector' data-hover='true' onChange={({ target }) => updateFdr(target.value)} >
      {map_list.map((p, i) => (
        <optgroup label={p[0]} key={i}>
          {p[1].map((c, ii) => (
            <option key={ii}>
              {c}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  )

  const handleNewNbayesValue = (rowId, value) => {
    setFdrs((prevState) => {
      let newFdrs = _.cloneDeep(prevState)
      newFdrs.map(f => {
        if (f.id === rowId) {
          f.n_bayes_matched = value
        }
        return f
      })
      return newFdrs
    })
  }

  const Row = ({ id, account_type, account_number, extracted_account, historic_matched, xlsx_matched, n_bayes_matched, final_account, edited_by, ...row }) => {
    const [selected, setSelected] = useState(selections[id] || final_account || xlsx_matched || n_bayes_matched);
    let accountTypeClassNames = account_type ? `text-center ${accountTypeColors[account_type]}-selected` : null
    let finalAccountClassNames = `square-drag ${mapListAccountTypeColor(selected, true)}`

    const updateFdr = (value, n_bayes_match = false, rowId = null) => {
      window.selections[id] = value;
      setSelected(value);
      updateRows([id], value, false, n_bayes_match);
      if (n_bayes_match) { handleNewNbayesValue(rowId, value) }
    };

    const MatchOverride = ({ rowId = null, matchType, value, bestMatches = null }) => {
      // If values from other columns match the final account then make them a darker background color.
      let overRideClassNames = value === selected ? `match-override ${mapListAccountTypeColor(value, true)}` : `match-override ${mapListAccountTypeColor(value)}`


      const handleNbayesMatchDisplay = (rowId, bestMatches) => {
        setBestNbayesMatches(prevState => {
          let newDisplay = null

          if (prevState.rowId === null || prevState.rowId !== rowId) {
            newDisplay = true
          } else if (prevState.rowId === rowId) {
            newDisplay = !prevState.display
          }

          return {
            ...prevState,
            display: newDisplay,
            rowId: rowId,
            bestMatches: bestMatches
          }
        })
      }

      if (matchType !== 'n_bayes' || Object.keys(bestMatches).length === 0) {
        return (
          <td
            id={rowId}
            key={rowId}
            className={overRideClassNames}
            onClick={() => updateFdr(value)}
          >
            {value}
          </td>
        );
      } else {
        let fontAwesomeStyle = rowId === bestNbayesMatches.rowId && bestNbayesMatches.display ? { 'outline': '1px solid black', 'borderRadius': '3px', 'padding': '1px' } : {}
        let selectedBestMatch = Object.values(bestMatches).find((a) => a['account'] === value)
        return (
          <td
            id={rowId}
            key={rowId}
            className={overRideClassNames}
          >
            <div id={`${rowId}-${value}`} key={`${rowId}-${value}`} className='nbayes-match-cell'>
              <span onClick={() => updateFdr(value)}>{`${value} (${Math.round(selectedBestMatch['percentage'])}%)`}</span>
              <div className='match-list-icon'>
                <FontAwesomeIcon style={fontAwesomeStyle} icon={faListUl} onClick={() => handleNbayesMatchDisplay(rowId, bestMatches)} />
                {
                  rowId === bestNbayesMatches.rowId && bestNbayesMatches.display
                    ? <div className='nbayes-match-options'>
                        {Object.keys(bestMatches).map((match, i) => {
                          return <div id={`${rowId}-${match}-${i}`} key={`${rowId}-${match}-${i}`} onClick={() => updateFdr(bestMatches[match]['account'], true, rowId)}>{`${match}: ${bestMatches[match]['account']} ${Math.round(bestMatches[match]['percentage'])}%`}</div>
                        })}
                      </div>
                    : null
                }
              </div>
            </div>
          </td>
        );
      }
    };

    return (
      <tr className={`${historic_matched ? 'row-with-historic' : null}`}>
        <td>
          <ul className='edited-by-list'>
            {edited_by && edited_by.map(e => {
              return <li key={Math.random(8)}>{`${e.name} ${moment(e.date).calendar()}`}</li>
            })}
          </ul>
        </td>
        <td className='text-center'>{account_number}</td>
        <td className={accountTypeClassNames}>{account_type}</td>
        <td className='given'>{extracted_account}</td>
        <MatchOverride matchType={'historic'} value={historic_matched} />
        <MatchOverride matchType={'simple'} value={xlsx_matched} />
        <MatchOverride rowId={id} matchType={'n_bayes'} value={n_bayes_matched} bestMatches={row.nbayes_top_matches} />
        <td className={finalAccountClassNames} style={{ 'position': 'relative' }} data-hover='true'>
          <MapList id={id} map_list={map_list} selected={selected} updateFdr={updateFdr} />
          <div className='drag-clicker' fdr-id={id} onMouseDown={dragListener} ></div>
        </td>
        {years.map((year, i) =>
          <td key={i}>{parseMoney(row[`year_${year}`])}</td>)}
      </tr>
    );
  };

  return (
    <div>
      <div id='mapping-table-container'>
        <table className='mapping-table table table-striped table-bordered'>
          <thead id='mapper-header'>
            <tr>
              <th><div className='mapper-head-cell'><span>Edited By</span></div></th>
              {Object.keys(sortCols).map(col => {
                let direction = tableSort[col]
                let sortArrows = <div className='sort-arrows'><span>&#9650;</span><span>&#9660;</span></div>
                if (direction === 'asc') {
                  sortArrows = <div className='sort-arrows'><span>&#9650;</span></div>
                } else if (direction === 'desc') {
                  sortArrows = <div className='sort-arrows'><span>&#9660;</span></div>
                }
                return <th key={col} onClick={() => handleRowSort(col)}><div className='mapper-head-cell'><span>{sortCols[col]}</span>{sortArrows}</div></th>
              })}
              {years.map((year, i) => {
                // On the back end, different fiscal year ends other than Dec 31 (Calendar Year End) are already sorted to the previous year.
                // However, the year still needs to be the same as seen the file uploads. Ex. Sep 30, 2021 needs to be compared to Dec 31, 2020.
                let displayYear = fiscalYearEnd === '12/31' ? year : Number(year) + 1
                let direction = tableSortYears[`year_${year}`]
                let sortArrows = <div className='sort-arrows'><span>&#9650;</span><span>&#9660;</span></div>
                if (direction === 'desc') {
                  sortArrows = <div className='sort-arrows'><span>&#9650;</span></div>
                } else if (direction === 'asc') {
                  sortArrows = <div className='sort-arrows'><span>&#9660;</span></div>
                }
                return <th key={`year_${year}`} onClick={() => handleRowSort(`year_${year}`)}><div className='mapper-head-cell'><span>{`${fiscalYearEnd}/${displayYear}`}</span>{sortArrows}</div></th>
              })}
            </tr>
          </thead>
          <tbody>
            {fdrs.map(row => <Row {...row} key={row.id} />)}
          </tbody>
        </table>
      </div>
      {isFetching ? <LoaderGraphic /> : null}
    </div>
  );
};

export default memo(MappingTable, (_, { update }) => {
  return !update;
});
