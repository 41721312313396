import React, { useState, useEffect, useRef } from 'react';
import { Data, Filters } from '../contexts';
import { useTour } from '@reactour/tour';
import MonthlyReport from './MonthlyReport';
import { SurveyCharts, FutureSurvey } from '../SurveyHelper';
import LoaderGraphic from '../../utils/LoaderGraphic';
import EnterDataBtn from '../utils/EnterDataBtn';
import SurveyModal from '../SurveyModal';
import ExportDropdown from '../utils/ExportDropdown';
import ForecastPDFandPrint from '../../ForecastPDFandPrint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import ahoy from 'ahoy.js';

const MonthlyReportContainer = (props) => {
  const componentRefs = useRef();
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();
  const sliderIcon = <FontAwesomeIcon icon={faSlidersH} />
  // set default state
  const [state, setState] = useState({
    clientName: props.client_name || '',
    calendarYearEnd: props.calendarYearEnd,
    fiscalYearStartMonth: props.fiscalYearStartMonth || 1,
    real_name: props.real_name || '',
    report: props.page || 'money',
    pageTitle: 'Future',
    calcs: props.calcs,
    previousYearCalcs: [],
    fetch: !props.calcs,
    loading: false,
    filters: props.filters,
    filterOptions: props.filterOptions,
    fiscalYearEnd: props.fiscal_year_end,
    tree: props.tree,
    n: props.n,
    forecast_scenarios: [],
    insufficient_data: false,
    reportSpecific: props.reportSpecific || {},
    companyReportSurvey: {},
    surveyTaken: false,
    surveyResults: {}
  })
  // Control forecast modal animation from going off everytime
  const [forecastInputsBtnClicked, setForecastInputsBtnClicked] = useState(true);
  const [forecastInputsOpen, setForecastInputsOpen] = useState(true)
  const [displayPages, setDisplayOptions] = useState({
    cover_page: {
      checked: true,
      label: 'Cover Page',
      all_pages: true
    },
    revenue_model: {
      checked: true,
      label: 'Revenue Model',
      all_pages: false,
      pages: ['forecast']
    },
    pnl: {
      checked: true,
      label: 'Profit & Loss',
      all_pages: false,
      pages: ['money', 'forecast']
    },
    balance_sheet: {
      checked: true,
      label: 'Balance Sheet',
      all_pages: false,
      pages: ['money', 'forecast']
    },
    cash_flow: {
      checked: true,
      label: 'Cash Flow',
      all_pages: false,
      pages: ['money', 'forecast']
    },
    kfis: {
      checked: true,
      label: 'Key Financial Indicators',
      all_pages: false,
      pages: ['money', 'forecast']
    }
  })
  const [scenarioName, setScenarioName] = useState('Straightline')
  const [surveyModalIsOpen, setSurveyModalOpen] = useState(false)
  const [surveyData, setSurveyData] = useState({
    chartColors: [],
    sections: [],
    surveyLayoutData: {},
    surveyCharts: [],
  })

  const turnOffLiveData = () => {
    setState((prevState) => {
      let newReportSpecifc = { ...state.reportSpecific, isLiveData: false }
      return {
        ...prevState,
        reportSpecific: newReportSpecifc
      }
    })
  }

  // filter change callback
  const handleFilterChange = (filters) => {
    updateState({ filters })
  };

  const handleSurveyModal = (closeOpenSurvey) => {
    setSurveyModalOpen(closeOpenSurvey);
  };

  const updateScenario = (scenario) => {
    setScenarioName(scenario)
    updateState({ forecast_scenarios: [scenario], scenario_name: scenario })
  }

  const handlePDFandPrintChecked = (key) => {
    setDisplayOptions((prevState) => {
      let newOptions = _.cloneDeep(prevState)
      newOptions[key]['checked'] = !newOptions[key]['checked']
      return newOptions
    })
  }

  async function fetchLiveData() {
    const json = await fetch('/api/v1/monthly_calculations', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.token,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        live_data: true,
        report: 'forecast',
        scenario_name: scenarioName
      })
    })
    const res = await json.json()
    const { reportSpecific } = res
    setState(prevState => {
      return {
        ...prevState,
        reportSpecific,
        loading: false,
      }
    })
  }

  async function fetchCalcs(args, { filters, report, forecast_scenarios, scenario_name, ...prevState }) {
    const previousReport = report;
    let surveyCharts = [];
    const json = await fetch('/api/v1/monthly_calculations', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.token,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        company_id: props.id,
        previous_report: previousReport,
        filters,
        report,
        forecast_scenarios,
        scenario_name,
        previousYearCalcs: [],
        ...args // over write current state with new args
      })
    })
    const res = await json.json()
    const { reportSpecific, ...newState } = res

    setState(prevState => {
      return {
        ...prevState,
        ...newState,
        reportSpecific,
        loading: false,
        calcs: res.calcs,
        previousYearCalcs: reportSpecific.previousYearCompanyCalc,
        previousReport: previousReport,
        companyReportSurvey: res.companyReportSurvey,
        surveyTaken: res.surveyTaken,
        surveyResults: res.surveyResults
      }
    })
    let chartData = []
    let survey = FutureSurvey(res.companyReportSurvey);
    let sectionDatas = Object.values(res.companyReportSurvey['survey_format_with_answers'])
    sectionDatas.forEach(data => {
      if (data.hasOwnProperty('sectionData')) chartData = [...chartData, ...data['sectionData']]
    })
    surveyCharts = chartData

    setSurveyData(() => {
      return {
        sections: survey.sections,
        surveyLayoutData: survey.surveyLayoutData,
        surveyCharts: surveyCharts,
      }
    })
  }

  const handleSurveySubmit = async (e, surveyState) => {
    e.preventDefault();
    let answers = {}
    let numberYearAnswers = {}
    surveyData.sections.forEach((section, i) => {
      surveyState.surveyLayoutData[section]['sectionData'].forEach((data => {
        if (data.inputType === 'radio' || data.inputType === 'ranking') {
          data.questions.forEach(q => {
            answers[q.key] = q.answer
          })
        } else {
          answers[data.key] = data.answer
          numberYearAnswers[data.key] = data.answer
        }
      }))
    })

    try {
      const res = await fetch(`/api/v1/surveys/${state.companyReportSurvey.survey.id}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: answers,
          report: state.report,
          year: state.companyReportSurvey.survey.year,
          filters: state.filters
        }),
      })

      const surveyRes = await res.json();

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        setState(prevState => {
          return {
            ...prevState,
            companyReportSurvey: {
              survey: surveyRes.companyReportSurvey,
              number_year_inputs: numberYearAnswers
            },
            surveyResults: surveyRes.surveyResults,
            surveyTaken: surveyRes.surveyTaken,
          }
        });
        setSurveyModalOpen(false)
        ahoy.track('Survey Submitted', { survey: `${props.client_name} (NAICS: ${props.code}): ${state.pageTitle} ${state.companyReportSurvey.survey.year}` });
      }
    } catch (error) {
      console.log(error);
      alert(
        'Sorry something went wrong. Changes were not saved properly.'
      );
    }
  }

  async function updateState(args = {}) {
    setState(prevState => {
      fetchCalcs(args, prevState)
      if (args.report) {
        return {
          ...prevState, report: args.report, loading: true
        }
      } else {
        return {
          ...prevState, loading: true
        }
      }
    })
  };

  const handleLoading = (value) => {
    setState(prevState => {
      return { ...prevState, loading: value }
    })
  }

  // fetch calculations on load if not present in props
  useEffect(() => {
    if (props.tour_data.tracker_id && !props.tour_data.tour_taken) {
      setIsOpen(true)
    }
    if (state.fetch) {
      updateState()
    };
  }, [])

  const updateSliderInputModals = (event) => {
    setForecastInputsBtnClicked(true)
    setForecastInputsOpen(!forecastInputsOpen)
  }

  const { loading, report, pageTitle, calendarYearEnd, fiscalYearStartMonth, calcs, real_name, previousYearCalcs, tree, n, filters, filterOptions, fiscalYearEnd, insufficient_data, reportSpecific, companyReportSurvey, surveyTaken, surveyResults } = state

  return (
    <div id='monthly-report-container' className={`${report}-content`} style={{ opacity: (loading ? 0.6 : 1) }}>
      {calcs
        ? <Data.Provider value={{ calcs, previousYearCalcs }}>
          <Filters.Provider value={{ filters, filterOptions }}>
            <div id='sticky-topnav-left-btns'>
              <ForecastPDFandPrint
                {...props}
                naicsCode={props.code}
                componentRefs={componentRefs}
                displayPages={displayPages}
                handlePDFandPrintChecked={handlePDFandPrintChecked}
                planAccess={{
                  export_to_excel: true,
                  print_and_pdf: true,
                }}
                year={filters.year}
              />
              <ExportDropdown
                printRef={componentRefs}
                planAccess={{
                  export_to_excel: true,
                  print_and_pdf: true,
                }}
                name={props.name}
                real_name={real_name}
                page={props.page}
                year={filters.year}
                xlsx={true}
              />
            </div>
            <div id='sticky-topnav-right-btns'>
              <button className='hide-on-print' onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faQuestionCircle} /></button>
            </div>
            <div id='sticky-side-btns'>
             <button className='filters-btn hide-on-print' onClick={() => updateSliderInputModals()}>{sliderIcon}</button>             
            </div>
            <MonthlyReport
              ref={componentRefs}
              {...{
                ...reportSpecific,
                calendarYearEnd,
                clientName: props.client_name,
                companyReportSurvey,
                displayPages,
                fetchLiveData: fetchLiveData,
                fiscalYearStartMonth,
                fiscalYearEnd,
                forecastInputsBtnClicked,
                forecastInputsOpen,
                handleLoading,
                turnOffLiveData,
                id: props.id,
                industry: props.industry,
                insufficientData: insufficient_data,
                logo: props.logo,
                naicsCode: props.code,
                name: props.name,
                page: props.page,
                page: report,
                pageTitle,
                real_name: real_name,
                scenarioName: scenarioName,
                setForecastInputsBtnClicked,
                setSurveyModalOpen,
                surveyData,
                surveyResults,
                surveyTaken,
                tourData: props.tour_data,
                tree,
                updateScenario,
                year: filters.year
              }}
            />
          </Filters.Provider>
        </Data.Provider>
        : <div style={{ marginBottom: '400px' }}>
          <LoaderGraphic />
        </div>}
    </div>
  )
}

export default MonthlyReportContainer
