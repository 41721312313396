import mapDataUS from "@highcharts/map-collection/countries/us/us-all.geo.json";
// KEEP US MAP DATA IN SEPERATE FILE!
// us-all.geo.json file is big (~65KB) and helps webpacker avoid compiling it if not being used.
const getUSMapOptions = (data) => {
    let orderCompaniesByState = {};
    let finalSeries = [];
    for (let i = 0; i < data.length; i++) {
        // check state
        let companyState = data[i]['state'];
        if (orderCompaniesByState[companyState]) {
            orderCompaniesByState[companyState] += 1
        } else {
            orderCompaniesByState[companyState] = 1
        }
    }
    for (let state in orderCompaniesByState) {
        let returnObj = {
            code: state,
            value: orderCompaniesByState[state]
        }
        finalSeries.push(returnObj)
    }

    // Instantiate the map
    return ({
        chart: {
            map: mapDataUS,
            style: {
                fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
            }
        },
        title: {
            text: 'Where Your Clients Are',
            style: {
                fontSize: '18px',
                fontWeight: 'normal'
            }
        },
        exporting: {
            sourceWidth: 600,
            sourceHeight: 500
        },
        legend: {
            layout: 'horizontal',
            borderWidth: 0,
            itemDistance: 8,
            floating: true,
            title: {
                style: {
                    fontSize: '12px'
                },
                text: 'Number of Clients',
            },
            squareSymbol: false,
            verticalAlign: 'top',
            y: 15
        },
        credits: {
            enabled: false
        },
        mapNavigation: {
            enabled: true
        },
        colorAxis: {
            min: 1,
            minorTickInterval: 1,
            type: 'logarithmic',
            minColor: '#EEEEFF',
            maxColor: '#000022',
            stops: [
                [0, '#88E858'],
                [0.2, '#6FBD48'],
                [0.5, '#69B144'],
                [0.7, '#589639'],
                [1, '#42702B']
            ]
        },
        series: [
            {
                animation: {
                    duration: 1000
                },
                data: finalSeries,
                joinBy: ['postal-code', 'code'],
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    format: '{point.value}',
                    style: {
                        fontSize: "13px"
                    },
                },
                name: 'Number of Clients',
                tooltip: {
                    pointFormat: '{point.code}: {point.value}',
                    style: {
                        fontSize: '11px'
                    }
                }
            }
        ]
    })
}

export default getUSMapOptions;