import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faEdit, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faKey, faSlidersH } from '@fortawesome/free-solid-svg-icons';

const reactourSteps = (page, tourData = {}) => {
  let tourTaken = tourData.tour_taken
  let newFeaturesMoneyTaken = tourData.new_features_tour_taken

  const endTour = (newFeature = false) => {
    if (!tourTaken) {
      let tourType = page !== 'money' && page !== 'people' && page !== 'customers' ? page : 'reports';
      fetch(`/api/v1/tour/end_tour/${tourData.tracker_id}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type: tourType })
      });
    }

    if (newFeature && !newFeaturesMoneyTaken && page === 'money') {
      fetch(`/api/v1/tour/end_tour/${tourData.tracker_id}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type: 'new_features_money_taken' })
      });
    }
  };

  if (page === 'map file') {
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }
              }>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <h2>Welcome to the Mapping page!</h2>
            <p>Here you will map your file's rows to the appropriate accounts for each of your clients in order to generate their reports.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '.company-header',
        content: () => (<p className='reactour-text'>Here's quick information about the client you are working on.</p>)
      },
      {
        selector: '.select-document',
        content: () => (<p className='reactour-text'>The file's name you are viewing and mapping is here. You can change which file uploads to use with this dropdown menu.</p>)
      },
      {
        selector: '#hist-button',
        content: () => (
          <div className='reactour-container'>
            <p className='reactour-text'>This button shows and hides the rows of your file.</p>
            <p className='reactour-text'>When you upload new files for a client, new rows will display first, and you can click this button to display older rows too.</p>
          </div>
        )
      },
      {
        selector: '.mapper-table-header',
        content: () => (
          <div className='reactour-container'>
            <p className='reactour-text'>Each row's information and year values are organized into these columns.</p>
            <p className='reactour-text'>
              <b>Historical</b>, <b>Best Match</b>, and <b>Alternatives</b> will give you suggested accounts to map to. You can click on any of these suggestions to map to your <b>Final</b> account choice.
            </p>
            <p className='reactour-text'><b>Final</b> account cells have dropdowns menus to select any account you find more appropiate.</p>
            <p className='reactour-text'>They also have a little square in the bottom right hand corner that can be clicked and dragged to cells above and below to map them to the same accounts and save time.</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '#mapper-nav-tabs',
        content: () => (<p className='reactour-text'>Here you can preview your <b>Balance Sheet</b> and <b>Profit &#38; Loss</b> with these tabs.</p>)
      },
      {
        selector: '#recalculate-btn',
        content: () => (
          <div className='reactour-container'>
            <p className='reactour-text'>Before clicking <b>Generate Reports</b>, you want to make sure the green check mark right next to it is fully visible <span className='mapper-check-full'><FontAwesomeIcon icon={faCheck} /></span>.</p>
            <p className='reactour-text'>If the check mark is faded out <span className='mapper-check-faded'><FontAwesomeIcon icon={faCheck} /></span>, then it means the Balance Sheet is out of balance.</p>
            <p className='reactour-text'>Click the <b>Recalculate</b> button to run calculations based off any of your mapping changes. Once the check mark is fully green you will be good to go!</p>
          </div>
        )
      },

      {
        selector: '.submit-mapping-button',
        content: () => (<p className='reactour-text'>When you are finished mapping accounts and the check mark is fully green and visible <span className='mapper-check-full'><FontAwesomeIcon icon={faCheck} /></span>, click <b>Generate Reports</b> and you will be redirected to the reports page when everything is completed!</p>)
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour()
      },
    ];
  }

  if (page === 'manageClients') {
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }
              }>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <h2>Welcome to the Manage Clients page!</h2>
            <p>This page will serve as command central for your firm's portal. It's where your client portals are created, data is uploaded, and reports accessed.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '.report-credits',
        content: () => (<p className='reactour-text'>Here indicates how many <b>report credits</b> you still have available. One credit unlocks a client's report suite.</p>)
      },
      {
        selector: '.new-client-btn',
        content: () => (
          <div className='reactour-container'>
            <p>Use this button to quickly <b>add one client</b> to the portal.</p>
            <p>You'll need to know their Client ID, their 6-digit NAICS code, the state where they are located, the partner that leads the engagement, and the office that services them.</p>
          </div>
        )
      },
      {
        selector: '.bulk-upload-btn',
        content: () => (<p className='reactour-text'>Use this feature to easily upload 2 or more clients using the template provided.</p>)
      },
      {
        selector: '#sticky-side-btns',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters live here. The Filters allow you to sort and display your clients.</p>
          </div>
        )
      },
      {
        selector: '.first-table-btn',
        content: () => (<p className='reactour-text'>Try clicking this button to open up the <b>Details</b> drawer for your client!</p>),
        highlightedSelectors: ['.overlay-base, .client-modal-content'],
        mutationObservables: ['.overlay-base, .client-modal-content'],
      },
      {
        selector: '#company-modal-edit-tab',
        content: () => (<p className='reactour-text'>Once opened, you can edit and save the client's information.</p>),
        position: 'left'
      },
      {
        selector: '.import-file-modal-btn',
        content: () => (<p className='reactour-text'>You can upload trial balances in Excel from any tax/audit/accounting software package by clicking the <b>Import Trial Balance</b> button.</p>)
      },
      {
        selector: '.map-file-link-modal-btn',
        content: () => (<p className='reactour-text'>Once your file or accounting software is done uploading data, you will be able to map the data to Peerview Data's chart of accounts.</p>)
      },
      {
        selector: '.report-link-modal-btn',
        content: () => (<p className='reactour-text'>After the data is completely mapped, you will be able to view the reports for your client.</p>)
      },
      {
        selector: '.connect-to-btns-container',
        content: () => (<p className='reactour-text'>You can connect to an accounting software such as QuickBooks Online here.</p>)
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour()
      },
    ];
  }

  if (page === 'money') {
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }}>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <p>This <b>Money</b> report is created from your client's trial balance. It's where every conversation will start.</p>
            <p>You'll be able to see how they're doing, how they're going against their peers, and identify opportunities for improvement.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '#sticky-topnav-left-btns',
        content: () => (
          <div className='reactour-container'>
            <ul className='sticky-side-tour-list-description'>
              <li><p><FontAwesomeIcon icon={faFileExcel} /></p> Enables you to export to Excel</li>
              <li><p><FontAwesomeIcon icon={faFilePdf} /></p> Enables you to print or export to PDF</li>
            </ul>
          </div>
        )
      },
      {
        selector: '#sticky-side-btns',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters live here. Give this button a click!</p>
          </div>
        )
      },
      {
        selector: '.money-dropdown-container',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters allow you to customize the timeline and comparative data based on your client's data and the purpose of your meeting.</p>
            <p>Filters will only be active if there are 20 or more companies for comparison.</p>
          </div>
        )
      },
      {
        selector: '.naics-code-filter',
        content: () => (
          <p className='reactour-text'><b>Industry</b> will start at a 3-digit NAICS code level but often provides ability to get more detailed comparisons.</p>
        )
      },
      {
        selector: '.revenue-band-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are three <b>Revenue</b> bands: </p>
            <ul>
              <li>Under $10 million</li>
              <li>$10 to 100 million</li>
              <li>Over $100 million</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.region-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are four geographic <b>Regions</b>: </p>
            <ul>
              <li>Northeast</li>
              <li>Midwest</li>
              <li>South</li>
              <li>West</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.display-columns-filter',
        content: () => (
          <p className='reactour-text'>Years is the default <b>display</b>. However, if you've connected your client's QuickBooks Online account, you'll also have access to the Months option.</p>
        )
      },
      {
        selector: '.report-period-filter',
        content: () => (
          <p className='reactour-text'>Choose the <b>Report Period</b> of data you would like displayed based on the data you've loaded for your client or based on the use case.</p>
        )
      },
      {
        selector: '.compare-with-filter',
        content: () => (
          <div className='reactour-container'>
            <p>For annual reports, you'll <b>compare with</b> peers.</p>
            <p>For monthly reports, you can choose between peers or with their own year-over-year data.</p>
          </div>
        )
      },
      {
        selector: '.year-filter',
        content: () => (
          <p className='reactour-text'>Comparative Peer data is always shown by the <b>Calendar Year</b>. Here you can choose the last year to be displayed. A client with a Fiscal Year End will always be compared to the previous calendar year end dataset.</p>
        )
      },
      {
        selector: '.run-report-btn',
        content: () => (
          <p className='reactour-text'>After selecting your filters, you will click the <b>Run Report</b> button to display the new data.</p>
        )
      },
      {
        selector: '#nav-tabs-money-report',
        content: () => (
          <p className='reactour-text'>Much like Excel, you can switch between the tabs to view different report elements.</p>
        )
      },
      {
        selector: '#pnl-tab',
        content: () => (
          <div className='reactour-container'>
            <p>The <b>Profit &#38; Loss</b> tab will show your client's P&#38;L, the common sized values, and their peers' common sized values.</p>
            <p>Green "up" arrows indicate where your client is better than their peers by 5% or more.</p>
            <p>Red "down" arrows indicate where your client is worse than their peers by 5% or more..</p>
          </div>
        )
      },
      {
        selector: '#balance-sheet-tab',
        content: () => (
          <p className='reactour-text'><b>Balance Sheet</b> is much the same as the P&#38;L</p>
        )
      },
      {
        selector: '#cash-flow-tab',
        content: () => (
          <p className='reactour-text'><b>Cash Flow</b> is calculated from the P&#38;L and Balance Sheet, the report is setup like a standard cash flow statement using operating, investing, and financing categories.</p>
        )
      },
      {
        selector: '#kfis-tab',
        content: () => (
          <p className='reactour-text'><b>Key Financial Indicators (KFI's)</b> are a series of ratios and metrics that apply to most businesses.</p>
        )
      },
      {
        selector: '.edit-metrics-cell',
        content: () => (
          <div className='reactour-container'>
            <p>On all the report sheets you will see a <b>pencil</b> icon.</p>
            <p>When you click on it, you can <b>directly click on line items on the sheet</b> that you would like to focus on for your client.</p>
            <p>After clicking on a line, a check mark &#10004; will appear beside the metric's name.</p>
            <p>Ex. &#10004; Revenue.</p>
            <p>Metrics that appear more than once on the other sheets or any related metrics will also be checked for you.</p>
            <p>Also checked metrics will display related charts and items on your client's dashboard.</p>
            <p>Make sure to save your selections by clicking on the <b>save</b> icon <i className='fa fa-save' style={{ 'fontSize': '1.5em', 'margin': '0 3px' }}></i> right where the pencil used to be.</p>
          </div>
        )
      },
      {
        selector: '#dashboard-tab',
        content: () => (
          <div className='reactour-container'>
            <p><b>Dashboard</b> allows you to customize what charts and graphs you want to view the data.</p>
            <p>You can Reorder and Resize the items to display what you want, the way that you want, and save the layout.</p>
            <p>Use the menu icon in the top right corner of some of the cards to export or print just that graphic. They can be really useful for inclusion in audit reports, financial statements, or other management documents.</p>
            <p>Try clicking on this tab to check it out!</p>
          </div>
        ),
        highlightedSelectors: ['.react-grid-layout'],
        mutationObservables: ['.react-grid-layout'],
        position: 'top'
      },
      {
        selector: '.edit-dashboard-btn',
        content: () => (
          <div className='reactour-container'>
            <p>The Dashboard is completely curatable for each client.</p>
            <p>Turn on/off any of the charts/graphs.</p>
            <p>You can also check which metrics you want to focus on from all the sheets here.</p>
            <p>Save the format at the end of your session to come back to the same display next visit!</p>
          </div>
        ),
      },
      {
        selector: '#survey-tab',
        content: () => (
          <div className='reactour-container'>
            <p>The <b>Survey</b> tab is where you can see your client's survey responses and compare them with peer responses.</p>
            <p>If you haven't taken the survey yet for the selected year, then the survey will pop up when you click on the tab.</p>
          </div>
        ),
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour(true)
      }
    ];
  }

  if (page === 'people' || page === 'customers') {
    let pageTitle = page === 'people' ? 'People' : 'Customers';
    let runReportBtnSelector = page === 'people' ? '.run-report-btn-people' : '.run-report-btn-customers';
    let navTabsSelector = page === 'people' ? '#nav-tabs-people-report' : '#nav-tabs-customers-report';
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }
              }>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <p>This <b>{pageTitle}</b> report is created from a combination of your client's trial balance data along with the survey data that they will need to provide.</p>
            <p>You'll be able to see how they're doing, how they're going against their peers, and identify opportunities for improvement.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '#sticky-topnav-left-btns',
        content: () => (
          <div className='reactour-container'>
            <ul className='sticky-side-tour-list-description'>
              <li><p><FontAwesomeIcon icon={faFilePdf} /></p> Enables you to print or export to PDF</li>
            </ul>
          </div>
        )
      },
      {
        selector: '#sticky-side-btns',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters live here. Give this button a click!</p>
          </div>
        )
      },
      {
        selector: '.money-dropdown-container',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters allow you to customize the timeline and comparative data based on your client's data and the purpose of your meeting.</p>
            <p>Filters will only be active if there are 20 or more companies for comparison.</p>
          </div>
        )
      },
      {
        selector: '.naics-code-filter',
        content: () => (
          <p className='reactour-text'><b>Industry</b> will start at a 3-digit NAICS code level but often provides ability to get more detailed comparisons.</p>
        )
      },
      {
        selector: '.revenue-band-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are three <b>Revenue</b> bands: </p>
            <ul>
              <li>Under $10 million</li>
              <li>$10 to 100 million</li>
              <li>Over $100 million</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.region-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are four geographic <b>Regions</b>: </p>
            <ul>
              <li>Northeast</li>
              <li>Midwest</li>
              <li>South</li>
              <li>West</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.display-columns-filter',
        content: () => (
          <p className='reactour-text'>Years is the default <b>display</b>. However, if you've connected your client's QuickBooks Online account, you'll also have access to the Months option.</p>
        )
      },
      {
        selector: '.report-period-filter',
        content: () => (
          <p className='reactour-text'>Choose the <b>Report Period</b> of data you would like displayed based on the data you've loaded for your client or based on the use case.</p>
        )
      },
      {
        selector: '.compare-with-filter',
        content: () => (
          <div className='reactour-container'>
            <p>For annual reports, you'll <b>compare with</b> peers.</p>
            <p>For monthly reports, you can choose between peers or with their own year-over-year data.</p>
          </div>
        )
      },
      {
        selector: '.year-filter',
        content: () => (
          <p className='reactour-text'>Comparative Peer data is always shown by the <b>Calendar Year</b>. Here you can choose the last year to be displayed. A client with a Fiscal Year End will always be compared to the previous calendar year end dataset.</p>
        )
      },
      {
        selector: runReportBtnSelector,
        content: () => (
          <p className='reactour-text'>After selecting your filters, you will click the <b>Run Report</b> button to display the new data.</p>
        )
      },
      {
        selector: navTabsSelector,
        content: () => (
          <p className='reactour-text'>Much like Excel, you can switch between the tabs to view different report elements.</p>
        )
      },
      {
        selector: '#financial-detail-tab',
        content: () => (
          <div className='reactour-container'>
            <p>The <b>Financial Detail</b> tab will show your client's data, the common sized values, and their peers' common sized values.</p>
            <p>Green "up" arrows indicate where your client is better than their peers by 5% or more.</p>
            <p>Red "down" arrows indicate where your client is worse than their peers by 5% or more.</p>
          </div>
        )
      },
      {
        selector: '#kpi-tab',
        content: () => (
          <p className='reactour-text'><b>Key Performance Indicators (KPI)</b> are a series of ratios and metrics that apply to most businesses.</p>
        )
      },
      {
        selector: '#summary-tab',
        content: () => (
          <div className='reactour-container'>
            <p><b>Summary</b> provides some narrative explanations of how to improve underperforming metrics.</p>
            <p>The responses will be in one of five categories: within peer range, lower than, higher than, much lower than, and much higher than.</p>
          </div>
        )
      },
      {
        selector: '#dashboard-tab',
        content: () => (
          <div className='reactour-container'>
            <p><b>Dashboard</b> allows you to customize what charts and graphs you want to view the data.</p>
            <p>You can Reorder and Resize the items to display what you want, the way that you want, and save the layout.</p>
            <p>Use the menu icon in the top right corner of some of the cards to export or print just that graphic. They can be really useful for inclusion in audit reports, financial statements, or other management documents.</p>
            <p>Try clicking on this tab to check it out!</p>
          </div>
        ),
        highlightedSelectors: ['.react-grid-layout'],
        mutationObservables: ['.react-grid-layout'],
        position: 'top'
      },
      {
        selector: '.edit-dashboard-btn',
        content: () => (
          <p className='reactour-text'>The Dashboard is completely curatable for each client. Turn on/off any of the charts/graphs. Save the format at the end of your session to come back to the same display next visit!</p>
        )
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour()
      },
    ];
  }

  if (page === 'practice') {
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }
              }>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
            <div className='tour-new-info-btns'>
              <button onClick={() => setCurrentStep(13)}>What's new here? <span>🤔</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <p>This <b>Practice</b> report is created from all your clients' data.</p>
            <p>You'll be able to see how they're doing, how they're going against their peers, and identify opportunities for improvement.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '#sticky-topnav-left-btns',
        content: () => (
          <div className='reactour-container'>
            <ul className='sticky-side-tour-list-description'>
              <li><p><FontAwesomeIcon icon={faFilePdf} /></p> Enables you to print or export to PDF</li>
              <li><p><FontAwesomeIcon icon={faFileExcel} /></p> Enables you to export to Excel</li>
            </ul>
          </div>
        )
      },
      {
        selector: '#sticky-side-btns',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters live here. Give this button a click!</p>
          </div>
        )
      },
      {
        selector: '.money-dropdown-filters',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters allow you to customize the timeline and comparative data based on your clients' data and the purpose of your meeting.</p>
          </div>
        )
      },
      {
        selector: '.naics-code-filter',
        content: () => (
          <p className='reactour-text'>Practice Reports start at 2-digit <b>Industry</b> groupings, enabling you to see all clients from the industries you serve. You can choose how broad or specific the group is that you want to analyze.</p>
        )
      },
      {
        selector: '.revenue-band-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are three <b>Revenue</b> bands: </p>
            <ul>
              <li>Under $10 million</li>
              <li>$10 to 100 million</li>
              <li>Over $100 million</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.region-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are four geographic <b>Regions</b>: </p>
            <ul>
              <li>Northeast</li>
              <li>Midwest</li>
              <li>South</li>
              <li>West</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.partner-filter',
        content: () => (
          <p className='reactour-text'>Display client data by <b>Partner</b></p>
        )
      },
      {
        selector: '.office-filter',
        content: () => (
          <p className='reactour-text'>Display client data by <b>Office</b> location</p>
        )
      },
      {
        selector: '.year-filter',
        content: () => (
          <p className='reactour-text'>Comparative Peer data is always shown by the <b>Calendar Year</b>. Here you can choose the last year to be displayed.</p>
        )
      },
      {
        selector: '.run-report-btn',
        content: () => (
          <p className='reactour-text'>After selecting your filters, you will click the <b>Run Report</b> button to display the new data.</p>
        )
      },
      {
        selector: '#nav-tabs-money-report',
        content: () => (
          <p className='reactour-text'>Much like Excel, you can switch between the tabs to view different report elements.</p>
        )
      },
      {
        selector: '#naics-analysis-tab',
        content: () => (
          <p className='reactour-text'>Analyze your clients' data by <b>NAICS</b> codes. You can click on the pie chart or legend below the chart to drill down and view data for specific codes.</p>
        )
      },
      {
        selector: '#client-analysis-tab',
        content: () => (
          <div className='reactour-container'>
            <p>Here you can view the Average values for all <b>clients</b> selected as well as values for each individual client that you serve within the selected industry.</p>
            <p>There's 2 tables to help keep your <b>Submitted Clients</b> and <b>Unsubmitted Clients</b> organized.</p>
            <p>You can also click on clients you want to focus on. A check mark will appear next to your client in the <b>Focus</b> column.</p>
            <p>On the Edit Dashboard pop up, you can select <b>Focused Clients</b> and a list of your focused clients will appear on the Dashboard.</p>
          </div>
        )
      },
      {
        selector: '#pnl-tab',
        content: () => (
          <div className='reactour-container'>
            <p>The <b>Profit &#38; Loss</b> tab will show the average of all clients within the selected group, their common sized values, and their peers’ common sized values.</p>
          </div>
        )
      },
      {
        selector: '#balance-sheet-tab',
        content: () => (
          <p className='reactour-text'><b>Balance Sheet</b> is much the same as the P&#38;L</p>
        )
      },
      {
        selector: '#dashboard-tab',
        content: () => (
          <div className='reactour-container'>
            <p><b>Dashboard</b> allows you to customize what charts and graphs you want to view the data.</p>
            <p>You can Reorder and Resize the items to display what you want, the way that you want, and save the layout.</p>
            <p>Use the menu icon in the top right corner of some of the cards to export or print just that graphic.</p>
            <p>Try clicking on this tab to check it out!</p>
          </div>
        ),
        highlightedSelectors: ['.react-grid-layout'],
        mutationObservables: ['.react-grid-layout'],
        position: 'top'
      },
      {
        selector: '.edit-dashboard-btn',
        content: () => (
          <p className='reactour-text'>The Dashboard is completely curatable for each client. Turn on/off any of the charts/graphs. Save the format at the end of your session to come back to the same display next visit!</p>
        ),
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour()
      },
    ];
  }

  if (page === 'industry') {
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }
              }>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <p>This <b>Industry</b> report is created from all industry data by naics code.</p>
            <p>It's format is similar to the Money, People, and Customers Reports.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '#sticky-topnav-left-btns',
        content: () => (
          <div className='reactour-container'>
            <ul className='sticky-side-tour-list-description'>
              <li><p><FontAwesomeIcon icon={faFilePdf} /></p> Enables you to print or export to PDF</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.industry-report-tabs',
        content: () => (
          <div className='reactour-container'>
            <p>As mentioned before, the format is similar to the <b>Money, People, and Customers</b> Reports.</p>
            <p>Each section has its own tabs and dashboards to view, edit, and save too.</p>
            <p>The filters will apply to all sections so you can switch between them with ease.</p>
          </div>
        ),
        position: 'bottom'
      },
      {
        selector: '#sticky-side-btns',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters live here. Give this button a click!</p>
          </div>
        )
      },
      {
        selector: '.money-dropdown-filters',
        content: () => (
          <div className='reactour-container'>
            <p>The Filters allow you to customize the timeline and comparative data for the Money, People, and Customers sections.</p>
          </div>
        )
      },
      {
        selector: '.naics-code-filter',
        content: () => (
          <p className='reactour-text'><b>Industry</b> will start at a 3-digit NAICS code level but often provides ability to get more detailed comparisons.</p>
        )
      },
      {
        selector: '.revenue-band-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are three <b>Revenue</b> bands: </p>
            <ul>
              <li>Under $10 million</li>
              <li>$10 to 100 million</li>
              <li>Over $100 million</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.region-filter',
        content: () => (
          <div className='reactour-container'>
            <p>There are four geographic <b>Regions</b>: </p>
            <ul>
              <li>Northeast</li>
              <li>Midwest</li>
              <li>South</li>
              <li>West</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.year-filter',
        content: () => (
          <p className='reactour-text'>Comparative Peer data is always shown by the <b>Calendar Year</b>. Here you can choose the last year to be displayed.</p>
        )
      },
      {
        selector: '.run-report-btn',
        content: () => (
          <p className='reactour-text'>After selecting your filters, you will click the <b>Run Report</b> button to display the new data.</p>
        )
      },
      {
        selector: '#nav-tabs-money-report',
        content: () => (
          <div className='reactour-container'>
            <p>Much like Excel, you can switch between the tabs to view different report elements.</p>
            <p>For the purpose of this tour, we will take you through the Money tabs.</p>
            <p>The People and Customers sections have Financial Detail, Key Performance Indicators (KPI), and Dashboard tabs.</p>
          </div>
        )
      },
      {
        selector: '#pnl-tab',
        content: () => (
          <div className='reactour-container'>
            <p>The <b>Profit &#38; Loss</b> tab will show common sized values.</p>
          </div>
        )
      },
      {
        selector: '#balance-sheet-tab',
        content: () => (
          <p className='reactour-text'><b>Balance Sheet</b> is much the same as the P&#38;L</p>
        )
      },
      {
        selector: '#kfis-tab',
        content: () => (
          <p className='reactour-text'><b>Key Financial Indicators (KFI's)</b> are a series of ratios and metrics that apply to most businesses.</p>
        )
      },
      {
        selector: '#dashboard-tab',
        content: () => (
          <div className='reactour-container'>
            <p><b>Dashboard</b> tab allows you to customize what charts and graphs you want to view the data in each section.</p>
            <p>You can Reorder and Resize the items to display what you want, the way that you want, and save the layout.</p>
            <p>Use the menu icon in the top right corner of some of the cards to export or print just that graphic.</p>
            <p>Try clicking on this tab to check it out!</p>
          </div>
        ),
        highlightedSelectors: ['.react-grid-layout'],
        mutationObservables: ['.react-grid-layout'],
        position: 'top'
      },
      {
        selector: '.edit-dashboard-btn',
        content: () => (
          <p className='reactour-text'>The Dashboard is completely curatable for each client. Turn on/off any of the charts/graphs. Save the format at the end of your session to come back to the same display next visit!</p>
        ),
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour()
      },
    ];
  }

  if (page === 'forecast') {
    return [
      {
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h2>Hello!</h2>
            <h3>Would you like to take a tour of this page?</h3>
            <div className='tour-intro-btns'>
              <button onClick={() => {
                endTour()
                setIsOpen(false)
              }
              }>No thanks, I'm good. <span>😎</span></button>
              <button onClick={() => setCurrentStep(1)}>Yes please! <span>😃</span></button>
            </div>
          </div>
        ),
        position: 'center'
      },
      {
        content: () => (
          <div className='reactour-container'>
            <h2>Welcome to the Monthly Forecast!</h2>
            <p>Each year’s forecast is based off of last year’s numbers. If you have Live Data available, for example, from QuickBooks Online, you will be able to see and click the Sync Live Data button.</p>
            <p>Click on the arrows below to navigate throughout the tour at any time.</p>
            <p>You can also click outside of the tour's pop up window in the shaded area to move on to the next step.</p>
            <p style={{ 'textAlign': 'center' }}>Let's get started!</p>
          </div>
        ),
        position: 'center'
      },
      {
        selector: '#sticky-topnav-left-btns',
        content: () => (
          <div className='reactour-container'>
            <ul className='sticky-side-tour-list-description'>
              <li><p><FontAwesomeIcon icon={faFilePdf} /></p> Enables you to print or export to PDF</li>
              <li><p><FontAwesomeIcon icon={faFileExcel} /></p> Enables you to export to Excel</li>
            </ul>
          </div>
        )
      },
      {
        selector: '#sticky-side-btns',
        content: () => (
          <div className='reactour-container'>
            <ul className='sticky-side-tour-list-description'>
              <li><p><FontAwesomeIcon icon={faSlidersH} /></p> Opens/Closes the slider and number inputs drawer for the Profit &#38; Loss and Balance Sheet. You can edit the forecast values with these.</li>
            </ul>
          </div>
        )
      },
      {
        selector: '.monthly-forcast-chart',
        content: () => (
          <div className='reactour-container'>
            <p>Here you can see <b>Revenue Models</b> for your Straightline, You, and your client's Peers.</p>
            <p>By choosing one of the models, the revenue will be distributed throughout the year using either a straightline method or by using the historical monthly distribution of your client’s revenue or their peers’ revenue.</p>
            <p>You can click on the legend in the chart to display and edit the values below in the Forecast sheets.</p>
          </div>
        )
      },
      {
        selector: '.revenue-model-btn',
        content: () => (
          <p className='reactour-text'>Open/Close the Revenue Model chart with this button.</p>
        )
      },
      {
        selector: '.sync-live-btn',
        content: () => (
          <p className='reactour-text'>If you have Live Data available, for example, from QuickBooks Online, you will be able to see and click the <span style={{ 'whiteSpace': 'nowrap' }}><b>Sync Live Data</b></span> button for the current year.</p>
        )
      },
      {
        selector: '.scenario-form',
        content: () => (
          <p className='reactour-text'>After you make changes to your forecast and you want to save, make sure to give you a new scenario and save it before navigating to another page.</p>
        )
      },
      {
        selector: '.scenario-selection',
        content: () => (
          <p className='reactour-text'>You can display your saved scenarios by selecting them in this dropdown.</p>
        )
      },
      {
        selector: '#monthly-forecast-tabs',
        content: () => (
          <p className='reactour-text'>Much like Excel, you can switch between the tabs to view different report elements. Here we have the Profit &#38; Loss, Balance Sheet, Cash Flow, and Key Financial Indicators (KFI's).</p>
        ),
        position: 'center'
      },
      {
        selector: '.year-selector',
        content: () => (
          <div className='reactour-container'>
            <p>The forecast will go out to 3 years. You can click back and forth between the years and it will display the same year when you click the different tabs.</p>
            <p>You will notice on the Profit &#38; Loss that there's a growth rate number that will also change with the years.</p>
          </div>
        )
      },
      {
        selector: '#year1-pnl-modal',
        content: () => (
          <div className='reactour-container'>
            <p className='reactour-text'>The slider and number inputs drawers for the Profit &#38; Loss and Balance Sheet are where can edit and create your scenarios.</p>
            <p>You can click to toggle between the slider and input tabs.</p>
            <p>When creating a scenario you will want to edit Revenue, Expense, Asset, Liability and Equity accounts to fit your expectations.</p>
          </div>
        )
      },
      {
        selector: '.current-month-col',
        content: () => (
          <div className='reactour-container'>
            <p>You can click and select which month you want to change values for with the light orange highlight.</p>
            <p>You’ll notice that when you change a value within a month, each following month will have that same value.</p>
          </div>
        )
      },
      {
        selector: '.fa-question-circle',
        content: ({ setCurrentStep, setIsOpen }) => (
          <div className='reactour-container'>
            <h3>That's the end of the tour!</h3>
            <p>If you would like to take it again at any time just click this button.</p>
            <button className='finish-tour-btn' onClick={() => {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
            }>Finish Tour 👍</button>
          </div>
        ),
        position: 'center',
        action: () => endTour()
      },
    ];
  }
}

const reactourStyles = (page) => {
  switch (page) {
    case 'people':
      return {
        badge: base => (
          { ...base, backgroundColor: '#08aeef' }
        ),
        dot: () => (
          { display: 'none' }
        )
      }
    case 'customers':
      return {
        badge: base => (
          { ...base, backgroundColor: '#5d2a72' }
        ),
        dot: () => (
          { display: 'none' }
        )
      }
    case 'forecast':
      return {
        badge: base => (
          { ...base, backgroundColor: '#f17b5b' }
        ),
        dot: () => (
          { display: 'none' }
        )
      }
    default:
      return {
        badge: base => (
          { ...base, backgroundColor: '#69B144' }
        ),
        dot: () => (
          { display: 'none' }
        )
      }

  }
}
export { reactourSteps, reactourStyles };