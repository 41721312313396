import React, { useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';

const CompanyCreate = (props) => {

    let lineChartOptions = (title, cumulative) => {
        let dates = []
        let numOfCompanies = []

        Object.entries(props.info).forEach(([key, value], index) => {
            dates.push(key)
    
            if (cumulative) {
                let numOfCompaniesByDate = value.length
                let sumOfCompanies = index ? numOfCompanies[index - 1] : 0
                numOfCompanies.push(sumOfCompanies + numOfCompaniesByDate)
            } else {
                numOfCompanies.push(value.length)
            }
        })

        return {
            chart: {
                zoomType: 'x',
                events: {
                    load: function () {
                        var chart = this,
                            series = this.series[0],
                            xAxis = chart.xAxis[0],
                            newStart = series.xData[series.xData.length - 10],
                            newEnd = series.xData[series.xData.length - 1];

                        xAxis.setExtremes(newStart, newEnd);
                    }
                },
                style: {
                    fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
                }
            },
            title: {
                text: title,
                style: {
                    fontSize: '14px'
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                allowHTML: true,
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            navigation: {
                menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#000000'
                },
                menuItemStyle: {
                    padding: '3px 5px',
                    fontWeight: 'bold'
                },
                menuStyle: {
                    padding: '0',
                }
            },
            tooltip: {
                shared: true,
                pointFormatter: function () {
                    return `# of Companies : <b>${this.y}</b><br/>`
                },
                style: {
                    stroke: "none",
                    fontSize: '12px'
                }
            },
            xAxis: {
                categories: dates.map(date => {
                    return Highcharts.dateFormat('%Y-%m-%d', new Date(date).getTime());
                }),
                startOnTick: true,
                endOnTick: true,
                labels: {
                    align: 'right',
                    style: {
                        fontSize: '12px'
                    }
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            },
            series: [{ data: numOfCompanies }],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        align: 'center',
                        crop: false,
                        allowOverlap: true,
                        style: {
                            stroke: "none",
                            textOutline: "none",
                            fontSize: '12px'
                        }
                    }
                }
            },
            legend: {
                enabled: false
            }
        }
    }

    let chartOptionsCumulative = lineChartOptions('Cumulative # of Parent Companies Over Time', true)
    let chartOptions = lineChartOptions('Parent Companies Created By Date')

    return (
        <div>
            <div className='companies-created-chart'>
                <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '100%', minHeight: '100%', boxShadow: '0px 2px 8px 0px #63636333' } }} options={chartOptionsCumulative} />
            </div>
            <div className='companies-created-chart'>
                <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '100%', minHeight: '100%', boxShadow: '0px 2px 8px 0px #63636333' } }} options={chartOptions} />
            </div>
        </div>
    )
}

export default CompanyCreate;