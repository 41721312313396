import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { YTDCashFlowValues, CashFlowCommonSizeArrows } from '../report_components/MoneyHelpers';

const MoneyYTDCashFlow = (props) => {
    const { calcs, previousYearCalcs } = useContext(Data);
    const { currentNewFilters } = useContext(Filters);
    const calcYearKeys = Object.keys(calcs);
    const previousYearCalcKeys = Object.keys(previousYearCalcs['monthly_full_year']);
    let compareWith = currentNewFilters.compare_with;
    let oldYear, newYear;
    let previousOldFullYear, previousNewFullYear;
    previousOldFullYear = previousYearCalcKeys[0];
    previousNewFullYear = previousYearCalcKeys[1];

    const previousYearYTDYouTotalRevenue = calcs[props.year - 1] ? calcs[props.year - 1]['you']['total_revenue'] : undefined;
    const currentYearYTDYouTotalRevenue = calcs[props.year] ? calcs[props.year]['you']['total_revenue'] : undefined;
    let firstYearMonth = previousYearYTDYouTotalRevenue ? Object.values(previousYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    let secondYearMonth = currentYearYTDYouTotalRevenue ? Object.values(currentYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    let clientFirstYearMonth = firstYearMonth < 10 ? '0' + firstYearMonth.toString() : firstYearMonth;
    let clientSecondYearMonth = secondYearMonth < 10 ? '0' + secondYearMonth.toString() : secondYearMonth;

    if (Number(calcYearKeys[1]) > Number(calcYearKeys[0])) {
        newYear = calcYearKeys[1];
        oldYear = calcYearKeys[0];
    } else {
        newYear = calcYearKeys[0];
        oldYear = calcYearKeys[1];
    }
    // Ex. Selected Year is 2021
    const calcsYou = calcs[newYear].you;
    const calcsPeers = compareWith === 'Peers' ? calcs[newYear].avg : null;
    // Ex. 2020
    const calcsYouOldYear = calcs[oldYear].you;
    const calcsPeersOldYear = compareWith === 'Peers' ? calcs[oldYear].avg : null;
    // 2020 Full Year
    const previousYearCalcsYou = previousYearCalcs['monthly_full_year'][previousNewFullYear].you;
    const previousYearCalcsPeers = compareWith === 'Peers' ? previousYearCalcs['monthly_full_year'][previousNewFullYear].avg : null;
    // 2019 Full Year
    const previousYearCalcsYouOldYear = previousYearCalcs['monthly_full_year'][previousOldFullYear].you;
    const previousYearCalcsPeersOldYear = compareWith === 'Peers' ? previousYearCalcs['monthly_full_year'][previousOldFullYear].avg : null;
    const cashFlowValues = {}
    const cashFlowValuesPeersAvg = {}
    const cashFlowValuesOldYear = {}
    const cashFlowValuesPeersAvgOldYear = {}
    // Current Year YTD Cash Flow to should match the 'Total' column in Current Year Monthly Cash Flow
    const PreviousMonthMinusCurrentMonthAmount = (opexNi, currentYear, previousYear) => {
        let returnValue = null;
        let numberOfMonths = Object.keys(currentYear).length;
        const returnObject = {}
        for (let i = 1; i <= 12; i++) {
            if (i === 1 && _.isNumber(previousYear[12])) {
                returnObject[i] = previousYear[12] - currentYear[1]
            } else if (_.isNumber(currentYear[i])) {
                returnObject[i] = currentYear[i - 1] - currentYear[i]
            } else {
                returnObject[i] = null;
            }
        }
        returnValue = Object.values(returnObject).reduce((a, b) => a + b);
        return returnValue;
    }

    const CurrentMonthMinusPreviousMonthAmount = (opexNi, currentYear, previousYear) => {
        let returnValue = null;
        let numberOfMonths = Object.keys(currentYear).length;
        const returnObject = {}
        for (let i = 1; i <= 12; i++) {
            if (i === 1 && _.isNumber(previousYear[12])) {
                returnObject[i] = currentYear[1] - previousYear[12]
            } else if (_.isNumber(currentYear[i])) {
                returnObject[i] = currentYear[i] - currentYear[i - 1]
            } else {
                returnObject[i] = null;
            }
        }
        returnValue = Object.values(returnObject).reduce((a, b) => a + b);
        return returnValue;
    }

    function commonSizeValue(props) {
        let finalValue = null;
        if (Object.values(props.passedCalcs).length > 0) {
            const val = props.value;
            const totalRevenue = Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0)
            finalValue = ((val / totalRevenue) * 100).toFixed(2)
        }
        return finalValue
    }

    function emptyRow(i, copy, relatedCheckedKey, checked, displayChart, firstStyle, secondStyle) {

        if (copy === 'Current Liabilities') {
            secondStyle['paddingTop'] = '20px'
        }
        let rowChecked = displayChart && checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

        return (
            <tr key={i} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'cash_flow', relatedCheckedKey) : null}>
                {!props.pdf ? <td>{rowChecked}</td> : null}
                <td className='table-copy' style={secondStyle}>{copy}</td>
                <td className='table-copy money-table-cell' style={firstStyle}></td>
                <td className='table-copy money-table-cell' style={firstStyle}></td>
                {compareWith === 'Peers' ? <td className='table-copy money-table-cell' style={firstStyle}></td> : null}
                {compareWith === 'Peers' ? <td className='table-copy money-table-cell' style={firstStyle}></td> : null}
                <td className='table-copy' style={secondStyle}></td>
                <td className='table-copy' style={secondStyle}></td>
                <td className='table-copy' style={secondStyle}></td>
                {compareWith === 'Peers' ? <td className='table-copy' style={secondStyle}></td> : null}
                {compareWith === 'Peers' ? <td className='table-copy' style={secondStyle}></td> : null}
                <td className='table-copy' style={secondStyle}></td>
                <td className='table-copy money-table-cell' style={firstStyle}></td>
            </tr>
        )
    };

    function Total(props) {
        let finalValue = parseMoney(props.value)
        return (<td className={props.cssClass} style={props.style}>
            {finalValue}
        </td>);

    }

    function CommonSizeArrowsHelper(props) {
        if (Object.values(props.passedCalcs).length > 0 && props.totalValue !== null && props.totalValueCompare !== null) {
            let arrow;
            const totalRevenueYou = Object.values(calcsYou['total_revenue']).reduce((a, b) => a + b, 0);
            const totalRevenueCompareTo = Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0);
            const you = (props.totalValueYou / totalRevenueYou)
            const compareTo = (props.totalValueCompare / totalRevenueCompareTo)
            arrow = CashFlowCommonSizeArrows(you, compareTo, props.keyArrBuilder)

            if (arrow === 'better' || arrow === 'best') {
                arrow = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
            } else if (arrow === 'worse' || arrow === 'worst') {
                arrow = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
            } else {
                arrow = ''
            }
            return (<td className={props.cssClass} style={props.style}>{arrow}</td>);
        } else {
            return (<td className={props.cssClass} style={props.style}></td>);
        }
    }

    function Delta(props) {
        let previousYearTotal = props.valuePreviousYear;
        let currentYearTotal = props.valueCurrentYear;
        let deltaValue = '$0';

        let delta = currentYearTotal - previousYearTotal;
        deltaValue = parseMoney(delta);

        if (previousYearTotal === null || currentYearTotal === null) {
            deltaValue = '-';
        }
        return (<td className='common-size-cell money-table-cell' style={props.style}>
            {deltaValue}
        </td>);
    }

    const buildRows = () => {
        let allRows = []
        let borderTopStyle, fontStyle, paddingTopStyle, metricNameStyle, backgroundStyle, firstStyle;
        props.rows.forEach((row, i) => {
            let newerYearVals = YTDCashFlowValues(row.key, calcsYou, previousYearCalcsYou, calcsPeers, previousYearCalcsPeers);
            let newerYearYouVal = newerYearVals['youValue'];
            let newerYearCompareVal = newerYearVals['compareValue'];
            let olderYearVals = YTDCashFlowValues(row.key, calcsYouOldYear, previousYearCalcsYouOldYear, calcsPeersOldYear, previousYearCalcsPeersOldYear);
            let olderYearYouVal = olderYearVals['youValue'];
            let olderYearCompareVal = olderYearVals['compareValue'];
            const emptyRowKeys = ['Cash flow from operating activities:', 'Cash flow from investing activities:', 'Cash flow from financing activities:']
            const borderKeys = ['Net cash flow from operating activities', 'Net cash flow from investing activities', 'Net cash flow from financing activities']
            const paddingTopKeys = ['Cash flow from investing activities:', 'Cash flow from financing activities:', 'Net change in cash and cash equivalents', 'Cash at beginning of period', 'Cash at end of period']
            borderKeys.includes(row.copy) ? borderTopStyle = { borderTop: 'solid 1px', minHeight: '17px', paddingBottom: '17px' } : borderTopStyle = { borderTop: 'none' }
            paddingTopKeys.includes(row.copy) ? paddingTopStyle = { paddingTop: '20px' } : paddingTopStyle = { paddingTop: '3px' }
            row.copy.toLowerCase().includes('cash') ? fontStyle = { textAlign: 'left', fontWeight: 'bold', fontSize: '12px', paddingLeft: 0, minWidth: '224px' } : fontStyle = { textAlign: 'left', fontWeight: 'none', paddingLeft: '18px', minWidth: '224px' }
            backgroundStyle = { backgroundColor: '#BFE0AE' };
            metricNameStyle = { ...fontStyle, ...paddingTopStyle };
            firstStyle = { ...paddingTopStyle, ...backgroundStyle };

            let valuePYMonthlyCommonSize = commonSizeValue({ value: olderYearYouVal, passedCalcs: calcsYouOldYear })
            let valuePYPeersMonthlyCommonSize = compareWith === 'Peers' ? commonSizeValue({ value: olderYearCompareVal, passedCalcs: calcsPeersOldYear }) : null;
            let valueCYMonthlyCommonSize = commonSizeValue({ value: newerYearYouVal, passedCalcs: calcsYou })
            let valueCYPeersMonthlyCommonSize = compareWith === 'Peers' ? commonSizeValue({ value: newerYearCompareVal, passedCalcs: calcsPeers }) : null;
            let comparisonPY = valuePYPeersMonthlyCommonSize !== '0.00';
            let comparisonCY = valueCYPeersMonthlyCommonSize !== '0.00';
            let displayRow = row.ytdView;
            if (row.ytdView) {
                if (row.key.includes('title') || row.key.includes('net')) {
                    displayRow = true;
                } else {
                    displayRow = comparisonPY && comparisonCY;
                }
            }

            // first section 2020, last section 2021
            if (emptyRowKeys.includes(row.copy)) {
                allRows.push(emptyRow(i, row.copy, row.checked, row.displayChart, firstStyle, paddingTopStyle))
            } else {
                let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

                allRows.push(
                    (
                        <tr key={i} style={displayRow ? borderTopStyle : { display: 'none' }} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'cash_flow', row.relatedCheckedKey) : null}>
                            {!props.pdf ? <td>{rowChecked}</td> : null}
                            <td className='account-column' style={metricNameStyle}>
                                {row.copy}
                            </td>
                            <Total
                                cssClass={'money-table-cell'}
                                value={olderYearYouVal}
                                style={firstStyle}
                            />
                            <td className='common-size-cell money-table-cell' style={firstStyle}>
                                {valuePYMonthlyCommonSize ? valuePYMonthlyCommonSize + '%' : '-'}
                            </td>
                            {compareWith === 'Peers'
                                ? <CommonSizeArrowsHelper
                                    cssClass={'money-table-cell'}
                                    totalValueYou={olderYearYouVal}
                                    totalValueCompare={olderYearCompareVal}
                                    style={firstStyle}
                                    keyArrBuilder={row.key}
                                    passedCalcs={calcsPeersOldYear}
                                />
                                : null}
                            {compareWith === 'Peers'
                                ? <td className='common-size-cell money-table-cell' style={firstStyle}>
                                    {valuePYPeersMonthlyCommonSize ? valuePYPeersMonthlyCommonSize + '%' : '-'}
                                </td>
                                : null}
                            <td style={paddingTopStyle}></td>
                            <Total
                                cssClass={''}
                                value={newerYearYouVal}
                                style={paddingTopStyle}
                            />
                            <td className='common-size-cell' style={paddingTopStyle}>
                                {valueCYMonthlyCommonSize ? valueCYMonthlyCommonSize + '%' : '-'}
                            </td>
                            {compareWith === 'Peers'
                                ? <CommonSizeArrowsHelper
                                    cssClass={''}
                                    totalValueYou={newerYearYouVal}
                                    totalValueCompare={newerYearCompareVal}
                                    style={paddingTopStyle}
                                    keyArrBuilder={row.key}
                                    passedCalcs={calcsPeers}
                                />
                                : null}
                            {compareWith === 'Peers'
                                ? <td className='common-size-cell' style={paddingTopStyle}>
                                    {valueCYPeersMonthlyCommonSize ? valueCYPeersMonthlyCommonSize + '%' : '-'}
                                </td>
                                : null}
                            <td style={paddingTopStyle}></td>
                            <Delta
                                valuePreviousYear={olderYearYouVal}
                                valueCurrentYear={newerYearYouVal}
                                style={firstStyle}
                            />
                        </tr>
                    )
                )
            }
        });
        return allRows;
    }


    const buildYTDCashFlow = () => {
        if (props.pdf) {
            let pageViewClass = 'portrait-view'
            return (
                <div className={pageViewClass}>
                    <div className='money-pdf-portrait'>
                        <div>
                            <h1 className='report-table-title'>{`Cash Flow - ${props.clientName}`}</h1>
                            <div className='money-pdf-table-div'>
                                <table className='report-table sheets' id='cash-flow-ytd-pdf'>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>{`YTD ${clientFirstYearMonth}/${props.year - 1}`}</td>
                                            <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>You</td>
                                            {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}></td> : null}
                                            {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>Peers</td> : null}
                                            <td></td>
                                            <td>{`YTD ${clientSecondYearMonth}/${props.year}`}</td>
                                            <td className='common-size-cell'>You</td>
                                            {compareWith === 'Peers' ? <td className='common-size-cell'></td> : null}
                                            {compareWith === 'Peers' ? <td className='common-size-cell'>Peers</td> : null}
                                            <td></td>
                                            <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>Δ Delta</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {buildRows()}
                                        <tr><td className='footer-cell'><i>{props.n < 20 ? `n < 20` : `n = ${props.n}`}</i></td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='money-report-card monthly-sheet'>
                    <div className='money-report-container'>
                        <table className='report-table sheets' id='cash-flow-ytd'>
                            <thead>
                                <tr>
                                    <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>
                                    <td></td>
                                    <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>{`YTD ${clientFirstYearMonth}/${props.year - 1}`}</td>
                                    <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>You</td>
                                    {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}></td> : null}
                                    {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>Peers</td> : null}
                                    <td></td>
                                    <td>{`YTD ${clientSecondYearMonth}/${props.year}`}</td>
                                    <td className='common-size-cell'>You</td>
                                    {compareWith === 'Peers' ? <td className='common-size-cell'></td> : null}
                                    {compareWith === 'Peers' ? <td className='common-size-cell'>Peers</td> : null}
                                    <td></td>
                                    <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>Δ Delta</td>
                                </tr>
                            </thead>
                            <tbody>
                                {buildRows()}
                            </tbody>
                            <tfoot>
                                <tr><td><i>n = {props.n ? props.n : 0}</i></td></tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )
        }
    }

    return buildYTDCashFlow()
}

export default MoneyYTDCashFlow;