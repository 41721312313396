$.fn.followTo = function (pos) {
    var $this = this,
        $window = $(window);

    $window.scroll(function (e) {
        if ($window.scrollTop() < pos) {
            $this.css({
                position: '-webkit-sticky',
                position: 'sticky',
                top: 20,
            });
        }
    });
};
