import React, { useState } from 'react';
import Modal from 'react-modal';

const DashboardViewsEditor = (props) => {
    let tableClass = 'my-dash-views-table-money'
    if (props.report === 'people' || props.page === 'people') {
        tableClass = 'my-dash-views-table-people'
    }
    if (props.report === 'customers' || props.page === 'customers') {
        tableClass = 'my-dash-views-table-customers'
    }
    return (
        <div>
            <h3>Edit Your Dashboard Views</h3>
            <p>Click Anywhere Outside of this Pop-up to Close</p>
            <table className={tableClass}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Who Can View</th>
                        <th>View Details</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {props.dashboardViewModalState.user_views.map((v, i) => {
                        return (<tr key={`dashview-${i}`}>
                                    <td style={{ 'textAlign': 'left' }}>{v.name}</td>
                                    <td>{v.private ? 'Only Me' : 'All Can View'}</td>
                                    <td>{v.permission_details === 'edit_and_view' ? 'Edit & View' : 'View Only'}</td>
                                    <td>                                        
                                        <button className='dashboard-view-setting-btn' onClick={() => props.handleDashboardViewModal('edit_view', v)}><i className='fa fa-pencil dashboard-view-edit-icon'></i></button>
                                    </td>
                                    <td>
                                        <button className='dashboard-view-setting-btn' onClick={(e) => props.deleteDashBoardView(e, v.id)}><i className='fa fa-trash-o dashboard-view-edit-icon'></i></button>
                                    </td>
                                </tr>)
                    })}
                </tbody>
            </table>
            <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('add_view')}>+ Add New View</button>
        </div>
    )
}

export default DashboardViewsEditor;