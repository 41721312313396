import React from 'react';

export default class Header extends React.Component {
  state = {
    open: false
  }

  handleClick = ({ target }) => {
    if (target.id != 'dont-open') {
      const clickListen = (e) => {
        if (this.props.header) {
          this.setState({ open: false })
          document.removeEventListener('click', clickListen)
        }
      }

      if (!this.state.open) {
        this.setState({ open: true })
        document.addEventListener('click', clickListen)
      }
    }
  }

  capitalize(string) {
    if (string) {
      const res = string.split('_').map(str => {
        const split = str.split('');
        split[0] = split[0].toUpperCase();
        return split.join('')
      })
      return res.join(' ');
    }
  }

  handleSelection = (prevOption, option) => {
    this.props.handleSelection(prevOption, option, this.props.column);
    this.setState({ open: false })
  }

  headerModal = (header) => {
    return (
      <div id='header-modal' >
        <div id='header-modal-options'>
          {this.props.options.map((option, i) => {
            return <div
              className='header-modal-option'
              key={i}
              onClick={() => this.handleSelection(header, option)}>
              {this.capitalize(option)}
            </div>
          })}
        </div>
      </div>
    )
  }

  render() {
    const { header } = this.props;

    if (header) {
      return (
        <td className='fdu-header-col' onClick={this.handleClick} >
          <span>{this.capitalize(header)}</span>
          <span className='remove-header' id='dont-open' onClick={() => this.props.removeHeader(header, this.props.column)} >{'\u00d7'}</span>
          {this.state.open ? this.headerModal(header) : null}
        </td>
      )
    } else {
      return (
        <td className='empty-header-col' onClick={this.handleClick} >
          Select Header
          {this.state.open ? this.headerModal(header) : null}
        </td>
      )
    }
  }
}
