import { utils } from 'xlsx';

const nonYears = ['account_number', 'description', 'credit', 'debit', "account_type"];
const accountMatches = ['account', 'account number', 'account id', 'account no'];
const descriptionMatches = ['description', 'account name', 'account description', 'name'];
const descriptionCaseWareMatch = ['account', 'name']
const pnlBalanceSheetDescriptions = ['year', 'years', 'description', 'account name', 'account description', 'name']
const debitMatches = ['debit', 'debits', 'debit amt'];
const creditMatches = ['credit', 'credits', 'credit amt'];
const accountTypeMatches = ['account type', 'type']
const yearArrMaker = () => {
  const yearsArr = [];
  const currentYear = new Date().getFullYear()
  let startYear = currentYear - 14;
  while (startYear <= currentYear) {
    yearsArr.unshift(startYear.toString());
    startYear++
  }
  return yearsArr
}
const years = yearArrMaker();
export default class Extractor {
  constructor({ workbook, fduId, headers, clientId, financialReportType }) {
    this.fduId = fduId;
    this.clientId = clientId;
    if (workbook) this.workbookToSheet(workbook);
    this.missingHeaders = [];
    this.subscribed = false;
    this.headers = headers || this.findHeaderCols();
    this.financialReportType = financialReportType
  }

  workbookToSheet = (workbook) => {
    const sheetName = workbook.SheetNames[0] == "QuickBooks Desktop Export Tips" ? workbook.SheetNames[1] : workbook.SheetNames[0];
    // ^^^ i don't like this code very much ^^^^
    const xlSheet = workbook.Sheets[sheetName];
    this.sheet = utils.sheet_to_json(xlSheet, { header: 1, raw: false });
  }

  findHeaderCols = () => {
    const headers = {};

    headers.account_number = this.findCellWhere({
      condition: this.isAccount,
      description: "account_number"
    });

    headers.description = this.findCellWhere({
      condition: this.isDescription,
      description: "description"
    });

    headers.debit = this.findCellWhere({
      condition: this.isDebit,
      description: "debit"
    });

    headers.credit = this.findCellWhere({
      condition: this.isCredit,
      description: "credit"
    });

    headers.account_type = this.findCellWhere({
      condition: this.isAccountType,
      description: "account_type"
    });

    headers.years = {};
    // Years needs to be in the same row not all over the place
    let yearRowNumbers = {};
    years.forEach((year) => {
      headers.years[year] = this.findYearCell({
        condition: (cell, row, column) => {
          let yearExists = this.isYear(cell, year);
          if (yearExists) {
            yearRowNumbers.hasOwnProperty(row) ? yearRowNumbers[row] += 1 : yearRowNumbers[row] = 1
          }
          return yearExists;
        },
        description: year
      });
    });
    let yearRowNumber = _.isEmpty(yearRowNumbers) ? null : Object.keys(yearRowNumbers).reduce((a, b) => yearRowNumbers[a] > yearRowNumbers[b] ? a : b);
    this.verifyYears(headers.years, yearRowNumber);
    this.handleQbd(headers);
    this.handleCasewareDescription(headers);
    this.postHeaders({ headers });
    console.log(headers, 'headers')
    return headers;
  }

  findCellWhere = ({ condition, header }) => {
    let row = 0;
    let column = 0;
    for (row; row <= 15; row++) {
      let rowLength = this.sheet[row] ? this.sheet[row].length : 0;
      for (column; column < rowLength; column++) {
        let value = this.sheet[row][column];
        if (condition(value, row)) return { row, column, value, found: true };
      }
      column = 0;
    }

    this.missingHeaders.push(header);
    return { row: null, column: null, value: null, found: false }
  }

  // Keep going through the cells instead of stopping once a date is found, because it could just be a random date with no values below it
  findYearCell = ({ condition, header }) => {
    let row = 0;
    let column = 0;
    let possibleYears = []
    for (row; row <= 15; row++) {
      let rowLength = this.sheet[row] ? this.sheet[row].length : 0;
      for (column; column < rowLength; column++) {
        let value = this.sheet[row][column];
        if (condition(value, row, column)) {
          possibleYears.push({ row, column, value, found: true })
        };
      }
      column = 0;
    }

    if (possibleYears.length > 0) {
      return possibleYears;
    } else {
      return [{ row: null, column: null, value: null, found: false }]
    }
  }

  isAccount = (cell = "") => {
    return this.findMatch(accountMatches, cell);
  }

  isDescription = (cell = "", headers = null) => {
    if (this.financialReportType === 'trial_balance') {
      return this.findMatch(descriptionMatches, cell);
    } else {
      return this.findMatch(pnlBalanceSheetDescriptions, cell);
    }
  }

  isDebit = (cell = "") => {
    return this.findMatch(debitMatches, cell);
  }

  isCredit = (cell = "") => {
    return this.findMatch(creditMatches, cell);
  }

  isAccountType = (cell = "") => {
    return this.findMatch(accountTypeMatches, cell);
  }

  findMatch = (matchList, cell = "") => {
    let cellFormated = cell.toLowerCase().trim()
    return matchList.includes(cellFormated);
  }

  isYear(cell = "", year) {
    const date = new Date(cell);
    let year2DigitEnd = year.substring(2)
    let yearExists = false;
    let cellAsNumber = Number.parseFloat(cell)
    let datePresent = date.toString() !== 'Invalid Date';
    if (cell.length == 4) {
      yearExists = parseInt(cell) == year;
      return yearExists;
    } else if (cell.endsWith(year2DigitEnd) && Number.isNaN(cellAsNumber)) {
      return true
    } else {
      if (parseInt(cell) && cell.includes(',') && cell.includes('.') && datePresent == true) {
        // Check money values with comma like 1,234.5 because they can be converted to dates and we don't want them to be.
        return false;
      }
      return date.getFullYear() == year;
    }
  }

  verifyYears = (yearRows = this.headers.years, yearRowNumber = '') => {
    let yearRow;
    let verifiedYears = {}
    years.forEach(year => {
      let yearObj = {}
      let possibleYears = yearRows[year];
      for (let i = 0; i < possibleYears.length; i++) {
        if (possibleYears[i] && possibleYears[i].row === parseInt(yearRowNumber)) {
          yearObj.row = possibleYears[i].row;
          yearObj.column = possibleYears[i].column;
          yearObj.value = possibleYears[i].value
          yearObj.found = true;
        }
      }

      if (_.isEmpty(yearObj)) {
        this.missingHeaders.push(year)
        return verifiedYears[year] = { row: null, column: null, value: null, found: false }
      } else {
        return verifiedYears[year] = yearObj
      }
    })

    years.forEach(year => {
      return yearRows[year] = verifiedYears[year]
    })
  }

  handleQbd = (headers = this.headers) => {
    if (headers.credit.found && headers.debit.found) {
      headers.qbd = true;
      const year = Object.keys(headers.years).find(key => headers.years[key].found);
      if (year) {
        headers.qbd_year = year;
        headers.years[year].row = null;
        headers.years[year].column = null;
      }
    };
  }

  handleCasewareDescription = (headers = this.headers) => {
    if (!headers.description.found) {
      headers.description = headers.account_number
      headers.account_number = { row: null, column: null, value: null, found: false }
    };
  }

  extract = () => {
    let valid = this.validateHeaders();
    if (valid) {
      this.rows = this.extractRows();

      if (this.rows.length > 0) {
        this.continueExtracting();
      } else {
        valid = false
        this.onExtractError(true)
      }
    }
    return valid;
  }

  continueExtracting = async () => {
    this.postRows(this.rows);
  }

  extractRows = (headers = this.headers) => {
    const headerMax = this.lastHeaderRow();
    let beginRowIndex = Number.isInteger(headerMax) ? headerMax + 1 : 0
    let groupFound, subgroupFound = null
    let groupName, subgroupName = null
    const rows = this.sheet.slice(beginRowIndex, this.sheet.length).map((row, i) => {
      const rowHash = {
        years: {}
      };
      rowHash.row = i + beginRowIndex;

      this.forEachHeader((key, header, isYear) => {
        let val = row[header.column];
        if (headers.qbd_year && key == "debit") {
          rowHash.years[headers.qbd_year] = rowHash.years[headers.qbd_year] || 0;
          rowHash.years[headers.qbd_year] += this.sanitizeNumber(val, true);
        } else if (headers.qbd_year && key == "credit") {
          rowHash.years[headers.qbd_year] = rowHash.years[headers.qbd_year] || 0;
          rowHash.years[headers.qbd_year] -= this.sanitizeNumber(val, true);
        } else if (isYear && header.found && val !== undefined) {
          rowHash.years[key] = this.sanitizeNumber(val);
        } else if (val) {
          rowHash[key] = val;
        }
      });
      groupFound = rowHash.account_number && rowHash.account_number.includes('Group')
      subgroupFound = rowHash.account_number && rowHash.account_number.includes('Subgroup')
      if (groupFound) { groupName = rowHash.description }
      if (subgroupFound) { subgroupName = rowHash.description }
      rowHash['group'] = groupName
      rowHash['subgroup'] = subgroupName
      // rowHash {years: {…}, row: 332, account_number: '9650', description: 'Accrued Interest', account_type: undefined} 
      if (!this.rowExclusions(rowHash) || groupFound) {
        return rowHash;
      }
    });
    return rows.filter(Boolean);
  }

  sanitizeNumber = (val, defaultZero = false) => {
    if (val) {
      val = val.replace("(", "-");
      val = val.replace(")", "");
      val = val.replace("$", "");
      val = val.replace(/,/g, "");
      return parseFloat(val) ? parseFloat(val) : 0;
    } else if (defaultZero) {
      return 0;
    }
  }

  lastHeaderRow = (headers = this.headers) => {
    const rows = [];
    this.forEachHeader((key, header) => {
      rows.push(header.row);
    });
    return Math.max(...rows);
  }

  validateHeaders = (headers = this.headers) => {
    let missing = false;
    const cols = [];
    // account must be found
    // if (!headers.account.found) {
    //   cols.push('Account');
    //   missing = true;
    // }

    // description must be found
    if (!headers.description.found) {
      cols.push('Description');
      missing = true;
    }

    // at least 1 year must be found
    const someYears = Object.keys(headers.years).some(year => {
      return headers.years[year].found;
    })

    if (!someYears) {
      cols.push('Years');
      missing = true;
    }

    if (missing) {
      fetch(`/companies/${this.clientId}/step`);
      this.onHeaderError(cols);
    }

    return !missing;
  }

  postRows = (rows = this.rows) => {
    this.postPayload(rows);
    this.subscribeExtraction();
  }

  headerList = (headers = this.headers) => {
    const headerList = [];
    this.forEachHeader((key, header) => {
      if (header.column > -1) headerList[header.column] = key;
    }, headers)
    return headerList;
  }

  forEachHeader = (callback, headers = this.headers) => {
    Object.keys(headers).forEach(key => {
      if (nonYears.includes(key)) {
        callback(key, headers[key], false);
      } else if (key === 'years') {
        Object.keys(headers[key]).forEach(year => {
          callback(year, headers[key][year], true);
        })
      }
    })
  }

  anyColumns = (row, callback) => {
    const keys = Object.keys(row);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (nonYears.includes(key)) {
        if (callback(key, row[key], false)) {
          return true;
        }
      } else if (key === 'years') {
        const allYearsExcluded = Object.keys(row[key]).every(year => {
          return callback(year, row[key][year], true);
        })
        if (allYearsExcluded) return true;
      }
    };
    return false;
  }

  rowExclusions = (row) => {
    return this.anyColumns(row, (key, column, isYear) => {
      if (isYear) {
        return !Number.isInteger(parseInt(column));
      } else {
        const isExcluded = [
          'account number',
          'subtotal : none',
          'subgroup : none',
          'account',
          'account name',
          'client:',
          'engagement:',
          'period ending:',
          'workpaper:',
          'trial balance:',
          'net (income) loss',
          'total'
        ].includes(column && column.toLowerCase());
        return isExcluded;
      };
    })
  }

  post = async (body) => {
    try {
      const response = await fetch(`/api/v1/financial_data_uploads/${this.fduId}`, {
        headers: {
          'X-CSRF-Token': window.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify({
          financial_data_upload: body
        })
      })
      console.log(response, 'r')
    } catch (error) {
      console.log(error);
      alert(
        "Sorry something went wrong. Try again later."
      );
    }
  }

  postHeaders = async ({ headers, update_client, update_firm }) => {
    this.post({ headers, update_client, update_firm });
  }

  postPayload = async (financial_data_rows) => {
    this.post({ financial_data_rows });
  }

  subscribeExtraction = async () => {
    this.subscribed = true
    App[`map_${this.fduId}`] = App.cable.subscriptions.create(
      {
        channel: 'JobsChannel',
        job_type: 'ExtractWorker',
      },
      {
        received: (res) => {
          if (res.error) {
            this.subscribed = false
            console.log(res);
            this.onExtractError(res.error);
          } else {
            const data = JSON.parse(res.data);
            if (data.fdu.id == this.fduId) {
              App[`map_${this.fduId}`].unsubscribe();
              this.subscribed = false
              if (res.finish) {
                this.onComplete(data.fdu);
                this.subscribed = false
              }
            }
          }
        }
      }
    )
  }
}
