import React from 'react';
import AccountTree from '../../../utils/AccountTree';
import { parseMoney, percentify } from '../../../utils'

const GrowthRate = (props) => {
  let changingGrowthRate = (((props.forecastedRevenue / props.previousRevenue) - 1) * 100).toFixed(2)
  let val, startGR, endGR = null

  if (props.selectedYear === 'year1') {
    val = props.value === NaN ? 0 : props.value
  } else {
    val = (((props.previousRevenue / props.twoYearsAgoRevenue) - 1) * 100).toFixed(2)
  }

  if (props.calendarYearEnd && !props.rolling12Touched) {
    startGR = `${props.year} Growth Rate: `
    endGR = `${props.year + 1} Growth Rate: `
  } else if (!props.calendarYearEnd && !props.rolling12Touched) {
    // Different Fiscal Year End
    startGR = `FY${props.year + 1} Growth Rate: `
    endGR = `FY${props.year + 2} Growth Rate: `
  } else if (props.rolling12Touched) {
    startGR = props.calendarYearEnd ? `${props.year} Growth Rate: ` : `FY${props.year + 1} Growth Rate: `
    endGR = 'Rolling 12 Growth Rate: '
  }

  return (
    <div className='growth-rate-container'>
      <div style={{ width: '250px' }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between', height: '20px', width: '100%', paddingRight: '5px', paddingTop: '5px' }}>
        <span>
          <strong>{startGR}</strong>{val}%
        </span>
        <span>
          <strong>{endGR}</strong>{changingGrowthRate}%
        </span>
      </div>
    </div>
  )
}

const MonthlyForecastSheetMaker = (props) => {
  const key = {
    pnl: {
      title: 'Profit & Loss'
    },
    balance_sheet: {
      title: 'Balance Sheet'
    }
  }
  let startYear, endYear = ''
  const months = Object.values(props.months);

  const selectedMonth = (i) => { 
    if (props.editMultipleMonths) {
      return i >= props.month ? 'current-month-col' : null
    } else {
      return props.month == i ? 'current-month-col' : null 
    }
  }

  const backgroundChanger = (i) => i < (props.blendMonth - 1) && props.isLiveData && props.year === new Date(Date.now()).getFullYear() - 1 && i !== props.month ? 'live-data-col' : null

  function getValue(set, key, sumKey) {
    if (set && set[key]) {
      return parseFloat(set[key])
    } else if (props.useSum) {
      return parseFloat(props.baselineRatios[sumKey]) / 100
    } else {
      return 0
    }
  }

  function buildRows() {
    const accountTree = new AccountTree({
      page: 'forecast',
      tree: props.tree[props.sheet]
    })
    const rows = []
    accountTree.forEach((row) => {
      if (row.money) rows.push(newBuildRow(row))
    })
    return rows
  }

  function newBuildRow(row) {
    const commonSizer = {
      pnl: 'revenue',
      balance_sheet: 'assets'
    }

    const key = `${row.key}_common_size_${commonSizer[props.sheet]}`
    const sumKey = row.key
    const youCurrentRatio = getValue(props.yearly, key, sumKey) * 100
    const peerCurrentRatio = props.peerAvgValues ? getValue(props.peerAvgValues, key, sumKey) * 100 : 0;
    const valueSet = projectionSetFor(row.key, props.valueSet)

    return dataToRow(row, youCurrentRatio, peerCurrentRatio, valueSet, row.i)
  }

  function projectionSetFor(key, valueSet) {
    return valueSet.map((vs) => {
      return vs[key] || 0
    })
  }

  function ratioSetFor(key, ratioSet) {
    return ratioSet.map((rs) => {
      return (rs[key] || 0) * 100
    })
  }

  function dataToRow(row, youCurrentRatio, peerCurrentRatio, projectionSet, i) {
    let underline
    if (row.boldedUnderline) {
      underline = 'solid 1px'
    } else if (row.doubleUnderline) {
      underline = 'double 1px'
    } else if (row.underline) {
      underline = 'solid 1px'
    }

    const copyStyle = {
      fontWeight: (row.bold ? 'bold' : ''),
      textAlign: 'left',
      borderBottom: underline,
      borderTop: row.topline ? 'solid 1px' : '',
      whiteSpace: 'nowrap'
    }

    const valueStyle = {
      textAlign: 'right',
      borderBottom: underline,
      borderTop: row.topline ? 'solid 1px' : ''
    }

    const youPercentStyle = { ...valueStyle }

    const peerPercentStyle = { ...valueStyle }

    props.displayPeerValues ? peerPercentStyle['backgroundColor'] = '#e2e0e0' : null;
    
    let previousYearTotalVal = props.yearly[row.key]
    if (!props.yearly[row.key]) {
      if (row.parentKey === 'total_revenue') {
        let total_revenue = props.yearly['total_revenue']
        previousYearTotalVal = props.yearly['total_revenue'] * props.yearly[row.key+'_common_size_revenue']
      }
    }

    return (
      <tr key={i}>
        <td className='year-rows row-name' style={copyStyle}>{row.padding + row.copy}</td>
        <td className='year-rows col-previous-year' style={valueStyle}>{!row.header || row.header_is_total ? parseMoney(previousYearTotalVal) : ''}</td>
        <td className='year-rows ratio-font baseline-percentage' style={youPercentStyle}>{!row.header || row.header_is_total ? percentify(youCurrentRatio) : ''}</td>
        {props.displayPeerValues ? <td className='year-rows peer-ratio-font baseline-percentage' style={peerPercentStyle}>{!row.header || row.header_is_total ? percentify(peerCurrentRatio) : ''}</td> : null}
        {months.map((_, i) =>
          <td
            key={i}
            className={'year-rows month-col ' + selectedMonth(i) + ' ' + backgroundChanger(i)}
            onClick={() => props.setMonth(i)}
            style={valueStyle}
          >
            {!row.header || row.header_is_total ? parseMoney(projectionSet[i]) : ''}
          </td>
        )}
        {props.sum
          ? <td className='year-rows' style={valueStyle}>{!row.header || row.header_is_total ? parseMoney(props.sum[row.key]) : ''}</td>
          : null}
        <td className='year-rows ratio-font' style={valueStyle}>{!row.header || row.header_is_total ? percentify(props.sumRatios[row.key]) : ''}</td>
      </tr>
    )
  }

  const sheet = key[props.sheet]['title']

  const rows = buildRows()

  const bold = {
    fontWeight: 'bold',
    textAlign: 'center'
  }

  const youPercentStyle = { ...bold }

  const peerPercentStyle = { ...bold }

  props.displayPeerValues ? peerPercentStyle['backgroundColor'] = '#e2e0e0' : '';

  if (props.calendarYearEnd && !props.rolling12Touched) {
    startYear = `${props.year}`
    endYear = `${props.year + 1}`
  } else if (!props.calendarYearEnd && !props.rolling12Touched) {
    // Different Fiscal Year End
    startYear = `FY${props.year + 1}`
    endYear = `FY${props.year + 2}`
  } else if (props.rolling12Touched) {
    startYear = props.calendarYearEnd ? `${props.year}` : `FY${props.year + 1}`
    endYear = 'Rolling 12'
  }

  return (
    <div>
      <div style={props.sheet === 'balance_sheet' ? { 'paddingTop': '30px' } : {}} className={`monthly-forecast-landscape report-card monthly-sheet`}>
        <div>
          <h1 className='report-table-title'>{`${props.year + 1} ${sheet} - ${props.clientName}`}</h1>
          <div className='pdf-table-div'>
            {props.sheet === 'pnl' ?
              <GrowthRate
                calendarYearEnd={props.calendarYearEnd}
                peerAvgValues={props.peerAvgValues}
                previousRevenue={props.previousRevenue}
                forecastedRevenue={props.forecastedRevenue}
                goalGrowthRate={props.goalGrowthRate}
                value={props.value}
                rolling12Touched={props.rolling12Touched}
                selectedYear={props.selectedYear}
                twoYearsAgoRevenue={props.twoYearsAgoRevenue || null}
                yearNumber={props.yearNumber}
                year={props.year}
              />
              : null
            }
            <table className='monthly-forecast-table' id={`${props.sheet}-sheet-${props.year + 1}`}>
              <thead>
                <tr>
                  <td className='row-name' />
                  <td className='print-year-rows col-previous-year' style={bold}>{startYear}</td>
                  <td className='print-year-rows ratio-font baseline-percentage' style={youPercentStyle}>Your %'s</td>
                  {props.displayPeerValues ? <td className='print-year-rows peer-ratio-font baseline-percentage' style={peerPercentStyle}>Peers %'s</td> : null}
                  {months.map((month, i) =>
                    <td
                      key={i}
                      className={`print-year-rows month-col ${selectedMonth(i)} ${backgroundChanger(i)}`}
                      style={bold}
                      onClick={() => props.setMonth(i)}
                    >{month}
                    </td>)}
                  {props.sum
                    ? <td className='print-year-rows' style={bold}>Forecast {endYear}</td>
                    : null}
                  <td className='print-year-rows ratio-font' style={bold}>Forecast %'s</td>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
      </div>
    </div>
  )
}

export default MonthlyForecastSheetMaker