import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { objReducer, calculateKFIMetric, KFIsCommonSizeArrows } from '../report_components/MoneyHelpers';
import { parseMoney } from '../../utils';

const MoneyYTDKFI = (props) => {
  const { calcs, previousYearCalcs } = useContext(Data);
  const { currentNewFilters } = useContext(Filters);
  const previousYearYTDYouTotalRevenue = calcs[props.year - 1] ? calcs[props.year - 1]['you']['total_revenue'] : undefined;
  const currentYearYTDYouTotalRevenue = calcs[props.year] ? calcs[props.year]['you']['total_revenue'] : undefined;
  let compareWith = currentNewFilters.compare_with;
  let firstYearMonth = previousYearYTDYouTotalRevenue ? Object.values(previousYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
  let secondYearMonth = currentYearYTDYouTotalRevenue ? Object.values(currentYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
  let compareFirstYearMonth = firstYearMonth < 10 ? '0' + firstYearMonth.toString() : firstYearMonth;
  let compareSecondYearMonth = secondYearMonth < 10 ? '0' + secondYearMonth.toString() : secondYearMonth;

  const formatPercent = (value, key) => {
    if (value || value === 0) {
      return (Math.round(value * 10000) / 100) + '%';
    } else {
      return '-'
    }
  };

  const formatRatio = (value) => {
    if (value || value === 0) {
      return `${value.toFixed(1)} to 1`;
    } else {
      return '-'
    }
  };

  const formatDays = (value) => {
    if (value || value === 0) {
      return `${value.toFixed(1)} days`;
    } else {
      return '-'
    }
  };

  const formatTwoDecimal = (value) => {
    if (value || value === 0) {
      return value.toFixed(2);
    } else {
      return '-'
    }
  };

  const rowFormatter = (amount, key) => {
    const ratio = ['quick_ratio', 'debt_ratio', 'operating_cash_flow_kfi', 'current_ratio', 'net_working_capital_ratio', 'debt_to_equity', 'senior_debt_to_ebitda', 'ebitda_to_annual_interest']
    const days = ['days_cash_on_hand', 'ar_days', 'ap_days']
    const money = ['net_working_capital', 'operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization', 'ebitda', 'total_ebitda']
    const twoDecimal = ['asset_turnover_ratio', 'inventory_turnover_ratio']
    const percent = ['total_revenue_growth_rate', 'net_profit', 'cash_flow_margin', 'roa', 'roe', 'roce', 'gross_profit_common_size_revenue', 'operating_profit_common_size_revenue', 'gross_profit', 'operating_profit']
    if (_.isNumber(amount)) {
      if (ratio.includes(key)) {
        return formatRatio(amount)
      } else if (days.includes(key)) {
        return formatDays(amount)
      } else if (money.includes(key)) {
        return parseMoney(amount);
      } else if (twoDecimal.includes(key)) {
        return formatTwoDecimal(amount)
      } else if (percent.includes(key)) {
        return formatPercent(amount, key)
      } else {
        return '-'
      };
    } else {
      return ''
    }
  };

  function CommonSizeArrowsHelper(valueYou, valueCompareTo, keyArrBuilder) {

    let arrow;
    if (valueYou) {
      arrow = KFIsCommonSizeArrows(valueYou, valueCompareTo, keyArrBuilder)
      if (arrow === 'better' || arrow === 'best') {
        arrow = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
      } else if (arrow === 'worse' || arrow === 'worst') {
        arrow = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
      } else {
        arrow = ''
      }
      return arrow
    }
    return arrow
  }

  const buildRows = () => {
    return props.rows.map((row, i) => {
      let paddingLeft = { paddingLeft: row.paddingLeft }
      let paddingTop = row['parentKey'] ? {} : { paddingTop: '20px' }
      let borderBottom = { borderBottom: '2px solid #000000' }
      let titleStyle = row['parentKey'] ? {} : { ...paddingLeft, ...borderBottom }
      let hideRow = '';
      let zeroYears = [];
      const rowValues = {}
      // hide unused rows
      props.yearRange.forEach(year => {
        const yearData = calcs[year];
        if (!yearData) return;
      
        const youMetric = calculateKFIMetric(row.key, yearData['you'], previousYearCalcs['monthly'][year - 1]['you'], previousYearCalcs['monthly_full_year'][year - 1]['you']);
        const peersMetric = calculateKFIMetric(row.key, yearData['avg'], previousYearCalcs['monthly'][year - 1]['avg'], previousYearCalcs['monthly_full_year'][year - 1]['avg']);
        rowValues[year] = {
          you: youMetric, 
          compare: peersMetric
        }

        const youKfiMetric = youMetric === '0.00' || youMetric === null;
        const peersKfiMetric = peersMetric === '0.00' || peersMetric === null;
      
        if (youKfiMetric && peersKfiMetric) zeroYears.push(year);
      });

      zeroYears.length === props.yearRange.length ? hideRow = row.key : null

      if (row.key === 'ebitda') {
        titleStyle = { borderBottom: '1px solid black' }
        paddingTop['paddingTop'] = 0
      } else if (row.key === hideRow) {
        titleStyle = { display: 'none' }
      }

      let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

      return (
        <tr key={Math.random(89)} style={titleStyle} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'kfis', row.relatedCheckedKey) : null}>
          {!props.pdf ? <td>{rowChecked}</td> : null}
          <td className='table-copy' style={paddingTop}>{row.padding + row.copy}</td>
          {props.yearRange.map((year, ii) => {
            let arrowCellClass = 'common-size-arrow';
            let backgroundStyle = {}
            let commonSizeValCellClass = 'common-size-cell';
            let valueCellClass = '';
            if (ii % 2 === 0) {
              arrowCellClass += ' money-table-cell';
              backgroundStyle['backgroundColor'] = '#BFE0AE';
              commonSizeValCellClass += ' money-table-cell';
              valueCellClass = 'money-table-cell';
            }
            try {
              if (row['parentKey'] !== 'parent') {
                let arrowColumn = ''
                let youValue, avgValue;
                youValue = rowValues[year]['you']
                if (compareWith === 'Peers') {
                  avgValue = calcs[year] ? rowValues[year]['compare'] : undefined;
                  arrowColumn = CommonSizeArrowsHelper(youValue, avgValue, row.key)
                }

                return (
                  <React.Fragment key={Math.random(89)}>
                    <td className={valueCellClass} style={backgroundStyle}>
                      {rowFormatter(youValue, row.key)}
                    </td>
                    {compareWith === 'Peers'
                      ? <td className={arrowCellClass} style={{ ...backgroundStyle, 'textAlign': 'center' }}>
                        {arrowColumn}
                      </td>
                      : null}
                    {compareWith === 'Peers'
                      ? <td className={commonSizeValCellClass} style={backgroundStyle}>
                        {rowFormatter(avgValue, row.key)}
                      </td>
                      : null}
                    <td></td>
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={Math.random(89)}>
                    <td className={valueCellClass} style={{ ...backgroundStyle, 'borderBottom': '2px solid #000000' }}></td>
                    {compareWith === 'Peers' ? <td className={arrowCellClass} style={{ ...backgroundStyle, 'borderBottom': '2px solid #000000' }}></td> : null}
                    {compareWith === 'Peers' ? <td className={commonSizeValCellClass} style={{ ...backgroundStyle, 'borderBottom': '2px solid #000000' }}></td> : null}
                    <td style={{ 'borderBottom': '2px solid #000000' }}></td>
                  </React.Fragment>
                )
              }
            } catch { return <td key={Math.random(89)}></td>; }
          })}
        </tr>
      )
    })
  };

  const renderYearHeaderColumns = () => {
    return (
      <thead>
        <tr>
          {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
          <td></td>
          <td colSpan={compareWith === 'Peers' ? '3' : '1'} className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>{`YTD ${compareFirstYearMonth}/${props.year - 1}`}</td>
          <td></td>
          <td colSpan={compareWith === 'Peers' ? '3' : '1'}>{`YTD ${compareSecondYearMonth}/${props.year}`}</td>
        </tr>
        <tr>
          {!props.pdf ? <td></td> : null}
          <td></td>
          <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>You</td>
          {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}></td> : null}
          {compareWith === 'Peers' ? <td className='common-size-cell money-table-cell' style={{ backgroundColor: '#BFE0AE' }}>Peers</td> : null}
          <td></td>
          <td className='common-size-cell'>You</td>
          {compareWith === 'Peers' ? <td className='common-size-cell'></td> : null}
          {compareWith === 'Peers' ? <td className='common-size-cell'>Peers</td> : null}
        </tr>
      </thead>
    )
  }

  const buildYTDKFI = () => {
    if (props.pdf) {
      let pageViewClass = 'portrait-view'
      // let pageViewClass = 'landscape-view'
      return (
        <div className={pageViewClass}>
          <div className='money-pdf-portrait'>
            <div>
              <h1 className='report-table-title'>{`Key Financial Indicators - ${props.clientName}`}</h1>
              <div className='money-pdf-table-div'>
                <table className='report-table sheets' id='kfi-table-ytd-pdf'>
                  {renderYearHeaderColumns()}
                  <tbody>
                    {buildRows()}
                    <tr><td className='footer-cell'><i>{props.n < 20 ? `n < 20` : `n = ${props.n}`}</i></td></tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='money-report-card monthly-sheet'>
          <div className='money-report-container'>
            <table className='report-table sheets' id='kfi-table-ytd'>
              {renderYearHeaderColumns()}
              <tbody>
                {buildRows()}
              </tbody>
              <tfoot>
                <tr><td><i>n = {props.n ? props.n : 0}</i></td></tr>
              </tfoot>
            </table>
          </div>
        </div>
      )
    }
  }

  return buildYTDKFI()
};

export default MoneyYTDKFI;