import React, { useContext } from 'react';
import { Data } from '../contexts';
import { kfiTitleKeys } from '../report_components/MoneyHelpers';
import { parseMoney } from '../../utils/index';

const IndustryKFI = (props) => {
  const { calcs } = useContext(Data);
  const avg = calcs.avg
  const avgExists = Object.keys(avg).length > 0 ? true : false;
  const avgTotalCurrentAssets = avg['total_current_assets'];
  const avgTotalCurrentLiabilities = avg['total_current_liabilities'];
  const avgNetIncome = avg['operating_expenses_net_income'];
  const avgInterest = avg['interest_and_other_expenses'];
  const avgTaxesFS = avg['total_other_costs_taxes_- federal & state'];
  const avgDeprAmor = avg['depreciation_and_amortization'];
  const avgLongTermDebt = avg['long_term_debt'];
  const avgLineOfCredit = avg['line_of_credit'];
  const avgCurrentPortionOfLTD = avg['current_portion_of_ltd'];

  const calculateNetWorkingCapital = (currentTotalAssets, currentTotalLiabilities) => {
    let netWorkingCapital = {};
    props.yearRange.forEach((y) => {
      if (currentTotalAssets && currentTotalAssets.hasOwnProperty(y)) {
        netWorkingCapital[y] = currentTotalAssets[y] - currentTotalLiabilities[y];
      }
    })
    return netWorkingCapital;
  }

  const calculateDebtToEquity = (longTermDebt, netWorth) => {
    let debtToEquity = {};
    let setLongTermDebt, setNetWorth = 0
    props.yearRange.forEach((y) => {
      longTermDebt ? setLongTermDebt = longTermDebt[y] : null
      netWorth ? setNetWorth = netWorth[y] : null
      debtToEquity[y] = longTermDebt ? setLongTermDebt / setNetWorth : null
    })
    return debtToEquity;
  }

  // Need to calculate the ebitda correctly with taxes for you and avg values and calulations that use it
  const calculateEBITDA = (netIncome, interest, taxesFS, deprAmor) => {
    let ebitda = {}
    let setNetIncome, setInterest, setTaxesFS, setDeprAmor = 0

    props.yearRange.forEach((y) => {
      netIncome ? setNetIncome = netIncome[y] : null
      interest ? setInterest = interest[y] : null
      taxesFS ? setTaxesFS = taxesFS[y] : null
      deprAmor ? setDeprAmor = deprAmor[y] : null
      ebitda[y] = setNetIncome + setInterest + setTaxesFS + setDeprAmor
    })
    return ebitda
  }

  const calculateSeniorDebtToEBITDA = (longTermDebt, lineOfCredit, currentPortionOfLTD, ebitda) => {
    let seniorDebtToebitda = {}
    if (longTermDebt && lineOfCredit && currentPortionOfLTD && ebitda) {
      props.yearRange.forEach((y) => {
        if (ebitda[y] === 0 || ebitda[y] === null) {
          seniorDebtToebitda[y] = null
        } else {
          seniorDebtToebitda[y] = (longTermDebt[y] + lineOfCredit[y] + currentPortionOfLTD[y]) / ebitda[y]
        }
      })
      return seniorDebtToebitda
    }
    return seniorDebtToebitda
  }

  const calculateEBITDAtoAnnualInterest = (ebitda, annualInterest) => {
    let ebitdaToAnnualInterest = {}

    if (ebitda && annualInterest) {

      props.yearRange.forEach((y) => {

        if (annualInterest[y] === 0 || annualInterest[y] === null) {
          ebitdaToAnnualInterest[y] = null
        } else {
          ebitdaToAnnualInterest[y] = ebitda[y] / annualInterest[y]
        }
      })
      return ebitdaToAnnualInterest
    }
    return ebitdaToAnnualInterest
  }

  const avgNetWorkingCapital = avgExists ? calculateNetWorkingCapital(avgTotalCurrentAssets, avgTotalCurrentLiabilities) : null;

  const avgDebtToEquity = avgExists ? calculateDebtToEquity(avgLongTermDebt, avg['net_worth']) : null;

  const avgEBITDA = avgExists ? calculateEBITDA(avgNetIncome, avgInterest, avgTaxesFS, avgDeprAmor) : null;

  const avgSeniorDebtToEBITDA = avgExists ? calculateSeniorDebtToEBITDA(avgLongTermDebt, avgLineOfCredit, avgCurrentPortionOfLTD, avgEBITDA) : null;

  const avgEBITDAtoAnnualInterest = avgExists ? calculateEBITDAtoAnnualInterest(avgEBITDA, avgInterest) : null;

  const formatPercent = (value, key) => {
    if (value || value === 0) {
      return (Math.round(value * 10000) / 100) + '%';
    } else {
      return '-'
    }
  };

  const formatRatio = (value) => {
    if (value || value === 0) {
      return `${value.toFixed(1)} to 1`;
    } else {
      return '-'
    }
  };

  const formatDays = (value) => {
    if (value || value === 0) {
      return `${value.toFixed(1)} days`;
    } else {
      return '-'
    }
  };

  const formatTwoDecimal = (value) => {
    if (value || value === 0) {
      return value.toFixed(2);
    } else {
      return '-'
    }
  };

  const rowFormatter = (amount, key) => {
    const ratio = ['current_ratio', 'net_working_capital_ratio', 'debt_to_equity', 'senior_debt_to_ebitda', 'ebitda_to_annual_interest']
    const days = ['ar_days', 'ap_days']
    const money = ['net_working_capital', 'operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization', 'total_ebitda']
    const twoDecimal = ['asset_turnover_ratio', 'inventory_turnover_ratio']
    const percent = ['roa', 'roe', 'roce', 'gross_profit_common_size_revenue', 'operating_profit_common_size_revenue']

    if (ratio.includes(key)) {
      return formatRatio(amount)
    } else if (days.includes(key)) {
      return formatDays(amount)
    } else if (money.includes(key)) {
      return parseMoney(amount);
    } else if (twoDecimal.includes(key)) {
      return formatTwoDecimal(amount)
    } else if (percent.includes(key)) {
      return formatPercent(amount, key)
    } else {
      return '-'
    };
  };

  const buildRows = () => {
    let kfiKeys = kfiTitleKeys();
    return kfiKeys.map((row, i) => {
      let paddingLeft = { paddingLeft: row.paddingLeft }
      let paddingTop = { paddingTop: '20px' }
      let borderBottom = { borderBottom: '2px solid #000000' }
      let titleStyle = row['parentKey'] === 'parent' ? { ...paddingLeft, ...borderBottom, ...paddingTop } : {}

      let hideRow = '';
      let zeroYears = [];

      zeroYears.length === props.yearRange.length ? hideRow = row.key : null

      let rowStyle = {}
      if (row.key === 'total_ebitda') {
        rowStyle = { borderTop: '1px solid black' }
      } else if (row.key === hideRow) {
        rowStyle = { ...rowStyle, display: 'none' }
      }

      return (
        <tr key={Math.random(i)} style={rowStyle}>
          <td className='table-copy industry-copy-cell' style={titleStyle}>{row.title}</td>
          {props.yearRange.map((year, ii) => {
            let cellClass = '';
            if (ii % 2 === 0) {
              cellClass = 'money-table-cell';
            }
            let titleRowStyles = { 'borderBottom': '2px solid #000000' };
            let arrowStyles = { 'textAlign': 'center' };
            try {
              if (row['parentKey'] !== 'parent') {
                let avgValue;
                if (row.key === 'net_working_capital') {
                  avgValue = avgExists ? avgNetWorkingCapital[year] : null;
                } else if (row.key === 'debt_to_equity') {
                  avgValue = avgExists ? avgDebtToEquity[year] : null;
                } else if (row.key === 'total_ebitda') {
                  avgValue = avgExists ? avgEBITDA[year] : null;
                } else if (row.key === 'senior_debt_to_ebitda') {
                  avgValue = avgExists ? avgSeniorDebtToEBITDA[year] : null;
                } else if (row.key === 'ebitda_to_annual_interest') {
                  avgValue = avgExists ? avgEBITDAtoAnnualInterest[year] : null;
                } else {
                  avgValue = avgExists ? avg[row.key][year] : null;
                }

                return (
                  <React.Fragment key={ii + row.key}>
                    <td className={cellClass}>
                      {rowFormatter(avgValue, row.key)}
                    </td>
                    <td></td>
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={Math.random(ii)}>
                    <td className={cellClass} style={titleRowStyles}></td>
                    <td style={{ 'borderBottom': '2px solid #000000' }}></td>
                  </React.Fragment>
                )
              }
            } catch { return (<td key={Math.random(ii)} className={cellClass}></td>) }
          })}
        </tr>
      )
    })
  };

  const buildNumOfCompaniesRow = () => {
    return (
      <tr key={`number-of-companies-${props.page}`} id={`${props.chart}-report-row-${props.page}`} >
        <td className="industry-copy-cell">Number of Companies</td>
        {props.yearRange.map((year, ii) => {
          let backgroundStyle = '';
          let numOfCompanies = calcs['avg']['number_of_companies'][year] || 0

          if (ii % 2 === 0) {
            backgroundStyle = 'money-table-cell';
          }

          return (
            <React.Fragment key={Math.random(80)}>
              <td key={ii} className={backgroundStyle}>
                {`n = ${numOfCompanies}`}
              </td>
              <td></td>
            </React.Fragment>
          )
        })}
      </tr>
    )
  }

  const renderYearHeaderColumns = (years) => {
    let youPeersRow = [];
    let formatedYearsRow = [];

    years.forEach((y, i) => {
      let cellClasses = 'common-size-cell ';

      if (i % 2 === 0) {
        cellClasses += 'money-table-cell';
      }

      formatedYearsRow.push(
        <React.Fragment key={Math.random(i)}>
          <td className={cellClasses}>{y}</td>
          <td className='space-cell'></td>
        </React.Fragment>
      )
    })

    return (
      <thead>
        <tr key={Math.random(20)}>
          <td></td>
          {formatedYearsRow}
        </tr>
      </thead>
    )
  }

  return (
    <div>
      <div className={`monthly-forecast-landscape report-card monthly-sheet`}>
        <div style={{ 'paddingTop': '10px' }}>
          <h1 className='report-table-title hide-not-on-print'>Key Financial Indicators - Money</h1>
          <div className='pdf-table-div industry-table-container'>
            <table className='report-table' id='annual-kfi-table'>
              {renderYearHeaderColumns(props.yearRange)}
              <tbody>
                {buildRows()}
                {buildNumOfCompaniesRow()}
              </tbody>
            </table>
          </div>
          <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
        </div>
      </div>
    </div>
  )
};

export default IndustryKFI;
