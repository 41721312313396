import React, { useState } from 'react';
import AccountTree from '../utils/AccountTree';

const pageSettings = {
  map: {
    bold: true,
    pad: true,
  },
  money: {},
  people: {},
  customers: {},
  forecast: {
    bold: true,
    underline: true
  }
}
const EditChartOfAccountsPreview = ({ tree, map_list }) => {
  const [page, setPage] = useState('map');

  function buildRow(row) {
    const settings = pageSettings[page];

    if (row.padRow) return (
      <tr key={row.i} >
        <td>{'\u00A0'}</td>
      </tr>
    )


    let underline;
    if (row.boldedUnderline) {
      underline = 'solid 3px';
    } else if (row.doubleUnderline) {
      underline = 'double 3px';
    } else if (row.underline) {
      underline = 'solid 1px';
    }

    const copyStyle = {
      fontWeight: settings.bold && row.bold ? 'bold' : '',
      fontStyle: row.fontStyle ? row.fontStyle : '',
    }

    const rowStyle = {
      borderBottom: row.borderBottom ? row.borderBottom : underline,
      borderTop: row.topline ? '1px solid black' : ''
    }
    let finalCopy = row.no_left_padding ? row.copy : row.padding + row.copy;

    return (
      <tr key={row.i} style={rowStyle} >
        <td style={copyStyle} >{finalCopy}</td>
        <td>{row.header && !row.header_is_total ? '' : '$100'}</td>
      </tr>
    );
  }

  const pnl = new AccountTree({
    tree: tree.pnl,
    page: page == 'map' ? null : page,
    pad: page == 'map',
  });

  const pnlRows = [];
  pnl.forEach((row) => {
    pnlRows.push(buildRow(row));
  });

  const balanceSheet = new AccountTree({
    tree: tree.balance_sheet,
    page: page == 'map' ? null : page,
    pad: page == 'map',
  });

  const bsRows = [];
  balanceSheet.forEach((row) => {
    bsRows.push(buildRow(row));
  });

  const cashFlowSheet = new AccountTree({
    tree: tree.cash_flow,
    page: page == 'map' ? null : page,
    pad: page == 'map',
  });

  const cashFlowRows = [];
  cashFlowSheet.forEach((row) => {
    cashFlowRows.push(buildRow(row));
  });

  const kfisSheet = new AccountTree({
    tree: tree.kfis,
    page: page == 'map' ? null : page,
    pad: page == 'map',
  });

  const kfisRows = [];
  kfisSheet.forEach((row) => {
    kfisRows.push(buildRow(row));
  });

  const kpiPeopleSheet = new AccountTree({
    tree: tree.kpi_people,
    page: page == 'map' ? null : page,
    pad: page == 'map',
  });

  const kpiPeopleRows = [];
  kpiPeopleSheet.forEach((row) => {
    kpiPeopleRows.push(buildRow(row));
  });

  return (
    <div id='coa-preview' style={{ height: `${window.innerHeight - 50}px` }}>
      <MapList map_list={map_list} />
      <div className='coa-tabs'>
        {['map', 'money', 'people', 'customers', 'forecast'].map((_page_, i) => (
          <div key={i} className={_page_ == page ? 'current-coa-tab' : ''} onClick={() => setPage(_page_)} >{_page_.toUpperCase()}</div>
        ))}
      </div>
      <h3>P & L</h3>
      <table className='coa-child' ><tbody>{pnlRows}</tbody></table>
      <h3>Balance Sheet</h3>
      <table className='coa-child'><tbody>{bsRows}</tbody></table>
      <h3>Cash Flow</h3>
      <table className='coa-child'><tbody>{cashFlowRows}</tbody></table>
      <h3>KFI's</h3>
      <table className='coa-child'><tbody>{kfisRows}</tbody></table>
      <h3>KPI (People)</h3>
      <table className='coa-child'><tbody>{kpiPeopleRows}</tbody></table>
    </div>
  )
}

const MapList = ({ map_list }) => (
  <select>
    {map_list.map((p, i) => (
      <optgroup label={p[0]} key={i}>
        {p[1].map((c, ii) => (
          <option key={ii}>
            {c}
          </option>
        ))}
      </optgroup>
    ))}
  </select>
)

export default EditChartOfAccountsPreview;
