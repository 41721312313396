import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const QualifiedCalcsInfo = (props) => {
    const getMuiTheme = createTheme({
        components: {
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        '&:last-child': {
                            paddingRight: '20px',
                        }
                    },
                    selectLabel: {
                        fontSize: '12px',
                        margin: 0
                    },
                    displayedRows: {
                        fontSize: '12px',
                        margin: 0
                    }
                }
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    contentWrapper: {
                        justifyContent: 'center'
                    },
                    data: {
                        fontSize: '14px',
                        paddingBottom: 0,
                        textTransform: 'none'
                    },
                    fixedHeader: {
                        zIndex: 0,
                    },
                    toolButton: {
                        justifyContent: 'center'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none !important',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        order: 2
                    }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        order: 1
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        fontSize: '25px',
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        margin: '20px'
                    }
                }
            },
            MUIDataTableResize: {
                styleOverrides: {
                    root: {
                        zIndex: 0,
                    }
                }
            },
            MUIDataTableSelectCell: {
                styleOverrides: {
                    headerCell: {
                        zIndex: 0,
                    },
                    fixedLeft: {
                        zIndex: 0,
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        zIndex: 1,
                    },
                    root: {
                        fontSize: '14px',
                        padding: '2px',
                        textAlign: 'center',
                    }
                }
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        zIndex: 0,
                        minHeight: '20px',
                        paddingLeft: '5px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:nth-child(even)': {
                            backgroundColor: '#F5F5F5',
                        },
                        '&$hover:hover': {
                            backgroundColor: '#BFE0AE',
                        },
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                    },
                    body1: {
                        fontSize: '16px',
                    },
                    body2: {
                        fontSize: '12px',
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '1.2em'
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        fontSize: '0.95em'
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '1.1em'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '0.9em'
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        fontSize: '1em'
                    }
                }
            }
        }
    });

    const columnBuilder = () => {
        let tableCols = [
            // column 0
            {
                name: 'code',
                label: 'NAICS Code',
                options: {
                    filter: false,
                    sort: true,
                    sortThirdClickReset: true,
                    setCellProps: () => ({
                        style: {
                            maxWidth: "50px",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }
                    }),
                }
            },
            {
                name: 'name',
                label: 'NAICS Name',
                options: {
                    filter: false,
                    sort: true,
                    sortThirdClickReset: true,
                    setCellProps: () => ({
                        style: {
                            textAlign: "left",
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }
                    })
                }
            }
        ]

        props.years.forEach((y) => {
            tableCols.push({
                name: y.toString(),
                label: y.toString(),
                options: {
                    filter: false,
                    sort: true,
                    sortThirdClickReset: true,
                }
            })
        })

        return tableCols;
    }

    const columns = columnBuilder()

    const options = {
        selectableRows: 'none',
        print: false,
        download: false,
        filter: false,
        responsive: 'standard',
        resizableColumns: props.qualified_calcs_info[0] ? true : false,
        rowsPerPage: 100,
        rowsPerPageOptions: [25, 50, 100],
        count: props.qualified_calcs_info.count,
        confirmFilters: true,
        serverSide: false,
        onTableChange: (action) => {
            switch (action) {
                default:
                    break;
            }
        }
    };

    return (
        <div>
            <h1>Number of Yearly Qualified Calcs Included in Aggregate per NAICS (Past 5 Years)</h1>
            <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable data={props.qualified_calcs_info} columns={columns} options={options} />
            </ThemeProvider>
        </div>

    )
}

export default QualifiedCalcsInfo;