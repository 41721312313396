import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const UserDashboardPreferences = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    };

    const Checkbox = ({ child }) => {
        let labelClass = 'dashboard-form-check-label'

        return (
            <div key={child.key} className='dashboard-form-check'>
                <label className={labelClass}>
                    <input
                        type='checkbox'
                        checked={child.checked}
                        onChange={() => props.handleDashboardSelections(child)}
                        className='form-check-input'
                    />
                    <span>{child.label}</span>
                </label>
            </div>
        )
    }

    let selections = props.preferences.filter(p => p.displaySelection)
    let groupedSelections = {}
    selections.forEach(s => {
        let parent = s.parentType
        if (groupedSelections[parent]) {
            groupedSelections[parent].push(s)
        } else {
            groupedSelections[parent] = []
            groupedSelections[parent].push(s)
        }
    })

    return (
        <>
            <button className='dash-item-edit-btn' onClick={openModal}>My Preferences</button>
            <Modal
                className={{
                    base: 'dashboard-modal-content hide-on-print',
                    afterOpen: 'dashboard-modal-content_after-open hide-on-print',
                    beforeClose: 'dashboard-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'dashboard-overlay-base hide-on-print',
                    afterOpen: 'dashboard-overlay-base_after-open hide-on-print',
                    beforeClose: 'dashboard-overlay-base_before-close hide-on-print',
                }}
                ariaHideApp={false}
                closeTimeoutMS={0}
                contentLabel='User Dashboard Preferences Modal'
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                <h3>Select Items to Display on Your Dashboard</h3>
                <p>Click Anywhere Outside of this Pop-up to Close</p>
                <div className='edit-modal-btn-section'>
                    <button key='select-all-btn' className='btn dashboard-save-btn' onClick={() => props.handleSelectAll(true)}>Select All</button>
                    <button key='unselect-all-btn' className='btn dashboard-save-btn' onClick={() => props.handleSelectAll(false)}>Unselect All</button>
                </div>
                <form onSubmit={(e) => props.updateDashboardView(e)}>
                    <div className='user-dashboard-checkbox-section'>
                        {Object.keys(groupedSelections).map((parentKey, i) => {
                            return (
                                <div key={'dashitem' + i} className='form-section'>
                                    <p>{parentKey}</p>
                                    <React.Fragment>
                                        {groupedSelections[parentKey].map((child, i) => {
                                            return (<Checkbox key={child.key} child={child} />)
                                        })}
                                    </React.Fragment>
                                </div>
                            )
                        })}
                    </div>
                    <div className='dashboard-form-btn-section'>
                        <button className='btn dashboard-save-btn'>Save Changes</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default UserDashboardPreferences;