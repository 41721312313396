import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { parseMoney } from "../../utils";

const TrendChart = (props) => {
    const [dataType, setDataType] = useState('all')
    const [allData, setAllData] = useState(props.naics_trends_data || [])
    const [data, setData] = useState({
        topThird: [],
        midThird: [],
        botThird: []
    })
    const [linesChart, setLinesChart] = useState(false)

    useEffect(() => {
        setDataType('all')
        let naicsDataAll = _.cloneDeep(props.naics_trends_data)
        setAllData(naicsDataAll)
        setData(() => {
            let values = []
            let topData = []
            let midData = []
            let botData = []

            naicsDataAll.forEach((n) => {
                // get selected filter year or last value of 5 year arrays
                if (props.type === 'number') {
                    values.push([n.naics, n.submitted_count[4]])
                } else {
                    values.push([n.naics, n.revenue[4]])
                }
            })

            let valuesSorted = values.sort((a, b) => b[1] - a[1])
            let thirdNum = Math.round(naicsDataAll.length / 3)
            valuesSorted.forEach((v, i) => {
                let naicsData = naicsDataAll.find((n) => n['naics'] === v[0])
                if (naicsData && i < thirdNum) {
                    topData.push(naicsData)
                }

                if (naicsData && thirdNum <= i && i <= thirdNum * 2) {
                    midData.push(naicsData)
                }

                if (naicsData && thirdNum * 2 <= i && i <= thirdNum * 3) {
                    botData.push(naicsData)
                }
            })
            return {
                topThird: topData,
                midThird: midData,
                botThird: botData
            }
        })
    }, [props])

    const years = [props.year - 4, props.year - 3, props.year - 2, props.year - 1, props.year]

    const format = (format, num) => {
        switch (format) {
            case 'percent':
                return ` ${Highcharts.numberFormat((num * 100), 2, '.', ',')}%`;
            case 'decimal':
                return Highcharts.numberFormat(num, 2);
            case 'ratio':
                return ` ${Highcharts.numberFormat(num, 2, '.', ',')}:1`;
            case 'money':
                return num >= 0 ? ` $${Highcharts.numberFormat(num, 0, '.', ',')}` : ` -$${Highcharts.numberFormat(Math.abs(num), 0, '.', ',')}`;
            case 'wholeNumber':
                return Math.round(num)
        }
    }

    let lineChartOptions = () => {
        let finalData = []
        let formatType = props.type === 'number' ? 'wholeNumber' : 'money'
        let dataSet = allData
        let chartType = linesChart ? 'line' : 'column'

        if (dataType === 'topThird') {
            dataSet = data.topThird
        } else if (dataType === 'midThird') {
            dataSet = data.midThird
        } else if (dataType === 'botThird') {
            dataSet = data.botThird
        }

        if (props.type === 'number') {
            finalData = dataSet.map((d, i) => {
                return {
                    type: chartType,
                    name: `${d.naics}${d.naics_description}`,
                    data: d.submitted_count,
                    color: d.color
                }
            })
        } else {
            finalData = dataSet.map((d, i) => {
                return {
                    type: chartType,
                    name: `${d.naics}${d.naics_description}`,
                    data: d.revenue,
                    color: d.color
                }
            })
        }

        return {
            title: {
                text: ''
            },
            chart: {
                height: '725px',
                zoomType: 'y',
                style: {
                    fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                allowHTML: true,
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            navigation: {
                menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#000000'
                },
                menuItemStyle: {
                    padding: '3px 5px',
                    fontWeight: 'bold'
                },
                menuStyle: {
                    padding: '0',
                }
            },
            tooltip: {
                shared: false,
                useHTML: true,
                formatter: function () {
                    let y = format(formatType, this.y);
                    return this.series.name + ': ' + y
                },
                style: {
                    stroke: "none",
                    fontSize: '12px'
                }
            },
            xAxis: {
                categories: years,
                startOnTick: true,
                endOnTick: true,
                labels: {
                    align: 'right',
                    style: {
                        fontSize: '12px'
                    }
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    formatter: function () {
                        if (this.value) {
                            return format(formatType, this.value)
                        } else {
                            return 0
                        }
                    },
                    style: {
                        fontSize: '12px'
                    }
                },
                zoomEnabled: true
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        formatter: function () {
                            if (this.y) {
                                let plotY = format(formatType, this.y);
                                return plotY;
                            } else {
                                let plotY = format(formatType, 0);
                                return plotY;
                            }
                        },
                        align: 'center',
                        crop: false,
                    },
                },
                line: {
                    dataLabels: {
                        enabled: false,
                        align: 'center',
                        crop: false,
                        allowOverlap: true,
                        style: {
                            stroke: "none",
                            textOutline: "none",
                            fontSize: '12px'
                        }
                    }
                }
            },
            series: finalData,
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'middle',
                itemStyle: {
                    fontSize: "11px",
                    fontWeight: 'bold'
                },
                width: '250px'
            }
        }
    }

    let chartOptions = lineChartOptions()

    return (
        <div className='naics-trend-container'>
            {props.naics_trends_data.length > 2
                ? <div className='third-btns'>
                    <button className='btn btn-primary' onClick={() => setLinesChart(!linesChart)}>Change Chart View</button>
                    <button className='btn btn-primary' onClick={() => setDataType('all')}>All NAICS</button>
                    <button className='btn btn-primary' onClick={() => setDataType('topThird')}>Top Third</button>
                    <button className='btn btn-primary' onClick={() => setDataType('midThird')}>Middle Third</button>
                    <button className='btn btn-primary' onClick={() => setDataType('botThird')}>Bottom Third</button>
                </div>
                : null
            }
            <div className='naics-trend-highchart-div'>
                <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '100%', height: '725px' } }} options={chartOptions} />
            </div>
        </div>
    )
}

export default TrendChart