import React from 'react';
import ForecastInputOutput from './ForecastInputOutput';

class ForecastSlider extends React.Component {
  constructor(props) {
    super(props);
    this.prepareSlider = this.prepareSlider.bind(this);
    this.prepareSlider(this.props);
  }

  prepareSlider(props) {
    function min(v) {
      return v < 0 ? v : 0;
    }

    const configs = {
      total_revenue: {
        max: () => 100,
        min: () => -99,
        step: () => 1,
      },
      balance_sheetChild: {
        max: function (v) {
          return v + (Math.abs(v) * .5) + 1000;
        },
        step: function (v) {
          return 100;
        },
        round: 0,
      },
      balance_sheetParent: {
        max: function (v) {
          return v + (Math.abs(v) * .5) + 10000;
        },
        step: function (v) {
          return 1000;
        },
        round: 0,
      },
      pnlChild: {
        max: function (v) {
          return v + (Math.abs(v) * .5) + 1;
        },
        step: function (v) {
          return 0.1;
        },
        round: 1,
      },
      pnlParent: {
        max: function (v) {
          return v + (Math.abs(v) * .5) + 1;
        },
        step: function (v) {
          if (v <= 10) {
            return 0.1;
          } else {
            return 0;
          }
        },
        round: 0,
      }
    }

    this.config = { ...configs[props.category], ...props.config };
    const minFunc = this.config.min || min;
    this.min = minFunc(props.output || props.value).toFixed(0);
    if (props.parent === 'total_costs' && props.value < 1) {
      this.step = this.config.step(props.output || props.value)
    } else {
      this.step = this.config.step(props.output || props.value).toFixed(0);
    }
    this.max = this.config.max(props.output || props.value).toFixed(0);
  }

  componentWillReceiveProps(props) {
    if (props.revChange && props.sheet == 'balance_sheet') {
      this.prepareSlider(props)
    }
  }

  render() {
    let value
    let output
    if (this.props.title !== 'Cost of Goods Sold' && this.props.parent !== 'total_costs') {
      value = this.props.value.toFixed(0)
      if (this.props.output) {
        output = Math.round(this.props.output)
      }
    } else {
      value = this.props.value.toFixed(2)
      if (this.props.output) {
        output = Math.round(this.props.output * 100) / 100
      }
    }

    return (
      <div className='slide-stuff'>
        <div className='title-and-input'>
          <h3>
            {this.props.padding + this.props.title + ' : '}
          </h3>
          <input
            id='myRange'
            onChange={({ target }) => {
              this.props.onChange(target.value)
            }}
            className={this.props.inputsDisplay === 'sliders' ? `slider ${this.props.sheet}-slider ${this.props.parent ? 'child-slider' : ''}` : 'display-none'}
            max={this.max}
            min={this.min}
            step={this.step}
            type='range'
            value={value}
          />
          <ForecastInputOutput
            inputsDisplay={this.props.inputsDisplay}
            value={value}
            buc={this.props.buc}
            onSubmit={this.props.onChange}
            output={output} // super gross
          />
        </div>
      </div>
    )
  }
}

export default ForecastSlider;
