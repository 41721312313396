import React from "react";
import { createRoot } from 'react-dom/client';
import TopNavbar from "../src/TopNavbar";
import { mountReportsNavbar } from '../src/reports/utils/Navbar';
import { reactourSteps, reactourStyles } from '../src/ReactourHelper';
import { TourProvider } from '@reactour/tour';
import { ToastContainer } from 'react-toastify';
import ReportContainerWrapper from '../src/reports/ReportContainerWrapper';
import MonthlyReportContainer from '../src/reports/monthly/MonthlyReportContainer';
import EditChartOfAccounts from '../src/naics_chart_of_accounts/EditChartOfAccounts';
import ManageFirm from "../src/manage_firm/ManageFirm";
import EditFdu from '../src/financial_data_uploads/EditFdu';
import EditSurveyFormat from '../src/naics_survey_formats/EditSurveyFormat';
import IndustryReportContainer from '../src/reports/IndustryReport/IndustryReportContainer';
import IntacctLogin from '../src/IntacctLogin';
import ManageClientsTable from '../src/clients/ManageClientsTable';
import NewUserForm from "../src/NewUserForm";
import Mapper from '../src/mapper/Mapper';
import OpsContainer from '../src/admin/OpsView/OpsContainer';
import CompanyCreated from '../src/admin/OpsView/CompanyCreated';
import PracticeReport from '../src/reports/PracticeReport/PracticeReport';
import QualifiedCalcsInfo from '../src/admin/OpsView/QualifiedCalcsInfo';
import RecentFdusInfo from '../src/admin/OpsView/RecentFdusInfo';
import RecurlyPlans from '../src/RecurlyPlans';
import SignUp from '../src/SignUp';
import UserDashboard from '../src/users/UserDashboard';
import Workflow from "../src/workflows/Workflow";

document.addEventListener('DOMContentLoaded', () => {
  const topNavTarget = document.getElementById('top-navbar-target');

  if (topNavTarget) {
    const navbar = window.navbar.navbar;
    const navRoot = createRoot(topNavTarget)
    const isAdminView = document.body.getAttribute('data-layout') === 'admin';

    navRoot.render(
      <TopNavbar {...navbar} isAdminView={isAdminView} />
    )
  }

  const targetReports = document.getElementById('report-target');
  if (targetReports) {
    const root = createRoot(targetReports);
    let page = window.data.page;
    const tourSteps = reactourSteps(page, window.data.tour_data);
    const numberOfTourSteps = tourSteps ? tourSteps.length : 0;
    const tourStyles = reactourStyles(page);
    const navbar = window.navbar.navbar;

    root.render(
      <>
        <TourProvider
          scrollSmooth={true}
          steps={tourSteps}
          styles={tourStyles}
          onClickMask={({ currentStep, setCurrentStep, setIsOpen }) => {
            if (currentStep < numberOfTourSteps - 1) {
              let nextStep = currentStep + 1
              setCurrentStep(nextStep)
            } else {
              setIsOpen(false)
              setCurrentStep(0)
            }
          }}
        >
          {/* {mountReportsNavbar('reports-navbar-target', {
            page: window.data.page,
            naicsCode: window.data.code,
            plan_type: navbar.plan_type,
            plan_type_access: navbar.plan_type_access,
            user_type: navbar.user_type,
            report_credits: navbar.report_credits,
            reports_unlocked: navbar.reports_unlocked,
            unlock_reports_url: navbar.unlock_reports_url
          })} */}
          <ReportContainerWrapper {...window.data} planType={navbar.plan_type} planAccess={navbar.plan_type_access} />
        </TourProvider>
      </>
    );
  }

  const targetMonthlyReport = document.getElementById('monthly-report-target')
  if (targetMonthlyReport) {
    const root = createRoot(targetMonthlyReport);
    let tourData = window.data.tour_data;
    const tourSteps = reactourSteps('forecast', tourData);
    const numberOfTourSteps = tourSteps.length;
    const tourStyles = reactourStyles('forecast');
    const navbar = window.navbar.navbar;

    let tourTaken = tourData.tour_taken
    const endTour = () => {
      if (!tourTaken) {
        fetch(`/api/v1/tour/end_tour/${tourData.tracker_id}`, {
          method: "POST",
          headers: {
            'X-CSRF-Token': window.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ page: window.data.page })
        });
      }
    };

    root.render(
      <>
        <TourProvider
          scrollSmooth={true}
          steps={tourSteps}
          styles={tourStyles}
          onClickMask={({ currentStep, setCurrentStep, setIsOpen }) => {
            if (currentStep === 0 && !tourTaken) {
              endTour()
            }
            if (currentStep < numberOfTourSteps - 1) {
              let nextStep = currentStep + 1
              setCurrentStep(nextStep)
            } else {
              setIsOpen(false)
              setCurrentStep(0)
            }
          }}
        >
          {/* {mountReportsNavbar('reports-navbar-target', {
            page: window.data.page,
            naicsCode: window.data.code,
            plan_type: window.data.plan_type,
            plan_type_access: navbar.plan_type_access,
            user_type: navbar.user_type,
            report_credits: navbar.report_credits,
            reports_unlocked: navbar.reports_unlocked,
            unlock_reports_url: navbar.unlock_reports_url
          })} */}
          <MonthlyReportContainer {...window.data} />
        </TourProvider>
      </>
    );
  }

  const targetCOA = document.getElementById('chart_of_account_target');
  if (targetCOA) {
    const root = createRoot(targetCOA);
    root.render(<EditChartOfAccounts {...window.data} />);
  }

  const targetIntacctLogin = document.getElementById('intacct_login_target');
  if (targetIntacctLogin) {
    const root = createRoot(targetIntacctLogin);
    root.render(<IntacctLogin {...window.info} />);
  }

  const targetManageFirm = document.getElementById('edit-company-form')
  if (targetManageFirm) {
    const root = createRoot(targetManageFirm);
    root.render(<ManageFirm {...window.data} />);
  }

  const targetEditFDU = document.getElementById('edit_fdu_target')
  if (targetEditFDU) {
    const root = createRoot(targetEditFDU);
    root.render(<EditFdu {...window.data} />);
  }

  const targetEditSurveyFormat = document.getElementById('survey_format_target');
  if (targetEditSurveyFormat) {
    const root = createRoot(targetEditSurveyFormat);
    root.render(<EditSurveyFormat {...window.data} />);
  }

  const targetIndustry = document.getElementById('industry-report-target');
  if (targetIndustry) {
    const root = createRoot(targetIndustry);
    const tourSteps = reactourSteps('industry', window.data);
    const numberOfTourSteps = tourSteps.length;
    const tourStyles = reactourStyles('industry');

    root.render(
      <TourProvider
        scrollSmooth={true}
        steps={tourSteps}
        styles={tourStyles}
        onClickMask={({ currentStep, setCurrentStep, setIsOpen }) => {
          if (currentStep < numberOfTourSteps - 1) {
            let nextStep = currentStep + 1
            setCurrentStep(nextStep)
          } else {
            setIsOpen(false)
            setCurrentStep(0)
          }
        }}
      >
        <IndustryReportContainer {...window.data} />
      </TourProvider>
    );
  }

  const targetMC = document.getElementById('client-container-target');
  if (targetMC) {
    const notificationTarget = document.getElementById('notification-container-target')
    const notiRoot = createRoot(notificationTarget)
    notiRoot.render(
      <ToastContainer
        position='top-right'
        autoClose={false}
        closeButton={true}
        closeOnClick={false}
      />
    )

    const root = createRoot(targetMC);
    const navbar_info = window.navbar.navbar;
    const user_preference = JSON.parse(targetMC.getAttribute('user_preference'))
    const naics_list = JSON.parse(targetMC.getAttribute('naics_list'));
    const qbo_notifications = JSON.parse(targetMC.getAttribute('qbo_notifications'));
    const users = JSON.parse(targetMC.getAttribute('users'));
    let tourData = JSON.parse(targetMC.getAttribute('tour_data'))
    const tourSteps = reactourSteps('manageClients', tourData)
    const numberOfTourSteps = tourSteps.length;
    const tourStyles = reactourStyles('manageClients');
    const xero_connect_url = JSON.parse(targetMC.getAttribute('xero_connect_url'));
    const plan_access = JSON.parse(targetMC.getAttribute('plan_access'));
    const plan_type = targetMC.getAttribute('plan_type')
    const user_notifications = JSON.parse(targetMC.getAttribute('user_notifications'));

    let tourTaken = tourData.tour_taken
    const endTour = () => {
      if (!tourTaken) {
        fetch(`/api/v1/tour/end_tour/${tourData.tracker_id}`, {
          method: "POST"
        });
      }
    };

    root.render(
      <>
        <TourProvider
          scrollSmooth={true}
          steps={tourSteps}
          styles={tourStyles}
          onClickMask={({ currentStep, setCurrentStep, setIsOpen }) => {
            if (currentStep < numberOfTourSteps - 1) {
              let nextStep = currentStep + 1
              setCurrentStep(nextStep)
            } else {
              endTour()
              setIsOpen(false)
              setCurrentStep(0)
            }
          }}
        >
          <ManageClientsTable navBarInfo={navbar_info} naicsList={naics_list} user_preference={user_preference} qbo_notifications={qbo_notifications} users={users} tourData={tourData} xero_connect_url={xero_connect_url} plan_access={plan_access} plan_type={plan_type} user_notifications={user_notifications} />
        </TourProvider>
      </>
    );
  }

  const targetMapper = document.getElementById('mapper-target');
  if (targetMapper) {
    const root = createRoot(targetMapper);
    let tourData = window.data.tour_data;
    const tourSteps = reactourSteps('map file', tourData);
    const numberOfTourSteps = tourSteps.length;
    const tourStyles = reactourStyles('map file');

    let tourTaken = tourData.tour_taken
    const endTour = () => {
      if (!tourTaken) {
        fetch(`/api/v1/tour/end_tour/${tourData.tracker_id}`, {
          method: "POST"
        });
      }
    };

    root.render(
      <TourProvider
        scrollSmooth={true}
        steps={tourSteps}
        styles={tourStyles}
        onClickMask={({ currentStep, setCurrentStep, setIsOpen }) => {
          if (currentStep < numberOfTourSteps - 1) {
            let nextStep = currentStep + 1
            setCurrentStep(nextStep)
          } else {
            endTour()
            setIsOpen(false)
            setCurrentStep(0)
          }
        }}
      >
        <Mapper {...window.data} tourData={tourData} />
      </TourProvider>
    );
  }

  // no append_javascript_tag
  const targetOpsUserStats = document.getElementById('admin-ops-view-user-stats')
  if (targetOpsUserStats) {
    const root = createRoot(targetOpsUserStats);
    root.render(<OpsContainer {...window.data} />);
  }

  // no append_javascript_tag
  const targetOpsCompanyCreated = document.getElementById('admin-ops-company-created')
  if (targetOpsCompanyCreated) {
    const root = createRoot(targetOpsCompanyCreated);
    root.render(<CompanyCreated {...window.data} />)
  }

  const targetPractice = document.getElementById('group-big-table-target')
  if (targetPractice) {
    const root = createRoot(targetPractice);
    const tourSteps = reactourSteps('practice', window.data);
    const numberOfTourSteps = tourSteps.length;
    const tourStyles = reactourStyles('practice');

    root.render(
      <TourProvider
        scrollSmooth={true}
        steps={tourSteps}
        styles={tourStyles}
        onClickMask={({ currentStep, setCurrentStep, setIsOpen }) => {
          if (currentStep < numberOfTourSteps - 1) {
            let nextStep = currentStep + 1
            setCurrentStep(nextStep)
          } else {
            setIsOpen(false)
            setCurrentStep(0)
          }
        }}
      >
        <PracticeReport {...window.data} />
      </TourProvider>
    )
  }

  // no append_javascript_tag
  const targetQualifedCalcs = document.getElementById('qualified_calcs_info_target');
  if (targetQualifedCalcs) {
    const root = createRoot(targetQualifedCalcs);
    root.render(<QualifiedCalcsInfo {...window.data} />)
  }

  // no append_javascript_tag
  const targetRecentFDUs = document.getElementById('recent_fdus_info_target');
  if (targetRecentFDUs) {
    const root = createRoot(targetRecentFDUs);
    root.render(<RecentFdusInfo {...window.data} />)
  }

  const targetSubscriptions = document.getElementById('subscriptions-target')
  if (targetSubscriptions) {
    const root = createRoot(targetSubscriptions);
    let planType = window.navbar.navbar.plan_type
    let userType = window.navbar.navbar.user_type
    root.render(<RecurlyPlans subData={window.data.subData} futureSubData={window.data.futureSubData} planType={planType} userType={userType} />);
  }

  const targetDemoSignUp = document.getElementById('demo_signup_target');
  if (targetDemoSignUp) {
    const root = createRoot(targetDemoSignUp);
    root.render(<SignUp {...window.data} />);
  }

  const targetFreeSignUp = document.getElementById('freemium_signup_target');
  if (targetFreeSignUp) {
    const root = createRoot(targetFreeSignUp);
    root.render(<SignUp {...window.data} />);
  }

  const newUserForm = document.getElementById('new-user-form')
  if (newUserForm) {
    const root = createRoot(newUserForm);
    root.render(<NewUserForm {...window.data} using_navbar={false} />)
  }

  const targetUserDash = document.getElementById('user-dashboard-target');
  if (targetUserDash) {
    const root = createRoot(targetUserDash);
    root.render(<UserDashboard {...window.data} />);
  }

  const targetWorkflow = document.getElementById('workflow-target');
  if (targetWorkflow) {
    const root = createRoot(targetWorkflow);
    root.render(<Workflow {...window.data} />);
  }
});