import React, { useContext } from 'react'
import { Data } from '../../contexts';
import AccountTree from '../../../utils/AccountTree';
import { parseMoney } from '../../../utils';
import cashFlowStructure from './cashFlowStructure';

const MonthlyForecastCashFlow = (props) => {
  const { you, avg } = useContext(Data);

  const months = Object.values(props.months);

  const bold = {
    fontWeight: 'bold',
    textAlign: 'center',
  };

  const selectedMonth = (i) => { 
    if (props.editMultipleMonths) {
      return i >= props.month ? 'current-month-col' : null
    } else {
      return props.month == i ? 'current-month-col' : null 
    }
  }

  const backgroundChanger = (i) => i < (props.blendMonth - 1) && props.isLiveData && props.year === new Date(Date.now()).getFullYear() && i !== props.month ? 'live-data-col' : null

  function buildRows() {

    // build custom account trees
    const balAccountTree = new AccountTree({
      page: 'forecast',
      tree: props.tree['balance_sheet'],
    });

    const pnlAccountTree = new AccountTree({
      page: 'forecast',
      tree: props.tree['pnl']
    })

    const startingCash = props.startingCash['cash']

    const adjustedCashFlowValues = signChanger(props.cashFlowSet, balAccountTree)

    const cashFlowAccounts = cashFlowBuilder(balAccountTree, pnlAccountTree, startingCash, adjustedCashFlowValues)

    function signChanger(cashFlowValues, balance_sheet) {
      const keysNeedingFlipped = [];
      balance_sheet.forEach((row) => {
        row.negative ? keysNeedingFlipped.push(row.key) : null
      });

      cashFlowValues.forEach((valuesObject) => {
        for (const key of keysNeedingFlipped) {
          if (valuesObject.hasOwnProperty(key)) {
            valuesObject[key] = valuesObject[key] * -1;
          }
        }
      });

      return cashFlowValues;
    }

    function cashFlowBuilder(balance_sheet, pnl, startingCash, adjustedCashFlowValues) {
      // title and header rows with some rows

      // total row container
      const rowOrder = []

      // add the two pnl items and do some styling
      pnl.forEach((row) => {
        if (row.key == 'operating_expenses_net_income' || row.key == 'depreciation_and_amortization') {
          row.cashFlowCategory = 'operatingActivities'
          row.key == 'operating_expenses_net_income' ? row.order = 2 : row.order = 3
          row.doubleUnderline = false
          row.topline = false
          row.bold = false
          rowOrder.push(row)
        }
      })

      function categorySetter(row, key) {
        switch (row.key) {
          case 'total_current_assets':
            return 'operatingActivities'
          case 'total_current_liabilities':
            if (key !== 'line_of_credit' && key !== 'current_portion_of_ltd') {
              return 'operatingActivities';
            } else {
              return 'financingActivities';
            }
          case 'total_fixed_assets':
            return 'investingActivities';
          case 'net_worth':
            return 'financingActivities';
          case 'long_term_debt':
            return 'financingActivities'
          default:
            return 'n/a'
        }
      }

      function indexSetter(category, row) {
        const i = row.i
        switch (category) {
          case 'operatingActivities':
            return i + 100;
          case 'investingActivities':
            return i + 200;
          case 'financingActivities':
            if (row.key === 'long_term_debt' || row.key === 'current_portion_of_ltd') {
              // need to reorder these two keys to get to the bottom of the section.... might run into problems if the equity section gets larger than 10 items
              return i + 310
            } else {
              return i + 300;
            }
          default:
            return 999;
        }
      }

      // loop through balance sheet items and add some meta data
      const balSheetKeys = []

      balance_sheet.forEach((row) => {
        const groupContainer = []
        // special treatment for long term debt
        if (row.header == true || row.key === 'long_term_debt') {
          if (row.key === 'long_term_debt') {
            const newObj = {}
            newObj.key = row.key
            newObj.copy = row.copy
            newObj.cashFlowCategory = categorySetter(row, row.key)
            groupContainer.push(newObj)
          } else {
            for (const [key, value] of Object.entries(row.children)) {
              const newObj = {}
              newObj.key = key
              newObj.copy = value.copy
              newObj.cashFlowCategory = categorySetter(row, key)
              groupContainer.push(newObj)
            }
          }
          const newGroupContainer = groupContainer.filter(row => row.key !== 'retained_earnings');
          balSheetKeys.push(newGroupContainer)
        }
      });

      // add meta data to balance sheet rows and push
      balance_sheet.forEach((row) => {
        const bsKeys = balSheetKeys.flat()
        if (bsKeys.some(obj => obj.key === row.key)) {
          let noRowKeys = ['cash', 'operating_expenses_net_income', 'less_accumulated_depreciation', 'capital_stock', 'owner_distributions']
          if (!noRowKeys.includes(row.key)) {
            const bsData = bsKeys.find(element => element.key == row.key)
            row.order = indexSetter(bsData.cashFlowCategory, row)
            row.cashFlowCategory = bsData.cashFlowCategory
            row.key === 'long_term_debt' ? removeStyling(row) : row
            rowOrder.push(row)
          }
        }
      });

      function removeStyling(row) {
        row.bold = false
        row.topline = false
        row.underline = false
      }

      // add padding for non header rows
      const editedRows = rowEditor(rowOrder)

      function rowEditor(rows) {
        rows.forEach((row) => {
          row.padding = '        '
        })
        return rows
      }

      // merge header / sum rows
      const mergedRows = [...editedRows, ...cashFlowStructure]

      // sort by order
      const sortieBoi = mergedRows.sort(compare)

      function compare(a, b) {
        let comparison = 0
        a.order > b.order ? comparison = 1 : comparison = -1
        return comparison
      }

      return sortieBoi
    }

    const rows = [];
    cashFlowAccounts.forEach((row) => {
      if (row.money) rows.push(newBuildRow(row, cashFlowAccounts, startingCash, adjustedCashFlowValues))
    });

    return rows;
  }



  function sumRowFor(category, bvs, cashFlowVals, pnlVals, cashFlowAccounts, startingCash) {
    const netCash = netCashFlowSum('changeInCash', cashFlowVals, pnlVals, cashFlowAccounts);
    const beginCash = bvs.map((vs, i) => {
      if (i !== 0) {
        return bvs[i - 1]['cash'] || 0
      } else {
        return startingCash || 0;
      }
    });
    const mergeCash = [...netCash, ...beginCash];
    switch (category) {
      case 'operatingActivities':
        return netCashFlowSum(category, cashFlowVals, pnlVals, cashFlowAccounts);
      case 'financingActivities':
        return netCashFlowSum(category, cashFlowVals, pnlVals, cashFlowAccounts);
      case 'investingActivities':
        return netCashFlowSum(category, cashFlowVals, pnlVals, cashFlowAccounts);
      case 'changeInCash':
        return netCash
      case 'beginCash':
        return beginCash; // array
      case 'endCash':
        return endCash(mergeCash)
      default:
        return 'n/a';
    }

  }

  function projectionSetFor(key, cashFlowValueSet, pnlValueSet) {
    const valueSet = key === 'depreciation_and_amortization' || key === 'operating_expenses_net_income' ? pnlValueSet : cashFlowValueSet
    return valueSet.map((vs) => {
      return vs[key] || 0;
    })
  }

  function netCashFlowSum(category, cashFlowVals, pnlVals, cashFlowAccounts) {
    const keys = []
    cashFlowAccounts.forEach((account) => {
      if (category === 'changeInCash') {
        keys.push(account.key)
      } else {
        account.cashFlowCategory === category ? keys.push(account.key) : null
      }
    })
    let cashFlowSumArr = cashFlowVals.map((vs, i) => {
      const sumArr = [];
      keys.forEach((key) => {
        const isDepreciation = key === 'depreciation_and_amortization'
        const isNetIncome = key === 'operating_expenses_net_income'
        const isSpecialCase = isDepreciation || isNetIncome
        isSpecialCase && category === 'operatingActivities' || isSpecialCase && category === 'changeInCash' ? sumArr.push(pnlVals[i][key] || 0) : sumArr.push(vs[key] || 0)
      })
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      return sumArr.reduce(reducer);
    });

    return cashFlowSumArr;
  }

  function endCash(mergeCash) {
    let endCashValues = {};
    for (let i = 0; i < mergeCash.length; i++) {
      // check index 0-11, setup endCashValues with keys 0-11
      if (i < 12) {
        endCashValues[i] = mergeCash[i];
      } else {
        let mergeCashVal
        // then add index 12 and on from mergedArray to the endCashValues values
        endCashValues[i - 12] += mergeCash[i];
        let endCashVal = Math.round(endCashValues[i - 12])

        if (mergeCash[i + 1]) {
          mergeCashVal = Math.round(mergeCash[i + 1])
        }
        // End of period should always equal cash on balance sheet for each month
        if (endCashVal !== mergeCashVal && mergeCashVal !== undefined) {
          endCashValues[i - 12] = mergeCash[i + 1];
        }
      }
    }
    // {0: 2, 1: 4, 2: 6, ...} endCashValues object
    return Object.values(endCashValues); // [2, 4, 6 ...] values into a new array to return the totals
  }

  function newBuildRow(row, cashFlowAccounts, startingCash, adjustedCashFlowValues, i) {
    const projectionSet = row.totalRow ? sumRowFor(row.cashFlowCategory, props.bvs, adjustedCashFlowValues, props.pnlValueSet, cashFlowAccounts, startingCash) : projectionSetFor(row.key, adjustedCashFlowValues, props.pnlValueSet);

    let underline;

    if (row.boldedUnderline) {
      underline = 'solid 3px';
    } else if (row.doubleUnderline) {
      underline = 'double 3px';
    } else if (row.underline) {
      underline = 'solid 1px';
    }

    const copyStyle = row.spacerRow
      ? {
        height: '17px'
      }
      : {
        fontWeight: (row.bold ? 'bold' : ''),
        textAlign: 'left',
        borderBottom: underline,
        borderTop: row.topline ? 'solid 1px' : '',
        whiteSpace: 'nowrap',
      }

    const valueStyle = {
      textAlign: 'right',
      borderBottom: underline,
      borderTop: row.topline ? 'solid 1px' : '',
    }

    return (
      <tr key={row.order} >
        <td className='year-rows cashflow-row-name' style={copyStyle}>{row.padding + row.copy}</td>
        {months.map((_, i) =>
          <td
            key={i}
            className={'year-rows cash-flow-column month-col ' + selectedMonth(i) + ' ' + backgroundChanger(i)}
            onClick={() => props.setMonth(i)}
            style={valueStyle}>
            {!(row.header) ? parseMoney(projectionSet[i]) : ''}
          </td>
        )}
      </tr>
    )
  }

  const rows = buildRows();

  return (
    <div>
      <div className='monthly-forecast-landscape report-card monthly-sheet'>
        <div>
          <h1 className='report-table-title hide-not-on-print'>{`${props.year} Cash Flow - ${props.clientName}`}</h1>
          <div className='pdf-table-div'>
            <table className='monthly-forecast-table' id={`monthly-forecast-cash-flow-${props.year}`}>
              <thead>
                <tr>
                  <td></td>
                  {months.map((month, i) =>
                    <td key={i}
                      className={`print-year-rows month-col ${selectedMonth(i)} ${backgroundChanger(i)}`}
                      onClick={() => props.setMonth(i)}
                      style={bold}
                    >
                      {month}
                    </td>)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
      </div>
    </div>
  )
}

export default MonthlyForecastCashFlow;