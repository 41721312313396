import React from "react";

const EditMultipleClientsForm = (props) => {

    const propertyInputsFixed = (inputType) => {

        if (inputType === 'naics_id') {
            return (
                <>
                    <label htmlFor='naics_id'>NAICS</label>
                    <select
                        value={props.propertyValues['naics_id']}
                        onChange={(e) => props.handleSelectedPropertyValue('naics_id', e.target.value)}
                        required
                        aria-required='true'
                    >
                        {
                            props.naicsOptions.map(naics => {
                                return (<option key={naics.key} value={naics.value}>
                                    {`${naics.key}-${naics.name}`}
                                </option>)
                            })
                        }
                    </select>
                </>
            )
        }

        if (inputType === 'state') {
            return (
                <>
                    <label htmlFor='state'>State</label>
                    <select
                        name='state'
                        value={props.propertyValues['state']}
                        onChange={(e) => props.handleSelectedPropertyValue('state', e.target.value)}
                        required
                        aria-required='true'

                    >
                        <option value=''></option>
                        <option value='AL'>Alabama</option>
                        <option value='AK'>Alaska</option>
                        <option value='AZ'>Arizona</option>
                        <option value='AR'>Arkansas</option>
                        <option value='CA'>California</option>
                        <option value='CO'>Colorado</option>
                        <option value='CT'>Connecticut</option>
                        <option value='DE'>Delaware</option>
                        <option value='DC'>District of Columbia</option>
                        <option value='FL'>Florida</option>
                        <option value='GA'>Georgia</option>
                        <option value='HI'>Hawaii</option>
                        <option value='ID'>Idaho</option>
                        <option value='IL'>Illinois</option>
                        <option value='IN'>Indiana</option>
                        <option value='IA'>Iowa</option>
                        <option value='KS'>Kansas</option>
                        <option value='KY'>Kentucky</option>
                        <option value='LA'>Louisiana</option>
                        <option value='ME'>Maine</option>
                        <option value='MD'>Maryland</option>
                        <option value='MA'>Massachusetts</option>
                        <option value='MI'>Michigan</option>
                        <option value='MN'>Minnesota</option>
                        <option value='MS'>Mississippi</option>
                        <option value='MO'>Missouri</option>
                        <option value='MT'>Montana</option>
                        <option value='NE'>Nebraska</option>
                        <option value='NV'>Nevada</option>
                        <option value='NH'>New Hampshire</option>
                        <option value='NJ'>New Jersey</option>
                        <option value='NM'>New Mexico</option>
                        <option value='NY'>New York</option>
                        <option value='NC'>North Carolina</option>
                        <option value='ND'>North Dakota</option>
                        <option value='OH'>Ohio</option>
                        <option value='OK'>Oklahoma</option>
                        <option value='OR'>Oregon</option>
                        <option value='PA'>Pennsylvania</option>
                        <option value='PR'>Puerto Rico</option>
                        <option value='RI'>Rhode Island</option>
                        <option value='SC'>South Carolina</option>
                        <option value='SD'>South Dakota</option>
                        <option value='TN'>Tennessee</option>
                        <option value='TX'>Texas</option>
                        <option value='USA'>USA</option>
                        <option value='UT'>Utah</option>
                        <option value='VT'>Vermont</option>
                        <option value='VA'>Virginia</option>
                        <option value='WA'>Washington</option>
                        <option value='WV'>West Virginia</option>
                        <option value='WI'>Wisconsin</option>
                        <option value='WY'>Wyoming</option>
                    </select>
                </>
            )
        }
    }

    const propertyInputsWithNewOption = (inputType) => {
        if (inputType === 'partners') {
            return (
                <>
                    <div>
                        <label htmlFor='partners'>Partner</label>
                        <select
                            name='partners'
                            value={props.propertyValues['partners']}
                            onChange={(e) => props.handleSelectedPropertyValue('partners', e.target.value)}
                        >
                            <option value=''></option>
                            {
                                props.propertyOptions.partners.map(partner => {
                                    if (partner !== 'All') {
                                        return <option value={partner}>{partner}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                    <span>OR</span>
                    <div>
                        <label htmlFor='new-partners'>Enter New Partner</label>
                        <input
                            type='text'
                            value={props.propertyValues['partners']}
                            onChange={(e) => props.handleSelectedPropertyValue('partners', e.target.value)} />
                    </div>
                </>
            )
        }

        if (inputType === 'office') {
            return (
                <>
                    <div>
                        <label htmlFor='office'>Office</label>
                        <select
                            name='office'
                            value={props.propertyValues['office']}
                            onChange={(e) => props.handleSelectedPropertyValue('office', e.target.value)}
                        >
                            <option value=''></option>
                            {
                                props.propertyOptions.office.map(office => {
                                    if (office !== 'All') {
                                        return <option value={office}>{office}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                    <span>OR</span>
                    <div>
                        <label htmlFor='new-office'>Enter New Office</label>
                        <input
                            type='text'
                            value={props.propertyValues['office']}
                            onChange={(e) => props.handleSelectedPropertyValue('office', e.target.value)}
                        />
                    </div>
                </>
            )
        }
    }


    const buildPropertyOptions = () => {
        switch (props.propertySelected) {
            case 'naics_id':
                return propertyInputsFixed('naics_id');
            case 'state':
                return propertyInputsFixed('state');
            case 'partners':
                return propertyInputsWithNewOption('partners');
            case 'office':
                return propertyInputsWithNewOption('office')
            case 'fiscal_year_end':
                return (
                    <>
                        <p>Select End Date for Client's Fiscal Year End</p>
                        <label htmlFor='fiscal_year_end'>Fiscal Year End</label>
                        <input
                            name='fiscal_year_end'
                            type='date'
                            value={props.propertyValues['fiscal_year_end']}
                            onChange={(e) => props.handleSelectedPropertyValue('fiscal_year_end', e.target.value)}
                        />
                    </>
                )
            case 'status':
                return (
                    <>
                        <label>Options</label>
                        <select
                            value={props.propertyValues['status']}
                            onChange={(e) => props.handleSelectedPropertyValue('status', e.target.value)}
                        >
                            <option value=''></option>
                            <option value='Active'>Active</option>
                            <option value='Inactive'>Inactive</option>
                            <option value='Prospect'>Prospect</option>
                        </select>
                    </>
                )
            default:
                break;
        }
    }

    return (
        <div className='edit-multiple-clients-container'>
            <div className='edit-multiple-clients-header'>
                <h1>{props.numOfClients > 1 ? `Edit ${props.numOfClients} Clients` : `Edit ${props.numOfClients} Client`}</h1>
                <p>You can select/deselect clients currently on the table.</p>
            </div>
            <form id='edit-multiple-clients-form' onSubmit={(e) => props.handleMultipleCompaniesUpdate(e)}>
                <p className='error-message'>{props.error ? props.error : ''}</p>
                <div className='select-property-input'>
                    <label>Select Property to Edit</label>
                    <select value={props.propertySelected}
                        onChange={(e) => props.handleSelectedPropertyForm(e.target.value)}
                    >
                        <option value='naics_id'>NAICS</option>
                        <option value='partners'>Partner</option>
                        <option value='office'>Office</option>
                        <option value='state'>State</option>
                        <option value='fiscal_year_end'>Fiscal Year End</option>
                        <option value='status'>Status</option>
                    </select>
                </div>
                <div className='property-value-input'>
                    {buildPropertyOptions()}
                </div>
                <div className='edit-multiple-clients-btns'>
                    <button className='btn btn-primary' type='submit'>Update</button>
                    <button className='btn btn-primary' type='button' onClick={() => props.closeEditMultipleModal()}>Close</button>
                </div>
            </form>
        </div>
    )
}

export default EditMultipleClientsForm;