import React, { useState } from 'react';

const AddAndEditDashboardView = (props) => {
    const [dashViewFormState, setDashViewFormState] = useState({
        name: props.dashboardViewModalState.edit_view.name || '',
        viewVisibilty: props.dashboardViewModalState.edit_view.private ? 'only_me' : 'all',
        displayViewPermissionQ: !props.dashboardViewModalState.edit_view.private,
        displayApplyQ: ['userDashboard', 'practice', 'industry'].includes(props.report) ? false : true,
        viewPermission: props.dashboardViewModalState.edit_view.permission_details === 'view_only' ? 'view_only' : 'edit_and_view',
        viewApply: 'current_client'
    })

    const handleDashViewForm = (type, value) => {
        setDashViewFormState((prevState) => {
            switch (type) {
                case 'name':
                    return {
                        ...prevState,
                        name: value,
                    }
                case 'viewPermission':
                    return {
                        ...prevState,
                        viewPermission: value,
                    }
                case 'viewApply':
                    return {
                        ...prevState,
                        viewApply: value,
                    }
                case 'viewVisibilty':
                    return {
                        ...prevState,
                        viewVisibilty: value,
                        displayViewPermissionQ: value === 'all'
                    }
            }
        })

    }

    const handleAddEditViewSubmit = (e) => {
        e.preventDefault()
        if (props.dashboardViewModalState.action === 'add_view') {
            props.createDashBoardView(dashViewFormState)
        } else {
            props.updateDashBoardView(false, props.dashboardViewModalState.edit_view.id, dashViewFormState)
        }
    }

    return (
        <div>
            <h3>{props.dashboardViewModalState.action === 'add_view' ? 'Create A New Dashboard View' : 'Edit Dashboard View'}</h3>
            <p>Click Anywhere Outside of this Pop-up to Close</p>
            <form id='new-dashboard-view-form' onSubmit={(e) => handleAddEditViewSubmit(e)}>
                <div className='dash-view-name'>
                    <label htmlFor='dashboard-view-name'>View Name:</label>
                    <input
                        type='text'
                        id='dashboard-view-name'
                        maxLength='30'
                        placeholder="Ex. Bob's First View"
                        value={dashViewFormState.name}
                        onChange={(e) => handleDashViewForm('name', e.target.value)}
                        required
                    />
                </div>
                <div className='one-line-q'>
                    <span>Do you want all other firm users to view this dashboard or only you?</span>
                    <div className='dash-view-radio-q'>
                        <input
                            type='radio'
                            id='all-view'
                            name='dashboard-view-visibility'
                            value='all'
                            checked={dashViewFormState.viewVisibilty === 'all'}
                            onChange={(e) => handleDashViewForm('viewVisibilty', e.target.value)}
                            required
                        />
                        <label htmlFor='all-view'>All</label>
                    </div>
                    <div className='dash-view-radio-q'>
                        <input
                            type='radio'
                            id='only-me-view'
                            name='dashboard-view-visibility'
                            value='only_me'
                            checked={dashViewFormState.viewVisibilty === 'only_me'}
                            onChange={(e) => handleDashViewForm('viewVisibilty', e.target.value)} />
                        <label htmlFor='only-me-view'>Only Me</label>
                    </div>
                </div>
                {
                    dashViewFormState.displayViewPermissionQ
                        ? (<div className='one-line-q'>
                            <span>Can other users edit or just view your layout?</span>
                            <div className='dash-view-radio-q'>
                                <input
                                    type='radio'
                                    id='permit-edit-and-view'
                                    name='dashboard-view-permissions'
                                    value='edit_and_view'
                                    checked={dashViewFormState.viewPermission === 'edit_and_view'}
                                    onChange={(e) => handleDashViewForm('viewPermission', e.target.value)}
                                    required
                                />
                                <label htmlFor='permit-edit-and-view'>Both Edit & View</label>
                            </div>
                            <div className='dash-view-radio-q'>
                                <input
                                    type='radio'
                                    id='permit-view'
                                    name='dashboard-view-permissions'
                                    value='view_only'
                                    checked={dashViewFormState.viewPermission === 'view_only'}
                                    onChange={(e) => handleDashViewForm('viewPermission', e.target.value)}
                                />
                                <label htmlFor='permit-view'>Only View</label>
                            </div>
                        </div>)
                        : null
                }
                {dashViewFormState.displayApplyQ
                    ? (
                        <div>
                            <span>Do you want to apply this layout to other clients?</span>
                            {
                                dashViewFormState.displayViewPermissionQ
                                    ? (<>
                                        <div className='dash-view-radio-q'>
                                            <input
                                                type='radio'
                                                id='all-firm-clients'
                                                name='dashboard-view-apply'
                                                value='all_firm_clients'
                                                checked={dashViewFormState.viewApply === 'all_firm_clients'}
                                                onChange={(e) => handleDashViewForm('viewApply', e.target.value)}
                                            />
                                            <label htmlFor='all-firm-clients'>All Firm Clients</label>
                                        </div>

                                        <div className='dash-view-radio-q'>
                                            <input
                                                type='radio'
                                                id='all-firm-clients-naics'
                                                name='dashboard-view-apply'
                                                value='all_firm_clients_naics'
                                                checked={dashViewFormState.viewApply === 'all_firm_clients_naics'}
                                                onChange={(e) => handleDashViewForm('viewApply', e.target.value)}
                                            />
                                            <label htmlFor='all-firm-clients-naics'>All Firm Clients with Same NAICS Codes</label>
                                        </div>
                                    </>)
                                    : null
                            }

                            <div className='dash-view-radio-q'>
                                <input
                                    type='radio'
                                    id='all-my-clients'
                                    name='dashboard-view-apply'
                                    value='all_my_clients'
                                    checked={dashViewFormState.viewApply === 'all_my_clients'}
                                    onChange={(e) => handleDashViewForm('viewApply', e.target.value)}
                                />
                                <label htmlFor='all-my-clients'>All My Clients</label>
                            </div>

                            <div className='dash-view-radio-q'>
                                <input
                                    type='radio'
                                    id='all-my-clients-naics'
                                    name='dashboard-view-apply'
                                    value='all_my_clients_naics'
                                    checked={dashViewFormState.viewApply === 'all_my_clients_naics'}
                                    onChange={(e) => handleDashViewForm('viewApply', e.target.value)}
                                />
                                <label htmlFor='all-my-clients-naics'>All My Clients with Same NAICS Codes</label>
                            </div>

                            <div className='dash-view-radio-q'>
                                <input
                                    type='radio'
                                    id='just-this-client'
                                    name='dashboard-view-apply'
                                    value='current_client'
                                    checked={dashViewFormState.viewApply === 'current_client'}
                                    onChange={(e) => handleDashViewForm('viewApply', e.target.value)}
                                    required
                                />
                                <label htmlFor='just-this-client'>No, Just this Client</label>
                            </div>
                        </div>
                    )
                    : null
                }
                <div className='dashboard-form-btn-section'>
                    <button className='btn dashboard-save-btn'>{props.dashboardViewModalState.action === 'add_view' ? 'Create New View' : 'Save Changes'}</button>
                </div>
            </form>
            <button onClick={() => props.handleDashboardViewModal('all_views')}>Edit My Views</button>
        </div>
    )
}

export default AddAndEditDashboardView;