import React from "react";
import AccountTree from "../utils/AccountTree";
import { parseMoney } from "../utils";
import { CommonSizeArrows } from "./report_components/MoneyHelpers";

const FunctionalExpenses = (props) => {
    let pnlRows = []
    const pnlTreeAll = new AccountTree({
        tree: props.tree['pnl']
    })
    pnlTreeAll.forEach((row) => {
        pnlRows.push(row)
    })
    pnlRows = pnlRows.filter(Boolean)

    const compareWithRowKeys = {
        'None': null,
        'Practice': 'practice_avg',
        'Peers': 'avg',
        'NSCHBC': 'nschbc'
    }
    const calcsAvgType = compareWithRowKeys[props.compareWith]
    const avg = calcsAvgType ? props.calcs[calcsAvgType] : null;

    let youProgramServicesTotal = 0
    let youAdminTotal = 0
    let youFundraisingTotal = 0
    let youFinalTotal = 0
    let compareProgramServicesTotal = 0
    let compareAdminTotal = 0
    let compareFundraisingTotal = 0

    const renderHeaderColumns = () => {
        // Need to hanlde different fiscal year end
        let tableYearDisplay = props.calendarYearEnd ? props.year : `FY${props.year + 1}`
        return (
            <thead>
                <tr key='func-expense-header'>
                    <td>{`Functional Expenses ${tableYearDisplay}`}</td>
                    <td className='money-table-cell'>Program Services</td>
                    <td className='money-table-cell common-size-cell'>You</td>
                    <td className='money-table-cell common-size-cell'></td>
                    {calcsAvgType ? <td className='money-table-cell common-size-cell'>{props.compareWith}</td> : <td className='money-table-cell'></td>}
                    <td>Administrative</td>
                    <td className='common-size-cell'>You</td>
                    <td className='common-size-cell'></td>
                    {calcsAvgType ? <td className='common-size-cell'>{props.compareWith}</td> : <td></td>}
                    <td className='money-table-cell'>Fundraising</td>
                    <td className='money-table-cell common-size-cell'>You</td>
                    <td className='money-table-cell common-size-cell'></td>
                    {calcsAvgType ? <td className='money-table-cell common-size-cell'>{props.compareWith}</td> : <td className='money-table-cell'></td>}
                    <td>Totals</td>
                    <td className='functional-common-size common-size-cell'>You</td>
                    {calcsAvgType ? <td className='common-size-arrow'></td> : null}
                    {calcsAvgType ? <td className='functional-common-size common-size-cell'>{props.compareWith}</td> : null}
                </tr>
            </thead>
        )
    }

    const commonSizeArrowSign = (youCommonSize, compareCommonSize, key = '') => {
        let commonSizeArrow = CommonSizeArrows(youCommonSize, compareCommonSize, key)
        if (commonSizeArrow === 'worse' || commonSizeArrow === 'worst') {
            return <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
        } else if (commonSizeArrow === 'better' || commonSizeArrow === 'best') {
            return <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
        }
    }

    // Just need metrics in the Operating Expsenses section that divide into Program Services, Admin, and Fundraising
    const buildRows = () => {
        let reportRows = []
        let sectionKeys = ['total_people_costs', 'total_taxes_and_benefits', 'total_corporate_culture', 'total_customer_costs', 'total_operations']
        pnlRows.forEach(row => {
            let expenseKeys = false
            if (row.children) {
                let childKeys = Object.keys(row.children).join('')
                if (childKeys.includes('admin') || childKeys.includes('program services') || childKeys.includes('fundraising')) {
                    expenseKeys = true
                }
            }

            if (expenseKeys) {
                let youRowTotal = 0
                let compareRowTotal = 0
                let youTotalRevenue = props['calcs']['you']['total_revenue'][props.year]
                let compareTotalRevenue = avg ? avg['total_revenue'][props.year] : null
                let youCommonSize = 0
                let compareCommonSize = 0
                let youProgramVal = '$0'
                let youAdminVal = '$0'
                let youFundraisingVal = '$0'
                let youProgramCommonSize = ''
                let youAdminValCommonSize = ''
                let youFundraisingValCommonSize = ''
                let compareProgramCommonSize = ''
                let compareAdminValCommonSize = ''
                let compareFundraisingValCommonSize = ''
                let arrowColumnProgram = ''
                let arrowColumnAdmin = ''
                let arrowColumnFundraising = ''
                let arrowColumnTotal = ''

                Object.keys(row.children).forEach(child => {
                    if (child.includes('program services')) {
                        youProgramServicesTotal += Number(props['calcs']['you'][child][props.year])
                        youProgramVal = parseMoney(props['calcs']['you'][child][props.year])
                        youRowTotal += props['calcs']['you'][child][props.year]
                        youFinalTotal += props['calcs']['you'][child][props.year]

                        if (avg && avg[child]) {
                            compareProgramServicesTotal += Number(avg[child][props.year])
                            compareRowTotal += Number(avg[child][props.year])
                            youProgramCommonSize = (Number(props['calcs']['you'][child][props.year]) / youTotalRevenue) * 100
                            compareProgramCommonSize = (avg[child][props.year] / compareTotalRevenue) * 100
                        }
                    } else if (child.includes('admin')) {
                        youAdminTotal += Number(props['calcs']['you'][child][props.year])
                        youAdminVal = parseMoney(props['calcs']['you'][child][props.year])
                        youRowTotal += props['calcs']['you'][child][props.year]
                        youFinalTotal += props['calcs']['you'][child][props.year]

                        if (avg && avg[child]) {
                            compareAdminTotal += Number(avg[child][props.year])
                            compareRowTotal += Number(avg[child][props.year])
                            youAdminValCommonSize = (Number(props['calcs']['you'][child][props.year]) / youTotalRevenue) * 100
                            compareAdminValCommonSize = (avg[child][props.year] / compareTotalRevenue) * 100
                        }
                    } else if (child.includes('fundraising')) {
                        youFundraisingTotal += Number(props['calcs']['you'][child][props.year])
                        youFundraisingVal = parseMoney(props['calcs']['you'][child][props.year])
                        youRowTotal += props['calcs']['you'][child][props.year]
                        youFinalTotal += props['calcs']['you'][child][props.year]

                        if (avg && avg[child]) {
                            compareFundraisingTotal += Number(avg[child][props.year])
                            compareRowTotal += Number(avg[child][props.year])
                            youFundraisingValCommonSize = (Number(props['calcs']['you'][child][props.year]) / youTotalRevenue) * 100
                            compareFundraisingValCommonSize = (avg[child][props.year] / compareTotalRevenue) * 100
                        }
                    }
                })

                arrowColumnProgram = compareProgramCommonSize ? commonSizeArrowSign(youProgramCommonSize, compareProgramCommonSize, row.key) : ''
                arrowColumnAdmin = compareAdminValCommonSize ? commonSizeArrowSign(youAdminValCommonSize, compareAdminValCommonSize, row.key) : ''
                arrowColumnFundraising = compareFundraisingValCommonSize ? commonSizeArrowSign(youFundraisingValCommonSize, compareFundraisingValCommonSize, row.key) : ''
                youCommonSize = youRowTotal ? (youRowTotal / youTotalRevenue) * 100 : 0
                compareCommonSize = compareRowTotal ? (compareRowTotal / compareTotalRevenue) * 100 : 0
                arrowColumnTotal = commonSizeArrowSign(youCommonSize, compareCommonSize, row.key)

                reportRows.push(
                    <tr key={`func-expense-${row.key}`}>
                        <td className='table-copy'>{row.padding + row.copy}</td>
                        <td className='money-table-cell'>{youProgramVal}</td>
                        <td className='money-table-cell common-size-cell'>{youProgramCommonSize ? youProgramCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='money-table-cell common-size-arrow'>{arrowColumnProgram}</td>
                        <td className='money-table-cell common-size-cell'>{compareProgramCommonSize ? compareProgramCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td>{youAdminVal}</td>
                        <td className='common-size-cell'>{youAdminValCommonSize ? youAdminValCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='common-size-arrow'>{arrowColumnAdmin}</td>
                        <td className='common-size-cell'>{compareAdminValCommonSize ? compareAdminValCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='money-table-cell'>{youFundraisingVal}</td>
                        <td className='money-table-cell common-size-cell'>{youFundraisingValCommonSize ? youFundraisingValCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='money-table-cell common-size-arrow'>{arrowColumnFundraising}</td>
                        <td className='money-table-cell common-size-cell'>{compareFundraisingValCommonSize ? compareFundraisingValCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td>{parseMoney(youRowTotal)}</td>
                        <td className='functional-common-size common-size-cell'>{youCommonSize ? youCommonSize.toFixed(1) + '%' : '-'}</td>
                        {avg ? <td className='common-size-arrow'>{arrowColumnTotal}</td> : null}
                        {avg ? <td className='functional-common-size common-size-cell'>{compareCommonSize ? compareCommonSize.toFixed(1) + '%' : '-'}</td> : null}
                    </tr>
                )
            }

            if (sectionKeys.includes(row.key)) {
                reportRows.push(
                    <tr key={`func-expense-${row.key}`}>
                        <td className='table-copy'>{row.padding + row.copy}</td>
                        <td className='money-table-cell'></td>
                        <td className='money-table-cell'></td>
                        <td className='money-table-cell common-size-cell'></td>
                        <td className='money-table-cell'></td>
                        <td></td>
                        <td></td>
                        <td className='common-size-cell'></td>
                        <td></td>
                        <td className='money-table-cell'></td>
                        <td className='money-table-cell'></td>
                        <td className='money-table-cell common-size-cell'></td>
                        <td className='money-table-cell'></td>
                        <td></td>
                        <td className='functional-common-size common-size-cell'></td>
                        {avg ? <td className='common-size-arrow'></td> : null}
                        {avg ? <td className='functional-common-size common-size-cell'></td> : null}
                    </tr>
                )
            }
        })
        return reportRows
    }

    // Final Row needs totals for Program Services, Admin, and Fundraising and Everything
    const buildYouFinalTotalsRow = () => {
        let youTotalRevenue = props.calcs['you']['total_revenue'][props.year]
        let youTotalCommonSize = (youFinalTotal / youTotalRevenue) * 100
        let youProgramServicesTotalCS = youProgramServicesTotal ? youProgramServicesTotal / youTotalRevenue * 100 : 0
        let youAdminTotalCS = youAdminTotal ? youAdminTotal / youTotalRevenue * 100 : 0
        let youFundraisingTotalCS = youFundraisingTotal ? youFundraisingTotal / youTotalRevenue * 100 : 0

        let compareTotalRevenue = avg ? avg['total_revenue'][props.year] : null
        let compareTotalCommonSize = compareTotalRevenue ? ((compareProgramServicesTotal + compareAdminTotal + compareFundraisingTotal) / compareTotalRevenue) * 100 : 0
        let compareProgramServicesTotalCS = compareProgramServicesTotal ? compareProgramServicesTotal / compareTotalRevenue * 100 : 0
        let compareAdminTotalCS = compareAdminTotal ? compareAdminTotal / compareTotalRevenue * 100 : 0
        let compareFundraisingTotalCS = compareFundraisingTotal ? compareFundraisingTotal / compareTotalRevenue * 100 : 0

        let arrowColumnTotal = compareTotalCommonSize ? commonSizeArrowSign(youTotalCommonSize, compareTotalCommonSize, '') : ''
        let arrowColumnProgram = compareProgramServicesTotalCS ? commonSizeArrowSign(youProgramServicesTotalCS, compareProgramServicesTotalCS, '') : ''
        let arrowColumnAdmin = compareAdminTotalCS ? commonSizeArrowSign(youAdminTotalCS, compareAdminTotalCS, '') : ''
        let arrowColumnFundraising = compareFundraisingTotalCS ? commonSizeArrowSign(youFundraisingTotalCS, compareFundraisingTotalCS, '') : ''

        return (
            <tr key={`func-expense-total`} style={{ 'borderTop': '1px solid' }}>
                <td className='table-copy'>TOTAL FUNCTIONAL EXPENSES</td>
                <td className='money-table-cell'>{parseMoney(youProgramServicesTotal)}</td>
                <td className='money-table-cell common-size-cell'>{youProgramServicesTotalCS ? youProgramServicesTotalCS.toFixed(1) + '%' : '-'}</td>
                <td className='money-table-cell common-size-arrow'>{arrowColumnProgram}</td>
                <td className='money-table-cell common-size-cell'>{compareProgramServicesTotalCS ? compareProgramServicesTotalCS.toFixed(1) + '%' : '-'}</td>
                <td>{parseMoney(youAdminTotal)}</td>
                <td className='common-size-cell'>{youAdminTotalCS ? youAdminTotalCS.toFixed(1) + '%' : '-'}</td>
                <td className='common-size-arrow'>{arrowColumnAdmin}</td>
                <td className='common-size-cell'>{compareAdminTotalCS ? compareAdminTotalCS.toFixed(1) + '%' : '-'}</td>
                <td className='money-table-cell'>{parseMoney(youFundraisingTotal)}</td>
                <td className='money-table-cell common-size-cell'>{youFundraisingTotalCS ? youFundraisingTotalCS.toFixed(1) + '%' : '-'}</td>
                <td className='money-table-cell common-size-arrow'>{arrowColumnFundraising}</td>
                <td className='money-table-cell common-size-cell'>{compareFundraisingTotalCS ? compareFundraisingTotalCS.toFixed(1) + '%' : '-'}</td>
                <td>{parseMoney(youFinalTotal)}</td>
                <td className='functional-common-size common-size-cell'>{youTotalCommonSize ? youTotalCommonSize.toFixed(1) + '%' : '-'}</td>
                {avg ? <td className='common-size-arrow'>{arrowColumnTotal}</td> : null}
                {avg ? <td className='functional-common-size common-size-cell'>{compareTotalCommonSize ? compareTotalCommonSize.toFixed(1) + '%' : '-'}</td> : null}
            </tr>
        )
    }

    const buildTable = () => {
        if (props.calcs) {
            if (props.pdf) {
                let sheetTitle = props.continued ? `${props.title} - ${props.clientName} (Continued)` : `${props.title} - ${props.clientName}`
                return (
                    <div className='landscape-view'>
                        <div className='report-pdf-landscape'>
                            <div>
                                <h1 className='report-table-title'>{sheetTitle}</h1>
                                <div className='money-pdf-table-div'>
                                    <table style={{ whiteSpace: 'pre' }} className='money-pdf-table' >
                                        {renderHeaderColumns()}
                                        <tbody>
                                            {buildRows()}
                                            {buildYouFinalTotalsRow()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                        </div>
                    </div>
                )
            } else {
                let tableID = `new-annual-table-naics_focused`
                return (
                    <div className='money-report-card'>
                        <div className='money-report-pdf'>
                        <div className='money-report-container'>
                                <table id={tableID} style={{ whiteSpace: 'pre' }} className='report-table' >
                                    {renderHeaderColumns()}
                                    <tbody>
                                        {buildRows()}
                                        {buildYouFinalTotalsRow()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    return (buildTable())
}

export default FunctionalExpenses;