import React, { useState } from 'react';
import Modal from 'react-modal';
import AddAndEditDashboardView from './AddAndEditDashboardView';
import DashboardViewsEditor from './DashboardViewsEditor';

const DashboardViewModal = (props) => {
    const buildDashboadView = () => {
        if (props.dashboardViewModalState.action === 'add_view') {
            return <AddAndEditDashboardView dashboardViewModalState={props.dashboardViewModalState} handleDashboardViewModal={props.handleDashboardViewModal} createDashBoardView={props.createDashBoardView} report={props.report} />
        }
        if (props.dashboardViewModalState.action === 'edit_view') {
            return <AddAndEditDashboardView dashboardViewModalState={props.dashboardViewModalState} handleDashboardViewModal={props.handleDashboardViewModal} updateDashBoardView={props.updateDashBoardView} report={props.report} />
        }
        if (props.dashboardViewModalState.action === 'all_views') {
            return <DashboardViewsEditor dashboardViewModalState={props.dashboardViewModalState} handleDashboardViewModal={props.handleDashboardViewModal} deleteDashBoardView={props.deleteDashBoardView} updateDashBoardView={props.updateDashBoardView} report={props.report} page={props.page} />
        }
    }

    return (
        <Modal
            className={{
                base: 'dashboard-view-modal-content hide-on-print',
                afterOpen: 'dashboard-view-modal-content_after-open hide-on-print',
                beforeClose: 'dashboard-view-modal-content_before-close hide-on-print',
            }}
            overlayClassName={{
                base: 'dashboard-view-overlay-base hide-on-print',
                afterOpen: 'dashboard-view-overlay-base_after-open hide-on-print',
                beforeClose: 'dashboard-view-overlay-base_before-close hide-on-print',
            }}
            ariaHideApp={false}
            closeTimeoutMS={0}
            contentLabel='Create Dashboard View Modal'
            isOpen={props.dashboardViewModalState.isOpen}
            onRequestClose={() => props.handleDashboardViewModal('close')}
            shouldCloseOnOverlayClick={true}
        >
            {buildDashboadView()}
        </Modal>
    )
}

export default DashboardViewModal;