import React, { useState } from "react";
import UserPermissionsForm from "./UserPermissionsForm";
import { toast } from 'react-toastify';

const NewUserForm = (props) => {
    // prevent object mutation by Updating userPermissions state
    const filterOptions = _.cloneDeep(props.filter_options_obj)
    const [formData, setFormData] = useState({
        email: '',
        sso_only: false,
        first_name: '',
        last_name: '',
        position: '',
        user_type: 'Admin',
        company_id: null,
        email_match: true,
        error: ''
    })

    const [userPermissions, setUserPermissions] = useState({
        isOpen: false,
        allCompanies: props.all_companies,
        excludedClients: [],
        includeAll: true,
        includeOptions: filterOptions,
        excludeOptions: {
            naics_code: [],
            partners: [],
            office: [],
            region: []
        }
    })

    const handleUserForm = (type, value) => {
        if (type === 'email') {
            let emailMatch = props.email_ends.filter(e => value.includes(e))
            let userType = 'Client'
            let companyID = null
            let error = ''
            if (emailMatch.length > 0) {
                userType = 'Admin'
            } else {
                companyID = props.all_companies.length > 0 ? props.all_companies[0].id : null
                error = companyID ? '' : 'Please create a new Client first.'
            }

            setFormData((prevState) => {
                let newUserData = _.cloneDeep(formData)
                newUserData.email = value
                newUserData.email_match = emailMatch.length > 0
                newUserData.user_type = userType
                newUserData.company_id = companyID
                newUserData.error = error
                return newUserData
            })
        } else if (type === 'user_type') {
            if (props.all_companies.length > 0) {
                setFormData((prevState) => {
                    let newUserData = _.cloneDeep(formData)
                    newUserData.error = ''
                    newUserData.user_type = value
                    newUserData.company_id = value !== 'Client' ? null : props.all_companies[0].id
                    return newUserData
                })
            } else {
                setFormData((prevState) => {
                    let newUserData = _.cloneDeep(formData)
                    newUserData.error = 'Please create a new Client first.'
                    return newUserData
                })
            }
        } else {
            setFormData((prevState) => {
                let newUserData = _.cloneDeep(formData)
                newUserData[type] = value
                newUserData.error = ''
                return newUserData
            })
        }
    }

    const createNewUser = async (e) => {
        e.preventDefault()
        let changes = true
        if ((!formData.email_match && formData.user_type === 'Admin') || (!formData.email_match && formData.user_type === 'Team Member')) {
            changes = confirm('Are you sure you want new user to have access to all clients?');
        }
        if (changes) {
            let companyIds = userPermissions.excludedClients.map(c => c.id)
            const newUserData = {
                email: formData.email,
                sso_only: formData.sso_only,
                first_name: formData.first_name,
                last_name: formData.last_name,
                position: formData.position,
                user_type: formData.user_type,
                company_id: formData.company_id,
                using_navbar: props.using_navbar,
                excluded_clients: companyIds,
                excluded_client_options: userPermissions.excludeOptions
            }

            try {
                const res = await fetch('/clients', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': window.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newUserData),
                })
                const resJson = await res.json();

                if (resJson.success && !props.using_navbar) {
                    window.location.href = resJson.redirect_to
                } else if (resJson.success && props.using_navbar) {
                    toast.success('New User Invited!', {
                        onClick: () => toast.dismiss(),
                    });
                } else if (resJson.error) {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            error: resJson.error
                        }
                    })
                }
            } catch (error) {
                console.log(error, 'error')
            }
        }
    }

    return (
        <div className='new-user-form-container'>
            <form onSubmit={(e) => createNewUser(e)}>
                <div className='form-inputs'>
                    <div className='modal-info required'>
                        <label className='modal-label'>Email<abbr title='required'>*</abbr></label>
                        <input className='form-control' type='email' required aria-required onChange={(e) => handleUserForm('email', e.target.value)} value={formData.email} />
                    </div>
                    {/* <div className='modal-info'>
                            <span><b>For Intuit/QuickBooks Online Users Only</b>: If you want your new user to login into Peerview Data with their Intuit account, please use the same email associated with their Intuit account.</span>
                            <div className='sso-only-form-container'>
                                <span>Is the email above the same as your new user's Intuit email?</span>
                                <div className='sso-only-inputs'>
                                    <div key="yes-checkbox" className='sso-only-checkbox-input'>
                                        <label className='sso-only-label' htmlFor="yes-checked">Yes</label>
                                        <input id="yes-checked" type='checkbox' name="yes-checked" checked={formData.sso_only === true} onChange={(e) => handleUserForm('sso_only', true)} />
                                    </div>
                                    <div key="no-checkbox" className='sso-only-checkbox-input'>
                                        <label className='sso-only-label' htmlFor="no-checked">No</label>
                                        <input id="no-checked" type='checkbox' name="no-checked" checked={formData.sso_only === false} onChange={(e) => handleUserForm('sso_only', false)} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    <div className='modal-info required'>
                        <label className='modal-label'>First Name<abbr title='required'>*</abbr></label>
                        <input className='form-control' type='text' required aria-required onChange={(e) => handleUserForm('first_name', e.target.value)} value={formData.first_name} />
                    </div>
                    <div className='modal-info required'>
                        <label className='modal-label'>Last Name<abbr title='required'>*</abbr></label>
                        <input className='form-control' type='text' required aria-required onChange={(e) => handleUserForm('last_name', e.target.value)} value={formData.last_name} />
                    </div>
                    <div className='modal-info'>
                        <label className='modal-label'>Position</label>
                        <input className='form-control' type='text' onChange={(e) => handleUserForm('position', e.target.value)} value={formData.position} />
                    </div>
                    <div className='modal-info'>
                        <label className='modal-label'>User Role</label>
                        <select
                            style={{ width: '100%', fontSize: '14px' }}
                            onChange={({ target: { value } }) => handleUserForm('user_type', value)}
                            value={formData.user_type}
                        >
                            {['Admin', 'Team Member', 'Client'].map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    {formData.user_type === 'Client' && <div className='modal-info'>
                        <label className='modal-label'>Invite User to Which Client?</label>
                        <select
                            style={{ width: '100%', fontSize: '14px' }}
                            onChange={({ target: { value } }) => handleUserForm('company_id', value)}
                            value={formData.company_id}
                        >
                            {props.all_companies.map((c) => (
                                <option key={c.name} value={c.id}>
                                    {c.real_name ? `${c.name}, ${c.real_name}` : c.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    }
                </div>
                {formData.user_type !== 'Client' && <div>
                    <UserPermissionsForm
                        modal={false}
                        allFilterOptions={props.filter_options_obj}
                        userPermissions={userPermissions}
                        setUserPermissions={setUserPermissions}
                    />
                </div>}
                <p className='error-message'>{formData.error}</p>
                <div className='form-actions'>
                    <button className='btn btn-primary' type='submit'>Create User</button>
                </div>
            </form>
        </div>
    )
}

export default NewUserForm;