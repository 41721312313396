import React, { useState, useEffect, useRef } from 'react';
import LoaderGraphic from '../utils/LoaderGraphic';
import Filler from './Filler';

const ProgressBar = (props) => {
  // useRef Deal with deprecated componentWillReceiveProps
  const useWillReceiveProps = useRef(props);
  const [percentage, setPercentage] = useState(0)

  useEffect((() => {
    const { error, finish, percentage } = useWillReceiveProps;
    if (props.noActionCable) {
      setInterval(() => {
        if (props.jobID) {
          getSidekiqJobStatus()
        }
      }, 1500);
    } else {
      if (error) props.unmount({ failure: true });
      if (finish || (props.updatedProps && props.updatedProps.finish)) {
        setPercentage(100)
        setTimeout(() => {
          props.unmount({ failure: false, refresh: props.refresh })
        }, 1500);
      }
      if (percentage) setPercentage(percentage)
  
      setPercentage(35)
      if (!props.parentWillUpdate) {
        subscribe()
      }
    }
  }), [props])

  const getSidekiqJobStatus = async () => {
    const response = await fetch(`/sidekiq_status/${props.jobID}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET',
    });
    const res = await response.json();

    console.log(res, 'sidekiqStatusRes')
    if (res['job_data']) {
      let percentage = Number(res['job_data']['at'])
      setPercentage(percentage)
      if (percentage === 100) {
        props.unmount({ failure: false, refresh: props.refresh })
      }
    } else {
      console.log(res);
      props.unmount({ failure: true });
    }
  }

  const subscribe = () => {
    console.log('hit subscribe!')
    App.progessBar = App.cable.subscriptions.create(
      {
        channel: 'JobsChannel',
        job_type: props.job,
      },
      {
        received: (res) => {
          console.log(res, 'res')
          if (res.error) {
            App.progessBar.unsubscribe();
            props.unmount({ failure: true });
          } else {
            if (res.finish) {
              console.log(res, 'res')
              // res = {finish: true, data: null}
              App.progessBar.unsubscribe();
              setPercentage(100);
              setTimeout(() => {
                props.unmount({ failure: false, refresh: props.refresh })
              }, 1500);
            } else {
              setPercentage(50);
            }
          }
        }
      }
    )
    console.log(App.progessBar, 'App.progessBar')
  }

  return (
    <div className='progress-bar-container'>
      <div className='progress-percentage'>
        <span>Working on calculations...</span>
        <LoaderGraphic />
      </div>
      <div className='progress-bar'>
        <Filler fillerClass={'filler'} percentage={percentage} />
      </div>
    </div>
  )
}

export default ProgressBar;