import React from 'react';
import { read } from 'xlsx';
import Extractor from '../utils/Extractor';

export default class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.dropzone = React.createRef();

    this.state = {
      loading: false,
      error: null,
      extractError: null,
      headerError: null,
      filename: null
    }
  }

  handleFile = async (file) => {
    this.setState({
      filename: file.name,
      loading: true,
    })

    const { id, headers, error, message } = await this.postFile(file);
    if (error) {
      this.setState({ error: message })
    } else {
      this.fduId = id;
      this.readFile(file, headers);
    }
  }

  handleComplete = (fdu) => {
    this.setState({ loading: false });
    this.props.notify(`${JSON.parse(fdu.document)} uploaded for ${this.props.name}. You’ll be notified when the reports are ready to view or you can go ahead and map it.`);
    this.props.addFdu(fdu);
  }

  handleExtractError = (error) => {
    if (error) {
      this.setState({ extractError: 'Could not extract rows. Please remove any formatting, extra rows, and check that your headers columns match the values.' })
    }
  }

  handleHeaderError = (missingCols) => {
    this.setState({
      headerError: 'Missing Headers: ' + missingCols.join(', '),
    })
  }

  readFile = (file, headers = null) => {
    const { id, financialReportType } = this.props;
    const { handleHeaderError, handleComplete, handleExtractError, fduId } = this;
    const reader = new FileReader();
    reader.onload = function (e) {

      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const extractor = new Extractor({
        workbook,
        headers,
        fduId,
        clientId: id,
        financialReportType: financialReportType
      });

      extractor.onComplete = handleComplete;
      extractor.onHeaderError = handleHeaderError;
      extractor.onExtractError = handleExtractError;
      extractor.extract();
    };

    reader.readAsArrayBuffer(file);
  }

  postFile = async (file) => {
    const formData = new FormData();
    formData.append('attachment[document]', file);
    formData.append('attachment[company_id]', this.props.id);
    formData.append('attachment[financial_report_type]', this.props.financialReportType)

    const response = await fetch('/api/v1/financial_data_uploads', {
      headers: { 'X-CSRF-Token': window.token },
      method: 'POST',
      body: formData
    })
    const fdu = await response.json();
    return fdu;
  }

  onDragOver = (e) => {
    document.getElementById(`dropzone-${this.props.id}`).style.background = '#e6e6e6';
    e.preventDefault();
  }

  onDragLeave = () => {
    document.getElementById(`dropzone-${this.props.id}`).style.background = 'white';
  }

  onDrop = (e) => {
    e.preventDefault();
    document.getElementById(`dropzone-${this.props.id}`).style.background = 'white';
    this.handleFile(e.dataTransfer.files[0])
  }

  addFile = (e) => {
    this.handleFile(e.target.files[0])
  }

  render() {
    const { error, extractError, headerError, loading, filename } = this.state;
    if (error) {
      return (
        <div className='dropzone dropzone-error'>
          {error}
        </div>
      )
    }

    if (headerError) {
      return (
        <div className='dropzone dropzone-header-error'>
          {headerError}
          <div>
            <a href={this.props.hrefBuilder(this.props.subdomain, `/financial_data_uploads/${this.fduId}/edit`)}>Help Us Find Them</a>
          </div>
        </div>
      )
    }

    if (extractError) {
      return (
        <div className='dropzone dropzone-error'>
          {extractError}
          <div>
            <div>Check The File's Rows, Close & Open This Client's Details Pop-up and Re-Upload.</div>
          </div>
        </div>
      )
    }

    if (loading) {
      return (
        <div className='dropzone dropzone-loading'>
          Processing {filename}
        </div>
      )
    }

    if (error === null && extractError === null && headerError === null && filename !== null && loading === false) {
      let uploadMessage = this.props.financialReportType === "trial_balance" ? "Upload Another File" : this.props.financialReportType === "pnl" ? "Upload Another P&L" : "Upload Another Balance Sheet"
      return (
        <label className='modal-import-section green-background-btn' htmlFor={`file-upload-${this.props.id}`}>
          <input id={`file-upload-${this.props.id}`} className='file-upload-input' type='file' onChange={this.addFile} />
          <span>{uploadMessage}</span>
        </label>
      )
    }

    return (
      <label className='import-file-modal-btn modal-import-section green-background-btn' htmlFor={`file-upload-${this.props.id}-${this.props.financialReportType}`}>
        <input id={`file-upload-${this.props.id}-${this.props.financialReportType}`} className='file-upload-input' type='file' onChange={this.addFile} />
        <span>{this.props.financialReportType === "trial_balance" ? "Click to Import Trial Balance" : this.props.financialReportType === "pnl" ? "Import P&L" : "Import Balance Sheet"}</span>
      </label>
    )
  }
}
