const defaultLayouts = {
    money_peerview_charts: [
        { i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: false, x: 0, y: 0, w: 6, h: 1, minW: 6, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
        { i: 'quadrant_growth_profitability', isBounded: false, isDraggable: false, isResizable: true, x: 0, y: 1, w: 3, h: 2, minW: 1, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
        { i: 'quadrant_leverage_cash_flow', isBounded: false, isDraggable: false, isResizable: true, x: 3, y: 1, w: 3, h: 2, minW: 1, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
        { i: 'you_pie', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 1, w: 3, h: 2, minW: 1, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
        { i: 'compare_pie', isBounded: false, isDraggable: true, isResizable: true, x: 3, y: 1, w: 3, h: 2, minW: 1, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
    ],
    people_peerview_charts: [
        { i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: false, x: 0, y: 0, w: 6, h: 1, minW: 6, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
        { i: 'you_compare_stacked_column', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 1, w: 3, h: 2, minW: 1, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
    ],
    customers_peerview_charts: [
        { i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: false, x: 0, y: 0, w: 6, h: 1, minW: 6, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false }
    ]
}

let editableChartStyleDefault = {
    seriesOne: {
        type: 'column',
    },
    seriesTwo: {
        type: 'column',
    }
}

const moneyCharts = {
    peerview_charts: [
        {
            chartType: 'textarea',
            copy: 'Notes',
            key: 'my_notes',
            relatedCheckedKey: 'my_notes',
            checked: false,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            chartType: 'gauges',
            copy: 'Peerview Metric Gauges',
            key: 'peerview_gauges',
            relatedCheckedKey: 'peerview_gauges',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            dragTogglable: true,
            chartType: 'quadrantBubble',
            copy: 'Growth vs. Profitability Chart',
            key: 'quadrant_growth_profitability',
            relatedCheckedKey: 'quadrant_growth_profitability',
            keys: ['growth', 'profitability'],
            subtitle: '(Peerview Metrics)',
            x: 'Growth',
            y: 'Profitability',
            checked: false,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            dragTogglable: true,
            chartType: 'quadrantBubble',
            copy: 'Leverage & Cash Flow Chart',
            key: 'quadrant_leverage_cash_flow',
            relatedCheckedKey: 'quadrant_leverage_cash_flow',
            keys: ['leverage', 'cash_flow'],
            subtitle: '(Peerview Metrics)',
            x: 'Leverage',
            y: 'Cash Flow',
            checked: false,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            dragTogglable: true,
            chartType: 'pie',
            copy: 'Your Expenses Chart',
            key: 'you_pie',
            relatedCheckedKey: 'you_pie',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            dragTogglable: true,
            chartType: 'pie',
            copy: 'Peer Expenses Chart',
            key: 'compare_pie',
            relatedCheckedKey: 'compare_pie',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            displayAll: true,
            chartType: 'stackedBars',
            format: 'money',
            copy: 'Accounts Receivable Aging Summary Chart',
            key: 'ar_aging_summary',
            relatedCheckedKey: 'ar_aging_summary',
            checked: false,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            displayAll: true,
            chartType: 'stackedBars',
            format: 'money',
            copy: 'Accounts Payable Aging Summary Chart',
            key: 'ap_aging_summary',
            relatedCheckedKey: 'ap_aging_summary',
            checked: false,
            displayChart: true,
            sheet: 'peerview_charts',
        },
    ],
    money: [
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'Total Revenue Growth Rate Chart',
            key: 'total_revenue_growth_rate',
            relatedCheckedKey: 'total_revenue',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'COGS Chart',
            key: 'total_costs_common_size_revenue',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'total_costs',
            relatedCheckedKey: 'total_costs',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'Gross Profit Margin Chart',
            key: 'gross_margin',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'total_costs',
            relatedCheckedKey: 'gross_profit',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'OpEx Chart',
            key: 'total_operating_expenses_common_size_revenue',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'total_operating_expenses',
            relatedCheckedKey: 'total_operating_expenses',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'Net Profit Margin Chart',
            key: 'profit_margin',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'operating_expenses_net_income',
            relatedCheckedKey: 'operating_expenses_net_income',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'ROA Chart',
            key: 'roa',
            subtitle: '(Return on Assets)',
            trailing12Key: 'Return on Assets (ROA)',
            relatedCheckedKey: 'roa',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'ROE Chart',
            key: 'roe',
            subtitle: '(Return on Equity)',
            trailing12Key: 'Return on Equity (ROE)',
            relatedCheckedKey: 'roe',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'ROCE Chart',
            key: 'roce',
            subtitle: '(Return on Capital Employed)',
            trailing12Key: 'Return on Capital Employed (ROCE)',
            relatedCheckedKey: 'roce',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            copy: 'ROI Chart',
            key: 'mkt_roi',
            subtitle: '(Return on Investment)',
            trailing12Key: 'ROI',
            relatedCheckedKey: 'capital_stock',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            copy: 'Asset Turnover Ratio Chart',
            key: 'asset_turnover_ratio',
            trailing12Key: 'Asset Turnover Ratio',
            relatedCheckedKey: 'asset_turnover_ratio',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            copy: 'Cash Chart',
            key: 'cash',
            relatedCheckedKey: 'cash',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            copy: 'Net Working Capital Ratio Chart',
            key: 'net_working_capital_ratio',
            relatedCheckedKey: 'net_working_capital_ratio',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            copy: 'Net Working Capital Chart',
            key: 'net_working_capital',
            relatedCheckedKey: 'net_working_capital',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            copy: 'Current Ratio Chart',
            key: 'current_ratio',
            relatedCheckedKey: 'current_ratio',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            copy: 'Debt / Equity Chart',
            key: 'debt_to_equity',
            trailing12Key: 'Debt / Equity',
            relatedCheckedKey: 'debt_to_equity',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            copy: 'Senior Debt / EBITDA Chart',
            key: 'senior_debt_to_ebitda',
            trailing12Key: 'Senior Debt / EBITDA',
            relatedCheckedKey: 'senior_debt_to_ebitda',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            copy: 'EBITDA / Annual Interest Chart',
            key: 'ebitda_to_annual_interest',
            trailing12Key: 'EBITDA / Annual Interest',
            relatedCheckedKey: 'ebitda_to_annual_interest',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            copy: 'Accounts Receivable Days Chart',
            key: 'ar_days',
            trailing12Key: 'Accounts Receivable Days',
            relatedCheckedKey: 'accounts_receivable',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            copy: 'Inventory Turns Chart',
            key: 'inventory_turnover_ratio',
            trailing12Key: 'Inventory Turnover Ratio',
            relatedCheckedKey: 'inventory',
            checked: false
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            copy: 'Account Payable Days Chart',
            key: 'ap_days',
            trailing12Key: 'Accounts Payable Days',
            relatedCheckedKey: 'accounts_payable',
            checked: false
        },

    ],
}

const peopleCharts = {
    peerview_charts: [
        {
            chartType: 'gauges',
            copy: 'Peerview Metric Gauges',
            key: 'peerview_gauges',
            relatedCheckedKey: 'peerview_gauges',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
        },
        {
            chartType: 'stackedColumns',
            copy: 'Expenses Comparison Chart',
            key: 'you_compare_stacked_column',
            relatedCheckedKey: 'you_compare_stacked_column',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
        }
    ],
}

const customerCharts = {
    peerview_charts: [
        {
            chartType: 'gauges',
            copy: 'Peerview Metric Gauges',
            key: 'peerview_gauges',
            relatedCheckedKey: 'peerview_gauges',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
        },
    ],
    selected_items: [
        {
            formSection: 'Peerview Metrics',
            chartType: 'gauges',
            copy: 'Peerview Metric Gauges',
            key: 'peerview_gauges',
            relatedCheckedKey: 'peerview_gauges',
            checked: true,
            displayChart: true,
            sheet: 'peerview_charts',
            label: 'Peerview Metrics',
            checked: true
        },
        {
            formSection: 'Spend', key: 'total_revenue', label: 'Revenue', checked: true, sheet: 'financial_detail',
            children: [
                { chartType: 'editableChart', key: 'total_revenue', label: 'Total Revenue Growth Rate Chart', checked: true },
                { key: 'total_revenue_impact_box', label: 'Revenue Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Spend', key: 'gross_profit', label: 'Gross Profit', checked: true, sheet: 'financial_detail',
            children: [
                { key: 'gross_profit', label: 'Gross Profit Chart', checked: true },
                { key: 'gross_profit_impact_box', label: 'Gross Profit Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Spend', key: 'operating_expenses_net_income', label: 'Net Income', checked: true, sheet: 'financial_detail',
            children: [
                { key: 'operating_expenses_net_income', label: 'Net Income Chart', checked: true },
                { key: 'operating_expenses_net_income_impact_box', label: 'Net Income Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Spend', key: 'total_customer_costs_sales_& marketing', label: 'Sales & Marketing', checked: true, sheet: 'financial_detail',
            children: [
                { key: 'total_customer_costs_sales_& marketing', label: 'Sales & Marketing Chart', checked: true },
                { key: 'total_customer_costs_sales_& marketing_impact_box', label: 'Sales & Marketing Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Spend', key: 'travel_entertainment_and_meals', label: 'Travel, Entertainment & Meals', checked: true, sheet: 'financial_detail',
            children: [
                { key: 'travel_entertainment_and_meals', label: 'Travel, Entertainment & Meals Chart', checked: true },
                { key: 'travel_entertainment_and_meals_impact_box', label: 'Travel, Entertainment & Meals Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Spend', key: 'total_customer_costs_billings_& collections', label: 'Professional Fees - Customers', checked: true, sheet: 'financial_detail',
            children: [
                { key: 'total_customer_costs_billings_& collections', label: 'Professional Fees - Customers Chart', checked: true },
                { key: 'total_customer_costs_billings_& collections_impact_box', label: 'Professional Fees - Customers Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Spend', key: 'customers_misc_professional_fees', label: 'Professional Fees - Customers', checked: true, sheet: 'financial_detail',
            children: [
                { key: 'customers_misc_professional_fees', label: 'Professional Fees - Customers Chart', checked: true },
                { key: 'customers_misc_professional_fees_impact_box', label: 'Professional Fees - Customers Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Volatility', key: 'revenue_per_customer', label: 'Revenue Per Client', checked: true, sheet: 'kpi',
            children: [
                { key: 'revenue_per_customer', label: 'Revenue Per Client Chart', checked: true },
                { key: 'revenue_per_customer_impact_box', label: 'Revenue Per Client Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Volatility', key: 'gross_profit_per_customer', label: 'Gross Profit Per Client', checked: true, sheet: 'kpi',
            children: [
                { key: 'gross_profit_per_customer', label: 'Gross Profit Per Client Chart', checked: true },
                { key: 'gross_profit_per_customer_impact_box', label: 'Gross Profit Per Client Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Volatility', key: 'operating_expenses_net_income_per_customer', label: 'Net Profit Per Client', checked: true, sheet: 'kpi',
            children: [
                { key: 'operating_expenses_net_income_per_customer', label: 'Net Profit Per Client Chart', checked: true },
                { key: 'operating_expenses_net_income_per_customer_impact_box', label: 'Net Profit Per Client Impact Box', checked: true },
            ]
        },
        {
            formSection: 'ROI', key: 'customer_roi', label: 'Marketing ROI', checked: true, sheet: 'kpi',
            children: [
                { key: 'customer_roi', label: 'Marketing ROI Chart', checked: true },
                { key: 'customer_roi_impact_box', label: 'Marketing ROI Impact Box', checked: true },
            ]
        },
        {
            formSection: 'ROI', key: 'number_of_new_customers_needed_to_maintain_growth', label: 'Growth Requirements', checked: true, sheet: 'kpi',
            children: [
                { key: 'number_of_new_customers_needed_to_maintain_growth', label: 'Growth Requirements Chart', checked: true },
                { key: 'number_of_new_customers_needed_to_maintain_growth_impact_box', label: 'Growth Requirements Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Concentration', key: 'percent_of_sales_from_the_top_10_percent', label: 'Customer Concentration - 10%', checked: true, sheet: 'kpi',
            children: [
                { key: 'percent_of_sales_from_the_top_10_percent', label: 'Customer Concentration - 10% Chart', checked: true },
                { key: 'percent_of_sales_from_the_top_10_percent_impact_box', label: 'Customer Concentration - 10% Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Concentration', key: 'percent_of_sales_from_the_top_25_percent', label: 'Customer Concentration - 25%', checked: true, sheet: 'kpi',
            children: [
                { key: 'percent_of_sales_from_the_top_25_percent_chart', label: 'Customer Concentration - 25% Chart', checked: true },
                { key: 'percent_of_sales_from_the_top_25_percent_impact_box', label: 'Customer Concentration - 25% Impact Box', checked: true },
            ]
        },
        {
            formSection: 'Concentration', key: 'percent_of_sales_from_the_top_50_percent', label: 'Customer Concentration - 50%', checked: true, sheet: 'kpi',
            children: [
                { key: 'percent_of_sales_from_the_top_50_percent_chart', label: 'Customer Concentration - 50% Chart', checked: true },
                { key: 'percent_of_sales_from_the_top_50_percent_impact_box', label: 'Customer Concentration - 50% Impact Box', checked: true },
            ]
        },
    ],
    dashboard_items: [
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'total_revenue'
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'gross_profit',
            percentOfRevenueKey: 'total_costs',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'operating_expenses_net_income',
            percentOfRevenueKey: 'operating_expenses_net_income',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'revenue_per_customer',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'gross_profit_per_customer',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'operating_expenses_net_income_per_customer',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'total_customer_costs_common_size_revenue'
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'customer_roi'
        },
        // {
        //     // only years
        //     displayAll: false,
        //     chartType: 'horizontalBars',
        //     format: 'percent',
        //     key: 'customer_concentration',
        //     keys: ['percent_of_sales_from_the_top_10_percent', 'percent_of_sales_from_the_top_25_percent', 'percent_of_sales_from_the_top_50_percent'],
        //     title: 'Customer Concentration',
        //     subtitle: '(Percent of Sales from Percent of Customers)',
        //     yearOnly: true
        // },
        // {
        //     displayAll: true,
        //     chartType: 'verticalBars',
        //     format: 'decimal',
        //     key: 'number_of_new_customers_needed_to_maintain_growth',
        //     title: 'Growth Requirements',
        //     subtitle: '(# of Additional Customers Needed to Maintain Growth)'
        // },
        // {
        //     // only years
        //     displayAll: false,
        //     chartType: 'lines',
        //     format: 'money',
        //     key: 'customer_retention',
        //     keys: ['cltv_70', 'cltv_80', 'cltv_90'],
        //     title: 'Customer Retention Value',
        //     subtitle: '(Based on Annual Retention Rate over 7-Yr Period)',
        //     yearOnly: true
        // }
    ],
    nested_dashboard_layout: [
        { i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 4, moved: false, resizeHandles: ['se'], static: false },
        {
            i: 'total_revenue', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'total_revenue_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'total_revenue_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'gross_profit', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 2, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'gross_profit_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'gross_profit_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ],
        },
        {
            i: 'operating_expenses_net_income', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 4, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'operating_expenses_net_income_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'operating_expenses_net_income_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'total_customer_costs_sales_& marketing', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 6, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'total_customer_costs_sales_& marketing_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'total_customer_costs_sales_& marketing_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'travel_entertainment_and_meals', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'travel_entertainment_and_meals_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'travel_entertainment_and_meals_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'total_customer_costs_billings_& collections', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'total_customer_costs_billings_& collections_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'total_customer_costs_billings_& collections_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'revenue_per_customer', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'revenue_per_customer_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'revenue_per_customer_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'gross_profit_per_customer', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'gross_profit_per_customer_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'gross_profit_per_customer_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'operating_expenses_net_income_per_customer', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'operating_expenses_net_income_per_customer_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'operating_expenses_net_income_per_customer_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'customer_roi', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'customer_roi_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'customer_roi_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'number_of_new_customers_needed_to_maintain_growth', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'number_of_new_customers_needed_to_maintain_growth_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'number_of_new_customers_needed_to_maintain_growth_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'percent_of_sales_from_the_top_10_percent', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'percent_of_sales_from_the_top_10_percent_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'percent_of_sales_from_the_top_10_percent_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'percent_of_sales_from_the_top_25_percent', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'percent_of_sales_from_the_top_25_percent_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'percent_of_sales_from_the_top_25_percent_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
        {
            i: 'percent_of_sales_from_the_top_50_percent', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
            layout: [
                { i: 'percent_of_sales_from_the_top_50_percent_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                { i: 'percent_of_sales_from_the_top_50_percent_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
            ]
        },
    ]
}

const pageCharts = {
    industryMoney: [
        {
            chartType: 'line',
            format: 'percent',
            key: 'total_revenue_growth_rate',
            subtitle: '(Annual Growth Rate, by %)',
        },
        {
            chartType: 'line',
            format: 'percent',
            key: 'total_costs_common_size_revenue',
            subtitle: '(By % of Total Revenue)',
        },
        {
            chartType: 'line',
            format: 'percent',
            key: 'total_operating_expenses_common_size_revenue',
            subtitle: '(By % of Total Revenue)',
        },
        {
            chartType: 'line',
            format: 'percent',
            key: 'profitability',
            subtitle: '(By % of Total Revenue)',
        },

        {
            displayAll: true,
            chartType: 'line',
            format: 'percent',
            key: 'roe',
            subtitle: '(Return on Equity)',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'percent',
            key: 'roce',
            subtitle: '(Return on Capital Employed)',
            trailing12Key: 'Return on Capital Employed (ROCE)',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'percent',
            key: 'gross_margin'
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'percent',
            key: 'profit_margin'
        },

        {
            displayAll: true,
            chartType: 'line',
            format: 'ratio',
            key: 'current_ratio',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'ratio',
            key: 'debt_to_equity',
            trailing12Key: 'Debt / Equity',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'ratio',
            key: 'senior_debt_to_ebitda',
            trailing12Key: 'Senior Debt / EBITDA',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'ratio',
            key: 'ebitda_to_annual_interest',
            trailing12Key: 'EBITDA / Annual Interest',
        },

        {
            displayAll: true,
            chartType: 'line',
            format: 'decimal',
            key: 'inventory_turnover_ratio',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'decimal',
            key: 'asset_turnover_ratio',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'decimal',
            key: 'ar_days',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'decimal',
            key: 'ap_days',
        },
        {
            displayAll: true,
            chartType: 'line',
            format: 'decimal',
            key: 'ap_days',
        },
        {
            displayAll: true,
            chartType: 'donut',
            format: 'percent',
            key: 'donut_pie_industry_expenses',
        },
    ],
    industryPeople: [
        {
            chartType: 'line',
            key: 'turnover_rate',
            format: 'percent',
            subtitle: '(Company Wide, All Employees)',
        },
        {
            chartType: 'line',
            key: 'total_revenue_per_employee',
            format: 'money',
        },
        {
            chartType: 'line',
            key: 'operating_expenses_net_income_per_employee',
            format: 'money',
        },
        {
            chartType: 'line',
            key: 'sum_compensation_growth_rate',
            subtitle: '(Annual Growth Rate, by %)',
            format: 'percent',
        },
        {
            chartType: 'line',
            key: 'sum_taxes_and_benefits_growth_rate',
            subtitle: '(Annual Growth Rate, by %)',
            format: 'percent',
        },
        {
            chartType: 'line',
            key: 'sum_subcontractors_growth_rate',
            subtitle: '(Annual Growth Rate, by %)',
            format: 'percent'
        },
        {
            chartType: 'wageDataScaffoldTable',
            key: 'wage_data_scaffold_table',
            format: 'money',
        }
    ],
    industryCustomers: [
        {
            chartType: 'line',
            key: 'revenue_per_customer',
            format: 'money',
        },
        {
            chartType: 'line',
            key: 'gross_profit_per_customer',
            format: 'money',
        },
        {
            chartType: 'line',
            key: 'operating_expenses_net_income_per_customer',
            format: 'money',
        },
        {
            chartType: 'horizontalBars',
            format: 'percent',
            key: 'customer_concentration',
            keys: ['percent_of_sales_from_the_top_10_percent', 'percent_of_sales_from_the_top_25_percent', 'percent_of_sales_from_the_top_50_percent'],
            title: 'Customer Concentration',
            subtitle: '(Percent of Sales from Percent of Customers)',
        },
    ],
    practice: {
        selected_items: [
            { formSection: 'Focused Clients', key: 'practice_focused_clients', label: 'Focused Clients', checked: false },
            {
                formSection: 'Peerview Metrics', key: 'practice_revenue', label: 'Revenue', checked: true,
                children: [
                    { key: 'practice_revenue_bar_chart', label: 'Revenue Chart', checked: true },
                    { key: 'practice_largest_revenue_ranking_table', label: 'Largest Revenue', checked: true },
                ]
            },
            {
                formSection: 'Peerview Metrics', key: 'practice_growth', label: 'Growth', checked: true,
                children: [
                    { key: 'practice_growth_bar_chart', label: 'Growth Chart', checked: true },
                    { key: 'practice_fastest_growing_ranking_table', label: 'Fastest Growing', checked: true },
                ]
            },
            {
                formSection: 'Peerview Metrics', key: 'practice_profitability', label: 'Profitability', checked: true,
                children: [
                    { key: 'practice_profitability_bar_chart', label: 'Profitability Chart', checked: true },
                    { key: 'practice_most_profitable_ranking_table', label: 'Most Profitable', checked: true },
                ]
            },
            {
                formSection: 'Peerview Metrics', key: 'practice_liquidity', label: 'Liquidity', checked: true,
                children: [
                    { key: 'practice_liquidity_bar_chart', label: 'Liquidity Chart', checked: true },
                    { key: 'practice_best_liquidity_ranking_table', label: 'Best Liquidity', checked: true },
                ]
            },
            {
                formSection: 'Peerview Metrics', key: 'practice_leverage', label: 'Leverage', checked: true,
                children: [
                    { key: 'practice_leverage_bar_chart', label: 'Leverage Chart', checked: true },
                    { key: 'practice_best_leverage_ranking_table', label: 'Best Leverage', checked: true },
                ]
            },
            {
                formSection: 'Peerview Metrics', key: 'practice_cash_flow', label: 'Cash Flow', checked: true,
                children: [
                    { key: 'practice_cash_flow_bar_chart', label: 'Cash Flow Chart', checked: true },
                    { key: 'practice_strongest_cash_flow_ranking_table', label: 'Strongest Cash Flow', checked: true },
                ]
            },
            { formSection: 'Peerview Metrics Bubble Chart', key: 'quadrant_growth_profitability', label: 'Growth & Profitability', checked: false },
            { formSection: 'Peerview Metrics Bubble Chart', key: 'quadrant_leverage_cash_flow', label: 'Leverage & Cash Flow', checked: false },
            { formSection: '3D Bubble Chart', key: 'revenue_growth_rate_vs_net_profit3D', label: 'Total Revenue Growth Rate vs Net Profit', checked: false },
            { formSection: 'Scatter Plot', key: 'scatter_total_revenue_vs_growth_rate', label: 'Total Revenue Size vs Growth Rate', checked: false },
            { formSection: 'Map', key: 'map_of_client_number', label: 'Where Your Clients Are Located', checked: false },
        ],
        dashboard_items: [
            {
                chartType: 'practiceText',
                format: '',
                key: 'practice_focused_clients',
                title: 'Focused Clients'
            },
            {
                chartType: 'multiDataBarChart',
                dragTogglable: true,
                format: 'money',
                key: 'practice_revenue_bar_chart',
                metricKey: 'total_revenue',
                title: 'Revenue',
            },
            {
                chartType: 'rankingTable',
                format: 'money',
                key: 'practice_largest_revenue_ranking_table',
                keys: ['company_name', 'naics_string', ['total_revenue', 'money']],
                metricKey: 'total_revenue',
                title: 'Largest Revenue',
                headerCols: ['Client ID', 'NAICS', 'Revenue'],
            },
            {
                chartType: 'multiDataBarChart',
                dragTogglable: true,
                format: 'percent',
                key: 'practice_growth_bar_chart',
                metricKey: 'total_revenue_growth_rate',
                title: 'Growth',
            },
            {
                chartType: 'rankingTable',
                format: 'percent',
                key: 'practice_fastest_growing_ranking_table',
                keys: ['company_name', 'naics_string', ['total_revenue_growth_rate', 'percent'], ['growth', 'wholeNumber']],
                metricKey: 'total_revenue_growth_rate',
                title: 'Fastest Growing',
                headerCols: ['Client ID', 'NAICS', 'Growth %', 'Growth']
            },
            {
                chartType: 'multiDataBarChart',
                dragTogglable: true,
                format: 'percent',
                key: 'practice_profitability_bar_chart',
                metricKey: 'operating_expenses_net_income_common_size_revenue',
                title: 'Profitability',
            },
            {
                chartType: 'rankingTable',
                format: 'percent',
                key: 'practice_most_profitable_ranking_table',
                keys: ['company_name', 'naics_string', ['operating_expenses_net_income_common_size_revenue', 'percent'], ['profitability', 'wholeNumber']],
                metricKey: 'operating_expenses_net_income_common_size_revenue',
                title: 'Most Profitable',
                headerCols: ['Client ID', 'NAICS', 'Net Income', 'Profitability']
            },
            {
                chartType: 'multiDataBarChart',
                dragTogglable: true,
                format: 'wholeNumber',
                key: 'practice_cash_flow_bar_chart',
                metricKey: 'cash_flow',
                title: 'Cash Flow',
            },
            {
                chartType: 'rankingTable',
                format: 'wholeNumber',
                key: 'practice_strongest_cash_flow_ranking_table',
                keys: ['company_name', 'naics_string', ['cash_flow', 'wholeNumber']],
                metricKey: 'cash_flow',
                title: 'Cash Flow',
                headerCols: ['Client ID', 'NAICS', 'Cash Flow']
            },
            {
                chartType: 'multiDataBarChart',
                dragTogglable: true,
                format: 'wholeNumber',
                key: 'practice_leverage_bar_chart',
                metricKey: 'leverage',
                title: 'Leverage',
            },
            {
                chartType: 'rankingTable',
                format: 'wholeNumber',
                key: 'practice_best_leverage_ranking_table',
                keys: ['company_name', 'naics_string', ['leverage', 'wholeNumber']],
                metricKey: 'leverage',
                title: 'Best Leverage',
                headerCols: ['Client ID', 'NAICS', 'Leverage',]
            },
            {
                chartType: 'multiDataBarChart',
                dragTogglable: true,
                format: 'wholeNumber',
                key: 'practice_liquidity_bar_chart',
                metricKey: 'liquidity',
                title: 'Liquidity',
            },
            {
                chartType: 'rankingTable',
                format: 'wholeNumber',
                key: 'practice_best_liquidity_ranking_table',
                keys: ['company_name', 'naics_string', ['liquidity', 'wholeNumber']],
                metricKey: 'liquidity',
                title: 'Best Liquidity',
                headerCols: ['Client ID', 'NAICS', 'Liquidity']
            },
            {
                dragTogglable: true,
                chartType: 'quadrantBubble',
                key: 'quadrant_growth_profitability',
                keys: ['growth', 'profitability'],
                subtitle: '(Peerview Metrics)',
                x: 'Growth',
                y: 'Profitability',
            },
            {
                dragTogglable: true,
                chartType: 'quadrantBubble',
                key: 'quadrant_leverage_cash_flow',
                keys: ['leverage', 'cash_flow'],
                subtitle: '(Peerview Metrics)',
                x: 'Leverage',
                y: 'Cash Flow',
            },
            {
                dragTogglable: true,
                chartType: '3DBubble',
                key: 'revenue_growth_rate_vs_net_profit3D',
                format: 'percent',
                keys: ['total_revenue_growth_rate', 'operating_expenses_net_income_common_size_revenue'],
                x: 'Total Revenue Growth Rate',
                y: 'Net Profit',
            },
            {
                dragTogglable: true,
                chartType: 'scatterPlot',
                key: 'scatter_total_revenue_vs_growth_rate',
                keys: ['total_revenue', 'total_revenue_growth_rate'],
                x: 'Total Revenue',
                y: 'Total Revenue Growth Rate',
            }
        ],
        nested_dashboard_layout: [
            { i: 'practice_focused_clients', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 4, moved: false, resizeHandles: ['se'], static: false },
            {
                i: 'practice_revenue', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'practice_revenue_bar_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'practice_largest_revenue_ranking_table', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'practice_growth', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 2, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'practice_growth_bar_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'practice_fastest_growing_ranking_table', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ],
            },
            {
                i: 'practice_profitability', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 4, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'practice_profitability_bar_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'practice_most_profitable_ranking_table', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'practice_liquidity', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 6, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'practice_liquidity_bar_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'practice_best_liquidity_ranking_table', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'practice_leverage', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'practice_leverage_bar_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'practice_best_leverage_ranking_table', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'practice_cash_flow', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 10, w: 6, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'practice_cash_flow_bar_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'practice_strongest_cash_flow_ranking_table', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            { i: 'quadrant_growth_profitability', isBounded: false, isDraggable: false, isResizable: true, x: 0, y: 8, w: 6, h: 3, minW: 3, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
            { i: 'quadrant_leverage_cash_flow', isBounded: false, isDraggable: false, isResizable: true, x: 0, y: 11, w: 6, h: 3, minW: 3, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
            { i: 'revenue_growth_rate_vs_net_profit3D', isBounded: false, isDraggable: false, isResizable: true, x: 0, y: 14, w: 6, h: 3, minW: 3, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
            { i: 'scatter_total_revenue_vs_growth_rate', isBounded: false, isDraggable: false, isResizable: true, x: 0, y: 17, w: 6, h: 3, minW: 3, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false },
            { i: 'map_of_client_number', isBounded: false, isDraggable: false, isResizable: true, x: 0, y: 20, w: 6, h: 3, minW: 1, minH: 1, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false }
        ]
    },
    money: [
        {
            dragTogglable: true,
            chartType: 'quadrantBubble',
            key: 'quadrant_growth_profitability',
            keys: ['growth', 'profitability'],
            subtitle: '(Peerview Metrics)',
            x: 'Growth',
            y: 'Profitability',
        },
        {
            dragTogglable: true,
            chartType: 'quadrantBubble',
            key: 'quadrant_leverage_cash_flow',
            keys: ['leverage', 'cash_flow'],
            subtitle: '(Peerview Metrics)',
            x: 'Leverage',
            y: 'Cash Flow',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'total_revenue_growth_rate',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'total_costs_common_size_revenue',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'total_costs',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'gross_margin',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'total_costs',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'total_operating_expenses_common_size_revenue',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'total_operating_expenses',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'profit_margin',
            subtitle: '(By % of Total Revenue)',
            percentOfRevenueKey: 'operating_expenses_net_income',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'roa',
            subtitle: '(Return on Assets)',
            trailing12Key: 'Return on Assets (ROA)',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'roe',
            subtitle: '(Return on Equity)',
            trailing12Key: 'Return on Equity (ROE)',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'roce',
            subtitle: '(Return on Capital Employed)',
            trailing12Key: 'Return on Capital Employed (ROCE)',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'mkt_roi',
            subtitle: '(Return on Investment)',
            trailing12Key: 'ROI',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            key: 'asset_turnover_ratio',
            trailing12Key: 'Asset Turnover Ratio',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'cash',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            key: 'net_working_capital_ratio',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'net_working_capital',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            key: 'current_ratio',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            key: 'debt_to_equity',
            trailing12Key: 'Debt / Equity',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            key: 'senior_debt_to_ebitda',
            trailing12Key: 'Senior Debt / EBITDA',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'ratio',
            key: 'ebitda_to_annual_interest',
            trailing12Key: 'EBITDA / Annual Interest',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'accounts_receivable',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            key: 'ar_days',
            trailing12Key: 'Accounts Receivable Days',
        },
        {
            displayAll: true,
            chartType: 'stackedBars',
            format: 'money',
            key: 'ar_aging_summary',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'inventory',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            key: 'inventory_turnover_ratio',
            trailing12Key: 'Inventory Turnover Ratio',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'accounts_payable',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'decimal',
            key: 'ap_days',
            trailing12Key: 'Accounts Payable Days',
        },
        {
            displayAll: true,
            chartType: 'stackedBars',
            format: 'money',
            key: 'ap_aging_summary',
        },
    ],
    people: [
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'total_revenue_per_employee',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'operating_expenses_net_income_per_employee',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'sum_compensation_growth_rate',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'sum_taxes_and_benefits_growth_rate',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'percent',
            key: 'sum_subcontractors_growth_rate',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'average_wages_and_salaries',
        },
        {
            displayAll: true,
            chartType: 'editableChart',
            styles: editableChartStyleDefault,
            format: 'money',
            key: 'average_benefits',
        },
        {
            displayAll: true,
            chartType: 'verticalBars',
            format: 'percent',
            key: 'turnover_rate',
        },
        {
            // only years
            displayAll: false,
            chartType: 'noChart',
            format: 'money',
            key: 'cost_of_turnover',
        }
    ],
    customers: {
        selected_items: [
            {
                formSection: 'Peerview Metrics',
                chartType: 'gauges',
                copy: 'Peerview Metric Gauges',
                key: 'peerview_gauges',
                relatedCheckedKey: 'peerview_gauges',
                checked: true,
                displayChart: true,
                sheet: 'peerview_charts',
                label: 'Peerview Metrics',
                checked: true
            },
            {
                formSection: 'Spend', key: 'total_revenue', relatedCheckedKey: 'total_revenue', label: 'Revenue', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'total_revenue_growth_rate', relatedCheckedKey: 'total_revenue', label: 'Total Revenue Growth Rate Chart', chartType: 'editableChart', checked: true },
                    { key: 'total_revenue_impact_box', relatedCheckedKey: 'total_revenue', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Spend', key: 'gross_profit', relatedCheckedKey: 'gross_profit', label: 'Gross Profit', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'gross_margin', relatedCheckedKey: 'gross_profit', label: 'Gross Profit Margin Chart', chartType: 'editableChart', checked: true },
                    { key: 'gross_profit_impact_box', relatedCheckedKey: 'gross_profit', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Spend', key: 'operating_profit', relatedCheckedKey: 'operating_profit', label: 'Operating Profit', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'operating_profit_chart', relatedCheckedKey: 'operating_profit', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'operating_profit_impact_box', relatedCheckedKey: 'operating_profit', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Spend', key: 'operating_expenses_net_income', relatedCheckedKey: 'operating_expenses_net_income', label: 'Net Income', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'profit_margin', relatedCheckedKey: 'operating_expenses_net_income', label: 'Net Profit Margin Chart', chartType: 'editableChart', checked: true },
                    { key: 'operating_expenses_net_income_impact_box', relatedCheckedKey: 'operating_expenses_net_income', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Spend', key: 'total_customer_costs_sales_& marketing', relatedCheckedKey: 'total_customer_costs_sales_& marketing', label: 'Sales & Marketing', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'total_customer_costs_sales_& marketing_chart', relatedCheckedKey: 'total_customer_costs_sales_& marketing', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'total_customer_costs_sales_& marketing_impact_box', relatedCheckedKey: 'total_customer_costs_sales_& marketing', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Spend', key: 'travel_entertainment_and_meals', relatedCheckedKey: 'travel_entertainment_and_meals', label: 'Travel, Entertainment & Meals', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'travel_entertainment_and_meals_chart', relatedCheckedKey: 'travel_entertainment_and_meals', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'travel_entertainment_and_meals_impact_box', relatedCheckedKey: 'travel_entertainment_and_meals', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Spend', key: 'customers_misc_professional_fees', relatedCheckedKey: 'customers_misc_professional_fees', label: 'Professional Fees - Customers', checked: true, sheet: 'financial_detail',
                children: [
                    { key: 'customers_misc_professional_fees_chart', relatedCheckedKey: 'customers_misc_professional_fees', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'customers_misc_professional_fees_impact_box', relatedCheckedKey: 'customers_misc_professional_fees', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Volatility', key: 'revenue_per_customer', relatedCheckedKey: 'revenue_per_customer', label: 'Revenue Per Client', checked: true, sheet: 'kpi',
                children: [
                    { key: 'revenue_per_customer_chart', relatedCheckedKey: 'revenue_per_customer', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'revenue_per_customer_impact_box', relatedCheckedKey: 'revenue_per_customer', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Volatility', key: 'gross_profit_per_customer', relatedCheckedKey: 'gross_profit_per_customer', label: 'Gross Profit Per Client', checked: true, sheet: 'kpi',
                children: [
                    { key: 'gross_profit_per_customer_chart', relatedCheckedKey: 'gross_profit_per_customer', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'gross_profit_per_customer_impact_box', relatedCheckedKey: 'gross_profit_per_customer', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Volatility', key: 'operating_expenses_net_income_per_customer', relatedCheckedKey: 'operating_expenses_net_income_per_customer', label: 'Net Profit Per Client', checked: true, sheet: 'kpi',
                children: [
                    { key: 'operating_expenses_net_income_per_customer_chart', relatedCheckedKey: 'operating_expenses_net_income_per_customer', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'operating_expenses_net_income_per_customer_impact_box', relatedCheckedKey: 'operating_expenses_net_income_per_customer', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'ROI', key: 'customer_roi', relatedCheckedKey: 'customer_roi', label: 'Marketing ROI', checked: true, sheet: 'kpi',
                children: [
                    { key: 'customer_roi_chart', relatedCheckedKey: 'customer_roi', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'customer_roi_impact_box', relatedCheckedKey: 'customer_roi', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'ROI', key: 'number_of_new_customers_needed_to_maintain_growth', relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth', label: 'Growth Requirements', checked: true, sheet: 'kpi',
                children: [
                    { key: 'number_of_new_customers_needed_to_maintain_growth_chart', relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth', label: 'Chart', chartType: 'columns', checked: true },
                    { key: 'number_of_new_customers_needed_to_maintain_growth_impact_box', relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Concentration', key: 'percent_of_sales_from_the_top_10_percent', relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent', label: 'Customer Concentration - 10%', chartType: 'editableChart', checked: true, sheet: 'kpi',
                children: [
                    { key: 'percent_of_sales_from_the_top_10_percent_chart', relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'percent_of_sales_from_the_top_10_percent_impact_box', relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Concentration', key: 'percent_of_sales_from_the_top_25_percent', relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent', label: 'Customer Concentration - 25%', checked: true, sheet: 'kpi',
                children: [
                    { key: 'percent_of_sales_from_the_top_25_percent_chart', relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'percent_of_sales_from_the_top_25_percent_impact_box', relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent', label: 'Impact Box', checked: true },
                ]
            },
            {
                formSection: 'Concentration', key: 'percent_of_sales_from_the_top_50_percent', relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent', label: 'Customer Concentration - 50%', checked: true, sheet: 'kpi',
                children: [
                    { key: 'percent_of_sales_from_the_top_50_percent_chart', relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent', label: 'Chart', chartType: 'editableChart', checked: true },
                    { key: 'percent_of_sales_from_the_top_50_percent_impact_box', relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent', label: 'Impact Box', checked: true },
                ]
            },
        ],
        dashboard_items: [
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'total_revenue_growth_rate'
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'gross_margin'
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'profit_margin'
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'revenue_per_customer',
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'gross_profit_per_customer',
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'operating_expenses_net_income_per_customer',
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'total_customer_costs_common_size_revenue'
            },
            {
                displayAll: true,
                chartType: 'editableChart',
                styles: editableChartStyleDefault,
                format: 'percent',
                key: 'customer_roi'
            },
            {
                // only years
                displayAll: false,
                chartType: 'horizontalBars',
                format: 'percent',
                key: 'customer_concentration',
                keys: ['percent_of_sales_from_the_top_10_percent', 'percent_of_sales_from_the_top_25_percent', 'percent_of_sales_from_the_top_50_percent'],
                title: 'Customer Concentration',
                subtitle: '(Percent of Sales from Percent of Customers)',
                yearOnly: true
            },
            {
                displayAll: true,
                chartType: 'verticalBars',
                format: 'decimal',
                key: 'number_of_new_customers_needed_to_maintain_growth',
                title: 'Growth Requirements',
                subtitle: '(# of Additional Customers Needed to Maintain Growth)'
            },
            // {
            //     // only years
            //     displayAll: false,
            //     chartType: 'lines',
            //     format: 'money',
            //     key: 'customer_retention',
            //     keys: ['cltv_70', 'cltv_80', 'cltv_90'],
            //     title: 'Customer Retention Value',
            //     subtitle: '(Based on Annual Retention Rate over 7-Yr Period)',
            //     yearOnly: true
            // }
        ],
        nested_dashboard_layout: [
            { i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 4, moved: false, resizeHandles: ['se'], static: false },
            {
                i: 'total_revenue', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'total_revenue_growth_rate', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'total_revenue_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'gross_profit', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 2, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'gross_margin', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'gross_profit_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ],
            },
            {
                i: 'operating_profit', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 2, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'operating_profit_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 4, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'operating_profit_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ],
            },
            {
                i: 'operating_expenses_net_income', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 4, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'profit_margin', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'operating_expenses_net_income_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'total_customer_costs_sales_& marketing', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 6, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'total_customer_costs_sales_& marketing_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'total_customer_costs_sales_& marketing_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'travel_entertainment_and_meals', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'travel_entertainment_and_meals_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'travel_entertainment_and_meals_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'customers_misc_professional_fees', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'customers_misc_professional_fees_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'customers_misc_professional_fees_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'revenue_per_customer', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'revenue_per_customer_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'revenue_per_customer_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'gross_profit_per_customer', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'gross_profit_per_customer_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'gross_profit_per_customer_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'operating_expenses_net_income_per_customer', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'operating_expenses_net_income_per_customer_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'operating_expenses_net_income_per_customer_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'customer_roi', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'customer_roi_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'customer_roi_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'number_of_new_customers_needed_to_maintain_growth', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'number_of_new_customers_needed_to_maintain_growth_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'number_of_new_customers_needed_to_maintain_growth_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'percent_of_sales_from_the_top_10_percent', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'percent_of_sales_from_the_top_10_percent_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'percent_of_sales_from_the_top_10_percent_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'percent_of_sales_from_the_top_25_percent', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'percent_of_sales_from_the_top_25_percent_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'percent_of_sales_from_the_top_25_percent_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
            {
                i: 'percent_of_sales_from_the_top_50_percent', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 8, w: 3, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 12, moved: false, resizeHandles: ['se'], static: false,
                layout: [
                    { i: 'percent_of_sales_from_the_top_50_percent_chart', isBounded: false, isDraggable: true, isResizable: true, x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false },
                    { i: 'percent_of_sales_from_the_top_50_percent_impact_box', isBounded: false, isDraggable: true, isResizable: true, x: 4, y: 0, w: 1, h: 2, minW: 2, minH: 2, maxW: 6, maxH: 6, moved: false, resizeHandles: ['se'], static: false }
                ]
            },
        ]
    }
};

const mergeItems = (report, savedSelectedItemsArr, savedLayoutArr) => {
    let setSelectedItems, setLayout;
    let newSelectedItems = [];
    let newLayout = [];
    let testLayout = [];

    if (report === 'money') {
        setSelectedItems = moneyCharts['peerview_charts']
        setLayout = defaultLayouts['money_peerview_charts']
    } else if (report === 'people') {
        setSelectedItems = peopleCharts['peerview_charts']
        setLayout = defaultLayouts['people_peerview_charts']
    } else if (report === 'customers') {
        setSelectedItems = customerCharts['peerview_charts']
        setLayout = defaultLayouts['customers_peerview_charts']
    } else {
        setSelectedItems = pageCharts[report]['selected_items']
        setLayout = pageCharts[report]['nested_dashboard_layout']
    }

    if (savedSelectedItemsArr) {
        setSelectedItems.forEach((setItem) => {
            let savedSelection = savedSelectedItemsArr.find(saved => saved.key === setItem.key)
            if (savedSelection) {
                if (setItem.children && savedSelection.children) {
                    let selectionChildren = []
                    setItem.children.forEach((setChild) => {
                        let savedChild = savedSelection.children.find(savedChild => savedChild.key === setChild.key)
                        savedChild ? selectionChildren.push(savedChild) : selectionChildren.push(setChild)
                    })

                    savedSelection.children = selectionChildren;
                    newSelectedItems.push(savedSelection)
                } else if (setItem.children && !savedSelection.children) {
                    savedSelection.children = setItem.children
                    newSelectedItems.push(savedSelection)
                } else {
                    newSelectedItems.push(savedSelection)
                }
            } else {
                newSelectedItems.push(setItem)
            }
        })
    } else {
        newSelectedItems = setSelectedItems;
    }

    newSelectedItems.forEach((s) => {
        let childLayout = []
        if (s.checked) {
            // find saved layout obj
            let savedLayoutObj = savedLayoutArr ? savedLayoutArr.find(savedLay => savedLay.i === s.key) : null;

            if (s.children) {
                // if saved item was a checked parent, then going to have more than 1 child
                if (savedLayoutObj) {
                    let trueChildren = s.children.filter(child => child.checked)
                    if (trueChildren.length > 1) {
                        trueChildren.forEach(child => {
                            let savedChildObj = savedLayoutObj.layout ? savedLayoutObj.layout.find(l => l.i === child.key) : null;

                            if (savedChildObj) {
                                childLayout.push(savedChildObj)
                            } else {
                                let setLayoutObj = setLayout.find(setLay => setLay.i === s.key)
                                let setChildObj = setLayoutObj.layout.find(ll => ll.i === child.key)
                                childLayout.push(setChildObj)
                            }
                        })
                        savedLayoutObj.layout = childLayout;
                        newLayout.push(savedLayoutObj)
                    }
                } else {
                    let setLayoutObj = setLayout.find(setLay => setLay.i === s.key)
                    let trueChilds = s.children.filter(child => child.checked)
                    // only one child was checked
                    if (trueChilds.length === 1) {
                        let setChildObj = setLayoutObj.layout.find(childLay => childLay.i === trueChilds[0].key)
                        let savedChildObj = savedLayoutArr ? savedLayoutArr.find(savedChildLay => savedChildLay.i === trueChilds[0].key) : null;
                        savedChildObj ? newLayout.push(savedChildObj) : newLayout.push(setChildObj)
                    }

                    if (trueChilds.length > 1) {
                        trueChilds.forEach(c => {
                            let setChildObj = setLayoutObj.layout.find(childLay => childLay.i === c.key)
                            let savedChildObj = savedLayoutArr ? savedLayoutArr.find(savedChildLay => savedChildLay.i === c.key) : null;
                            savedChildObj ? childLayout.push(savedChildObj) : childLayout.push(setChildObj)
                        })
                        setLayoutObj.layout = childLayout;
                        newLayout.push(setLayoutObj)
                    }
                }
            } else {
                if (savedLayoutObj) {
                    newLayout.push(savedLayoutObj)
                } else {
                    let setLayoutObj = setLayout.find(setLay => setLay.i === s.key)
                    newLayout.push(setLayoutObj)
                }
            }
        }
    })

    return { layout: newLayout, selectedItems: newSelectedItems };
}

const layoutMaker = (item, num = 0, parentHeight = 0, parentWidth = 0) => {
    let xPos = 0
    let yPos = 0
    let width = 2
    let height = 2
    let draggable = true
    let resizable = true

    if (item.key === 'peerview_gauges') {
        xPos = 0
        yPos = 0
        width = 6
        height = 1
        resizable = false
    } else if (item.key === 'my_notes' || item.key === 'money_report_notes') {
        xPos = 0
        yPos = Infinity
        width = 3
        height = 1
        draggable = false
    } else if (item.key === 'quadrant_growth_profitability' || item.key === 'quadrant_leverage_cash_flow') {
        xPos = 0
        yPos = Infinity
        width = 3
        height = 2
    } else if (item.key.includes('impact')) {
        xPos = 2
        yPos = 0
        width = 1
        height = 2
    } else if (item.key.includes('_bar_chart') || item.key.includes('_chart')) {
        xPos = 0
        yPos = Infinity
        width = 2
        height = 2
    } else if (item.key.includes('_notes') && item.key !== 'my_notes') {
        xPos = 0
        yPos = Infinity
        width = 3
        height = 1
    } else {
        xPos = 0
        yPos = 0
        width = 2
        height = 2
    }

    return {
        i: item.key,
        isBounded: false,
        isDraggable: draggable,
        isResizable: resizable,
        x: xPos,
        y: yPos,
        w: width,
        h: height,
        minW: 1,
        minH: 1,
        maxW: 6,
        maxH: 12,
        resizeHandles: ['se'],
        metricRow: item['metricRow'] ? item['metricRow'] : item
    };
}

export { defaultLayouts, moneyCharts, peopleCharts, customerCharts, pageCharts, mergeItems, layoutMaker }