import React, { useState } from 'react';
import moment from 'moment';

const AuditScenarios = (props) => {
    const [newAuditName, setNewAuditName] = useState('');

    const createNewScenarioForm = () => {
        return (
            <form className='audit-scenario-form' onSubmit={(e) => props.handleNewScenario(e, props.reportType, newAuditName, props.auditScenario)}>
                <input type='text' placeholder='New Scenario Name' className='scenario-name-input' onChange={(e) => setNewAuditName(e.target.value)} required />
                <button className='btn btn-primary audit-btn'>Save New Scenario</button>
            </form>
        )
    }

    return (
        <div className='audit-scenarios-container'>
        {createNewScenarioForm()}
        <div className='audit-scenarios-selection'>
            <label htmlFor='audit-scenario-select'>Current Scenario:</label>
            <select
                id='audit-scenario-select'
                value={props.auditScenario['id']}
                onChange={(e) => props.handleAuditScenarioSelect(e, props.reportType)}
            >

                {props.allAuditScenarios.map((option) => (
                    <option key={`${option[0]}-${option[1]}`} value={option[0]}>
                        {option[1]}
                    </option>
                ))}
            </select>
            <span>{`Version ${props.auditScenario['version']}`}</span>
            <button className='btn btn-primary audit-btn' onClick={() => props.handleUpdateScenario(props.reportType, props.auditScenario)}>Save Changes</button>
        </div>
        <div className='audit-scenario-updates'>
            <span className='current-view-updated-time'>{props.auditScenario.updated_at ? `Last updated: ${moment(props.auditScenario.updated_at).calendar()}` : null}</span>
            <span className='current-view-updated-time'>{props.auditScenario.updated_by ? `Last saved by: ${props.auditScenario.updated_by}` : null}</span>
        </div>
    </div>
    )
}

export default AuditScenarios;