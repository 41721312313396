import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';

const MoneyAnnualTable = (props) => {
  let compareWith, finalCompareTitle = ''

  if (props.page !== 'practice') {
    let allFilters = useContext(Filters);
    compareWith = allFilters.currentNewFilters.compare_with;
    finalCompareTitle = props.page === 'naics_focused' && compareWith !== 'NSCHBC' ? 'Peers' : compareWith
  }

  const buildRows = () => {
    return props.rows.map((row, i) => {
      let underlineFormat, toplineFormat;
      if (row.forecast) {
        if (row.forecast.total) {
          if (row.forecast.total.boldedUnderline) {
            underlineFormat = 'solid 1px';
          } else if (row.forecast.total.doubleUnderline) {
            underlineFormat = 'double 1px';
          } else if (row.forecast.total.underline) {
            underlineFormat = 'solid 1px';
          }

          if (row.forecast.total.topline) {
            toplineFormat = 'solid 1px'
          } else {
            toplineFormat = ''
          }

        } else if (row.forecast) {
          if (row.forecast.boldedUnderline) {
            underlineFormat = 'solid 1px';
          } else if (row.forecast.doubleUnderline) {
            underlineFormat = 'double 1px';
          } else if (row.forecast.underline) {
            underlineFormat = 'solid 1px';
          }

          if (row.forecast.topline) {
            toplineFormat = 'solid 1px'
          } else {
            toplineFormat = ''
          }
        }
      }

      if (row.topline) {
        toplineFormat = 'solid 1px'
      }

      const copyRowStyle = {
        borderBottom: underlineFormat,
        borderTop: toplineFormat
      }

      const copyCellStyle = row.whiteSpace ? 'table-copy wrap-copy-cell' : 'table-copy';

      let hideRow = '';
      let zeroYears = [];
      props.yearRange.forEach(y => { row[y] === 0 && Math.abs(row['you' + y]).toFixed(1) === '0.0' && Math.abs(row['avg' + y]).toFixed(1) === '0.0' ? zeroYears.push(y) : null })
      zeroYears.length === props.yearRange.length ? hideRow = row.copy : null

      let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

      if (row.emptyRow) {
        return (
          <tr key={Math.random(i)} id={`${props.chart}-report-row-${i}`}>
            {props.page === 'money' ? <td className='row-check'></td> : null}
            <td className='table-copy'></td>
            {props.yearRange.map((year, ii) => {
              let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
              let backgroundStyle = { height: '20px' }
              let commonSizeValCellClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';
              let valueCellClass = '';
              let noEndSpaceCell = props.yearRange.length - 1 == ii;

              if (ii % 2 === 0) {
                arrowCellClass += ' money-table-cell';
                backgroundStyle['backgroundColor'] = '#BFE0AE';
                commonSizeValCellClass += ' money-table-cell';
                valueCellClass = 'money-table-cell';
              }

              return (
                <React.Fragment key={Math.random(ii)}>
                  <td key={ii} className={valueCellClass} style={backgroundStyle}></td>
                  <td className={commonSizeValCellClass} style={backgroundStyle}></td>
                  {props.page !== 'practice' && compareWith === 'None' ? null : <td className={arrowCellClass} style={backgroundStyle}></td>}
                  {props.page !== 'practice' && compareWith === 'None' ? null : <td className={commonSizeValCellClass} style={backgroundStyle}></td>}
                  {noEndSpaceCell ? null : <td className='space-cell'></td>}
                </React.Fragment>
              )
            })}
            {props.yearRange.length === 2 ? addDeltaColumn(row) : null}
          </tr>
        )
      } else {
        return (
          <tr style={hideRow === row.copy && !row.alwaysDisplayRow ? { display: 'none' } : copyRowStyle} key={Math.random(i)} id={`${props.chart}-report-row-${i}`} onClick={() => row.displayChart && props.editMetrics ? props.handleSelectAndLayout('money', props.sheet, row.relatedCheckedKey) : null}>
            {props.page === 'practice' || props.page === 'naics_focused' || props.pdf ? null : <td>{rowChecked}</td>}
            {row.copy === 'Current Liabilities'
              ? <td className={copyCellStyle} style={{ paddingTop: '20px' }}>{row.copyWithPadding}</td>
              : <td className={copyCellStyle}>{row.copyWithPadding}</td>}
            {props.yearRange.map((year, ii) => {
              let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
              let backgroundStyle = {}
              let commonSizeValCellClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';
              let valueCellClass = '';
              let noEndSpaceCell = props.yearRange.length - 1 == ii

              if (ii % 2 === 0) {
                arrowCellClass += ' money-table-cell';
                backgroundStyle['backgroundColor'] = '#BFE0AE';
                commonSizeValCellClass += ' money-table-cell';
                valueCellClass = 'money-table-cell';
              }
              try {
                if (props.commonSizeTable) {
                  return (
                    <td key={Math.random(ii)} style={{ textAlign: 'center' }} >
                      {row[year].toFixed(2)}%
                    </td>
                  )
                } else {
                  // return blank td's if on a header
                  if (Object.keys(row).length > 2) {
                    let arrowColumn = '';
                    let yearValue = parseMoney(row[year]);
                    let youPercentage = _.isNumber(row['you' + year]) ? row['you' + year].toFixed(1) + '%' : '-';
                    let avgPercentage = _.isNumber(row['avg' + year]) ? row['avg' + year].toFixed(1) + '%' : '-';
                    if (row['status' + year] === 'worse' || row['status' + year] === 'worst') {
                      arrowColumn = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
                    } else if (row['status' + year] === 'better' || row['status' + year] === 'best') {
                      arrowColumn = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
                    }

                    if (youPercentage === '-' && avgPercentage === '-') {
                      youPercentage = ''
                      avgPercentage = ''
                    }

                    return (
                      <React.Fragment key={Math.random(ii)}>
                        <td key={ii} className={valueCellClass} style={backgroundStyle}>
                          {yearValue}
                        </td>
                        <td className={commonSizeValCellClass} style={backgroundStyle}>
                          {youPercentage}
                        </td>
                        {props.page !== 'practice' && compareWith === 'None'
                          ? null
                          : <td className={arrowCellClass} style={backgroundStyle}>
                            {arrowColumn}
                          </td>}
                        {props.page !== 'practice' && compareWith === 'None'
                          ? null
                          : <td className={commonSizeValCellClass} style={backgroundStyle}>
                            {avgPercentage}
                          </td>}
                        {noEndSpaceCell ? null : <td className='space-cell'></td>}
                      </React.Fragment>
                    )
                  } else {
                    return (
                      <React.Fragment key={Math.random(ii)}>
                        <td className={valueCellClass} style={backgroundStyle}></td>
                        <td className={commonSizeValCellClass} style={backgroundStyle}></td>
                        {props.page !== 'practice' && compareWith === 'None' ? null : <td className={arrowCellClass} style={backgroundStyle}></td>}
                        {props.page !== 'practice' && compareWith === 'None' ? null : <td className={commonSizeValCellClass} style={backgroundStyle}></td>}
                        {noEndSpaceCell ? null : <td className='space-cell'></td>}
                      </React.Fragment>
                    )
                  }
                }
              } catch (error) {
                console.log(error, 'err')
                return (<td key={Math.random(ii)}></td>);
              }
            })}
            {props.yearRange.length === 2 ? addDeltaColumn(row) : null}
          </tr>
        )
      }
    })
  }

  const renderYearHeaderColumns = (years) => {
    const currentYear = new Date().getFullYear();
    let youPeersRow = [];
    let formatedYearsRow = [];
    let twoClasses = props.pdf ? 'common-size-arrow-pdf money-table-cell' : 'common-size-arrow money-table-cell'

    years.forEach((y, i) => {
      let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
      let backgroundStyle = {}
      let commonSizeValCellClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';
      let valueCellClass = '';
      let youDate, compareDate = '';
      let noEndSpaceCell = years.length - 1 == i

      if (y === currentYear) {
        youDate = props.companyYTDDate;
        compareDate = props.aggregateYTDDate;
      } else {
        youDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${y}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${y + 1}` : `12/31/${y}`;
        compareDate = `12/31/${y}`;
      }

      if (i % 2 === 0) {
        let tableCellClass = props.pdf ? 'money-table-cell' : 'money-table-cell'
        arrowCellClass += ' ' + tableCellClass;
        backgroundStyle['backgroundColor'] = '#BFE0AE';
        commonSizeValCellClass += ' ' + tableCellClass;
        valueCellClass = tableCellClass
      }

      if (props.page !== 'practice' && compareWith === 'None') {
        youPeersRow.push(
          <React.Fragment key={Math.random(i)}>
            <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>You</td>
            {noEndSpaceCell ? null : <td className='space-cell'></td>}
          </React.Fragment>
        )

        formatedYearsRow.push(
          <React.Fragment key={Math.random(i)}>
            <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
            {noEndSpaceCell ? null : <td className='space-cell'></td>}
          </React.Fragment>
        )
      } else {
        youPeersRow.push(
          <React.Fragment key={Math.random(i)}>
            <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>{props.page === 'practice' ? 'Your Avg Client' : 'You'}</td>
            <td className={arrowCellClass} style={backgroundStyle}></td>
            <td className={commonSizeValCellClass} style={backgroundStyle}>{props.page === 'practice' ? 'Peerview Avg Client' : finalCompareTitle}</td>
            {noEndSpaceCell ? null : <td className='space-cell'></td>}
          </React.Fragment>
        )

        formatedYearsRow.push(
          <React.Fragment key={Math.random(i)}>
            <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
            <td className={arrowCellClass} style={backgroundStyle}></td>
            <td className={commonSizeValCellClass} style={backgroundStyle}>{compareDate}</td>
            {noEndSpaceCell ? null : <td className='space-cell'></td>}
          </React.Fragment>
        )
      }

    })

    return (
      <thead>
        <tr key={Math.random(20)}>
          {props.page === 'practice' || props.page === 'naics_focused' || props.pdf
            ? <td></td>
            : (<td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>)}
          {props.page === 'practice' || props.page === 'naics_focused' || props.pdf ? null : <td></td>}
          {youPeersRow}
          {props.yearRange.length === 2 ?
            <td className={twoClasses} style={{ 'backgroundColor': '#BFE0AE' }}>Δ Delta</td> : null}
        </tr>
        <tr key={Math.random(20)}>
          <td></td>
          {props.page === 'practice' || props.page === 'naics_focused' || props.pdf ? null : <td></td>}
          {formatedYearsRow}
          {props.yearRange.length === 2 ?
            <td className={twoClasses} style={{ 'backgroundColor': '#BFE0AE' }}></td> : null}
        </tr>
      </thead>
    )
  }

  const addDeltaColumn = (row) => {
    let twoClasses = props.pdf ? 'common-size-cell-pdf money-table-cell' : 'common-size-cell money-table-cell';
    if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(row[props.yearRange[1]])) {
      return <td className={twoClasses} style={{ 'backgroundColor': '#BFE0AE' }}>{parseMoney(row[props.yearRange[1]] - row[props.yearRange[0]])}</td>
    } else {
      return <td className={twoClasses} style={{ 'backgroundColor': '#BFE0AE' }}></td>
    }
  }

  const buildTable = () => {
    if (props.pdf) {
      let sheetTitle = props.continued ? `${props.title} - ${props.clientName} (Continued)` : `${props.title} - ${props.clientName}`
      return (
        <div className='report-pdf-landscape'>
          <div>
            <h1 className='report-table-title'>{sheetTitle}</h1>
            <div className='money-pdf-table-div'>
              <table style={{ whiteSpace: 'pre' }} className='money-pdf-table' >
                {renderYearHeaderColumns(props.yearRange)}
                <tbody>
                  {buildRows()}
                  <tr><td className='footer-cell'><i>n = {props.n ? props.n : 0}</i></td></tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
        </div>
      )
    } else {
      let tableID = props.page !== 'naics_focused' ? `new-annual-table-${props.sheet}` : `new-annual-table-naics_focused`
      return (
        <div className='money-report-card'>
          <div className='money-report-pdf'>
            <div className='money-report-container'>
              <table id={tableID} style={{ whiteSpace: 'pre' }} className='report-table' >
                {renderYearHeaderColumns(props.yearRange)}
                <tbody>
                  {buildRows()}
                </tbody>
                <tfoot>
                  {props.page === 'practice'
                    ? <tr><td><i>Displaying {props.n ? props.n : 0} of your clients</i></td></tr>
                    : <tr><td><i>n = {props.n ? props.n : 0}</i></td></tr>}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }

  return (buildTable())
}

export default MoneyAnnualTable;
