import React from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { ToastContainer, toast } from 'react-toastify';
import ApplyChangesToast from './ApplyChangesToast';

const MonthlyForecastLineChart = ({ actions, setApplyActions, calendarYearEnd, clientName, displayPDF, firmName, hideChart, months, updateScenario, youDataForChart, peerDataForChart, handleChartChange, scenarios, year }) => {

  const handleScenario = (name) => {
    if (actions.length > 0) {
      toast(<ApplyChangesToast handleScenarioChange={handleChartChange} liveData={false} setApplyActions={setApplyActions} value={name} />)
    } else {
      handleChartChange(name)
    }
  }

  const buildLineSeries = () => {
    const dataArray = ['Straightline']
    let youScenario = calendarYearEnd ? `You ${year}` : `You FY${year + 1}`
    !_.isEmpty(youDataForChart) ? dataArray.push(youScenario) : null
    !_.isEmpty(peerDataForChart) ? dataArray.push(`Peers ${year}`) : null

    return dataArray.map(g => {
      let dataSet = []
      switch (g) {
        case 'Straightline':
          dataSet = months.map(v => v = 8.33)
          break;
        case youScenario:
          dataSet = youDataForChart.map(v => Number((v * 100).toFixed(2)))
          break;
        case `Peers ${year}`:
          dataSet = peerDataForChart.map(v => Number((v * 100).toFixed(2)))
          break;
      }

      return {
        name: g,
        data: dataSet,
        events: {
          legendItemClick: function (e) {
            e.preventDefault()
            handleScenario(g)
          }
        }
      }
    })
  }

  let chartOptions = {
    chart: {
      type: 'areaspline',
      reflow: true,
      events: {
        // For "Print Chart" only and not with whole page
        beforePrint: function () {
          this.oldhasUserSize = this.hasUserSize;
          this.resetParams = [this.chartWidth, this.chartHeight, false];
          this.setSize(822, 250, false);
        },
        afterPrint: function () {
          this.setSize.apply(this, this.resetParams);
          this.hasUserSize = this.oldhasUserSize;
        }
      },
      style: {
        fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
      }
    },
    colors: [
      '#F17B5B', '#FFCCB2', '#3C3C3C'
    ],
    title: {
      text: 'Revenue Models',
      style: {
        fontWeight: 'normal',
        fontSize: '18px'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      x: 150,
      y: 0,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '12px',
      }
    },
    xAxis: {
      categories: months,
      labels: {
        style: {
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Monthly Revenue % of Annual Revenue',
        style: {
          fontSize: '12px'
        }
      },
      labels: {
        style: {
          fontSize: '11px'
        }
      }
    },
    tooltip: {
      positioner: function (a, b, point) {
        return {
          x: point.plotX,
          y: this.chart.plotHeight
        }
      },
      shared: true,
      valueSuffix: '%',
      style: {
        fontSize: '12px'
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0
      }
    },
    series: buildLineSeries()
  }

  // Highcharts uses SVG and need set width and height to print properly
  return (
    <>
      <div className='revenue-model' style={hideChart ? { display: 'none' } : {}}><HighchartsReact containerProps={{ id: 'monthly-forecast-chart', key: 'monthly-forecast-chart', style: { width: '100%', height: '250px' } }} highcharts={Highcharts} options={chartOptions} /></div>
      <div>
        <div className='monthly-forecast-chart' style={displayPDF ? {} : { display: 'none' }}>
          <h1 className='report-table-title'>{`Revenue Model - ${clientName}`}</h1>
          <div className='revenue-model-pdf-print'><HighchartsReact containerProps={{ id: 'monthly-forecast-chart-pdf', key: 'monthly-forecast-chart-pdf', style: { width: '274mm', height: '250px' } }} highcharts={Highcharts} options={chartOptions} /></div>
          <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
        </div>
      </div>
    </>
  )
}

export default MonthlyForecastLineChart