import React, { useState } from "react";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const DashboardViewControls = (props) => {
    let currentViewUpdatedAtTime = props.dashboardViewModalState && props.dashboardViewModalState.current_view.updated_at ? `Last updated: ${moment(props.dashboardViewModalState.current_view.updated_at).calendar()}` : '';
    let viewPrivate = props.dashboardViewModalState && props.dashboardViewModalState.current_view.private ? 'Only Me' : `All Can `;
    let viewPermissions = props.dashboardViewModalState && props.dashboardViewModalState.current_view.permission_details === 'edit_and_view' ? 'Edit & View' : 'View';
    let finalSettingWords = viewPrivate === 'Only Me' ? viewPrivate : viewPrivate + viewPermissions;
    let controlsClass = 'dashboard-view-controls-money'
    let groupLabelMy = ''
    let groupLabelAll = ''

    if (props.report === 'practice') {
        groupLabelMy = 'My Practice Views'
        groupLabelAll = 'All Practice Views'
    }
    if (props.report === 'money') {
        groupLabelMy = 'My Money Views'
        groupLabelAll = 'All Money Views'
    }
    if (props.report === 'people') {
        controlsClass = 'dashboard-view-controls-people'
        groupLabelMy = 'My People Views'
        groupLabelAll = 'All People Views'
    }
    if (props.report === 'customers') {
        controlsClass = 'dashboard-view-controls-customers'
        groupLabelMy = 'My Customers Views'
        groupLabelAll = 'All Customers Views'
    }

    if (props.report === 'industry') {
        if (props.page === 'money') {
            groupLabelMy = 'My Industry Money Views'
            groupLabelAll = 'All Industry Money Views'
        }
        if (props.page === 'people') {
            controlsClass = 'dashboard-view-controls-people'
            groupLabelMy = 'My Industry People Views'
            groupLabelAll = 'All Industry People Views'
        }
        if (props.page === 'customers') {
            controlsClass = 'dashboard-view-controls-customers'
            groupLabelMy = 'My Industry Customers Views'
            groupLabelAll = 'All Industry Customers Views'
        }
    }

    return (
        <div className='dashboard-view-controls-container hide-on-print'>
            <div className='dashboard-view-blank-div'></div>
            <div className={controlsClass}>
                <div className='dashboard-view-selection'>
                    <label htmlFor='dashboard-view-select'>Current View:</label>
                    <select
                        id='dashboard-view-select'
                        value={props.dashboardViewModalState.current_view.id}
                        onChange={({ target: { value } }) => props.getDashBoardView(value)}
                    >
                        <optgroup label={groupLabelMy}>
                            {props.dashboardViewModalState.user_views.map((option) => (
                                <option key={`${option.id}-${option.name}`} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label={groupLabelAll}>
                            {props.dashboardViewModalState.firms_views.map((option) => (
                                <option key={`${option.id}-${option.name}`} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </optgroup>
                    </select>
                </div>
                <button className='dashboard-view-setting-btn' onClick={() => props.handleDashboardViewModal('edit_view')}><span>{finalSettingWords}</span> <i className='fa fa-pencil dashboard-view-edit-icon'></i></button>
            </div>
            <span className='current-view-updated-time'>{currentViewUpdatedAtTime}</span>
        </div>
    )
}

export default DashboardViewControls;