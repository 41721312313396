export { capitalize }
export { createMoneyFormat }
export { generateId }
export { parseMoney }
export { percentify }

const capitalize = (word) => {
  const split = word.split('')
  split[0] = word[0].toUpperCase()
  return split.join('')
}

const createMoneyFormat = (n) => {
  let int = parseInt(n)
  if ((int && typeof int === 'number') || int === 0) {
    int = parseInt(Math.round(n))
    return int.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })
  } else if (n === '-') {
    return '-$0'
  } else if (n === undefined) {
    return '$0'
  } else {
    return '$0'
  }
};

const generateId = () => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

function parseMoney (n, noRounding = false) {
  let int = parseInt(n)
  if ((int && typeof int === 'number') || int === 0) {
    if (noRounding) {
      return n.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      int = parseInt(Math.round(n))
      return int.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })
    }
  } else if (n === undefined || n === null) {
    return ''
  } else {
    return '-'
  }
};

const percentify = (val, muliplier = 1) => {
  if (val || val === 0) {
    return ((Math.round(val * 100) / 100) * muliplier) + '%'
  } else {
    return '0%'
  }
}
