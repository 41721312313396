import React, { useState } from 'react';

const EventCounter = (props) => {

  const [current, setCurrent] = useState(0)

  const goToNext = () => {
    const length = Object.keys(props).length;
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  const slideBuilder = (key, index) => {
    
    return(
      <div key={index} className={index === current ? "visits-slide active cardify" : "visits-slide"}>
        <div className="visits-slide-content">
          {key[0]} : {key[1]}
          <div className="visits-navigation" onClick={goToNext}>Next</div>
        </div>
      </div>
    )
  }

  const slides = []
  Object.entries(props).forEach((key, value) => slides.push(slideBuilder(key, value)))
  
  return (
    <div>
      <h1>Total Events</h1>
      <div className="visits-slider">
        {slides}
      </div>
    </div>
  );
}

export default EventCounter;