import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

const ApplyChangesToast = (props) => {
    const handleApply = (apply) => {
        props.setApplyActions(apply)
        props.closeToast()
        props.value ? props.handleScenarioChange(props.value) : props.handleScenarioChange()
    }

    let scenarioLiveDataText = props.liveData ? 'live data' : 'scenario'

    return (
        <div className='forecast-scenario-alert'>
            <p className='p1'>{`Do you want to apply your changes so far to the ${scenarioLiveDataText} being loaded?`}</p>
            <div className='btn-section'><button onClick={() => handleApply(true)}>Yes</button><button onClick={() => handleApply(false)}>No</button></div>
        </div>
    )

}

export default ApplyChangesToast;