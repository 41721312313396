import React from 'react';
import ReportContainer from './ReportContainer';
import Report from './Report';
import CustomReport from './CustomReport';

const ReportContainerWrapper = (props) => {
  let planAccess = props.planAccess
  let pending_calcs = props.pending_calcs
  let subdomain = props.subdomain
  let industry = props.industry
  let naicsCode = props.code
  let logo = props.logo
  let real_name = props.real_name

  if (props.page !== 'customreport') {
    return (
      <ReportContainer {...props}>
        {(props) => <Report {...props} planAccess={planAccess} industry={industry} naicsCode={naicsCode} pending_calcs={pending_calcs} subdomain={subdomain} logo={logo} real_name={real_name} />}
      </ReportContainer>
    )
  } else {
    return (<CustomReport {...props} />)
  }
}

export default ReportContainerWrapper;
