import React from 'react'
import { CommonSizeArrows } from '../report_components/MoneyHelpers'
import { sumTrailing12MonthsValues } from '../monthly/monthy_analysis_table_trailing12_calculations';

function monthlyCommonSized(object, passedCalcs) {
    const reducedArray = object ? Object.values(object).reduce((a,b) => a + b, 0) : 0
    const totalRevenue = Object.values(passedCalcs['total_revenue']).reduce((a,b) => a + b, 0)
    if (totalRevenue !== 0) {
        return (reducedArray / totalRevenue)*100
    } else {
        return undefined
    }
}

function commonSizeArrowsHelper(dataCalcs, propsYou, propsComparedTo, passedCalcs, keyArrBuilder, displayColumnsBy) {
    let arrow

    if (displayColumnsBy === 'Months') {
        const totalRevenueYou = Object.values(dataCalcs['total_revenue']).reduce((a,b) => a + b, 0)
        const totalRevenueComparedTo = Object.values(passedCalcs['total_revenue']).reduce((a,b) => a + b, 0)
        if (totalRevenueYou !== 0 && totalRevenueComparedTo !== 0) {
            const reducedArrayYou = Object.values(propsYou).reduce((a,b) => a + b, 0)
            const reducedArrayComparedTo = propsComparedTo ? Object.values(propsComparedTo).reduce((a,b) => a + b, 0) : 0

            const you = (reducedArrayYou / totalRevenueYou)*100
            const compareTo = (reducedArrayComparedTo / totalRevenueComparedTo)*100
            arrow = CommonSizeArrows(you, compareTo, keyArrBuilder)
        } else {
            return arrow = ''
        }
    } else {
        arrow = CommonSizeArrows(propsYou, propsComparedTo, keyArrBuilder)
    }
    if (arrow === 'better' || arrow === 'best') {
        arrow = <p className='common-size-arrow-green' style={{margin: 0, color: 'green'}}>&#9650;</p>
    } else if (arrow === 'worse' || arrow === 'worst') {
        arrow = <p className='common-size-arrow-red' style={{margin: 0, color: 'red'}}>&#9660;</p>
    } else {
        arrow = ''
    }

    return arrow;
}

function reduceYTD ( dataCalcs, year, who, key, commonSize = false) {
    let answer = dataCalcs[year][who][key] ? Object.values(dataCalcs[year][who][key]).reduce((a,b) => a + b, 0) : undefined
    let totalRevenue = dataCalcs[year][who]['total_revenue'] ? Object.values(dataCalcs[year][who]['total_revenue']).reduce((a,b) => a + b, 0) : undefined

    answer && commonSize && totalRevenue ? answer = (answer / totalRevenue) * 100 : answer

    return answer;
}


// this is the year to date array builder section YTD and monthly
// ytd calcs come like this: 2020: {you: {roa: {1: 1.1270120245297484, 2: null}, 2019: {you: {roa: {1: 1.1270120245297484, 2: null},
// for ytd filter we want the trailing twelve for the last month available
// year to date only ever compares with peers, there is no YOY filter, because it already is YOY
// if we're displaying years 2019 and 2020, we'll need full years back to 2017
// 2020/2019/2018
// 2019/2018/2017
// previousYearCalc.monthly['2019']

const peopleCustomersT12Metrics = (peopleOrCustomers, metricName, currentYear, previousYear, twoYearsBack) => {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    let returnObj = {};
    currentYear = currentYear || {}
    previousYear = previousYear || {}
    twoYearsBack = twoYearsBack || {}

    switch (metricName) {
        // People methods for report summary
        case 'total_revenue_per_employee':
        case 'revenue_per_customer':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['total_revenue'], 'numClientsEmpl')
            break;
        case 'operating_expenses_net_income_per_employee':
        case 'operating_expenses_net_income_per_customer':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['operating_expenses_net_income'], 'numClientsEmpl')
            break;
        case 'turnover_rate':
        case 'total_revenue':
        case 'gross_profit':
        case 'total_number_of_customers':
        case 'total_number_of_employees':
        case 'operating_expenses_net_income':
            returnObj[metricName] = currentYear.hasOwnProperty(metricName) ? currentYear[metricName] : undefined
            break;
        case 'sum_compensation_per_employee':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['sum_compensation'], 'numClientsEmpl')
            break;
        case 'sum_taxes_and_benefits_per_employee':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['sum_taxes_and_benefits'], 'numClientsEmpl')
            break;
        case 'sum_payroll_per_employee':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['sum_payroll'], 'numClientsEmpl')
            break;
        case 'sum_health_insurance_per_employee':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['sum_health_insurance'], 'numClientsEmpl')
            break;
        case 'sum_retirement_per_employee':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['sum_retirement'], 'numClientsEmpl')
            break;
        case 'training_per_employee':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['training'], 'numClientsEmpl')
            break;
        case 'number_of_employees_who_left':
            returnObj[metricName] = numberEmployeesWhoLeft(months, currentYear)
            break;
        case 'cost_of_turnover':
            break;                

        // People methods for KPIs
        case 'sum_compensation_growth_rate':
        case 'sum_taxes_and_benefits_growth_rate':
        case 'sum_subcontractors_growth_rate':
            returnObj[metricName] = pcGrowthRate(months, currentYear, previousYear, twoYearsBack, metricName)
            break;
        case 'average_wages_and_salaries':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['labor_cogs_compensation', 'labor_cogs_taxes_and_benefits', 'total_compensation', 'total_taxes_and_benefits'], 'numClientsEmpl')
            break;
        case 'average_benefits':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['labor_cogs_taxes_and_benefits', 'total_taxes_and_benefits'], 'numClientsEmpl')
            break;

        // Customers methods for report summary
        case 'gross_profit_per_customer':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['gross_profit'], 'numClientsEmpl')
            break;
        case 'customer_roi':
            returnObj[metricName] = pcCustomerROI(months, currentYear, previousYear, twoYearsBack)
            break;
        case 'total_customer_costs_common_size_revenue':
            returnObj[metricName] = pcNumeratorArDividedByDenominator(months, peopleOrCustomers, currentYear, previousYear, ['total_customer_costs'], 'total_revenue')                
            break;
        case 'number_of_new_customers_needed_to_maintain_growth':
            returnObj[metricName] = pcNumberCustomersNeededMaintainGrowth(months, currentYear, previousYear, twoYearsBack)
            break;
        
        default:
            returnObj[metricName] = undefined
            break;
        }
    return returnObj
}

const numberEmployeesWhoLeft = (months, currentYear) => {
    let turnoverRate = currentYear.hasOwnProperty('turnover_rate') ? currentYear['turnover_rate'] : {}
    let numberEmployees = currentYear.hasOwnProperty('total_number_of_employees') ? currentYear['total_number_of_employees'] : {}
    let returnObj = {};

    if (turnoverRate && numberEmployees) {
        months.forEach((m, i) => {
            returnObj[i+1] = turnoverRate[i+1] * numberEmployees[i+1]
        })
    } else returnObj = undefined

    return returnObj
}

const pcNumberCustomersNeededMaintainGrowth = (months, currentYear, previousYear, twoYearsBack) => {
    let sumT12TotalRev = currentYear.hasOwnProperty('total_revenue') && previousYear.hasOwnProperty('total_revenue') ? sumTrailing12MonthsValues(currentYear, previousYear, 'total_revenue') : {}
    let totRevGrowthRateT12 = pcGrowthRate(months, currentYear, previousYear, twoYearsBack, 'total_revenue')
    let revPerT12 = pcNumeratorArDividedByDenominator(months, 'customers', currentYear, previousYear, ['total_revenue'], 'numClientsEmpl')
    let returnObj = {};

    if (sumT12TotalRev && totRevGrowthRateT12 && revPerT12) {
        months.forEach((m, i) => {
            returnObj[i+1] = (sumT12TotalRev[i+1] * totRevGrowthRateT12[i+1]) / revPerT12[i+1]
        })
        return returnObj
    } else returnObj = undefined

    return returnObj
    // (total_revenue * total_revenue_growth_rate) / revenue_per_customer
}


const pcCustomerROI = (months, currentYear, previousYear, twoYearsBack) => {
    let sumT12TotalRev = currentYear.hasOwnProperty('total_revenue') && previousYear.hasOwnProperty('total_revenue') ? sumTrailing12MonthsValues(currentYear, previousYear, 'total_revenue') : {}
    let sumT12TotalRevPreviousDecember  = previousYear.hasOwnProperty('total_revenue') && twoYearsBack.hasOwnProperty('total_revenue') ? sumTrailing12MonthsValues(previousYear, twoYearsBack, 'total_revenue') : {}
    let sumT12TotCusCosts = currentYear.hasOwnProperty('total_customer_costs') && previousYear.hasOwnProperty('total_customer_costs') ? sumTrailing12MonthsValues(currentYear, previousYear, 'total_customer_costs') : {}
    let returnObj = {};

    if (sumT12TotalRev && sumT12TotCusCosts) {
        months.forEach((month,i) => {
            if (i === 0) {
                returnObj[i+1] = ((sumT12TotalRev[1] - sumT12TotalRevPreviousDecember[12]) - (sumT12TotCusCosts[1])) / sumT12TotCusCosts[1]
            } else {
                returnObj[i+1] = ((sumT12TotalRev[i+1] - sumT12TotalRev[i]) - (sumT12TotCusCosts[i+1])) / sumT12TotCusCosts[i+1]
            }
        })
        return returnObj
    } else returnObj = undefined

    return returnObj
}


const pcGrowthRate = (months, currentYear, previousYear, twoYearsBack, metricName) => {
    let returnObj = {};
    let metricString = ''
    
    switch (metricName) {
        case 'sum_compensation_growth_rate':
            metricString = 'sum_compensation'
            break;
        case 'sum_taxes_and_benefits_growth_rate':
            metricString = 'sum_taxes_and_benefits'
            break;                
        case 'sum_subcontractors_growth_rate':
            metricString = 'sum_subcontractors'
            break;
        default:
            metricString = metricName
            break;
    }
            
    let numeratorDenominatorSumT12 = currentYear.hasOwnProperty(metricString) && previousYear.hasOwnProperty(metricString) ? sumTrailing12MonthsValues(currentYear, previousYear, metricString) : {}
    let forJanDenominatorSumT12  = previousYear.hasOwnProperty(metricString) && twoYearsBack.hasOwnProperty(metricString) ? sumTrailing12MonthsValues(previousYear, twoYearsBack, metricString) : {}

    if (numeratorDenominatorSumT12) {

        months.forEach((month, i) => {
            let monthNumber = i+1
            
            if (monthNumber === 1) {
                returnObj[i+1] = (numeratorDenominatorSumT12[1] / forJanDenominatorSumT12[12]) -1 
            } else {
                returnObj[i+1] = (numeratorDenominatorSumT12[monthNumber] / numeratorDenominatorSumT12[monthNumber-1]) -1 
            }
        })
        return returnObj
    } else returnObj = undefined
        
    return returnObj
}


const pcNumeratorArDividedByDenominator = (months, peopleOrCustomers, currentYear, previousYear, numeratorAr, denominator) => {
    let numeratorSumT12 = {};
    let denominatorSumT12;
    let returnObj = {};

    months.forEach((month, i) => numeratorSumT12[i+1] = 0)

    numeratorAr.forEach(metric => {
        let metricLoop = currentYear.hasOwnProperty(metric) && previousYear.hasOwnProperty(metric) ? sumTrailing12MonthsValues(currentYear, previousYear, metric) : 0
        if (metricLoop) {
            Object.values(metricLoop).forEach((v,i) => {
                numeratorSumT12[i+1] += v
            })
        }
    })

    if (denominator === 'numClientsEmpl' && peopleOrCustomers === 'people') {
        denominatorSumT12 = currentYear.hasOwnProperty('total_number_of_employees') ? currentYear['total_number_of_employees'] : 0 
    } else if (denominator == 'numClientsEmpl' && peopleOrCustomers == 'customers') {
        denominatorSumT12 = currentYear.hasOwnProperty('total_number_of_customers') ? currentYear['total_number_of_customers'] : 0
    } else {
        denominatorSumT12 = currentYear.hasOwnProperty(denominator) && previousYear.hasOwnProperty(denominator) ? sumTrailing12MonthsValues(currentYear, previousYear, denominator) : 0
    }

    if (numeratorSumT12 && denominatorSumT12) {
        months.forEach((month, i) => {
            returnObj[i+1] = numeratorSumT12[i+1] / denominatorSumT12[i+1]
        })
        return returnObj
    } else returnObj = undefined

    return returnObj
}

const peersAnalysisPerCustomerVals = (key, year, vals) => {
    let finalValue = null
    let firstPartKey = key.substring(0, key.length - 13)
    if (key === 'revenue_per_customer') {
        finalValue = vals['total_revenue'][year] / vals['total_number_of_customers'][year]
    } else if (key.includes('_per_customer')) {
        let totalVal = vals[firstPartKey + '_common_size_revenue'][year] * vals['total_revenue'][year]
        finalValue = totalVal / vals['total_number_of_customers'][year]
    } else {
        finalValue = vals[key + '_common_size_revenue'][year] * vals['total_revenue'][year]
    }
    return finalValue
}

export { monthlyCommonSized, commonSizeArrowsHelper, reduceYTD, peopleCustomersT12Metrics, peersAnalysisPerCustomerVals }