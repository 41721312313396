import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { AnnualKFIsValues } from './MoneyHelpers';

const compareWithRowKeys = {
  'None': null,
  'Practice': 'practice_avg',
  'Peers': 'avg',
  'NSCHBC': 'nschbc'
}

const MoneyKFI = (props) => {
  const { calcs } = useContext(Data)
  const allFilters = useContext(Filters);
  const compareWith = allFilters.currentNewFilters.compare_with;
  const calcsAvgType = compareWithRowKeys[compareWith]
  const displayCompareCol = props.reportType === 'audit-kfis' || compareWith === 'None' ? false : true

  const formatPercent = (value, key) => {
    if (value || value === 0) {
      return (Math.round(value * 10000) / 100) + '%';
    } else {
      return '-'
    }
  };

  const formatRatio = (value) => {
    if (value || value === 0) {
      return `${value.toFixed(1)} to 1`;
    } else {
      return '-'
    }
  };

  const formatDays = (value) => {
    if (value || value === 0) {
      return `${value.toFixed(1)} days`;
    } else {
      return '-'
    }
  };

  const formatTwoDecimal = (value) => {
    if (value || value === 0) {
      return value.toFixed(2);
    } else {
      return '-'
    }
  };

  const rowFormatter = (amount, key) => {
    const roundNumber = ['number_of_holes_on_golf_course'];
    const ratio = ['quick_ratio', 'debt_ratio', 'operating_cash_flow_kfi', 'current_ratio', 'net_working_capital_ratio', 'debt_to_equity', 'senior_debt_to_ebitda', 'ebitda_to_annual_interest']
    const days = ['days_cash_on_hand', 'ar_days', 'ap_days']
    const money = ['net_working_capital', 'operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization', 'ebitda', 'maintenace_per_golf_hole']
    const twoDecimal = ['asset_turnover_ratio', 'inventory_turnover_ratio']
    const percent = ['total_revenue_growth_rate', 'net_profit', 'cash_flow_margin', 'roa', 'roe', 'roce', 'gross_profit', 'operating_profit']

    if (key.includes('title')) {
      return ''
    } else if (roundNumber.includes(key)) {
      return _.isNumber(amount) ? Math.round(amount) : '-'
    } else if (ratio.includes(key)) {
      return formatRatio(amount)
    } else if (days.includes(key)) {
      return formatDays(amount)
    } else if (money.includes(key)) {
      return parseMoney(amount);
    } else if (twoDecimal.includes(key)) {
      return formatTwoDecimal(amount)
    } else if (percent.includes(key)) {
      return formatPercent(amount, key)
    } else {
      return _.isNumber(amount) ? '-' : ''
    };
  };

  const buildRows = () => {
    let kfisRows = [];
    props.rows.forEach((row, i) => {
      let rowValues = AnnualKFIsValues(calcs, calcsAvgType, props.yearRange, row.key)
      let newRow = { ...row, ...rowValues }
      kfisRows.push(newRow)
    })

    return kfisRows.map((row, i) => {
      let paddingLeft = { paddingLeft: row.paddingLeft }
      let padding = row.pad ? { padding: '3px', paddingBottom: '20px' } : { padding: '3px' }
      if (props.pdf && padding.paddingBottom) {
        padding.paddingTop = '1px'
        padding.paddingBottom = '8px'
      } else if (props.pdf) {
        padding.paddingTop = '1px'
        padding.paddingBottom = '1px'
      }
      let titleStyle = { ...paddingLeft, ...padding }
      let hideRow = '';

      let rowStyle = row.borderBottom ? { borderBottom: row.borderBottom } : {}
      if (row.key === hideRow) {
        rowStyle['display'] = 'none';
      }

      let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''

      return (
        <tr key={Math.random(i)} style={hideRow === row.copy ? { display: 'none' } : rowStyle} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'kfis', row.relatedCheckedKey) : null}>
          {props.reportType !== 'audit-kfis' && !props.pdf ? <td style={padding}>{rowChecked}</td> : null}
          <td className='table-copy' style={titleStyle}>{row.padding + row.copy}</td>
          {props.yearRange.map((year, ii) => {
            let arrowCellClass = 'common-size-arrow';
            let backgroundStyle = {}
            backgroundStyle['textAlign'] = props.reportType !== 'audit-kfis' ? '' : 'center';
            let commonSizeValCellClass = 'common-size-cell';
            let valueCellClass = '';
            if (ii % 2 === 0) {
              arrowCellClass += ' money-table-cell';
              backgroundStyle['backgroundColor'] = '#BFE0AE';
              commonSizeValCellClass += ' money-table-cell';
              valueCellClass = 'money-table-cell';
            }
            let titleRowStyles = { 'borderBottom': '2px solid #000000', ...backgroundStyle };
            let arrowStyles = { 'textAlign': 'center', ...backgroundStyle, ...padding };

            let cellStyle = { ...backgroundStyle, ...padding }
            try {
              if (row.parentKey !== 'parent') {

                let arrowColumn = ''
                let youValue = row[year];
                let avgValue = row['avg' + year];
                let status = row['status' + year]
                if (status === 'worse' || status === 'worst') {
                  arrowColumn = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
                } else if (status === 'better' || status === 'best') {
                  arrowColumn = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
                } else {
                  arrowColumn = ''
                }

                return (
                  <React.Fragment key={ii + row.key}>
                    <td className={commonSizeValCellClass} style={cellStyle}>
                      {rowFormatter(youValue, row.key)}
                    </td>
                    {displayCompareCol ? <td className={arrowCellClass} style={arrowStyles}>{arrowColumn}</td> : null}
                    {displayCompareCol ? <td className={commonSizeValCellClass} style={cellStyle}>{rowFormatter(avgValue, row.key)}</td> : null}
                    <td></td>
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={Math.random(ii)}>
                    <td className={commonSizeValCellClass} style={titleRowStyles}></td>
                    {displayCompareCol ? <td className={commonSizeValCellClass} style={titleRowStyles}></td> : null}
                    {displayCompareCol ? <td className={commonSizeValCellClass} style={titleRowStyles}></td> : null}
                    <td style={{ 'borderBottom': '2px solid #000000' }}></td>
                  </React.Fragment>
                )
              }
            } catch (e) {
              console.log(e)
              return (<td className={commonSizeValCellClass} key={Math.random(ii)}></td>);
            }
          })}
        </tr>
      )
    });
  };

  const renderYearHeaderColumns = (years) => {
    const currentYear = new Date().getFullYear();
    let youPeersRow = [];
    let formatedYearsRow = [];

    years.forEach((y, i) => {
      let arrowCellClass = 'common-size-arrow';
      let backgroundStyle = {}
      let commonSizeValCellClass = 'common-size-cell';
      let valueCellClass = '';
      let youDate, compareDate = '';

      if (y === currentYear) {
        youDate = props.companyYTDDate;
        compareDate = props.aggregateYTDDate;
      } else {
        youDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${y}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${y + 1}` : `12/31/${y}`;
        compareDate = `12/31/${y}`;
      }

      if (i % 2 === 0) {
        arrowCellClass += ' money-table-cell';
        backgroundStyle['backgroundColor'] = '#BFE0AE';
        commonSizeValCellClass += ' money-table-cell';
        valueCellClass = 'money-table-cell'
      }

      youPeersRow.push(
        <React.Fragment key={Math.random(i)}>
          <td className={commonSizeValCellClass} style={backgroundStyle}>You</td>
          {displayCompareCol ? <td className={arrowCellClass} style={backgroundStyle}></td> : null}
          {displayCompareCol ? <td className={commonSizeValCellClass} style={backgroundStyle}>{compareWith}</td> : null}
          <td className='space-cell'></td>
        </React.Fragment>
      )

      formatedYearsRow.push(
        <React.Fragment key={Math.random(i)}>
          <td className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
          {displayCompareCol ? <td className={arrowCellClass} style={backgroundStyle}></td> : null}
          {displayCompareCol ? <td className={commonSizeValCellClass} style={backgroundStyle}>{compareDate}</td> : null}
          <td className='space-cell'></td>
        </React.Fragment>
      )
    })

    return (
      <thead>
        <tr key={Math.random(20)}>
          {props.reportType !== 'audit-kfis' && !props.pdf
            ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>
            : null
          }
          <td></td>
          {youPeersRow}
        </tr>
        <tr key={Math.random(20)}>
          {props.reportType !== 'audit-kfis' && !props.pdf
            ? <td></td>
            : null
          }
          <td></td>
          {formatedYearsRow}
        </tr>
      </thead>
    )
  }

  const renderKFI = () => {
    if (props.pdf) {
      let pageViewClass = 'landscape-view'
      return (
        <div className={pageViewClass}>
          <div className='report-pdf-landscape'>
            <div>
              <h1 className='report-table-title'>{`Key Financial Indicators - ${props.clientName}`}</h1>
              <div className='money-pdf-table-div'>
                <table id='annual-kfi-table-pdf' className='money-pdf-table'>
                  {renderYearHeaderColumns(props.yearRange)}
                  <tbody>
                    {buildRows()}
                    <tr><td className='footer-cell'><i>n = {props.n ? props.n : 0}</i></td></tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='money-report-card'>
          <div className='money-report-container'>
            <table id='annual-kfi-table' className='report-table'>
              {renderYearHeaderColumns(props.yearRange)}
              <tbody>
                {buildRows()}
              </tbody>
              {props.reportType !== 'audit-kfis'
                ? (
                  <tfoot>
                    <tr><td><i>n = {props.n ? props.n : 0}</i></td></tr>
                  </tfoot>
                )
                : null
              }
            </table>
          </div>
        </div>
      )
    }
  }

  return renderKFI()
};

export default MoneyKFI;
