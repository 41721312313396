const getYPostion = (heightSpaceLeft) => {
    switch (heightSpaceLeft) {
        case 7:
            return 0
        case 6:
            return 1
        case 5:
            return 2
        case 4:
            return 3
        case 3:
            return 4
        case 2:
            return 5
        case 1:
            return 6
        case 0:
            return 0
        default:
            return 0
    }
}

const getDashBoardPagesPDF = (dashboardLayout) => {
    if (dashboardLayout && dashboardLayout.length > 0) {
        // landscape layout = 6 squares width X 4 square height, heightSpaceLeft [4, 4, 4, 4, 4, 4]
        // portrait layout = 6 squares width X 7 squares height, heightSpaceLeft [7, 7, 7, 7, 7, 7]
        let dashBoardPages = {
            1: {
                heightSpaceLeft: [7, 7, 7, 7, 7, 7],
                items: [],
                full: false
            }
        }
        let allDashboards = []
        let currentPage = 0
        let nextPage = 1
        // Need to break layout here
        // sort by y's here
        let sortedByY = dashboardLayout.sort((a, b) => a.y - b.y)
        let lastItem = sortedByY[sortedByY.length - 1]
        let estPageNum = Math.round((lastItem.y + 7) / 7)
        let Yends = []
        let YendSum = 0

        for (let i = 0; i <= estPageNum; i++) {
            YendSum = i == 0 ? 6 : YendSum + 7
            Yends.push(YendSum)

            if (!dashBoardPages[i + 1]) {
                dashBoardPages[i + 1] = {
                    heightSpaceLeft: [7, 7, 7, 7, 7, 7],
                    items: [],
                    full: false
                }
            }
        }

        // Order by Y Position VERY IMPORTANT!!!
        sortedByY.forEach((l) => {
            let itemAdded = false
            let allPageNumbers = Object.keys(dashBoardPages)
            let pageIndex = Yends.findIndex((y) => (y - 6) <= l.y && l.y <= y)
            let startPageNumbers = allPageNumbers.slice(pageIndex)

            startPageNumbers.forEach((p, pageIndex) => {
                let currentPage = dashBoardPages[p]

                if (!itemAdded && !currentPage.full) {
                    let goToNextPage = false
                    // Need to get space left not from an item's x position but from the whole width of the item
                    let itemWidthSection = currentPage.heightSpaceLeft.slice(l.x, l.x + l.w)
                    let leastSpaceLeft = Math.min(...itemWidthSection)
                    let newHeightSpaceLeft = currentPage.heightSpaceLeft.map((height, index) => {
                        let stopIndex = l.x + (l.w - 1)
                        if (l.x <= index && index <= stopIndex && !goToNextPage) {
                            if (height - l.h < 0) {
                                goToNextPage = true

                                if (!dashBoardPages[pageIndex + 2]) {
                                    dashBoardPages[pageIndex + 2] = {
                                        heightSpaceLeft: [7, 7, 7, 7, 7, 7],
                                        items: [],
                                        full: false
                                    }
                                }

                                return height
                            } else {
                                return leastSpaceLeft - l.h
                            }
                        } else {
                            return height
                        }
                    })

                    if (!goToNextPage) {
                        currentPage.heightSpaceLeft = newHeightSpaceLeft
                        let heightSpaceLeftAtX = newHeightSpaceLeft[l.x]
                        let newY = getYPostion(heightSpaceLeftAtX + l.h)
                        let dashItemCopy = _.cloneDeep(l)
                        dashItemCopy.y = newY
                        currentPage.items.push(dashItemCopy)
                        itemAdded = true
                        let getZeroHeights = newHeightSpaceLeft.filter(n => n == 0)
                        if (getZeroHeights.length == 7) {
                            currentPage.full = true
                        }
                    }
                }
            })
        })

        let pageNumbers = Object.keys(dashBoardPages)
        pageNumbers.forEach(p => {
            if (dashBoardPages[p]['items'].length > 0) {
                allDashboards.push(dashBoardPages[p]['items'])
            }
        })

        return allDashboards;
    } else {
        return []
    }
}

export { getDashBoardPagesPDF };