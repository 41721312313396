import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { createMoneyFormat, parseMoney, percentify } from '../../utils';
import AuditScenarios from './AuditScenarios';

const defaultWPScenario = {
    id: 'wp-default',
    name: 'Default',
    report_type: 'wp-analytics',
    created_by: '',
    updated_by: '',
    updated_at: '',
    version: 1,
    data: {
        selected_month: 6,
        dollar_change: {
            integer: 10000,
            text: "$10,000"
        },
        percent_change: {
            integer: 10,
            text: "10%"
        },
        audit_data: [{
            sheet: "balance_sheet",
            key: "cash",
            preliminary_risk_assessment: 'Low',
            explaination_variances: 'Explain!',
            qualitatively_significant: 'Yes',
            cx7form: '',
            comment: 'My comment.'
        }
        ]
    }
}

function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0);
}

const AuditWPAnalytics = (props) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [showDetails, setShowDetails] = useState(false)
    const [auditWPScenario, setAuditWPScenario] = useState(props.auditScenario)
    useEffect(() => {
        setAuditWPScenario(() => {
            return props.auditScenario
        })
    }, [props.auditScenario])

    const handleAuditInfo = (key, sheet, type, value) => {
        const auditDataExists = auditWPScenario['data']['audit_data'].find(a => a.key === key)

        if (auditDataExists) {
            const newAuditData = auditWPScenario['data']['audit_data'].map((a) => {
                if (a.key === key) {
                    a[type] = value
                }
                return a
            })

            setAuditWPScenario((prevState) => {
                let newAuditScenario = prevState.data
                newAuditScenario['audit_data'] = newAuditData
                return {
                    ...prevState,
                    data: newAuditScenario
                }
            })
        } else {
            const newAuditDataObj = {
                sheet: sheet,
                key: key
            }

            newAuditDataObj['preliminary_risk_assessment'] = ''
            newAuditDataObj['explaination_variances'] = ''
            newAuditDataObj['qualitatively_significant'] = ''
            newAuditDataObj['cx7form'] = ''
            newAuditDataObj['comment'] = ''

            newAuditDataObj[type] = value
            setAuditWPScenario((prevState) => {
                let newAuditScenario = prevState.data
                newAuditScenario['audit_data'] = [newAuditDataObj, ...newAuditScenario['audit_data']]
                return {
                    ...prevState,
                    data: newAuditScenario
                }
            })

        }
    }

    const annualizedCY = (row) => {
        let annualizedCYVal = null;
        if (_.isNumber(row[props.yearRange[1]])) {
            annualizedCYVal = row[props.yearRange[1]] * (12 / parseInt(props.selectedMonth))
        }
        return annualizedCYVal
    }

    const deltaDollarValue = (row, annualizedCYVal = 0) => {
        let finalVal = null
        if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(row[props.yearRange[1]])) {
            if (row.sheet === 'pnl') {
                finalVal = parseMoney(annualizedCYVal - row[props.yearRange[0]])
            } else {
                finalVal = parseMoney(row[props.yearRange[1]] - row[props.yearRange[0]])
            }
        }
        return finalVal
    }

    const deltaPercentColumn = (row, annualizedCYVal = 0) => {
        let finalVal = null
        if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(row[props.yearRange[1]])) {
            if (row.sheet === 'pnl') {
                let deltaVal = annualizedCYVal - row[props.yearRange[0]]
                finalVal = row[props.yearRange[0]] ? ((deltaVal / row[props.yearRange[0]]) * 100).toFixed(0) + '%' : '100%';
            } else {
                let deltaVal = row[props.yearRange[1]] - row[props.yearRange[0]]
                finalVal = row[props.yearRange[0]] ? ((deltaVal / row[props.yearRange[0]]) * 100).toFixed(0) + '%' : '100%';
            }
        }
        return finalVal
    }

    const analyticScope = (row, annualizedCYVal = 0) => {
        let dollarDiff = 0
        let percentDiff = 0
        let minuend = row.sheet === 'pnl' ? annualizedCYVal : row[props.yearRange[1]]
        if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(minuend)) {
            dollarDiff = Math.abs(minuend - row[props.yearRange[0]])
            percentDiff = (dollarDiff) / Math.abs(row[props.yearRange[0]]) * 100
        }
        return dollarDiff > props.dollarChange.integer && percentDiff > props.percentChange.integer ? 'Yes' : 'No'
    }

    const quantitativelySignificant = (row) => {
        let dollarDiff = 0
        let yesOrNo = ''
        if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(row[props.yearRange[1]])) {
            dollarDiff = row[props.yearRange[1]] - row[props.yearRange[0]]
            yesOrNo = row[props.yearRange[1]] > dollarDiff ? 'Yes' : 'No'
        }
        return yesOrNo
    }

    const scopedInForTesting = (quantVal, qualVal) => {
        if (quantVal == qualVal) {
            return quantVal;
        } else if (quantVal === 'Yes' || qualVal === 'Yes') {
            return 'Yes'
        } else if (quantVal && !qualVal) {
            return quantVal
        } else if (!quantVal && qualVal) {
            return qualVal
        }
    }

    function buildRows() {
        let totalRevenueAnnualized, totalAssetsUnadjusted;
        if (props.compareWith === 'Peers PY') {
            let revenueRow = props.rows.find(r => r.key === 'total_revenue')
            totalRevenueAnnualized = annualizedCY(revenueRow)
            let totalAssetsRow = props.rows.find(r => r.key === 'total_assets')
            totalAssetsUnadjusted = totalAssetsRow[props.year]
        }
        return props.rows.map((row, i) => {
            let underlineFormat, toplineFormat;
            if (row.emptyRow) {
                const emptyStyle = {
                    "backgroundColor": "#bfe0ae",
                    "height": "30px",
                    "borderBottom": "1px solid black"
                }
                return (
                    <tr key='wp-empty-row'>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                        <td style={emptyStyle}></td>
                    </tr>
                )
            }
            if (row.forecast) {
                if (row.forecast.total) {
                    if (row.forecast.total.boldedUnderline) {
                        underlineFormat = 'solid 1px';
                    } else if (row.forecast.total.doubleUnderline) {
                        underlineFormat = 'double 1px';
                    } else if (row.forecast.total.underline) {
                        underlineFormat = 'solid 1px';
                    }

                    if (row.forecast.total.topline) {
                        toplineFormat = 'solid 1px'
                    } else {
                        toplineFormat = ''
                    }

                } else if (row.forecast) {
                    if (row.forecast.boldedUnderline) {
                        underlineFormat = 'solid 1px';
                    } else if (row.forecast.doubleUnderline) {
                        underlineFormat = 'double 1px';
                    } else if (row.forecast.underline) {
                        underlineFormat = 'solid 1px';
                    }

                    if (row.forecast.topline) {
                        toplineFormat = 'solid 1px'
                    } else {
                        toplineFormat = ''
                    }
                }
            }

            const copyStyle = {
                borderBottom: underlineFormat,
                borderTop: toplineFormat,
            }

            let auditInfo = auditWPScenario['data']['audit_data'].find((a) => a.key === row.key);
            let preliminaryRiskAssessment = auditInfo ? auditInfo.preliminary_risk_assessment : '';

            if (props.compareWith === 'Peers PY' && row.sheet === 'pnl' && !row.header_key) {
                row[props.year - 1] = (row['avg'+(props.year - 1)] / 100) * totalRevenueAnnualized
            }
            if (props.compareWith === 'Peers PY' && row.sheet === 'balance_sheet' && !row.header_key) {
                row[props.year - 1] = (row['avg'+(props.year - 1)] / 100) * totalAssetsUnadjusted
            }

            let annualizedCYVal = row.sheet === 'pnl' ? annualizedCY(row) : null;
            let deltaDollarVal = deltaDollarValue(row, annualizedCYVal)
            let deltaPercentVal = deltaPercentColumn(row, annualizedCYVal)

            let analyticScopeVal = analyticScope(row, annualizedCYVal)
            let explainationVariances = auditInfo ? auditInfo.explaination_variances : '';
            let quantSigVal = quantitativelySignificant(row)
            let qualSigVal = auditInfo ? auditInfo.qualitatively_significant : '';
            let scopeInForTestVal = scopedInForTesting(quantSigVal, qualSigVal)
            let cx7form = auditInfo ? auditInfo.cx7form : '';
            let comment = auditInfo ? auditInfo.comment : '';

            let hideRow = '';
            let zeroYears = [];
            props.yearRange.forEach(y => { row[y] === 0 && Math.abs(row['you' + y]).toFixed(1) === '0.0' && Math.abs(row['avg' + y]).toFixed(1) === '0.0' ? zeroYears.push(y) : null })
            zeroYears.length === props.yearRange.length ? hideRow = row.copy : null
            let rowKey = row.key ? `${row.sheet}-${row.key}-report-row` : `${row.sheet}-${row.header_key}-report-row`

            return (
                <tr style={hideRow === row.copy ? { display: 'none' } : copyStyle} key={rowKey} id={rowKey}>
                    <td className='normal-whitespace-cell'>
                        <textarea key={`${row.key}-prelimAssessment-hide`} className='hide-section' value={`${preliminaryRiskAssessment}xlsxVal`} readOnly></textarea>
                        {row.key
                            ? (<select key={`${row.key}-prelimAssessment`} value={preliminaryRiskAssessment} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'preliminary_risk_assessment', e.target.value)}>
                                <option key={`${row.key}-prelimAssessment-blank`} value=''>-</option>
                                <option key={`${row.key}-prelimAssessment-high`} value='High'>High</option>
                                <option key={`${row.key}-prelimAssessment-mid`} value='Mid'>Mid</option>
                                <option key={`${row.key}-prelimAssessment-low`} value='Low'>Low</option>
                            </select>)
                            : null}
                    </td>
                    {row.copy === 'Current Liabilities'
                        ? <td className='table-copy' style={{ paddingTop: '20px' }}>{row.copyWithPadding}</td>
                        : <td className='table-copy'>{row.copyWithPadding}</td>}
                    {props.yearRange.map((year, ii) => {
                        let arrowCellClass = 'common-size-arrow';
                        let backgroundStyle = {}
                        let commonSizeValCellClass = 'common-size-cell';
                        let valueCellClass = '';
                        if (ii % 2 === 0) {
                            arrowCellClass += ' money-table-cell';
                            backgroundStyle['backgroundColor'] = '#BFE0AE';
                            commonSizeValCellClass += ' money-table-cell';
                            valueCellClass = 'money-table-cell';
                        }
                        try {
                            // return blank td's if on a header
                            if (Object.keys(row).length > 2) {
                                let arrowColumn = '';
                                let yearValue = parseMoney(row[year]);
                                return (
                                    <React.Fragment key={`year-${ii}`}>
                                        <td key={ii} className={valueCellClass} style={backgroundStyle}>
                                            {yearValue}
                                        </td>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment key={`year-${ii}-blank`}>
                                        <td className={valueCellClass} style={backgroundStyle}></td>
                                    </React.Fragment>
                                )
                            }
                        } catch (error) {
                            console.log(error, 'err')
                            return (<td key={'year-error'}></td>);
                        }
                    })}
                    <td>{parseMoney(annualizedCYVal)}</td>
                    <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>{deltaDollarVal}</td>

                    <td className='common-size-cell money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>{deltaPercentVal}</td>

                    <td className='normal-whitespace-cell yes-no-cell'>{row.key ? analyticScopeVal : null}</td>
                    <td className='normal-whitespace-cell audit-textarea-cell'>
                        {row.key
                            ? <textarea id={`${row.key}-explainvar`} key={`${row.key}-explainvar`} value={explainationVariances} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'explaination_variances', e.target.value)} ></textarea>
                            : null}
                    </td>
                    <td className='normal-whitespace-cell yes-no-cell'>{row.key ? quantSigVal : null}</td>
                    <td className='normal-whitespace-cell yes-no-cell'>
                        <textarea key={`${row.key}-qualsig-hide`} className='hide-section' value={`${qualSigVal}xlsxVal`} readOnly></textarea>
                        {row.key
                            ? (<select key={`${row.key}-qualsig`} value={qualSigVal} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'qualitatively_significant', e.target.value)}>
                                <option key={`${row.key}-qualsig-blank`} value=''>-</option>
                                <option key={`${row.key}-qualsig-yes`} value='Yes'>Yes</option>
                                <option key={`${row.key}-qualsig-no`} value='No'>No</option>
                            </select>)
                            : null}
                    </td>
                    <td className='normal-whitespace-cell yes-no-cell'>{row.key ? scopeInForTestVal : null}</td>
                    <td className='normal-whitespace-cell audit-textarea-cell'>
                        {row.key
                            ? <textarea id={`${row.key}-cx7form`} key={`${row.key}-cx7form`} value={cx7form} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'cx7form', e.target.value)} ></textarea>
                            : null}
                    </td>
                    <td className='normal-whitespace-cell audit-textarea-cell'>
                        {row.key
                            ? <textarea id={`${row.key}-comment`} key={`${row.key}-comment`} value={comment} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'comment', e.target.value)} ></textarea>
                            : null}
                    </td>
                </tr>
            )
        })
    }

    const renderYearHeaderColumns = (years) => {
        const currentYear = new Date().getFullYear();
        let youPeersRow = [];
        let formatedYearsRow = [];

        years.forEach((y, i) => {
            let arrowCellClass = 'common-size-arrow';
            let backgroundStyle = { 'borderBottom': '2px solid black' }
            let commonSizeValCellClass = 'common-size-cell';
            let valueCellClass = '';
            let youDate;
            if (i === 1) {
                let endDate = getLastDayOfMonth(y, props.selectedMonth)
                youDate = moment(endDate).format('L');
            } else {
                if (props.compareWith === 'Final PY') {
                    youDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${y}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${y + 1}` : `12/31/${y}`;
                } else {
                    let year = props.compareWithSplit ? Number(props.compareWithSplit[1]) : null;
                    youDate = year ? `12/31/${year}` : `12/31/${y}`
                }
            }

            if (i % 2 === 0) {
                arrowCellClass += ' money-table-cell';
                backgroundStyle['backgroundColor'] = '#BFE0AE';
                commonSizeValCellClass += ' money-table-cell';
                valueCellClass = 'money-table-cell'
            }

            formatedYearsRow.push(
                <React.Fragment key={Math.random(i)}>
                    <td className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
                </React.Fragment>
            )
        })

        let theads = {
            "position": "sticky",
            "top": "0px",
            "zIndex": "2",
            "backgroundColor": "white"
        }

        let borderBottomStyle = {
            "borderBottom": "2px solid black"
        }

        return (
            <thead style={theads}>
                <tr key='audit-first-header-row'>
                    <td className='normal-whitespace-cell'>Overall Expectation:</td>
                    <td className='audit-overall-textarea-cell' colSpan='8'>
                        <textarea id='audit-wp-overall-expectation' key='audit-wp-overall-expectation' value={props.overallExpectation} onChange={(e) => props.setOverallExpectation(e.target.value)}></textarea>
                    </td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                </tr>
                <tr key='audit-input-labels-row'>
                    <td className='normal-whitespace-cell'></td>
                    <td></td>
                    <td></td>
                    <td>Months</td>
                    <td># of Months</td>
                    <td>TM</td>
                    <td></td>
                    <td></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                </tr>
                <tr key='audit-inputs-header-row'>
                    <td className='normal-whitespace-cell'></td>
                    <td></td>
                    <td></td>
                    <td className='audit-change-input-cell'>
                        <textarea key='audit-wp-text-month-hide' className='hide-section' value={`${months[props.selectedMonth]}xlsxVal`} readOnly></textarea>
                        <select value={props.selectedMonth} onChange={(e) => props.setSelectedMonth(e.target.value)}>
                            {months.map((m, i) => {
                                return (<option key={`${m}`} value={i + 1}>{m}</option>)
                            })}
                        </select>
                    </td>
                    <td className='audit-change-input-cell'>
                        <textarea key='audit-wp-num-month-hide' className='hide-section' value={`${props.selectedMonth}xlsxVal`} readOnly></textarea>
                        <select value={props.selectedMonth} onChange={(e) => props.setSelectedMonth(e.target.value)}>
                            {months.map((m, i) => {
                                return (<option key={`${m}-number`} value={i + 1}>{i + 1}</option>)
                            })}
                        </select>
                    </td>
                    <td className='audit-change-input-cell'>
                        <textarea key='audit-wp-dollar-hide' className='hide-section' value={`${props.dollarChange.text}xlsxVal`} readOnly></textarea>
                        <input className='audit-dollar-input' type='text' value={props.dollarChange.text} onChange={(e) => props.dollarChangeInput(e.target.value)} />
                    </td>
                    <td className='audit-change-input-cell'>
                        <textarea key='audit-wp-percent-hide' className='hide-section' value={`${props.percentChange.text}xlsxVal`} readOnly></textarea>
                        <input className='audit-percent-input' type='text' value={props.percentChange.text} onChange={(e) => props.percentChangeInput(e.target.value)} />
                    </td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='normal-whitespace-cell'></td>
                </tr>
                <tr key='audit-fields-header-row'>
                    <td className='normal-whitespace-cell'>Preliminary Risk Assessment</td>
                    <td></td>
                    <td className='normal-whitespace-cell' style={{ 'backgroundColor': '#BFE0AE', 'maxWidth': '120px', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap' }}>{props.compareWith}</td>
                    <td className='common-size-cell'>Unadjusted CY</td>
                    <td className='common-size-cell'>Annualized CY</td>
                    <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>$ Change</td>
                    <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE' }}>% Change</td>
                    <td className='normal-whitespace-cell'>Meet Analytic Scope</td>
                    <td className='normal-whitespace-cell'>Explanation of Variances</td>
                    <td className='normal-whitespace-cell'>Quantitatively Significant</td>
                    <td className='normal-whitespace-cell'>Qualitatively Significant</td>
                    <td className='normal-whitespace-cell'>Scoped-in for Testing?</td>
                    <td className='normal-whitespace-cell'>Risk Assessment Form</td>
                    <td className='normal-whitespace-cell'>Comment</td>
                </tr>
                <tr key='audit-years-header-row'>
                    <td style={borderBottomStyle}></td>
                    <td style={borderBottomStyle}></td>
                    {formatedYearsRow}
                    <td style={borderBottomStyle}></td>
                    <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE', "borderBottom": "2px solid black" }}></td>
                    <td className='money-table-cell' style={{ 'backgroundColor': '#BFE0AE', "borderBottom": "2px solid black" }}></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                    <td style={borderBottomStyle} className='normal-whitespace-cell'></td>
                </tr>
            </thead>
        )
    }

    function buildTable() {
        const auditDetailClass = {
            'fontWeight': 600,
            'textAlign': 'right'
        }
        return (
            <div className='money-report-card money-report-pdf'>
                <h1 className='report-table-title hide-not-on-print'>{props.title} - {props.clientName}</h1>
                <button className="btn btn-primary audit-details-btn" type="button" onClick={() => setShowDetails(!showDetails)}>
                    {showDetails ? 'Hide Details' : 'Show Details'}
                </button>
                <div className={showDetails ? 'show-section' : 'hide-section'}>
                    <table id='audit-wp-details-table' className='audit-details-table'>
                        <tbody>
                            <tr key='wp-client-name' className='hide-section'>
                                <td>Client:</td>
                                <td>{props.clientName}</td>
                            </tr>
                            <tr key='wp-scenario-name' className='hide-section'>
                                <td>Scenario:</td>
                                <td>{props.auditScenario['name']}</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Purpose:</td>
                                <td>1) To obtain an understanding of the business and its environment to help assess the risk of material misstatement in order to determine the nature, timing and extent of audit procedures.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>2) To determine accounts to scope-in for testing.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Procedures:</td>
                                <td>1) Obtain unadjusted trial balance and compare to prior year final trial balance (grouped by financial statement line item).</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>2) Determine preliminary risk assessment.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>3) Explain all variances that meet the analytic scoping threshold.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>4) If FS line item is not quantitatively significant, determine if qualitatively significant.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>5) Determine which risk assessment form the FS line item is covered by.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Analytic Scope:</td>
                                <td>Variances greater than tolerable misstatement ("TM") and 10%.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Testing Scope:</td>
                                <td>Accounts balances greater than tolerable misstatement (i.e., performance materiality) or are qualitatively significant (e.g., assessed as High Risk, etc.).</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Conclusion:</td>
                                <td>See below for results. Additionally, accounts scoped-in will be re-assessed for testing based on final trial balance using the Final Analytics worksheet.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <AuditScenarios
                    key='audit-wp-analytics-scenario'
                    allAuditScenarios={props.allAuditScenarios}
                    auditScenario={auditWPScenario}
                    defaultScenario={props.defaultScenario}
                    handleNewScenario={props.handleNewScenario}
                    handleAuditScenarioSelect={props.handleAuditScenarioSelect}
                    handleUpdateScenario={props.handleUpdateScenario}
                    reportType={'wp-analytics'}
                />
                <div className='audit-report-container'>
                    <table id='audit-wp-main-table' className='report-table' >
                        {renderYearHeaderColumns(props.yearRange)}
                        <tbody>
                            {buildRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (buildTable())
}

export default AuditWPAnalytics;
