import React, { useState } from 'react';
import OpsTable from './OpsTable'
import EventCounter from './EventCounter'

const OpsContainer = (props) => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const todayFormatted = `${year}-${month}-${day}`;
  const [data, setData] = useState({
    visits: props.stats.visits.visits,
    total: props.stats.visits.total,
    page: 1,
    rows: 100,
  })
  const [dateFilter, setDateFilter] = useState({
    start_date: todayFormatted,
    end_date: todayFormatted,
    time_period: '',
    firm_name: ''
  })

  const handleFilters = (type, value) => {
    switch (type) {
      case 'firm_name':
        setDateFilter(prevState => {
          return {
            ...prevState,
            firm_name: value
          }
        })
        break;
      case 'time_period':
        setDateFilter(prevState => {
          return {
            ...prevState,
            time_period: value
          }
        })
        break;
      case 'start_date':
        setDateFilter(prevState => {
          return {
            ...prevState,
            time_period: 'custom',
            start_date: value
          }
        })
        break;
      case 'end_date':
        setDateFilter(prevState => {
          return {
            ...prevState,
            time_period: 'custom',
            end_date: value
          }
        })
        break;
      case 'next_page':
        setData((prevState) => {
          return {
            ...prevState,
            page: value
          }
        })
        getVisitsData(null, value)
        break;
      case 'previous_page':
        setData((prevState) => {
          return {
            ...prevState,
            page: value
          }
        })
        getVisitsData(null, value)
        break;
      default:
        break;
    }
  }

  const getVisitsData = async (e = null, page = null) => {
    if (e) { e.preventDefault() }

    let pageVal = page ? page : data.page

    try {
      const res = await fetch(`/admin/ops/visits/filter/?time_period=${dateFilter.time_period}&page=${pageVal}&rows=${data.rows}&firm_name=${dateFilter.firm_name}&start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })
      const resJson = await res.json()
      setData((prevState) => {
        return {
          ...prevState,
          visits: resJson.visits.visits,
          total: resJson.visits.total
        }
      })

    } catch (error) {
      console.log(error)
      alert(error)
    }
  }

  const pageArrows = () => {
    let tableRange, endRange;
    let rowTotal = data.total
    let numberOfRowsPerPage = data.rows < 100 ? data.rows : 100
    let pageCounter = data.page

    if (pageCounter === 1) {
      tableRange = numberOfRowsPerPage < rowTotal ? `${pageCounter} - ${numberOfRowsPerPage}` : `${pageCounter} - ${rowTotal}`
    } else if (rowTotal > (pageCounter * numberOfRowsPerPage)) {
      tableRange = `${(numberOfRowsPerPage * (pageCounter - 1)) + 1} - ${pageCounter * numberOfRowsPerPage}`
    } else {
      endRange = rowTotal
      tableRange = `${(numberOfRowsPerPage * (pageCounter - 1)) + 1} - ${endRange}`
    }

    if (rowTotal > 0) {
      return (
        <div className='page-arrows'>
          {pageCounter > 1 && rowTotal > 100 ? <div onClick={() => handleFilters('previous_page', data.page - 1)}><i className='fa fa-chevron-left' style={{ cursor: 'pointer' }}></i></div> : <div></div>}
          <div>{tableRange}</div>
          <div>of {rowTotal}</div>
          {rowTotal > 100 && rowTotal !== endRange ? <div onClick={() => handleFilters('next_page', data.page + 1)}><i className='fa fa-chevron-right' style={{ cursor: 'pointer' }}></i></div> : <div></div>}
        </div>
      )
    } else return null
  }

  return (
    <div>
      <div>
        <form id='opsview-form' onSubmit={(e) => getVisitsData(e)}>
          <div>
            <label htmlFor='time-period'>Time Period</label>
            <select name='time-period' value={dateFilter.time_period} onChange={(e) => handleFilters('time_period', e.target.value)}>
              <option value=''></option>
              <option value='custom'>Custom</option>
              <option value='today'>Today</option>
              <option value='this_week'>This Week</option>
              <option value='last_week'>Last Week</option>
              <option value='this_month'>This Month</option>
              <option value='last_month'>Last Month</option>
            </select>
          </div>
          <div>
            <label>Date Range:</label>
            <input name='start-date' type={'date'} value={dateFilter.start_date} onChange={(e) => handleFilters('start_date', e.target.value)}></input>
            <span> to </span>
            <input name='end-date' type={'date'} value={dateFilter.end_date} onChange={(e) => handleFilters('end_date', e.target.value)}></input>
          </div>
          <div>
            <label htmlFor='firm-name'>Search By Firm: </label>
            <input name='firm-name' type={'text'} value={dateFilter.firm_name} onChange={(e) => handleFilters('firm_name', e.target.value)} />
          </div>
          <button className='btn btn-primary' type='submit'>Filter</button>
        </form>
      </div>
      <div className='visits-container'>
        {data.visits.length > 0
          ?
          <>
            <h2>User Visits</h2>
            <div className='page-arrows-top-pr-container'>{pageArrows()}</div>
            <OpsTable
              visitsData={true}
              data={data.visits}
            />
            <div className='page-arrows-bottom-pr-container'>{pageArrows()}</div>
          </>
          : <h2>No Matching Data</h2>
        }
      </div>
      <div className='event-counter-container'>
        <EventCounter {...props.stats.event_count} />
      </div>
      <div className='emailed-surveys-container'>
        <h2>Emailed Surveys</h2>
        <OpsTable
          visitsData={false}
          data={props.stats.survey_emails_sent}
        />
      </div>
    </div>
  )
};

export default OpsContainer