import React from 'react';
import { generateId } from './index';

const Tooltip = (props) => {
  const id = generateId();
  const position = () => {
    const config = {
      top: {
        bottom: '35px'
      },
      bottom: {
        top: '25px',
      },
      right: {
        left: '20px',
      },
      left: {
        right: '20px',
      }
    }
    return config[props.position];
  }
  const parentCss = {
    position: 'relative',
  }
  const mousEnter = (e) => {
    document.getElementById(`tooltip-${id}`).style.opacity = '0.9';
    document.getElementById(`tooltip-${id}`).style.overflow = 'hidden';
    document.getElementById(`tooltip-${id}`).style.textOverflow = 'ellipsis';
  }
  const mouseLeave = (e) => {
    document.getElementById(`tooltip-${id}`).style.opacity = '0';
  }
  return (
    <span style={{position: props.postionStyle}} onMouseEnter={mousEnter} onMouseLeave={mouseLeave}>
      {props.children}
      <div className={props.className ? props.className : 'tooltip-child'} id={`tooltip-${id}`} style={{...position(), ...props.style}}>
        {props.description}
      </div>
    </span>
  )
}
export default Tooltip;