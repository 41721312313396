import React from 'react';
import { parseMoney } from '../../utils';
import { cardonator } from '../HOCs'
import { customersKPIsFormat, compensationFTE, peersAnalysisPerCustomerVals } from './KPIsHelper';

const PeopleCustomersKPIs = ({ props, clientFirstYearMonth, clientSecondYearMonth, yearRange, monthsObj, year, youMetrics, compareMetrics, youPreviousYearMetricsYTD, comparePreviousYearMetricsYTD }) => {
  const padding = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
  let backgroundColor = '';
  let cellClass = '';
  let commonSizeValCellClass = 'common-size-cell';
  let tableClasses = '';
  let avgCalcs = {}
  if (props.currentNewFilters.compare_with === 'Peers') {
    avgCalcs = props.calcs.avg
  } else if (props.currentNewFilters.compare_with === 'NSCHBC') {
    avgCalcs = props.calcs.nschbc
  } else {
    avgCalcs = props.previousYearCalcs.you
  }

  if (props.page === 'people') {
    backgroundColor = '#8ADFFE';
    cellClass = 'people-table-cell';
    commonSizeValCellClass += ' people-table-cell';
    tableClasses = 'people-report-table';
  } else {
    backgroundColor = '#CDA5DE';
    cellClass = 'customers-table-cell';
    commonSizeValCellClass += ' customers-table-cell';
    tableClasses = 'customers-report-table';
  }

  const costOfTurnover = (whoseCalcs, y) => {
    const lostProductivity = .2;
    const lostProductivityTime = .125;
    const lostWagesTime = .125;
    const employeeProductivityDecline = .5;
    const employeeProductivityDeclineTime = .185;
    // Cost of Turnover Formula
    // (Your Turnover Rate(year) − Peer Turnover Rate(year)) 
    // * (Number of Employees(year) 
    // * ((Revenue Per Employee(year) * Lost Productivity * Lost Productivity Time) 
    //   + (Average Wages and Salaries(year) * Lost Wages Time ) 
    //   + ((.25 * Training(year))/Number of Employees(year))
    //   + (Revenue Per Employee(year) * Employee Productivity Decline * Employee Productivity Decline Time)))

    if (avgCalcs.hasOwnProperty('turnover_rate') && whoseCalcs && whoseCalcs.turnover_rate[y] !== 0 && whoseCalcs.total_number_of_employees[y] !== 0 && whoseCalcs.revenue_per_employee[y] !== 0 && whoseCalcs.average_wages_and_salaries[y] !== 0) {
      return ((whoseCalcs.turnover_rate[y] - avgCalcs.turnover_rate[y])
        * (whoseCalcs.total_number_of_employees[y]
          * ((whoseCalcs.revenue_per_employee[y] * lostProductivity * lostProductivityTime)
            + (whoseCalcs.average_wages_and_salaries[y] * lostWagesTime)
            + ((whoseCalcs.training[y] * .25) / whoseCalcs.total_number_of_employees[y])
            + (whoseCalcs.revenue_per_employee[y] * employeeProductivityDecline * employeeProductivityDeclineTime))));
    } else return undefined
  }

  let peopleKpis = props.reportMetrics['kpi']
  let customersKpis = props.reportMetrics['kpi']

  const buildRowsArray = (reportPeriod, displayColumnsBy, compareFilter) => {
    const rows = []
    let arrayOfMetrics = props.page === 'people' ? peopleKpis : customersKpis;
    let compareWith = avgCalcs
    let specificIndustryMetrics = ['number_of_hygienists', 'number_of_assistants', 'number_of_office_employees', 'number_of_dentists', 'number_of_nurses', 'number_of_doctors'];
    let analysisPerClientMetrics = ['revenue_per_customer', 'gross_profit', 'gross_profit_per_customer', 'operating_expenses_net_income', 'operating_expenses_net_income_per_customer'];

    if (displayColumnsBy === 'Years') {
      if (reportPeriod !== 'Year to Date') {
        arrayOfMetrics.forEach(set => {
          if (set['display'] || set['yearsView']) {
            let helperObj = {
              key: set.key,
              title: set.title || set.copy,
              format: set.format,
              checked: set.checked,
              padding: set.padding,
              header: set.header
            }

            yearRange.forEach(y => {
              if (specificIndustryMetrics.includes(set.key)) {
                helperObj['industryValue' + y] = props.calcs['you'][set.key] ? props.calcs['you'][set.key][y] : null;
                helperObj['industryValueCompare' + y] = compareWith && compareWith[set.key] ? compareWith[set.key][y] : null;
                helperObj[y] = props.calcs['you'][set.key] ? compensationFTE(props.naicsCode, props.calcs['you'], set.key, y) : null;
                helperObj[y + 'compare'] = compareWith && compareWith[set.key] ? compensationFTE(props.naicsCode, compareWith, set.key, y) : null;
              } else if (analysisPerClientMetrics.includes(set.key)) {
                helperObj[y] = props.calcs['you'][set.key] ? props.calcs['you'][set.key][y] : null;
                helperObj[y + 'compare'] = compareWith && compareWith[set.key] ? peersAnalysisPerCustomerVals(set.key, y, compareWith) : null;
              } else if (set.key !== 'title' && set.key !== 'cost_of_turnover' && set.key !== 'number_of_employees_who_left') {
                helperObj[y] = props.calcs['you'][set.key] ? props.calcs['you'][set.key][y] : null;
                helperObj[y + 'compare'] = compareWith && compareWith[set.key] ? compareWith[set.key][y] : null;
              } else if (set.key === 'cost_of_turnover') {
                helperObj[y] = costOfTurnover(props.calcs['you'], y)
                helperObj[y + 'compare'] = costOfTurnover(compareWith, y)
              } else if (set.key === 'number_of_employees_who_left') {
                helperObj[y] = props.calcs['you']['turnover_rate'][y] * props.calcs['you']['total_number_of_employees'][y]
                helperObj[y + 'compare'] = compareWith && compareWith['turnover_rate'] ? compareWith['turnover_rate'][y] * compareWith['total_number_of_employees'][y] : null;
              } else {
                helperObj[y] = null
              }
            })

            rows.push(helperObj)
          }
        })
      } else {
        // YTD section
        let lastMonthNumber = 0;
        let useableMonths = Object.values(props.calcs[year]['you']['roa']);
        useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);

        arrayOfMetrics.forEach(set => {
          if (set['display'] || set['ytdView']) {
            let helperObj = {
              key: set.key,
              title: set.title || set.copy,
              format: set.format,
              checked: set.checked,
              padding: set.padding,
              header: set.header
            }

            const ytdAr = [year - 1, year]

            ytdAr.forEach(y => {
              if (set.header) {
                helperObj[y] = ''
                helperObj[y + 'compare'] = ''
              } else {
                if (y === year) {
                  helperObj[y] = youMetrics.hasOwnProperty([set.key]) ? youMetrics[set.key][lastMonthNumber] : null
                  if (compareMetrics[set.key] !== undefined) {
                    helperObj[y + 'compare'] = compareMetrics.hasOwnProperty([set.key]) ? compareMetrics[set.key][lastMonthNumber] : null
                  } else {
                    helperObj[y + 'compare'] = null
                  }
                } else {
                  helperObj[y] = youPreviousYearMetricsYTD.hasOwnProperty([set.key]) ? youPreviousYearMetricsYTD[set.key][lastMonthNumber] : null
                  if (compareMetrics[set.key] !== undefined) {
                    helperObj[y + 'compare'] = comparePreviousYearMetricsYTD.hasOwnProperty([set.key]) ? comparePreviousYearMetricsYTD[set.key][lastMonthNumber] : null
                  } else {
                    helperObj[y + 'compare'] = null
                  }
                }
              }
            })
            rows.push(helperObj)
          }
        })
      }

    } else {
      // monthly array
      arrayOfMetrics.forEach(set => {
        if (set['display'] || set['monthlyView']) {
          let helperObj = {
            key: set.key,
            title: set.title || set.copy,
            format: set.format,
            checked: set.checked,
            padding: set.padding,
            header: set.header
          }

          Object.values(monthsObj).forEach((m, i) => {
            if (set.header) {
              helperObj['youYTD'] = ''
              helperObj['compare'] = ''
            } else {
              let monthNumber = i + 1
              helperObj[monthNumber] = youMetrics.hasOwnProperty([set.key]) ? youMetrics[set.key][monthNumber] : null

              let lastMonthNumber = 0;
              let useableMonths = Object.values(props.calcs['you']['roa']);
              useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);

              helperObj['youYTD'] = youMetrics.hasOwnProperty([set.key]) ? youMetrics[set.key][lastMonthNumber] : null
              helperObj['compare'] = compareMetrics.hasOwnProperty([set.key]) ? compareMetrics[set.key][lastMonthNumber] : null
            }
          })
          rows.push(helperObj)
        }
      })
    }
    return rows
  }


  const buildBodyYears = (reportPeriod) => {
    let rows = buildRowsArray(reportPeriod, props.currentNewFilters.display_columns_by, props.currentNewFilters.compare_with)

    if (reportPeriod !== 'Year to Date') {
      return rows.map(row => {
        let rowChecked = row.checked && props.reportMetrics['editMetrics'] ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
        let finalCopy = ''
        if (props.page === 'people') {
          finalCopy = row['padding'] + row['title']
        } else {
          finalCopy = row['format'] !== 'header' ? padding + row['title'] : row['title']
        }

        return (
          <tr key={Math.random(24)} onClick={() => props.reportMetrics['editMetrics'] ? props.handleSelectAndLayout(props.page, 'kpi', row.key) : null}>
            {!props.pdf ? <td>{rowChecked}</td> : null}
            <td className='people-cus-kpi-table-copy'>{finalCopy}</td>
            {yearRange.map((y, i) => {
              let backgroundStyle = {}
              let tableCellClass = '';
              if (i % 2 === 0) {
                backgroundStyle['backgroundColor'] = backgroundColor;
                tableCellClass = cellClass;
              }
              let industryValue = row['industryValue' + y] ? row['industryValue' + y] : '';
              let industryValueCompare = row['industryValueCompare' + y] ? Math.round(row['industryValueCompare' + y]) : '';
              let value;
              let valueCompare;

              switch (row.format) {
                case 'currency':
                case 'money':
                  value = parseMoney(row[y])
                  valueCompare = parseMoney(row[y + 'compare'])
                  break;
                case 'percent':
                  value = row[y] ? row[y].toFixed(2) + '%' : '-'
                  valueCompare = row[y + 'compare'] ? row[y + 'compare'].toFixed(2) + '%' : '-'
                  break;
                case 'header':
                  value = ''
                  valueCompare = ''
                  break;
                default:
                  value = row[y] ? row[y].toFixed(0) : '-';
                  valueCompare = row[y + 'compare'] ? row[y + 'compare'].toFixed(0) : '-';
                  break;
              }

              if (row.header) {
                value = ''
                valueCompare = ''
              }

              return (
                <React.Fragment key={Math.random(80)}>
                  <td className={tableCellClass} style={backgroundStyle}>{industryValue}</td>
                  <td className={tableCellClass} style={backgroundStyle}>{value}</td>
                  <td className={tableCellClass} style={backgroundStyle}></td>
                  <td className={tableCellClass} style={backgroundStyle}>{industryValueCompare}</td>
                  <td className={tableCellClass} style={backgroundStyle}>{valueCompare}</td>
                  <td></td>
                </React.Fragment>
              )
            })}
          </tr>
        )
      })
    } else {
      // Year to Date display section
      return rows.map(row => {
        let rowChecked = row.checked && props.reportMetrics['editMetrics'] ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
        let finalCopy = ''
        if (props.page === 'people') {
          finalCopy = row['padding'] + row['title']
        } else {
          finalCopy = row['format'] !== 'header' ? padding + row['title'] : row['title']
        }

        return (
          <tr key={Math.random(24)} onClick={() => props.reportMetrics['editMetrics'] ? props.handleSelectAndLayout(props.page, 'kpi', row.key) : null}>
            {!props.pdf ? <td>{rowChecked}</td> : null}
            <td className='people-cus-kpi-table-copy'>{finalCopy}</td>
            {[year - 1, year].map((y, i) => {
              let backgroundStyle = {}
              let valueCellClass = '';
              if (i % 2 === 0) {
                backgroundStyle['backgroundColor'] = backgroundColor
                valueCellClass = cellClass;
              }
              let value;
              let valueCompare;

              switch (row.format) {
                case 'money':
                  value = parseMoney(row[y])
                  valueCompare = parseMoney(row[y + 'compare'])
                  break;
                case 'percent':
                  value = row[y] ? (row[y] * 100).toFixed(2) + '%' : '-'
                  valueCompare = row[y + 'compare'] ? (row[y + 'compare'] * 100).toFixed(2) + '%' : '-'
                  break;
                case 'header':
                  value = ''
                  valueCompare = ''
                  break;
                default:
                  value = row[y] ? row[y] : '-';
                  valueCompare = row[y + 'compare'] ? row[y + 'compare'] : '-';
                  break;
              }

              if (row.header) {
                value = ''
                valueCompare = ''
              }

              return (
                <React.Fragment key={Math.random(80)}>
                  {i === 1 ? <td></td> : null}
                  <td className={valueCellClass} style={backgroundStyle}>{value}</td>
                  <td className={valueCellClass} style={backgroundStyle}></td>
                  <td className={valueCellClass} style={backgroundStyle}>{valueCompare}</td>
                </React.Fragment>
              )
            })}
          </tr>
        )
      })
    }
  }

  const buildBodyMonths = (reportPeriod) => {
    let rows = buildRowsArray(reportPeriod, props.currentNewFilters.display_columns_by, props.currentNewFilters.compare_with)

    return rows.map(row => {
      let rowChecked = row.checked && props.reportMetrics['editMetrics'] ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
      let youYTD;
      let valueCompare;
      let finalCopy = ''
      if (props.page === 'people') {
        finalCopy = row['padding'] + row['title']
      } else {
        finalCopy = row['format'] !== 'header' ? padding + row['title'] : row['title']
      }

      switch (row.format) {
        case 'money':
          youYTD = parseMoney(row['youYTD'])
          valueCompare = parseMoney(row['compare'])
          break;
        case 'percent':
          youYTD = row['youYTD'] ? (row['youYTD'] * 100).toFixed(2) + '%' : '-'
          valueCompare = row['compare'] ? (row['compare'] * 100).toFixed(2) + '%' : '-'
          break;
        case 'header':
          youYTD = ''
          valueCompare = ''
          break;
        default:
          youYTD = row['youYTD'] ? row['youYTD'] : '-';
          valueCompare = row['compare'] ? row['compare'] : '-';
          break;
      }

      return (
        <tr key={Math.random(24)} onClick={() => props.reportMetrics['editMetrics'] ? props.handleSelectAndLayout(props.page, 'kpi', row.key) : null}>
          {!props.pdf ? <td>{rowChecked}</td> : null}
          <td className='people-cus-kpi-table-copy'>{finalCopy}</td>
          {Object.values(monthsObj).map((month, i) => {
            let value;
            switch (row.format) {
              case 'money':
                value = parseMoney(row[i + 1])
                break;
              case 'percent':
                value = row[i + 1] ? (row[i + 1] * 100).toFixed(2) + '%' : '-'
                break;
              case 'header':
                value = ''
                break;
            }
            if (row.header) { value = '' }
            return <td key={Math.random(80)}>{value}</td>
          })}
          <td>{youYTD}</td>
          {
            props.currentNewFilters.compare_with === 'None'
              ? null
              : <React.Fragment key={Math.random(80)}>
                <td></td>
                <td>
                  {valueCompare}
                </td>
              </React.Fragment>
          }
          <td></td>
        </tr>
      )
    })
  }

  const renderYearHeaderColumns = (displayColumnsBy, reportPeriod) => {
    const currentYear = new Date().getFullYear();
    let monthsHeaders;
    let formatedYearsRow = [];
    let youPeersRow = [];

    if (displayColumnsBy === 'Years') {
      if (reportPeriod !== 'Year to Date') {
        yearRange.forEach((y, i) => {
          let arrowCellClass = 'common-size-arrow ';
          let backgroundStyle = {}
          let commonSizeValCellClass = 'common-size-cell ';
          let youDate, compareDate = '';

          if (y === currentYear) {
            youDate = props.companyYTDDate;
            compareDate = props.aggregateYTDDate;
          } else {
            youDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${y}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${y + 1}` : `12/31/${y}`;
            compareDate = `12/31/${y}`;
          }

          if (i % 2 === 0) {
            arrowCellClass += cellClass;
            backgroundStyle['backgroundColor'] = backgroundColor
            commonSizeValCellClass += cellClass;
          }

          youPeersRow.push(
            <React.Fragment key={Math.random(i) + 'tcpc-year-headers'}>
              <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>You</td>
              <td className={arrowCellClass} style={backgroundStyle}></td>
              <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>Peers</td>
              <td className='space-cell'></td>
            </React.Fragment>
          )

          formatedYearsRow.push(
            <React.Fragment key={Math.random(i) + 'tcpc-year-headers'}>
              <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
              <td className={arrowCellClass} style={backgroundStyle}></td>
              <td colSpan='2' className={commonSizeValCellClass} style={backgroundStyle}>{compareDate}</td>
              <td className='space-cell'></td>
            </React.Fragment>
          )
        })

        return (
          <thead>
            <tr key={Math.random(20)}>
              {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.reportMetrics['editMetrics'] ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
              <td></td>
              {youPeersRow}
            </tr>
            <tr key={Math.random(20)}>
              {!props.pdf ? <td></td> : null}
              <td></td>
              {formatedYearsRow}
              {yearRange.length === 2 ? <td></td> : null}
            </tr>
          </thead>
        )
      } else {
        // YTD Headers
        return (
          <thead>
            <tr>
              {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.reportMetrics['editMetrics'] ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
              <td></td>
              <td colSpan='3' className={cellClass} style={{ 'backgroundColor': backgroundColor }}>{`YTD ${clientFirstYearMonth}/${year - 1}`}</td>
              <td></td>
              <td colSpan='3'>{`YTD ${clientSecondYearMonth}/${year}`}</td>
            </tr>
            <tr>
              {!props.pdf ? <td></td> : null}
              <td></td>
              <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}>You</td>
              <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}></td>
              <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}>Peers</td>
              <td></td>
              <td className='common-size-cell'>You</td>
              <td className='common-size-cell'></td>
              <td className='common-size-cell'>Peers</td>
              <td></td>
            </tr>
          </thead>
        )
      }
    } else {
      // Months Headers
      monthsHeaders = Object.values(monthsObj).map(month => {
        return <td key={Math.random(80) + 'tcpc-months-headers'}>{month}</td>
      })
      monthsHeaders.push(
        <React.Fragment key={Math.random(20) + 'tcpc-months-headers'}>
          <td>You YTD</td>
          {
            props.currentNewFilters.compare_with === 'None'
              ? null
              : (
                <React.Fragment key={Math.random(80)}>
                  <td></td>
                  <td>{props.currentNewFilters.compare_with} {props.currentNewFilters.compare_with === 'YOY' ? year - 1 : null}</td>
                </React.Fragment>
              )
          }
        </React.Fragment>
      )

      return (
        <thead>
          <tr key={Math.random(20)}>
            {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.reportMetrics['editMetrics'] ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
            <td></td>
            {monthsHeaders}
          </tr>
        </thead>
      )
    }
  }

  const buildTable = (displayColumnsBy, reportPeriod) => {
    if (props.pdf) {
      return (
        <div className='landscape-view'>
          <div className='report-pdf-landscape'>
            <div>
              <h1 className='report-table-title'>Key Performance Indicators - {props.clientName}</h1>
              <div className='money-pdf-table-div'>
                <table style={{ whiteSpace: 'pre' }} className='money-pdf-table' >
                  {renderYearHeaderColumns(displayColumnsBy, reportPeriod)}
                  <tbody>
                    {displayColumnsBy === 'Years' ? buildBodyYears(reportPeriod) : buildBodyMonths(reportPeriod)}
                  </tbody>
                  <tfoot>
                    <tr><td><i>{props.n ? `n = ${props.n}` : null}</i></td></tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='people-customers-pdf'>
          <h1 className='report-table-title hide-not-on-print'>Key Performance Indicators - {props.clientName}</h1>
          <div className='money-report-container'>
            <table style={{ whiteSpace: 'pre' }} className={tableClasses} >
              {renderYearHeaderColumns(displayColumnsBy, reportPeriod)}
              <tbody>
                {displayColumnsBy === 'Years' ? buildBodyYears(reportPeriod) : buildBodyMonths(reportPeriod)}
              </tbody>
              <tfoot>
                <tr><td><i>{props.n ? `n = ${props.n}` : null}</i></td></tr>
              </tfoot>
            </table>
          </div>
        </div>
      )
    }

  }

  return (buildTable(props.currentNewFilters.display_columns_by, props.currentNewFilters.report_period))
}

export default cardonator(PeopleCustomersKPIs);