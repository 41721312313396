import React from 'react';
import NavFormIndustryDropdown from '../NavFormIndustryDropdown';

const MCDropdownFilter = ({ filterOptions, handleFilterSubmit, selectedFilters, setSelectedFilters }) => {
  const updateFilters = ({ target }) => {
    let updated = target.value;
    switch (target.name) {
      case 'naics_code':
        setSelectedFilters(prevState => {
          return { ...prevState, naics_code: updated }
        })
        break;
      case 'revenue_band':
        setSelectedFilters(prevState => {
          return { ...prevState, revenue_band: updated }
        })
        break;
      case 'region':
        setSelectedFilters(prevState => {
          return { ...prevState, region: updated }
        })
        break;
      case 'partners':
        setSelectedFilters(prevState => {
          return { ...prevState, partners: updated }
        })
        break;
      case 'office':
        setSelectedFilters(prevState => {
          return { ...prevState, office: updated }
        })
        break;
      case 'status':
        setSelectedFilters(prevState => {
          return { ...prevState, status: updated }
        })
        break;
    }
  }

  const buildFilter = ({ list, selected, key, label, styleName, optionCallback }) => {
    if (list) return (
      <div className={styleName} id={key + '-filter'}>
        <label htmlFor={key} className='text-left'>{label}</label>
        <select name={key} value={selected} onChange={updateFilters} className={`${styleName}`}>
          {list.map((filter, i) => {
            if (optionCallback) return optionCallback(filter, i);
            return (
              <option key={i} value={filter} className='option-dropdown-filter'>
                {filter}
              </option>
            );
          })}
        </select>
      </div>
    )
  }

  return (
    <form id='dropdown-filter-id' onSubmit={(e) => { handleFilterSubmit(e) }}>
      <div className='money-dropdown-filters' >
        <NavFormIndustryDropdown selectedCode={selectedFilters.naics_code} codeOptions={filterOptions.naics_code} setSelectedFilters={setSelectedFilters} page='manageClients' />
        {buildFilter({
          list: filterOptions.partners,
          selected: selectedFilters.partners,
          key: 'partners',
          label: 'Partner',
          styleName: 'partner-filter filter-item-side-bar',
        })}
        {buildFilter({
          list: filterOptions.office,
          selected: selectedFilters.office,
          key: 'office',
          label: 'Office',
          styleName: 'office-filter filter-item-side-bar',
        })}
        {buildFilter({
          list: filterOptions.region,
          selected: selectedFilters.region,
          key: 'region',
          label: 'Region',
          styleName: 'region-filter filter-item-side-bar',
        })}
        {buildFilter({
          list: filterOptions.revenue_band,
          selected: selectedFilters.revenue_band,
          key: 'revenue_band',
          label: 'Revenue',
          styleName: 'revenue-band-filter filter-item-side-bar',
        })}
        {buildFilter({
          list: filterOptions.status,
          selected: selectedFilters.status,
          key: 'status',
          label: 'Status',
          styleName: 'status-filter filter-item-side-bar',
        })}
        <button className='run-report-btn' type='submit'>Apply Filters</button>
      </div>
    </form>
  )
}

export default MCDropdownFilter;
