import React from 'react';

const cardonator = (Component, money=false) => {
  const ComponentCard = (props) => {
    let reportCard = money ? 'money-report-card' : 'report-card'
    return(
      <div className={`${reportCard} ${props.columnClass || ''}`}>
        <Component {...props} />
      </div>
    )
  }
  return ComponentCard
}

export default cardonator;
