import React, { useState, useRef, useMemo } from "react";
import { Responsive, WidthProvider } from 'react-grid-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faArrowUpRightFromSquare, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import moment from 'moment';
import LoaderGraphic from '../utils/LoaderGraphic';
import DashboardNotes from "./DashboardNotes";
import UserDashboardPreferences from "./UserDashboardPreferences";
import RssFeed from "./RssFeed";

const ResponsiveGridLayout = WidthProvider(Responsive);

// const dashItemLimitType = {
//     recentViewedLinks: 1,
//     jumpToLinks: 1,
//     peerviewUsersUpdates: 1,
//     clientActivity: 1,
//     teamActivity: 1,
//     rssFeedPeerviewDataBlog: 1
// }

const headerStyle = { backgroundColor: "#69b144", color: "#ffffff", margin: 0, fontSize: "20px", minHeight: "30px", padding: "3px", textAlign: "center" }

const UserDashboard = (props) => {
    const [userDashboard, setUserDashboard] = useState({
        id: props.dashboard_views.current_view["id"],
        selected_items: props.dashboard_views.current_view["selected_items"],
        dashboard_layout: props.dashboard_views.current_view["dashboard_layout"],
        noChangesMade: true,
        updatedAt: props.dashboard_views.current_view["updated_at"]
    });

    const [dashboardItemDropdown, setDashboardItemDropdown] = useState({
        itemId: '',
        display: false
    })

    const [dashboardItemState, setDashboardItemState] = useState({
        data: {}
    })

    const [dashboardConfirmModalState, setDashboardConfirmModalState] = useState({
        isOpen: false,
        action: 'close',
        text: '',
        itemId: null
    })

    const [dashItemEditorState, setDashItemEditorState] = useState({
        type: '',
        loading: false,
        details: {},
        action: '',
        error: ''
    })

    const [rssFeedData, setRssFeedData] = useState(props.rss_feed_data || {})
    const [preferences, setPreferences] = useState(props.all_selections);
    const [podcastRss, setPodactRss] = useState(props.podcast_rss_feed_data || {})

    let currentViewUpdatedAtTime = userDashboard.updatedAt ? `Last updated: ${moment(userDashboard.updatedAt).calendar()}` : '';

    const hrefBuilder = (subdomain = "", path) => {
        const domainSplit = document.location.host.split('.');
        domainSplit[0] = subdomain ? subdomain : domainSplit[0];
        return location.protocol + '//' + domainSplit.join('.') + path;
    };

    const handleOnLayoutChange = (newLayout) => {
        setUserDashboard((prevState) => {
            return { ...prevState, dashboard_layout: newLayout }
        })
    }

    const getRssUrlData = async (naics_ids) => {
        try {
            const res = await fetch(`/rss_url/show/?naics_id=${naics_ids}`, {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                }
            })
            const resJson = await res.json()
            setRssFeedData((prevState) => {
                return {
                    ...prevState,
                    ...resJson
                }
            })

            setUserDashboard((prevState) => {
                let newItems = prevState.selected_items.map(item => {
                    item.loading = false
                    return item
                })

                return {
                    ...prevState,
                    selected_items: newItems
                }
            })

        } catch (error) {
            console.log(error)
            setUserDashboard((prevState) => {
                let newItems = prevState.selected_items.map(item => {
                    if (item.id === dashItemEditorState.id) {
                        item.loading = false
                    }
                    return item
                })

                return {
                    ...prevState,
                    items: newItems
                }
            })
        }
    }

    const updateDashboardView = async (e = false) => {
        if (e) { e.preventDefault() }
        let updatedView = { report_type: 'user_dashboard' }
        let allPreferences = _.cloneDeep(preferences)
        let newSelectedItems = []
        let newDashLayout = []
        let getNAICSIdsForRssFeeds = []
        let oldSelectedItems = _.cloneDeep(userDashboard.selected_items)
        let oldDashLayout = _.cloneDeep(userDashboard.dashboard_layout)
        let sortByY = oldDashLayout.sort((a, b) => b.y - a.y)
        let bottomMostItem = sortByY[0]
        let xPos = bottomMostItem.x
        let yPos = bottomMostItem.y

        allPreferences.forEach(preference => { 
            if (preference.checked) {
                if (preference.type === 'rssFeed') {
                    let checkIfIndustryRssFeedExists = rssFeedData.hasOwnProperty(Number(preference.details.naics_id))
                    if (!checkIfIndustryRssFeedExists) {
                        getNAICSIdsForRssFeeds.push(Number(preference.details.naics_id))
                    }
                    newSelectedItems.push(preference)
                } else if (preference.type === 'myNotes') {
                    let newNotes = oldSelectedItems.find(o => o.type === 'myNotes')
                    newNotes ? newSelectedItems.push(newNotes) : newSelectedItems.push(preference)
                } else {
                    newSelectedItems.push(preference)
                }
            }
        })

        newSelectedItems.forEach((item, i) => {
           let oldItem = oldDashLayout.find(d => d['i'] === item.key);
           if (oldItem) {
               newDashLayout.push(oldItem)
           } else {
                if (item.type === 'myNotes') {
                    yPos = xPos >= 4 ? yPos + 1 : yPos
                    newDashLayout.push({
                        i: item.key,
                        isBounded: false,
                        isDraggable: true,
                        isResizable: true,
                        x: xPos,
                        y: yPos + 1,
                        w: 2,
                        h: 1,
                        minW: 1,
                        minH: 1,
                        maxW: 6,
                        maxH: 12,
                        resizeHandles: ['se']
                    })
                    // Update X position after adding new item
                    xPos = xPos >= 4 ? 0 : xPos + 2
                } else {
                    yPos = xPos >= 4 ? yPos + 2 : yPos
                    newDashLayout.push({
                        i: item.key,
                        isBounded: false,
                        isDraggable: true,
                        isResizable: true,
                        x: xPos,
                        y: yPos + 2,
                        w: 2,
                        h: 2,
                        minW: 1,
                        minH: 1,
                        maxW: 6,
                        maxH: 12,
                        resizeHandles: ['se']
                    })
                    // Update X position after adding new item
                    xPos = xPos >= 4 ? 0 : xPos + 2
                }
           }
       })
        
        updatedView['selected_items'] = newSelectedItems
        updatedView['dashboard_layout'] = newDashLayout

        try {
            const res = await fetch(`/dashboard_view/${userDashboard.id}`, {
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedView),
            })

            const dashViewRes = await res.json()

            if (!res.ok) {
                throw new Error('Network response was not ok.');
            } else {
                setUserDashboard((prevState) => {
                    let newItemsWithLoading = newSelectedItems.map(n => {
                        if (n.type === 'rssFeed') {
                            let checkIfIndustryRssFeedExists = rssFeedData.hasOwnProperty(Number(n.details.naics_id))
                            if (!checkIfIndustryRssFeedExists) {
                                n.loading = true
                            }
                            return n
                        } else {
                            return n
                        }
                    })

                    return {
                        ...prevState,
                        selected_items: newItemsWithLoading,
                        dashboard_layout: newDashLayout,
                        updatedAt: dashViewRes['current_view']['updated_at'],
                        noChangesMade: true
                    }
                })

                if (getNAICSIdsForRssFeeds.length > 0) {
                    getRssUrlData(getNAICSIdsForRssFeeds)
                }
                // setDashboardViewModalState((prevState) => {
                //     return {
                //         ...prevState,
                //         current_view: dashViewRes.current_view
                //     }
                // })
                toast.success('Changes saved!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.log(error);
            toast.error('Sorry something went wrong. Changes to view could not be saved.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const notificationTextBuilder = (data) => {
        let textTemplate = data.details ? data.details.text_template : null
        if (textTemplate) {
            let userFullName = data.user_full_name || data.details.original_data.user_full_name
            let companyName = data.company_name || data.details.original_data.company_name
            Object.keys(data.details.original_data).forEach(key => {
                let replaceText = ''
                if (key === 'user_full_name') {
                    replaceText = userFullName
                } else if (key === 'company_name') {
                    replaceText = companyName
                } else {
                    replaceText = data.details.original_data[key]
                }

                textTemplate = textTemplate.replace(key, replaceText)
            })
        } else {
            textTemplate = data.body
        }

        return textTemplate
    }

    const ClientActivity = () => {

        const buildClientActivity = () => {
            if (props.client_activity && props.client_activity.length > 0) {
                return (
                    <div className='client-activity-container'>
                        {props.client_activity.map((ca, i) => {
                            let link = hrefBuilder(ca.subdomain, ca.path)
                            let notificationText = notificationTextBuilder(ca)
                            return (
                                <div key={`client-activity-${i}`} className='client-activity-item'>
                                    <div className='client-activity-item-header'>
                                        <h4>{`Client ID: ${ca.company_name}`}</h4>
                                        <span>{moment(ca.created_at).calendar()}</span>
                                    </div>
                                    <p>{notificationText} <a href={link} target='_blank'>{ca.link_text} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></p>
                                </div>
                            )
                        })}
                    </div>
                )
            } else {
                return (
                    <div className='client-activity-container'>
                        <p>Notifications about your Clients' Activity will appear here.</p>
                    </div>
                )
            }
        }


        return (
            <>
                <h3 className='user-dash-item-header' style={headerStyle}>Your Client Activity</h3>
                <h3 className='hidden-space' style={headerStyle}>Your Client Activity</h3>
                {buildClientActivity()}
            </>
        )
    }

    const TeamActivity = () => {

        const buildTeamActivity = () => {
            if (props.team_activity && props.team_activity.length > 0) {
                return (
                    <div className='team-user-activity-item'>
                        {props.team_activity.map((ta, i) => {
                            let userFullName = ta.user_full_name || ta.details.original_data.user_full_name
                            let link = hrefBuilder(ta.subdomain, ta.path)
                            let notificationText = notificationTextBuilder(ta)
                            let finalLink = ta.subdomain && ta.path ? <a href={link} target='_blank'>{ta.link_text} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> : null
                            return (
                                <div key={`team-activity-${i}`} className='client-activity-item'>
                                    <div className='client-activity-item-header'>
                                        <h4><FontAwesomeIcon icon={faCircleUser} /> {userFullName} - {`Client ID: ${ta.company_name}`}</h4>
                                        <span>{moment(ta.created_at).calendar()}</span>
                                    </div>
                                    <p>{notificationText} {finalLink}</p>
                                </div>
                            )
                        })}
                    </div>
                )
            } else {
                return (
                    <div className='client-activity-container'>
                        <p>Notifications about your Team's Activity will appear here.</p>
                    </div>
                )
            }
        }


        return (
            <>
                <h3 className='user-dash-item-header' style={headerStyle}>Your Team Activity</h3>
                <h3 className='hidden-space' style={headerStyle}>Your Team Activity</h3>
                {buildTeamActivity()}
            </>
        )
    }

    const editNotesDetails = (value) => {
        setUserDashboard((prevState) => {
            let oldItems = _.cloneDeep(prevState['selected_items'])
            let newItems = oldItems.map(o => {
                if (o.type === 'myNotes') {
                    o["details"]["content"] = value
                    return o
                } else {
                    return o
                }
            })

            return {
                ...prevState,
                selected_items: newItems
            }
        })
    }

    let layoutItems = useMemo(() => {
        return userDashboard.selected_items.map((i) => {
            if (i.type === 'rssFeed') {
                let articles = rssFeedData[Number(i.details.naics_id)]

                return (
                    <div key={i.id}>
                        <div className='all-content'>
                            <RssFeed key={`rss-${i.details.naics_id}`} headerStyle={headerStyle} preview={false} articles={articles} rssState={i} loading={i.loading} />
                        </div>
                    </div>
                )
            } else if (i.type === 'rssFeedPeerviewDataBlog') {
                let peerviewBlogs = rssFeedData[981]
                return (
                    <div key={i.id}>
                        <div className='all-content'>
                            <RssFeed key='rssFeedPeerviewDataBlog' headerStyle={headerStyle} preview={false} articles={peerviewBlogs} rssState={i} loading={i.loading} type='rssFeedPeerviewDataBlog' />
                        </div>
                    </div>
                )
            } else if (i.type === "peerviewUsersUpdates") {
                let finalDescription = podcastRss['description'].slice(0, 450) + '...'
                let peerviewUserImgPath = "https://www.peerviewdata.com/hs-fs/hubfs/Best%20Metrics%20Episode%20Graphic%20(3).png?width=400&height=400&name=Best%20Metrics%20Episode%20Graphic%20(3).png"
                let podcastDescription = <div className='podcast-description'>{finalDescription}</div>
                let htmlRegEx = new RegExp(`<("[^"]*"|'[^']*'|[^'">])*>`);
                let containsHTML = htmlRegEx.test(finalDescription)
                let containsUniCode = finalDescription.match(/\&(.*?)\;/g)
                if (containsHTML || containsUniCode) {
                    podcastDescription = <div className='podcast-description' dangerouslySetInnerHTML={{ __html: finalDescription }} />
                }

                return (
                    <div key={i.id}>
                        <div className='peerview-user-updates-container'>
                            <img className='best-metrics-podcast-pic' src={peerviewUserImgPath} draggable="false"></img>
                            <div>
                                <h3 className='podcast-title'>{podcastRss['title']}</h3>
                                {podcastDescription}
                                <a className='peerview-users-link-btn btn-primary' href={podcastRss['link']} target='_blank'>Listen Now!!!</a>
                            </div>
                        </div>
                    </div>
                )
            } else if (i.type === 'clientActivity') {
                return (
                    <div key={i.id}>
                        <div className='all-content'>
                            <ClientActivity details={i.details} />
                        </div>
                    </div>
                )
            } else if (i.type === 'teamActivity') {
                return (
                    <div key={i.id}>
                        <div className='all-content'>
                            <TeamActivity details={i.details} />
                        </div>
                    </div>
                )
            } else if (i.type === 'myNotes') {
                return (
                    <div className="reports-dashboard-chart" key={i.id}>
                        <DashboardNotes key={i.id} headerStyle={headerStyle} noteState={i} editNotesDetails={editNotesDetails} />
                    </div>
                )
            } else if (i.type === 'recentlyViewedLinks') {
                const buildRecentlyViewedLinks = () => {
                    if (props.recently_viewed_links && props.recently_viewed_links.length > 0) {
                        return (
                            <ul>
                                {props.recently_viewed_links.map((l, i) => {
                                    return <li key={`recent-link-${i}`}><a href={l.link} target='_blank'>{l.page} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></li>
                                })}
                            </ul>
                        )
                    } else {
                        return (<p>Your Recently Viewed Links will appear here.</p>)
                    }
                }

                return (
                    <div className="reports-dashboard-chart" key={i.id}>
                        <div className='all-content'>
                            <h3 className='user-dash-item-header' style={headerStyle}>{i['label']}</h3>
                            <h3 className='hidden-space' style={headerStyle}>{i['label']}</h3>
                            <div className='recently-view-links'>
                                {buildRecentlyViewedLinks()}
                            </div>
                        </div>
                    </div>
                )
            } else if (i.type === 'jumpToLinks') {
                let linkPaths = [
                    {
                        path: '/group_reports/practice',
                        page: 'Practice Report'
                    },
                    {
                        path: '/reports/industry',
                        page: 'Industry Reports'
                    },
                    {
                        path: '/clients',
                        page: 'Admin Portal'
                    },
                    {
                        path: '/companies',
                        page: 'Manage Clients'
                    },
                    {
                        path: '/manage_firm',
                        page: 'Manage Firm'
                    },
                    {
                        path: '/subscriptions',
                        page: 'Manage Subscription'
                    },
                    {
                        url: 'https://www.peerviewdata.com/resources-page',
                        page: 'Resources'
                    }
                ]

                if (props.user.type === 'Client') {
                    linkPaths.splice(4, 2)
                }

                return (
                    <div className="reports-dashboard-chart" key={i.id}>
                        <div className='all-content'>
                            <h3 className='user-dash-item-header' style={headerStyle}>{i['label']}</h3>
                            <h3 className='hidden-space' style={headerStyle}>{i['label']}</h3>
                            <div className='recently-view-links'>
                                <ul>
                                    {linkPaths.map((l, i) => {
                                        let link = l.path ? hrefBuilder(null, l.path) : l.url
                                        return <li key={`jumptolink-${i}`}><a href={link}>{l.page} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    })

    const handleSelectAll = (select) => {
        setPreferences((prevState) => {
            let newPreferences = _.cloneDeep(prevState);
            newPreferences = newPreferences.map(p => {
                if (p.displaySelection) {
                    p.checked = select
                }
                return p
            })
            return newPreferences;
        })
    }

    const handleDashboardSelections = (childItem) => {
        let itemsCheckedTrue = [];
        let newLayout = [];

        setPreferences((prevState) => {
            let newPreferences = _.cloneDeep(prevState);
            let selectedIndex = newPreferences.findIndex(p => p.key == childItem.key)
            newPreferences[selectedIndex]['checked'] = !newPreferences[selectedIndex]['checked']
            return newPreferences;
        })
    }

    const bannerMessage = () => {
        let userFirstName = `${props.user.first_name}`
        let date = new Date();
        let hour = date.getHours();
        let message = '';

        if (hour < 12) {
            message = `Good Morning, ${userFirstName}!`;
        } else if (hour >= 12 && hour <= 17) {
            message = `Good Afternoon, ${userFirstName}!`;
        } else if (hour >= 17 && hour <= 24) {
            message = `Good Evening, ${userFirstName}!`;
        }

        return message;
    }

    return (
        <div>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme='colored'
            />
            <Modal
                className={{
                    base: 'dashboard-view-modal-content hide-on-print',
                    afterOpen: 'dashboard-view-modal-content_after-open hide-on-print',
                    beforeClose: 'dashboard-view-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'dashboard-view-overlay-base hide-on-print',
                    afterOpen: 'dashboard-view-overlay-base_after-open hide-on-print',
                    beforeClose: 'dashboard-view-overlay-base_before-close hide-on-print',
                }}
                ariaHideApp={false}
                closeTimeoutMS={0}
                contentLabel='Add/Edit Dashboard Item Modal'
                isOpen={dashboardConfirmModalState.isOpen}
                onRequestClose={() => handleConfirmModal('close')}
                shouldCloseOnOverlayClick={true}
            >
                <div>
                    <div>
                        <h2>{dashboardConfirmModalState.text}</h2>
                    </div>
                    <button onClick={() => handleDeleteDashboardItem(dashboardConfirmModalState.action, dashboardConfirmModalState.itemId)}>Yes</button>
                    <button onClick={() => handleConfirmModal('close')}>Cancel</button>
                </div>
            </Modal>
            <div className='user-dashboard-banner'>
                <h1>{bannerMessage()}</h1>
                <div>
                    <button className='dash-item-edit-btn' onClick={() => updateDashboardView()}>Save Changes</button>
                    <span>{currentViewUpdatedAtTime}</span>
                    <UserDashboardPreferences
                        handleSelectAll={handleSelectAll}
                        handleDashboardSelections={handleDashboardSelections}
                        updateDashboardView={updateDashboardView}
                        preferences={preferences}
                    />
                </div>
            </div>
            <ResponsiveGridLayout
                className='user-dashboard-layout'
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                layouts={{ lg: userDashboard.dashboard_layout }}
                cols={{ lg: 6, md: 6, sm: 3, xs: 3, xxs: 3 }}
                rowHeight={210}
                isDraggable={true}
                isDroppable={true}
                isResizable={true}
                onLayoutChange={(layout) => {
                    handleOnLayoutChange(layout)
                }}
            >
                {layoutItems}
            </ResponsiveGridLayout>
        </div>
    )
}

export default UserDashboard;
