import React from 'react';
import { parseMoney } from '../../../utils';
export default class ForecastInputOutput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
      input: true,
    }
    this.growthRate = false;
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentWillReceiveProps(props) {
    this.growthRate = props.output != null
    this.setState({value: this.growthRate ? props.output : props.value})
  }

  render() {
    const output = this.props.buc ? `${parseMoney(this.props.output || this.state.value)}` : `${this.props.output || this.state.value}%`;
    return(
      <form className={ this.props.inputsDisplay === 'normalInputs' ? 'input-output-form' : 'display-none' } onSubmit={this.handleSubmit} >
        {this.props.buc ? '\u00A0 $' : ''}
        <input
          className={'slider-input slider-right'}
          type='number'
          step={this.props.buc ? '1' : '0.1'}
          value={this.state.value}
          onChange={({ target }) => this.setState({ value: target.value })}
        />
        <span>{this.props.buc ? '' : ' %'}</span>
      </form>
    )
  }

  handleClick(event) {
    const comp = this
    $('.growth-btn').click(function() {
      this.props.onSubmit(comp.state.value);
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state.value);
  }
}

