import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const NavFormIndustryDropdown = ({ selectedCode, codeOptions, setSelectedFilters, page = '' }) => {
  const [allCodeOptions, setAllCodeOptions] = useState(codeOptions)
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setAllCodeOptions(codeOptions)
  }, [codeOptions]) 

  const handleOptionsExpand = (index) => {
    setAllCodeOptions(() => {
      let newCodeOptions = allCodeOptions.map((c, i) => {
        if (i === index) {
          c[2] = c[2] ? false : true
        } else {
          c[2] = false
        }
        return c
      })
      return newCodeOptions
    })
  }

  const handleMouseEnter = () => {
    setVisible(true);
  };

  // Function to hide the menu (after clicking an option)
  const handleMouseLeave = () => {
    setVisible(false);
  };

  const setClicked = (updated) => {
    setVisible((prevVisible) => {
      return false;
    });

    switch (page) {
      case 'practice':
        setSelectedFilters(prevState => {
          return { ...prevState, naics_string: updated }
        })
        break;
      case 'manageClients':
        setSelectedFilters(prevState => {
          return { ...prevState, naics_code: updated }
        })
        break;
      default:
        setSelectedFilters(prevState => {
          return { ...prevState, code: updated }
        })
        break;
    }
  }

  const findSimilarNaics = (code) => {
    return allCodeOptions.map((filter, i) => {
      if (filter[0].slice(0, 2) === code.slice(0, 2)) {
        return (
          <li key={i + 'naics_string'} value={filter[0]} style={{ 'width': '100%' }} onClick={() => { setClicked(filter[0]) }}>
            {filter[0] === selectedCode ? <div className='fa fa-check'> </div> : null}
            <div>{filter[0]}{filter[1].slice(0, 3).includes('-') || filter[0] === 'All' ? null : ' - '}{filter[1]}</div>
          </li>
        )
      }
    })
  }

  const makeNaicsFilterOptions = () => {
    const twoDigitOptions = []
    let subULs;
    if (allCodeOptions) {
      subULs = allCodeOptions.map((filter, i) => {
        if (filter[0].length === 2 || filter[0] === 'All' || filter[0].includes('-') || filter[0] === 'vets' || filter[0] === 'saas' || filter[0] === 'SaaS' || filter[0] === '999999') {
          twoDigitOptions.push(filter[0])
          return (
            <li key={Math.random(89)} onClick={() => handleOptionsExpand(i)}>
              <div>{filter[0]}{filter[1].slice(0, 3).includes('-') || filter[0] === 'All' ? null : ' - '}{filter[1].length > 40 ? filter[1].slice(0, 40).concat('...') : filter[1]} <FontAwesomeIcon icon={faChevronDown} /></div>
              {filter[2]
                ? <ul>
                  {findSimilarNaics(filter[0])}
                </ul>
                : null
              }
            </li>
          )
        }
        else if (filter[0] === selectedCode && !twoDigitOptions.includes(selectedCode.slice(0, 2))) {
          return (
            <li key={Math.random(89)}>
              <div>{filter[0]}{filter[1].slice(0, 3).includes('-') || filter[0] === 'All' ? null : ' - '}{filter[1].length > 40 ? filter[1].slice(0, 40).concat('...') : filter[1]} <FontAwesomeIcon icon={faChevronDown} /></div>
              {filter[2]
                ? <ul>
                  {findSimilarNaics(filter[0])}
                </ul>
                : null
              }
            </li>
          )
        }
      })
    }

    return (
      <div
        className={`form-disguise-dropdown ${visible ? 'visible' : ''}`}
      >
        <ul
        >
          {subULs}
        </ul>
      </div>
    )
  }

  const findDescription = () => {
    let description = ''
    if (allCodeOptions) {
      allCodeOptions.forEach(filter => {
        return filter[0] == selectedCode ? description = filter[1].slice(0, 3).includes('-') || filter[0] === 'All' ? filter[1] : ' - ' + filter[1] : null
      })
    }
    return description
  }

  return (
    <div className='naics-code-filter form-disguise-flex-wrapper'>
      <label style={{ color: 'black', fontSize: '13px' }}>Industry</label>
      <div
        className='form-disguise-div'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {makeNaicsFilterOptions()}
        <div className='form-disguise-selected'>
          {selectedCode}
          {findDescription()}
          <div className='fa fa-caret-down'></div>
        </div>
      </div>
    </div>
  )
}

export default NavFormIndustryDropdown