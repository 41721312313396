import React, { useContext } from 'react'
import { Data } from '../contexts'
import AccountTree from '../../utils/AccountTree'
import TableChartIndustry from './TableChartIndustry';
import PeopleCustomersTable from './PeopleCustomersTable';
import { monthlyCommonSized, commonSizeArrowsHelper, reduceYTD } from './PeopleCustomersHelpers'

const BigTableChartsForSheets = (props) => {
  if (props.page === 'people' || props.page === 'customers') {
    const { calcs, previousYearCalcs } = useContext(Data)
    dataCalcs = calcs
    dataPreviousYearCalcs = previousYearCalcs
    currentFilters = props.currentNewFilters
  } else {
    const { calcs } = useContext(Data)
    dataCalcs = calcs
  }

  const padding = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
  let dataCalcs;
  let dataPreviousYearCalcs;
  let currentFilters;

  const chartSpecs = {
    people: {
      charts: {
        pnl: {
          title: 'Financial Detail',
          commonKey: 'revenue'
        }
      }
    },
    customers: {
      charts: {
        pnl: {
          title: 'Financial Detail',
          commonKey: 'revenue',
          max: 10
        }
      }
    },
    money: {
      charts: {
        pnl: {
          title: 'Profit & Loss',
          commonKey: 'revenue'
        },
        balance_sheet: {
          title: 'Balance Sheet',
          commonKey: 'assets'
        }
      }
    }
  }

  const buildTables = () => {
    const { commonKey } = chartSpecs[props.page]
    if (props.report === 'industry') {
      const chartProps = {
        title: props.title,
        commonKey: props.commonKey
      }
      let sheet = props.sheet
      const rows = buildRowData(sheet, chartProps.commonKey)

      if (props.PDFPrintModalOpen && rows.length > 34) {
        let first30 = rows.slice(0, 34)
        let second30 = rows.slice(34)
        return (
          <>
            <TableChartIndustry key={Math.random(80)} {...{ ...chartProps, ...props, rows: first30, sheet }} />
            <TableChartIndustry key={Math.random(80)} {...{ ...chartProps, ...props, rows: second30, sheet }} />
          </>
        )
      } else {
        return (<TableChartIndustry key={Math.random(80)} {...{ ...chartProps, ...props, rows, sheet }} />)
      }
    } else {
      return Object.keys(chartSpecs[props.page].charts).map((chart, i) => {
        const chartProps = chartSpecs[props.page].charts[chart]
        const rows = buildRowData(chart, chartProps.commonKey)

        if (props.page === 'people' || props.page === 'customers') {
          return <PeopleCustomersTable {...{ ...chartProps, ...props, rows, chart }} key={Math.random(80) + 'table-chart-people-customers'} />
        } else {
          return (
            <div className='big-table-charts' key={Math.random(80)}>
              <TableChartIndustry {...{ ...chartProps, ...props, rows, chart }} />
            </div>
          )
        }
      })
    }
  }

  const buildRowData = (chart, commonKey) => {
    const rows = []
    // Customers Report
    if (props.page === 'customers' && props.report !== 'industry') {
      props.reportMetrics['financial_detail'].forEach(row => {
        rows.push(buildRowPeopleCustomers({ ...row }))
      })
      return rows
    }

    // Industry Report w/Customers Tab
    if (props.page === 'customers' && props.report === 'industry') {
      // Customers Beginning Rows
      const extraCustomersRows = [
        {
          copy: 'Revenue',
          header: false,
          key: 'total_revenue',
          padding: padding
        },
        {
          copy: 'Gross Profit',
          header: false,
          key: 'gross_profit',
          padding: padding
        },
        {
          copy: 'Operating Profit',
          header: false,
          key: 'operating_profit',
          padding: padding
        },
        {
          copy: 'Net Income',
          header: false,
          key: 'operating_expenses_net_income',
          padding: padding
        },
        {
          copy: padding,
          header: true,
          key: '',
          padding: padding
        }
      ]

      extraCustomersRows.forEach(row => {
        rows.push(buildRow({ ...row, commonKey }))
      })
    }

    // People Report
    if (props.page === 'people' && props.report !== 'industry') {
      props.reportMetrics['financial_detail'].forEach(row => {
        rows.push(buildRowPeopleCustomers({ ...row }))
      })
      return rows
    }

    const tree = new AccountTree({
      tree: props.tree[chart],
      page: props.page
    })

    tree.forEach((row) => {
      // Industry Report w/People or Customers Tab
      if (props.report === 'industry') {
        rows.push(buildRow({ ...row, commonKey }))
      }
    })

    return rows.filter(Boolean)
  };

  const buildRow = ({ key, copy, header, header_is_total, padding, commonKey }) => {
    const row = { key: key }
    row.copy = padding + copy
    if (props.page === 'customers') {
      row.copy = copy
    }

    if (!header || header_is_total) {
      // table data
      props.yearRange.forEach(year => {
        if (props.commonSizeTable) {
          if (key === 'operating_expenses_net_income' && commonKey === 'assets') {
            row[year + 'commonSize'] = dataCalcs.avg[key] ? (dataCalcs.avg[key][year] / dataCalcs.avg["total_assets"][year]) * 100 : undefined
          } else {
            row[year + 'commonSize'] = dataCalcs.avg[key + '_common_size_' + commonKey] ? dataCalcs.avg[key + '_common_size_' + commonKey][year] * 100 : undefined
          }
          row[year] = dataCalcs.avg[key] ? dataCalcs.avg[key][year] : undefined
        } else {
          row[year] = dataCalcs.you[key] ? dataCalcs.you[key][year] : undefined
        }
      });

      // chart data
      ['you', 'min', 'max', 'avg'].forEach(tableKey => {
        try {
          row[tableKey] = dataCalcs[tableKey][key + '_common_size_' + commonKey][props.year] * 100
        } catch {
          row[tableKey] = null
        }
      })
    }
    if (header || dataExists(row)) return row
  }

  const buildRowPeopleCustomers = ({ checked = false, key, copy, header, header_is_total, padding }) => {
    const row = {
      header: header,
      emptyRow: header && !header_is_total,
      checked: checked,
      key: key,
      copy: padding + copy
    }

    if (props.page === 'customers') {
      row.copy = copy
    }

    let compareWith;
    if (props.currentNewFilters.compare_with === 'Peers') {
      compareWith = dataCalcs.avg
    } else if (props.currentNewFilters.compare_with === 'NSCHBC') {
      compareWith = dataCalcs.nschbc
    } else {
      compareWith = dataPreviousYearCalcs.you
    }

    if (!header || header_is_total) {
      // table data
      if (currentFilters.display_columns_by === 'Years') {

        if (currentFilters.report_period !== 'Year to Date') {
          props.yearRange.forEach(year => {
            let youCompareVal = dataCalcs.you.hasOwnProperty([key + '_common_size_revenue']) ? dataCalcs.you[key + '_common_size_revenue'][year] * 100 : 0
            row[year] = dataCalcs.you.hasOwnProperty(key) ? dataCalcs.you[key][year] : null
            row[year + 'youCommonSize'] = youCompareVal
            let compare = compareWith ? compareWith[key + '_common_size_revenue'] : null;
            if (compare) {
              row[year + 'arrow'] = commonSizeArrowsHelper(dataCalcs.you, youCompareVal, compareWith[key + '_common_size_revenue'][year] * 100, compareWith, key, currentFilters.display_columns_by)
              row[year + 'compareCommonSize'] = compareWith[key + '_common_size_revenue'][year] * 100
            } else {
              row[year + 'arrow'] = ''
              row[year + 'compareCommonSize'] = 0
            }
          });

        } else {
          // YTD section
          [props.year - 1, props.year].forEach(year => {
            row[year] = reduceYTD(dataCalcs, year, 'you', key)
            row[year + 'youCommonSize'] = reduceYTD(dataCalcs, year, 'you', key, true)
            row['arrow'] = reduceYTD(dataCalcs, year, 'you', key, true) && reduceYTD(dataCalcs, year, 'avg', key, true) ?
              commonSizeArrowsHelper(dataCalcs.you, reduceYTD(dataCalcs, year, 'you', key, true), reduceYTD(dataCalcs, year, 'avg', key, true), compareWith, key, currentFilters.display_columns_by) : ''
            row[year + 'compareCommonSize'] = reduceYTD(dataCalcs, year, 'avg', key, true)
          })
        }
      } else {
        Object.values(props.monthsObj).forEach((month, i) => {
          row[i + 1] = dataCalcs.you[key] ? dataCalcs.you[key][i + 1] : 0.0
          let youVals = dataCalcs.you[key] || []
          row['youCommonSize'] = monthlyCommonSized(youVals, dataCalcs.you)
          row['arrow'] = commonSizeArrowsHelper(dataCalcs.you, youVals, compareWith[key], compareWith, key, currentFilters.display_columns_by)
          row['compareCommonSize'] = monthlyCommonSized(compareWith[key], compareWith)
        });
      }
    } else {
      row['null'] = null
    }
    return row
  }

  const dataExists = (row) => {
    const keys = ['you', 'min', 'max', 'avg'].concat(props.yearRange)
    return keys.some(key => row[key])
  }

  return (buildTables())
}

export default BigTableChartsForSheets