import React from "react";

const ForecastError = (props) => {  
  return(
    <h3 className="insufficient-monthly-data text-center" id={props.page + "-insufficient-data"}>
      It looks like you're missing monthly forecast data for {props.year}.
      <br></br><br></br>
      Please update your {props.year} company data or generate reports in your company mapping.
    </h3>
  )
}

const InsufficientData = (props) => {  
  const page = props.page
  const year = props.year
  return(
    <div>
      <ForecastError page={page} year={year} />
    </div>
  )
}

export default InsufficientData