import React from 'react';
import { read } from 'xlsx';
import Extractor from '../../utils/Extractor';

export default class DropzoneComponentForInsufficientReportAnnual extends React.Component {
  constructor(props) {
    super(props);
    this.dropzone = React.createRef();

    this.state = {
      loading: false,
      error: null,
      extractError: null,
      headerError: null,
      filename: null
    }
  }

  handleFile = async (file) => {
    this.setState(previousState => {
      return {
        ...previousState,
        filename: file.name,
        loading: true
      }
    })

    const { id, headers, error, message } = await this.postFile(file);
    if (error) {
      this.setState(previousState => { return {...previousState, error: message }})
    } else {
      this.fduId = id;
      this.readFile(file, headers);
    }
  }

  handleComplete = (fdu) => {
    this.setState(previousState => { return {...previousState, loading: false }});
    this.props.notify(`${JSON.parse(fdu.document)} uploaded for ${this.props.name}.`);
    this.props.addFdu(fdu);
  }

  handleExtractError = (error) => {
    this.setState(previousState => { return {...previousState, extractError: "File Format Error:"}})
  }

  handleHeaderError = (missingCols) => {
    this.setState(previousState => {
      return {
        ...previousState,
        headerError: "Missing Headers: " + missingCols.join(", ")
      }
    })
  }

  readFile = (file, headers = null) => {
    const { id } = this.props;
    const { handleHeaderError, handleComplete, handleExtractError, fduId } = this;
    const reader = new FileReader();
    reader.onload = function(e) {

      const data = new Uint8Array(e.target.result);
      const workbook = read(data, {type: 'array'});

      const extractor = new Extractor({
        workbook,
        headers,
        fduId,
        clientId: id
      });

      extractor.onComplete = handleComplete;
      extractor.onHeaderError = handleHeaderError;
      extractor.onExtractError = handleExtractError;

      extractor.extract();
    };

    reader.readAsArrayBuffer(file);
  }

  postFile = async (file) => {
    const formData  = new FormData();
    formData.append("attachment[document]", file);
    formData.append("attachment[company_id]", this.props.id);
    const response = await fetch('/api/v1/financial_data_uploads', {
      headers: { 'X-CSRF-Token': window.token },
      method: 'POST',
      body: formData
    })
    const fdu = await response.json();
    return fdu;
  }

  onDragOver = (e) => {
    document.getElementById(`dropzone-${this.props.id}`).style.background = "#e6e6e6";
    e.preventDefault();
  }

  onDragLeave = () => {
    document.getElementById(`dropzone-${this.props.id}`).style.background = "white";
  }

  onDrop = (e) => {
    e.preventDefault();
    document.getElementById(`dropzone-${this.props.id}`).style.background = "white";
    this.handleFile(e.dataTransfer.files[0])
  }

  addFile = (e) => {
    this.handleFile(e.target.files[0])
  }

  render() {
    const { error, extractError, headerError, loading, filename } = this.state;
    if (error) {
      return(
        <div className="dropzone dropzone-error">
          {error}
        </div>
      )
    }

    if (headerError) {
      return(
        <div className="dropzone dropzone-header-error">
          {headerError}
          <div>
            <a href={this.props.hrefBuilder(this.props.subdomain, `/financial_data_uploads/${this.fduId}/edit`)}>Help Us Find Them</a>
          </div>
        </div>
      )
    }

    if (extractError) {
      return(
        <div className="dropzone dropzone-error">
          {extractError}
          <div>
            <div><a href={this.props.hrefBuilder(this.props.subdomain, `/financial_data_uploads/${this.fduId}/edit`)}>Check The File's Headers</a> and Re-Upload.</div>
          </div>
        </div>
      )
    }

    if (loading) {
      return(
        <div className="dropzone dropzone-loading">
          Processing {filename}
        </div>
      )
    }

    if (error === null && extractError === null && headerError === null && this.props.fdu.document !== undefined && loading === false) {
      
      return(
        <div className="dropzone" id={`dropzone-${this.props.id}`}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}>
          <div className="dz-details">
            <label htmlFor={`file-upload-${this.props.id}`}>{this.props.fdu.document} Uploaded! You can map this file now or upload a different file.</label>
            <input type="file" className="file-upload-input" onChange={this.addFile} id={`file-upload-${this.props.id}`} />
          </div>
        </div>
      )
    }
  
    return(
      <div className="dropzone" id={`dropzone-${this.props.id}`}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}>
        <div className="dz-details">Drop .xlsx Files Here to Upload</div>
        <input type="file" onChange={this.addFile} className="file-upload-input" id={`file-upload-${this.props.id}`} />
      </div>
    )
  }
}
