import React from 'react'
import MonthlyForecast from './forecast/MonthlyForecast'
import InsufficientData from "./InsufficientData"

const MonthlyReport = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className='money-report-component'>
        {props.insufficientData 
        ? <InsufficientData page={props.page} year={props.year}/>
        : <MonthlyForecast {...props} />}
    </div>
  )
})

export default MonthlyReport
