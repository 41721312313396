import { COPY } from './chart_helpers';

const fontsArr = ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
const gaugeKeys = {
  money: ['growth', 'profitability', 'liquidity', 'leverage', 'cash_flow'],
  people: ['hr_budget', 'wages_salaries', 'benefits', 'turnover', 'productivity'],
  customers: ['mkt_spend', 'volatility', 'concentration', 'mkt_roi', 'efficiency'],
}

const keysToTitle = (key) => {
  return COPY[key] ? COPY[key].toUpperCase() : key.toUpperCase().split('_').join(' ');
}

const randomColorArrayMaker = (number) => {
  let countDownNumber = 0
  const returnArr = []
  while (countDownNumber < number) {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      const random = Math.random();
      const bit = (random * 16) | 0;
      color += (bit).toString(16);
    };
    returnArr.push(color)
    countDownNumber += 1
  }
  return returnArr
};

let colorArray = randomColorArrayMaker(400);

const getIndustryLineChartOptions = (chart, data) => {
  return (
    {
      chart: {
        type: 'line',
        style: {
          fontFamily: fontsArr
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: chart.title,
        style: {
          fontSize: '18px',
          fontWeight: 'normal'
        }
      },
      subtitle: {
        text: chart.subtitle,
        style: {
          fontSize: '12px'
        }
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: '%',
        shared: true,
        pointFormatter: function () {
          return `<span class="${this.color}">\u25CF</span> ${this.series.name}: <b>${format(chart.format, this.y)}</b><br/>`
        },
        style: {
          stroke: "none",
          fontSize: '11px'
        }
      },
      xAxis: {
        title: {
          text: 'Year',
          style: {
            fontSize: '12px'
          }
        },
        categories: data['categories'],
        startOnTick: true,
        endOnTick: true,
        labels: {
          align: 'right',
          style: {
            fontSize: '11px'
          }
        },
        crosshair: {}
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          enabled: true,
          formatter: function () {
            if (this.value) {
              let val = axisFormat(chart.format, this.value);
              return val;
            } else {
              return 0
            }
          },
          style: {
            fontSize: '11px'
          }
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return format(chart.format, this.y);
            },
            align: 'center',
            crop: false,
            allowOverlap: true,
            style: {
              stroke: "none",
              textOutline: "none",
              fontSize: '11px'
            }
          },
          lineWidth: 3
        }
      },
      series: data['seriesData'],
      legend: {
        itemStyle: {
          stroke: "none",
          textOutline: "none"
        }
      }
    }
  )
};

const getIndustryBarChartOptions = (chart, data) => {
  // Need to give color to series in order to export/print charts with highcharts exporting module
  const barColor = {
    money: '#69B144',
    people: '#00AEEF',
    customers: '#542667'
  }

  return (
    {
      chart: {
        type: 'column',
        style: {
          fontFamily: fontsArr
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: chart.title,
        style: {
          fontSize: '18px',
          fontWeight: 'normal'
        }
      },
      subtitle: {
        text: chart.subtitle,
        style: {
          fontSize: '12px'
        }
      },
      tooltip: {
        enabled: false,
        shared: true,
        valuePrefix: '',
        valueSuffix: '',
        valueDecimals: 0,
      },
      xAxis: {
        categories: data['categories'],
        labels: {
          align: 'center',
          reserveSpace: true,
          x: 0, y: 15
        }
      },
      legend: {
        itemStyle: {
          stroke: "none",
          textOutline: "none",
          fontSize: "9px",
        }
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            align: 'center',
            enabled: true,
            allowOverlap: true,
            crop: false,
            style: {
              stroke: "none",
              textOutline: "none",
            },
            formatter: function () {
              if (this.y) {
                return format(chart.format, this.y);
              } else {
                return ""
              }
            },
          }
        }
      },
      series: data['seriesData'],
    }
  )
}

const getMultiDataBarChartOptions = (chartProps, rankingData) => {
  let data = rankingData[chartProps.metricKey]
  let xAxisCatergories = data.map(({ company_name }) => company_name.toString())
  let finalData = data.map((d) => { return d[chartProps.metricKey] });
  let max200Keys = ['liquidity', 'leverage', 'cash_flow'];
  return {
    chart: {
      zoomType: 'x',
      type: 'column',
      style: {
        fontFamily: fontsArr
      }
    },

    title: {
      text: chartProps.title,
      style: {
        fontSize: '18px',
        fontWeight: 'normal'
      }
    },

    subtitle: {
      text: 'Zoom in and out',
      style: {
        fontSize: '12px'
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        let y = format(chartProps.format, this.y);
        return (this.x + ":" + y);
      },
      style: {
        fontSize: '11px'
      }
    },
    xAxis: {
      categories: xAxisCatergories,
      labels: {
        style: {
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      max: max200Keys.includes(chartProps.metricKey) ? 200 : null,
      title: {
        text: null
      },
      labels: {
        enabled: true,
        formatter: function () {
          if (this.value) {
            let val = axisFormat(chartProps.format, this.value);
            return val;
          } else {
            return 0
          }
        },
        style: {
          fontSize: '11px'
        }
      },
    },
    series: [{
      color: '#69b144',
      data: finalData,
      lineWidth: 0.5,
    }],

  }
}

const get3DBubbleChartOptions = (chartProps, data) => {
  // Need to group by naics code
  let orderCompaniesByNaics = {};
  for (let i = 0; i < data.length; i++) {
    let naics = data[i]['naics'];
    if (orderCompaniesByNaics[naics]) {
      orderCompaniesByNaics[naics].push(data[i])
    } else {
      orderCompaniesByNaics[naics] = [data[i]]
    }
  }
  let finalSeries = [];
  let count = 0;
  for (let naics in orderCompaniesByNaics) {
    let dataAr = orderCompaniesByNaics[naics].map((c, i) => {
      let first = c[chartProps['keys'][0]];
      let second = c[chartProps['keys'][1]];
      return {
        color: colorArray[count],
        name: c.name,
        subdomain: c.subdomain,
        x: first,
        y: second,
        z: c.total_revenue,
      }
    });
    let returnObj = {
      name: naics,
      data: dataAr,
      marker: {
        fillColor: {
          radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
          stops: [
            [0, 'rgba(255,255,255,0.5)'],
            [1, colorArray[count]]
          ]
        }
      }
    }
    count++;
    finalSeries.push(returnObj)
  }

  return (
    {
      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        style: {
          fontFamily: fontsArr
        }
      },
      title: {
        text: chartProps.title,
        style: {
          fontSize: '18px',
          fontWeight: 'normal'
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        allowHTML: true,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true
              }
            }
          }
        }
      },
      legend: {
        borderWidth: 1,
        itemStyle: {
          fontSize: "12px",
        }
      },
      xAxis: {
        gridLineWidth: 1,
        title: {
          text: chartProps.x,
          style: {
            fontSize: '12px'
          }
        },
        labels: {
          formatter: function () {
            let point = format('percent', this.value)
            return point;
          },
          style: {
            fontSize: '11px'
          }
        }
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        title: {
          text: chartProps.y,
          style: {
            fontSize: '12px'
          }
        },
        labels: {
          formatter: function () {
            let point = format('percent', this.value)
            return point;
          },
          style: {
            fontSize: '11px'
          }
        }
      },
      series: finalSeries,
      tooltip: {
        useHTML: true,
        style: {
          padding: 0,
          pointerEvents: 'auto'
        },
        pointFormatter: function () {
          let x = format('percent', this.x);
          let y = format('percent', this.y);
          let z = format('money', this.z);
          let reportLink = hrefBuilder(this.subdomain, "/reports/money")
          let tooltipStr = `<span>Client: <b>${this.name}</b></span><br><span>Growth Rate: <b>${x}</b></span><br><span>Net Profit: <b>${y}</b></span><br><span>Total Revenue: <b>${z}</b></span><br><a href=${reportLink} target='_blank'>Click Here to View Reports</a>`;
          return tooltipStr;
        },
      },

    }
  )
}

const getBubbleChartOptions = (chartProps, data) => {
  // Need to group by naics code
  let orderCompaniesByNaics = {};
  for (let i = 0; i < data.length; i++) {
    let naics = data[i]['naics'];
    if (orderCompaniesByNaics[naics]) {
      orderCompaniesByNaics[naics].push(data[i])
    } else {
      orderCompaniesByNaics[naics] = [data[i]]
    }
  }
  let finalSeries = [];
  let count = 0;
  for (let naics in orderCompaniesByNaics) {
    let dataAr = orderCompaniesByNaics[naics].map((c, i) => {
      let first = c[chartProps['keys'][0]];
      let second = c[chartProps['keys'][1]];
      return {
        x: first,
        y: second,
        z: c.total_revenue,
        name: c.company.name,
        color: colorArray[count]
      }
    })

    let returnObj = {
      name: naics,
      data: dataAr,
    }
    count++;
    finalSeries.push(returnObj)
  }

  return (
    {
      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        style: {
          fontFamily: fontsArr
        }
      },
      colors: colorArray,
      title: {
        text: chartProps.title,
        style: {
          fontSize: '18px',
          fontWeight: 'normal'
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        allowHTML: true,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true
              }
            }
          }
        }
      },
      series: finalSeries,
      xAxis: {
        gridLineWidth: 1,
        title: {
          text: chartProps.x
        },
        labels: {
          formatter: function () {
            let point = format('percent', this.value)
            return point;
          }
        },
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        title: {
          text: chartProps.y
        },
        labels: {
          formatter: function () {
            let point = format('percent', this.value)
            return point;
          }
        },
        maxPadding: 0.2,
      },
      tooltip: {
        useHTML: true,
        pointFormatter: function () {
          let x = format('percent', this.x);
          let y = format('percent', this.y);
          let z = format('money', this.z);
          let tooltipStr = `<span>Client: <b>${this.name}</b></span><br><span>Net Profit: <b>${x}</b></span><br><span>Growth Rate: <b>${y}</b></span><br><span>Total Revenue: <b>${z}</b></span>`;
          return tooltipStr;
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        }
      },
    })
}

const hrefBuilder = (subdomain, path) => {
  const domainSplit = document.location.host.split(".");
  domainSplit[0] = subdomain;
  return location.protocol + "//" + domainSplit.join(".") + path;
};

const getQuadrantOptions = (chartProps, data) => {
  const pdfTitleStyle = chartProps.pdf ? { fontSize: '1em', fontWeight: 'normal' } : { fontSize: '18px', fontWeight: 'normal' }
  const pdfLabelStyle = chartProps.pdf ? { fontSize: '0.8em' } : { fontSize: '11px' }
  const pdfPlotStyle = chartProps.pdf ? { fontSize: '0.8em' } : { fontSize: '11px' }
  // Need to group by naics code
  let finalSeries = [
    { // next four series are for color
      type: 'area',
      fillOpacity: 0.01,
      color: '#fff',
      data: [[-10, 210], [100, 210]],
      enableMouseTracking: false,
      lineWidth: 1,
      marker: {
        enabled: false,
      },
      stacking: 'normal',
      showInLegend: false,
    },
    {
      type: 'area',
      fillOpacity: 0.3,
      color: '#DBDBDB',
      data: [[100, 210], [210, 210]],
      enableMouseTracking: false,
      lineWidth: 1,
      marker: {
        enabled: false,
      },
      stacking: 'normal',
      showInLegend: false,
    },
    {
      type: 'area',
      fillOpacity: 0.01,
      color: '#fff',
      data: [[100, 100], [210, 100]],
      enableMouseTracking: false,
      lineWidth: 1,
      marker: {
        enabled: false,
      },
      stacking: 'normal',
      showInLegend: false,
    },
    {
      type: 'area',
      fillOpacity: 0.3,
      color: '#DBDBDB',
      data: [[-10, 100], [100, 100]],
      enableMouseTracking: false,
      lineWidth: 1,
      marker: {
        enabled: false,
      },
      stacking: 'normal',
      showInLegend: false,
    }];

  if (chartProps.page === 'practice') {
    let orderCompaniesByNaics = {};
    for (let i = 0; i < data.length; i++) {
      let naics = data[i]['naics'];
      if (orderCompaniesByNaics[naics]) {
        orderCompaniesByNaics[naics].push(data[i])
      } else {
        orderCompaniesByNaics[naics] = [data[i]]
      }
    }
    let count = 0;
    for (let naics in orderCompaniesByNaics) {
      let dataAr = orderCompaniesByNaics[naics].map((c, i) => {
        let first = c[chartProps['keys'][0]];
        let second = c[chartProps['keys'][1]];
        return {
          color: colorArray[count],
          name: c.name,
          subdomain: c.subdomain,
          x: first,
          y: second,
          z: c.total_revenue,
        }
      })

      let returnObj = {
        name: naics,
        data: dataAr,
      }
      count++;
      finalSeries.push(returnObj)
    }
  }

  if (chartProps.page === 'money') {
    finalSeries.push(data)
  }

  return (
    {
      chart: {
        type: 'bubble',
        renderTo: 'container',
        colors: colorArray,
        defaultSeriesType: 'bubble',
        zoomType: 'xy',
        marginLeft: 90,
        marginRight: 50,
        backgroundColor: '#fff',
        plotBackgroundColor: '#fff',
        style: {
          fontFamily: fontsArr
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: chartProps.pdf ? false : true,
        allowHTML: true,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true
              }
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      title: {
        text: chartProps.title,
        style: pdfTitleStyle
      },
      tooltip: {
        useHTML: true,
        style: {
          padding: 0,
          pointerEvents: 'auto'
        },
        formatter: function () {
          let x = Math.round(this.x);
          let y = Math.round(this.y);
          let z = format('money', this.point.z);
          let reportLink = this.point.subdomain ? hrefBuilder(this.point.subdomain, "/reports/money") : null;
          let reportHTML = reportLink ? `<br><a href=${reportLink} target='_blank'>Click Here to View Reports</a>` : '';
          let tooltipStr = `<b>${this.series.name}</b><br>${chartProps.x}: <b>${x}</b>, ${chartProps.y}: <b>${y}</b><br>Total Revenue: <b>${z}</b>` + reportHTML;
          return tooltipStr;
        },
      },
      plotOptions: {
        series: {
          shadow: false,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: pdfPlotStyle,
          }
        },
        line: {
          marker: {
            enabled: false
          }
        }
      },
      xAxis: {
        title: {
          text: chartProps.x,
          style: pdfLabelStyle
        },
        labels: {
          formatter: function () {
            if (this.value === 50) {
              return 'Low';
            } else if (this.value === 150) {
              return 'High';
            } else {
              return this.value;
            }
          },
          style: pdfLabelStyle
        },
        min: 0,
        max: 200,
        tickInterval: 50,
        minorTickInterval: 10,
        gridLineWidth: 1,
        showFirstLabel: false,
        showLastLabel: true,
        lineColor: '#fff',
        lineWidth: 1,
        gridZIndex: 0,
      },
      yAxis: {
        title: {
          text: chartProps.y,
          style: pdfLabelStyle
        },
        labels: {
          formatter: function () {
            if (this.value === 50) {
              return 'Low';
            } else if (this.value === 150) {
              return 'High';
            } else {
              return this.value;
            }
          },
          style: pdfLabelStyle
        },
        min: 0,
        max: 200,
        tickInterval: 50,
        minorTickInterval: 10,
        lineColor: '#fff',
        lineWidth: 1,
        gridZIndex: 0,
      },
      series: finalSeries
    })
}

const getScatterPlotOptions = (chartProps, data) => {
  // Need to group by naics code
  let orderCompaniesByNaics = {};
  for (let i = 0; i < data.length; i++) {
    let naics = data[i]['naics'];
    if (orderCompaniesByNaics[naics]) {
      orderCompaniesByNaics[naics].push(data[i])
    } else {
      orderCompaniesByNaics[naics] = [data[i]]
    }
  }

  let finalSeries = [];
  let count = 0;
  for (let naics in orderCompaniesByNaics) {
    let dataObj = orderCompaniesByNaics[naics].map((c, i) => {
      let first = c[chartProps['keys'][0]];
      let second = c[chartProps['keys'][1]];
      let subDomain = c.subdomain;
      return {
        x: first,
        y: second,
        name: c.name,
        subdomain: subDomain
      }
    })
    let returnObj = {
      name: naics,
      data: dataObj,
      color: colorArray[count],
    }
    count++;
    finalSeries.push(returnObj)
  }

  return (
    {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        style: {
          fontFamily: fontsArr
        }
      },
      title: {
        text: chartProps.title,
        style: {
          fontSize: '18px',
          fontWeight: 'normal'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          enabled: true,
          text: chartProps.x,
          style: {
            fontSize: '12px'
          }
        },
        labels: {
          formatter: function () {
            if (this.value >= 0) {
              return '$' + this.axis.defaultLabelFormatter.call(this);
            } else {
              this.value = Math.abs(this.value)
              return '-$' + this.axis.defaultLabelFormatter.call(this);
            }
          },
          style: {
            fontSize: '11px'
          }
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        title: {
          text: chartProps.y,
          style: {
            fontSize: '12px'
          }
        },
        labels: {
          formatter: function () {
            let point = format('percent', this.value)
            return point;
          },
          style: {
            fontSize: '11px'
          }
        },
      },
      legend: {
        borderWidth: 1,
        itemStyle: {
          fontSize: "12px",
        }
      },
      series: finalSeries,
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
        }
      },
      tooltip: {
        useHTML: true,
        style: {
          padding: 0,
          pointerEvents: 'auto'
        },
        pointFormatter: function () {
          let x = format('money', this.x);
          let y = format('percent', this.y);
          let reportLink = hrefBuilder(this.subdomain, "/reports/money")
          let tooltipStr = `Client Name: <b>${this.name}</b><br>${chartProps.x}: <b>${x}</b><br>${chartProps.y}: <b>${y}</b><br><a href=${reportLink} target='_blank'>Click Here to View Reports</a>`;
          return tooltipStr;
        },
      }
    }
  )
}

const getGaugeOptions = (metricKey, data) => {
  let gaugeTitle = keysToTitle(metricKey);
  // prevent blending the stops colors too much
  return (
    {
      chart: {
        type: "solidgauge",
        reflow: true,
        style: {
          fontFamily: fontsArr
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      exporting: {
        enabled: false,
      },
      title: null,
      series: [
        {
          name: gaugeTitle,
          data: [data],
          dataLabels: {
            format:
              '<div style="text-align:center">' +
              '<span style="font-size:18px">{y}</span><br/>' +
              "</div>",
            y: 1,
            style: {
              fontSize: '12px'
            }
          }
        }
      ],
      pane: {
        center: ["50%", "85%"],
        size: "100%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc"
        }
      },
      yAxis: {
        minColor: "#DF5353",
        maxColor: "#55BF3B",
        stops: [
          [0.2, '#DF5353'], // red
          [0.25, '#DDDF0D'], // yellow
          [0.3, '#DDDF0D'],
          [0.4, '#55BF3B'], // green
          [0.45, '#55BF3B'],
          [0.5, '#55BF3B'], // green
          [0.55, '#55BF3B'],
          [0.6, '#55BF3B'], // green
          [0.7, '#DDDF0D'], // yellow
          [0.79, '#DDDF0D'],
          [0.8, '#DF5353'], // red
          [0.9, '#DF5353']
        ],
        lineWidth: 0,
        min: 0,
        max: 200,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          text: gaugeTitle,
          color: "#ffffff",
          y: -60,
          style: {
            fontSize: '12px'
          }
        },
        labels: {
          distance: "80%",
          y: 16,
          style: {
            fontSize: '12px'
          }
        }
      },
      plotOptions: {
        series: {
          animation: {
            duration: 2000
          }
        },
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      }
    }
  )
}

const buildDonutData = (donut) => {
  const series = {
    parents: [],
    children: []
  }
  let data = donut.data;
  let parentTotal = 0;

  let keys = {
    'Money': ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'warranty_expense'],
    'People': ['total_compensation', 'total_taxes_and_benefits', 'total_corporate_culture', 'subcontractor_expenses', 'people_misc_professional_fees'],
    'Customers': ['sales_and_marketing', 'travel_entertainment_and_meals', 'customers_misc_professional_fees'],
    'COGS': ['labor_costs', 'cogs_subcontractors', 'materials_costs', 'overhead', 'transportation', 'other_costs'],
    'Net Income': ['operating_expenses_net_income'],
  }

  Highcharts.setOptions({
    colors: ['#69B144', '#00AEEF', '#542667', '#999999', '#737373', '#FFB74D']
  });
  const colors = Highcharts.getOptions().colors;

  Object.keys(keys).forEach((parentKey, i) => {
    let total = 0;
    const childLen = keys[parentKey].length;

    keys[parentKey].forEach((child, j) => {
      let key, title;
      let value = 0;

      if (typeof child == 'object') {
        const { title, key } = child;
      } else {
        key = child;
        title = COPY[key];
      }

      try {
        value = data[`${key}_common_size_${donut.commonSize}`][donut.year];
      } catch (err) {
        if (err.name !== 'TypeError') {
          console.log(err, 'err')
          throw (err)
        }
      }

      total += value;
      let brightness = 0.05 + (j / childLen) / 4;
      let childColor = Highcharts.Color(colors[i]).brighten(brightness).get()
      series.children.push({ name: title, y: value, parent: i, ratio: j / childLen, color: childColor })
    })
    parentTotal += total;
    if (total > 0) {
      series.parents.push({ y: Math.abs(total), negative: false, name: parentKey, color: colors[i] })
    }
  })
  const other = 1 - parentTotal;
  const otherCosts = otherCosts < 0 ? 0 : other;
  series.parents.push({ y: otherCosts, negative: false, name: 'Other', color: '#FFB74D' })
  series.children.push({ name: 'Other', y: otherCosts, parent: 5, ratio: 0 })
  return series;
}

const getDonutOptions = (donut) => {
  const donutData = buildDonutData(donut);

  return (
    {
      chart: {
        type: 'pie',
        style: {
          fontFamily: fontsArr
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Industry Expenses',
        style: {
          fontSize: '18px',
          fontWeight: 'normal'
        }
      },
      subtitle: {
        text: '(As a Percentage of Total Revenue)',
        style: {
          fontSize: '12px'
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
        },
        series: {
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              enabled: false
            }
          }
        },
      },
      tooltip: {
        pointFormatter: function () {
          return (this.negative ? "-" : "") + ((this.y * 100).toFixed(4)) + '%';
        },
        borderColor: "none",
        style: {
          stroke: "none",
          fontSize: '11px'
        }
      },
      series: [{
        data: donutData.parents,
        size: '60%',
        dataLabels: {
          formatter: function () {
            return this.point.name;
          },
          color: '#ffffff',
          connectorLine: '#fff',
          distance: -30,
          style: {
            fontSize: '11px'
          }
        }
      }, {
        data: donutData.children,
        size: '80%',
        innerSize: '80%',
        dataLabels: {
          formatter: function () {
            return this.y > .005 ? '<b>' + this.point.name + ':</b> ' +
              ((this.y * 100).toFixed(2)) + '%' : null;
          },
          style: {
            fontSize: '11px'
          }
        },
      }],
    }
  )
}

const buildDrilldownData = (pie) => {
  const series = {
    parents: [],
    children: []
  }

  Object.keys(pie.keys).forEach((parentKey, i) => {
    series.children.push({ name: parentKey, id: parentKey, data: [], colorByPoint: true })
    pie.keys[parentKey].forEach((child) => {
      let key, title, value;
      if (typeof child == 'object') {
        title = child.title;
        key = child.key;
      } else {
        key = child;
        title = COPY[key];
      }
      if (pie.displayColumnsBy === 'Years' && pie.reportPeriod !== 'Year to Date') {
        value = pie.data[`${key}_common_size_${pie.commonSize}`] && pie.data[`${key}_common_size_${pie.commonSize}`][pie.year] ? pie.data[`${key}_common_size_${pie.commonSize}`][pie.year] : 0;
      }
      if (pie.displayColumnsBy === 'Years' && pie.reportPeriod === 'Year to Date') {
        let totalRevenueValue = 0;
        if (pie.data['total_revenue']) {
          totalRevenueValue = Object.values(pie.data['total_revenue']).reduce((a, b) => a + b);
        }
        let totalValue = 0;
        if (pie.data[key]) {
          totalValue = Object.values(pie.data[key]).reduce((a, b) => a + b);
        }
        let percentValue = totalValue / totalRevenueValue;
        value = percentValue ? percentValue : 0;
      }
      if (pie.displayColumnsBy === 'Months') {
        let totalRevenueValue = Object.values(pie.data['total_revenue']).reduce((a, b) => a + b);
        let totalValue = 0;
        if (pie.data[key]) {
          totalValue = Object.values(pie.data[key]).reduce((a, b) => a + b);
        }
        let percentValue = totalValue / totalRevenueValue;
        value = percentValue ? percentValue : 0;
      }

      series.children[i].data.push([title, value])
    })
    const total = series.children[i].data.reduce((acc, cur) => {
      return acc + cur[1];
    }, 0);
    series.parents.push({ y: total, negative: total < 0, drilldown: parentKey, name: parentKey, color: '#00AEEF' })
  })
  const parentTotal = series.parents.reduce((acc, cur) => {
    return cur.negative ? acc - cur.y : acc + cur.y;
  }, 0);

  if (pie.page !== 'people') {
    const other = 1 - parentTotal;
    const otherCosts = otherCosts < 0 ? 0 : other;
    series.parents.push({ y: otherCosts, negative: false, drilldown: 'Other', name: 'Other' })
    series.children.push({ name: 'Other', id: 'Other', data: ['Other', otherCosts] })
  }

  return series
}

const getPieOptions = (pie) => {
  const pieData = buildDrilldownData(pie);
  const pieColors = {
    money: ['#69B144', '#00AEEF', '#542667', '#999999', '#737373', '#FFB74D'],
    people: ['#00AEEF', '#71cff3', '#dceef4', '#3a9ec3', '#737373']
  }
  const pdfTitleStyle = pie.pdf ? { fontSize: '1em', fontWeight: 'normal' } : { fontSize: '18px', fontWeight: 'normal' }
  const pdfSubTitleStyle = pie.pdf ? { fontSize: '0.7em' } : { fontSize: '12px' }
  const pdfPlotStyle = pie.pdf ? { textOutline: "none", fontSize: '0.6em' } : { textOutline: "none", fontSize: '11px' }
  return {
    chart: {
      type: 'pie',
      events: {
        drilldown: function (category) {
          const $container = $(this.container);
          const drilldownName = category.seriesOptions.name;
          switch (drilldownName) {
            case 'Money': $container.addClass('money'); break;
            case 'People': $container.addClass('people'); break;
            case 'Customers': $container.addClass('customers'); break;
            case 'Other': $container.addClass('other'); break;
            case 'COGS': $container.addClass('cogs'); break;
            case 'Net Income': $container.addClass('net-income'); break;
          }
        },
        drillupall: function (category) {
          const $container = $(this.container);
          $container.removeClass('money people customers other cogs net-income');
        }
      },
      style: {
        stroke: "none",
        fontFamily: fontsArr
      }
    },
    legend: { enabled: false },
    title: {
      text: pie.title,
      style: pdfTitleStyle
    },
    subtitle: {
      text: pie.subtitle,
      style: pdfSubTitleStyle
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: '12px'
        },
      }
    },
    yAxis: {
      labels: {
        style: {
          fontSize: '12px'
        },
        formatter: function () {
          return this.value * 100 + "%";
        }
      },
      title: {
        text: null
      },
    },
    exporting: {
      enabled: pie.pdf ? false : true,
      allowHTML: true,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        }
      }
    },
    navigation: {
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#000000'
      },
      menuItemStyle: {
        padding: '3px 5px',
        fontWeight: 'bold'
      },
      menuStyle: {
        padding: '0',
      }
    },
    tooltip: {
      pointFormatter: function () {
        return (this.negative ? "-" : "") + ((this.y * 100).toFixed(4)) + '%';
      },
      borderColor: "none",
      style: {
        stroke: "none",
        fontSize: '12px'
      }
    },
    plotOptions: {
      pie: {
        colors: pieColors[pie.page],
        dataLabels: {
          distance: 10,
          style: pdfPlotStyle
        }
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (pie.page === 'people') {
              return (this.y * 100).toFixed(2) + '%';
            } else {
              return this.point.name + ': ' + (this.point.negative ? "-" : "") + (this.y * 100).toFixed(2) + '%';
            }
          },
          style: {
            fontSize: '12px'
          },
        }
      }
    },
    series: [{
      name: 'Categories',
      data: pieData.parents,
    }],
    drilldown: {
      animation: true,
      series: pieData.children,
    }
  }
};

const format = (format, num) => {
  switch (format) {
    case 'percent':
      return ` ${Highcharts.numberFormat((num * 100), 2, '.', ',')}%`;
    case 'days':
      return `${Highcharts.numberFormat(num, 1)} days`;
    case 'decimal':
      return Highcharts.numberFormat(num, 2);
    case 'ratio':
      return ` ${Highcharts.numberFormat(num, 2, '.', ',')}:1`;
    case 'money':
      return num >= 0 ? ` $${Highcharts.numberFormat(num, 0, '.', ',')}` : ` -$${Highcharts.numberFormat(Math.abs(num), 0, '.', ',')}`;
    case 'wholeNumber':
      return Math.round(num)
  }
}

const axisFormat = (format, num) => {
  switch (format) {
    case 'percent':
      return `${Highcharts.numberFormat((num * 100), 0, '', ',')}%`;
    case 'days':
      return `${Highcharts.numberFormat(num, 1)} days`;
    case 'decimal':
      return Highcharts.numberFormat(num, 0);
    case 'ratio':
      return `${Highcharts.numberFormat(num, 0, '.', ',')}:1`;
    case 'money':
      return num >= 0 ? `$${Highcharts.numberFormat(num, 0, '.', ',')}` : `-$${Highcharts.numberFormat(Math.abs(num), 0, '.', ',')}`;
    case 'wholeNumber':
      return Math.round(num)
  }
}

const getLineOrColOptions = (chart, youData, compareData, fiscalYearEnd) => {
  // Need to give color to series in order to export/print charts with highcharts exporting module
  const barColor = {
    money: '#69B144',
    people: '#00AEEF',
    customers: '#542667'
  }

  const printBarChartClass = {
    money: 'ind-money-content',
    people: 'ind-people-content',
    customers: 'ind-customer-content'
  }

  let seriesOneType = chart.styles ? chart.styles.seriesOne.type : 'column'
  let seriesTwoType = chart.styles ? chart.styles.seriesTwo.type : 'column'

  let seriesArr = [
    {
      className: printBarChartClass[chart.page],
      color: barColor[chart.page],
      type: seriesOneType,
      name: chart.displayColumnsBy === 'Years' && chart.reportPeriod !== 'Year to Date' ? `You ${fiscalYearEnd}` : 'You',
      data: youData,
      zIndex: 2
    }
  ]

  if (compareData && compareData.length > 0) {
    let compareName = chart.compareWith

    if (chart.displayColumnsBy === 'Years' && chart.reportPeriod !== 'Year to Date' && chart.compareWith === 'Peers') {
      compareName = 'Peers 12/31'
    } else if (chart.page === 'money' && chart.displayColumnsBy === 'Months') {
      compareName = chart.compareWithTitle
    }

    seriesArr.push({
      type: seriesTwoType,
      color: '#D3D3D3',
      stroke: barColor[chart.page],
      name: compareName,
      data: compareData,
      zIndex: 1,
    })
  }

  const pdfTitleStyle = chart.pdf ? { fontSize: '1em', fontWeight: 'normal' } : { fontSize: '18px', fontWeight: 'normal' }
  const pdfLabelStyle = chart.pdf ? { fontSize: '0.8em' } : { fontSize: '11px' }
  const pdfPlotStyle = chart.pdf ? { stroke: "none", fontSize: '0.8em' } : { stroke: "none", fontSize: '11px' }

  return {
    chart: {
      style: {
        fontFamily: fontsArr
      }
    },
    colors: [
      "highcharts-color-0",
      "highcharts-color-11"
    ],
    title: {
      text: chart.title,
      style: pdfTitleStyle
    },
    subtitle: {
      text: chart.subtitle,
      style: {
        fontSize: '12px'
      }
    },
    credits: {
      enabled: false
    },
    navigation: {
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#000000'
      },
      menuItemStyle: {
        padding: '3px 5px',
        fontWeight: 'bold'
      },
      menuStyle: {
        padding: '0',
      }
    },
    exporting: {
      enabled: false
    },
    series: seriesArr,
    tooltip: {
      enabled: chart.pdf ? false : true,
      shared: true,
      style: {
        stroke: "none",
      },
      useHTML: true,
      formatter: function () {
        if (chart.displayColumnsBy === 'Years' && chart.reportPeriod !== 'Year to Date') {
          if (this.points) {
            let currentYear = new Date().getFullYear();
            return this.points.reduce(function (s, point) {
              let y = format(chart.format, point.y);
              let youOrCompare = point.series.name.includes('You');
              let year = youOrCompare && fiscalYearEnd !== '12/31' ? point.x + 1 : point.x;

              if ((year - 1) === currentYear && chart.companyYTDDate && youOrCompare || year === currentYear && chart.companyYTDDate && youOrCompare && fiscalYearEnd === '12/31') {
                return s + 'You ' + chart.companyYTDDate + ': ' + y + '<br/>';
              } else if (point.series.name.includes('Peers') && currentYear === year && chart.aggregateYTDDate) {
                return s + 'Peers ' + chart.aggregateYTDDate + ': ' + y + '<br/>';
              } else if (['Practice', 'NSCHBC'].includes(point.series.name)) {
                return s + point.series.name + ' ' + year + ': ' + y + '<br/>';
              } else {
                return s + point.series.name + '/' + year + ': ' + y + '<br/>';
              }
            }, '');
          }
        } else if (chart.displayColumnsBy === 'Years' && chart.reportPeriod === 'Year to Date' || chart.displayColumnsBy === 'Months') {
          if (this.points) {
            return this.points.reduce(function (s, point) {
              let y = format(chart.format, point.y);
              return s + '<br/>' + point.series.name + ': ' + y;
            }, '<b>' + this.x + '</b>');
          }
        } else {
          return ""
        }
      }
    },
    xAxis: {
      title: {
        text: chart.displayColumnsBy,
        style: pdfLabelStyle,
      },
      categories: chart.displayColumnsBy === "Years" ? chart.years : chart.months,
      startOnTick: true,
      endOnTick: true,
      labels: {
        align: 'center',
        style: pdfLabelStyle
      },
      crosshair: {}
    },
    legend: {
      itemStyle: {
        stroke: "none",
        textOutline: "none",
        fontSize: "9px",
      }
    },
    yAxis: {
      title: {
        enabled: false
      },
      labels: {
        enabled: true,
        formatter: function () {
          if (this.value) {
            let val = axisFormat(chart.format, this.value);
            return val;
          } else {
            return 0
          }
        },
        style: pdfLabelStyle
      },
    },
    plotOptions: {
      line: {
        lineWidth: 3
      },
      series: {
        dataLabels: {
          enabled: true,
          style: pdfPlotStyle,
          formatter: function () {
            if (this.y) {
              let plotY = format(chart.format, this.y);
              return plotY;
            } else {
              let plotY = format(chart.format, 0);
              return plotY;
            }
          },
          align: 'center',
          crop: false,
        },
      }
    },
  };
}

// Only used in people and customers report pages
const getVerticalBarChartOptions = (chart, youData, compareData) => {
  const barColor = {
    money: '#69B144',
    people: '#00AEEF',
    customers: '#542667'
  }

  let seriesArr = [
    {
      type: 'column',
      name: 'You',
      color: barColor[chart.page],
      data: [youData],
      zIndex: 1
    }
  ]

  if (compareData) {
    seriesArr.push({
      type: 'column',
      name: chart.compareWith,
      color: '#cccccc',
      data: [compareData],
      zIndex: 2,
    })
  }

  return {
    chart: {
      type: 'column',
      style: {
        fontFamily: fontsArr
      }
    },
    colors: [
      "highcharts-color-0",
      "highcharts-color-1"
    ],
    title: {
      text: chart.title,
      style: {
        fontSize: '18px',
        fontWeight: 'normal'
      }
    },
    subtitle: {
      text: chart.subtitle,
      style: {
        fontSize: '12px'
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      allowHTML: true,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        }
      }
    },
    navigation: {
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#000000'
      },
      menuItemStyle: {
        padding: '3px 5px',
        fontWeight: 'bold'
      },
      menuStyle: {
        padding: '0',
      }
    },
    tooltip: {
      enabled: false,
      shared: true,
      valuePrefix: '',
      valueSuffix: '',
      valueDecimals: 0,
    },
    xAxis: {
      categories: [''],
      labels: {
        align: 'center',
        reserveSpace: false,
        x: 0, y: 15
      }
    },
    legend: {
      itemStyle: {
        stroke: "none",
        textOutline: "none",
        fontSize: "9px",
      }
    },
    yAxis: {
      title: {
        enabled: false
      },
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          align: 'center',
          enabled: true,
          allowOverlap: true,
          crop: false,
          style: {
            stroke: "none",
            textOutline: "none",
            fontSize: '12px'
          },
          formatter: function () {
            if (this.y) {
              let plotY = format(chart.format, this.y);
              return plotY;
            } else {
              return ""
            }
          },
        }
      }
    },
    series: seriesArr
  }
}

const getHorizontalBarOptions = (chart, xCategories, hBarSeries) => {
  return {
    chart: {
      type: 'bar',
      style: {
        fontFamily: fontsArr
      }
    },
    title: {
      text: chart.title,
      style: {
        fontWeight: 'normal',
        fontSize: '18px'
      }
    },
    subtitle: {
      text: chart.subtitle,
      style: {
        fontSize: '12px'
      }
    },
    xAxis: {
      categories: xCategories,
      title: {
        text: null
      },
      labels: {
        style: {
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      tickPositions: [0, 25, 50, 75, 100],
      min: 0,
      max: 100,
      opposite: true,
      title: { text: null },
      labels: {
        style: {
          fontSize: '11px'
        }
      }
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    exporting: {
      allowHTML: true,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        }
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y + '%'
          },
          style: {
            stroke: "none",
            textOutline: "none",
            fontSize: '11px'
          }
        }
      }
    },
    legend: {
      itemStyle: {
        stroke: "none",
        textOutline: "none",
        fontSize: '12px'
      }
    },
    series: hBarSeries,
  }
}

const getStackedColumnOptions = (chart, youData) => {
  const pdfTitleStyle = chart.pdf ? { fontSize: '1em', fontWeight: 'normal' } : { fontSize: '18px', fontWeight: 'normal' }
  const pdfLabelStyle = chart.pdf ? { fontSize: '0.8em' } : { fontSize: '11px' }
  const pdfPlotStyle = chart.pdf ? { fontSize: '0.8em' } : { fontSize: '11px' }
  const pdfLegendStyle = chart.pdf ? { fontSize: '0.7em' } : { fontSize: '12px' }

  return {
    chart: {
      type: 'column',
      style: {
        fontFamily: fontsArr
      }
    },
    title: {
      text: chart.title,
      style: pdfTitleStyle
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: chart.pdf ? false : true,
      allowHTML: true,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        }
      }
    },
    navigation: {
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#000000',
      },
      menuItemStyle: {
        padding: '3px 5px',
        fontWeight: 'bold'
      },
      menuStyle: {
        padding: '0',
      }
    },
    xAxis: {
      title: {
        style: pdfLabelStyle
      },
      categories: chart.categories,
      startOnTick: true,
      endOnTick: true,
      labels: {
        align: 'center',
        style: pdfLabelStyle
      },
      crosshair: {}
    },
    yAxis: {
      min: 0,
      title: {
        enabled: false
      },
      labels: {
        formatter: function () {
          if (chart.format === 'percent') {
            return this.axis.defaultLabelFormatter.call(this) * 100 + '%';
          } else {
            return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        },
        style: pdfLabelStyle
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
        }
      }
    },
    legend: {
      backgroundColor: 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
      itemStyle: pdfLegendStyle,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        let y = format(chart.format, this.y);
        let total = format(chart.format, this.total)
        return this.series.name + ': ' + y + '<br/>' + 'Total: ' + total;
      },
      style: {
        fontSize: '11px'
      }
    },
    series: youData,
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: pdfPlotStyle,
          formatter: function () {
            return format(chart.format, this.y)
          }
        }
      }
    },
  }
}

const getLineChartOptions = (chart, seriesTitles, lineSeries) => {
  // need latest year for cltv70, 80, 90
  return {
    chart: {
      type: 'line',
      style: {
        fontFamily: fontsArr
      }
    },
    colors: [
      "highcharts-color-0",
      "highcharts-color-1"
    ],
    title: {
      text: chart.title,
      style: {
        fontSize: '18px',
        fontWeight: 'normal'
      }
    },
    subtitle: {
      text: chart.subtitle,
      style: {
        fontSize: '12px'
      }
    },
    credits: {
      enabled: false
    },
    exporting: {
      allowHTML: true,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        }
      }
    },
    navigation: {
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#000000'
      },
      menuItemStyle: {
        padding: '3px 5px',
        fontWeight: 'bold'
      },
      menuStyle: {
        padding: '0',
      }
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: '%',
      shared: true,
      pointFormatter: function () {
        return `<span class="${this.color}">\u25CF</span> ${this.series.name}: <b>${format(chart.format, this.y)}</b><br/>`
      },
      style: {
        stroke: "none",
        fontSize: '11px'
      }
    },
    xAxis: {
      categories: seriesTitles,
      startOnTick: true,
      endOnTick: true,
      labels: {
        align: 'right',
        style: {
          fontSize: '11px'
        }
      },
      crosshair: {}
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: '12px'
        }
      },
      crosshair: {}
    },
    series: lineSeries,
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return format(chart.format, this.y);
          },
          align: 'center',
          crop: false,
          allowOverlap: true,
          style: {
            stroke: "none",
            textOutline: "none",
            fontSize: '12px'
          }
        },
        lineWidth: 3
      }
    },
    legend: {
      itemStyle: {
        stroke: "none",
        textOutline: "none",
        fontSize: '12px'
      }
    }
  }
}

export { gaugeKeys, getIndustryLineChartOptions, getIndustryBarChartOptions, getMultiDataBarChartOptions, get3DBubbleChartOptions, getQuadrantOptions, getScatterPlotOptions, getGaugeOptions, buildDrilldownData, getPieOptions, getDonutOptions, format, axisFormat, getLineOrColOptions, getVerticalBarChartOptions, getHorizontalBarOptions, getStackedColumnOptions, getLineChartOptions };