import React, { useContext } from 'react';
import { Data } from '../contexts';
import { capitalize, parseMoney } from '../../utils';


const IndustryKPI = (props) => {
    const { calcs } = useContext(Data);
    const avg = calcs.avg
    const padding = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
    let cellClass = '';
    let pageTitle = capitalize(props.page);
    let tableClasses = '';
    if (props.page === 'people') {
        cellClass = 'people-table-cell';
        tableClasses = 'people-report-table';
    } else {
        cellClass = 'customers-table-cell';
        tableClasses = 'customers-report-table';
    }

    let peopleKpis = [
        {
            key: 'title',
            title: 'Analysis per Employee',
            format: 'header'
        },
        {
            key: 'total_revenue_per_employee',
            title: 'Revenue Per Employee',
            format: 'money'
        },
        {
            key: 'operating_expenses_net_income_per_employee',
            title: 'Profit per Employee',
            format: 'money'
        },
        {
            key: 'sum_compensation_growth_rate',
            title: 'Compensation Growth Rate',
            format: 'percent'
        },
        {
            key: 'sum_taxes_and_benefits_growth_rate',
            title: 'Benefits Growth Rate',
            format: 'percent'
        },
        {
            key: 'sum_subcontractors_growth_rate',
            title: 'Use of Subcontractors Growth Rate',
            format: 'percent'
        },
        {
            key: 'average_wages_and_salaries',
            title: 'Avg Wages and Salaries Per Employee',
            format: 'money'
        },
        {
            key: 'average_benefits',
            title: 'Avg Benefits Per Employee',
            format: 'money'
        },
        {
            key: 'title',
            title: padding,
            format: 'header'
        },
        {
            key: 'title',
            title: 'Turnover Analysis',
            format: 'header'
        },
        {
            key: 'turnover_rate',
            title: 'Turnover Rate',
            format: 'percent'
        },
        {
            key: 'title',
            title: padding,
            format: 'header'
        },
        {
            key: 'title',
            title: 'Employee Data',
            format: 'header'
        },
        {
            key: 'total_number_of_employees',
            title: 'Number of Employees',
            format: ''
        },
        {
            key: 'number_of_employees_who_left',
            title: 'Number of Employees who left',
            format: ''
        }
    ]

    let customersKpis = [
        {
            key: 'title',
            title: 'Analysis per Client',
            format: 'header'
        },
        {
            key: 'total_revenue',
            title: 'Revenue',
            format: 'money'
        },
        {
            key: 'revenue_per_customer',
            title: 'Revenue Per Client',
            format: 'money'
        },
        {
            key: 'gross_profit',
            title: 'Gross Profit',
            format: 'money'
        },
        {
            key: 'gross_profit_per_customer',
            title: 'Gross Profit Per Client',
            format: 'money'
        },
        {
            key: 'operating_expenses_net_income',
            title: 'Net Profit',
            format: 'money'
        },
        {
            key: 'operating_expenses_net_income_per_customer',
            title: 'Net Profit Per Client',
            format: 'money'
        },
        {
            key: 'title',
            title: padding,
            format: 'header'
        },
        {
            key: 'title',
            title: 'Marketing Spend/ROI',
            format: 'header'
        },
        {
            key: 'total_customer_costs_common_size_revenue',
            title: 'Marketing Spend',
            format: 'percent'
        },
        {
            key: 'customer_roi',
            title: 'Marketing ROI',
            format: 'percent'
        },
        {
            key: 'title',
            title: padding,
            format: 'header'
        },
        {
            key: 'title',
            title: 'Customer Concentration',
            format: 'header'
        },
        {
            key: 'percent_of_sales_from_the_top_10_percent',
            title: 'Top 10%',
            format: 'percent'
        },
        {
            key: 'percent_of_sales_from_the_top_25_percent',
            title: 'Top 25%',
            format: 'percent'
        },
        {
            key: 'percent_of_sales_from_the_top_50_percent',
            title: 'Top 50%',
            format: 'percent'
        },
        {
            key: 'title',
            title: padding,
            format: 'header'
        },
        {
            key: 'title',
            title: 'Client Data',
            format: 'header'
        },
        {
            key: 'total_number_of_customers',
            title: 'Number of Clients',
            format: ''
        },
    ]

    const buildRowsArray = () => {
        const rows = []
        let arrayOfMetrics;
        props.page === 'people' ? arrayOfMetrics = peopleKpis : arrayOfMetrics = customersKpis

        arrayOfMetrics.forEach(set => {
            let helperObj = {}
            helperObj['title'] = set.title
            helperObj['format'] = set.format

            props.yearRange.forEach(y => {
                if (set.key !== 'title' && set.key !== 'cost_of_turnover' && set.key !== 'number_of_employees_who_left') {
                    helperObj[y] = _.isNumber(avg[set.key][y]) ? avg[set.key][y] : null;
                } else if (set.key === 'number_of_employees_who_left') {
                    let turnoverRate = _.isNumber(avg['turnover_rate'][y]) ? avg['turnover_rate'][y] : null;
                    let totalNumEmployees = _.isNumber(avg['total_number_of_employees'][y]) ? avg['total_number_of_employees'][y] : null;
                    helperObj[y] = turnoverRate * totalNumEmployees
                } else {
                    helperObj[y] = null
                }
            })

            rows.push(helperObj)
        })

        return rows
    }

    const buildNumOfCompaniesRow = () => {
        return (
            <tr key={`number-of-companies-${props.page}`} id={`${props.chart}-report-row-${props.page}`} >
                <td className="industry-copy-cell">Number of Companies</td>
                {props.yearRange.map((year, ii) => {
                    let backgroundStyle = '';
                    let numOfCompanies = calcs['avg']['number_of_companies'][year] || 0

                    if (ii % 2 === 0) {
                        backgroundStyle = cellClass;
                    }

                    return (
                        <React.Fragment key={Math.random(80)}>
                            <td key={ii} className={backgroundStyle}>
                                {`n = ${numOfCompanies}`}
                            </td>
                            <td></td>
                        </React.Fragment>
                    )
                })}
            </tr>
        )
    }

    const buildBodyYears = () => {
        let rows = buildRowsArray()

        return rows.map(row => {
            return (
                <tr key={Math.random(24)}>
                    <td className='people-cus-kpi-table-copy industry-copy-cell'>{row['format'] !== 'header' ? padding + row['title'] : row['title']}</td>
                    {props.yearRange.map((y, i) => {
                        let backgroundStyle = ''
                        let value;
                        if (i % 2 === 0) {
                            backgroundStyle = cellClass
                        }

                        switch (row.format) {
                            case 'money':
                                value = parseMoney(row[y]);
                                break;
                            case 'percent':
                                value = row[y] ? row[y].toFixed(2) + '%' : '-';
                                break;
                            case 'header':
                                value = '';
                                break;
                            default:
                                value = row[y] ? row[y].toFixed(0) : '-';
                                break;
                        }
                        return (
                            <React.Fragment key={Math.random(80)}>
                                <td className={backgroundStyle}>{value}</td>
                                <td></td>
                            </React.Fragment>
                        )

                    })}
                </tr>
            )
        })
    }

    const renderYearHeaderColumns = () => {
        let formatedYearsRow = [];

        props.yearRange.forEach((y, i) => {
            let cellClasses = 'common-size-cell ';

            if (i % 2 === 0) {
                cellClasses += cellClass;
            }

            formatedYearsRow.push(
                <React.Fragment key={Math.random(i) + 'tcpc-year-headers'}>
                    <td className={cellClasses}>{y}</td>
                    <td className='space-cell'></td>
                </React.Fragment>
            )
        })

        return (
            <tr key={Math.random(20)}>
                <td></td>
                {formatedYearsRow}
            </tr>
        )
    }

    const buildTable = () => {
        return (
            <div>
                <div className={`monthly-forecast-landscape report-card monthly-sheet`}>
                    <div style={{ 'paddingTop': '10px' }}>
                        <h1 className='report-table-title hide-not-on-print'>Key Performance Indicators - {pageTitle}</h1>
                        <div className='pdf-table-div industry-table-container'>
                            <table style={{ whiteSpace: 'pre' }} className={tableClasses} >
                                <thead>
                                    {renderYearHeaderColumns()}
                                </thead>
                                <tbody>
                                    {buildBodyYears()}
                                    {buildNumOfCompaniesRow()}
                                </tbody>
                            </table>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (buildTable())
}

export default IndustryKPI;