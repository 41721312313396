import React from 'react';
import { cardonator } from '../HOCs';
import { parseMoney } from "../../utils";

const PeopleCustomersTable = (props) => {
  let backgroundColor = '';
  let cellClass = '';
  let commonSizeValCellClass = 'common-size-cell';
  let tableClasses;

  if (props.page === 'people') {
    backgroundColor = '#8ADFFE';
    cellClass = 'people-table-cell';
    commonSizeValCellClass += ' people-table-cell';
    tableClasses = "people-report-table";
  } else {
    backgroundColor = '#CDA5DE';
    cellClass = 'customers-table-cell';
    commonSizeValCellClass += ' customers-table-cell';
    tableClasses = "customers-report-table";
  }

  const buildRowsMonths = () => {
    return props.rows.map((row, i) => {
      let rowChecked = row.checked && props.reportMetrics['editMetrics'] ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
      let emptyCellVal = row.emptyRow ? '' : '-'
      return (
        <tr key={Math.random(80) + 'buildRowMonths'} id={`${props.chart}-report-row-${i}`} onClick={() => props.reportMetrics['editMetrics'] ? props.handleSelectAndLayout(props.page, 'financial_detail', row.key) : null}>
          {!props.pdf ? <td>{rowChecked}</td> : null}
          <td className="table-copy">{row.copy}</td>
          {Object.values(props.monthsObj).map((month, ii) => {
            if (Object.keys(row).length > 1) {
              return (
                <td key={Math.random(80)}>
                  {row[ii + 1] ? parseMoney(row[ii + 1]) : emptyCellVal}
                </td>
              )
            } else {
              <td key={Math.random(80)}></td>
            }
          })}
          <td>
            {row['youCommonSize'] ? row['youCommonSize'].toFixed(2) + '%' : emptyCellVal}
          </td>
          {
            props.currentNewFilters.compare_with === 'None'
              ? null
              : <React.Fragment key={Math.random(80)}>
                <td>{row['arrow']}</td>
                <td>
                  {row['compareCommonSize'] ? row['compareCommonSize'].toFixed(2) + '%' : emptyCellVal}
                </td>
              </React.Fragment>
          }
          <td></td>
        </tr>
      )
    })
  }

  const buildRowsYears = () => {
    if (props.currentNewFilters.report_period !== 'Year to Date') {
      return props.rows.map((row, i) => {
        let rowChecked = row.checked && props.reportMetrics['editMetrics'] ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
        let emptyCellVal = row.emptyRow ? '' : '-'
        return (
          <tr key={Math.random(80) + 'buildRowYears'} id={`${props.chart}-report-row-${i}`} onClick={() => props.reportMetrics['editMetrics'] ? props.handleSelectAndLayout(props.page, 'financial_detail', row.key) : null}>
            {!props.pdf ? <td>{rowChecked}</td> : null}
            <td className="table-copy">{row.copy}</td>
            {props.yearRange.map((year, i) => {
              let backgroundStyle = {}
              let commonSizeValCellClass = 'common-size-cell ';
              let valueCellClass = '';

              if (i % 2 === 0) {
                backgroundStyle['backgroundColor'] = backgroundColor;
                commonSizeValCellClass += cellClass;
                valueCellClass = cellClass;
              }

              if (Object.keys(row).length > 2) {
                return (
                  <React.Fragment key={Math.random(80)}>
                    <td className={valueCellClass} style={backgroundStyle}>
                      {row[year] ? parseMoney(row[year]) : emptyCellVal}
                    </td>
                    <td className={valueCellClass} style={backgroundStyle}>
                      {row[year + 'youCommonSize'] ? row[year + 'youCommonSize'].toFixed(2) + '%' : emptyCellVal}
                    </td>
                    <td className={commonSizeValCellClass} style={backgroundStyle}>{row[year + 'arrow']}</td>
                    <td className={valueCellClass} style={backgroundStyle}>
                      {row[year + 'compareCommonSize'] ? row[year + 'compareCommonSize'].toFixed(2) + '%' : emptyCellVal}
                    </td>
                    <td></td>
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={Math.random(80)}>
                    <td className={valueCellClass} style={backgroundStyle}></td>
                    <td className={valueCellClass} style={backgroundStyle}></td>
                    <td className={commonSizeValCellClass} style={backgroundStyle}></td>
                    <td className={valueCellClass} style={backgroundStyle}></td>
                    <td></td>
                  </React.Fragment>
                )
              }
            })}
          </tr>
        )
      })
    } else {
      return props.rows.map((row, i) => {
        let rowChecked = row.checked && props.reportMetrics['editMetrics'] ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
        let emptyCellVal = row.emptyRow ? '' : '-'
        return (
          <tr key={Math.random(80) + 'buildRowsYTD'} id={`${props.chart}-report-row-${i}`} onClick={() => props.reportMetrics['editMetrics'] ? props.handleSelectAndLayout(props.page, 'financial_detail', row.key) : null}>
            {!props.pdf ? <td>{rowChecked}</td> : null}
            <td className="table-copy">{row.copy}</td>
            {[props.year - 1, props.year].map((year, i) => {
              let arrowCellClass = 'common-size-arrow';
              let backgroundStyle = {}
              let commonSizeValCellClass = 'common-size-cell';
              let valueCellClass = '';
              if (i % 2 === 0) {
                backgroundStyle['backgroundColor'] = backgroundColor;
                valueCellClass = cellClass
              }
              return (
                <React.Fragment key={Math.random(80)}>
                  <td className={valueCellClass} style={backgroundStyle}>{row[year] ? parseMoney(row[year]) : emptyCellVal}</td>
                  <td className={valueCellClass} style={backgroundStyle}>{row[year + 'youCommonSize'] ? row[year + 'youCommonSize'].toFixed(2) + '%' : emptyCellVal}</td>
                  <td className={valueCellClass} style={backgroundStyle}>{row[year + 'compareCommonSize'] ? row[year + 'compareCommonSize'].toFixed(2) + '%' : emptyCellVal}</td>
                  <td></td>
                </React.Fragment>
              )
            })}
            <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}>{parseMoney(row[props.year - 1] - row[props.year]) ? parseMoney(row[props.year - 1] - row[props.year]) : emptyCellVal}</td>
          </tr>
        )
      })
    }
  }

  const renderYearHeaderColumns = (displayColumnsBy, reportPeriod) => {
    const currentYear = new Date().getFullYear();
    let monthsHeaders;
    let formatedYearsRow = [];
    let youPeersRow = [];

    if (displayColumnsBy === 'Years') {
      if (reportPeriod !== 'Year to Date') {
        props.yearRange.forEach((y, i) => {
          let arrowCellClass = 'common-size-arrow ';
          let backgroundStyle = {}
          let commonSizeValCellClass = 'common-size-cell ';
          let youDate, compareDate = '';

          if (y === currentYear) {
            youDate = props.companyYTDDate;
            compareDate = props.aggregateYTDDate;
          } else {
            youDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${y}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${y + 1}` : `12/31/${y}`;
            compareDate = `12/31/${y}`;
          }

          if (i % 2 === 0) {
            arrowCellClass += cellClass;
            backgroundStyle['backgroundColor'] = backgroundColor
            commonSizeValCellClass += cellClass;
          }

          youPeersRow.push(
            <React.Fragment key={Math.random(i) + 'tcpc-year-headers'}>
              <td colSpan="2" className={commonSizeValCellClass} style={backgroundStyle}>You</td>
              <td className={arrowCellClass} style={backgroundStyle}></td>
              <td className={commonSizeValCellClass} style={backgroundStyle}>Peers</td>
              <td className='space-cell'></td>
            </React.Fragment>
          )

          formatedYearsRow.push(
            <React.Fragment key={Math.random(i) + 'tcpc-year-headers'}>
              <td colSpan="2" className={commonSizeValCellClass} style={backgroundStyle}>{youDate}</td>
              <td className={arrowCellClass} style={backgroundStyle}></td>
              <td className={commonSizeValCellClass} style={backgroundStyle}>{compareDate}</td>
              <td className='space-cell'></td>
            </React.Fragment>
          )
        })

        return (
          <thead>
            <tr key={Math.random(20)}>
              {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.reportMetrics['editMetrics'] ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
              <td></td>
              {youPeersRow}
            </tr>
            <tr key={Math.random(20)}>
              {!props.pdf ? <td></td> : null}
              <td></td>
              {formatedYearsRow}
              {props.yearRange.length === 2 ? <td></td> : null}
            </tr>
          </thead>
        )

      } else {
        // YTD Headers
        return (
          <thead>
            <tr>
              {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.reportMetrics['editMetrics'] ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
              <td></td>
              <td className={cellClass} style={{ 'backgroundColor': backgroundColor }}>{`YTD ${props.clientFirstYearMonth}/${props.year - 1}`}</td>
              <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}> You</td>
              <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}>Peers</td>
              <td></td>
              <td>{`YTD ${props.clientSecondYearMonth}/${props.year}`}</td>
              <td className='common-size-cell'>You</td>
              <td className='common-size-cell'>Peers</td>
              <td></td>
              <td className={`common-size-cell ${cellClass}`} style={{ 'backgroundColor': backgroundColor }}>Δ Delta</td>
            </tr>
          </thead>
        )
      }
    } else {
      // Months Headers
      monthsHeaders = Object.values(props.monthsObj).map(month => {
        return <td key={Math.random(80) + 'tcpc-months-headers'}>{month}</td>
      })
      monthsHeaders.push(
        <React.Fragment key={Math.random(80)}>
          <td>You YTD</td>
          {
            props.currentNewFilters.compare_with === 'None'
              ? null
              : (
                <React.Fragment key={Math.random(80)}>
                  <td></td>
                  <td>{props.currentNewFilters.compare_with} {props.currentNewFilters.compare_with === 'YOY' ? props.year - 1 : null}</td>
                </React.Fragment>
              )
          }
        </React.Fragment>
      )

      return (
        <thead>
          <tr key={Math.random(20)}>
            {!props.pdf ? <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.reportMetrics['editMetrics'] ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td> : null}
            <td></td>
            {monthsHeaders}
          </tr>
        </thead>
      )
    }
  }

  const buildTable = (displayColumnsBy, reportPeriod) => {
    if (props.pdf) {
      return (
        <div className='landscape-view'>
          <div className='report-pdf-landscape'>
            <div>
              <h1 className="report-table-title">Financial Detail - {props.clientName}</h1>
              <div className='money-pdf-table-div'>
                <table style={{ whiteSpace: 'pre' }} className='money-pdf-table' >
                  {renderYearHeaderColumns(displayColumnsBy, reportPeriod)}
                  <tbody>
                    {displayColumnsBy === 'Years' ? buildRowsYears() : buildRowsMonths()}
                  </tbody>
                  <tfoot>
                    <tr><td><i>{props.n ? `n = ${props.n}` : null}</i></td></tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='people-customers-pdf'>
          <div className='money-report-container'>
            <table style={{ whiteSpace: 'pre' }} className={tableClasses} >
              {renderYearHeaderColumns(displayColumnsBy, reportPeriod)}
              <tbody>
                {displayColumnsBy === 'Years' ? buildRowsYears() : buildRowsMonths()}
              </tbody>
              <tfoot>
                <tr><td><i>{props.n ? `n = ${props.n}` : null}</i></td></tr>
              </tfoot>
            </table>
          </div>
        </div>
      )
    }
  }

  return (buildTable(props.currentNewFilters.display_columns_by, props.currentNewFilters.report_period))
}

export default cardonator(PeopleCustomersTable);