import React, { useContext } from 'react';
import { Data } from "../contexts";
import { capitalize, parseMoney } from "../../utils";

const TableChartIndustry = (props) => {
  const { calcs } = useContext(Data);
  let cellClass = '';
  let tableClasses = '';
  let pageTitle = capitalize(props.page)
  if (props.page === 'money') {
    cellClass = 'money-table-cell';
    tableClasses = "industry-money-table";
  } else if (props.page === 'people') {
    cellClass = 'people-table-cell';
    tableClasses = "industry-people-table";
  } else {
    cellClass = 'customers-table-cell';
    tableClasses = "industry-customers-table";
  }
  const titles = {
    revenue: 'Revenue',
    assets: 'Total Assets'
  }

  const buildRows = () => {
    return props.rows.map((row, i) => {
      let rowKeysLength = Object.keys(row).length;
      return (
        <tr key={i} id={`${props.chart}-report-row-${i}`} >
          <td className="industry-copy-cell">{row.copy}</td>
          {props.yearRange.map((year, ii) => {
            let firstValStyle = ''
            let secondValStyle = 'common-size-cell ';
            let firstVal, secondVal;
            if (rowKeysLength > 1) {
              firstVal = row.hasOwnProperty(year) && _.isFinite(row[year]) ? parseMoney(row[year]) : ''
              secondVal = props.commonSizeTable && row[year + 'commonSize'] && _.isFinite(row[year]) ? row[year + 'commonSize'].toFixed(2) + '%' : '';
            } else {
              firstVal = null
              secondVal = null;
            }

            if (ii % 2 === 0) {
              firstValStyle = cellClass
              secondValStyle += cellClass
            }

            return (
              <React.Fragment key={Math.random(80)}>
                <td key={`first-${ii}`} className={firstValStyle}>{firstVal}</td>
                <td key={ii} className={secondValStyle}>{secondVal}</td>
                <td></td>
              </React.Fragment>
            )
          })}
        </tr>
      )
    })
  }

  const buildNumOfCompaniesRow = () => {
    return (
      <tr key={`number-of-companies-${props.page}`} id={`${props.chart}-report-row-${props.page}`} >
        <td className="industry-copy-cell">Number of Companies</td>
        {props.yearRange.map((year, ii) => {
          let backgroundStyle = '';
          let numOfCompanies = calcs['avg']['number_of_companies'][year] || 0

          if (ii % 2 === 0) {
            backgroundStyle = cellClass;
          }

          return (
            <React.Fragment key={Math.random(80)}>
              <td key={`first-${ii}`} className={backgroundStyle}></td>
              <td key={ii} className={backgroundStyle}>
                {`n = ${numOfCompanies}`}
              </td>
              <td></td>
            </React.Fragment>
          )
        })}
      </tr>
    )
  }

  const renderYearHeaderColumns = () => {
    let formatedYearsRow = [];

    props.yearRange.forEach((y, i) => {
      let cellClasses = 'common-size-cell ';

      if (i % 2 === 0) {
        cellClasses += cellClass;
      }

      formatedYearsRow.push(
        <React.Fragment key={Math.random(i) + 'tcpc-year-headers'}>
          <td className={cellClasses} colSpan='2'>{y}</td>
          <td className='space-cell'></td>
        </React.Fragment>
      )
    })

    return (
      <tr key={Math.random(20)}>
        <td className="industry-copy-cell"></td>
        {formatedYearsRow}
      </tr>
    )
  }

  const buildTable = () => {
    return (
      <div className='industry-table-container'>
        <table style={{ whiteSpace: 'pre' }} className={tableClasses} >
          <thead>
            {renderYearHeaderColumns()}
          </thead>
          <tbody>
            {buildRows()}
            {buildNumOfCompaniesRow()}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <div className={`monthly-forecast-landscape report-card monthly-sheet`}>
        <div style={{ 'paddingTop': '10px', 'paddingLeft': '5px' }}>
          <h1 className="report-table-title hide-not-on-print">{props.title} - {pageTitle}</h1>
          <div className='pdf-table-div'>
            {props.page === 'money' && <p className='ind-table-info'>{`(As a % of ${titles[props.commonKey]} as Compared to Peers)`}</p>}
            {buildTable()}
          </div>
          <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
        </div>
      </div>
    </div>
  )
}

export default TableChartIndustry;
