// Calculations on Monthly Forecast for current ratio, gross profit, and operating profit
const ratioCalculations = (key, calcs) => {
    const ratio = {}
    let denominator;
    let numerator = _.pickBy(calcs[key], _.isNumber);
    const totalNumeratorMonths = Object.keys(numerator)
    const numberOfNumeratorMonths = totalNumeratorMonths.length

    if (key === 'total_current_assets') {
        denominator = _.pickBy(calcs.total_current_liabilities, _.isNumber)
    }
    if (key === 'gross_profit' || key === 'operating_profit') {
        denominator = _.pickBy(calcs.total_revenue, _.isNumber)
    }
    const totalDenominatorMonths = Object.keys(denominator)
    const numberOfDenominatorMonths = totalDenominatorMonths.length

    if (numberOfNumeratorMonths === numberOfDenominatorMonths) {
        for (let i = 1; i <= numberOfNumeratorMonths; i++) {
            let numeratorMonth = totalNumeratorMonths[i - 1]
            let denominatorMonth = totalDenominatorMonths[i - 1]
            if (numeratorMonth === denominatorMonth) {
                ratio[i] = (numerator[i] / denominator[i])
            } else {
                ratio[i] = null
            }
        }
    }

    return ratio
}

// ratioCalcs for Money report
const ratioCalculationsMoney = (key, calcs, previousYearCalcs, numberOfMonths) => {
    const ratio = {};
    let denominator;
    let numerator = sumTrailing12MonthsValues(calcs, previousYearCalcs, key)
    const totalNumeratorMonths = Object.keys(numerator)
    const numberOfNumeratorMonths = numberOfMonths

    if (calcs && previousYearCalcs && key === 'gross_profit' || key === 'operating_profit') {
        denominator = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_revenue')
    } else if (key === 'total_current_assets') {
        // denominator = _.pickBy(calcs.total_current_liabilities, _.isNumber)
        denominator = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_current_liabilities')
    }

    const totalDenominatorMonths = Object.keys(denominator)
    const numberOfDenominatorMonths = totalDenominatorMonths.length

    if (numberOfNumeratorMonths <= numberOfDenominatorMonths) {
        for (let i = 1; i <= numberOfNumeratorMonths; i++) {
            let numeratorMonth = totalNumeratorMonths[i - 1]
            let denominatorMonth = totalDenominatorMonths[i - 1]
            if (numeratorMonth === denominatorMonth) {
                ratio[i] = (numerator[i] / denominator[i])
            } else {
                ratio[i] = null
            }
        }
    }
    return ratio
}

// need to calculate EBITDA for forecast
const calculateEBITDA = (calcs) => {
    const ebitda = {}
    let netIncomeValues = _.pickBy(calcs.operating_expenses_net_income, _.isNumber)
    const monthsWithValues = Object.keys(netIncomeValues)
    const numberOfMonths = monthsWithValues.length

    for (let i = 1; i <= numberOfMonths; i++) {
        let numbers = []
        let sum = 0
        let netIncome = calcs.operating_expenses_net_income ? calcs.operating_expenses_net_income[i] : null
        let interest = calcs.interest_and_other_expenses ? calcs.interest_and_other_expenses[i] : null
        let taxes = calcs['total_other_costs_taxes_- federal & state'] ? calcs['total_other_costs_taxes_- federal & state'][i] : null
        let deprAmor = calcs.depreciation_and_amortization ? calcs.depreciation_and_amortization[i] : null

        ebitda[i] = (netIncome + interest + taxes + deprAmor)
    }

    return ebitda
}

// Do not have net_working_capital values on back end.
const netWorkingCapitalCalculation = (calcs) => {
    // Current Assets - Current Liabilities (Still use current calcs)
    // Need to be careful if client only has data for specific months
    const netWorkingCapitalRatio = {}
    const totalCurrentAssets = _.pickBy(calcs.total_current_assets, _.isNumber)
    const totalCurrentAssetsMonths = Object.keys(totalCurrentAssets)
    const numberOfTotalCurrentAssets = totalCurrentAssetsMonths.length
    const totalCurrentLiabilities = _.pickBy(calcs.total_current_liabilities, _.isNumber)
    const totalCurrentLiabilitiesMonths = Object.keys(totalCurrentLiabilities)
    const numberOfTotalCurrentLiabilities = totalCurrentLiabilitiesMonths.length

    if (numberOfTotalCurrentAssets === numberOfTotalCurrentLiabilities) {
        for (let i = 1; i <= numberOfTotalCurrentAssets; i++) {
            let totalCurrentAssetsMonth = totalCurrentAssetsMonths[i - 1]
            let totalCurrentLiabilitiesMonth = totalCurrentLiabilitiesMonths[i - 1]
            if (totalCurrentAssetsMonth === totalCurrentLiabilitiesMonth) {
                netWorkingCapitalRatio[i] = totalCurrentAssets[i] - totalCurrentLiabilities[i]
            } else {
                netWorkingCapitalRatio[i] = null
            }
        }
    }
    return netWorkingCapitalRatio // {1: 123, 2: 312, ...}
}

// The current net_working_capital_ratio values are wrong.
const netWorkingCapitalRatioCalculation = (calcs) => {
    // Current Assets - Current Liabilities (Still use current calcs)
    // Need to be careful if client only has data for specific months
    const netWorkingCapitalRatio = {}
    const totalCurrentAssets = _.pickBy(calcs.total_current_assets, _.isNumber)
    const totalCurrentAssetsMonths = Object.keys(totalCurrentAssets)
    const numberOfTotalCurrentAssets = totalCurrentAssetsMonths.length
    const totalCurrentLiabilities = _.pickBy(calcs.total_current_liabilities, _.isNumber)
    const totalCurrentLiabilitiesMonths = Object.keys(totalCurrentLiabilities)
    const numberOfTotalCurrentLiabilities = totalCurrentLiabilitiesMonths.length
    const totalAssets = _.pickBy(calcs.total_assets, _.isNumber);

    if (numberOfTotalCurrentAssets === numberOfTotalCurrentLiabilities) {
        for (let i = 1; i <= numberOfTotalCurrentAssets; i++) {
            let totalCurrentAssetsMonth = totalCurrentAssetsMonths[i - 1]
            let totalCurrentLiabilitiesMonth = totalCurrentLiabilitiesMonths[i - 1]
            if (totalCurrentAssetsMonth === totalCurrentLiabilitiesMonth && totalAssets[i]) {
                netWorkingCapitalRatio[i] = (totalCurrentAssets[i] - totalCurrentLiabilities[i]) / totalAssets[i]
            } else {
                netWorkingCapitalRatio[i] = null
            }
        }
    }

    return netWorkingCapitalRatio // {1: 123, 2: 312, ...}
}

// trailing12MonthCalculations gets called when one of the keys we need to calculate the sum of the trailing 12 month values for
const trailing12MonthCalculations = (key, calcs, previousYearCalcs) => {
    let calculatedValues;
    let calcKeys = calcs ? Object.keys(calcs) : [];
    let previousYearKeys = previousYearCalcs ? Object.keys(previousYearCalcs) : [];

    if (calcKeys.length > 0 && previousYearKeys.length > 0) {
        switch (key) {
            case 'Return on Assets (ROA)':
                calculatedValues = calcROAandROEandROCEandDE(key, calcs, previousYearCalcs);
                break;
            case 'Return on Equity (ROE)':
                calculatedValues = calcROAandROEandROCEandDE(key, calcs, previousYearCalcs);
                break;
            case 'Return on Capital Employed (ROCE)':
                calculatedValues = calcROAandROEandROCEandDE(key, calcs, previousYearCalcs);
                break;
            case 'Asset Turnover Ratio':
                calculatedValues = calcATRandARD(key, calcs, previousYearCalcs);
                break;
            case 'Inventory Turnover Ratio':
                calculatedValues = calcITR(calcs, previousYearCalcs);
                break;
            case 'Accounts Receivable Days':
                calculatedValues = calcATRandARD(key, calcs, previousYearCalcs);
                break;
            case 'Accounts Payable Days':
                calculatedValues = calcAPD(calcs, previousYearCalcs);
                break;
            case 'Debt / Equity':
                calculatedValues = calcROAandROEandROCEandDE(key, calcs, previousYearCalcs);
                break;
            case 'Senior Debt / EBITDA':
                calculatedValues = calcSDEandEAI(key, calcs, previousYearCalcs)
                break;
            case 'EBITDA / Annual Interest':
                calculatedValues = calcSDEandEAI(key, calcs, previousYearCalcs);
                break;
            case 'ROI':
                calculatedValues = calcROI(calcs, previousYearCalcs);
                break;
        }
    }
    return calculatedValues;
}

const getSumForEachMonth = (i, monthNumber, previousYearMonth, numberOfPreviousYearMonths, numberOfEnoughMonths, metrics = {}, previousYearMetrics = {}) => {

    if (numberOfPreviousYearMonths > numberOfEnoughMonths) {
        let monthValues = {}

        // getting previous year month values
        for (let j = i + 1; j <= 12; j++) {
            if (previousYearMonth !== monthNumber) {
                monthValues[j] = previousYearMetrics[j]
            }
        }
        // getting current year month values
        for (let k = i + 1; k > 0; k--) {
            monthValues[k] = metrics[k]
        }

        let values = Object.values(monthValues)
        let sumForMonth = values.reduce((a, b) => {
            let firstVal =  _.isNumber(a) ? a : 0;
            let secondVal = _.isNumber(b) ? b : 0;
            return (Number(firstVal.toFixed(2)) + Number(secondVal.toFixed(2)))
        })
        return sumForMonth
    }
}

const sumTrailing12MonthsValues = (calcs, previousYearCalcs, value) => {
    // We need to get 12 values for trailing 12 months for each month client has data for.
    // Each month is going to need a sum, save each new sum value for each month in a new object.
    // Client may not always have data starting at January of current year and we need to be careful about getting values for the right months.
    let sumsOfTrailing12Months = {} // { 1: 0.1906043449119196, 2: 0.16704260377198493, 3: 0.31016848516398315, 4: 0.21613961614104255, 5: 0.11732514571381171}
    let metrics
    let previousYearMetrics

    // Lodash method _.pickBy takes the object as the 1st argument and a function as the 2nd argument to select properties that satisfy the function.
    // _.isNumber checks if the value is a number or not
    // Removing proprties with null values from given object to find out if we will have a total of 12 months to use from the current and previous years.
    // Ex. calcs: { roa:{ 1: 1231, 2: 23131, 3: 213, 4: 0, 5: null, 6: null, 7: ... 12: 0}}
    // Ex. previousYearCalcs: { roa:{ 1: 120, 2: 23000, 3: 213, 4: 0, 5: 3141, 6: 234234, 7: ... 12: 0}}
    if (value === 'ebitda') {
        metrics = calcs
        previousYearMetrics = previousYearCalcs
    } else {
        metrics = _.pickBy(calcs[value], _.isNumber)
        previousYearMetrics = _.pickBy(previousYearCalcs[value], _.isNumber)
    }

    // check the length of arrays
    let currentYearMonths = Object.keys(metrics) // ['1', '2', '3', '4', '5']
    let previousYearMonths = Object.keys(previousYearMetrics) // ['1', '2', '3', '4', '5', ...]
    let numberOfCurrentYearMonths = currentYearMonths.length
    let numberOfPreviousYearMonths = previousYearMonths.length

    // Check if we have less than 12 month values from both years and just return empty object which will show no values in table.
    if (numberOfCurrentYearMonths + numberOfPreviousYearMonths < 12) {
        return sumsOfTrailing12Months
    }
    // Getting a sums for each month per metrics/key.
    // Ex. if metrics/key is Return on Assets (ROA), current year is 2020 and previous year is 2019
    // when month = December 2020 (or Current Year), we can add all the values in the current calcs and don't need previousYearCalcs
    // November 2020, Nov -> Jan 2020 + December 2019: calcs.roa(1-11) previousYearCalcs.roa (12)
    // Oct 2020, Oct -> Jan 2020 + Dec -> Nov 2019: calcs.roa(1-10) previousYearCalcs.roa (11-12)
    // Sept 2020, Sept -> Jan 2020 + Dec -> Oct 2019: calcs.roa(1-9) previousYearCalcs.roa (10-12)
    // Aug 2020, Aug -> Jan 2020 + Dec -> Sept 2019: calcs.roa(1-8) previousYearCalcs.roa (9-12)
    // Jul 2020, Jul -> Jan 2020 + Dec -> Aug 2019: calcs.roa(1-7) previousYearCalcs.roa (8-12)
    // Jun 2020, Jun -> Jan 2020 + Dec -> Jul 2019: calcs.roa(1-6) previousYearCalcs.roa (7-12)
    // May 2020, May -> Jan 2020 + Dec -> Jun 2019: calcs.roa(1-5) previousYearCalcs.roa (6-12)
    // Apr 2020, Apr -> Jan 2020 + Dec -> May 2019: calcs.roa(1-4) previousYearCalcs.roa (5-12)
    // Mar 2020, Mar -> Jan 2020 + Dec -> Apr 2019: calcs.roa(1-3) previousYearCalcs.roa (4-12)
    // Feb 2020, Feb -> Jan 2020 + Dec -> Mar 2019: calcs.roa(1-2) previousYearCalcs.roa (3-12)
    // Jan 2020, Jan 2020 + Dec -> Feb 2019: calcs.roa(1) previousYearCalcs.roa (2-12)
    // january 2020
    // We're going through the number of months in the current year.
    // For each month we check the number and the number of months from the previous year to make we have 12 trailing months.
    // We create a new object and in the inner loop we set previous year months in that object.
    // After the loop we set current year months to this object based on which month we have current year data for.
    // Then the values for that object are set equal to an array. 
    // The array is reduced to get the sum for the month we need and pushed into the final sums array.

    for (let i = 0; i < numberOfCurrentYearMonths; i++) {
        let currentYearMonth = currentYearMonths[i]
        let previousYearMonth = previousYearMonths[i]

        switch (currentYearMonth) {
            case '1':
                sumsOfTrailing12Months[1] = getSumForEachMonth(i, 1, previousYearMonth, numberOfPreviousYearMonths, 10, metrics, previousYearMetrics);
                break;
            case '2':
                sumsOfTrailing12Months[2] = getSumForEachMonth(i, 2, previousYearMonth, numberOfPreviousYearMonths, 9, metrics, previousYearMetrics);
                break;
            case '3':
                sumsOfTrailing12Months[3] = getSumForEachMonth(i, 3, previousYearMonth, numberOfPreviousYearMonths, 8, metrics, previousYearMetrics);
                break;
            case '4':
                sumsOfTrailing12Months[4] = getSumForEachMonth(i, 4, previousYearMonth, numberOfPreviousYearMonths, 7, metrics, previousYearMetrics);
                break;
            case '5':
                sumsOfTrailing12Months[5] = getSumForEachMonth(i, 5, previousYearMonth, numberOfPreviousYearMonths, 6, metrics, previousYearMetrics);
                break;
            case '6':
                sumsOfTrailing12Months[6] = getSumForEachMonth(i, 6, previousYearMonth, numberOfPreviousYearMonths, 5, metrics, previousYearMetrics);
                break;
            case '7':
                sumsOfTrailing12Months[7] = getSumForEachMonth(i, 7, previousYearMonth, numberOfPreviousYearMonths, 4, metrics, previousYearMetrics);
                break;
            case '8':
                sumsOfTrailing12Months[8] = getSumForEachMonth(i, 8, previousYearMonth, numberOfPreviousYearMonths, 3, metrics, previousYearMetrics);
                break;
            case '9':
                sumsOfTrailing12Months[9] = getSumForEachMonth(i, 9, previousYearMonth, numberOfPreviousYearMonths, 2, metrics, previousYearMetrics);
                break;
            case '10':
                sumsOfTrailing12Months[10] = getSumForEachMonth(i, 10, previousYearMonth, numberOfPreviousYearMonths, 1, metrics, previousYearMetrics);
                break;
            case '11':
                sumsOfTrailing12Months[11] = getSumForEachMonth(i, 11, previousYearMonth, numberOfPreviousYearMonths, 1, metrics, previousYearMetrics);
                break;
            case '12':
                sumsOfTrailing12Months[12] = getSumForEachMonth(i, 12, previousYearMonth, numberOfPreviousYearMonths, 0, metrics, previousYearMetrics);
                break;
        }
    }
    return sumsOfTrailing12Months;
}


const calcROI = (calcs, previousYearCalcs) => {
    const returnObject = {};
    if (calcs) {
        let sumTrailing12NetIncome = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'operating_expenses_net_income');
        let capitalStock = calcs.capital_stock;
        for (let month in sumTrailing12NetIncome) {
            if (capitalStock[month] !== 0) {
                // (last 12 months of operating_expenses_net_income) / capital stock
                returnObject[month] = sumTrailing12NetIncome[month] / capitalStock[month];
            }
        }
    }
    return returnObject;
}

// calculations for both return on assets and return on equity
// both use operating_expenses_net_income trailing 12 as a numerator
const calcROAandROEandROCEandDE = (key, calcs, previousYearCalcs) => {
    const returnObject = {};
    const currentYearTotalNetWorth = {};
    const denominatorROCE = {};
    const balanceSheetNetIncome = {};
    if (calcs) {
        let sumTrailing12NetIncome = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'operating_expenses_net_income');
        let sumTrailing12OperatingProfit = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'operating_profit');
        let currentYearTotalAssets = calcs.total_assets;
        let currentYearLongTermDebt = calcs.long_term_debt;
        let currentTotalLiabilities = calcs.total_current_liabilities;
        let currentLongTermDebt = calcs.long_term_debt;
        let nonTrailing12NetIcome = calcs.operating_expenses_net_income;
        // the balance sheet on Historic Monthly uses a different net income than the one from calcs.operating_expenses_net_income
        // the analysis table needs the cumulative version of net income which is why we are using the code below
        let nonTrailing12NetIcomeValues = Object.values(nonTrailing12NetIcome);
        let helperTotal = 0;
        nonTrailing12NetIcomeValues.forEach((value, index) => {
            if (value) {
                helperTotal += value;
                balanceSheetNetIncome[index + 1] = helperTotal;
            }
        })

        // month example looks like '1', '2', '3', ...
        for (let month in sumTrailing12NetIncome) {
            let capitalStock = calcs.capital_stock ? calcs.capital_stock[month] : 0
            let ownerDistributions = calcs.owner_distributions ? calcs.owner_distributions[month] : 0
            let retainedEarnings = calcs.retained_earnings ? calcs.retained_earnings[month] : 0
            currentYearTotalNetWorth[month] = capitalStock + ownerDistributions + retainedEarnings + balanceSheetNetIncome[month];
            denominatorROCE[month] = currentYearLongTermDebt[month] + currentYearTotalNetWorth[month];

            if (key === 'Return on Assets (ROA)' && currentYearTotalAssets[month] !== 0) {
                // (last 12 months of operating_expenses_net_income) / total_assets
                returnObject[month] = sumTrailing12NetIncome[month] / currentYearTotalAssets[month];
            } else if (key === 'Return on Equity (ROE)' && currentYearTotalNetWorth[month] !== 0) {
                // (last 12 months of operating_expenses_net_income) / (capital_stock + owner_distributions + retained_earnings)
                returnObject[month] = sumTrailing12NetIncome[month] / currentYearTotalNetWorth[month];
            } else if (key === 'Return on Capital Employed (ROCE)' && denominatorROCE[month] !== 0) {
                // (Last 12 months of Operating Profit) / (Long Term Debt + Net Worth)
                returnObject[month] = sumTrailing12OperatingProfit[month] / denominatorROCE[month];
            } else if (key === 'Debt / Equity' && currentYearTotalNetWorth[month] !== 0) {

                // (total_current_liabilities + long_term_debt / (Last 12 months of Operating Profit)
                returnObject[month] = (currentTotalLiabilities[month] + currentLongTermDebt[month]) / currentYearTotalNetWorth[month];
            }
        }
        return returnObject;
    }
    return returnObject;
}

// calculations for both Asset Turnover Ratio and Accounts Receivable Days
// both use trailing 12 total revenue
const calcATRandARD = (key, calcs, previousYearCalcs) => {
    const returnObject = {};
    if (calcs) {
        let sumTrailing12TotalRevenue = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_revenue');
        let currentYearTotalAssets = calcs.total_assets;
        let currentYearAcctsRec = calcs.accounts_receivable;
        // month example looks like '1', '2', '3', ...
        for (let month in sumTrailing12TotalRevenue) {
            if (key === 'Asset Turnover Ratio' && currentYearTotalAssets[month] !== 0) {
                // (last 12 months of total_revenue) / total_assets
                returnObject[month] = sumTrailing12TotalRevenue[month] / currentYearTotalAssets[month];
            } else if (key === 'Accounts Receivable Days' && sumTrailing12TotalRevenue[month] !== 0) {
                // ( 360 * accounts_receivable ) / (last 12 months of total_revenue)
                returnObject[month] = (360 * currentYearAcctsRec[month]) / sumTrailing12TotalRevenue[month];
            }
        }
        return returnObject;
    }
    return returnObject;
}

// Inventory Turnover Ratio
const calcITR = (calcs, previousYearCalcs) => {
    const returnObject = {};
    const denominator = {};
    if (calcs) {
        let sumTrailing12COGSMat = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'materials_costs');
        let currentYearInventory = calcs.inventory;
        let previousYearInventory = previousYearCalcs.inventory;

        for (let month in sumTrailing12COGSMat) {
            denominator[month] = (currentYearInventory[month] + previousYearInventory[month]) / 2;
            if (denominator[month] !== 0) {
                returnObject[month] = sumTrailing12COGSMat[month] / denominator[month];
            } else {
                returnObject[month] = 0;
            }
        }
        return returnObject
    }
    return returnObject
}

// Accounts Payable Days
const calcAPD = (calcs, previousYearCalcs) => {
    const returnObject = {};
    const denominator = {};
    if (calcs) {
        let sumCOGS = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_costs');
        let sumLaborCosts = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'labor_costs');
        let sumTotalOpEx = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_operating_expenses');
        let sumTotalTaxBenies = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_taxes_and_benefits');
        let sumTotalComp = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'total_compensation');
        let currentYearAccountsPayable = calcs.accounts_payable;

        for (let month in sumCOGS) {
            denominator[month] = sumCOGS[month] - sumLaborCosts[month] + sumTotalOpEx[month] - sumTotalTaxBenies[month] - sumTotalComp[month];
            if (denominator[month] !== 0) {
                returnObject[month] = (360 * currentYearAccountsPayable[month]) / denominator[month];
            }
        }
        return returnObject
    }
    return returnObject
}

// calculations for both Senior Debt / EBITDA and EBITDA / Annual Interest
// both use trailing 12 months of EBITDA
const calcSDEandEAI = (key, calcs, previousYearCalcs) => {
    const returnObject = {};
    if (calcs && previousYearCalcs) {
        let sumTrailing12Interest = sumTrailing12MonthsValues(calcs, previousYearCalcs, 'interest_and_other_expenses')
        let currentYearEBITDA = calculateEBITDA(calcs)
        let previousYearEBITDA = calculateEBITDA(previousYearCalcs)
        let sumTrailing12EBITDA = sumTrailing12MonthsValues(currentYearEBITDA, previousYearEBITDA, 'ebitda');
        let currentYearLOC = calcs.line_of_credit;
        let currentYearCurrentPortionLTD = calcs.current_portion_of_ltd;
        let currentYearLTD = calcs.long_term_debt;
        // month example looks like '1', '2', '3', ...
        for (let month in sumTrailing12EBITDA) {
            if (key === 'Senior Debt / EBITDA' && sumTrailing12EBITDA[month] !== 0) {
                // (long_term_debt + line_of_credit + current_portion_of_ltd) / (last 12 months of ebitda)
                returnObject[month] = (currentYearLOC[month] + currentYearCurrentPortionLTD[month] + currentYearLTD[month]) / sumTrailing12EBITDA[month];
            } else if (key === 'EBITDA / Annual Interest' && sumTrailing12Interest[month] !== 0) {
                // (last 12 months of ebitda) / (last 12 months of interest_and_other_expenses)
                returnObject[month] = sumTrailing12EBITDA[month] / sumTrailing12Interest[month];
            }
        }
        return returnObject;
    }
    return returnObject;
}

export { ratioCalculations, ratioCalculationsMoney, calculateEBITDA, netWorkingCapitalCalculation, netWorkingCapitalRatioCalculation, trailing12MonthCalculations, sumTrailing12MonthsValues };