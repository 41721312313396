const SurveyRadioAnswers = {
    satisfaction: {
        'Very Unsatisfied': 1,
        'Unsatisfied': 2,
        'Neutral': 3,
        'Satisfied': 4,
        'Very Satisfied': 5
    },
    growth: {
        'Big Decrease': 1,
        'Slight Decrease': 2,
        'Same': 3,
        'Slight Increase': 4,
        'Big Increase': 5
    },
    likelihood: {
        'Very Unlikely': 1,
        'Unlikely': 2,
        'Neutral': 3,
        'Likely': 4,
        'Very likely': 5
    },
    concern: {
        'Not At All Concerned': 1,
        'Neutral': 2,
        'Somewhat Concerned': 3,
        'Very Concerned': 4,
        'Extremely Concerned': 5
    },
    effectivity: {
        'Waste Of Time': 1,
        'Ineffective': 2,
        'Okay': 3,
        'Good': 4,
        'Very Effective': 5,
    },
    importance: {
        'Unimportant': 1,
        'Low Importance': 2,
        'Neutral': 3,
        'Important': 4,
        'Very Important': 5,
    },
    alignment: {
        'not_applicable': 1,
        'out_of_sync': 2,
        'not_so_close': 3,
        'close': 4,
        'in_sync': 5,
    },
    rating: {
        'not_applicable': 1,
        'need_improvement': 2,
        'neutral': 3,
        'good': 4,
        'very_good': 5,
    },
    growthPercentage: {
        'up_10_percent': 1,
        'up_20_percent': 2,
        'up_30_percent': 3,
        'up_40_percent': 4,
        'up_50_percent': 5,
    },
    optionalSatisfaction: {
        'not_applicable': 1,
        'unsatisfied': 2,
        'neutral': 3,
        'satisfied': 4,
        'very_satisfied': 5,
    },
    competition: {
        'Price': 1,
        'Quality': 2,
        'Compatibility': 3,
        'Timeliness': 4,
    },
    competitionChart: {
        competitive_advantages_price: 'Price',
        competitive_advantages_quality: 'Quality',
        competitive_advantages_compatibility: 'Compatibility',
        competitive_advantages_timeliness: 'Timeliness'
    },
    inBoundMarketing: {
        'email': 1,
        'social_media': 2,
        'seo': 3,
        'podcasts': 4,
        'blogs': 5,
        'videos': 6,
        'other': 7,
        'forums': 8,
    },
    outBoundMarketing: {
        'pay_per_click': 1,
        'display_and_banner_ads': 2,
        'daily_deals': 3,
        'sponsorships': 4,
        'direct_mail': 5,
        'telemarketing': 6,
        'offline_ads': 7,
    }
}

// const CoreSurvey = {
//     total_number_of_employees:                                   : yearly,
//     number_of_employees_who_left:                                : yearly,
//     total_number_of_customers:                                   : yearly,
//     percent_of_customers_that_are_new:                           : yearly,
//     percent_of_sales_from_the_top_50_percent:                   : yearly,
//     percent_of_sales_from_the_top_25_percent:                   : yearly,
//     percent_of_sales_from_the_top_10_percent:                   : yearly,
//     officers_compensation:                                      : yearly,
// }

// // One Year Expectations
// one_year_revenue: { type: radio, answers: SurveyRadioAnswers[growth] },
// one_year_margins: { type: radio, answers: SurveyRadioAnswers[growth] },
// one_year_profitability: { type: radio, answers: SurveyRadioAnswers[growth] },
// one_year_explanation: { type: text },
// // Three Year Expectations
// three_year_revenue: { type: radio, answers: SurveyRadioAnswers[growth] },
// three_year_margins: { type: radio, answers: SurveyRadioAnswers[growth] },
// three_year_profitability: { type: radio, answers: SurveyRadioAnswers[growth] },
// three_year_explanation: { type: text },

// reduce_debt: { type: radio, answers: SurveyRadioAnswers[likelihood] },
// leverage_new_debt: { type: radio, answers: SurveyRadioAnswers[likelihood] },
// hold_cash: { type: radio, answers: SurveyRadioAnswers[likelihood] },
// invest_cash: { type: radio, answers: SurveyRadioAnswers[likelihood] },
// reduce_indirect_costs: { type: radio, answers: SurveyRadioAnswers[likelihood] },
// reduce_direct_costs: { type: radio, answers: SurveyRadioAnswers[likelihood] },
// capital_expenditures: { type: radio, answers: SurveyRadioAnswers[likelihood] },

const SurveyCharts = {
    money: [
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'one_year_expectations',
            surveyKeys: ['one_year_revenue', 'one_year_margins', 'one_year_profitability'],
            categories: ['Revenue', 'Margins', 'Profitability'],
            title: 'One Year Expectations',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'one_year_explanation',
            surveyKeys: ['one_year_explanation'],
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'three_year_expectations',
            surveyKeys: ['three_year_revenue', 'three_year_margins', 'three_year_profitability'],
            categories: ['Revenue', 'Margins', 'Profitability'],
            title: 'Three Year Expectations',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'three_year_explanation',
            surveyKeys: ['three_year_explanation'],
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'priorities',
            surveyKeys: ['reduce_debt', 'leverage_new_debt', 'hold_cash', 'invest_cash', 'reduce_indirect_costs', 'reduce_direct_costs', 'capital_expenditures'],
            categories: ['Reduce debt', 'Leverage new debt', 'Hold cash', 'Invest cash', 'Reduce indirect costs', 'Reduce direct costs', 'Capital expenditure(s)'],
            title: 'Priorities',
            options: SurveyRadioAnswers['likelihood']
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'concerns',
            surveyKeys: ['increased_taxes', 'increased_regulation', 'lingering_economic_uncertainty', 'access_to_credit', 'interest_rates', 'increased_cost_of_labor', 'impact_of_healthcare_on_bottom_line', 'downward_pressure_on_prices', 'growth_and_profitability', 'wildcard_risks', 'increased_competition', 'other_concerns'],
            categories: ['Increased taxes', 'Increased regulation', 'Lingering economic uncertainty', 'Access to credit', 'Interest rates', 'Increased cost of labor', 'Impact of healthcare on bottom line', 'Downward pressure on prices', 'Growth & Profitability', 'Wildcard risks', 'Increased competition', 'Other'],
            title: 'Concerns',
            options: SurveyRadioAnswers['concern']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'any_significant_change_in_the_way_you_do_business',
            surveyKeys: ['any_significant_change_in_the_way_you_do_business'],
            title: 'If you anticipate any significant change in the way you do business, please explain:'
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'additional_comments_youd_like_to_make',
            surveyKeys: ['additional_comments_youd_like_to_make'],
            title: "If there are additional comments you'd like to make, please use the space provided:"
        },
    ],
    people: [
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'one_year_expectations',
            surveyKeys: ['one_year_number_of_employees', 'one_year_number_of_outside_contractors', 'one_year_costs', 'one_year_salaries', 'one_year_benefits'],
            categories: ['# of employees', '# of outside contractors', 'Costs', 'Salaries', 'Benefits'],
            title: 'One Year Expectations',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'one_year_explanation',
            surveyKeys: ['one_year_explanation'],
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'three_year_expectations',
            surveyKeys: ['one_year_number_of_employees', 'one_year_number_of_outside_contractors', 'one_year_costs', 'one_year_salaries', 'one_year_benefits'],
            categories: ['# of employees', '# of outside contractors', 'Costs', 'Salaries', 'Benefits'],
            title: 'Three Year Expectations',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'three_year_explanation',
            surveyKeys: ['three_year_explanation'],
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'priorities',
            surveyKeys: ['strategic_alignment_priorities', 'planning_and_policy_development_priorities', 'organizational_design_priorities', 'performance_management_priorities', 'wellness_priorities', 'human_capital_analytics_priorities', 'benefits_administration_priorities', 'increase_roi_of_human_capital_priorities', 'cuts_costs_priorities', 'employee_retention_priorities', 'employee_acquisition_priorities', 'outsourcing_priorities'],
            categories: ['Strategic alignment', 'Planning and policy development', 'Organizational design', 'Performance management', 'Wellness', 'Human capital analytics', 'Benefits administration', 'Increase ROI of human capital', 'Cuts costs', 'Employee retention', 'Employee acquisition', 'Outsourcing'],
            title: 'Priorities',
            options: SurveyRadioAnswers['importance']
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'concerns',
            surveyKeys: ['healthcare_costs_concerns', 'payroll_taxes_concerns', 'increased_regulation_concerns', 'ability_to_remain_competitive_concerns', 'employee_acquisition_concerns', 'employee_retention_concerns', 'succession_planning_and_bench_strength_concerns', 'economic_uncertainty_concerns', 'wildcard_risks_concerns', 'other_concerns'],
            categories: ['Healthcare costs', 'Payroll taxes', 'Increased regulation', 'Ability to remain competitive', 'Employee acquisition', 'Employee retention', 'Succession planning and bench strength', 'Economic uncertainty', 'Wildcard risks', 'Other'],
            title: 'Concerns',
            options: SurveyRadioAnswers['concern']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'any_significant_change_in_the_way_you_do_business',
            surveyKeys: ['any_significant_change_in_the_way_you_do_business'],
            title: 'If you anticipate any significant change in the way you do business, please explain:'
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'additional_comments_youd_like_to_make',
            surveyKeys: ['additional_comments_youd_like_to_make'],
            title: "If there are additional comments you'd like to make, please use the space provided:"
        },
    ],
    customers: [
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'one_year_expectations',
            surveyKeys: ['one_year_marketing_spend', 'one_year_marketing_roi', 'one_year_number_of_leads', 'one_year_number_of_customers'],
            categories: ['Marketing spend', 'Marketing ROI', '# of leads', '# of customers'],
            title: 'One Year Expectations',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'one_year_explanation',
            surveyKeys: ['one_year_explanation'],
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'three_year_expectations',
            surveyKeys: ['three_year_marketing_spend', 'three_year_marketing_roi', 'three_year_number_of_leads', 'three_year_number_of_customers'],
            categories: ['Marketing spend', 'Marketing ROI', '# of leads', '# of customers'],
            title: 'Three Year Expectations',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'three_year_explanation',
            surveyKeys: ['three_year_explanation'],
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'concerns',
            surveyKeys: ['targeting_the_right_customers', 'implementing_the_right_marketing_strategy', 'finding_the_optimal_marketing_mix', 'adopting_the_right_tools_and_marketing_technologies', 'improving_roi', 'improving_conversion_rates', 'seo_and_search_algorithm_changes', 'proliferation_of_new_tools_and_marketing_technologies', 'pressure_to_continuously_optimize_performance', 'ability_to_create_compelling_content', 'other_concerns'],
            categories: ['Targeting the right customers', 'Implementing the right marketing strategy', 'Finding the optimal marketing mix', 'Adopting the right tools and marketing technologies', 'Improving ROI', 'PImproving conversion rates', 'SEO/ability to effectively respond to changes to search algorithms', 'Proliferation of new tools/marketing technologies', 'Pressure to continuously optimize performance', 'Ability to create compelling content', 'Other'],
            title: 'Concerns',
            options: SurveyRadioAnswers['concern']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'other_concerns_explanation',
            surveyKeys: ['other_concerns_explanation'],
            title: `If 'other' please explain:`
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'customer_priorities',
            surveyKeys: ['acquisition', 'retention', 'reactivation'],
            categories: ['Acquisition', 'Retention', 'Reactivation'],
            title: 'Customer Priorities',
            options: SurveyRadioAnswers['importance']
        },
    ],
    future: [
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'satisfaction',
            surveyKeys: ['how_satisfied_are_you_with_your_companys_performance', 'how_satisfied_are_you_with_your_companys_management', 'how_satisfied_are_you_with_your_companys_workforce', 'how_satisfied_are_you_with_your_companys_ability_to_compete', 'how_satisfied_are_you_with_your_companys_products_services'],
            categories: [`Company's performance?`, `Company's management?`, `Company's workforce?`, `Company's ability to compete?`, `Company's products/services?`],
            title: 'Satisfaction',
            options: SurveyRadioAnswers['satisfaction']
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'five_year_expectations',
            surveyKeys: ['five_year_revenue', 'five_year_profitability', 'five_year_number_of_employees', 'five_year_op_ex', 'five_year_taxes', 'five_year_wages_and_salaries', 'five_year_benefits'],
            categories: ['Revenue', 'Profitability', '# of employees', 'OP-EX', 'Taxes', 'Wages & Salaries', 'Benefits'],
            title: 'Five Year Expectations (60+ months)',
            options: SurveyRadioAnswers['growth']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'five_year_explanation',
            title: 'Please explain, if necessary:'
        },
        {
            chartType: 'stackedBarRanking',
            format: 'percent',
            key: 'competition',
            surveyKeys: ['competitive_advantages_price', 'competitive_advantages_quality', 'competitive_advantages_compatibility', 'competitive_advantages_timeliness'],
            categories: ['Price', 'Quality', 'Compatibility', 'Timeliness'],
            title: 'Competition',
            surveyKeysConverted: SurveyRadioAnswers['competitionChart'],
            options: [4, 3, 2, 1]
        },
        {
            chartType: 'stackedBar',
            format: 'percent',
            key: 'growth',
            surveyKeys: ['new_markets', 'existing_markets', 'cost_reduction', 'technological_innovation', 'inflation'],
            categories: ['New markets', 'Existing markets', 'Cost reduction', 'Technological innovation', 'Inflation'],
            title: 'Source of Growth',
            options: SurveyRadioAnswers['likelihood']
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'any_significant_change_in_the_way_you_do_business',
            title: 'If you anticipate any significant change in the way you do business, please explain:'
        },
        {
            chartType: 'text',
            format: 'text',
            key: 'additional_comments_youd_like_to_make',
            title: "If there are additional comments you'd like to make, please use the space provided:"
        },
    ]
};
const getChartColors = (report, numberOfQuestions) => {
    let chartColors = [];
    let moneyChartColors = ['#2C491D', '#3C6629', '#46762E', '#579339', '#69B144', '#7EC05D', '#96CC7B', '#ADD798', '#B9DCA7', '#D0E8C5'];
    let peopleChartColors = ['#045677', '#06729D', '#0681B2', '#089DD9', '#00AEEF', '#1FC3FF', '#62CEF9', '#75D4FA', '#9DDFFB', '#C4ECFD'];
    let customerChartColors = ['#542667', '#6D3286', '#793795', '#9142B3', '#9B4CBD', '#AC6AC8', '#BC88D3', '#CDA6DE', '#D7B5E3', '#E7D3EE'];
    let futureChartColors = ['#BC3510', '#E14014', '#EB4A1E', '#EF6843', '#F17B5B', '#F38668', '#F4967C', '#F7B3A1', '#F8C3B4', '#FCE1DA'];
    let colors = report === 'Money' ? moneyChartColors : report === 'People' ? peopleChartColors : report === 'Customers' ? customerChartColors : futureChartColors;

    for (let i = 0; i < 10; i++) {
      if (numberOfQuestions < 6  && chartColors.length < numberOfQuestions) {
          if (i % 2 === 0) { 
            chartColors.push(colors[i])
          }
      } else if (chartColors.length < numberOfQuestions) {
          chartColors.push(colors[i])
      }
    }
    return chartColors
}

const previousYear = new Date().getFullYear() - 1
const surveyYears = [previousYear - 2, previousYear - 1, previousYear]
const MoneySurvey = (year, reportSurvey) => {
    let surveyYearss = [year - 2, year - 1, year]
    let surveyAnswers = reportSurvey["survey"]["answers"]
    let surveyNumbersAnswers = reportSurvey["number_year_inputs"]
    return {
        chartColors: ['#2C491D', '#46762E', '#69B144', '#96CC7B', '#B9DCA7'],
        sections: ['Expectations', 'Priorities', 'Concerns', 'Facilities', 'Additional Comments'],
        surveyLayoutData: {
            "Expectations": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Expectations (0-12 months)',
                        subtitle: 'One Year Expectations',
                        key: 'one_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        lockAnswers: true,
                        questions: [
                            {
                                key: 'one_year_revenue',
                                questionTitle: 'Revenue',
                                answer: surveyAnswers['one_year_revenue'] ? surveyAnswers['one_year_revenue'] : 3
                            },
                            {
                                key: 'one_year_margins',
                                questionTitle: 'Margin',
                                answer: surveyAnswers['one_year_margins'] ? surveyAnswers['one_year_margins'] : 3
                            },
                            {
                                key: 'one_year_profitability',
                                questionTitle: 'Profitability',
                                answer: surveyAnswers['one_year_profitability'] ? surveyAnswers['one_year_profitability'] : 3
                            },

                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'one_year_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: 'Please explain, if necessary:',
                        answer: surveyAnswers['one_year_explanation'] ? surveyAnswers['one_year_explanation'] : '',
                        lockAnswers: false,
                    },
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Expectations (13-36 months)',
                        subtitle: 'Three Year Expectations',
                        key: 'three_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        lockAnswers: true,
                        questions: [
                            {
                                key: 'three_year_revenue',
                                questionTitle: 'Revenue',
                                answer: surveyAnswers['three_year_revenue'] ? surveyAnswers['three_year_revenue'] : 3
                            },
                            {
                                key: 'three_year_margins',
                                questionTitle: 'Margin',
                                answer: surveyAnswers['three_year_margins'] ? surveyAnswers['three_year_margins'] : 3
                            },
                            {
                                key: 'three_year_profitability',
                                questionTitle: 'Profitability',
                                answer: surveyAnswers['three_year_profitability'] ? surveyAnswers['three_year_profitability'] : 3
                            },
                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'three_year_explanation',
                        inputType: 'text',
                        questionTitle: 'Please explain, if necessary:',
                        answer: surveyAnswers['three_year_explanation'] ? surveyAnswers['three_year_explanation'] : '',
                        lockAnswers: false,
                    }
                ]
            },
            "Priorities": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Priorities',
                        questionTitle: '',
                        subtitle: '',
                        key: 'priorities',
                        inputType: 'radio',
                        answerOptions: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'],
                        answerData: SurveyRadioAnswers['likelihood'],
                        lockAnswers: true,
                        questions: [
                            {
                                key: 'reduce_debt',
                                questionTitle: 'Reduce debt',
                                answer: surveyAnswers['reduce_debt'] ? surveyAnswers['reduce_debt'] : 3
                            },
                            {
                                key: 'leverage_new_debt',
                                questionTitle: 'Leverage new debt',
                                answer: surveyAnswers['leverage_new_debt'] ? surveyAnswers['leverage_new_debt'] : 3
                            },
                            {
                                key: 'hold_cash',
                                questionTitle: 'Hold cash',
                                answer: surveyAnswers['hold_cash'] ? surveyAnswers['hold_cash'] : 3
                            },
                            {
                                key: 'invest_cash',
                                questionTitle: 'Invest cash',
                                answer: surveyAnswers['invest_cash'] ? surveyAnswers['invest_cash'] : 3
                            },
                            {
                                key: 'reduce_indirect_costs',
                                questionTitle: 'Reduce indirect costs',
                                answer: surveyAnswers['reduce_indirect_costs'] ? surveyAnswers['reduce_indirect_costs'] : 3
                            },
                            {
                                key: 'reduce_direct_costs',
                                questionTitle: 'Reduce direct costs',
                                answer: surveyAnswers['reduce_direct_costs'] ? surveyAnswers['reduce_direct_costs'] : 3
                            },
                            {
                                key: 'capital_expenditures',
                                questionTitle: 'Capital expenditure(s)',
                                answer: surveyAnswers['capital_expenditures'] ? surveyAnswers['capital_expenditures'] : 3
                            },
                        ]
                    },
                ]
            },
            "Concerns": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Concerns',
                        questionTitle: '',
                        subtitle: '',
                        key: 'concerns',
                        inputType: 'radio',
                        answerOptions: ['Not At All Concerned', 'Neutral', 'Somewhat Concerned', 'Very Concerned', 'Extremely Concerned'],
                        answerData: SurveyRadioAnswers['concern'],
                        lockAnswers: true,
                        questions: [
                            {
                                key: 'increased_taxes',
                                questionTitle: 'Increased taxes',
                                answer: surveyAnswers['increased_taxes'] ? surveyAnswers['increased_taxes'] : 3
                            },
                            {
                                key: 'increased_regulation',
                                questionTitle: 'Increased regulation',
                                answer: surveyAnswers['increased_regulation'] ? surveyAnswers['increased_regulation'] : 3
                            },
                            {
                                key: 'lingering_economic_uncertainty',
                                questionTitle: 'Lingering economic uncertainty',
                                answer: surveyAnswers['lingering_economic_uncertainty'] ? surveyAnswers['lingering_economic_uncertainty'] : 3
                            },
                            {
                                key: 'access_to_credit',
                                questionTitle: 'Access to credit',
                                answer: surveyAnswers['access_to_credit'] ? surveyAnswers['access_to_credit'] : 3
                            },
                            {
                                key: 'interest_rates',
                                questionTitle: 'Interest rates',
                                answer: surveyAnswers['interest_rates'] ? surveyAnswers['interest_rates'] : 3
                            },
                            {
                                key: 'increased_cost_of_labor',
                                questionTitle: 'Increased cost of labor',
                                answer: surveyAnswers['increased_cost_of_labor'] ? surveyAnswers['increased_cost_of_labor'] : 3
                            },
                            {
                                key: 'impact_of_healthcare_on_bottom_line',
                                questionTitle: 'Impact of healthcare on bottom line',
                                answer: surveyAnswers['impact_of_healthcare_on_bottom_line'] ? surveyAnswers['impact_of_healthcare_on_bottom_line'] : 3
                            },
                            {
                                key: 'downward_pressure_on_prices',
                                questionTitle: 'Downward pressure on prices',
                                answer: surveyAnswers['downward_pressure_on_prices'] ? surveyAnswers['downward_pressure_on_prices'] : 3
                            },
                            {
                                key: 'growth_and_profitability',
                                questionTitle: 'Growth & Profitability',
                                answer: surveyAnswers['growth_and_profitability'] ? surveyAnswers['growth_and_profitability'] : 3
                            },
                            {
                                key: 'wildcard_risks',
                                questionTitle: 'Wildcard risks',
                                answer: surveyAnswers['wildcard_risks'] ? surveyAnswers['wildcard_risks'] : 3
                            },
                            {
                                key: 'increased_competition',
                                questionTitle: 'Increased competition',
                                answer: surveyAnswers['increased_competition'] ? surveyAnswers['increased_competition'] : 3
                            },
                            {
                                key: 'other_concerns',
                                questionTitle: 'Other',
                                answer: surveyAnswers['other_concerns'] ? surveyAnswers['other_concerns'] : 3
                            },
                        ]
                    },
                ]
            },
            "Facilities": {
                sectionData: [
                    {
                        section: 'Facilities',
                        key: 'number_facilities',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: 'How many individual plants, facilities, offices and/or locations do you have?',
                        answer: surveyNumbersAnswers["number_facilities"],
                        lockAnswers: false,
                    },
                    {
                        section: 'Facilities',
                        key: 'total_square_feet_facilities',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: 'What is the approximate combined square footage of all your individual plants, facilities, offices and/or locations?',
                        answer: surveyNumbersAnswers["total_square_feet_facilities"],
                        lockAnswers: false,
                    }
                ]
            },
            "Additional Comments": {
                sectionData: [
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Additional Comments',
                        key: 'any_significant_change_in_the_way_you_do_business',
                        inputType: 'textarea',
                        questionType: 'Text area',
                        questionTitle: 'If you anticipate any significant change in the way you do business, please explain:',
                        answer: surveyAnswers['any_significant_change_in_the_way_you_do_business'] ? surveyAnswers['any_significant_change_in_the_way_you_do_business'] : '',
                        lockAnswers: false,
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Additional Comments',
                        key: 'additional_comments_youd_like_to_make',
                        inputType: 'textarea',
                        questionType: 'Text area',
                        questionTitle: "If there are additional comments you'd like to make, please use the space provided:",
                        answer: surveyAnswers['additional_comments_youd_like_to_make'] ? surveyAnswers['additional_comments_youd_like_to_make'] : '',
                        lockAnswers: false,
                    }
                ]
            }
        }
    }
}

const PeopleSurvey = (year, reportSurvey) => {
    let surveyYearss = [year - 2, year - 1, year]
    let surveyAnswers = reportSurvey["survey"]["answers"]
    let surveyNumbersAnswers = reportSurvey["number_year_inputs"]
    return {
        chartColors: ['#045677', '#0681B2', '#00AEEF', '#62CEF9', '#9ddffb'],
        sections: ['Expectations', 'Priorities', 'Concerns', 'Headcount', 'Additional Comments'],
        surveyLayoutData: {
            "Expectations": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Expectations (0-12 months)',
                        subtitle: 'One Year Expectations',
                        key: 'one_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        questions: [
                            {
                                key: 'one_year_number_of_employees',
                                questionTitle: '# of employees',
                                answer: surveyAnswers['one_year_number_of_employees'] ? surveyAnswers['one_year_number_of_employees'] : 3
                            },
                            {
                                key: 'one_year_number_of_outside_contractors',
                                questionTitle: '# of outside contractors',
                                answer: surveyAnswers['one_year_number_of_outside_contractors'] ? surveyAnswers['one_year_number_of_outside_contractors'] : 3
                            },
                            {
                                key: 'one_year_costs',
                                questionTitle: 'Costs',
                                answer: surveyAnswers['one_year_costs'] ? surveyAnswers['one_year_costs'] : 3
                            },
                            {
                                key: 'one_year_salaries',
                                questionTitle: 'Salaries',
                                answer: surveyAnswers['one_year_salaries'] ? surveyAnswers['one_year_salaries'] : 3
                            },
                            {
                                key: 'one_year_benefits',
                                questionTitle: 'Benefits',
                                answer: surveyAnswers['one_year_benefits'] ? surveyAnswers['one_year_benefits'] : 3
                            }
                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'one_year_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: 'Please explain, if necessary:',
                        answer: surveyAnswers['one_year_explanation'] ? surveyAnswers['one_year_explanation'] : ''
                    },
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Expectations (13-36 months)',
                        subtitle: 'Three Year Expectations',
                        key: 'three_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        questions: [
                            {
                                key: 'three_year_number_of_employees',
                                questionTitle: '# of employees',
                                answer: surveyAnswers['three_year_number_of_employees'] ? surveyAnswers['three_year_number_of_employees'] : 3
                            },
                            {
                                key: 'three_year_number_of_outside_contractors',
                                questionTitle: '# of outside contractors',
                                answer: surveyAnswers['three_year_number_of_outside_contractors'] ? surveyAnswers['three_year_number_of_outside_contractors'] : 3
                            },
                            {
                                key: 'three_year_costs',
                                questionTitle: 'Costs',
                                answer: surveyAnswers['three_year_costs'] ? surveyAnswers['three_year_costs'] : 3
                            },
                            {
                                key: 'three_year_salaries',
                                questionTitle: 'Salaries',
                                answer: surveyAnswers['three_year_salaries'] ? surveyAnswers['three_year_salaries'] : 3
                            },
                            {
                                key: 'three_year_benefits',
                                questionTitle: 'Benefits',
                                answer: surveyAnswers['three_year_benefits'] ? surveyAnswers['three_year_benefits'] : 3
                            },

                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'three_year_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: 'Please explain, if necessary:',
                        answer: surveyAnswers['three_year_explanation'] ? surveyAnswers['three_year_explanation'] : ''
                    }
                ]
            },
            "Priorities": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Priorities',
                        questionTitle: '',
                        subtitle: '',
                        key: 'priorities',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Unimportant', 'Low Importance', 'Neutral', 'Important', 'Very Important'],
                        answerData: SurveyRadioAnswers['importance'],
                        questions: [
                            {
                                key: 'strategic_alignment_priorities',
                                questionTitle: 'Strategic alignment',
                                answer: surveyAnswers['strategic_alignment_priorities'] ? surveyAnswers['strategic_alignment_priorities'] : 3
                            },
                            {
                                key: 'planning_and_policy_development_priorities',
                                questionTitle: 'Planning and policy development',
                                answer: surveyAnswers['planning_and_policy_development_priorities'] ? surveyAnswers['planning_and_policy_development_priorities'] : 3
                            },
                            {
                                key: 'organizational_design_priorities',
                                questionTitle: 'Organizational design',
                                answer: surveyAnswers['organizational_design_priorities'] ? surveyAnswers['organizational_design_priorities'] : 3
                            },
                            {
                                key: 'performance_management_priorities',
                                questionTitle: 'Performance management',
                                answer: surveyAnswers['performance_management_priorities'] ? surveyAnswers['performance_management_priorities'] : 3
                            },
                            {
                                key: 'wellness_priorities',
                                questionTitle: 'Wellness',
                                answer: surveyAnswers['wellness_priorities'] ? surveyAnswers['wellness_priorities'] : 3
                            },
                            {
                                key: 'human_capital_analytics_priorities',
                                questionTitle: 'Human capital analytics',
                                answer: surveyAnswers['human_capital_analytics_priorities'] ? surveyAnswers['human_capital_analytics_priorities'] : 3
                            },
                            {
                                key: 'benefits_administration_priorities',
                                questionTitle: 'Benefits administration',
                                answer: surveyAnswers['benefits_administration_priorities'] ? surveyAnswers['benefits_administration_priorities'] : 3
                            },
                            {
                                key: 'increase_roi_of_human_capital_priorities',
                                questionTitle: 'Increase ROI of human capital',
                                answer: surveyAnswers['increase_roi_of_human_capital_priorities'] ? surveyAnswers['increase_roi_of_human_capital_priorities'] : 3
                            },
                            {
                                key: 'cuts_costs_priorities',
                                questionTitle: 'Cuts costs',
                                answer: surveyAnswers['cuts_costs_priorities'] ? surveyAnswers['cuts_costs_priorities'] : 3
                            },
                            {
                                key: 'employee_retention_priorities',
                                questionTitle: 'Employee retention',
                                answer: surveyAnswers['employee_retention_priorities'] ? surveyAnswers['employee_retention_priorities'] : 3
                            },
                            {
                                key: 'employee_acquisition_priorities',
                                questionTitle: 'Employee acquisition',
                                answer: surveyAnswers['employee_acquisition_priorities'] ? surveyAnswers['employee_acquisition_priorities'] : 3
                            },
                            {
                                key: 'outsourcing_priorities',
                                questionTitle: 'Outsourcing',
                                answer: surveyAnswers['outsourcing_priorities'] ? surveyAnswers['outsourcing_priorities'] : 3
                            },
                        ]
                    },
                ]
            },
            "Concerns": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Concerns',
                        questionTitle: '',
                        subtitle: '',
                        key: 'concerns',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Not At All Concerned', 'Neutral', 'Somewhat Concerned', 'Very Concerned', 'Extremely Concerned'],
                        answerData: SurveyRadioAnswers['concern'],
                        questions: [
                            {
                                key: 'healthcare_costs_concerns',
                                questionTitle: 'Healthcare costs',
                                answer: surveyAnswers['healthcare_costs_concerns'] ? surveyAnswers['healthcare_costs_concerns'] : 3
                            },
                            {
                                key: 'payroll_taxes_concerns',
                                questionTitle: 'Payroll taxes',
                                answer: surveyAnswers['payroll_taxes_concerns'] ? surveyAnswers['payroll_taxes_concerns'] : 3
                            },
                            {
                                key: 'increased_regulation_concerns',
                                questionTitle: 'Increased regulation',
                                answer: surveyAnswers['increased_regulation_concerns'] ? surveyAnswers['increased_regulation_concerns'] : 3
                            },
                            {
                                key: 'ability_to_remain_competitive_concerns',
                                questionTitle: 'Ability to remain competitive',
                                answer: surveyAnswers['ability_to_remain_competitive_concerns'] ? surveyAnswers['ability_to_remain_competitive_concerns'] : 3
                            },
                            {
                                key: 'employee_acquisition_concerns',
                                questionTitle: 'Employee acquisition',
                                answer: surveyAnswers['employee_acquisition_concerns'] ? surveyAnswers['employee_acquisition_concerns'] : 3
                            },
                            {
                                key: 'employee_retention_concerns',
                                questionTitle: 'Employee retention',
                                answer: surveyAnswers['employee_retention_concerns'] ? surveyAnswers['employee_retention_concerns'] : 3
                            },
                            {
                                key: 'succession_planning_and_bench_strength_concerns',
                                questionTitle: 'Succession planning and bench strength',
                                answer: surveyAnswers['succession_planning_and_bench_strength_concerns'] ? surveyAnswers['succession_planning_and_bench_strength_concerns'] : 3
                            },
                            {
                                key: 'economic_uncertainty_concerns',
                                questionTitle: 'Economic uncertainty',
                                answer: surveyAnswers['economic_uncertainty_concerns'] ? surveyAnswers['economic_uncertainty_concerns'] : 3
                            },
                            {
                                key: 'wildcard_risks_concerns',
                                questionTitle: 'Wildcard risks',
                                answer: surveyAnswers['wildcard_risks_concerns'] ? surveyAnswers['wildcard_risks_concerns'] : 3
                            },
                            {
                                key: 'other_concerns',
                                questionTitle: 'Other',
                                answer: surveyAnswers['other_concerns'] ? surveyAnswers['other_concerns'] : 3
                            },
                        ]
                    },
                ]
            },
            "Headcount": {
                sectionData: [
                    {
                        section: 'Headcount',
                        key: 'senior_management_count',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: `Total # Owner/Partners/Sr. Executives (President, Exec. V.P., CMO, etc.)`,
                        answer: surveyNumbersAnswers['senior_management_count']
                    },
                    {
                        section: 'Headcount',
                        key: 'total_number_of_employees',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: 'Total # of employees:',
                        answer: surveyNumbersAnswers['total_number_of_employees']
                    },
                    {
                        section: 'Headcount',
                        key: 'number_of_employees_who_left',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: `# of employees who left the company (either voluntarily or involuntarily)`,
                        answer: surveyNumbersAnswers['number_of_employees_who_left']
                    }
                ]
            },
            "Additional Comments": {
                sectionData: [
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Additional Comments',
                        key: 'any_significant_change_in_the_way_you_do_business',
                        inputType: 'textarea',
                        questionType: 'Text area',
                        questionTitle: 'If you anticipate any significant change in the way you do business, please explain:',
                        answer: surveyAnswers['any_significant_change_in_the_way_you_do_business'] ? surveyAnswers['any_significant_change_in_the_way_you_do_business'] : ''
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Additional Comments',
                        key: 'additional_comments_youd_like_to_make',
                        inputType: 'textarea',
                        questionType: 'Text area',
                        questionTitle: "If there are additional comments you'd like to make, please use the space provided:",
                        answer: surveyAnswers['additional_comments_youd_like_to_make'] ? surveyAnswers['additional_comments_youd_like_to_make'] : ''
                    }
                ]
            }
        }
    }
}

const CustomerSurvey = (year, reportSurvey) => {
    let surveyYearss = [year - 2, year - 1, year]
    let surveyAnswers = reportSurvey["survey"]["answers"]
    let surveyNumbersAnswers = reportSurvey["number_year_inputs"]
    return {
        chartColors: ['#542667', '#793795', '#9B4CBD', '#BC88D3', '#D7B5E3'],
        sections: ['Expectations', 'Concerns', 'Customer Priorities', 'Customer Concentration'],
        surveyLayoutData: {
            "Expectations": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Expectations (0-12 months)',
                        subtitle: 'One Year Expectations',
                        key: 'one_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        questions: [
                            {
                                key: 'one_year_marketing_spend',
                                questionTitle: 'Marketing spend',
                                answer: surveyAnswers['one_year_marketing_spend'] ? surveyAnswers['one_year_marketing_spend'] : 3
                            },
                            {
                                key: 'one_year_marketing_roi',
                                questionTitle: 'Marketing ROI',
                                answer: surveyAnswers['one_year_marketing_roi'] ? surveyAnswers['one_year_marketing_roi'] : 3
                            },
                            {
                                key: 'one_year_number_of_leads',
                                questionTitle: '# of leads',
                                answer: surveyAnswers['one_year_number_of_leads'] ? surveyAnswers['one_year_number_of_leads'] : 3
                            },
                            {
                                key: 'one_year_number_of_customers',
                                questionTitle: '# of customers',
                                answer: surveyAnswers['one_year_number_of_customers'] ? surveyAnswers['one_year_number_of_customers'] : 3
                            },
                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'one_year_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: 'Please explain, if necessary:',
                        answer: surveyAnswers['one_year_explanation'] ? surveyAnswers['one_year_explanation'] : ''
                    },
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Expectations (13-36 months)',
                        subtitle: 'Three Year Expectations',
                        key: 'three_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        questions: [
                            {
                                key: 'three_year_marketing_spend',
                                questionTitle: 'Marketing spend',
                                answer: surveyAnswers['three_year_marketing_spend'] ? surveyAnswers['three_year_marketing_spend'] : 3
                            },
                            {
                                key: 'three_year_marketing_roi',
                                questionTitle: 'Marketing ROI',
                                answer: surveyAnswers['three_year_marketing_roi'] ? surveyAnswers['three_year_marketing_roi'] : 3
                            },
                            {
                                key: 'three_year_number_of_leads',
                                questionTitle: '# of leads',
                                answer: surveyAnswers['three_year_number_of_leads'] ? surveyAnswers['three_year_number_of_leads'] : 3
                            },
                            {
                                key: 'three_year_number_of_customers',
                                questionTitle: '# of customers',
                                answer: surveyAnswers['three_year_number_of_customers'] ? surveyAnswers['three_year_number_of_customers'] : 3
                            },
                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'three_year_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: 'Please explain, if necessary:',
                        answer: surveyAnswers['three_year_explanation'] ? surveyAnswers['three_year_explanation'] : ''
                    }
                ]
            },
            "Concerns": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Concerns',
                        questionTitle: '',
                        key: 'concerns',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Not At All Concerned', 'Neutral', 'Somewhat Concerned', 'Very Concerned', 'Extremely Concerned'],
                        answerData: SurveyRadioAnswers['concern'],
                        questions: [
                            {
                                key: 'targeting_the_right_customers',
                                questionTitle: 'Targeting the right customers',
                                answer: surveyAnswers['targeting_the_right_customers'] ? surveyAnswers['targeting_the_right_customers'] : 3
                            },
                            {
                                key: 'implementing_the_right_marketing_strategy',
                                questionTitle: 'Implementing the right marketing strategy',
                                answer: surveyAnswers['implementing_the_right_marketing_strategy'] ? surveyAnswers['implementing_the_right_marketing_strategy'] : 3
                            },
                            {
                                key: 'finding_the_optimal_marketing_mix',
                                questionTitle: 'Finding the optimal marketing mix',
                                answer: surveyAnswers['finding_the_optimal_marketing_mix'] ? surveyAnswers['finding_the_optimal_marketing_mix'] : 3
                            },
                            {
                                key: 'adopting_the_right_tools_and_marketing_technologies',
                                questionTitle: 'Adopting the right tools and marketing technologies',
                                answer: surveyAnswers['adopting_the_right_tools_and_marketing_technologies'] ? surveyAnswers['adopting_the_right_tools_and_marketing_technologies'] : 3
                            },
                            {
                                key: 'improving_roi',
                                questionTitle: 'Improving ROI',
                                answer: surveyAnswers['improving_roi'] ? surveyAnswers['improving_roi'] : 3
                            },
                            {
                                key: 'improving_conversion_rates',
                                questionTitle: 'Improving conversion rates',
                                answer: surveyAnswers['improving_conversion_rates'] ? surveyAnswers['improving_conversion_rates'] : 3
                            },
                            {
                                key: 'seo_and_search_algorithm_changes',
                                questionTitle: 'SEO and ability to effectively respond to changes to search algorithms',
                                answer: surveyAnswers['seo_and_search_algorithm_changes'] ? surveyAnswers['seo_and_search_algorithm_changes'] : 3
                            },
                            {
                                key: 'proliferation_of_new_tools_and_marketing_technologies',
                                questionTitle: 'Proliferation of new tools and marketing technologies',
                                answer: surveyAnswers['proliferation_of_new_tools_and_marketing_technologies'] ? surveyAnswers['proliferation_of_new_tools_and_marketing_technologies'] : 3
                            },
                            {
                                key: 'pressure_to_continuously_optimize_performance',
                                questionTitle: 'Pressure to continuously optimize performance',
                                answer: surveyAnswers['pressure_to_continuously_optimize_performance'] ? surveyAnswers['pressure_to_continuously_optimize_performance'] : 3
                            },
                            {
                                key: 'ability_to_create_compelling_content',
                                questionTitle: 'Ability to create compelling content',
                                answer: surveyAnswers['ability_to_create_compelling_content'] ? surveyAnswers['ability_to_create_compelling_content'] : 3
                            },
                            {
                                key: 'other_concerns',
                                questionTitle: 'Other',
                                answer: surveyAnswers['other_concerns'] ? surveyAnswers['other_concerns'] : 3
                            },
                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Concerns',
                        key: 'other_concerns_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: `If 'other' please explain:`,
                        answer: surveyAnswers['other_concerns_explanation'] ? surveyAnswers['other_concerns_explanation'] : ''
                    },
                ]
            },
            "Customer Priorities": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Customer Priorities',
                        questionTitle: '',
                        subtitle: '',
                        key: 'customer_priorities',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Unimportant', 'Low Importance', 'Neutral', 'Important', 'Very Important'],
                        answerData: SurveyRadioAnswers['importance'],
                        questions: [
                            {
                                key: 'acquisition',
                                questionTitle: 'Acquisition',
                                answer: surveyAnswers['acquisition'] ? surveyAnswers['acquisition'] : 3
                            },
                            {
                                key: 'retention',
                                questionTitle: 'Retention',
                                answer: surveyAnswers['retention'] ? surveyAnswers['retention'] : 3
                            },
                            {
                                key: 'reactivation',
                                questionTitle: 'Reactivation',
                                answer: surveyAnswers['reactivation'] ? surveyAnswers['reactivation'] : 3
                            },
                        ]
                    },
                ]
            },
            "Customer Concentration": {
                sectionData: [
                    {
                        section: 'Customer Concentration',
                        key: 'total_number_of_customers',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: `Total number of customers`,
                        answer: surveyNumbersAnswers['total_number_of_customers']
                    },
                    {
                        section: 'Customer Concentration',
                        key: 'percent_of_customers_that_are_new',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: 'What percent of your customers are new each year?',
                        answer: surveyNumbersAnswers['percent_of_customers_that_are_new']
                    },
                    {
                        section: 'Customer Concentration',
                        key: 'percent_of_sales_from_the_top_10_percent',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: `What percent of your total sales come from the top 10 percent of your customers?`,
                        answer: surveyNumbersAnswers['percent_of_sales_from_the_top_10_percent']
                    },
                    {
                        section: 'Customer Concentration',
                        key: 'percent_of_sales_from_the_top_25_percent',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: `What percent of your total sales come from the top 25 percent of your customers?`,
                        answer: surveyNumbersAnswers['percent_of_sales_from_the_top_25_percent']
                    },
                    {
                        section: 'Customer Concentration',
                        key: 'percent_of_sales_from_the_top_50_percent',
                        inputType: 'numberYears',
                        questionType: 'Number Years Inputs',
                        questionTitle: `What percent of your total sales come from the top 50 percent of your customers?`,
                        answer: surveyNumbersAnswers['percent_of_sales_from_the_top_50_percent']
                    }
                ]
            },
        }
    }
}

// Formerly Planning Survey
const FutureSurvey = (reportSurvey) => {
    let surveyAnswers = reportSurvey["survey"]["answers"]
    return {
        chartColors: ['#BC3510', '#EB4A1E', '#f17b5b', '#F4967C', '#F8C3B4'],
        sections: ['Satisfaction', 'Expectations', 'Competition', 'Growth', 'Additional Comments'],
        surveyLayoutData: {
            "Satisfaction": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Satisfaction',
                        questionTitle: '',
                        subtitle: '',
                        key: 'satisfaction',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Very Unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'],
                        answerData: SurveyRadioAnswers['satisfaction'],
                        questions: [
                            {
                                key: 'how_satisfied_are_you_with_your_companys_performance',
                                questionTitle: `How satisfied are you with your company's performance?`,
                                answer: surveyAnswers['how_satisfied_are_you_with_your_companys_performance'] ? surveyAnswers['how_satisfied_are_you_with_your_companys_performance'] : 3
                            },
                            {
                                key: 'how_satisfied_are_you_with_your_companys_management',
                                questionTitle: `How satisfied are you with your company's management?`,
                                answer: surveyAnswers['how_satisfied_are_you_with_your_companys_management'] ? surveyAnswers['how_satisfied_are_you_with_your_companys_management'] : 3
                            },
                            {
                                key: 'how_satisfied_are_you_with_your_companys_workforce',
                                questionTitle: `How satisfied are you with your company's workforce?`,
                                answer: surveyAnswers['how_satisfied_are_you_with_your_companys_workforce'] ? surveyAnswers['how_satisfied_are_you_with_your_companys_workforce'] : 3
                            },
                            {
                                key: 'how_satisfied_are_you_with_your_companys_ability_to_compete',
                                questionTitle: `How satisfied are you with your company's ability to compete?`,
                                answer: surveyAnswers['how_satisfied_are_you_with_your_companys_ability_to_compete'] ? surveyAnswers['how_satisfied_are_you_with_your_companys_ability_to_compete'] : 3
                            },
                            {
                                key: 'how_satisfied_are_you_with_your_companys_products_services',
                                questionTitle: `How satisfied are you with your company's products/services?`,
                                answer: surveyAnswers['how_satisfied_are_you_with_your_companys_products_services'] ? surveyAnswers['how_satisfied_are_you_with_your_companys_products_services'] : 3
                            },
                        ]
                    }
                ]
            },
            "Expectations": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Expectations',
                        questionTitle: 'Five Year Expectations (60+ months)',
                        subtitle: '',
                        key: 'five_year_expectations',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                        answerData: SurveyRadioAnswers['growth'],
                        questions: [
                            {
                                key: 'five_year_revenue',
                                questionTitle: 'Revenue',
                                answer: surveyAnswers['five_year_revenue'] ? surveyAnswers['five_year_revenue'] : 3
                            },
                            {
                                key: 'five_year_profitability',
                                questionTitle: 'Profitability',
                                answer: surveyAnswers['five_year_profitability'] ? surveyAnswers['five_year_profitability'] : 3
                            },
                            {
                                key: 'five_year_number_of_employees',
                                questionTitle: '# of employees',
                                answer: surveyAnswers['five_year_number_of_employees'] ? surveyAnswers['five_year_number_of_employees'] : 3
                            },
                            {
                                key: 'five_year_op_ex',
                                questionTitle: 'OP-EX',
                                answer: surveyAnswers['five_year_op_ex'] ? surveyAnswers['five_year_op_ex'] : 3
                            },
                            {
                                key: 'five_year_taxes',
                                questionTitle: 'Taxes',
                                answer: surveyAnswers['five_year_taxes'] ? surveyAnswers['five_year_taxes'] : 3
                            },
                            {
                                key: 'five_year_wages_and_salaries',
                                questionTitle: 'Wages & Salaries',
                                answer: surveyAnswers['five_year_wages_and_salaries'] ? surveyAnswers['five_year_wages_and_salaries'] : 3
                            },
                            {
                                key: 'five_year_benefits',
                                questionTitle: 'Benefits',
                                answer: surveyAnswers['five_year_benefits'] ? surveyAnswers['five_year_benefits'] : 3
                            }
                        ]
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Expectations',
                        key: 'five_year_explanation',
                        inputType: 'text',
                        questionType: 'Text',
                        questionTitle: `Please explain, if necessary:`,
                        answer: surveyAnswers['five_year_explanation'] ? surveyAnswers['five_year_explanation'] : ''
                    },
                ]
            },
            "Competition": {
                sectionData: [
                    {
                        chartType: 'stackedBarRanking',
                        format: 'percent',
                        section: 'Competition',
                        questionTitle: 'Ability to Compete',
                        subtitle: '',
                        key: 'competitive_advantages',
                        inputType: 'ranking',
                        questionType: 'Ranking',
                        answerOptions: [1, 2, 3, 4],
                        answerData: {
                            1: 1, 
                            2: 2, 
                            3: 3,
                            4: 4
                        },
                        questions: [
                            {
                                key: 'competitive_advantages_price',
                                questionTitle: 'Price',
                                answer: surveyAnswers['competitive_advantages_price'] ? surveyAnswers['competitive_advantages_price'] : 1
                            },
                            {
                                key: 'competitive_advantages_quality',
                                questionTitle: 'Quality',
                                answer: surveyAnswers['competitive_advantages_quality'] ? surveyAnswers['competitive_advantages_quality'] : 2
                            },
                            {
                                key: 'competitive_advantages_compatibility',
                                questionTitle: 'Compatibility',
                                answer: surveyAnswers['competitive_advantages_compatibility'] ? surveyAnswers['competitive_advantages_compatibility'] : 3
                            },
                            {
                                key: 'competitive_advantages_timeliness',
                                questionTitle: 'Timeliness',
                                answer: surveyAnswers['competitive_advantages_timeliness'] ? surveyAnswers['competitive_advantages_timeliness'] : 4
                            },
                        ]
                    }
                ]
            },
            "Growth": {
                sectionData: [
                    {
                        chartType: 'stackedBar',
                        format: 'percent',
                        section: 'Growth',
                        questionTitle: 'Source of Growth',
                        subtitle: 'In the future, additional revenues will most likely come from which of the following:',
                        key: 'growth',
                        inputType: 'radio',
                        questionType: 'Group Questions',
                        answerOptions: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'],
                        answerData: SurveyRadioAnswers['likelihood'],
                        questions: [
                            {
                                key: 'new_markets',
                                questionTitle: 'New markets',
                                answer: surveyAnswers['new_markets'] ? surveyAnswers['new_markets'] : 3
                            },
                            {
                                key: 'existing_markets',
                                questionTitle: 'Existing markets',
                                answer: surveyAnswers['existing_markets'] ? surveyAnswers['existing_markets'] : 3
                            },
                            {
                                key: 'cost_reduction',
                                questionTitle: 'Cost reduction',
                                answer: surveyAnswers['cost_reduction'] ? surveyAnswers['cost_reduction'] : 3
                            },
                            {
                                key: 'technological_innovation',
                                questionTitle: 'Technological innovation',
                                answer: surveyAnswers['technological_innovation'] ? surveyAnswers['technological_innovation'] : 3
                            },
                            {
                                key: 'inflation',
                                questionTitle: 'Inflation',
                                answer: surveyAnswers['inflation'] ? surveyAnswers['inflation'] : 3
                            }
                        ]
                    }
                ]
            },
            "Additional Comments": {
                sectionData: [
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Additional Comments',
                        key: 'any_significant_change_in_the_way_you_do_business',
                        inputType: 'textarea',
                        questionType: 'Text area',
                        questionTitle: 'If you anticipate any significant change in the way you do business, please explain:',
                        answer: surveyAnswers['any_significant_change_in_the_way_you_do_business'] ? surveyAnswers['any_significant_change_in_the_way_you_do_business'] : ''
                    },
                    {
                        chartType: 'text',
                        format: 'text',
                        section: 'Additional Comments',
                        key: 'additional_comments_youd_like_to_make',
                        inputType: 'textarea',
                        questionType: 'Text area',
                        questionTitle: "If there are additional comments you'd like to make, please use the space provided:",
                        answer: surveyAnswers['additional_comments_youd_like_to_make'] ? surveyAnswers['additional_comments_youd_like_to_make'] : ''
                    }
                ]
            }
        }
    }
}

export { SurveyCharts, SurveyRadioAnswers, getChartColors, MoneySurvey, PeopleSurvey, CustomerSurvey, FutureSurvey };
