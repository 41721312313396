import React from 'react';
import ReactDOM from 'react-dom';

const FduSelect = ({ list, path }) => {

  function selectFdu ({ target }) {
    window.location.href = pathifier(target.value)
  }

  function pathifier(id) {
    const split = path.split(':');
    const split2 = split[1].split('/');
    split2[0] = id;
    const join2 = split2.join('/');
    split[1] = join2;
    return split.join('');
  }

  return(
    <div className='select-document'>
      <div id='fdu-select' className='pull-right'>
        <h3>Document:</h3>
        <select onChange={selectFdu} >
          {list.map(fdu => (
            <option key={fdu[1]} value={fdu[1]}>{fdu[0]}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default FduSelect;
