import React, { useState } from 'react';

const IntacctLogin = (props) => {
    const [loginData, setLoginData] = useState({
        connected_to_intacct: props.info.connected_to_intacct,
        company_id: props.info.si_company_id ? props.info.si_company_id : '',
        entity_id: props.info.si_entity_id ? props.info.si_entity_id : '',
        si_entity: false,
        user_id: props.info.si_user_id ? props.info.si_user_id : '',
        password: props.info.si_password ? props.info.si_password : '',
        error: ''
    })

    const handleIntactLoginForm = (type, value = '') => {
        setLoginData((prevState) => {
            switch (type) {
                case 'company_id':
                    return {
                        ...prevState,
                        company_id: value,
                        error: value ? '' : 'Please enter your Sage Intacct Company ID.'
                    }
                    break;
                case 'entity_id':
                    return {
                        ...prevState,
                        entity_id: value,
                        error: value ? '' : 'Please enter the Entity ID.'
                    }
                    break;
                case 'si_entity':
                    return {
                        ...prevState,
                        si_entity: !loginData["si_entity"]
                    }
                    break;
                case 'user_id':
                    return {
                        ...prevState,
                        user_id: value,
                        error: value ? '' : 'Please enter your Sage Intacct User ID.'
                    }
                    break;
                case 'password':
                    return {
                        ...prevState,
                        password: value,
                        error: value ? '' : 'Please enter your Sage Intacct Password.'
                    }
                    break;
                default:
                    break;
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await fetch(`/intacct_connect`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pvd_user_id: props.info.user_id,
                    pvd_company_id: props.info.company_id,
                    si_company_id: loginData.company_id,
                    si_entity_id: loginData.entity_id,
                    si_entity: loginData.si_entity,
                    si_user_id: loginData.user_id,
                    si_password: loginData.password
                }),
            })
            const resJson = await res.json();

            if (resJson.success) {
                window.location.href = resJson.redirect_to
            } else if (resJson.error) {
                setLoginData(prevState => {
                    return {
                        ...prevState,
                        error: resJson.error
                    }
                })
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }

    const handleIntacctDisconnect = async () => {
        const resDisconnect = await fetch(`/intacct_disconnect`, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': window.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: props.info.company_id,
            }),
        })
        const resJson = await resDisconnect.json()

        if (resJson.error) {
            alert(resJson.error)
        } else if (resJson.success) {
            alert(resJson.success)
            setLoginData(prevState => {
                return {
                    ...prevState,
                    connected_to_intacct: false
                }
            })
        }
    }

    return (
        <div className='intacct-login-container'>
            <h1>Connecting {props.info.company_name} to Sage Intacct</h1>
            <h2>Enter the Correct Company ID you want to use from your Sage Intacct account</h2>
            <h2 className='h2-second-line'>and Your Sage Intacct Login Information</h2>
            <p>If you forget your password or any of your login information please change it at Sage Intacct's website here: <a href='https://www.intacct.com/ia/acct/login.phtml' target='_blank'>Sage Intacct Login Page</a></p>
            <p>To connect an Entity you will need its ID. In Sage Intacct, it can be found by going to their Dropdown Menu:</p>
            <p>Locate <b>Company</b> -> <b>Setup</b> -> <b>Configuration</b> -> Click on <b>Entities</b></p>
            <p className='intacct-form-message error-message'>{loginData.connected_to_intacct ? 'This company is already connected to Sage Intacct. Please disconnect it if you want to reconnect it again.' : ''}</p>
            {loginData.connected_to_intacct ? <a className='sage-intacct-btn' onClick={() => handleIntacctDisconnect()}>Disconnect from Sage Intacct</a> : null}
            <p className='intacct-form-message error-message'>{loginData['error']}</p>
            <form id='intacct-login-form' onSubmit={(e) => handleSubmit(e)}>
                <h3>Sage Intacct</h3>
                <div>
                    <div className='form-input-group'>
                        <label htmlFor='company_id'>Company ID</label>
                        <input name='company_id' type='text' required value={loginData['company_id']} onChange={(e) => handleIntactLoginForm('company_id', e.target.value)} />
                    </div>
                    <div className='form-input-checkbox'>
                        <span>Do you want to connect an Entity?</span>
                        <div className='checkbox-option'>
                            <label htmlFor='si_entity_yes'>Yes</label>
                            <input name='si_entity_yes' type='checkbox' checked={loginData['si_entity'] == true} onChange={() => handleIntactLoginForm('si_entity')} />
                        </div>
                        <div className='checkbox-option'>
                            <label htmlFor='si_entity_no'>No</label>
                            <input name='si_entity_no' type='checkbox' checked={loginData['si_entity'] == false} onChange={() => handleIntactLoginForm('si_entity')} />
                        </div>
                    </div>
                    {loginData['si_entity']
                        ? <div className='form-input-group'>
                            <label htmlFor='entity_id'>Entity ID</label>
                            <input name='entity_id' type='text' required value={loginData['entity_id']} onChange={(e) => handleIntactLoginForm('entity_id', e.target.value)} />
                        </div>
                        : null}
                    <div className='form-input-group'>
                        <label htmlFor='user_id'>User ID</label>
                        <input name='user_id' type='text' required value={loginData['user_id']} onChange={(e) => handleIntactLoginForm('user_id', e.target.value)} />
                    </div>
                    <div className='form-input-group'>
                        <label htmlFor='password'>Password</label>
                        <input name='password' type='password' required value={loginData['password']} onChange={(e) => handleIntactLoginForm('password', e.target.value)} />
                    </div>
                </div>
                <button type='submit'>Login & Connect</button>
            </form>
        </div>
    )
}

export default IntacctLogin;