const padding = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'

let financialDetailRows = [
    {
        copy: 'Revenue',
        header: false,
        key: 'total_revenue',
        relatedCheckedKey: 'total_revenue',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        layout: [
            { chartType: 'editableChart', key: 'total_revenue_growth_rate', copy: 'Total Revenue Growth Rate Chart', checked: true, relatedCheckedKey: 'total_revenue' },
            { key: 'total_revenue_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'total_revenue' },
            { key: 'total_revenue_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'total_revenue' }
        ]
    },
    {
        copy: 'Gross Profit',
        header: false,
        key: 'gross_profit',
        relatedCheckedKey: 'gross_profit',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        percentOfRevenueKey: 'total_costs',
        layout: [
            { key: 'gross_margin', copy: 'Gross Profit Margin Chart', checked: true, relatedCheckedKey: 'gross_profit', percentOfRevenueKey: 'total_costs' },
            { key: 'gross_profit_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'gross_profit' },
            { key: 'gross_profit_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'gross_profit' }
        ]
    },
    {
        copy: 'Operating Profit',
        header: false,
        key: 'operating_profit',
        relatedCheckedKey: 'operating_profit',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        layout: [
            { key: 'operating_profit_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'operating_profit' },
            { key: 'operating_profit_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'operating_profit' },
            { key: 'operating_profit_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'operating_profit' }
        ]
    },
    {
        copy: 'Net Income',
        header: false,
        key: 'operating_expenses_net_income',
        relatedCheckedKey: 'operating_expenses_net_income',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        percentOfRevenueKey: 'operating_expenses_net_income',
        layout: [
            { key: 'profit_margin', copy: 'Net Profit Margin Chart', checked: true, relatedCheckedKey: 'operating_expenses_net_income', percentOfRevenueKey: 'operating_expenses_net_income' },
            { key: 'operating_expenses_net_income_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'operating_expenses_net_income' },
            { key: 'operating_expenses_net_income_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'operating_expenses_net_income' }
        ]
    },
    {
        copy: padding,
        header: true,
        key: 'empty_finanical_detail',
        sheet: 'financial_detail'
    },
    {
        copy: 'Sales & Marketing',
        header: false,
        key: 'total_customer_costs_sales_& marketing',
        relatedCheckedKey: 'total_customer_costs_sales_& marketing',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        layout: [
            { key: 'total_customer_costs_sales_& marketing_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'total_customer_costs_sales_& marketing' },
            { key: 'total_customer_costs_sales_& marketing_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'total_customer_costs_sales_& marketing' },
            { key: 'total_customer_costs_sales_& marketing_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'total_customer_costs_sales_& marketing' }
        ]
    },
    {
        copy: 'Travel, Entertainment & Meals',
        header: false,
        key: 'travel_entertainment_and_meals',
        relatedCheckedKey: 'travel_entertainment_and_meals',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        layout: [
            { key: 'travel_entertainment_and_meals_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'travel_entertainment_and_meals' },
            { key: 'travel_entertainment_and_meals_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'travel_entertainment_and_meals' },
            { key: 'travel_entertainment_and_meals_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'travel_entertainment_and_meals' }
        ]
    },
    {
        copy: 'Professional Fees - Customers',
        header: false,
        key: 'customers_misc_professional_fees',
        relatedCheckedKey: 'customers_misc_professional_fees',
        checked: false,
        sheet: 'financial_detail',
        displayChart: true,
        format: 'percent',
        layout: [
            { key: 'customers_misc_professional_fees_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'customers_misc_professional_fees' },
            { key: 'customers_misc_professional_fees_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'customers_misc_professional_fees' },
            { key: 'customers_misc_professional_fees_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'customers_misc_professional_fees' }
        ]
    },
]

const customersKPIsFormat = (displayColsBy = '', reportPeriod = '') => {
    let trailing12Abbr = '';
    let monthOverMonthAbbr = '';

    if (displayColsBy === 'Months' || reportPeriod === 'Year to Date') {
        trailing12Abbr = '(T12)';
        monthOverMonthAbbr = '(MoM)'
    }

    return [
        {
            key: 'analysis_per_client_title',
            copy: `Analysis per Client ${trailing12Abbr}`,
            format: 'header',
            header: true,
            display: true,
            sheet: 'kpi'
        },
        {
            key: 'total_revenue',
            relatedCheckedKey: 'total_revenue',
            copy: 'Revenue',
            format: 'money',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { chartType: 'editableChart', key: 'total_revenue_growth_rate', copy: 'Total Revenue Growth Rate Chart', checked: true, relatedCheckedKey: 'total_revenue' },
                { key: 'total_revenue_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'total_revenue' },
                { key: 'total_revenue_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'total_revenue' }
            ]
        },
        {
            key: 'revenue_per_customer',
            relatedCheckedKey: 'revenue_per_customer',
            copy: 'Revenue Per Client',
            format: 'money',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'revenue_per_customer_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'revenue_per_customer' },
                { key: 'revenue_per_customer_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'revenue_per_customer' },
                { key: 'revenue_per_customer_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'revenue_per_customer' }
            ]
        },
        {
            key: 'gross_profit',
            relatedCheckedKey: 'gross_profit',
            copy: 'Gross Profit',
            format: 'money',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'gross_margin', copy: 'Gross Profit Margin Chart', checked: true, relatedCheckedKey: 'gross_profit' },
                { key: 'gross_profit_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'gross_profit' },
                { key: 'gross_profit_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'gross_profit' }
            ]
        },
        {
            key: 'gross_profit_per_customer',
            relatedCheckedKey: 'gross_profit_per_customer',
            copy: 'Gross Profit Per Client',
            format: 'money',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'gross_profit_per_customer_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'gross_profit_per_customer' },
                { key: 'gross_profit_per_customer_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'gross_profit_per_customer' },
                { key: 'gross_profit_per_customer_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'gross_profit_per_customer' }
            ]
        },
        {
            key: 'operating_expenses_net_income',
            relatedCheckedKey: 'operating_expenses_net_income',
            copy: 'Net Profit',
            format: 'money',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'profit_margin', copy: 'Net Profit Margin Chart', checked: true, relatedCheckedKey: 'operating_expenses_net_income' },
                { key: 'operating_expenses_net_income_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'operating_expenses_net_income' },
                { key: 'operating_expenses_net_income_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'operating_expenses_net_income' }
            ]
        },
        {
            key: 'operating_expenses_net_income_per_customer',
            relatedCheckedKey: 'operating_expenses_net_income_per_customer',
            copy: 'Net Profit Per Client',
            format: 'money',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'operating_expenses_net_income_per_customer_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'operating_expenses_net_income_per_customer' },
                { key: 'operating_expenses_net_income_per_customer_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'operating_expenses_net_income_per_customer' },
                { key: 'operating_expenses_net_income_per_customer_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'operating_expenses_net_income_per_customer' }
            ]
        },
        {
            key: 'empty_row_1',
            copy: padding,
            format: 'header',
            display: true,
            sheet: 'kpi'
        },
        {
            key: 'marketing_spend_roi_title',
            relatedCheckedKey: 'marketing_spend_roi_title',
            copy: 'Marketing Spend/ROI',
            format: 'header',
            header: true,
            display: true,
            sheet: 'kpi',
        },
        {
            key: 'total_customer_costs_common_size_revenue',
            relatedCheckedKey: 'total_customer_costs_common_size_revenue',
            copy: 'Marketing Spend',
            format: 'percent',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'total_customer_costs_common_size_revenue_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'total_customer_costs_common_size_revenue' },
                { key: 'total_customer_costs_common_size_revenue_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'total_customer_costs_common_size_revenue' },
                { key: 'total_customer_costs_common_size_revenue_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'total_customer_costs_common_size_revenue' }
            ]
        },
        {
            key: 'customer_roi',
            relatedCheckedKey: 'customer_roi',
            copy: `Marketing ROI ${monthOverMonthAbbr}`,
            format: 'percent',
            display: true,
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'customer_roi_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'customer_roi' },
                { key: 'customer_roi_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'customer_roi' },
                { key: 'customer_roi_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'customer_roi' }
            ]
        },
        {
            key: 'number_of_new_customers_needed_to_maintain_growth',
            relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth',
            copy: 'Growth Requirements',
            format: 'wholeNumber',
            display: false,
            checked: true,
            padding: padding,
            displayChart: true,
            sheet: 'kpi',
            layout: [
                { key: 'number_of_new_customers_needed_to_maintain_growth_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth' },
                { key: 'number_of_new_customers_needed_to_maintain_growth_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth' },
                { key: 'number_of_new_customers_needed_to_maintain_growth_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'number_of_new_customers_needed_to_maintain_growth' }
            ]
        },
        {
            key: 'empty_row_2',
            copy: padding,
            format: 'header',
            display: true,
            sheet: 'kpi'
        },
        {
            key: 'customer_concentration_title',
            copy: 'Customer Concentration',
            format: 'header',
            header: true,
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            sheet: 'kpi'
        },
        {
            key: 'percent_of_sales_from_the_top_10_percent',
            relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent',
            copy: 'Top 10%',
            format: 'percent',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'percent_of_sales_from_the_top_10_percent_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent' },
                { key: 'percent_of_sales_from_the_top_10_percent_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent' },
                { key: 'percent_of_sales_from_the_top_10_percent_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'percent_of_sales_from_the_top_10_percent' }
            ]
        },
        {
            key: 'percent_of_sales_from_the_top_25_percent',
            relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent',
            copy: 'Top 25%',
            format: 'percent',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'percent_of_sales_from_the_top_25_percent_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent' },
                { key: 'percent_of_sales_from_the_top_25_percent_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent' },
                { key: 'percent_of_sales_from_the_top_25_percent_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'percent_of_sales_from_the_top_25_percent' }
            ]
        },
        {
            key: 'percent_of_sales_from_the_top_50_percent',
            relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent',
            copy: 'Top 50%',
            format: 'percent',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            checked: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: true,
            layout: [
                { key: 'percent_of_sales_from_the_top_50_percent_chart', copy: 'Chart', checked: true, relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent' },
                { key: 'percent_of_sales_from_the_top_50_percent_impact_box', copy: 'Impact Box', checked: true, relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent' },
                { key: 'percent_of_sales_from_the_top_50_percent_notes', copy: 'Notes', checked: false, relatedCheckedKey: 'percent_of_sales_from_the_top_50_percent' }
            ]
        },
        {
            key: 'empty_row_3',
            copy: padding,
            format: 'header',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            sheet: 'kpi'
        },
        {
            key: 'client_data_title',
            copy: 'Client Data',
            format: 'header',
            header: true,
            display: true,
            sheet: 'kpi',
            displayChart: false
        },
        {
            key: 'total_number_of_customers',
            relatedCheckedKey: 'total_number_of_customers',
            copy: 'Number of Clients',
            format: 'roundedDecimal',
            display: true,
            padding: padding,
            sheet: 'kpi',
            displayChart: false
        },
    ]
}

const updateCustomersRows = (selectedItems, customerRows) => {
    customerRows.forEach((row, i) => {
        let selectedIndex = selectedItems.findIndex(item => item.key === row.key || item.relatedCheckedKey === row.key)

        if (selectedIndex > -1) {
            customerRows[i]['checked'] = selectedItems[selectedIndex]['checked']

            customerRows[i]['layout'].forEach((child, j) => {
                if (customerRows[i]['layout'] && selectedItems[selectedIndex]['layout']) {
                    let selectedChilds = selectedItems[selectedIndex]['layout'].filter(l => l.checked)
                    selectedChilds.forEach(s => {
                        if (child.key === s.key) {
                            customerRows[i]['layout'][j]['checked'] = true
                        }
                    })
                } else if (customerRows[i]['layout']) {
                    if (child.key === selectedItems[selectedIndex].key) {
                        customerRows[i]['layout'][j]['checked'] = selectedItems[selectedIndex]['checked']
                    } else {
                        customerRows[i]['layout'][j]['checked'] = false
                    }
                }
            })
        } else {
            customerRows[i]['checked'] = false

            if (customerRows[i]['layout']) {
                customerRows[i]['layout'].forEach((child, j) => {
                    customerRows[i]['layout'][j]['checked'] = false
                })
            }
        }
    })
    return customerRows
}

const addMetricRow = (layout) => {
    let allKpis = customersKPIsFormat('Years');
    return layout.map(l => {
        if (l.layout) {
            let financialDetail = financialDetailRows.find(f => f.key == l.i)
            let kpi = allKpis.find(k => k.key === l.i)

            if (l['metricRow']) {
                l['metricRow'] = financialDetail ? { ...financialDetail, ...l['metricRow'] } : kpi ? { ...kpi, ...l['metricRow'] } : {}
            } else {
                l['metricRow'] = financialDetail || kpi
            }
        }
        return l
    })
}

export { financialDetailRows, customersKPIsFormat, updateCustomersRows, addMetricRow } 