import React, { useRef } from 'react';
import EditDashboard from '../utils/EditDashboard';
import BigTableChartsForSheets from '../report_components/BigTableChartsForSheets';
import IndustryKFI from './IndustryKFI';
import IndustryKPI from './IndustryKPI';
import ReportsDashboard from '../ReportsDashboard';
import DashboardViewModal from '../utils/DashboardViewModal';
import DashboardViewControls from '../utils/DashboardViewControls';
import CoverReportPage from '../utils/CoverReportPage';
import IndustryDoctorsPNL from './IndustryDoctorsPNL';
import { getDashBoardPagesPDF } from '../PDFDashboardHelper'
import IndustryFunctionalExpenses from './IndustryFunctionalExpenses';

const naicsPNLTitle = (naicsCode) => {
    if (naicsCode.startsWith('621')) {
        return 'Doctor P&L'
    } else if (naicsCode.startsWith('813')) {
        return 'Functional Expenses'
    } else {
        return null
    }
}

const IndustryReport = React.forwardRef((props, ref) => {
    let displayData = props.meta.money_n > 19
    if (props.filters.dataset === 'NSCHBC') {
        displayData = props.meta.money_n >= 3
    }
    let moneyDashboardPages = getDashBoardPagesPDF(props.indMoneyDashboard.layout)
    let peopleDashboardPages = getDashBoardPagesPDF(props.indPeopleDashboard.layout)
    let customersDashboardPages = getDashBoardPagesPDF(props.indCustomersDashboard.layout)
    const firmName = props.meta.firm_name ? props.meta.firm_name : ''
    const naicsFocusedReport = naicsPNLTitle(props.filters.code)
    const firstYearRange = props.yearRange.slice(0, 5)
    const secondYearRange = props.yearRange.slice(5)

    const renderMoneyPNL = () => {
        if (props.PDFPrintModalOpen && secondYearRange.length > 0) {
            return (
                <>
                    <div id='money-pnl-1' className={props.displayPages['money']['pages']['pnl']['checked'] ? '' : 'hide-on-print'}>
                        <BigTableChartsForSheets
                            page={'money'}
                            report={'industry'}
                            sheet={'pnl'}
                            title={'Profit & Loss'}
                            commonKey={'revenue'}
                            PDFPrintModalOpen={props.PDFPrintModalOpen}
                            year={props.year}
                            yearRange={firstYearRange}
                            tree={props.tree}
                            commonSizeTable={true}
                        />
                    </div>
                    <div id='money-pnl-2' className={props.displayPages['money']['pages']['pnl']['checked'] ? '' : 'hide-on-print'}>
                        <BigTableChartsForSheets
                            page={'money'}
                            report={'industry'}
                            sheet={'pnl'}
                            title={'Profit & Loss'}
                            commonKey={'revenue'}
                            PDFPrintModalOpen={props.PDFPrintModalOpen}
                            year={props.year}
                            yearRange={secondYearRange}
                            tree={props.tree}
                            commonSizeTable={true}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div id='money-pnl' className={props.displayPages['money']['pages']['pnl']['checked'] ? '' : 'hide-on-print'}>
                    <BigTableChartsForSheets
                        page={'money'}
                        report={'industry'}
                        sheet={'pnl'}
                        title={'Profit & Loss'}
                        commonKey={'revenue'}
                        PDFPrintModalOpen={props.PDFPrintModalOpen}
                        year={props.year}
                        yearRange={props.yearRange}
                        tree={props.tree}
                        commonSizeTable={true}
                    />
                </div>
            )
        }
    }

    const renderNAICSFocusedReport = () => {
        if (naicsFocusedReport === 'Doctor P&L' && props.PDFPrintModalOpen && secondYearRange.length > 0) {
            return (
                <>
                    <div id='money-doctor_pnl' className={props.displayPages['money']['pages']['doctor_pnl']['checked'] ? '' : 'hide-on-print'}>
                        <IndustryDoctorsPNL
                            commonKey={'revenue'}
                            firmName={firmName}
                            naicsCode={props.filters.code}
                            page={'money'}
                            report={'industry'}
                            sheet={'pnl'}
                            title={naicsFocusedReport}
                            year={props.year}
                            yearRange={firstYearRange}
                        />
                    </div>
                    <div id='money-doctor_pnl' className={props.displayPages['money']['pages']['doctor_pnl']['checked'] ? '' : 'hide-on-print'}>
                        <IndustryDoctorsPNL
                            commonKey={'revenue'}
                            firmName={firmName}
                            naicsCode={props.filters.code}
                            page={'money'}
                            report={'industry'}
                            sheet={'pnl'}
                            title={naicsFocusedReport}
                            year={props.year}
                            yearRange={secondYearRange}
                        />
                    </div>
                </>
            )
        } else if (naicsFocusedReport === 'Doctor P&L') {
            return (
                <div id='money-doctor_pnl' className={props.displayPages['money']['pages']['doctor_pnl']['checked'] ? '' : 'hide-on-print'}>
                    <IndustryDoctorsPNL
                        commonKey={'revenue'}
                        firmName={firmName}
                        naicsCode={props.filters.code}
                        page={'money'}
                        report={'industry'}
                        sheet={'pnl'}
                        title={naicsFocusedReport}
                        year={props.year}
                        yearRange={props.yearRange}
                    />
                </div>
            )
        } else if (naicsFocusedReport === 'Functional Expenses') {
            return (
                <div id='money-functional-expenses' className={props.displayPages['money']['pages']['functional_expenses']['checked'] ? '' : 'hide-on-print'}>
                    <IndustryFunctionalExpenses
                        firmName={firmName}
                        naicsCode={props.filters.code}
                        page={'money'}
                        report={'industry'}
                        sheet={'pnl'}
                        title={naicsFocusedReport}
                        tree={props.tree}
                        year={props.year}
                    />
                </div>
            )
        }
    }

    const renderMoneyBalanceSheet = () => {
        if (props.PDFPrintModalOpen && secondYearRange.length > 0) {
            return (
                <>
                    <div id='money-balance_sheet' className={props.displayPages['money']['pages']['balance_sheet']['checked'] ? '' : 'hide-on-print'}>
                        <BigTableChartsForSheets
                            commonKey={'assets'}
                            firmName={firmName}
                            page={'money'}
                            report={'industry'}
                            sheet={'balance_sheet'}
                            title={'Balance Sheet'}
                            year={props.year}
                            yearRange={firstYearRange}
                            tree={props.tree}
                            commonSizeTable={true}
                        />
                    </div>
                    <div id='money-balance_sheet' className={props.displayPages['money']['pages']['balance_sheet']['checked'] ? '' : 'hide-on-print'}>
                        <BigTableChartsForSheets
                            commonKey={'assets'}
                            firmName={firmName}
                            page={'money'}
                            report={'industry'}
                            sheet={'balance_sheet'}
                            title={'Balance Sheet'}
                            year={props.year}
                            yearRange={secondYearRange}
                            tree={props.tree}
                            commonSizeTable={true}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div id='money-balance_sheet' className={props.displayPages['money']['pages']['balance_sheet']['checked'] ? '' : 'hide-on-print'}>
                    <BigTableChartsForSheets
                        commonKey={'assets'}
                        firmName={firmName}
                        page={'money'}
                        report={'industry'}
                        sheet={'balance_sheet'}
                        title={'Balance Sheet'}
                        year={props.year}
                        yearRange={props.yearRange}
                        tree={props.tree}
                        commonSizeTable={true}
                    />
                </div>
            )
        }
    }

    const renderFinancialDetail = (page) => {
        if (props.PDFPrintModalOpen && secondYearRange.length > 0) {
            return (
                <>
                    <div id={`${page}-financial_detail`} className={props.displayPages[page]['pages']['financial_detail']['checked'] ? '' : 'hide-on-print'}>
                        <BigTableChartsForSheets
                            commonKey={'revenue'}
                            firmName={firmName}
                            page={page}
                            report={'industry'}
                            sheet={'pnl'}
                            title={'Financial Detail'}
                            year={props.year}
                            yearRange={firstYearRange}
                            tree={props.tree}
                            commonSizeTable={true}
                        />
                    </div>
                    <div id={`${page}-financial_detail`} className={props.displayPages[page]['pages']['financial_detail']['checked'] ? '' : 'hide-on-print'}>
                        <BigTableChartsForSheets
                            commonKey={'revenue'}
                            firmName={firmName}
                            page={page}
                            report={'industry'}
                            sheet={'pnl'}
                            title={'Financial Detail'}
                            year={props.year}
                            yearRange={secondYearRange}
                            tree={props.tree}
                            commonSizeTable={true}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div id={`${page}-financial_detail`} className={props.displayPages[page]['pages']['financial_detail']['checked'] ? '' : 'hide-on-print'}>
                    <BigTableChartsForSheets
                        commonKey={'revenue'}
                        firmName={firmName}
                        page={page}
                        report={'industry'}
                        sheet={'pnl'}
                        title={'Financial Detail'}
                        year={props.year}
                        yearRange={props.yearRange}
                        tree={props.tree}
                        commonSizeTable={true}
                    />
                </div>
            )
        }
    }

    return (
        <div ref={ref} className='industry-content'>
            <div className={props.displayPages['cover_page']['checked'] ? 'hide-cover-page-print' : 'hide-all-time'}>
                <CoverReportPage
                    filters={props.filters}
                    firmName={firmName}
                    firmLogo={props.meta.firm_logo}
                    page={'industry'}
                    industry={props.meta.ind_name}
                    naicsCode={props.meta.ind_naics}
                    n={props.meta.money_n}
                    year={props.year}
                    yearRange={props.yearRange}
                    displayPrintItems={true} />
            </div>
            <div className={props.activeTab === 'money' ? 'ind-money-content' : 'hide-section'}>
                <div className='hide-on-print'>
                    <ul className='report-tab-list' id='nav-tabs-money-report' role='tablist' >
                        <li id='pnl-tab' className={props.moneyActiveTab === 'pnl' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => props.setMoneyActiveTab('pnl')}>
                            Profit &#38; Loss
                        </li>
                        {naicsFocusedReport
                            ? (
                                <li id='naics_focused-tab' className={props.moneyActiveTab === 'naics_focused' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => props.setMoneyActiveTab('naics_focused')}>
                                    {naicsFocusedReport}
                                </li>
                            )
                            : null
                        }
                        <li id='balance-sheet-tab' className={props.moneyActiveTab === 'balance-sheet' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => props.setMoneyActiveTab('balance-sheet')}>
                            Balance Sheet
                        </li>
                        <li id='kfis-tab' className={props.moneyActiveTab === 'kfis' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => props.setMoneyActiveTab('kfis')}>
                            KFI's
                        </li>
                        <li id='dashboard-tab' className={props.moneyActiveTab === 'dashboard' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => props.setMoneyActiveTab('dashboard')}>
                            Dashboard
                        </li>
                        <DashboardViewModal
                            dashboardViewModalState={props.dashboardViewMoneyState}
                            page={'money'}
                            report={'industry'}
                            handleDashboardViewModal={props.handleDashboardViewModal}
                            createDashBoardView={props.createDashBoardView}
                            deleteDashBoardView={props.deleteDashBoardView}
                            updateDashBoardView={props.updateDashBoardView}
                        />
                        <div className='edit-dashboard-section'>
                            {props.indMoneyDashboard.noChangesMade ? null : <button className='save-dashboard-btn' onClick={() => props.updateDashBoardLayoutChange(props.activeTab, props.indMoneyDashboard.layout)}>Save Money View Changes</button>}
                            {props.moneyActiveTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('add_view')}>+ New View</button> : null}
                            {props.moneyActiveTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('all_views')}>Edit My Views</button> : null}
                            <EditDashboard
                                page={'money'}
                                report={'industry'}
                                gridItemsSelected={props.indMoneyDashboard.selectedItems}
                                noChangesMade={props.indMoneyDashboard.noChangesMade}
                                handleDashboardSelections={props.handleDashboardSelections}
                                handleSelectAll={props.handleSelectAll}
                                updateDashBoardView={props.handleDashEditFormSubmit}
                            />
                        </div>
                    </ul>
                </div>
                {displayData ?
                    (<div>
                        <div className={props.moneyActiveTab === 'pnl' ? 'show-section' : 'hide-section'}>
                            {renderMoneyPNL()}
                        </div>
                        <div className={props.moneyActiveTab === 'naics_focused' && naicsFocusedReport ? 'show-section' : 'hide-section'}>
                            {renderNAICSFocusedReport()}
                        </div>
                        <div className={props.moneyActiveTab === 'balance-sheet' ? 'show-section' : 'hide-section'}>
                            {renderMoneyBalanceSheet()}
                        </div>
                        <div className={props.moneyActiveTab === 'kfis' ? 'show-section' : 'hide-section'}>
                            <div id='money-kfis' className={props.displayPages['money']['pages']['kfis']['checked'] ? '' : 'hide-on-print'}>
                                <IndustryKFI
                                    firmName={firmName}
                                    yearRange={props.yearRange}
                                />
                            </div>
                        </div>
                        <div className={props.moneyActiveTab === 'dashboard' ? 'show-section' : 'hide-section'}>
                            <DashboardViewControls
                                page={'money'}
                                report={'industry'}
                                dashboardViewModalState={props.dashboardViewMoneyState}
                                getDashBoardView={props.getDashBoardView}
                                handleDashboardViewModal={props.handleDashboardViewModal}
                            />
                        </div>
                        {props.moneyActiveTab === 'dashboard' &&
                            <ReportsDashboard
                                naicsCode={props.filters.code}
                                dashboardLayout={props.indMoneyDashboard.layout}
                                dashBoardType={'normalDashboard'}
                                handleOnLayoutChange={props.handleOnLayoutChange}
                                page={'industryMoney'}
                                year={props.year}
                                yearRange={props.yearRange}
                            />
                        }
                        <div id='money-dashboard' className={props.displayPages['money']['pages']['dashboard']['checked'] ? 'hide-section' : 'hide-all-time'}>
                            {moneyDashboardPages.map(dashboardItems => {
                                return (
                                    <div className='potrait-view'>
                                        <div className='money-pdf-portrait'>
                                            <div>
                                                <h1 className='report-table-title'>{`Dashboard - Money`}</h1>
                                                <ReportsDashboard
                                                    naicsCode={props.filters.code}
                                                    dashboardLayout={dashboardItems}
                                                    dashBoardType={'normalDashboard'}
                                                    handleOnLayoutChange={props.handleOnLayoutChange}
                                                    page={'industryMoney'}
                                                    pdf={true}
                                                    year={props.year}
                                                    yearRange={props.yearRange}
                                                />
                                            </div>
                                            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>) : <h1>Insufficient Money Data</h1>
                }
            </div>
            <div className={props.activeTab === 'people' ? 'ind-people-content' : 'hide-section'}>
                <div className='hide-on-print'>
                    <ul className='report-tab-list' id='nav-tabs-people-report' role='tablist' >
                        <li className={props.peopleActiveTab === 'financial-detail' ? 'report-tab-item people-active' : 'report-tab-item'} role='presentation' onClick={() => props.setPeopleActiveTab('financial-detail')} style={{ fontSize: '12px' }}>
                            Financial Detail
                        </li>
                        <li className={props.peopleActiveTab === 'kpi' ? 'report-tab-item people-active' : 'report-tab-item'} role='presentation' onClick={() => props.setPeopleActiveTab('kpi')}>
                            KPI
                        </li>
                        <li className={props.peopleActiveTab === 'dashboard' ? 'report-tab-item people-active' : 'report-tab-item'} role='presentation' onClick={() => props.setPeopleActiveTab('dashboard')}>
                            Dashboard
                        </li>
                        <DashboardViewModal
                            dashboardViewModalState={props.dashboardViewPeopleState}
                            page={'people'}
                            report={'industry'}
                            handleDashboardViewModal={props.handleDashboardViewModal}
                            createDashBoardView={props.createDashBoardView}
                            deleteDashBoardView={props.deleteDashBoardView}
                            updateDashBoardView={props.updateDashBoardView}
                        />
                        <div className='edit-dashboard-section'>
                            {props.indPeopleDashboard.noChangesMade ? null : <button className='save-dashboard-btn' onClick={() => props.updateDashBoardLayoutChange(props.activeTab, props.indPeopleDashboard.layout)}>Save People View Changes</button>}
                            {props.peopleActiveTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('add_view')}>+ New View</button> : null}
                            {props.peopleActiveTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('all_views')}>Edit My Views</button> : null}
                            <EditDashboard
                                page={'people'}
                                report={'industry'}
                                gridItemsSelected={props.indPeopleDashboard.selectedItems}
                                noChangesMade={props.indPeopleDashboard.noChangesMade}
                                handleDashboardSelections={props.handleDashboardSelections}
                                handleSelectAll={props.handleSelectAll}
                                updateDashBoardView={props.handleDashEditFormSubmit}
                            />
                        </div>
                    </ul>
                </div>
                {displayData ?
                    (<div>
                        <div className={props.peopleActiveTab === 'financial-detail' ? 'show-section' : 'hide-section'}>
                            {renderFinancialDetail('people')}
                        </div>
                        <div className={props.peopleActiveTab === 'kpi' ? 'show-section' : 'hide-section'}>
                            <div id='people-kpi' className={props.displayPages['people']['pages']['kpi']['checked'] ? '' : 'hide-on-print'}>
                                <IndustryKPI
                                    firmName={firmName}
                                    page={'people'}
                                    yearRange={props.yearRange}
                                />
                            </div>
                        </div>
                        <div className={props.peopleActiveTab === 'dashboard' ? 'show-section' : 'hide-section'}>
                            <DashboardViewControls
                                page={'people'}
                                report={'industry'}
                                dashboardViewModalState={props.dashboardViewPeopleState}
                                getDashBoardView={props.getDashBoardView}
                                handleDashboardViewModal={props.handleDashboardViewModal}
                            />
                        </div>
                        {props.peopleActiveTab === 'dashboard' &&
                            <ReportsDashboard
                                dashboardLayout={props.indPeopleDashboard.layout}
                                dashBoardType={'normalDashboard'}
                                handleOnLayoutChange={props.handleOnLayoutChange}
                                page={'industryPeople'}
                                year={props.year}
                                yearRange={props.yearRange}
                            />}
                        <div id='people-dashboard' className={props.displayPages['people']['pages']['dashboard']['checked'] ? 'hide-section' : 'hide-all-time'}>
                            {peopleDashboardPages.map(dashboardItems => {
                                return (
                                    <div className='potrait-view'>
                                        <div className='money-pdf-portrait'>
                                            <div>
                                                <h1 className='report-table-title'>{`Dashboard - People`}</h1>
                                                <ReportsDashboard
                                                    naicsCode={props.filters.code}
                                                    dashboardLayout={dashboardItems}
                                                    dashBoardType={'normalDashboard'}
                                                    handleOnLayoutChange={props.handleOnLayoutChange}
                                                    page={'industryPeople'}
                                                    pdf={true}
                                                    year={props.year}
                                                    yearRange={props.yearRange}
                                                />
                                            </div>
                                            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>) : <h1>Insufficient People Data</h1>
                }
            </div>
            <div className={props.activeTab === 'customers' ? 'ind-customer-content' : 'hide-section'}>
                <div className='hide-on-print'>
                    <ul className='report-tab-list' id='nav-tabs-customers-report' role='tablist' >
                        <li className={props.customersActiveTab === 'financial-detail' ? 'report-tab-item customers-active' : 'report-tab-item'} role='presentation' onClick={() => props.setCustomersActiveTab('financial-detail')} style={{ fontSize: '12px' }}>
                            Financial Detail
                        </li>
                        <li className={props.customersActiveTab === 'kpi' ? 'report-tab-item customers-active' : 'report-tab-item'} role='presentation' onClick={() => props.setCustomersActiveTab('kpi')}>
                            KPI
                        </li>
                        <li className={props.customersActiveTab === 'dashboard' ? 'report-tab-item customers-active' : 'report-tab-item'} role='presentation' onClick={() => props.setCustomersActiveTab('dashboard')}>
                            Dashboard
                        </li>
                        <DashboardViewModal
                            dashboardViewModalState={props.dashboardViewCustomersState}
                            page={'customers'}
                            report={'industry'}
                            handleDashboardViewModal={props.handleDashboardViewModal}
                            createDashBoardView={props.createDashBoardView}
                            deleteDashBoardView={props.deleteDashBoardView}
                            updateDashBoardView={props.updateDashBoardView}
                        />
                        <div className='edit-dashboard-section'>
                            {props.indCustomersDashboard.noChangesMade ? null : <button className='save-dashboard-btn' onClick={() => props.updateDashBoardLayoutChange(props.activeTab, props.indCustomersDashboard.layout)}>Save Customers View Changes</button>}
                            {props.customersActiveTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('add_view')}>+ New View</button> : null}
                            {props.customersActiveTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('all_views')}>Edit My Views</button> : null}
                            <EditDashboard
                                page={'customers'}
                                report={'industry'}
                                gridItemsSelected={props.indCustomersDashboard.selectedItems}
                                noChangesMade={props.indCustomersDashboard.noChangesMade}
                                handleDashboardSelections={props.handleDashboardSelections}
                                handleSelectAll={props.handleSelectAll}
                                updateDashBoardView={props.handleDashEditFormSubmit}
                            />
                        </div>
                    </ul>
                </div>
                {displayData ?
                    (<div>
                        <div className={props.customersActiveTab === 'financial-detail' ? 'show-section' : 'hide-section'}>
                            {renderFinancialDetail('customers')}
                        </div>
                        <div className={props.customersActiveTab === 'kpi' ? 'show-section' : 'hide-section'}>
                            <div id='customers-kpi' className={props.displayPages['customers']['pages']['kpi']['checked'] ? '' : 'hide-on-print'}>
                                <IndustryKPI
                                    firmName={firmName}
                                    page={'customers'}
                                    yearRange={props.yearRange}
                                />
                            </div>
                        </div>
                        <div className={props.customersActiveTab === 'dashboard' ? 'show-section' : 'hide-section'}>
                            <DashboardViewControls
                                page={'customers'}
                                report={'industry'}
                                dashboardViewModalState={props.dashboardViewCustomersState}
                                getDashBoardView={props.getDashBoardView}
                                handleDashboardViewModal={props.handleDashboardViewModal}
                            />
                        </div>
                        {props.customersActiveTab === 'dashboard' &&
                            <ReportsDashboard
                                dashboardLayout={props.indCustomersDashboard.layout}
                                dashBoardType={'normalDashboard'}
                                handleOnLayoutChange={props.handleOnLayoutChange}
                                page={'industryCustomers'}
                                year={props.year}
                                yearRange={props.yearRange}
                            />}
                        <div id='customers-dashboard' className={props.displayPages['customers']['pages']['dashboard']['checked'] ? 'hide-section' : 'hide-all-time'}>
                            {customersDashboardPages.map(dashboardItems => {
                                return (
                                    <div className='potrait-view'>
                                        <div className='money-pdf-portrait'>
                                            <div>
                                                <h1 className='report-table-title'>{`Dashboard - Customers`}</h1>
                                                <ReportsDashboard
                                                    naicsCode={props.filters.code}
                                                    dashboardLayout={dashboardItems}
                                                    dashBoardType={'normalDashboard'}
                                                    handleOnLayoutChange={props.handleOnLayoutChange}
                                                    page={'industryCustomers'}
                                                    pdf={true}
                                                    year={props.year}
                                                    yearRange={props.yearRange}
                                                />
                                            </div>
                                            <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>) : <h1>Insufficient Customers Data</h1>
                }
            </div>
        </div >
    )
})

export default IndustryReport;