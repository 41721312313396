import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const EditDashboard = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [formMetrics, setFormMetrics] = useState([]);

    useEffect(() => {
        if (props.gridItemsSelected) {
            if (props.report === 'industry') {
                if (props.page === 'money') {
                    setFormMetrics(['Annual Growth Rates', 'Profitability Analysis', 'Liquidity Analysis', 'Leverage Analysis', 'Cash Flow Analysis', 'Activity Analysis', 'Expense Comparison']);
                }
    
                if (props.page === 'people') {
                    setFormMetrics(['Annual Growth Rates', 'Wages & Salaries']);
                }
    
                if (props.page === 'customers') {
                    setFormMetrics(['Annual Growth Rates', 'Concentration']);
                }
            } else {
                if (props.page === 'practice') {
                    setFormMetrics(['Focused Clients', 'Peerview Metrics', 'Peerview Metrics Bubble Chart', '3D Bubble Chart', 'Scatter Plot', 'Map'])
                }
                if (props.page === 'money') {
                    setFormMetrics(['Peerview Metrics', 'Growth', 'Profitability', 'Liquidity', 'Leverage', 'Cash Flow']);
                }
    
                if (props.page === 'people') {
                    setFormMetrics(['Peerview Metrics', 'HR Budget', 'Wages & Salaries', 'Benefits', 'Turnover', 'Productivity']);
                }
    
                if (props.page === 'customers') {
                    setFormMetrics(['Peerview Metrics', 'Spend', 'Volatility', 'Concentration', 'ROI', 'Efficiency']);
                }
            }
        }

    }, [props.gridItemsSelected])

    const openModal = () => {
        setIsOpen(true);
    }
    
    const closeModal = () => {
        setIsOpen(false);
    };

    const Checkbox = ({ chart, parent=null }) => {
        let labelClass = parent ? 'dashboard-form-check-label-child' : 'dashboard-form-check-label'

        return (
            <div key={chart.key} className='dashboard-form-check'>
                <label className={labelClass}>
                    <input
                        type='checkbox'
                        checked={chart.checked}
                        onChange={() => props.handleDashboardSelections(chart, parent)}
                        className='form-check-input'
                    />
                    <span>{chart.label}</span>
                </label>
            </div>
        )
    }

    let split = props.page.split('');
    split[0] = props.page[0].toUpperCase();
    let pageTitle = split.join('');

    return (
        <>
            <div className='edit-dashboard-btn' onClick={openModal}>
                <i className='fa fa-bar-chart' aria-hidden='true'></i>
            </div>
            <Modal
                className={{
                    base: 'dashboard-modal-content hide-on-print',
                    afterOpen: 'dashboard-modal-content_after-open hide-on-print',
                    beforeClose: 'dashboard-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'dashboard-overlay-base hide-on-print',
                    afterOpen: 'dashboard-overlay-base_after-open hide-on-print',
                    beforeClose: 'dashboard-overlay-base_before-close hide-on-print',
                }}
                ariaHideApp={false}
                closeTimeoutMS={0}
                contentLabel='Edit Dashboard Modal'
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={true}
            >
                <h3>Select Charts to Display on {pageTitle} {props.dashboardLayoutType} Dashboard</h3>
                <p>Click Anywhere Outside of this Pop-up to Close</p>
                <div className='edit-modal-btn-section'>
                    <button key='select-all-btn' className='btn dashboard-save-btn' onClick={() => props.handleSelectAll(true)}>Select All</button>
                    <button key='unselect-all-btn' className='btn dashboard-save-btn' onClick={() => props.handleSelectAll(false)}>Unselect All</button>
                </div>
                <form onSubmit={(e) => props.updateDashBoardView(e)}>
                    <div className='form-checkbox-section'>
                        {formMetrics.map((m, i) => {
                            let chartMetrics = props.gridItemsSelected.filter(c => c.formSection === m)
                            if (chartMetrics.length > 0) {
                                return (
                                    <div key={m+i} className='form-section'>
                                        <p>{m}</p>
                                        {chartMetrics.map((c) => {
                                            if (c.children) {
                                                return (
                                                    <React.Fragment>
                                                        <Checkbox key={c.key} chart={c} />
                                                        {c.children.map((child) => {
                                                            return (<Checkbox key={child.key} chart={child} parent={c} />)
                                                        })}
                                                    </React.Fragment>
                                                )
                                            } else {
                                                return <Checkbox key={c.key} chart={c} />
                                            }
                                        })}
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className='dashboard-form-btn-section'>
                        {props.noChangesMade ? null : <button className='btn dashboard-save-btn'>Save Changes</button>}
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default EditDashboard;