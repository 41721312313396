import React, { useContext } from 'react';
import MonthlyForecastKFITableBuilder from './MonthlyForecastKFITableBuilder';

const MonthlyForecastKFITable = (props) => {
  const months = props.months

  const forecastClassNames = props.page === 'forecast' ? "year-rows cash-flow-column month-col " : '';

  const selectedMonth = (i) => { 
    if (props.editMultipleMonths) {
      return i >= props.month ? 'current-month-col' : null
    } else {
      return props.month == i ? 'current-month-col' : null 
    }
  }

  const backgroundChanger = (i) => i < (props.blendMonth - 1) && props.isLiveData && props.year === new Date(Date.now()).getFullYear() && i !== props.month ? 'live-data-col' : null

  const tableSections = [
    {
      title: "Liquidity",
      keys: { "Current Ratio": 'current_ratio', "Net Working Capital Ratio": 'net_working_capital_ratio', 'Net Working Capital': 'net_working_capital' },
    },
    {
      title: "Profitability",
      keys: { "Return on Assets (ROA)": "roa", "Return on Equity (ROE)": "roe", "Return on Capital Employed (ROCE)": "roce", "Gross Profit": "gross_profit_common_size_revenue", "Operating Profit": "operating_profit_common_size_revenue" },
    },
    {
      title: "Activity",
      keys: { "Asset Turnover Ratio": 'asset_turnover_ratio', "Inventory Turnover Ratio": 'inventory_turnover_ratio', "Accounts Receivable Days": 'ar_days', "Accounts Payable Days": 'ap_days' },
    },
    {
      title: "Leverage",
      keys: { "Debt / Equity": 'debt_to_equity' },
    },
    {
      title: "Cash Flow",
      keys: { "Senior Debt / EBITDA": 'senior_debt_to_ebitda', "EBITDA / Annual Interest": 'ebitda_to_annual_interest' },
    },
    {
      title: "EBITDA",
      keys: { "Net Income": 'operating_expenses_net_income', "Interest": 'interest_and_other_expenses', "Taxes - Federal & State": 'total_other_costs_taxes_- federal & state', "Depreciation/Amortization": 'depreciation_and_amortization', "EBITDA": 'ebitda' },
    }
  ];

  return (
    <>
      <div>
        <div className={props.page === 'forecast' ? "monthly-forecast-landscape big-table-title report-card monthly-sheet" : 'money-report-container'}>
          <div>
            <h1 className='report-table-title hide-not-on-print'>{`${props.year} Key Financial Indicators - ${props.clientName}`}</h1>
            <div className='pdf-table-div'>
              <table className='monthly-forecast-table' id={`monthly-kfi-table-${props.year}`}>
                <thead>
                  <tr className="kfi-report-thead">
                    <td></td>
                    <td
                      className={forecastClassNames + selectedMonth(0) + ' ' + backgroundChanger(0)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(0)}
                    >
                      {months['1']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(1) + ' ' + backgroundChanger(1)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(1)}
                    >
                      {months['2']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(2) + ' ' + backgroundChanger(2)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(2)}
                    >
                      {months['3']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(3) + ' ' + backgroundChanger(3)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(3)}
                    >
                      {months['4']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(4) + ' ' + backgroundChanger(4)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(4)}
                    >
                      {months['5']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(5) + ' ' + backgroundChanger(5)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(5)}
                    >
                      {months['6']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(6) + ' ' + backgroundChanger(6)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(6)}
                    >
                      {months['7']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(7) + ' ' + backgroundChanger(7)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(7)}
                    >
                      {months['8']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(8) + ' ' + backgroundChanger(8)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(8)}
                    >
                      {months['9']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(9) + ' ' + backgroundChanger(9)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(9)}
                    >
                      {months['10']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(10) + ' ' + backgroundChanger(10)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(10)}
                    >
                      {months['11']}
                    </td>
                    <td
                      className={forecastClassNames + selectedMonth(11) + ' ' + backgroundChanger(11)}
                      style={{ textAlign: 'center' }}
                      onClick={() => props.setMonth(11)}
                    >
                      {months['12']}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {tableSections.map(({ title, keys }) => (
                    <MonthlyForecastKFITableBuilder
                      editMultipleMonths={props.editMultipleMonths}
                      title={title}
                      keys={keys}
                      months={months}
                      key={Math.random(890)}
                      page={props.page}
                      year={props.year}
                      futureYear={props.futureYear}
                      previousYearPnlSet={props.previousYearPnlSet}
                      previousYearBalSet={props.previousYearBalSet}
                      pnlValueSet={props.pnlValueSet}
                      balValueSet={props.balValueSet}
                      month={props.month}
                      setMonth={props.setMonth}
                      isLiveData={props.isLiveData}
                      blendMonth={props.blendMonth}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
        </div>
      </div>
    </>
  )
};

export default MonthlyForecastKFITable;
