import React from 'react';
import moment from 'moment';

const CoverReportPage = (props) => {
    const firmName = props.meta && props.meta.firm_name ? props.meta.firm_name : props.name
    const numOfClients = props.n < 20 ? '< 20' : props.n

    if (props.page === 'industry') {
        let coverPeriod = `${props.yearRange[0]} - ${props.yearRange[props.yearRange.length - 1]}`
        let todayDate = moment(Date.now()).format("LL")

        return (
            <div className='portrait-view cover-page-pdf'>
                <div className='cover-page-top-section'></div>
                <div className='cover-page-info'>
                    <div>
                        <div className='img-title-page-print'>{props.firmLogo ? <img src={props.firmLogo} alt=''></img> : null}</div>
                        <h1>Industry Report</h1>
                        <div className='cp-report-info'>
                            <p>{props.firmName}</p>
                            <p>REPORT PERIOD: {coverPeriod}</p>
                            <p>INDUSTRY: {props.industry}</p>
                            <p>NAICS: {props.naicsCode}</p>
                            <p>{`NUMBER OF COMPANIES: ${props.n}`}</p>
                            <p style={{ 'textTransform': 'uppercase' }}>{todayDate}</p>
                        </div>
                    </div>
                    <div className='cp-filters-list'>
                        <h2 className='cp-filter-title'>FILTERS USED IN THIS REPORT</h2>
                        <div className='cp-filter-cols'>
                            <p>DATA TYPE: {props.filters.datatype}</p>
                            <p>REVENUE: {props.filters.revenue_band}</p>
                            <p>REGION: {props.filters.region}</p>
                            <p>DATASET: {props.filters.dataset}</p>
                        </div>
                    </div>
                </div>
                <div className='cover-page-bottom-section'>
                    <p>Powered by Peerview Data</p>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className='img-title-page-print'>{props.logo && props.displayPrintItems ? <img src={props.logo} alt='logo'></img> : null}</div>
                <p style={{ textTransform: 'uppercase' }}>{props.page}</p>
                {props.name ? <p>CLIENT ID: {props.name}</p> : null}
                {props.real_name ? <p>CLIENT NAME: {props.real_name}</p> : null}
                {props.page !== 'practice report' && props.page !== 'industry report' ? <p>FISCAL YEAR END: {props.fiscalYearEnd}</p> : null}
                <p>INDUSTRY: {props.industry}</p>
                <p>NAICS: {props.naicsCode}</p>
                {props.page === 'industry report'
                    ? <>
                        <p>MONEY - NUMBER OF COMPANIES: {props.money_n || '<20'}</p>
                        <p>PEOPLE - NUMBER OF COMPANIES: {props.people_n || '<20'}</p>
                        <p>CUSTOMERS - NUMBER OF COMPANIES: {props.customer_n || '<20'}</p>
                    </> : null
                }
                {props.page === 'practice report' ? <p>{`NUMBER OF CLIENTS: ${numOfClients}`}</p> : props.page !== 'audit' && props.page !== 'industry report' ? <p>{`NUMBER OF COMPANIES: ${numOfClients}`}</p> : null}
                {props.displayPrintItems
                    ? <>
                        <p className='disclaimer-text'>These financial statements have been prepared by {firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. The economic uncertainties resulting from the COVID-19 pandemic may potentially impact the Company’s operating results. However, the general effects of the COVID-19 pandemic continue to change and remain unpredictable and the related financial impact and duration cannot be reasonably estimated at this time. Substantially all disclosures omitted.</p>
                    </> : null
                }
            </>
        )
    }
}

export default CoverReportPage;