import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

const SurveyRadioAnswers = {
    satisfaction: {
        'Very Unsatisfied': 1,
        'Unsatisfied': 2,
        'Neutral': 3,
        'Satisfied': 4,
        'Very Satisfied': 5
    },
    growth: {
        'Big Decrease': 1,
        'Slight Decrease': 2,
        'Same': 3,
        'Slight Increase': 4,
        'Big Increase': 5
    },
    likelihood: {
        'Very Unlikely': 1,
        'Unlikely': 2,
        'Neutral': 3,
        'Likely': 4,
        'Very likely': 5
    },
    concern: {
        'Not At All Concerned': 1,
        'Neutral': 2,
        'Somewhat Concerned': 3,
        'Very Concerned': 4,
        'Extremely Concerned': 5
    },
    effectivity: {
        'Waste Of Time': 1,
        'Ineffective': 2,
        'Okay': 3,
        'Good': 4,
        'Very Effective': 5,
    },
    importance: {
        'Unimportant': 1,
        'Low Importance': 2,
        'Neutral': 3,
        'Important': 4,
        'Very Important': 5,
    },
    alignment: {
        'not_applicable': 1,
        'out_of_sync': 2,
        'not_so_close': 3,
        'close': 4,
        'in_sync': 5,
    },
    rating: {
        'not_applicable': 1,
        'need_improvement': 2,
        'neutral': 3,
        'good': 4,
        'very_good': 5,
    },
    growthPercentage: {
        'up_10_percent': 1,
        'up_20_percent': 2,
        'up_30_percent': 3,
        'up_40_percent': 4,
        'up_50_percent': 5,
    },
    optionalSatisfaction: {
        'not_applicable': 1,
        'unsatisfied': 2,
        'neutral': 3,
        'satisfied': 4,
        'very_satisfied': 5,
    },
    competition: {
        'Price': 1,
        'Quality': 2,
        'Compatibility': 3,
        'Timeliness': 4,
    },
    competitionChart: {
        competitive_advantages_price: 'Price',
        competitive_advantages_quality: 'Quality',
        competitive_advantages_compatibility: 'Compatibility',
        competitive_advantages_timeliness: 'Timeliness'
    },
    inBoundMarketing: {
        'email': 1,
        'social_media': 2,
        'seo': 3,
        'podcasts': 4,
        'blogs': 5,
        'videos': 6,
        'other': 7,
        'forums': 8,
    },
    outBoundMarketing: {
        'pay_per_click': 1,
        'display_and_banner_ads': 2,
        'daily_deals': 3,
        'sponsorships': 4,
        'direct_mail': 5,
        'telemarketing': 6,
        'offline_ads': 7,
    }
}

const EditSurveyFormat = (props) => {
    const [surveyType, setSurveyType] = useState('Money');
    const [newQuestion, setNewQuestion] = useState({
        questionTypes: [['Group Questions', 'radio'], ['Ranking', 'ranking'], ['Text', 'text'], ['Text area', 'textarea'], ['Number Years Inputs', 'numberYears']],
        selectedFilterOptions: [],
        selectedSurveyType: 'Money',
        selectedQuestionType: 'radio',
        selectedSection: 'Expectations',
        addAfterQuestionIndex: 0,
        questionTitle: '',
        subtitle: '',
        questionText: '',
        numberOfValues: 5,
        optionValues: {
            1: 'Very Unlikely',
            2: 'Unlikely',
            3: 'Neutral',
            4: 'Likely',
            5: 'Very likely'
        },
        addBeforeQuestion: false,
    })

    const [surveyFormat, setSurveyFormat] = useState({
        naics_code: props.code,
        naics_id: props.id,
        naics_name: props.naics_name,
        surveyTypes: ['Money', 'People', 'Customers', 'Future'],
        questionTypes: ['radio', 'ranking', 'text', 'textarea', 'numberYears'],
        layout: props.survey_format || {
            'Money': {
                sections: ['Expectations', 'Priorities', 'Concerns', 'Facilities', 'Additional Comments'],
                industryMetrics: [],
                'Expectations': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Expectations (0-12 months)',
                            subtitle: 'One Year Expectations',
                            key: 'one_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            lockAnswers: true,
                            questions: [
                                {
                                    key: 'one_year_revenue',
                                    questionTitle: 'Revenue',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_margins',
                                    questionTitle: 'Margin',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_profitability',
                                    questionTitle: 'Profitability',
                                    answer: 3
                                },

                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'one_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: 'Please explain, if necessary:',
                            answer: '',
                            lockAnswers: false,
                        },
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Expectations (13-36 months)',
                            subtitle: 'Three Year Expectations',
                            key: 'three_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            lockAnswers: true,
                            questions: [
                                {
                                    key: 'three_year_revenue',
                                    questionTitle: 'Revenue',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_margins',
                                    questionTitle: 'Margin',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_profitability',
                                    questionTitle: 'Profitability',
                                    answer: 3
                                },
                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'three_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: 'Please explain, if necessary:',
                            answer: '',
                            lockAnswers: false,
                        },
                    ]
                },
                'Priorities': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Priorities',
                            questionTitle: '',
                            subtitle: '',
                            key: 'priorities',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'],
                            answerData: SurveyRadioAnswers['likelihood'],
                            lockAnswers: true,
                            questions: [
                                {
                                    key: 'reduce_debt',
                                    questionTitle: 'Reduce debt',
                                    answer: 3
                                },
                                {
                                    key: 'leverage_new_debt',
                                    questionTitle: 'Leverage new debt',
                                    answer: 3
                                },
                                {
                                    key: 'hold_cash',
                                    questionTitle: 'Hold cash',
                                    answer: 3
                                },
                                {
                                    key: 'invest_cash',
                                    questionTitle: 'Invest cash',
                                    answer: 3
                                },
                                {
                                    key: 'reduce_indirect_costs',
                                    questionTitle: 'Reduce indirect costs',
                                    answer: 3
                                },
                                {
                                    key: 'reduce_direct_costs',
                                    questionTitle: 'Reduce direct costs',
                                    answer: 3
                                },
                                {
                                    key: 'capital_expenditures',
                                    questionTitle: 'Capital expenditure(s)',
                                    answer: 3
                                },
                            ]
                        },
                    ]

                },
                'Concerns': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Concerns',
                            questionTitle: '',
                            subtitle: '',
                            key: 'concerns',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Not At All Concerned', 'Neutral', 'Somewhat Concerned', 'Very Concerned', 'Extremely Concerned'],
                            answerData: SurveyRadioAnswers['concern'],
                            lockAnswers: true,
                            questions: [
                                {
                                    key: 'increased_taxes',
                                    questionTitle: 'Increased taxes',
                                    answer: 3
                                },
                                {
                                    key: 'increased_regulation',
                                    questionTitle: 'Increased regulation',
                                    answer: 3
                                },
                                {
                                    key: 'lingering_economic_uncertainty',
                                    questionTitle: 'Lingering economic uncertainty',
                                    answer: 3
                                },
                                {
                                    key: 'access_to_credit',
                                    questionTitle: 'Access to credit',
                                    answer: 3
                                },
                                {
                                    key: 'interest_rates',
                                    questionTitle: 'Interest rates',
                                    answer: 3
                                },
                                {
                                    key: 'increased_cost_of_labor',
                                    questionTitle: 'Increased cost of labor',
                                    answer: 3
                                },
                                {
                                    key: 'impact_of_healthcare_on_bottom_line',
                                    questionTitle: 'Impact of healthcare on bottom line',
                                    answer: 3
                                },
                                {
                                    key: 'downward_pressure_on_prices',
                                    questionTitle: 'Downward pressure on prices',
                                    answer: 3
                                },
                                {
                                    key: 'growth_and_profitability',
                                    questionTitle: 'Growth & Profitability',
                                    answer: 3
                                },
                                {
                                    key: 'wildcard_risks',
                                    questionTitle: 'Wildcard risks',
                                    answer: 3
                                },
                                {
                                    key: 'increased_competition',
                                    questionTitle: 'Increased competition',
                                    answer: 3
                                },
                                {
                                    key: 'other_concerns',
                                    questionTitle: 'Other',
                                    answer: 3
                                },
                            ]
                        },
                    ]

                },
                'Facilities': {
                    sectionData: [
                        {
                            section: 'Facilities',
                            key: 'number_facilities',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: 'How many individual plants, facilities, offices and/or locations do you have?',
                            answer: null,
                            lockAnswers: false,
                        },
                        {
                            section: 'Facilities',
                            key: 'total_square_feet_facilities',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: 'What is the approximate combined square footage of all your individual plants, facilities, offices and/or locations?',
                            answer: null,
                            lockAnswers: false,
                        },
                    ]
                },
                'Additional Comments': {
                    sectionData: [
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Additional Comments',
                            key: 'any_significant_change_in_the_way_you_do_business',
                            inputType: 'textarea',
                            questionType: 'Text area',
                            questionTitle: 'If you anticipate any significant change in the way you do business, please explain:',
                            answer: '',
                            lockAnswers: false,
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Additional Comments',
                            key: 'additional_comments_youd_like_to_make',
                            inputType: 'textarea',
                            questionType: 'Text area',
                            questionTitle: `If there are additional comments you'd like to make, please use the space provided:`,
                            answer: '',
                            lockAnswers: false,
                        }
                    ]
                }
            },
            'People': {
                sections: ['Expectations', 'Priorities', 'Concerns', 'Headcount', 'Additional Comments'],
                industryMetrics: [],
                'Expectations': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Expectations (0-12 months)',
                            subtitle: 'One Year Expectations',
                            key: 'one_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            questions: [
                                {
                                    key: 'one_year_number_of_employees',
                                    questionTitle: '# of employees',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_number_of_outside_contractors',
                                    questionTitle: '# of outside contractors',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_costs',
                                    questionTitle: 'Costs',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_salaries',
                                    questionTitle: 'Salaries',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_benefits',
                                    questionTitle: 'Benefits',
                                    answer: 3
                                }
                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'one_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: 'Please explain, if necessary:',
                            answer: ''
                        },
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Expectations (13-36 months)',
                            subtitle: 'Three Year Expectations',
                            key: 'three_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            questions: [
                                {
                                    key: 'three_year_number_of_employees',
                                    questionTitle: '# of employees',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_number_of_outside_contractors',
                                    questionTitle: '# of outside contractors',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_costs',
                                    questionTitle: 'Costs',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_salaries',
                                    questionTitle: 'Salaries',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_benefits',
                                    questionTitle: 'Benefits',
                                    answer: 3
                                },

                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'three_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: 'Please explain, if necessary:',
                            answer: ''
                        },
                    ]
                },
                'Priorities': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Priorities',
                            questionTitle: '',
                            subtitle: '',
                            key: 'priorities',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Unimportant', 'Low Importance', 'Neutral', 'Important', 'Very Important'],
                            answerData: SurveyRadioAnswers['importance'],
                            questions: [
                                {
                                    key: 'strategic_alignment_priorities',
                                    questionTitle: 'Strategic alignment',
                                    answer: 3
                                },
                                {
                                    key: 'planning_and_policy_development_priorities',
                                    questionTitle: 'Planning and policy development',
                                    answer: 3
                                },
                                {
                                    key: 'organizational_design_priorities',
                                    questionTitle: 'Organizational design',
                                    answer: 3
                                },
                                {
                                    key: 'performance_management_priorities',
                                    questionTitle: 'Performance management',
                                    answer: 3
                                },
                                {
                                    key: 'wellness_priorities',
                                    questionTitle: 'Wellness',
                                    answer: 3
                                },
                                {
                                    key: 'human_capital_analytics_priorities',
                                    questionTitle: 'Human capital analytics',
                                    answer: 3
                                },
                                {
                                    key: 'benefits_administration_priorities',
                                    questionTitle: 'Benefits administration',
                                    answer: 3
                                },
                                {
                                    key: 'increase_roi_of_human_capital_priorities',
                                    questionTitle: 'Increase ROI of human capital',
                                    answer: 3
                                },
                                {
                                    key: 'cuts_costs_priorities',
                                    questionTitle: 'Cuts costs',
                                    answer: 3
                                },
                                {
                                    key: 'employee_retention_priorities',
                                    questionTitle: 'Employee retention',
                                    answer: 3
                                },
                                {
                                    key: 'employee_acquisition_priorities',
                                    questionTitle: 'Employee acquisition',
                                    answer: 3
                                },
                                {
                                    key: 'outsourcing_priorities',
                                    questionTitle: 'Outsourcing',
                                    answer: 3
                                },
                            ]
                        },
                    ]
                },
                'Concerns': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Concerns',
                            questionTitle: '',
                            subtitle: '',
                            key: 'concerns',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Not At All Concerned', 'Neutral', 'Somewhat Concerned', 'Very Concerned', 'Extremely Concerned'],
                            answerData: SurveyRadioAnswers['concern'],
                            questions: [
                                {
                                    key: 'healthcare_costs_concerns',
                                    questionTitle: 'Healthcare costs',
                                    answer: 3
                                },
                                {
                                    key: 'payroll_taxes_concerns',
                                    questionTitle: 'Payroll taxes',
                                    answer: 3
                                },
                                {
                                    key: 'increased_regulation_concerns',
                                    questionTitle: 'Increased regulation',
                                    answer: 3
                                },
                                {
                                    key: 'ability_to_remain_competitive_concerns',
                                    questionTitle: 'Ability to remain competitive',
                                    answer: 3
                                },
                                {
                                    key: 'employee_acquisition_concerns',
                                    questionTitle: 'Employee acquisition',
                                    answer: 3
                                },
                                {
                                    key: 'employee_retention_concerns',
                                    questionTitle: 'Employee retention',
                                    answer: 3
                                },
                                {
                                    key: 'succession_planning_and_bench_strength_concerns',
                                    questionTitle: 'Succession planning and bench strength',
                                    answer: 3
                                },
                                {
                                    key: 'economic_uncertainty_concerns',
                                    questionTitle: 'Economic uncertainty',
                                    answer: 3
                                },
                                {
                                    key: 'wildcard_risks_concerns',
                                    questionTitle: 'Wildcard risks',
                                    answer: 3
                                },
                                {
                                    key: 'other_concerns',
                                    questionTitle: 'Other',
                                    answer: 3
                                },
                            ]
                        },
                    ]
                },
                'Headcount': {
                    sectionData: [
                        {
                            section: 'Headcount',
                            key: 'senior_management_count',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: `Total # Owner/Partners/Sr. Executives (President, Exec. V.P., CMO, etc.)`,
                            answer: null
                        },
                        {
                            section: 'Headcount',
                            key: 'total_number_of_employees',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: 'Total # of employees:',
                            answer: null
                        },
                        {
                            section: 'Headcount',
                            key: 'number_of_employees_who_left',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: `# of employees who left the company (either voluntarily or involuntarily)`,
                            answer: null
                        },
                    ]
                },
                'Additional Comments': {
                    sectionData: [
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Additional Comments',
                            key: 'any_significant_change_in_the_way_you_do_business',
                            inputType: 'textarea',
                            questionType: 'Text area',
                            questionTitle: 'If you anticipate any significant change in the way you do business, please explain:',
                            answer: ''
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Additional Comments',
                            key: 'additional_comments_youd_like_to_make',
                            inputType: 'textarea',
                            questionType: 'Text area',
                            questionTitle: `If there are additional comments you'd like to make, please use the space provided:`,
                            answer: ''
                        }
                    ]
                }
            },
            'Customers': {
                sections: ['Expectations', 'Concerns', 'Customer Priorities', 'Customer Concentration'],
                industryMetrics: [],
                'Expectations': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Expectations (0-12 months)',
                            subtitle: 'One Year Expectations',
                            key: 'one_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            questions: [
                                {
                                    key: 'one_year_marketing_spend',
                                    questionTitle: 'Marketing spend',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_marketing_roi',
                                    questionTitle: 'Marketing ROI',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_number_of_leads',
                                    questionTitle: '# of leads',
                                    answer: 3
                                },
                                {
                                    key: 'one_year_number_of_customers',
                                    questionTitle: '# of customers',
                                    answer: 3
                                },
                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'one_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: 'Please explain, if necessary:',
                            answer: ''
                        },
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Expectations (13-36 months)',
                            subtitle: 'Three Year Expectations',
                            key: 'three_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            questions: [
                                {
                                    key: 'three_year_marketing_spend',
                                    questionTitle: 'Marketing spend',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_marketing_roi',
                                    questionTitle: 'Marketing ROI',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_number_of_leads',
                                    questionTitle: '# of leads',
                                    answer: 3
                                },
                                {
                                    key: 'three_year_number_of_customers',
                                    questionTitle: '# of customers',
                                    answer: 3
                                },
                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'three_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: 'Please explain, if necessary:',
                            answer: ''
                        },
                    ]
                },
                'Concerns': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Concerns',
                            questionTitle: '',
                            subtitle: '',
                            key: 'concerns',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Not At All Concerned', 'Neutral', 'Somewhat Concerned', 'Very Concerned', 'Extremely Concerned'],
                            answerData: SurveyRadioAnswers['concern'],
                            questions: [
                                {
                                    key: 'targeting_the_right_customers',
                                    questionTitle: 'Targeting the right customers',
                                    answer: 3
                                },
                                {
                                    key: 'implementing_the_right_marketing_strategy',
                                    questionTitle: 'Implementing the right marketing strategy',
                                    answer: 3
                                },
                                {
                                    key: 'finding_the_optimal_marketing_mix',
                                    questionTitle: 'Finding the optimal marketing mix',
                                    answer: 3
                                },
                                {
                                    key: 'adopting_the_right_tools_and_marketing_technologies',
                                    questionTitle: 'Adopting the right tools and marketing technologies',
                                    answer: 3
                                },
                                {
                                    key: 'improving_roi',
                                    questionTitle: 'Improving ROI',
                                    answer: 3
                                },
                                {
                                    key: 'improving_conversion_rates',
                                    questionTitle: 'Improving conversion rates',
                                    answer: 3
                                },
                                {
                                    key: 'seo_and_search_algorithm_changes',
                                    questionTitle: 'SEO and ability to effectively respond to changes to search algorithms',
                                    answer: 3
                                },
                                {
                                    key: 'proliferation_of_new_tools_and_marketing_technologies',
                                    questionTitle: 'Proliferation of new tools and marketing technologies',
                                    answer: 3
                                },
                                {
                                    key: 'pressure_to_continuously_optimize_performance',
                                    questionTitle: 'Pressure to continuously optimize performance',
                                    answer: 3
                                },
                                {
                                    key: 'ability_to_create_compelling_content',
                                    questionTitle: 'Ability to create compelling content',
                                    answer: 3
                                },
                                {
                                    key: 'other_concerns',
                                    questionTitle: 'Other',
                                    answer: 3
                                },
                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Concerns',
                            key: 'other_concerns_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: `If 'other' please explain:`,
                            answer: ''
                        },
                    ]
                },
                'Customer Priorities': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Customer Priorities',
                            questionTitle: '',
                            subtitle: '',
                            key: 'customer_priorities',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Unimportant', 'Low Importance', 'Neutral', 'Important', 'Very Important'],
                            answerData: SurveyRadioAnswers['importance'],
                            questions: [
                                {
                                    key: 'acquisition',
                                    questionTitle: 'Acquisition',
                                    answer: 3
                                },
                                {
                                    key: 'retention',
                                    questionTitle: 'Retention',
                                    answer: 3
                                },
                                {
                                    key: 'reactivation',
                                    questionTitle: 'Reactivation',
                                    answer: 3
                                },
                            ]
                        },
                    ]
                },
                'Customer Concentration': {
                    sectionData: [
                        {
                            section: 'Customer Concentration',
                            key: 'total_number_of_customers',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: `Total number of customers`,
                            answer: null
                        },
                        {
                            section: 'Customer Concentration',
                            key: 'percent_of_customers_that_are_new',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: 'What percent of your customers are new each year?',
                            answer: null
                        },
                        {
                            section: 'Customer Concentration',
                            key: 'percent_of_sales_from_the_top_10_percent',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: `What percent of your total sales come from the top 10 percent of your customers?`,
                            answer: null
                        },
                        {
                            section: 'Customer Concentration',
                            key: 'percent_of_sales_from_the_top_25_percent',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: `What percent of your total sales come from the top 25 percent of your customers?`,
                            answer: null
                        },
                        {
                            section: 'Customer Concentration',
                            key: 'percent_of_sales_from_the_top_50_percent',
                            industrySpecificMetric: false,
                            inputType: 'numberYears',
                            questionType: 'Number Years Inputs',
                            questionTitle: `What percent of your total sales come from the top 50 percent of your customers?`,
                            answer: null
                        }
                    ]
                }
            },
            'Future': {
                sections: ['Satisfaction', 'Expectations', 'Competition', 'Growth', 'Additional Comments'],
                industryMetrics: [],
                'Satisfaction': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Satisfaction',
                            questionTitle: '',
                            subtitle: '',
                            key: 'satisfaction',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Very Unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very Satisfied'],
                            answerData: SurveyRadioAnswers['satisfaction'],
                            questions: [
                                {
                                    key: 'how_satisfied_are_you_with_your_companys_performance',
                                    questionTitle: `How satisfied are you with your company's performance?`,
                                    answer: 3
                                },
                                {
                                    key: 'how_satisfied_are_you_with_your_companys_management',
                                    questionTitle: `How satisfied are you with your company's management?`,
                                    answer: 3
                                },
                                {
                                    key: 'how_satisfied_are_you_with_your_companys_workforce',
                                    questionTitle: `How satisfied are you with your company's workforce?`,
                                    answer: 3
                                },
                                {
                                    key: 'how_satisfied_are_you_with_your_companys_ability_to_compete',
                                    questionTitle: `How satisfied are you with your company's ability to compete?`,
                                    answer: 3
                                },
                                {
                                    key: 'how_satisfied_are_you_with_your_companys_products_services',
                                    questionTitle: `How satisfied are you with your company's products/services?`,
                                    answer: 3
                                },
                            ]
                        },
                    ]
                },
                'Expectations': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Expectations',
                            questionTitle: 'Five Year Expectations (60+ months)',
                            subtitle: '',
                            key: 'five_year_expectations',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Big Decrease', 'Slight Decrease', 'Same', 'Slight Increase', 'Big Increase'],
                            answerData: SurveyRadioAnswers['growth'],
                            questions: [
                                {
                                    key: 'five_year_revenue',
                                    questionTitle: 'Revenue',
                                    answer: 3
                                },
                                {
                                    key: 'five_year_profitability',
                                    questionTitle: 'Profitability',
                                    answer: 3
                                },
                                {
                                    key: 'five_year_number_of_employees',
                                    questionTitle: '# of employees',
                                    answer: 3
                                },
                                {
                                    key: 'five_year_op_ex',
                                    questionTitle: 'OP-EX',
                                    answer: 3
                                },
                                {
                                    key: 'five_year_taxes',
                                    questionTitle: 'Taxes',
                                    answer: 3
                                },
                                {
                                    key: 'five_year_wages_and_salaries',
                                    questionTitle: 'Wages & Salaries',
                                    answer: 3
                                },
                                {
                                    key: 'five_year_benefits',
                                    questionTitle: 'Benefits',
                                    answer: 3
                                }
                            ]
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Expectations',
                            key: 'five_year_explanation',
                            inputType: 'text',
                            questionType: 'Text',
                            questionTitle: `Please explain, if necessary:`,
                            answer: ''
                        },
                    ]
                },
                'Competition': {
                    sectionData: [
                        {
                            chartType: 'stackedBarRanking',
                            format: 'percent',
                            section: 'Competition',
                            questionTitle: 'Ability to Compete',
                            subtitle: '',
                            key: 'competitive_advantages',
                            inputType: 'ranking',
                            questionType: 'Ranking',
                            answerOptions: [1, 2, 3, 4],
                            answerData: {
                                1: 1,
                                2: 2,
                                3: 3,
                                4: 4
                            },
                            questions: [
                                {
                                    key: 'competitive_advantages_price',
                                    questionTitle: 'Price',
                                    answer: 1
                                },
                                {
                                    key: 'competitive_advantages_quality',
                                    questionTitle: 'Quality',
                                    answer: 2
                                },
                                {
                                    key: 'competitive_advantages_compatibility',
                                    questionTitle: 'Compatibility',
                                    answer: 3
                                },
                                {
                                    key: 'competitive_advantages_timeliness',
                                    questionTitle: 'Timeliness',
                                    answer: 4
                                },
                            ]
                        },
                    ]
                },
                'Growth': {
                    sectionData: [
                        {
                            chartType: 'stackedBar',
                            format: 'percent',
                            section: 'Growth',
                            questionTitle: 'Source of Growth',
                            subtitle: 'In the future, additional revenues will most likely come from which of the following:',
                            key: 'growth',
                            inputType: 'radio',
                            questionType: 'Group Questions',
                            answerOptions: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'],
                            answerData: SurveyRadioAnswers['likelihood'],
                            questions: [
                                {
                                    key: 'new_markets',
                                    questionTitle: 'New markets',
                                    answer: 3
                                },
                                {
                                    key: 'existing_markets',
                                    questionTitle: 'Existing markets',
                                    answer: 3
                                },
                                {
                                    key: 'cost_reduction',
                                    questionTitle: 'Cost reduction',
                                    answer: 3
                                },
                                {
                                    key: 'technological_innovation',
                                    questionTitle: 'Technological innovation',
                                    answer: 3
                                },
                                {
                                    key: 'inflation',
                                    questionTitle: 'Inflation',
                                    answer: 3
                                }
                            ]
                        },
                    ]
                },
                'Additional Comments': {
                    sectionData: [
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Additional Comments',
                            key: 'any_significant_change_in_the_way_you_do_business',
                            inputType: 'textarea',
                            questionType: 'Text area',
                            questionTitle: 'If you anticipate any significant change in the way you do business, please explain:',
                            answer: ''
                        },
                        {
                            chartType: 'text',
                            format: 'text',
                            section: 'Additional Comments',
                            key: 'additional_comments_youd_like_to_make',
                            inputType: 'textarea',
                            questionType: 'Text area',
                            questionTitle: `If there are additional comments you'd like to make, please use the space provided:`,
                            answer: ''
                        }
                    ]
                }
            }
        }
    })

    const snake_case_converter = (value) => {
        let allLowerCase = value.toLowerCase().replaceAll('&', 'and');
        let snakeCaseValue = allLowerCase.split(/[^\w\*]+/).join('_')
        // cleans up any underscores at the end of the string
        let finalValue = snakeCaseValue.replace(/_+$/, '')
        return finalValue;
    }

    const handleSectionChange = (surveyType, oldSection, index, value) => {
        setSurveyFormat(prevState => {
            if (prevState.layout[surveyType].hasOwnProperty(oldSection)) {
                let newLayout = _.cloneDeep(prevState.layout)
                newLayout[surveyType]['sections'][index] = value;
                newLayout[surveyType][value] = {}
                newLayout[surveyType][value]['sectionData'] = newLayout[surveyType][oldSection]['sectionData'].map(data => {
                    data['section'] = data['section'] === oldSection ? value : data['section']
                    return data;
                })
                delete newLayout[surveyType][oldSection]
                return {
                    ...prevState,
                    layout: newLayout
                }
            }
        })
        if (newQuestion.selectedSection === oldSection) {
            setNewQuestion(prevState => {
                return {
                    ...prevState,
                    selectedSection: value
                }
            })
        }
    }

    const deleteQuestion = (e, survey, section, questionKey) => {
        e.preventDefault()
        setNewQuestion(prevState => {
            return {
                ...prevState,
                addAfterQuestionIndex: 0
            }
        })
        setSurveyFormat(prevState => {
            let surveySectionData = _.cloneDeep(prevState['layout'])
            let newQuestions = surveySectionData[survey][section]['sectionData'].filter(q => q.key !== questionKey)
            surveySectionData[survey][section]['sectionData'] = newQuestions

            return {
                ...prevState,
                layout: surveySectionData
            }
        })
    }

    const handleAddingNewQuestion = (e) => {
        e.preventDefault();
        let q = {}
        let numberOfQuestions = surveyFormat['layout'][newQuestion.selectedSurveyType][newQuestion.selectedSection]['sectionData'].length
        let sectionFormattedName = snake_case_converter(newQuestion.selectedSection)
        let surveyFormatted = newQuestion.selectedSurveyType.toLowerCase()
        let questionType = newQuestion.questionTypes.find(type => type[1] === newQuestion.selectedQuestionType)
        switch (newQuestion.selectedQuestionType) {
            case 'radio':
            case 'ranking':
                q['section'] = newQuestion.selectedSection;
                q['questionTitle'] = '';
                q['subtitle'] = '';
                q['key'] = `${surveyFormatted}_${sectionFormattedName}_group_question_${numberOfQuestions}`;
                q['inputType'] = newQuestion.selectedQuestionType;
                q['questionType'] = questionType[0];
                q['answerOptions'] = Object.values(newQuestion.optionValues);
                q['answerData'] = newQuestion.optionValues;
                q['lockAnswers'] = true;
                q['questions'] = [];
                break;
            case 'text':
            case 'textarea':
                q['section'] = newQuestion.selectedSection;
                q['key'] = newQuestion.questionText ? snake_case_converter(newQuestion.questionText) : `${surveyFormatted}_${sectionFormattedName}_${newQuestion.selectedQuestionType}_${numberOfQuestions}`;
                q['inputType'] = newQuestion.selectedQuestionType;
                q['questionType'] = questionType[0];
                q['questionTitle'] = newQuestion.questionText;
                q['answer'] = null;
                q['lockAnswers'] = false;
                break;
            case 'numberYears':
                q['section'] = newQuestion.selectedSection;
                q['key'] = newQuestion.questionText ? snake_case_converter(newQuestion.questionText) : `${surveyFormatted}_${sectionFormattedName}_${newQuestion.selectedQuestionType}_${numberOfQuestions}`;
                q['industrySpecificMetric'] = false;
                q['inputType'] = newQuestion.selectedQuestionType;
                q['questionType'] = questionType[0];
                q['questionTitle'] = newQuestion.questionText;
                q['answer'] = null;
                q['lockAnswers'] = false;
                break;
        }
        setSurveyFormat(prevState => {
            let surveySectionData = _.cloneDeep(prevState['layout'])
            if (newQuestion.addBeforeQuestion) {
                surveySectionData[newQuestion.selectedSurveyType][newQuestion.selectedSection]['sectionData'].splice(newQuestion.addAfterQuestionIndex, 0, q)
            } else {
                surveySectionData[newQuestion.selectedSurveyType][newQuestion.selectedSection]['sectionData'].splice(newQuestion.addAfterQuestionIndex + 1, 0, q)
            }
            return {
                ...prevState,
                layout: surveySectionData
            }
        })
    }

    const deleteOption = (e, surveyType, section, groupKey, optionKey) => {
        e.preventDefault()
        setSurveyFormat(prevState => {
            let surveySectionData = _.cloneDeep(prevState['layout'])
            let newQuestions = surveySectionData[surveyType][section]['sectionData'].map(question => {
                if (question.key === groupKey) {
                    let newOptions = question['questions'].filter(option => option.key !== optionKey)
                    return {
                        ...question,
                        questions: newOptions
                    }
                } else {
                    return question
                }
            })
            surveySectionData[surveyType][section]['sectionData'] = newQuestions
            return {
                ...prevState,
                layout: surveySectionData
            }
        })
    }

    const addOption = (e, surveyType, section, groupKey) => {
        e.preventDefault()
        setSurveyFormat(prevState => {
            let surveySectionData = _.cloneDeep(prevState['layout'])

            let newQuestions = surveySectionData[surveyType][section]['sectionData'].map(question => {
                if (question.key === groupKey) {
                    let groupQuestions = question['questions'];
                    let numberOfQuestions = groupQuestions.length;
                    let newOption = {
                        key: `new_option_${numberOfQuestions}`,
                        questionTitle: 'New Option',
                        answer: 3
                    }
                    groupQuestions.push(newOption)
                    return {
                        ...question,
                        questions: groupQuestions
                    }
                } else {
                    return question
                }
            })
            surveySectionData[surveyType][section]['sectionData'] = newQuestions
            return {
                ...prevState,
                layout: surveySectionData
            }
        })
    }

    const handleOptionEdits = (e, surveyType, section, groupKey, optionKey, groupIndex, optionindex, type) => {
        setSurveyFormat(prevState => {
            let surveySectionData = _.cloneDeep(prevState['layout'])
            if (type === 'title') {
                surveySectionData[surveyType][section]['sectionData'][groupIndex]['questions'][optionindex]['questionTitle'] = e.target.value
            } else {
                surveySectionData[surveyType][section]['sectionData'][groupIndex]['questions'][optionindex]['key'] = e.target.value
            }

            return {
                ...prevState,
                layout: surveySectionData
            }
        })
    }

    const RadioAndRankingQs = (props) => {
        let inputClass = props.data.inputType === 'ranking' ? 'ranking-container' : 'radio-container'
        let divClass = 'edit-question-group-container ' + inputClass;
        return (
            <div key={props.index} className={divClass}>
                <p className='question-type'>Question Type: <span>{props.data.questionType}</span></p>
                <p className='question-values'>Values: <span>{props.data.answerOptions.join(', ')}</span></p>
                <div>
                    <label htmlFor={`${props.index}-title`}>Question Group Title: </label>
                    <textarea id={`${props.index}-title`} key={`${props.index}-title`} value={props.data.questionTitle} onChange={(e) => handleQuestionTitleAndMetricKey(e, props.index, props.surveyType, props.section, props.data.key, 'title')} />
                </div>
                <div>
                    <label htmlFor={`${props.index}-metric-key`}>Metric Key: </label>
                    <textarea id={`${props.index}-metric-key`} key={`${props.index}-metric-key`} value={props.data.key} onChange={(e) => handleQuestionTitleAndMetricKey(e, props.index, props.surveyType, props.section, props.data.key, 'key')} />
                </div>
                <div>
                    <label htmlFor={`${props.index}-subtitle`}>Subtitle: </label>
                    <textarea id={`${props.index}-subtitle`} key={`${props.index}-subtitle`} value={props.data.subtitle} onChange={(e) => handleQuestionTitleAndMetricKey(e, props.index, props.surveyType, props.section, props.data.key, 'subtitle')} />
                </div>
                {
                    (props.data['questions'].map((q, i) => {
                        return (
                            <div key={`${i}-container`} className='option-container'>
                                <div className='option-inputs'>
                                    <div>
                                        <label htmlFor={i}>Option: </label>
                                        <textarea id={i} key={i} value={q.questionTitle} onChange={(e) => handleOptionEdits(e, props.surveyType, props.section, props.data.key, q.key, props.index, i, 'title')} />
                                    </div>
                                    <div>
                                        <label htmlFor={i}>Metric Key: </label>
                                        <textarea id={i} key={i} value={q.key} onChange={(e) => handleOptionEdits(e, props.surveyType, props.section, props.data.key, q.key, props.index, i, 'key')} />
                                    </div>
                                </div>
                                <button onClick={(e) => deleteOption(e, props.surveyType, props.section, props.data.key, q.key)}>Delete Option</button>
                            </div>
                        )
                    }))
                }
                <button className='bottom-btns' onClick={(e) => addOption(e, props.surveyType, props.section, props.data.key)}>Add Option</button>
                <button className='bottom-btns' onClick={(e) => deleteQuestion(e, props.surveyType, props.section, props.data.key)}>Delete Question</button>
            </div>
        )

    }

    const handleQuestionTitleAndMetricKey = (e, questionIndex, survey, section, questionKey, type) => {
        setSurveyFormat(prevState => {
            let surveySectionData = _.cloneDeep(prevState['layout'])
            if (type === 'title') {
                surveySectionData[survey][section]['sectionData'][questionIndex]['questionTitle'] = e.target.value
            } else if (type === 'key') {
                surveySectionData[survey][section]['sectionData'][questionIndex]['key'] = e.target.value
            } else if (type === 'subtitle') {
                surveySectionData[survey][section]['sectionData'][questionIndex]['subtitle'] = e.target.value
            } else if (type === 'checkbox') {
                surveySectionData[survey][section]['sectionData'][questionIndex]['industrySpecificMetric'] = !surveySectionData[survey][section]['sectionData'][questionIndex]['industrySpecificMetric'];
                let checkIndustryMetricExists = surveySectionData[survey]['industryMetrics'].find(m => m === questionKey);
                if (surveySectionData[survey][section]['sectionData'][questionIndex]['industrySpecificMetric']) {
                    if (!checkIndustryMetricExists) surveySectionData[survey]['industryMetrics'].push(questionKey);
                } else {
                    if (checkIndustryMetricExists) surveySectionData[survey]['industryMetrics'] = surveySectionData[survey]['industryMetrics'].filter(m => m !== questionKey);
                }
            }
            return {
                ...prevState,
                layout: surveySectionData
            }
        })
    }

    const addSection = (e, surveyType, sectionIndex, direction) => {
        e.preventDefault()
        setSurveyFormat(prevState => {
            let newLayout = _.cloneDeep(prevState.layout)
            let newSectionName = `New Section ${Math.random(3)}`
            if (direction === 'left' && sectionIndex === 0) {
                newLayout[surveyType]['sections'].splice(0, 0, newSectionName)
            }

            if (direction === 'left' && sectionIndex !== 0) {
                newLayout[surveyType]['sections'].splice(sectionIndex, 0, newSectionName)
            }

            if (direction === 'right') {
                newLayout[surveyType]['sections'].splice(sectionIndex + 1, 0, newSectionName)
            }
            newLayout[surveyType][newSectionName] = { sectionData: [] }
            return {
                ...prevState,
                layout: newLayout
            }
        })
    }

    const deleteSection = (e, surveyType, oldSection, oldSectionIndex) => {
        e.preventDefault()
        let newSection = '';
        if (newQuestion.selectedSection === oldSection) {
            setNewQuestion(prevState => {
                return {
                    ...prevState,
                    selectedSection: ''
                }
            })
        }
        setSurveyFormat(prevState => {
            let newLayout = _.cloneDeep(prevState.layout)
            newLayout[surveyType]['sections'].splice(oldSectionIndex, 1)
            delete newLayout[surveyType][oldSection]
            return {
                ...prevState,
                layout: newLayout
            }
        })

    }

    const EditSurveyFormSection = (props) => {
        let surveySelectedFormat = props.surveyFormat.layout[props.surveyType]
        let surveySections = surveySelectedFormat.sections
        return (
            <div className='edit-survey-type-container'>
                {
                    surveySections.map((section, i) => {
                        let sectionQuestions = surveySelectedFormat[section]['sectionData'].filter(data => data['section'] === section)
                        return (
                            <div className='edit-survey-section-container' key={`edit-survey-section-${i}`} >
                                <div className='survey-section-name-input'>
                                    <button onClick={(e) => addSection(e, props.surveyType, i, 'left')}>Add Section to the Left &#8592;</button>
                                    <label htmlFor={`survey-section-name-${i}`} >Section Name: </label>
                                    <input id={`survey-section-name-${i}`} key={`survey-section-name-${i}`} value={section} onChange={(e) => handleSectionChange(props.surveyType, section, i, e.target.value)} />
                                    <button onClick={(e) => deleteSection(e, props.surveyType, section, i)}>Delete Section</button>
                                    <button onClick={(e) => addSection(e, props.surveyType, i, 'right')}>Add Section to the Right &#8594;</button>
                                </div>
                                <div>
                                    {
                                        sectionQuestions.map((data, j) => {
                                            switch (data['inputType']) {
                                                case 'text':
                                                case 'textarea':
                                                    let inputClass = data.inputType === 'text' ? 'text-container' : 'textarea-container';
                                                    let divClass = 'edit-question-group-container ' + inputClass;
                                                    return (
                                                        <div key={`${data}-${j}`} className={divClass}>
                                                            <p className='question-type'>Question Type: {data.questionType}</p>
                                                            <div>
                                                                <label htmlFor={`question-title-${j}`}>Question: </label>
                                                                <textarea id={`question-title-${j}`} key={`question-title-${j}`} value={data.questionTitle} onChange={(e) => handleQuestionTitleAndMetricKey(e, j, props.surveyType, section, data.key, 'title')} />
                                                            </div>
                                                            <div>
                                                                <label htmlFor={`${j}-metric-key`}>Metric Key: </label>
                                                                <textarea id={`${j}-metric-key`} key={`${j}-metric-key`} value={data.key} onChange={(e) => handleQuestionTitleAndMetricKey(e, j, props.surveyType, section, data.key, 'key')} />
                                                            </div>
                                                            <button className='bottom-btns' onClick={(e) => deleteQuestion(e, props.surveyType, section, data.key)}>Delete Question</button>
                                                        </div>
                                                    )
                                                case 'radio':
                                                case 'ranking':
                                                    return RadioAndRankingQs({ surveyType: props.surveyType, section: section, data: data, index: j })
                                                case 'numberYears':
                                                    return (
                                                        <div key={`${data}-${j}`} className='edit-question-group-container numberYears-container'>
                                                            <p className='question-type'>Question Type: {data.questionType}</p>
                                                            <div>
                                                                <label htmlFor={`question-title-${j}`}>Question: </label>
                                                                <textarea id={`question-title-${j}`} key={`question-title-${j}`} value={data.questionTitle} onChange={(e) => handleQuestionTitleAndMetricKey(e, j, props.surveyType, section, data.key, 'title')} />
                                                            </div>
                                                            <div>
                                                                <label htmlFor={`${j}-metric-key`}>Metric Key: </label>
                                                                <textarea id={`${j}-metric-key`} key={`${j}-metric-key`} value={data.key} onChange={(e) => handleQuestionTitleAndMetricKey(e, j, props.surveyType, section, data.key, 'key')} />
                                                            </div>
                                                            <label className='industry-metric-label'>
                                                                <span>Industry Specific Metric?</span>
                                                                <input
                                                                    name='industry-metric'
                                                                    type='checkbox'
                                                                    checked={data.industrySpecificMetric}
                                                                    onChange={(e) => handleQuestionTitleAndMetricKey(e, j, props.surveyType, section, data.key, 'checkbox')}
                                                                    className='industry-metric-check-input'
                                                                />
                                                            </label>
                                                            <button className='bottom-btns' onClick={(e) => deleteQuestion(e, props.surveyType, section, data.key)}>Delete Question</button>
                                                        </div>
                                                    )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )

    }

    const handleNewQuestionInputs = (e, inputType) => {
        let target = e.target
        switch (target.name) {
            case 'question-input-type':
                setNewQuestion((prevState) => {
                    let newAnswerData = null;
                    if (target.value === 'radio') {
                        newAnswerData = {
                            1: 'Very Unlikely',
                            2: 'Unlikely',
                            3: 'Neutral',
                            4: 'Likely',
                            5: 'Very likely'
                        }
                    }
                    if (target.value === 'ranking') {
                        newAnswerData = {
                            1: 1,
                            2: 2,
                            3: 3,
                            4: 4,
                            5: 5
                        }
                    }
                    return {
                        ...prevState,
                        numberOfValues: 5,
                        selectedQuestionType: target.value,
                        optionValues: newAnswerData,
                    }
                })
                break;
            case 'new-q-survey-type':
                setSurveyType(target.value);
                setNewQuestion((prevState) => {
                    return {
                        ...prevState,
                        selectedSection: surveyFormat['layout'][target.value]['sections'][0],
                        selectedSurveyType: target.value,
                        addAfterQuestionIndex: 0
                    }
                })
                break;
            case 'new-q-section-type':
                setNewQuestion((prevState) => {
                    return {
                        ...prevState,
                        selectedSection: target.value,
                        addAfterQuestionIndex: 0
                    }
                })
                break;
            case 'new-q-how-many-values':
                setNewQuestion((prevState) => {
                    let newAnswerData = {}
                    for (let i = 0; i < target.value; i++) {
                        if (inputType === 'radio') {
                            newAnswerData[i + 1] = ''
                        }
                        if (inputType === 'ranking') {
                            newAnswerData[i + 1] = i + 1
                        }
                    }
                    return {
                        ...prevState,
                        numberOfValues: target.value,
                        optionValues: newAnswerData
                    }
                })
                break;
            case 'new-q-before-after-check':
                setNewQuestion((prevState) => {
                    return {
                        ...prevState,
                        addBeforeQuestion: !prevState.addBeforeQuestion
                    }
                })
                break;
            case 'new-q-position':
                setNewQuestion((prevState) => {
                    return {
                        ...prevState,
                        addAfterQuestionIndex: Number(target.value)
                    }
                })
                break;
        }
    }

    const handleRadioValues = (e, index) => {
        setNewQuestion((prevState) => {
            let newOptionValues = prevState.optionValues
            newOptionValues[index] = e.target.value
            return {
                ...prevState,
                optionValues: newOptionValues
            }
        })
    }

    const handleNewQuestionText = ({ target }) => {
        setNewQuestion((prevState) => {
            return {
                ...prevState,
                questionText: target.value
            }
        })
    }

    const NewTextorTextAreaQuestion = () => {
        return (
            <div>
                <label htmlFor='new-q-text'>New Question: </label>
                <input id='new-q-text' name='new-q-text' type='text' value={newQuestion.questionText} onChange={(e) => handleNewQuestionText(e)} />
            </div>
        )
    }

    const NewRadioOrRankingQuestion = (inputType) => {
        let radioValues = []
        for (let i = 0; i < newQuestion.numberOfValues; i++) {
            if (inputType === 'radio') {
                radioValues.push(
                    <div key={`radio-value-${i}-${i}`}>
                        <label htmlFor={`${i}-${i}`}>{i + 1}:</label>
                        <input id={`${i}-${i}`} key={`${i}-${i}`} value={newQuestion.optionValues[i + 1]} type='text' required onChange={(e) => handleRadioValues(e, i + 1)} />
                    </div>
                )
            }

            if (inputType === 'ranking') {
                radioValues.push(<span key={`ranking-value-${i}-${i}`}>{i + 1}</span>)
            }
        }

        return (
            <div>
                <label htmlFor='new-q-how-many-values'>How Many Values?</label>
                <input id='new-q-how-many-values' name='new-q-how-many-values' type='number' required value={newQuestion.numberOfValues} min='2' max='10' onChange={(e) => handleNewQuestionInputs(e, inputType)} />
                <div>
                    {radioValues}
                </div>

            </div>
        )
    }

    const CreateInputsForNewQuestion = () => {
        switch (newQuestion.selectedQuestionType) {
            case 'radio':
            case 'ranking':
                let qType = newQuestion['selectedQuestionType'];
                return NewRadioOrRankingQuestion(qType)
            case 'text':
            case 'textarea':
            case 'numberYears':
                return NewTextorTextAreaQuestion();
            default:
                return null
        }
    }

    const getNaicsCodeSurveyInfo = async (e) => {
        e.preventDefault()
        const res = await fetch(`/admin/naicses/survey_formats/${surveyFormat.naics_id}`, {
            method: 'GET',
            headers: {
                'X-CSRF-Token': window.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const resJson = await res.json();
        setSurveyFormat((prevState) => {
            return {
                ...prevState,
                naics_code: resJson.code,
                naics_name: resJson.naics_name,
                layout: resJson.survey_format
            }
        })
    }

    const handleNaicsCodeSelection = ({ target }) => {
        setSurveyFormat((prevState) => {
            return {
                ...prevState,
                naics_id: Number(target.value)
            }
        })

    }

    const handleUpdateSurveyChanges = async (e) => {
        e.preventDefault();
        let changes = confirm(`Are you sure you want to save new changes? Double check to make sure the metric keys are unique to each survey. Also make sure metric keys are using lowercase and underscores ( _ ) instead of spaces between any words. Ex. are_you_sure_you_want_to_save`);
        if (changes === true) {
            try {
                const json = await fetch(`/admin/naicses/survey_formats/${surveyFormat.naics_id}`, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': window.token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        survey_format: surveyFormat.layout
                    })
                })
                const res = await json.json()
                if (!res.ok) {
                    throw new Error('Network response was not ok.');
                } else {
                    alert('Working on survey format changes!')
                }
            } catch (error) {
                console.log(error)
                alert('Error, try again later.')
            }
        }
    }

    const buildNewQuestionPositionOptions = () => {
        if (surveyFormat['layout'][surveyType].hasOwnProperty(newQuestion['selectedSection'])) {
            return (
                surveyFormat['layout'][surveyType][newQuestion['selectedSection']]['sectionData'].map((question, i) => {
                    return (<option key={question.key} value={i}>{question.key}</option>)
                })
            )
        }
    }

    const handleSurveyChange = (survey) => {
        setSurveyType(survey);
        setNewQuestion(prevState => {
            return {
                ...prevState,
                selectedSurveyType: survey
            }
        });
    }

    return (
        <div>
            <h1>Edit Survey: {surveyFormat.naics_code} {surveyFormat.naics_name}</h1>
            <form className='naics-code-selection-form' onSubmit={(e) => getNaicsCodeSurveyInfo(e)}>
                <label htmlFor='naics-code-select'>Choose NAICS Code:</label>
                <select name='naics-code-select' value={surveyFormat.naics_id} onChange={handleNaicsCodeSelection}>
                    {
                        props.naics_list.map((naics) => {
                            if (naics[1] === 'master') {
                                return (<option key={naics[0]} value={naics[0]}>{naics[1]}</option>)
                            } else {
                                return (<option key={naics[0]} value={naics[0]}>{`${naics[1]}-${naics[2]}`}</option>)
                            }
                        })
                    }
                </select>
                <button className='btn btn-primary' type='submit'>Get NAICS Code Survey Info</button>
            </form>
            <div>
                <form className='add-new-question-form' onSubmit={(e) => handleAddingNewQuestion(e)}>
                    <h2>Add a New Question</h2>
                    <div className='add-new-question-part-1'>
                        <div>
                            <label htmlFor='question-input-type'>What type of question input?</label>
                            <select name='question-input-type' value={newQuestion.selectedQuestionType} onChange={handleNewQuestionInputs}>
                                {
                                    newQuestion.questionTypes.map(q => {
                                        return (<option key={q[1]} value={q[1]}>{q[0]}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <label>Survey Type:</label>
                            <select name='new-q-survey-type' value={surveyType} onChange={handleNewQuestionInputs}>
                                {
                                    surveyFormat.surveyTypes.map(survey => {
                                        return (<option key={survey} value={survey}>{survey}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <label htmlFor='new-q-section-type' >Which Section?</label>
                            <select id='new-q-section-type' name='new-q-section-type' value={newQuestion['selectedSection']} onChange={handleNewQuestionInputs}>
                                {
                                    surveyFormat['layout'][surveyType]['sections'].map(section => {
                                        return (<option key={section} value={section}>{section}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='new-q-before-after-check'>
                        <label htmlFor='new-q-position'>Add it</label>
                        <label className='new-q-before-after-check-label'>
                            <input
                                name='new-q-before-after-check'
                                type='checkbox'
                                checked={newQuestion.addBeforeQuestion}
                                onChange={(e) => handleNewQuestionInputs(e)}
                                className='form-check-input'
                            />
                            <span>Before</span>
                        </label>
                        <label className='new-q-before-after-check-label'>
                            <input
                                name='new-q-before-after-check'
                                type='checkbox'
                                checked={!newQuestion.addBeforeQuestion}
                                onChange={(e) => handleNewQuestionInputs(e)}
                                className='form-check-input'
                            />
                            <span>After Question: </span>
                        </label>
                        <select id='new-q-position' name='new-q-position' value={newQuestion['addAfterQuestionIndex']} onChange={handleNewQuestionInputs}>
                            {buildNewQuestionPositionOptions()}
                        </select>
                    </div>

                    {CreateInputsForNewQuestion()}
                    <button className='btn btn-primary' type='submit'>Add Question</button>
                </form>
            </div>
            <div>
                <ul className='edit-survey-tabs'>
                    <div>View Survey: </div>
                    {
                        surveyFormat.surveyTypes.map((survey, i) =>
                            <div key={i} className={survey === surveyType ? 'current-survey-tab' : ''} onClick={() => handleSurveyChange(survey)}>{survey}</div>
                        )
                    }
                </ul>
            </div>
            <div className='edit-survey-info'>
                <h2>Edit Carefully!!!</h2>
                <p>Make sure the <b>metric keys</b> are <b>unique and simple</b> to each survey.</p>
                <p>Also make sure metric keys are using <b>lowercase</b> and <b>underscores</b> instead of spaces between any words. <b>Ex. my_awesome_question</b> </p>
                <p>The Number Years Inputs will display 3 years for users to enter number values. Ex. 2019: 10, 2020: 20, 2021: 30.</p>
                <p>If you make a question asking, for example, 'How many dentists do you have?', then make the metric key simple and descriptive like 'number_of_dentists'.</p>
                <p>This is vey important to make sure the survey responses are being collected and counted properly.</p>
            </div>
            <div>
                <form onSubmit={(e) => handleUpdateSurveyChanges(e)}>
                    {EditSurveyFormSection({ surveyType: surveyType, surveyFormat: surveyFormat })}
                    <button className='btn btn-primary' type='submit'>Save Survey Changes</button>
                </form>
            </div>
        </div>
    )
}

export default EditSurveyFormat;