import React, { useState } from 'react';
import Modal from 'react-modal';
import ProgressBar from './ProgressBar';
import Alert from './Alert';

const jobDictionary = {
  CompanyCalculationWorker: {
    message: 'Sit back and relax while the magic happens!',
    success: 'Successfully Updated Client Data.',
  },
  QuickBooksExtractorWorker: {
    message: 'Mapping your QuickBooks accounts...',
    success: 'Your QuickBooks Accounts have been mapped!',
  },
  SageIntacctExtractorWorker: {
    message: 'Mapping your Sage Intacct accounts...',
    success: 'Your Sage Intacct Accounts have been mapped!',
  },
  XeroExtractorWorker: {
    message: 'Mapping your Xero accounts...',
    success: 'Your Xero Accounts have been mapped!',
  },
  BulkLoadWorker: {
    message: 'Creating clients...',
    success: 'Your Companies Have Been Uploaded!',
  },
  ExtractWorker: {
    message: 'Uploading Your File...',
    success: 'Your File Has Been Uploaded!',
  },
  ChartOfAccountsWorker: {
    message: 'Updating Chart of Accounts...',
    success: 'Updated Chart of Accounts',
  },
  PracticeCalculationWorker: {
    message: 'Working on Practice Calcs...',
    success: 'Updated Practice Calcs',
  }
}

// figure out what is going here and uncomment this
// Modal.setAppElement('#progress-bar-target')

const ProgressBarModal = (props) => {
  const [progressModal, setProgressModal] = useState({
    render: true,
    failure: false,
    renderOnFinish: false,
  })

  const closeModal = () => {
    setProgressModal((prevState) => {
      return {
        ...prevState,
        render: false
      }
    })
  }

  const { message, success } = jobDictionary[props.job];

  const unmount = (args) => {
    // Page/URL redirect
    if (args.refresh == true) { props.path ? window.location = props.path : location.reload() }
    if (props.renderOnFinish) {
      setProgressModal((prevState) => {
        return {
          ...prevState,
          renderOnFinish: true
        }
      })
    } else {
      setProgressModal((prevState) => {
        return {
          ...prevState,
          render: false,
          failure: args.failure
        }
      })
    };
  }

  const buildModal = (message) => {
    if (progressModal.renderOnFinish) return (
      <Modal
        isOpen={progressModal.render}
        style={props.style}
        className={{
          base: 'progress-modal-content',
          afterOpen: 'progress-modal-content_after-open',
          beforeClose: 'progress-modal-content_before-close',
        }}
        overlayClassName={{
          base: 'progress-overlay-base',
          afterOpen: 'progress-overlay-base_after-open',
          beforeClose: 'progress-overlay-base_before-close',
        }} >
        {props.renderOnFinish(closeModal())}
      </Modal>
    )

    return (
      <Modal
        isOpen={progressModal.render}
        style={props.style}
        className={{
          base: 'progress-modal-content',
          afterOpen: 'progress-modal-content_after-open',
          beforeClose: 'progress-modal-content_before-close',
        }}
        overlayClassName={{
          base: 'progress-overlay-base',
          afterOpen: 'progress-overlay-base_after-open',
          beforeClose: 'progress-overlay-base_before-close',
        }}
      >
        <div className='progress-bar-content'>
          <h1>{message}</h1>
          <ProgressBar
            {...props}
            unmount={unmount}
          />
          {props.subscribed ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}><h2>This may take a while, but you can still use the rest of the app.</h2><h2 style={{ marginBottom: '20px' }}>We'll notify you when it's done.</h2><a className='btn btn-primary' href={props.url} >Manage Clients</a></div> : null}
        </div>
      </Modal>
    )
  }

  return (
    <div className='progress-bar-component'>
      {progressModal.render
        ? buildModal(message)
        : <Alert job={props.job} successMessage={success} failure={progressModal.failure} />}
    </div>
  )

}

export default ProgressBarModal;
