import React, { useState, useContext } from 'react';
import { Data, Filters } from './contexts';
import CoverReportPage from './utils/CoverReportPage';
import PeopleCustomersKPIs from './report_components/PeopleCustomersKPIs';
import BigTableChartsForSheets from './report_components/BigTableChartsForSheets';
import EditParentChildDashboard from './utils/EditParentChildDashboard';
import { peopleCustomersT12Metrics } from './report_components/PeopleCustomersHelpers'
import ParentDashboard from './ParentDashboard';
import DashboardViewControls from './utils/DashboardViewControls';
import SurveyResponses from './SurveyResponses';
import { getDashBoardPagesPDF } from './PDFDashboardHelper';
import moment from 'moment';

const monthsObjBuilder = (fiscalYearStartMonth = 1, reportPeriod = 'Trailing 12', year = null) => {
  const monthsObj = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC'
  }
  if (reportPeriod === 'Trailing 12') {
    let months = Object.values(monthsObj)
    const d = new Date();
    let monthIndex = d.getMonth();
    let startMonths = months.splice(monthIndex)
    let nextMonth = 1
  
    startMonths.forEach((f, i) => {
      monthsObj[i + 1] = f + ' ' + Number(year - 1)
      nextMonth++
    })

    months.forEach((f, i) => {
      monthsObj[nextMonth] = f + ' ' + year
      nextMonth++
    })

  } else {
    if (fiscalYearStartMonth !== 1) {
      let months = Object.values(monthsObj)
      let startMonths = months.splice(fiscalYearStartMonth - 1)
      let fiscalMonths = [...startMonths, ...months]
      fiscalMonths.forEach((f, i) => {
        monthsObj[i + 1] = f
      })
    }
  }

  return monthsObj
}

const compareWithRowKeys = {
  'None': null,
  'Practice': 'practice_avg',
  'Peers': 'avg',
  'NSCHBC': 'nschbc'
}

const PeopleReport = React.forwardRef(({ year, yearRange, ...props }, ref) => {
  const { calcs, previousYearCalcs, previous_yoy_calcs, calcs_3_years_back } = useContext(Data);
  const allFilters = useContext(Filters);
  const [activeTab, setActiveTab] = useState('financial-detail');
  const currentNewFilters = allFilters.currentNewFilters;
  const compareWith = allFilters.currentNewFilters.compare_with;
  const calcsAvgType = compareWithRowKeys[compareWith]
  const youMetrics = {};
  const compareMetrics = {};
  const youPreviousYearMetricsYTD = {};
  const comparePreviousYearMetricsYTD = {};
  let previousYearYTDYouTotalRevenue, currentYearYTDYouTotalRevenue, firstYearMonth, secondYearMonth, formattedFirstYearMonth, formattedSecondYearMonth = null;
  let dashboardLayoutType = currentNewFilters.display_columns_by === 'Years' && currentNewFilters.report_period !== 'Year to Date' ? 'Years'
    : currentNewFilters.display_columns_by === 'Years' && currentNewFilters.report_period === 'Year to Date' ? 'Year to Date'
      : 'Months';
  const firmName = props.name ? props.name.slice(0, props.name.indexOf('/') - 1) : ''
  const listOfPeopleCustomersMetrics = [
    'average_benefits',
    'average_wages_and_salaries',
    'cost_of_turnover',
    'customer_roi',
    'gross_profit',
    'gross_profit_per_customer',
    'number_of_employees_who_left',
    'number_of_new_customers_needed_to_maintain_growth',
    'operating_expenses_net_income',
    'operating_expenses_net_income_per_customer',
    'operating_expenses_net_income_per_employee',
    'revenue_per_customer',
    'sum_compensation_growth_rate',
    'sum_compensation_per_employee',
    'sum_health_insurance_per_employee',
    'sum_payroll_per_employee',
    'sum_retirement_per_employee',
    'sum_subcontractors_growth_rate',
    'sum_taxes_and_benefits_growth_rate',
    'sum_taxes_and_benefits_per_employee',
    'total_customer_costs_common_size_revenue',
    'total_number_of_customers',
    'total_number_of_employees',
    'total_revenue',
    'total_revenue_per_employee',
    'training_per_employee',
    'turnover_rate'
  ]
  const monthsObj = monthsObjBuilder(props.fiscalYearStartMonth, currentNewFilters.report_period, allFilters.filters.year)
  let monthLabels = props.calendarYearEnd ? ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'] : ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6', 'Month 7', 'Month 8', 'Month 9', 'Month 10', 'Month 11', 'Month 12']
  if (currentNewFilters.report_period === 'Trailing 12') {
    monthLabels = Object.values(monthsObj)
  }

  if (currentNewFilters.display_columns_by === 'Months') {

    listOfPeopleCustomersMetrics.forEach(metric => {
      Object.entries(peopleCustomersT12Metrics(props.page, metric, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)).forEach(k => {
        youMetrics[k[0]] = k[1]
      })
      return youMetrics
    })

    if (currentNewFilters.compare_with === 'Peers') {
      listOfPeopleCustomersMetrics.forEach(metric => {
        Object.entries(peopleCustomersT12Metrics(props.page, metric, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)).forEach(k => {
          compareMetrics[k[0]] = k[1]
        })
        return compareMetrics
      })
    } else {
      listOfPeopleCustomersMetrics.forEach(metric => {
        Object.entries(peopleCustomersT12Metrics(props.page, metric, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)).forEach(k => {
          compareMetrics[k[0]] = k[1]
        })
        return compareMetrics
      })

    }
  } else if (currentNewFilters.report_period === 'Year to Date') {
    // this is the year to date object builder
    // ytd calcs come like this: 2020: {you: {roa: {1: 1.1270120245297484, 2: null}, 2019: {you: {roa: {1: 1.1270120245297484, 2: null},
    // for ytd filter we want the trailing twelve for the last month available
    // year to date only ever compares with peers, there is no YOY filter, because it already is YOY
    // if we're displaying years 2019 and 2020, we'll need full years back to 2017
    // 2020/2019/2018
    // 2019/2018/2017

    const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
    const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
    const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']
    const compareWithPrevious3YrsBackYTD = previousYearCalcs['monthly'][year - 3]['avg']
    const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
    const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
    const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
    const previous3YearsBackYTDYou = previousYearCalcs['monthly'][year - 3]['you']

    listOfPeopleCustomersMetrics.forEach(metric => {
      Object.entries(peopleCustomersT12Metrics(props.page, metric, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)).forEach(k => {
        youMetrics[k[0]] = k[1]
      })
      Object.entries(peopleCustomersT12Metrics(props.page, metric, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)).forEach(k => {
        compareMetrics[k[0]] = k[1]
      })
      return youMetrics, compareMetrics
    })

    listOfPeopleCustomersMetrics.forEach(metric => {
      Object.entries(peopleCustomersT12Metrics(props.page, metric, previousYearCalcsYTDYou, previous2YearsBackYTDYou, previous3YearsBackYTDYou)).forEach(k => {
        youPreviousYearMetricsYTD[k[0]] = k[1]
      })
      Object.entries(peopleCustomersT12Metrics(props.page, metric, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD, compareWithPrevious3YrsBackYTD)).forEach(k => {
        comparePreviousYearMetricsYTD[k[0]] = k[1]
      })
      return youPreviousYearMetricsYTD, comparePreviousYearMetricsYTD
    })

    // Only two years being used for YTD tables.
    // Each month with values should have total revenue. Use to check how many months with values are available and label the YTD columns with the month/year.
    previousYearYTDYouTotalRevenue = calcs[year - 1] ? calcs[year - 1]['you']['total_revenue'] : undefined;
    currentYearYTDYouTotalRevenue = calcs[year] ? calcs[year]['you']['total_revenue'] : undefined;
    firstYearMonth = previousYearYTDYouTotalRevenue ? Object.values(previousYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    secondYearMonth = currentYearYTDYouTotalRevenue ? Object.values(currentYearYTDYouTotalRevenue).filter(value => _.isNumber(value)).length : '';
    formattedFirstYearMonth = firstYearMonth < 10 ? '0' + firstYearMonth.toString() : firstYearMonth;
    formattedSecondYearMonth = secondYearMonth < 10 ? '0' + secondYearMonth.toString() : secondYearMonth;
  }

  const handleActiveTab = (section) => {
    switch (section) {
      case 'survey':
        setActiveTab('survey')
        !props.surveyTaken ? props.handleSurveyModal(true) : props.handleSurveyModal(false)
        // Open a modal if survey has not been completed
        // Just display charts if survey is completed
        break;
      default:
        setActiveTab(section);
        break;
    }
  }

  const renderPeoplePDFSheets = () => {
    return (
      <>
        {
          props.displayPages['financial_detail']['checked']
            ? <BigTableChartsForSheets
              aggregateYTDDate={props.aggregateYTDDate}
              clientFirstYearMonth={formattedFirstYearMonth}
              clientSecondYearMonth={formattedSecondYearMonth}
              companyYTDDate={props.companyYTDDate}
              calendarYearEnd={props.calendarYearEnd}
              firmName={firmName}
              fiscalYearEnd={props.fiscalYearEnd}
              reportMetrics={props.reportMetrics}
              handleEditMetrics={props.handleEditMetrics}
              handleSelectAndLayout={props.handleSelectAndLayout}
              monthsObj={monthsObj}
              page={props.page}
              pdf={true}
              year={year}
              yearRange={yearRange}
              tree={props.tree}
              currentNewFilters={currentNewFilters}
              clientName={props.clientName}
              n={props.n}
            />
            : null
        }
        {
          props.displayPages['kpi']['checked']
            ? <PeopleCustomersKPIs
              aggregateYTDDate={props.aggregateYTDDate}
              clientFirstYearMonth={formattedFirstYearMonth}
              clientSecondYearMonth={formattedSecondYearMonth}
              companyYTDDate={props.companyYTDDate}
              calendarYearEnd={props.calendarYearEnd}
              firmName={firmName}
              fiscalYearEnd={props.fiscalYearEnd}
              reportMetrics={props.reportMetrics}
              handleEditMetrics={props.handleEditMetrics}
              handleSelectAndLayout={props.handleSelectAndLayout}
              monthsObj={monthsObj}
              pdf={true}
              props={props}
              yearRange={yearRange}
              year={year}
              youMetrics={youMetrics}
              compareMetrics={compareMetrics}
              youPreviousYearMetricsYTD={youPreviousYearMetricsYTD}
              comparePreviousYearMetricsYTD={comparePreviousYearMetricsYTD}
            />
            : null
        }
      </>
    )
  }

  if (props.pdf) {
    let dashboardPages = getDashBoardPagesPDF(props.reportMetrics.layout)
    let displayColumnsBy = currentNewFilters.display_columns_by;
    let coverPeriod = ''
    let reportPeriod = currentNewFilters.report_period;
    let reportTitle = 'Industry Benchmark Report'
    let NAICSfilter = allFilters.filters.code
    let numberOfDocs = allFilters.filters.number_of_pros === 0 ? 'All' : allFilters.filters.number_of_pros ? allFilters.filters.number_of_pros : null
    let todayDate = moment(Date.now()).format("LL")

    switch (displayColumnsBy) {
      case 'Months':
        coverPeriod = year
        break;
      case 'Years':
        if (reportPeriod === 'Year to Date') {
          coverPeriod = `${year - 1} - ${year}`
          break;
        } else {
          coverPeriod = `${yearRange[0]} - ${year}`
          break;
        }
      default:
        coverPeriod = `${yearRange[0]} - ${year}`
        break;
    }

    if (allFilters.filterOptions.code && allFilters.filterOptions.code.length > 1) {
      allFilters.filterOptions.code.forEach(filter => {
        if (NAICSfilter === filter[0])
          NAICSfilter = filter[0] + ' - ' + filter[1]
      })
    }

    const portraitView = {
      'backgroundColor': '#ffffff',
      'width': '215mm !important',
      'height': '279mm !important',
      'margin': '0 auto',
      'pageBreakInside': 'avoid',
      'pageBreakAfter': 'always'
    }

    return (
      <div ref={ref} className='pdf-print-container'>
        {props.displayPages['cover_page']['checked'] ?
          (<div className='portrait-view cover-page-pdf'>
            <div className='cover-page-top-section'></div>
            <div className='cover-page-info'>
              <div>
                <div className='img-title-page-print'>{props.logo ? <img src={props.logo} alt=''></img> : null}</div>
                <h1>{reportTitle}</h1>
                <div className='cp-report-info'>
                  <p>{props.name}</p>
                  <p>FOR THE PERIOD: {coverPeriod}</p>
                  <p>INDUSTRY: {props.industry}</p>
                  <p>NAICS: {props.naicsCode}</p>
                  <p>{`NUMBER OF COMPANIES: ${props.n}`}</p>
                  <p style={{ 'textTransform': 'uppercase' }}>{todayDate}</p>
                </div>
              </div>
              <div className='cp-filters-list'>
                <h2 className='cp-filter-title'>FILTERS USED IN THIS {props.page} REPORT</h2>
                <p>INDUSTRY: {NAICSfilter}</p>
                <div className='cp-filter-cols'>
                  <p>REVENUE: {allFilters.filters.revenue_band}</p>
                  <p>REGION: {allFilters.filters.region}</p>
                  {numberOfDocs ? <p># DOCTORS: {numberOfDocs}</p> : null}
                  <p>REPORT PERIOD: {currentNewFilters.report_period}</p>
                  <p>COMPARE WITH: {currentNewFilters.compare_with}</p>
                </div>
              </div>
            </div>
            <div className='cover-page-bottom-section'>
              <p>Powered by Peerview Data</p>
            </div>
          </div>) : null}
        {renderPeoplePDFSheets()}
        {props.displayPages['dashboard']['checked']
          ? (dashboardPages.map(dashboardItems => {
            return (
              <div className='portrait-view'>
                <div className='money-pdf-portrait'>
                  <div>
                    <h1 className='report-table-title'>{`Dashboard - ${props.clientName}`}</h1>
                    <ParentDashboard
                      aggregateYTDDate={props.aggregateYTDDate}
                      calcsAvgType={calcsAvgType}
                      clientName={props.clientName}
                      companyYTDDate={props.companyYTDDate}
                      compareWith={currentNewFilters.compare_with}
                      dashboardLayout={dashboardItems}
                      displayColumnsBy={currentNewFilters.display_columns_by}
                      myNotes={props.moneyNotes}
                      handleMetricNotes={props.handleMetricNotes}
                      handleMoneyNotes={props.handleMoneyNotes}
                      handleOnLayoutChange={props.handleOnLayoutChange}
                      handleToggleDraggable={props.handleToggleDraggable}
                      calendarYearEnd={props.calendarYearEnd}
                      fiscalYearEnd={props.fiscalYearEnd}
                      monthLabels={monthLabels}
                      naicsCode={props.naicsCode}
                      page={props.page}
                      pdf={true}
                      reportPeriod={currentNewFilters.report_period}
                      reportMetrics={props.reportMetrics}
                      year={year}
                      yearRange={yearRange}
                    />
                  </div>
                  <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                </div>
              </div>
            )
          }))
          : null}
      </div>
    )
  } else {
    return (
      <div ref={ref} className='money-report-component money-content'>
        <ul id='nav-tabs-people-report' className='report-tab-list' role='tablist' >
          <li id='financial-detail-tab' className={activeTab === 'financial-detail' ? 'report-tab-item people-active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('financial-detail')} style={{ fontSize: '12px' }}>
            Financial Detail
          </li>
          <li id='kpi-tab' className={activeTab === 'kpi' ? 'report-tab-item people-active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('kpi')}>
            KPI
          </li>
          <li id='dashboard-tab' className={activeTab === 'dashboard' ? 'report-tab-item people-active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('dashboard')}>
            Dashboard
          </li>
          <li id='survey-tab' className={activeTab === 'survey' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleActiveTab('survey')}>
            Survey
          </li>
          <div className='edit-dashboard-section'>
            {props.reportMetrics.noChangesMade ? null : <button className='save-dashboard-btn' onClick={() => props.updateDashBoardView()}>Save Changes</button>}
            {activeTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('add_view')}>+ New View</button> : null}
            {activeTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => props.handleDashboardViewModal('all_views')}>Edit My Views</button> : null}
            <EditParentChildDashboard
              allMetrics={props.reportMetrics}
              editDashboard={props.editDashboard}
              handleEditDashBoard={props.handleEditDashBoard}
              handleEditMetrics={props.handleEditMetrics}
              handleSelectAndLayout={props.handleSelectAndLayout}
              page={props.page}
            />
          </div>
        </ul>
        <div className='page-break' />
        <div className={activeTab === 'financial-detail' ? 'show-section' : 'hide-section'}>
          <BigTableChartsForSheets
            aggregateYTDDate={props.aggregateYTDDate}
            clientFirstYearMonth={formattedFirstYearMonth}
            clientSecondYearMonth={formattedSecondYearMonth}
            companyYTDDate={props.companyYTDDate}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            reportMetrics={props.reportMetrics}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            page={props.page}
            year={year}
            yearRange={yearRange}
            tree={props.tree}
            currentNewFilters={currentNewFilters}
            clientName={props.clientName}
            n={props.n}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'kpi' ? 'show-section' : 'hide-section'}>
          <PeopleCustomersKPIs
            aggregateYTDDate={props.aggregateYTDDate}
            clientFirstYearMonth={formattedFirstYearMonth}
            clientSecondYearMonth={formattedSecondYearMonth}
            companyYTDDate={props.companyYTDDate}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            reportMetrics={props.reportMetrics}
            handleEditMetrics={props.handleEditMetrics}
            handleSelectAndLayout={props.handleSelectAndLayout}
            monthsObj={monthsObj}
            props={props}
            yearRange={yearRange}
            year={year}
            youMetrics={youMetrics}
            compareMetrics={compareMetrics}
            youPreviousYearMetricsYTD={youPreviousYearMetricsYTD}
            comparePreviousYearMetricsYTD={comparePreviousYearMetricsYTD}
          />
        </div>
        <div className='page-break' />
        <div className={activeTab === 'dashboard' ? 'show-section' : 'hide-section'}>
          <DashboardViewControls
            report={'people'}
            dashboardViewModalState={props.dashboardViewModalState}
            getDashBoardView={props.getDashBoardView}
            handleDashboardViewModal={props.handleDashboardViewModal}
          />
        </div>
        {activeTab === 'dashboard'
          ? <ParentDashboard
            aggregateYTDDate={props.aggregateYTDDate}
            calcsAvgType={calcsAvgType}
            clientName={props.clientName}
            companyYTDDate={props.companyYTDDate}
            compareWith={currentNewFilters.compare_with}
            dashboardLayout={props.reportMetrics.layout}
            displayColumnsBy={currentNewFilters.display_columns_by}
            myNotes={props.moneyNotes}
            handleMetricNotes={props.handleMetricNotes}
            handleEditChartModal={props.handleEditChartModal}
            handleOnLayoutChange={props.handleOnLayoutChange}
            handleToggleDraggable={props.handleToggleDraggable}
            calendarYearEnd={props.calendarYearEnd}
            fiscalYearEnd={props.fiscalYearEnd}
            monthLabels={monthLabels}
            page={props.page}
            reportPeriod={currentNewFilters.report_period}
            reportMetrics={props.reportMetrics}
            year={year}
            yearRange={yearRange}
          /> : null}
        <div className='page-break' />
        <div className={activeTab === 'survey' ? 'show-section' : 'hide-section'}>
          <SurveyResponses
            chartColors={props.surveyData.chartColors}
            surveyCharts={props.surveyData.surveyCharts}
            surveyTaken={props.surveyTaken}
            companyReportSurvey={props.companyReportSurvey}
            surveyResults={props.surveyResults}
            pageTitle={props.pageTitle}
            year={year}
          />
        </div>
      </div>
    )
  }
})

export default PeopleReport