const peopleKPIsFormat = (displayColsBy, reportPeriod, naicsCode) => {
    const padding = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
    let trailing12Abbr = '';
    let monthOverMonthAbbr = '';

    if (displayColsBy === 'Months' || reportPeriod === 'Year to Date') {
        trailing12Abbr = '(T12)';
        monthOverMonthAbbr = '(MoM)'
    }

    let healthNaicsCode = naicsCode.startsWith('6211') || naicsCode.startsWith('6213')
    let dentalNaicsCode = naicsCode.startsWith('6212')

    return [
        {
            key: 'title',
            title: `Analysis per Employee ${trailing12Abbr}`,
            format: 'header',
            display: true
        },
        {
            key: 'total_revenue_per_employee',
            title: 'Revenue Per Employee',
            format: 'money',
            display: true
        },
        {
            key: 'operating_expenses_net_income_per_employee',
            title: 'Profit per Employee',
            format: 'money',
            display: true
        },
        {
            key: 'sum_compensation_growth_rate',
            title: `Compensation Growth Rate ${monthOverMonthAbbr}`,
            format: 'percent',
            display: true
        },
        {
            key: 'sum_taxes_and_benefits_growth_rate',
            title: `Benefits Growth Rate ${monthOverMonthAbbr}`,
            format: 'percent',
            display: true
        },
        {
            key: 'sum_subcontractors_growth_rate',
            title: `Use of Subcontractors Growth Rate ${monthOverMonthAbbr}`,
            format: 'percent',
            display: true
        },
        {
            key: 'average_wages_and_salaries',
            title: 'Avg Wages and Salaries Per Employee',
            format: 'money',
            display: true
        },
        {
            key: 'average_benefits',
            title: 'Avg Benefits Per Employee',
            format: 'money',
            display: true
        },
        {
            key: 'title',
            title: padding,
            format: 'header',
            display: (dentalNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date') || (healthNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date')
        },
        {
            key: 'title',
            title: 'Compensation per FTE',
            format: 'header',
            display: (dentalNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date') || (healthNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date')
        },
        {
            key: 'number_of_hygienists',
            title: 'Hygienist',
            format: 'money',
            display: dentalNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_assistants',
            title: 'Assistant',
            format: 'money',
            display: dentalNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_office_employees',
            title: 'Office',
            format: 'money',
            display: dentalNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_dentists',
            title: 'Dentist',
            format: 'money',
            display: dentalNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_nurses',
            title: 'Nurse',
            format: 'money',
            display: healthNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_assistants',
            title: 'Assistant',
            format: 'money',
            display: healthNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_office_employees',
            title: 'Office',
            format: 'money',
            display: healthNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'number_of_doctors',
            title: 'Doctor',
            format: 'money',
            display: healthNaicsCode && displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'title',
            title: padding,
            format: 'header',
            display: true
        },
        {
            key: 'title',
            title: 'Turnover Analysis',
            format: 'header',
            display: true
        },
        {
            key: 'turnover_rate',
            title: 'Turnover Rate',
            format: 'percent',
            display: true
        },
        {
            key: 'cost_of_turnover',
            title: 'Cost of Turnover',
            format: 'money',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'title',
            title: padding,
            format: 'header',
            display: true,
        },
        {
            key: 'title',
            title: 'Employee Data',
            format: 'header',
            display: true,
        },
        {
            key: 'total_number_of_employees',
            title: 'Number of Employees',
            format: '',
            display: true,
        },
        {
            key: 'number_of_employees_who_left',
            title: 'Number of Employees who left',
            format: '',
            display: true,
        }
    ]
}

const customersKPIsFormat = (displayColsBy, reportPeriod) => {
    const padding = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
    let trailing12Abbr = '';
    let monthOverMonthAbbr = '';

    if (displayColsBy === 'Months' || reportPeriod === 'Year to Date') {
        trailing12Abbr = '(T12)';
        monthOverMonthAbbr = '(MoM)'
    }

    return [
        {
            key: 'title',
            title: `Analysis per Client ${trailing12Abbr}`,
            format: 'header',
            display: true,
        },
        {
            key: 'total_revenue',
            title: 'Revenue',
            format: 'money',
            display: true,
            checked: true
        },
        {
            key: 'revenue_per_customer',
            title: 'Revenue Per Client',
            format: 'money',
            display: true,
            checked: true
        },
        {
            key: 'gross_profit',
            title: 'Gross Profit',
            format: 'money',
            display: true,
            checked: true
        },
        {
            key: 'gross_profit_per_customer',
            title: 'Gross Profit Per Client',
            format: 'money',
            display: true,
            checked: true
        },
        {
            key: 'operating_expenses_net_income',
            title: 'Net Profit',
            format: 'money',
            display: true,
            checked: true
        },
        {
            key: 'operating_expenses_net_income_per_customer',
            title: 'Net Profit Per Client',
            format: 'money',
            display: true,
            checked: true
        },
        {
            key: 'title',
            title: padding,
            format: 'header',
            display: true,
        },
        {
            key: 'title',
            title: 'Marketing Spend/ROI',
            format: 'header',
            display: true,
        },
        {
            key: 'total_customer_costs_common_size_revenue',
            title: 'Marketing Spend',
            format: 'percent',
            display: true,
            checked: true
        },
        {
            key: 'customer_roi',
            title: `Marketing ROI ${monthOverMonthAbbr}`,
            format: 'percent',
            display: true,
            checked: true
        },
        {
            key: 'title',
            title: padding,
            format: 'header',
            display: true,
        },
        {
            key: 'title',
            title: 'Customer Concentration',
            format: 'header',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'percent_of_sales_from_the_top_10_percent',
            title: 'Top 10%',
            format: 'percent',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            checked: true
        },
        {
            key: 'percent_of_sales_from_the_top_25_percent',
            title: 'Top 25%',
            format: 'percent',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            checked: true
        },
        {
            key: 'percent_of_sales_from_the_top_50_percent',
            title: 'Top 50%',
            format: 'percent',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date',
            checked: true
        },
        {
            key: 'title',
            title: padding,
            format: 'header',
            display: displayColsBy === 'Years' && reportPeriod !== 'Year to Date'
        },
        {
            key: 'title',
            title: 'Client Data',
            format: 'header',
            display: true
        },
        {
            key: 'total_number_of_customers',
            title: 'Number of Clients',
            format: '',
            display: true
        },
    ]
}

const compensationFTE = (naicsCode, calcs, industyKey, year) => {
    let dentistCompFTEKeys = {
        'number_of_hygienists': 'hygienist',
        'number_of_assistants': 'assistant',
        'number_of_office_employees': 'office',
        'number_of_dentists': 'dentist'
    }
    let healthComFTEKeys = {
        'number_of_doctors': 'total_compensation_doctor',
        'number_of_nurses': 'total_compensation_nurse',
        'number_of_assistants': 'total_compensation_assistant',
        'number_of_office_employees': 'total_compensation_office'
    }
    if ((naicsCode.startsWith('6211') && calcs[healthComFTEKeys[industyKey]]) || (naicsCode.startsWith('6213') && calcs[healthComFTEKeys[industyKey]])) {
        return calcs[healthComFTEKeys[industyKey]][year] / calcs[industyKey][year]
    }
    if (naicsCode.startsWith('6212') && calcs[dentistCompFTEKeys[industyKey]]) {
        return calcs[dentistCompFTEKeys[industyKey]][year] / calcs[industyKey][year]
    }
    return null;
}

const peersAnalysisPerCustomerVals = (key, year, vals) => {
    let finalValue = null
    let firstPartKey = key.substring(0, key.length - 13)
    if (key === 'revenue_per_customer') {
        finalValue = vals['total_revenue'][year] / vals['total_number_of_customers'][year]
    } else if (key.includes('_per_customer')) {
        let totalVal = vals[firstPartKey + '_common_size_revenue'][year] * vals['total_revenue'][year]
        finalValue = totalVal / vals['total_number_of_customers'][year]
    } else {
        finalValue = vals[key + '_common_size_revenue'][year] * vals['total_revenue'][year]
    }
    return finalValue
}

export { peopleKPIsFormat, customersKPIsFormat, compensationFTE, peersAnalysisPerCustomerVals }