import React, { useState } from "react";

const UserPermissionsForm = (props) => {
    const [search, setSearch] = useState({
        display: false,
        results: [],
    })

    const handlePermissionOptions = (type, value, excluded = false) => {
        props.setUserPermissions((prevState) => {
            let newIncludeOptions = props.userPermissions.includeOptions
            let newExcludeOptions = props.userPermissions.excludeOptions
            let includeAll = props.userPermissions.includeAll

            if (type !== 'all') {
                let currentExcludeOptions = newExcludeOptions[type]
                let currentIncludeOptions = newIncludeOptions[type]

                let focusedArrOptions = excluded ? currentExcludeOptions : currentIncludeOptions
                let otherArrOptions = excluded ? currentIncludeOptions : currentExcludeOptions

                if (focusedArrOptions.includes(value)) {
                    focusedArrOptions = focusedArrOptions.filter(s => s !== value)
                    otherArrOptions.push(value)
                    otherArrOptions = otherArrOptions.sort()
                } else {
                    otherArrOptions = otherArrOptions.filter(s => s !== value)
                    focusedArrOptions.push(value)
                    focusedArrOptions = focusedArrOptions.sort()
                }

                if (excluded) {
                    newExcludeOptions[type] = focusedArrOptions
                    newIncludeOptions[type] = otherArrOptions
                } else {
                    newExcludeOptions[type] = otherArrOptions
                    newIncludeOptions[type] = focusedArrOptions
                }

                if (newExcludeOptions['naics_code'].length == 0 &&
                    newExcludeOptions['partners'].length == 0 &&
                    newExcludeOptions['office'].length == 0 &&
                    newExcludeOptions['region'].length == 0) {
                    includeAll = true
                } else {
                    includeAll = false
                }
            } else {
                newExcludeOptions['naics_code'] = []
                newExcludeOptions['partners'] = []
                newExcludeOptions['office'] = []
                newExcludeOptions['region'] = []
                newIncludeOptions = props.allFilterOptions
                includeAll = true
            }
            // Config options first
            // Do 'or' with the filters
            let newExcludedClients = []

            if (!includeAll) {
                newExcludedClients = props.userPermissions.allCompanies.filter(c => {
                    let naicsFound = newExcludeOptions['naics_code'].some((naics) => c.naics_code.startsWith(naics[0]))
                    if (naicsFound ||
                        newExcludeOptions['partners'].includes(c.partners) ||
                        newExcludeOptions['office'].includes(c.office) ||
                        newExcludeOptions['region'].includes(c.region)) {
                        return c
                    }
                })
            }

            return {
                ...prevState,
                includeAll: includeAll,
                excludedClients: newExcludedClients,
                includeOptions: newIncludeOptions,
                excludeOptions: newExcludeOptions
            }
        })
    }

    const buildOptions = (type, excluded = false) => {
        let buttonStyle = ''
        let optionDivStyle = ''
        if (type === 'naics_code') {
            buttonStyle = props.modal ? 'user-permission-naics-btns' : 'new-user-permission-naics-btns'
            optionDivStyle = excluded ? 'user-permission-exclude-options' : 'user-permission-include-options'
        } else {
            buttonStyle = 'user-permission-btns'
            optionDivStyle = excluded ? 'user-permission-exclude-options-wrap' : 'user-permission-include-options-wrap'
        }

        let options = excluded ? props.userPermissions.excludeOptions[type] : props.userPermissions.includeOptions[type]
        let optionLabel = excluded ? 'Excluded' : 'Included'
        if (type === 'naics_code') {
            options = options.filter(naics => {
                if (naics[0] === 'vets') {
                    return naics
                } else if (naics[0].length == 2) {
                    return naics
                }
            })
        } else {
            options = options.filter(o => o !== 'All')
        }

        return (
            <div className='options-section'>
                <span>{optionLabel}</span>
                <div className={optionDivStyle}>
                    {options.map((l, i) => {
                        return (
                            <div className={buttonStyle} key={`${type}-${i}`} onClick={() => handlePermissionOptions(type, l, excluded)}><span>{l}</span></div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const addToExcludedClients = (client) => {
        props.setUserPermissions((prevState) => {
            let newExcludedClients = props.userPermissions.excludedClients
            let checkExcluded = newExcludedClients.find(e => e.id == client.id)
            if (!checkExcluded) {
                newExcludedClients.push(client)
                newExcludedClients = newExcludedClients.sort()
            }
            return {
                ...prevState,
                excludedClients: newExcludedClients
            }
        })
    }

    const removeExcludedClient = (client) => {
        props.setUserPermissions((prevState) => {
            let newExcludedClients = props.userPermissions.excludedClients.filter(e => e.id !== client.id)
            return {
                ...prevState,
                excludedClients: newExcludedClients
            }
        })
    }

    const displaySearchSection = () => {
        setSearch((prevState) => {
            return {
                ...prevState,
                display: !search.display
            }
        })
    }

    const searchForClient = (text) => {
        if (text) {
            let allCompanies = props.userPermissions.allCompanies;
            let finalText = text.toLowerCase()
            let searchResults = allCompanies.filter(c => c.name.toLowerCase().includes(finalText) || c.real_name && c.real_name.toLowerCase().includes(finalText))

            setSearch((prevState) => {
                return {
                    ...prevState,
                    results: searchResults
                }
            })
        } else {
            setSearch((prevState) => {
                return {
                    ...prevState,
                    results: []
                }
            })
        }
    }

    const buildExcludedClients = () => {
        let excludedClients = []
        props.userPermissions.excludedClients.forEach((e, i) => {
            if (e) {
                let companyName = e.real_name ? e.name + ' - ' + e.real_name : e.name
                excludedClients.push(<div key={`${i}-${e.name}`} className='company-name-btn' onClick={() => removeExcludedClient(e)}><span>{companyName}</span><span style={{ 'color': '#000000' }}>X</span></div>)
            }
        })
        return excludedClients
    }

    return (
        <div>
            {props.userModalData
                ? <header className='company-modal-header'>
                    <button
                        type='button'
                        className='btn btn-dark modal-close-btn'
                        onClick={props.closeUserModal}
                    >
                        <i className='fa fa-times'></i>
                    </button>
                    <h1 className='client-company-name'>{props.userModalData.name}</h1>
                </header>
                : null
            }
            <div className='user-permission-modal-info'>
                <p>Select and exclude which clients this user cannot view based on NAICS codes, partner, office, or region. You can exclude individual clients too.</p>
                <div style={{ 'textAlign': 'center', 'marginBottom': '15px' }}>{props.userPermissions.includeAll ? <p>This user can access <b>All Clients.</b></p> : <button className='clear-filters-btn' onClick={() => handlePermissionOptions('all')}>Clear All Excluded Filters</button>}</div>
                <div className='modal-info'>
                    <label className='filter-label'>
                        NAICS <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <div className='options-container'>
                        {buildOptions('naics_code')}
                        {buildOptions('naics_code', true)}
                    </div>
                </div>
                <div className='modal-info'>
                    <label className='filter-label'>
                        Partner <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <div className='options-container'>
                        {buildOptions('partners')}
                        {buildOptions('partners', true)}
                    </div>
                </div>
                <div className='modal-info'>
                    <label className='filter-label'>
                        Office <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <div className='options-container'>
                        {buildOptions('office')}
                        {buildOptions('office', true)}
                    </div>
                </div>
                <div className='modal-info'>
                    <label className='filter-label'>
                        Region <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <div className='options-container'>
                        {buildOptions('region')}
                        {buildOptions('region', true)}
                    </div>
                </div>


                <div className='modal-info'>
                    <div>
                        <p>Excluded Clients</p>
                    </div>
                    <div className='modal-info'>
                        <button className='add-clients-btn' type='button' onClick={() => displaySearchSection()}>+ Add Individual Clients to Exclude</button>
                        {
                            search.display
                                ? (<div className='search-clients-section'>
                                    <input className='client-search-bar' placeholder='Search for Client ID/Name' onChange={(e) => searchForClient(e.target.value)} />
                                    <div className='clients-search-wrap'>
                                        {search.results.map(s => {
                                            let companyName = s.real_name ? s.name + ' - ' + s.real_name : s.name
                                            if (props.userPermissions.excludedClients.find(ex => ex.name === s.name)) {
                                                return <div className='company-name-btn'><span>{companyName}</span><span style={{ 'color': '#000000' }}>&#10003;</span></div>
                                            } else {
                                                return <div className='company-name-btn' onClick={() => addToExcludedClients(s)}><span>{companyName}</span><span style={{ 'color': '#000000' }}>+</span></div>
                                            }
                                        })}
                                    </div>
                                </div>)
                                : null
                        }
                    </div>
                    <div className='user-permission-exclude-clients'>
                        {buildExcludedClients()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPermissionsForm;