import React, { useState, useEffect, useRef } from 'react';
import { CardNumberElement, CardMonthElement, CardYearElement, CardCvvElement, useRecurly } from '@recurly/react-recurly';
import { parseMoney } from './utils/index';
import planDetails from './PlanDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

const PlansPaymentForm = (props) => {
    const formRef = useRef();
    const recurly = useRecurly();
    const [payFormModal, setPayFormModal] = useState(false)
    const [plansForm, setPlansForm] = useState({
        plan_title: 'Peerview Advisor - Monthly',
        plan_name: 'peerviewadvisor-m',
        first_name: '',
        last_name: '',
        address1: '',
        city: '',
        state: '',
        country: 'US',
        postal_code: '',
        quantity: 1,
        report_credits: 5,
        base_price: 150,
        total_price: '$150',
        total_price_no_coupon: 150,
        coupon_code: '',
        saved_amount: 0
    })

    const [couponDiscount, setCouponDiscount] = useState({ type: null, amount: 0 })
    const [couponMessage, setCouponMessage] = useState({ message: '', valid: false })

    const cardElementStyles = {
        style: {
            fontSize: '1em',
        }
    }

    const closeModal = () => {
        setPayFormModal(false)
    }

    const handleTotalPrice = (quanityNum, basePrice, type = null, amount = null) => {
        let totalPrice = 0
        let totalPriceNoCoupon = quanityNum * basePrice
        let savedAmount = 0
        let couponType = type ? type : couponDiscount.type
        let couponAmount = amount ? amount : couponDiscount.amount

        if (couponType === 'percent') {
            let total = (quanityNum * basePrice) * (1 - (couponAmount / 100))
            totalPrice = parseMoney(total, true)
            savedAmount = parseMoney(totalPriceNoCoupon - total, true)
        } else if (couponType === 'fixed') {
            let total = (quanityNum * basePrice) - couponAmount
            totalPrice = parseMoney(total, true)
            savedAmount = parseMoney(totalPriceNoCoupon - total, true)
        } else {
            totalPrice = parseMoney(quanityNum * basePrice, true)
        }

        return { totalPrice, totalPriceNoCoupon, savedAmount }
    }

    const handlePlansForm = (input, value) => {
        setPayFormModal(true)
        setPlansForm((prevState) => {
            let quanityNum = 1;
            let basePrice = 0
            let totalPrice = 0
            let baseNumberOfClientReports = 0;
            let totalNumOfClientReports = 0;
            switch (input) {
                case 'plan_name':
                    let planTitle = ''
                    if (value === 'peerviewadvisor-m') {
                        basePrice = 150
                        baseNumberOfClientReports = 5
                        planTitle = 'Peerview Advisor - Monthly'
                    }

                    if (value === 'peerviewadvisor-a') {
                        basePrice = 1620
                        baseNumberOfClientReports = 5
                        planTitle = 'Peerview Advisor - Annual'
                    }

                    if (value === 'peerviewadvisorplus-m') {
                        basePrice = 560
                        baseNumberOfClientReports = 20
                        planTitle = 'Peerview Advisor+ - Monthly'
                    }

                    if (value === 'peerviewadvisorplus-a') {
                        basePrice = 6048
                        baseNumberOfClientReports = 20
                        planTitle = 'Peerview Advisor+ - Annual'
                    }

                    if (value === 'peerviewadvisorteam-m') {
                        basePrice = 1250
                        baseNumberOfClientReports = 50
                        planTitle = 'Peerview Advisor Team - Monthly'
                    }

                    if (value === 'peerviewadvisorteam-a') {
                        basePrice = 13500
                        baseNumberOfClientReports = 50
                        planTitle = 'Peerview Advisor Team - Annual'
                    }

                    let price = handleTotalPrice(quanityNum, basePrice)

                    return {
                        ...prevState,
                        plan_title: planTitle,
                        plan_name: value,
                        report_credits: baseNumberOfClientReports,
                        base_price: basePrice,
                        total_price: price.totalPrice,
                        total_price_no_coupon: price.totalPriceNoCoupon,
                        saved_amount: price.savedAmount,
                        quantity: quanityNum
                    }
                case 'coupon_code':
                    return {
                        ...prevState,
                        coupon_code: value
                    }
                case 'first_name':
                    return {
                        ...prevState,
                        first_name: value
                    }
                case 'last_name':
                    return {
                        ...prevState,
                        last_name: value
                    }
                case 'address1':
                    return {
                        ...prevState,
                        address1: value
                    }
                case 'city':
                    return {
                        ...prevState,
                        city: value
                    }

                case 'state':
                    return {
                        ...prevState,
                        state: value
                    }
                case 'postal_code':
                    return {
                        ...prevState,
                        postal_code: value
                    }
                case 'quanity':
                    quanityNum = Math.round(Number(value));
                    if (plansForm.plan_name === 'peerviewadvisor-m') {
                        basePrice = 150
                        baseNumberOfClientReports = 5
                    }

                    if (plansForm.plan_name === 'peerviewadvisor-a') {
                        basePrice = 1620
                        baseNumberOfClientReports = 5
                    }

                    if (plansForm.plan_name === 'peerviewadvisorplus-m') {
                        basePrice = 560
                        baseNumberOfClientReports = 20
                    }

                    if (plansForm.plan_name === 'peerviewadvisorplus-a') {
                        basePrice = 6048
                        baseNumberOfClientReports = 20
                    }

                    if (plansForm.plan_name === 'peerviewadvisorteam-m') {
                        basePrice = 1250
                        baseNumberOfClientReports = 50
                    }

                    if (plansForm.plan_name === 'peerviewadvisorteam-a') {
                        basePrice = 13500
                        baseNumberOfClientReports = 50
                    }

                    let tPrice = handleTotalPrice(quanityNum, basePrice)
                    totalNumOfClientReports = quanityNum * baseNumberOfClientReports

                    return {
                        ...prevState,
                        report_credits: totalNumOfClientReports,
                        total_price: tPrice.totalPrice,
                        total_price_no_coupon: tPrice.totalPriceNoCoupon,
                        saved_amount: tPrice.savedAmount,
                        quantity: quanityNum
                    }
                default:
                    break;
            }
        })
    }

    const handleCouponCheck = async (e) => {
        e.preventDefault()
        try {
            const res = await fetch(`/coupon?coupon_code=${plansForm.coupon_code}`, {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                }
            })
            const resJson = await res.json();

            if (!res.ok) {
                setCouponMessage(() => {
                    return { message: resJson.message, valid: false }
                })
            } else {
                let couponAmount = 0
                if (resJson.data.discount.attributes.type === 'percent') {
                    couponAmount = resJson.data.discount.attributes.percent
                }
                if (resJson.data.discount.attributes.type === 'fixed') {
                    couponAmount = resJson.data.discount.attributes.currencies[0].attributes.amount
                }
                setCouponDiscount(() => {
                    return {
                        type: resJson.data.discount.attributes.type,
                        amount: couponAmount
                    }
                })

                let price = handleTotalPrice(plansForm.quantity, plansForm.base_price, resJson.data.discount.attributes.type, couponAmount)

                setCouponMessage(() => {
                    return { message: `Coupon Applied! You saved: ${price.savedAmount}.`, valid: true }
                })
  
                setPlansForm((prevState) => {
                    return {
                        ...prevState,
                        total_price: price.totalPrice,
                        total_price_no_coupon: price.totalPriceNoCoupon,
                        saved_amount: price.savedAmount
                    }
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubscriptionPurchase = async (token) => {
        try {
            const res = await fetch('/subscriptions/purchase', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    form_data: plansForm
                }),
            })
            const resJson = await res.json();
            if (!res.ok) {
                alert(resJson.error);
                throw new Error('Network response was not ok.');
            } else {
                props.handleTabs('current-sub')
                props.setCurrentPlan(resJson.subscription.plan_type)
                props.setCurrentSubscription((prevState) => {
                    return {
                        id: resJson.subscription.id,
                        plan_type: resJson.subscription.plan_type,
                        subscription_starts: resJson.subscription.subscription_starts,
                        subscription_ends: resJson.subscription.subscription_ends,
                        status: resJson.subscription.status,
                        quantity: resJson.subscription.quantity
                    }
                })
                closeModal()
                alert(resJson.message)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        recurly.token(formRef.current, (err, token) => {
            if (err) {
                console.log(err, 'err')
                alert('Whoops! Something went wrong with your submission. Please check your information carefully. Contact us if you are still having problems.')
            } else {
                // save the token.id, and submit it to the Recurly API from your server
                // The tokens generated above may be used on any billing_info object in the Recurly API.
                handleSubscriptionPurchase(token)
            }
        });
    }

    const buildHeaders = () => {
        return planDetails.rowHeaders.map((header, i) => {
            let cellHeaderStyle = { backgroundColor: '#bfe0ae' }
            let cellHeaderStyle1 = {}
            let cellHeaderStyle2 = { backgroundColor: '#bfe0ae' }
            let cellHeaderStyle3 = {}
            let cellCopyStyle = {
                textAlign: 'left'
            }

            if (i === 0) {
                cellCopyStyle['backgroundColor'] = '#bfe0ae';
                cellHeaderStyle['backgroundColor'] = '#bfe0ae';
                cellHeaderStyle1['backgroundColor'] = '#bfe0ae';
                cellHeaderStyle2['backgroundColor'] = '#bfe0ae';
                cellHeaderStyle3['backgroundColor'] = '#bfe0ae';

                cellCopyStyle['height'] = '30px';
                cellHeaderStyle['height'] = '30px';
                cellHeaderStyle1['height'] = '30px';
                cellHeaderStyle2['height'] = '30px';
                cellHeaderStyle3['height'] = '30px';
            }

            return (
                <tr>
                    <td style={cellCopyStyle}>{header}</td>
                    <td style={cellHeaderStyle}>{planDetails.plans[0][planDetails.rowHeaderKeys[i]]}</td>
                    <td style={cellHeaderStyle1}>{planDetails.plans[1][planDetails.rowHeaderKeys[i]]}</td>
                    <td style={cellHeaderStyle2}>{planDetails.plans[2][planDetails.rowHeaderKeys[i]]}</td>
                    <td style={cellHeaderStyle3}>{planDetails.plans[3][planDetails.rowHeaderKeys[i]]}</td>
                </tr>
            )
        })
    }

    const buildRows = () => {
        let cellCopyStyle = {
            textAlign: 'left'
        }

        const paddingCreator = (paddingNumber) => {
            let padding = '';
            for (let i = 0; i < paddingNumber; i++) {
                padding += '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
            }
            return padding;
        }

        const cellValueCreator = (rowData, planName) => {
            if (rowData.plans && rowData.plans.includes(planName)) {
                if (rowData.displayUnlimited) {
                    return 'Unlimited'
                } else if (rowData.displayNA) {
                    return 'N/A'
                } else if (rowData.displayNumberOfReports) {
                    let planData = planDetails.plans.find(p => p.key === planName)
                    return planData.number_of_report_suites
                } else {
                    return <FontAwesomeIcon icon={faCheck} />
                }
            } else {
                return ''
            }
        }

        return planDetails.details.map((row, i) => {
            const rowCopy = paddingCreator(row.padding) + row.copy
            const rowData = row;
            const cellDataStyle1 = { backgroundColor: '#bfe0ae' }

            return (
                <tr>
                    <td style={cellCopyStyle}>{rowCopy}</td>
                    <td style={cellDataStyle1}>{cellValueCreator(rowData, 'free')}</td>
                    <td>{cellValueCreator(rowData, 'peerviewadvisor')}</td>
                    <td style={cellDataStyle1}>{cellValueCreator(rowData, 'peerviewadvisorplus')}</td>
                    <td>{cellValueCreator(rowData, 'peerviewadvisorteam')}</td>
                </tr>
            )
        })
    }

    const buildSubscriptionBtns = () => {
        return (
            <>
                <tr key='plan-btns-1'>
                    <td></td>
                    <td>
                        <a
                            className='plan-btn'
                            href={window.navbar.navbar.children_present ? '/companies' : '/admin/companies/new'}
                        >
                            Start with Freeview
                        </a>
                    </td>
                    <td>
                        <button className='plan-btn' onClick={(e) => handlePlansForm('plan_name', 'peerviewadvisor-m')}>
                            Subscribe for $150/Month
                        </button>
                    </td>
                    <td>
                        <button className='plan-btn' onClick={(e) => handlePlansForm('plan_name', 'peerviewadvisorplus-m')}>
                            Subscribe for $560/Month
                        </button>
                    </td>
                    <td>
                        <button className='plan-btn' onClick={(e) => handlePlansForm('plan_name', 'peerviewadvisorteam-m')}>
                            Subscribe for $1,250/Month
                        </button>
                    </td>
                </tr>
                <tr key='plan-btns-2'>
                    <td></td>
                    <td></td>
                    <td>
                        <button className='plan-btn' onClick={(e) => handlePlansForm('plan_name', 'peerviewadvisor-a')}>
                            Subscribe for $1,620/Year (10% Savings!)
                        </button>
                    </td>
                    <td>
                        <button className='plan-btn' onClick={(e) => handlePlansForm('plan_name', 'peerviewadvisorplus-a')}>
                            Subscribe for $6,048/Year (10% Savings!)
                        </button>
                    </td>
                    <td>
                        <button className='plan-btn' onClick={(e) => handlePlansForm('plan_name', 'peerviewadvisorteam-a')}>
                            Subscribe for $13,500/Year (10% Savings!)
                        </button>
                    </td>
                </tr>
            </>
        )
    }

    let couponMessageFinal = couponMessage.valid && plansForm.saved_amount ? `Coupon Applied! You saved: ${plansForm.saved_amount}.` : couponMessage.message

    return (
        <div>
            <div className='plans-container'>
                <div className='plans-table-container'>
                    <table className='plans-table'>
                        <thead>
                            {buildHeaders()}
                        </thead>
                        <tbody>
                            {buildRows()}
                        </tbody>
                        <tfoot>
                            {buildSubscriptionBtns()}
                        </tfoot>
                    </table>
                </div>

                <Modal
                    className={{
                        base: 'payment-modal-content hide-on-print',
                        afterOpen: 'payment-modal-content_after-open hide-on-print',
                        beforeClose: 'payment-modal-content_before-close hide-on-print',
                    }}
                    overlayClassName={{
                        base: 'overlay-base hide-on-print',
                        afterOpen: 'overlay-base_after-open hide-on-print',
                        beforeClose: 'overlay-base_before-close hide-on-print',
                    }}
                    isOpen={payFormModal}
                    onRequestClose={closeModal}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={0}
                    contentLabel='Subscription Plan Payment Form'
                    ariaHideApp={false}
                >
                    <form id='plans-payment-form' onSubmit={(e) => handleSubmit(e)} ref={formRef}>
                        <div className='order-summary'>
                            <div className='order-summary-container'>
                                <p>Plan Selected: <span>{plansForm.plan_title}</span></p>
                                <label className='order-summary-label'>Order Quantity: </label>
                                <input className='order-summary-input' type='string' value={plansForm.quantity} onChange={(e) => handlePlansForm('quanity', e.target.value)} />
                                <p>Total # of Report Suites: <span>{plansForm.report_credits}</span></p>
                                <div className='coupon-input-container'>
                                    <label className='order-summary-label' htmlFor='coupon_code'>Coupon Code:</label>
                                    <div>
                                        <input
                                            className='check-coupon-input'
                                            type="text"
                                            data-recurly="coupon_code"
                                            name='coupon_code'
                                            value={plansForm.coupon_code}
                                            onChange={(e) => handlePlansForm('coupon_code', e.target.value)}
                                        />
                                        <button type='button' className='check-coupon-btn' onClick={(e) => handleCouponCheck(e)}>{couponMessage.valid ? 'Applied' : 'Apply'} </button>
                                    </div>
                                </div>
                                <p className='coupon-message' style={couponMessage.valid ? { 'color': '#69B144' } : { 'color': 'red' }}>{couponMessageFinal}</p>
                                <p className='order-total'>Order Total: <span>{plansForm.total_price}</span></p>
                            </div>
                        </div>
                        <p>Click anywhere outside to close this pop-up.</p>
                        {props.userType === 'FirmAdmin' || props.userType === 'Admin'
                            ? (
                                <div className='all-inputs'>
                                    <div className='group-inputs'>
                                        <div className='input-container'>
                                            <label className='cc-info-label' htmlFor='first_name'>First Name</label>
                                            <input
                                                className='cc-info-input'
                                                type="text"
                                                data-recurly="first_name"
                                                name='first_name'
                                                value={plansForm.first_name}
                                                onChange={(e) => handlePlansForm('first_name', e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className='input-container'>
                                            <label className='cc-info-label' htmlFor='last_name'>Last Name</label>
                                            <input
                                                className='cc-info-input'
                                                type="text"
                                                data-recurly="last_name"
                                                name='last_name'
                                                value={plansForm.last_name}
                                                onChange={(e) => handlePlansForm('last_name', e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='input-container'>
                                        <label className='cc-info-label' htmlFor='address1'>Street Address</label>
                                        <input
                                            className='cc-info-input'
                                            type="text"
                                            data-recurly="address1"
                                            name='address1'
                                            value={plansForm.address1}
                                            onChange={(e) => handlePlansForm('address1', e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='group-inputs'>
                                        <div className='input-container'>
                                            <label className='cc-info-label' htmlFor='city'>City</label>
                                            <input
                                                className='cc-info-input'
                                                type="text"
                                                data-recurly="city"
                                                name='city'
                                                value={plansForm.city}
                                                onChange={(e) => handlePlansForm('city', e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className='input-container'>
                                            <label className='cc-info-label' htmlFor='user-state'>State</label>
                                            <select id='user-state' className='form-control select select-plan-states' name='user-state' data-recurly="state" value={plansForm.state} onChange={(e) => handlePlansForm('state', e.target.value)} required aria-required='true'>
                                                <option value=''></option>
                                                <option value='AL'>Alabama</option>
                                                <option value='AK'>Alaska</option>
                                                <option value='AZ'>Arizona</option>
                                                <option value='AR'>Arkansas</option>
                                                <option value='CA'>California</option>
                                                <option value='CO'>Colorado</option>
                                                <option value='CT'>Connecticut</option>
                                                <option value='DE'>Delaware</option>
                                                <option value='DC'>District of Columbia</option>
                                                <option value='FL'>Florida</option>
                                                <option value='GA'>Georgia</option>
                                                <option value='HI'>Hawaii</option>
                                                <option value='ID'>Idaho</option>
                                                <option value='IL'>Illinois</option>
                                                <option value='IN'>Indiana</option>
                                                <option value='IA'>Iowa</option>
                                                <option value='KS'>Kansas</option>
                                                <option value='KY'>Kentucky</option>
                                                <option value='LA'>Louisiana</option>
                                                <option value='ME'>Maine</option>
                                                <option value='MD'>Maryland</option>
                                                <option value='MA'>Massachusetts</option>
                                                <option value='MI'>Michigan</option>
                                                <option value='MN'>Minnesota</option>
                                                <option value='MS'>Mississippi</option>
                                                <option value='MO'>Missouri</option>
                                                <option value='MT'>Montana</option>
                                                <option value='NE'>Nebraska</option>
                                                <option value='NV'>Nevada</option>
                                                <option value='NH'>New Hampshire</option>
                                                <option value='NJ'>New Jersey</option>
                                                <option value='NM'>New Mexico</option>
                                                <option value='NY'>New York</option>
                                                <option value='NC'>North Carolina</option>
                                                <option value='ND'>North Dakota</option>
                                                <option value='OH'>Ohio</option>
                                                <option value='OK'>Oklahoma</option>
                                                <option value='OR'>Oregon</option>
                                                <option value='PA'>Pennsylvania</option>
                                                <option value='PR'>Puerto Rico</option>
                                                <option value='RI'>Rhode Island</option>
                                                <option value='SC'>South Carolina</option>
                                                <option value='SD'>South Dakota</option>
                                                <option value='TN'>Tennessee</option>
                                                <option value='TX'>Texas</option>
                                                <option value='USA'>USA</option>
                                                <option value='UT'>Utah</option>
                                                <option value='VT'>Vermont</option>
                                                <option value='VA'>Virginia</option>
                                                <option value='WA'>Washington</option>
                                                <option value='WV'>West Virginia</option>
                                                <option value='WI'>Wisconsin</option>
                                                <option value='WY'>Wyoming</option>
                                            </select>
                                        </div>
                                        <input
                                            type='hidden'
                                            data-recurly="country"
                                            name='country'
                                            value={'US'}
                                        />
                                        <div className='input-container'>
                                            <label className='cc-info-label' htmlFor='postal_code'>Zip Code</label>
                                            <input
                                                className='cc-info-input'
                                                type="text"
                                                data-recurly="postal_code"
                                                name='postal_code'
                                                value={plansForm.postal_code}
                                                onChange={(e) => handlePlansForm('postal_code', e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='all-card-info-inputs'>
                                        <div className='input-container'>
                                            <label className='cc-info-label'>Card Number</label>
                                            <CardNumberElement
                                                style={cardElementStyles}
                                            />
                                        </div>
                                        <div className='card-info-group-inputs'>
                                            <div className='card-info-date'>
                                                <div className='card-info-month-group'>
                                                    <label className='cc-info-label'>Month</label>
                                                    <CardMonthElement
                                                        inputType={'select'}
                                                    />
                                                </div>
                                                <span>/</span>
                                                <div className='card-info-year-group'>
                                                    <label className='cc-info-label'>Year</label>
                                                    <CardYearElement
                                                        inputType={'select'}
                                                    />
                                                </div>
                                            </div>
                                            <div className='card-special-no-info'>
                                                <label className='cc-info-label'>CVV</label>
                                                <CardCvvElement />
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn btn-primary subscribe-now-btn' type='submit'>Subscribe Now!</button>
                                    <p style={{ 'textAlign': 'center' }}>OR</p>
                                    <a className='btn btn-primary' href={window.navbar.navbar.children_present ? '/companies' : '/admin/companies/new'}>Continue with Free Plan</a>
                                </div>
                            )
                            : <p style={{ 'textAlign': 'center' }}>Only your Firm Admin can handle a subscription plan payment for your firm. Please contact your Firm Admin.</p>
                        }
                    </form>
                </Modal>
            </div>
        </div>
    );
}

export default PlansPaymentForm;