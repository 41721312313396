import React, { useState } from 'react';
import EventRow from './EventRow';

const OpsTable = (props) => {

  const [expandedRows, setRows] = useState([])

  const handleRowClick = (rowId) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    setRows(newExpandedRows)
  }

  const buildRow = (data) => {
    let visitRows = [];
  
    if (props.visitsData) {
      const formattedDate = new Date(data.date).toLocaleDateString('en-US')
      const hasEvents = data.events.length > 0;
      const clickCallback = () => handleRowClick(data.id);
      visitRows.push(
        <tr key={data.id} onClick={clickCallback}>
            <td className='kfi-text-left visits-far-left-column'>{formattedDate}</td>
            <td className='kfi-text-left'>{data.user['name']}</td>
            <td className='kfi-text-left'>{data.user['firm']}</td>
            <td className='kfi-text-left'>{data.browser}</td>
            <td className='kfi-text-left'>{data.events.length}</td>
        </tr>
      )
      if(hasEvents && expandedRows.includes(data.id)) {
        visitRows.push(
          <EventRows events={data.events}/>
        );
      }
    } else {
      const formattedDate = new Date(data.time_sent).toLocaleDateString('en-US')
      const formattedTime = new Date(data.time_sent).toLocaleTimeString('en-US')
      visitRows.push(
        <tr key={data.id}>
            <td className='kfi-text-left visits-far-left-column'>{`${formattedDate} @ ${formattedTime}`}</td>
            <td className='kfi-text-left'>{data.user['name']}</td>
            <td className='kfi-text-left'>{data.user['firm']}</td>
            <td className='kfi-text-left'>{data.user['email']}</td>
            <td className='kfi-text-left'>{data.email.email}</td>
        </tr>
      )
    }


    return(
      <>
        {visitRows}
      </>
    );
  }
  
  const rows = [];

  Object.values(props.data).forEach(d => rows.push(buildRow(d)));

  const renderUserVisitsTableHeader = () => {
    return (
      <thead>
        <tr>
            <th>Date</th>
            <th>User</th>
            <th>Firm</th>
            <th>Browser</th>
            <th>Event Count</th>
        </tr>
      </thead>
    )
  }

  const renderEmailedSurveysTableHeader = () => {
    return (
      <thead>
        <tr>
            <th>Time Sent</th>
            <th>User</th>
            <th>Firm</th>
            <th>User's Email</th>
            <th>Survey Sent to Email</th>
        </tr>
      </thead>
    )
  }

  return(
    <div>
      <table className='report-table'>
        {props.visitsData ? renderUserVisitsTableHeader() : renderEmailedSurveysTableHeader()}
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  )
};

const EventRows = (props) => {
  const events = props.events
  return(
    <>
      {events.map((event) =>
        <EventRow event={event} key={event.id}/>
      )}
    </>
  )
}

export default OpsTable;