import React, { useContext } from "react";
import { Data } from '../contexts'
import AccountTree from "../../utils/AccountTree";
import { parseMoney } from "../../utils";

const IndustryFunctionalExpenses = (props) => {
    const { calcs } = useContext(Data)
    let pnlRows = []
    const pnlTreeAll = new AccountTree({
        tree: props.tree['pnl']
    })
    pnlTreeAll.forEach((row) => {
        pnlRows.push(row)
    })
    pnlRows = pnlRows.filter(Boolean)

    let compareProgramServicesTotal = 0
    let compareAdminTotal = 0
    let compareFundraisingTotal = 0

    const renderHeaderColumns = () => {
        return (
            <thead>
                <tr key='func-expense-header'>
                    <td>{`Functional Expenses ${props.year}`}</td>
                    <td className='fe-table-cell-green' colSpan='2'>Program Services</td>
                    <td className='fe-table-cell' colSpan='2'>Administrative</td>
                    <td className='fe-table-cell-green' colSpan='2'>Fundraising</td>
                    <td className='fe-table-cell' colSpan='2'>Totals</td>
                </tr>
            </thead>
        )
    }

    // Just need metrics in the Operating Expsenses section that divide into Program Services, Admin, and Fundraising
    const buildRows = () => {
        let reportRows = []
        let sectionKeys = ['total_people_costs', 'total_taxes_and_benefits', 'total_corporate_culture', 'total_customer_costs', 'total_operations']
        pnlRows.forEach(row => {
            let expenseKeys = false
            if (row.children) {
                let childKeys = Object.keys(row.children).join('')
                if (childKeys.includes('admin') || childKeys.includes('program services') || childKeys.includes('fundraising')) {
                    expenseKeys = true
                }
            }

            if (expenseKeys) {
                let compareRowTotal = 0
                let compareTotalRevenue = calcs.avg ? calcs.avg['total_revenue'][props.year] : null
                let compareCommonSize = 0
                let compareProgramVal = '$0'
                let compareAdminVal = '$0'
                let compareFundraisingVal = '$0'
                let compareProgramCommonSize = ''
                let compareAdminValCommonSize = ''
                let compareFundraisingValCommonSize = ''

                Object.keys(row.children).forEach(child => {
                    if (child.includes('program services') && calcs.avg && calcs.avg[child]) {
                        compareProgramServicesTotal += Number(calcs.avg[child][props.year])
                        compareProgramVal = parseMoney(calcs.avg[child][props.year])
                        compareRowTotal += Number(calcs.avg[child][props.year])
                        compareProgramCommonSize = (calcs.avg[child][props.year] / compareTotalRevenue) * 100
                    } else if (child.includes('admin') && calcs.avg && calcs.avg[child]) {
                        compareAdminTotal += Number(calcs.avg[child][props.year])
                        compareAdminVal = parseMoney(calcs.avg[child][props.year])
                        compareRowTotal += Number(calcs.avg[child][props.year])
                        compareAdminValCommonSize = (calcs.avg[child][props.year] / compareTotalRevenue) * 100
                    } else if (child.includes('fundraising') && calcs.avg && calcs.avg[child]) {
                        compareFundraisingTotal += Number(calcs.avg[child][props.year])
                        compareFundraisingVal = parseMoney(calcs.avg[child][props.year])
                        compareRowTotal += Number(calcs.avg[child][props.year])
                        compareFundraisingValCommonSize = (calcs.avg[child][props.year] / compareTotalRevenue) * 100
                    }
                })

                compareCommonSize = compareRowTotal ? (compareRowTotal / compareTotalRevenue) * 100 : 0

                reportRows.push(
                    <tr key={`func-expense-${row.key}`}>
                        <td className='table-copy'>{row.padding + row.copy}</td>
                        <td className='fe-table-cell-green'>{compareProgramVal}</td>
                        <td className='fe-table-cell-green common-size-cell'>{compareProgramCommonSize ? compareProgramCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='fe-table-cell'>{compareAdminVal}</td>
                        <td className='fe-table-cell common-size-cell'>{compareAdminValCommonSize ? compareAdminValCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='fe-table-cell-green'>{compareFundraisingVal}</td>
                        <td className='fe-table-cell-green common-size-cell'>{compareFundraisingValCommonSize ? compareFundraisingValCommonSize.toFixed(1) + '%' : '-'}</td>
                        <td className='fe-table-cell'>{parseMoney(compareRowTotal)}</td>
                        <td className='fe-table-cell functional-common-size common-size-cell'>{compareCommonSize ? compareCommonSize.toFixed(1) + '%' : '-'}</td>
                    </tr>
                )
            }

            if (sectionKeys.includes(row.key)) {
                reportRows.push(
                    <tr key={`func-expense-${row.key}`}>
                        <td className='table-copy'>{row.padding + row.copy}</td>
                        <td className='fe-table-cell-green'></td>
                        <td className='fe-table-cell-green'></td>
                        <td className='fe-table-cell'></td>
                        <td className='fe-table-cell'></td>
                        <td className='fe-table-cell-green'></td>
                        <td className='fe-table-cell-green'></td>
                        <td className='fe-table-cell'></td>
                        <td className='fe-table-cell functional-common-size common-size-cell'></td>
                    </tr>
                )
            }
        })
        return reportRows
    }

    // Final Row needs totals for Program Services, Admin, and Fundraising and Everything
    const buildYouFinalTotalsRow = () => {
        let compareTotalRevenue = calcs.avg ? calcs.avg['total_revenue'][props.year] : 0
        let compareTotalCommonSize = compareTotalRevenue ? ((compareProgramServicesTotal + compareAdminTotal + compareFundraisingTotal) / compareTotalRevenue) * 100 : 0
        let compareProgramServicesTotalCS = compareProgramServicesTotal ? compareProgramServicesTotal / compareTotalRevenue * 100 : 0
        let compareAdminTotalCS = compareAdminTotal ? compareAdminTotal / compareTotalRevenue * 100 : 0
        let compareFundraisingTotalCS = compareFundraisingTotal ? compareFundraisingTotal / compareTotalRevenue * 100 : 0

        return (
            <tr key={`func-expense-total`} style={{ 'borderTop': '1px solid' }}>
                <td className='table-copy'>TOTAL FUNCTIONAL EXPENSES</td>
                <td className='fe-table-cell-green'>{parseMoney(compareProgramServicesTotal)}</td>
                <td className='fe-table-cell-green common-size-cell'>{compareProgramServicesTotalCS ? compareProgramServicesTotalCS.toFixed(1) + '%' : '-'}</td>
                <td>{parseMoney(compareAdminTotal)}</td>
                <td className='common-size-cell'>{compareAdminTotalCS ? compareAdminTotalCS.toFixed(1) + '%' : '-'}</td>
                <td className='fe-table-cell-green'>{parseMoney(compareFundraisingTotal)}</td>
                <td className='fe-table-cell-green common-size-cell'>{compareFundraisingTotalCS ? compareFundraisingTotalCS.toFixed(1) + '%' : '-'}</td>
                <td>{parseMoney(compareProgramServicesTotal + compareAdminTotal + compareFundraisingTotal)}</td>
                <td className='functional-common-size common-size-cell'>{compareTotalCommonSize ? compareTotalCommonSize.toFixed(1) + '%' : '-'}</td>
            </tr>
        )
    }

    const buildTable = () => {
        return (
            <div>
                <div className='monthly-forecast-landscape report-card monthly-sheet'>
                    <div style={{ 'paddingTop': '10px', 'paddingLeft': '5px' }}>
                        <h1 className='report-table-title hide-not-on-print'>Functional Expenses</h1>
                        <div className='pdf-table-div industry-table-container'>
                            <table id='new-annual-table-naics_focused' style={{ whiteSpace: 'pre' }} className='industry-money-table' >
                                {renderHeaderColumns()}
                                <tbody>
                                    {buildRows()}
                                    {buildYouFinalTotalsRow()}
                                </tbody>
                            </table>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {buildTable()}
        </div>
    )
}

export default IndustryFunctionalExpenses;