import React, { useState, useEffect } from 'react';
import { parseMoney } from '../../../utils';

const ForecastGrowthRateInput = (props) => {
    const [state, setState] = useState('')
    useEffect(() => {
        setState(props.value || '0.0')
    }, [props.value])

    const handleStateValue = (value) => {
        let cleanUpVal = isNaN(value) ? '' : value
        setState(cleanUpVal)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(state)
    }

    return (
        <div className='slide-stuff'>
            <div className='title-and-input'>
                <h3>{props.padding + props.title + ' : '}</h3>

                <form className={props.inputsDisplay === 'growthRateInputs' ? 'input-output-form' : 'display-none'} onSubmit={(e) => handleSubmit(e)}>
                    <input
                        className={'slider-input slider-right'}
                        type='text'
                        value={state}
                        onChange={(e) => handleStateValue(e.target.value)}
                    />
                    <span>{' %'}</span>
                </form>
            </div>
        </div>
    )
}

export default ForecastGrowthRateInput;