import React from 'react';

const EventRow = (props) => {
  let eventPropertyKey = Object.keys(props.event["properties"])[0]
  return (
    <tr key={props.event["id"]}>
      <td className="kfi-text-left visits-far-left-column"> - {props.event["name"]}: {props.event["properties"][eventPropertyKey]}</td>
    </tr>
  );
}

export default EventRow;