import React, { useContext } from 'react';
import { Data, Filters } from '../contexts';
import { parseMoney } from '../../utils';
import { MonthlyCashFlowValues, CashFlowCommonSizeArrows } from '../report_components/MoneyHelpers';

const MonthlyHistoricCashFlow = (props) => {
    const allData = useContext(Data);
    const { currentNewFilters } = useContext(Filters);
    let compareWith = currentNewFilters.compare_with;
    const calcsYou = allData.calcs.you;
    const calcsPeers = compareWith === 'Default:None' ? null : allData.calcs.avg;
    const previousYearCalcsYou = allData.previousYearCalcs.you;
    const previousYearCalcsPeers = compareWith === 'Default:None' ? null : allData.previousYearCalcs.avg;
    const calcsYOYYou = allData.yoy_calcs.you;
    const previousYOYYou = allData.previous_yoy_calcs.you;
    let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
    let commonSizeValCellClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';
    const monthNames = Object.values(props.monthsObj)
    let monthsAvailable = 0
    let useableMonths = Object.values(calcsYou['cash'])
    useableMonths.forEach(thing => thing !== null ? monthsAvailable += 1 : null)
    let passedCalcs = null

    if (compareWith === 'Default:Peers') {
        passedCalcs = calcsPeers
    } else if (compareWith === 'Default:YOY') {
        passedCalcs = previousYearCalcsYou
    } else if (compareWith.includes('Forecast')) {
        passedCalcs = props.forecastVals
    }


    const CommonSizeArrowsHelper = (props) => {
        if (_.isNumber(props.totalValueYou) && _.isNumber(props.totalValueCompare)) {
            let arrow;
            const totalRevenueYou = calcsYou['total_revenue'] ? Object.values(calcsYou['total_revenue']).reduce((a, b) => a + b, 0) : 0;
            const totalRevenueCompareTo = props.passedCalcs['total_revenue'] ? Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0) : 0;
            const you = totalRevenueYou ? (props.totalValueYou / totalRevenueYou) : 0
            const compareTo = totalRevenueCompareTo ? (props.totalValueCompare / totalRevenueCompareTo) : 0
            arrow = CashFlowCommonSizeArrows(you, compareTo, props.keyArrBuilder)
            if (compareTo && arrow === 'better' || compareTo && arrow === 'best') {
                arrow = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
            } else if (compareTo && arrow === 'worse' || compareTo && arrow === 'worst') {
                arrow = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
            } else {
                arrow = ''
            }
            return (<td className={arrowCellClass} style={props.style}>{arrow}</td>);
        } else {
            return (<td className={arrowCellClass}></td>);
        }
    }

    const MonthlyCommonSized = (props) => {
        if (props.passedCalcs && _.isNumber(props.totalValue)) {
            const totalRevenue = props.passedCalcs['total_revenue'] ? Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0) : 0
            return (<td className={commonSizeValCellClass} style={props.style}>
                {totalRevenue ? ((props.totalValue / totalRevenue) * 100).toFixed(2) + '%' : ''}
            </td>)
        } else if (props.totalValue === undefined) {
            // For hiding Cash flow from operating activities:, investing activities:, etc.
            return (<td className={commonSizeValCellClass}></td>)
        } else {
            return (<td className={commonSizeValCellClass}>-</td>)
        }
    }

    const buildRows = () => {
        let allRows = []
        let cashFlowRows = [];
        props.rows.forEach((row) => {
            let newRow = null
            if (compareWith === 'Default:None') {
                let { youValues } = MonthlyCashFlowValues(row.key, calcsYou, previousYearCalcsYou, null, null)
                newRow = { ...row, you: youValues, compare: null }
            } else if (compareWith === 'Default:Peers') {
                let { youValues, compareValues } = MonthlyCashFlowValues(row.key, calcsYou, previousYearCalcsYou, calcsPeers, previousYearCalcsPeers)
                newRow = { ...row, you: youValues, compare: compareValues }
            } else if (compareWith === 'Default:YOY') {
                let { youValues, compareValues } = MonthlyCashFlowValues(row.key, calcsYou, previousYearCalcsYou, calcsYOYYou, previousYOYYou)
                newRow = { ...row, you: youValues, compare: compareValues }
            } else if (compareWith.includes('Forecast')) {
                let { youValues, compareValues } = MonthlyCashFlowValues(row.key, calcsYou, previousYearCalcsYou, props.forecastVals, previousYearCalcsYou)
                newRow = { ...row, you: youValues, compare: compareValues }
            }
            if (newRow) { cashFlowRows.push(newRow) }
        })

        return cashFlowRows.map((row, i) => {
            const borderKeys = ['Net cash flow from operating activities', 'Net cash flow from investing activities', 'Net cash flow from financing activities']
            const paddingTopKeys = ['Cash flow from investing activities:', 'Cash flow from financing activities:', 'Net change in cash and cash equivalents', 'Cash at beginning of period', 'Cash at end of period']
            let copyClassName = ''
            let borderTopStyle = borderKeys.includes(row.copy) ? { borderTop: 'solid 1px', minHeight: '17px', paddingBottom: '17px' } : { borderTop: 'none' }
            let metricNameStyle;
            let paddingTopStyle = { paddingTop: '3px' }
            let fontStyle = { textAlign: 'left' }
            let youTotalValue, compareTotalValue;
            let youVals = row['you'];
            let compareVals = row['compare']

            if (row.key === 'cash_beginning_period') {
                compareTotalValue = compareVals ? compareVals[1] : null;
                youTotalValue = youVals ? youVals[1] : null;
            } else if (row.key === 'cash_end_period') {
                let getAllValues = youVals ? Object.values(youVals) : 0;
                let indexOfNullValue = getAllValues.indexOf(null);
                compareTotalValue = compareVals && indexOfNullValue >= 0 ? compareVals[indexOfNullValue] : compareVals && compareVals.hasOwnProperty(12) ? compareVals[12] : null;
                youTotalValue = youVals && indexOfNullValue >= 0 ? youVals[indexOfNullValue] : youVals && youVals.hasOwnProperty(12) ? youVals[12] : null;
            } else {
                let numberOfKeys = youVals ? Object.keys(youVals).length : 0;
                if (numberOfKeys) {
                    compareTotalValue = compareVals ? Object.values(compareVals).slice(0, monthsAvailable).reduce((a, b) => a + b, 0) : 0;
                    youTotalValue = youVals ? Object.values(youVals).reduce((a, b) => a + b, 0) : 0;
                }
            }

            props.pdf ? copyClassName = 'monthly-cash-flow-copy' : fontStyle['minWidth'] = '224px'

            if (props.pdf && paddingTopKeys.includes(row.copy)) {
                paddingTopStyle['paddingTop'] = '8px'
            } else if (paddingTopKeys.includes(row.copy)) {
                paddingTopStyle['paddingTop'] = '20px'
            }

            let cashCopyName = row.copy.toLowerCase().includes('cash')

            if (props.pdf && cashCopyName) {
                fontStyle = { ...fontStyle, fontWeight: 'bold', fontSize: '10px', paddingLeft: 0 }
            } else if (cashCopyName) {
                fontStyle = { ...fontStyle, fontWeight: 'bold', fontSize: '12px', paddingLeft: 0 }
            } else {
                fontStyle = { ...fontStyle, fontWeight: 'none', paddingLeft: '18px' }
            }

            let totalFontBold = { fontWeight: 'bold' };
            let totalStyles = { ...totalFontBold, ...paddingTopStyle }
            metricNameStyle = { ...fontStyle, ...paddingTopStyle }

            let zeroMonths = [];
            for (i = 0; i <= 12 + 1; i++) {
                compareTotalValue === 0 && youVals[i] === 0 || compareTotalValue === 0 && youVals[i] === null ? zeroMonths.push(i) : null
            }

            let rowChecked = row.displayChart && row.checked && props.editMetrics ? <p style={{ 'margin': 0 }}>&#10004;</p> : ''
            let hideRow = zeroMonths.length === 12 || !row.monthlyView;
            let rowStyle = hideRow ? { 'display': 'none' } : borderTopStyle
            return (
                <tr key={row.key + Math.random(89)} style={rowStyle} onClick={() => props.editMetrics ? props.handleSelectAndLayout('money', 'cash_flow', row.relatedCheckedKey) : null}>
                    {!props.pdf ? <td>{rowChecked}</td> : null}
                    <td key={`${i}-name`} className={copyClassName} style={metricNameStyle}>
                        {row.copy}
                    </td>
                    <td key={`${i}-jan`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[1]) : ''}
                    </td>
                    <td key={`${i}-feb`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[2]) : ''}
                    </td>
                    <td key={`${i}-mar`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[3]) : ''}
                    </td>
                    <td key={`${i}-apr`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[4]) : ''}
                    </td>
                    <td key={`${i}-may`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[5]) : ''}
                    </td>
                    <td key={`${i}-jun`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[6]) : ''}
                    </td>
                    <td key={`${i}-jul`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[7]) : ''}
                    </td>
                    <td key={`${i}-aug`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[8]) : ''}
                    </td>
                    <td key={`${i}-sep`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[9]) : ''}
                    </td>
                    <td key={`${i}-oct`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[10]) : ''}
                    </td>
                    <td key={`${i}-nov`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[11]) : ''}
                    </td>
                    <td key={`${i}-dec`} style={paddingTopStyle}>
                        {youVals ? parseMoney(youVals[12]) : ''}
                    </td>
                    {props.page !== 'money' || [0, 12, 16].includes(i) ? <td key={`${Math.random(i)}-you-total`} style={totalStyles}></td> :
                        <td key={`${i}-you-total`} style={totalStyles}>
                            {parseMoney(youTotalValue)}
                        </td>}
                    {props.page !== 'money' || [0, 12, 16].includes(i) ? <td key={Math.random(i)} className={commonSizeValCellClass} style={paddingTopStyle}></td> :
                        <MonthlyCommonSized
                            totalValue={youTotalValue}
                            passedCalcs={calcsYou}
                            style={paddingTopStyle}
                        />}
                    {compareWith === 'Default:None'
                        ? null
                        : props.page !== 'money' || [0, 12, 16].includes(i)
                            ? <td key={Math.random(i)} className={arrowCellClass} style={paddingTopStyle}></td>
                            :
                            <CommonSizeArrowsHelper
                                totalValueYou={youTotalValue}
                                totalValueCompare={compareTotalValue}
                                style={paddingTopStyle}
                                keyArrBuilder={row.key}
                                passedCalcs={passedCalcs}
                            />
                    }
                    {compareWith.includes('Forecast') && [0, 12, 16].includes(i)
                        ? <td key={`${Math.random(i)}-compare-total`} style={totalStyles}></td>
                        : compareWith.includes('Forecast')
                            ? <td key={`${i}-compare-total`} style={totalStyles}>
                                {parseMoney(compareTotalValue)}
                            </td>
                            : null}
                    {compareWith === 'Default:None'
                        ? null
                        : props.page !== 'money' || [0, 12, 16].includes(i)
                            ? <td key={Math.random(i)} className={commonSizeValCellClass} style={paddingTopStyle}></td>
                            :
                            <MonthlyCommonSized
                                totalValue={compareTotalValue}
                                passedCalcs={passedCalcs}
                                style={paddingTopStyle}
                            />
                    }
                </tr>
            )
        })
    }

    const rows = buildRows()
    let numberCompare;
    if (compareWith === 'YOY') {
        numberCompare = 'n = 1'
    } else {
        numberCompare = `n = ${props.n}`
    }

    let YOYyearDisplay = props.calendarYearEnd ? props.year - 1 : props.year
    let firstTotal = currentNewFilters.report_period === 'Trailing 12' ? `T12 ${props.year}` : 'Total'
    let firstPercent = currentNewFilters.report_period === 'Trailing 12' ? `You T12 ${props.year}` : 'You YTD'
    let secondPercent = null
    if (compareWith.includes('Forecast') && currentNewFilters.report_period !== 'Trailing 12') {
        secondPercent = props.monthlyCompareWithTitle
    } else if (compareWith === 'Default:Peers' && currentNewFilters.report_period !== 'Trailing 12') {
        secondPercent = 'Peers Avg'
    } else if (compareWith === 'Default:YOY' && currentNewFilters.report_period !== 'Trailing 12') {
        secondPercent = `YOY ${YOYyearDisplay}`
    } else if (currentNewFilters.report_period === 'Trailing 12' && compareWith !== 'Default:None') {
        secondPercent = `T12 ${props.year - 1}`
    }

    const buildMonthlyCashFlowTable = () => {
        if (props.pdf) {
            let pageViewClass = 'landscape-view'
            return (
                <div className={pageViewClass}>
                    <div className='report-pdf-landscape'>
                        <div>
                            <h1 className='report-table-title'>{`Cash Flow - ${props.clientName}`}</h1>
                            <div className='money-pdf-table-div'>
                                <table id='monthly-cash-flow-pdf' className='money-monthly-pdf-table' style={{ whiteSpace: 'pre' }} >
                                    <thead>
                                        <tr>
                                            <td></td>
                                            {monthNames.map(month =>
                                                <td key={Math.random(89)}>
                                                    {month}
                                                </td>
                                            )}
                                            <td style={{ 'fontWeight': 'bold' }}>{firstTotal}</td>
                                            <td className={commonSizeValCellClass}>{firstPercent}</td>
                                            {compareWith === 'Default:None' ? null : <td className={arrowCellClass}></td>}
                                            {secondPercent
                                                ? <td className={commonSizeValCellClass}>{secondPercent}</td>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                        <tr><td className='footer-cell'><i>{numberCompare}</i></td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='money-report-card monthly-sheet'>
                    <div className='money-report-container'>
                        <table className='monthly-report-table sheets' id='monthly-cash-flow'>
                            <thead>
                                <tr>
                                    <td className='edit-metrics-cell' onClick={() => props.handleEditMetrics()}>{props.editMetrics ? <i className='fa fa-save hide-on-print' style={{ 'fontSize': '2em' }}></i> : <i className='fa fa-pencil edit-icon hide-on-print' style={{ 'fontSize': '2em' }}></i>}</td>
                                    <td></td>
                                    {monthNames.map(month =>
                                        <td key={Math.random(89)}>
                                            {month}
                                        </td>
                                    )}
                                    <td style={{ 'fontWeight': 'bold' }}>{firstTotal}</td>
                                    <td className='common-size-cell'>{firstPercent}</td>
                                    {compareWith === 'Default:None' ? null : <td className='common-size-arrow'></td>}
                                    {secondPercent
                                        ? <td className='common-size-cell'>{secondPercent}</td>
                                        : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                            <tfoot>
                                <tr><td><i>{numberCompare}</i></td></tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )
        }
    }

    return buildMonthlyCashFlowTable()
}

export default MonthlyHistoricCashFlow;
