import React, { useState } from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import PlansPaymentForm from './PlansPaymentForm';
import { parseMoney } from './utils/index';
import moment from 'moment';
import Modal from 'react-modal';

const RecurlyPlans = (props) => {
    const [currentPlan, setCurrentPlan] = useState(props.planType || '')
    const [activeTab, setActiveTab] = useState(props.planType !== 'Freemium' ? 'current-sub' : 'plans')
    const [currentSubscription, setCurrentSubscription] = useState({
        id: props.subData.id || null,
        plan_type: props.subData.plan_type === 'Freemium' ? 'Free' : props.subData.plan_type,
        subscription_starts: props.subData.subscription_starts || '-',
        subscription_ends: props.subData.subscription_ends || '-',
        status: props.subData.status || 'No Subscriptions',
        quantity: props.subData.quantity || 0,
        report_credit_total: props.subData.report_credit_total || 0
    })
    const [futureSubscription, setFutureSubscription] = useState({
        plan_type: props.futureSubData.plan_type || null,
        subscription_starts: props.futureSubData.subscription_starts || null,
        status: props.futureSubData.status || null,
        quantity: props.futureSubData.quantity || null,
    })
    const [displayPlans, setDisplayPlans] = useState(false);
    const [changePlansForm, setChangePlansForm] = useState({
        plan_title: 'Peerview Advisor - Monthly',
        plan_name: 'peerviewadvisor-m',
        first_name: '',
        last_name: '',
        address1: '',
        city: '',
        state: '',
        country: 'US',
        postal_code: '',
        quantity: 1,
        report_credits: 5,
        total_price: '$150'
    })
    const [invoicesData, setInvoicesData] = useState([{}])
    const [subModalOpen, setSubModalOpen] = useState(false);
    const [adminFormData, setAdminFormData] = useState({
        plan_title: 'Peerview Advisor',
        plan_name: 'peerviewadvisor-a',
        report_credits: 5,
        subscription_starts: '',
        subscription_ends: ''
    })

    const openModal = () => {
        setSubModalOpen(true);
    }

    const closeModal = () => {
        setSubModalOpen(false);
    }

    const SubModal = () => {

        const cancelMessage = {
            h1: 'Are you sure about this?',
            p: 'Once you cancel your subscription, you will go back to the Free version and lose your current report credits and all your reports will locked again.'
        }

        return (
            <Modal
                className={{
                    base: 'subscription-modal-content hide-on-print',
                    afterOpen: 'subscription-modal-content_after-open hide-on-print',
                    beforeClose: 'subscription-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'overlay-base hide-on-print',
                    afterOpen: 'overlay-base_after-open hide-on-print',
                    beforeClose: 'overlay-base_before-close hide-on-print',
                }}
                isOpen={subModalOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={0}
                contentLabel='Subscription Notice'
                ariaHideApp={false}
            >
                <div>
                    <h1>{cancelMessage.h1}</h1>
                    <p>{cancelMessage.p}</p>
                    <button className='btn btn-primary' onClick={() => closeModal()}>Keep Subscription</button>
                    <span style={{ 'fontWeight': 'bold', 'margin': '0 10px' }}>OR</span>
                    <button className='btn btn-danger' onClick={() => handleSubsCancel()}>Cancel Subscription</button>
                </div>
            </Modal>
        )
    }

    const getAllInvoices = async () => {
        try {
            const res = await fetch('/get_invoices', {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                }
            })
            const resJson = await res.json()
            setInvoicesData((prevState) => {
                return resJson['invoices'];
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleTabs = (tab) => {
        switch (tab) {
            case 'sub-history':
                getAllInvoices()
                setActiveTab(tab)
                break;
            default:
                setActiveTab(tab)
                break;
        }
    }

    const handleChangePlansForm = (input, value) => {
        setChangePlansForm((prevState) => {
            let quanityNum = 1;
            let basePrice = 0
            let totalPrice = 0
            let baseNumberOfClientReports = 0;
            let totalNumOfClientReports = 0;
            switch (input) {
                case 'plan_name':
                    let planTitle = ''
                    if (value === 'peerviewadvisor-m') {
                        basePrice = 150
                        baseNumberOfClientReports = 5
                        planTitle = 'Peerview Advisor - Monthly'
                    }

                    if (value === 'peerviewadvisor-a') {
                        basePrice = 1620
                        baseNumberOfClientReports = 5
                        planTitle = 'Peerview Advisor - Annual'
                    }

                    if (value === 'peerviewadvisorplus-m') {
                        basePrice = 560
                        baseNumberOfClientReports = 20
                        planTitle = 'Peerview Advisor+ - Monthly'
                    }

                    if (value === 'peerviewadvisorplus-a') {
                        basePrice = 6048
                        baseNumberOfClientReports = 20
                        planTitle = 'Peerview Advisor+ - Annual'
                    }

                    if (value === 'peerviewadvisorteam-m') {
                        basePrice = 1250
                        baseNumberOfClientReports = 50
                        planTitle = 'Peerview Advisor Team - Monthly'
                    }

                    if (value === 'peerviewadvisorteam-a') {
                        basePrice = 13500
                        baseNumberOfClientReports = 50
                        planTitle = 'Peerview Advisor Team - Annual'
                    }

                    totalPrice = parseMoney(quanityNum * basePrice)

                    return {
                        ...prevState,
                        plan_title: planTitle,
                        plan_name: value,
                        report_credits: baseNumberOfClientReports,
                        total_price: totalPrice,
                        quantity: quanityNum
                    }
                case 'first_name':
                    return {
                        ...prevState,
                        first_name: value
                    }
                case 'last_name':
                    return {
                        ...prevState,
                        last_name: value
                    }
                case 'address1':
                    return {
                        ...prevState,
                        address1: value
                    }
                case 'city':
                    return {
                        ...prevState,
                        city: value
                    }

                case 'state':
                    return {
                        ...prevState,
                        state: value
                    }
                case 'postal_code':
                    return {
                        ...prevState,
                        postal_code: value
                    }
                case 'quanity':
                    quanityNum = Math.round(Number(value));
                    if (changePlansForm.plan_name === 'peerviewadvisor-m') {
                        basePrice = 150
                        baseNumberOfClientReports = 5
                    }

                    if (changePlansForm.plan_name === 'peerviewadvisor-a') {
                        basePrice = 1620
                        baseNumberOfClientReports = 5
                    }

                    if (changePlansForm.plan_name === 'peerviewadvisorplus-m') {
                        basePrice = 560
                        baseNumberOfClientReports = 20
                    }

                    if (changePlansForm.plan_name === 'peerviewadvisorplus-a') {
                        basePrice = 6048
                        baseNumberOfClientReports = 20
                    }

                    if (changePlansForm.plan_name === 'peerviewadvisorteam-m') {
                        basePrice = 1250
                        baseNumberOfClientReports = 50
                    }

                    if (changePlansForm.plan_name === 'peerviewadvisorteam-a') {
                        basePrice = 13500
                        baseNumberOfClientReports = 50
                    }

                    totalPrice = parseMoney(quanityNum * basePrice)
                    totalNumOfClientReports = quanityNum * baseNumberOfClientReports

                    return {
                        ...prevState,
                        report_credits: totalNumOfClientReports,
                        total_price: totalPrice,
                        quantity: quanityNum
                    }
                default:
                    break;
            }
        })
    }

    const handleAdminFormData = (input, value) => {
        setAdminFormData((prevState) => {
            switch (input) {
                case 'plan_name':
                    let planTitle = ''
                    if (value === 'peerviewadvisor-a') {
                        planTitle = 'Peerview Advisor'
                    }

                    if (value === 'peerviewadvisorplus-a') {
                        planTitle = 'Peerview Advisor+'
                    }

                    if (value === 'peerviewadvisorteam-a') {
                        planTitle = 'Peerview Advisor Team'
                    }

                    return {
                        ...prevState,
                        plan_title: planTitle,
                        plan_name: value,
                    }
                case 'report_credits':
                    return {
                        ...prevState,
                        report_credits: value,
                    }
                case 'subscription_starts':
                    return {
                        ...prevState,
                        subscription_starts: value,
                    }
                case 'subscription_ends':
                    return {
                        ...prevState,
                        subscription_ends: value,
                    }
                default:
                    break;
            }
        })
    }

    const cancelPlanChange = async () => {
        try {
            const res = await fetch(`/cancel_change/${currentSubscription.id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
            })

            const resJson = await res
            if (resJson.ok) {
                setFutureSubscription((prevState) => {
                    return {}
                })
            }
            alert('Your plan changes are canceled!')
        } catch (error) {
            alert(error)
            console.log(error);
        }
    }

    const handleAdminFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await fetch(`/custom_plan`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    form_data: adminFormData
                }),
            })

            const resJson = await res.json()

            setCurrentPlan(resJson.plan_type)
            setCurrentSubscription((prevState) => {
                return resJson.subscription
            })
            alert('Your plan has changed successfully!')
        } catch (error) {
            alert(error)
            console.log(error);
        }
    }

    const handlePlanChangeSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await fetch(`/change_plan`, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    form_data: changePlansForm
                }),
            })

            const resJson = await res
            if (resJson.future_subscription) {
                setFutureSubscription((prevState) => {
                    return resJson.futureSubscription
                })
            }
            setCurrentPlan(resJson.plan_type)
            setCurrentSubscription((prevState) => {
                return resJson.subscription
            })
            setDisplayPlans(false)
            alert('Your plan has changed successfully!')
        } catch (error) {
            alert(error)
            console.log(error);
        }
    }

    const handleSubsCancel = async () => {
        try {
            const res = await fetch(`/cancel_subscription/${currentSubscription.id}`, {
                method: 'PUT',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
            })
            const resJson = await res.json()
            setCurrentPlan(resJson.plan_type)
            setCurrentSubscription((prevState) => {
                return resJson.subscription
            })
            closeModal()
        } catch (error) {
            console.log(error);
        }
    }

    const buildInvoiceTableRows = () => {
        return invoicesData.map((invoice) => {
            return (
                <tr>
                    <td>{invoice.invoice_number}</td>
                    <td>{moment(invoice.date).format('LL')}</td>
                    <td>{invoice.paid_by}</td>
                    <td>{invoice.plan}</td>
                    <td>{parseMoney(invoice.amount, true)}</td>
                    <td>{invoice.status}</td>
                </tr>
            )
        })
    }

    let planEndText = 'Plan Ends & Auto Renews:'
    if (currentSubscription.status === 'expired') {
        planEndText = 'Plan Ends:'
    }

    return (
        <div>
            <SubModal />
            <ul id='nav-tabs-subscriptions' className='report-tab-list' role='tablist'>
                <li className={activeTab === 'current-sub' ? 'subscription-tab-item active' : 'subscription-tab-item'} role='presentation' onClick={() => handleTabs('current-sub')}>Your Plan</li>
                <li className={activeTab === 'sub-history' ? 'subscription-tab-item active' : 'subscription-tab-item'} role='presentation' onClick={() => handleTabs('sub-history')}>Subscription History</li>
                <li className={activeTab === 'plans' ? 'subscription-tab-item active' : 'subscription-tab-item'} role='presentation' onClick={() => handleTabs('plans')}>Plans</li>
            </ul>
            <div className='subscription-card'>
                <div className={activeTab === "current-sub" ? "show-section" : "hide-section"}>
                    <h1>Current Plan</h1>
                    <div className='current-plan-container'>
                        <p><span>Plan:</span> {currentPlan === 'Freemium' ? 'Free' : currentPlan}</p>
                        {
                            currentPlan !== 'Freemium'
                                ? (<div>
                                    <p><span>Plan Starts:</span> {moment(currentSubscription.subscription_starts).format('LL')}</p>
                                    <p><span>{planEndText}</span> {moment(currentSubscription.subscription_ends).format('LL')}</p>
                                    <p><span>Status:</span> {currentSubscription.status.toUpperCase()}</p>
                                    {currentSubscription.quantity ? <p><span>Quantity:</span> {currentSubscription.quantity}</p> : null}
                                    <p><span>Report Credits Purchased:</span> {currentSubscription.report_credit_total}</p>
                                </div>)
                                : null
                        }
                        {
                            futureSubscription.plan_type
                                ? (<div style={{ 'backgroundColor': '#bfe0ae' }}>
                                    <p><span>Future Plan:</span> {futureSubscription.plan_type}</p>
                                    <p><span>Future Plan Starts:</span> {moment(futureSubscription.subscription_starts).format('LL')}</p>
                                    <p><span>Status:</span> {futureSubscription.status.toUpperCase()}</p>
                                    <button className='btn btn-danger' onClick={() => cancelPlanChange()}>Cancel Plan Change</button>
                                </div>)
                                : null
                        }
                        <div>
                            {props.userType === 'FirmAdmin' && currentPlan !== 'Freemium' || props.userType === 'Admin' && currentPlan !== 'Freemium'
                                ? (<div className='subscription-actions'>
                                    <button className='btn btn-primary' onClick={() => setDisplayPlans(!displayPlans)}>Change Plans / Report Credits</button>
                                    <button className='btn btn-danger' onClick={() => openModal()}>Cancel Subscription</button>
                                </div>)
                                : null}
                            {props.userType === 'Admin'
                                ? <div className='admin-subscription-actions'>
                                    <h1>Admin: Edit Plan & Report Credits</h1>
                                    <form id='admin-subscription-edit-form' onSubmit={(e) => handleAdminFormSubmit(e)} >
                                        <div className='admin-subscription-edit-container'>
                                            <div className='change-plans-container'>
                                                <div className={adminFormData.plan_name === 'peerviewadvisor-a' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleAdminFormData('plan_name', 'peerviewadvisor-a')}>
                                                    <p className='plan-name'>Peerview Advisor</p>
                                                    {adminFormData.plan_name === 'peerviewadvisor-a' ? <p>Selected</p> : null}
                                                </div>

                                                <div className={adminFormData.plan_name === 'peerviewadvisorplus-a' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleAdminFormData('plan_name', 'peerviewadvisorplus-a')}>
                                                    <p className='plan-name'>Peerview Advisor Plus</p>
                                                    {adminFormData.plan_name === 'peerviewadvisorplus-a' ? <p>Selected</p> : null}
                                                </div>

                                                <div className={adminFormData.plan_name === 'peerviewadvisorteam-a' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleAdminFormData('plan_name', 'peerviewadvisorteam-a')}>
                                                    <p className='plan-name'>Peerview Advisor Team</p>
                                                    {adminFormData.plan_name === 'peerviewadvisorteam-a' ? <p>Selected</p> : null}
                                                </div>
                                            </div>

                                            <div className='form-changes-summary'>
                                                <p><span>Plan Selected:</span> {adminFormData.plan_title}</p>
                                                
                                                <div>
                                                    <label htmlFor='report-credits'>Report Credits: </label>
                                                    <input name='report-credits' type='string' value={adminFormData.report_credits} onChange={(e) => handleAdminFormData('report_credits', e.target.value)} required />
                                                </div>

                                                <div>
                                                    <label htmlFor='subscription_starts'>Start Date: </label>
                                                    <input name='subscription_starts' type='date' value={adminFormData.subscription_starts} onChange={(e) => handleAdminFormData('subscription_starts', e.target.value)} required />
                                                </div>

                                                <div>
                                                    <label htmlFor='subscription_ends'>End Date: </label>
                                                    <input name='subscription_ends' type='date' value={adminFormData.subscription_ends} onChange={(e) => handleAdminFormData('subscription_ends', e.target.value)} required />
                                                </div>
                                            </div>
                                        </div>
                                        <button className='admin-subscription-btn btn btn-primary' type='submit'>Update Company Plan / Report Credits</button>
                                    </form>
                                </div>
                                : null}
                            <div className={displayPlans ? 'change-plan-section show-section' : 'hide-section'}>
                                <h2>Change Your Plan / Report Credits</h2>
                                <form id='change-plan-form' onSubmit={(e) => handlePlanChangeSubmit(e)} >
                                    <div className='change-plans-container'>
                                        <div className='plan-container'>
                                            <p className='plan-name'>Peerview Advisor</p>
                                            <div className={changePlansForm.plan_name === 'peerviewadvisor-a' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleChangePlansForm('plan_name', 'peerviewadvisor-a')}>
                                                <p>Annual $500</p>
                                                {changePlansForm.plan_name === 'peerviewadvisor-a' ? <p>Selected</p> : null}
                                            </div>
                                            <div className={changePlansForm.plan_name === 'peerviewadvisor-m' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleChangePlansForm('plan_name', 'peerviewadvisor-m')}>
                                                <p>Monthly $50</p>
                                                {changePlansForm.plan_name === 'peerviewadvisor-m' ? <p>Selected</p> : null}
                                            </div>
                                        </div>

                                        <div className='plan-container'>
                                            <p className='plan-name'>Peerview Advisor Plus</p>
                                            <div className={changePlansForm.plan_name === 'peerviewadvisorplus-a' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleChangePlansForm('plan_name', 'peerviewadvisorplus-a')}>
                                                <p>Annual $2,000</p>
                                                {changePlansForm.plan_name === 'peerviewadvisorplus-a' ? <p>Selected</p> : null}
                                            </div>
                                            <div className={changePlansForm.plan_name === 'peerviewadvisorplus-m' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleChangePlansForm('plan_name', 'peerviewadvisorplus-m')}>
                                                <p>Monthly $200</p>
                                                {changePlansForm.plan_name === 'peerviewadvisorplus-m' ? <p>Selected</p> : null}
                                            </div>
                                        </div>

                                        <div className='plan-container'>
                                            <p className='plan-name'>Peerview Advisor Team</p>
                                            <div className={changePlansForm.plan_name === 'peerviewadvisorteam-a' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleChangePlansForm('plan_name', 'peerviewadvisorteam-a')}>
                                                <p>Annual $5,000</p>
                                                {changePlansForm.plan_name === 'peerviewadvisorteam-a' ? <p>Selected</p> : null}
                                            </div>
                                            <div className={changePlansForm.plan_name === 'peerviewadvisorteam-m' ? 'plan-card plan-card-active' : 'plan-card'} onClick={(e) => handleChangePlansForm('plan_name', 'peerviewadvisorteam-m')}>
                                                <p>Monthly $500</p>
                                                {changePlansForm.plan_name === 'peerviewadvisorteam-m' ? <p>Selected</p> : null}
                                            </div>
                                        </div>

                                    </div>
                                    <p>To add/remove Report Credits, just increase/decrease the quantity of your subscription.</p>
                                    <p>For example, if you already have 1 quantity worth 5 report credits and want 5 more report credits then change the quantity to 2.</p>
                                    <p>Please contact us if you require any assistance.</p>
                                    <div className='order-summary'>
                                        <div>
                                            <p>Plan Selected: <span>{changePlansForm.plan_title}</span></p>
                                            <label>Order Quantity: </label>
                                            <input type='string' value={changePlansForm.quantity} onChange={(e) => handleChangePlansForm('quanity', e.target.value)} required />
                                            <p>Total # of Report Suites: <span>{changePlansForm.report_credits}</span></p>
                                            <p>Order Total: <span>{changePlansForm.total_price}</span></p>
                                        </div>
                                    </div>
                                    <button className='change-plan-btn btn btn-primary' type='submit'>Change My Plan!</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={activeTab === "sub-history" ? "show-section" : "hide-section"}>
                    <div className='subscriptions-history'>
                        <h1>Subscription History</h1>
                        <table className='subscription-table'>
                            <thead>
                                <tr>
                                    <td>Invoice #</td>
                                    <td>Date</td>
                                    <td>Paid By</td>
                                    <td>Plan</td>
                                    <td>Amount</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {buildInvoiceTableRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={activeTab === "plans" ? "show-section" : "hide-section"}>
                    <h1>Subscription Plans</h1>
                    <p className='details'>Select a plan and enter your payment information below.</p>
                    <RecurlyProvider publicKey={window.data.rk}>
                        <Elements>
                            <PlansPaymentForm userType={props.userType} handleTabs={handleTabs} setCurrentPlan={setCurrentPlan} setCurrentSubscription={setCurrentSubscription} />
                        </Elements>
                    </RecurlyProvider>
                </div>
            </div>
        </div>
    );
}

export default RecurlyPlans;