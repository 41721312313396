import React from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
drilldown(Highcharts);
// VERY STRANGE BUG WARNING!!!
// When the legend points are clicked on, the selectedFilters props are okay to use.
// However, when a pie chart slice is clicked on, it somehow mutates the Parent Component's state back to its original default values and doesn't keep any selected filter changes from the dropdown filter.
// Cannot no figure out the reason why or if it is a bug with readt highchart's drilldown property.  
const PRPie = ({ pieTopLevelArr, child_count, drillup, drillup_link, drillup_text, setSelectedFilters, selectedFilters, handleSubmit }) => {
  let pieH = 460 + (20 * pieTopLevelArr.length)
  let buildPie = {
    chart: {
      type: 'pie',
      height: pieH,
      width: 425,
      marginTop: 40,
      events: {
        drilldown: function (event) {
          event.preventDefault()
          setSelectedFilters(prevState => {
            return {
              ...prevState,
              naics_string: event.point.drilldown,
            }
          })
          handleSubmit(event, true, event.point.drilldown)
        }
      },
      style: {
        fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
      }
    },
    legend: {
      maxHeight: '100%',
      itemMarginTop: 5,
      layout: 'vertical',
      symbolHeight: 11,
      symbolWidth: 11,
      symbolRadius: 5,
      symbolPadding: 3,
      squareSymbol: true,
      navigation: {
        activeColor: '#3E576F',
        animation: true,
        arrowSize: 12,
      },
      itemStyle: {
        fontSize: "12px",
        fontWeight: 'bold'
      }
    },
    credits: {
      enabled: false
    },
    title: {
      margin: 30,
      text: 'NAICS Analysis',
      style: {
        fontSize: '18px',
        fontWeight: 'normal'
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false
        },
      },
      pie: {
        colors: ['#69B144', '#00AEEF', '#542667', '#FFB74D', '#7cb5ec', '#b056d8', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1', '#2f7ed8', '#ff5959', '#8bbc21', '#910000', '#1aadce',
          '#492970', '#f28f43', '#77a1e5'],
        point: {
          events: {
            legendItemClick: function (event) {
              event.preventDefault()
              setSelectedFilters(prevState => {
                return {
                  ...prevState,
                  naics_string: event.target.drilldown,
                }
              })
              handleSubmit(event, true, event.target.drilldown)
            }
          }
        },
        size: 360,
        showInLegend: true,
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b> of {point.total}<br/>',
      style: {
        fontSize: '12px'
      }
    },
    series: [
      {
        name: 'NAICS Analysis',
        colorByPoint: true,
        data: pieTopLevelArr,
      }
    ]
  };

  const drillupSetState = () => {
    setSelectedFilters(prevState => {
      return {
        ...prevState,
        naics_string: drillup_link,
      }
    })
    handleSubmit(false, true, drillup_link)
  }

  return (
    <div key='pieKey1' className='pr-pie'>
      {drillup ? <a className='drillup-button' onClick={() => drillupSetState()} >{drillup_text}</a> : null}
      <HighchartsReact highcharts={Highcharts} options={buildPie} />
    </div>
  )
}
export default PRPie;