import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Data } from '../contexts';
import { ToastContainer, toast } from 'react-toastify';
import { useTour } from '@reactour/tour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import DropdownFilter from '../../utils/DropdownFilter'
import LoaderGraphic from '../../utils/LoaderGraphic';
import IndustryPDFandPrint from './IndustryPDFandPrint';
import IndustryReport from './IndustryReport';
import Modal from 'react-modal';

const IndustryReportContainer = (props) => {
  const componentRefs = useRef();
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('money');
  const [moneyActiveTab, setMoneyActiveTab] = useState('pnl');
  const [peopleActiveTab, setPeopleActiveTab] = useState('financial-detail');
  const [customersActiveTab, setCustomersActiveTab] = useState('financial-detail');
  const [indMoneyDashboard, setIndMoneyDashboard] = useState({ id: null, selectedItems: [], layout: [], noChangesMade: true });
  const [indPeopleDashboard, setIndPeopleDashboard] = useState({ id: null, selectedItems: [], layout: [], noChangesMade: true });
  const [indCustomersDashboard, setIndCustomersDashboard] = useState({ id: null, selectedItems: [], layout: [], noChangesMade: true });
  const [state, setState] = useState({
    filters: {},
    filterOptions: {},
    calcs: {
      you: {},
      min: {},
      max: {},
      avg: {},
      docs_avg: {},
      wagedata: {}
    },
    meta: {},
    tree: {},
    year: '',
    yearRange: [],
  })
  const [dashboardViewMoneyState, setDashboardViewMoneyState] = useState({
    isOpen: false,
    action: 'close',
    edit_view: {},
    current_view: {},
    user_views: [],
    firms_views: []
  });
  const [dashboardViewPeopleState, setDashboardViewPeopleState] = useState({
    isOpen: false,
    action: 'close',
    edit_view: {},
    current_view: {},
    user_views: [],
    firms_views: []
  });
  const [dashboardViewCustomersState, setDashboardViewCustomersState] = useState({
    isOpen: false,
    action: 'close',
    edit_view: {},
    current_view: {},
    user_views: [],
    firms_views: []
  });
  const [PDFPrintModalOpen, setPDFPrintModalOpen] = useState(false);
  const [displayPages, setDisplayOptions] = useState({
    cover_page: {
      checked: true,
      label: 'Cover Page',
    },
    money: {
      checked: true,
      label: 'Money',
      all_pages: true,
      pages: {
        pnl: {
          checked: true,
          label: 'Profit & Loss',
        },
        doctor_pnl: {
          checked: true,
          label: 'Doctor P&L',
        },
        functional_expenses: {
          checked: true,
          label: 'Functional Expenses',
        },
        balance_sheet: {
          checked: true,
          label: 'Balance Sheet',
        },
        kfis: {
          checked: true,
          label: `KFI's`,
        },
        dashboard: {
          checked: true,
          label: 'Dashboard',
        }
      }
    },
    people: {
      checked: false,
      label: 'People',
      all_pages: true,
      pages: {
        financial_detail: {
          checked: false,
          label: 'Financial Detail',
        },
        kpi: {
          checked: false,
          label: `KPI`,
        },
        dashboard: {
          checked: false,
          label: 'Dashboard',
        }
      }
    },
    customers: {
      checked: false,
      label: 'Customers',
      all_pages: true,
      pages: {
        financial_detail: {
          checked: false,
          label: 'Financial Detail',
        },
        kpi: {
          checked: false,
          label: `KPI`,
        },
        dashboard: {
          checked: false,
          label: 'Dashboard',
        }
      }
    },
  })
  const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)
  const [activePopover, setActivePopover] = useState(null)

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        let code = state.filters && state.filters.hasOwnProperty('code') ? state.filters.code : '';
        let year = state.filters && state.filters.hasOwnProperty('year') ? state.filters.year : '';

        const calculations = await fetch(`/api/v1/industry_reportable?code=${code}&year=${year}`, {
          headers: {
            'X-CSRF-Token': window.token
          }
        });
        const calcsRes = await calculations.json();

        setState(prevState => {
          return {
            ...prevState,
            filters: calcsRes.filters,
            filterOptions: calcsRes.filter_options,
            calcs: {
              you: {},
              min: {},
              max: {},
              avg: calcsRes.calcs.table.avg,
              docs_avg: calcsRes.calcs.table.docs_avg,
              wagedata: calcsRes.calcs.table.wagedata
            },
            meta: calcsRes.calcs.table.meta,
            tree: calcsRes.tree,
            year: calcsRes.calcs.table.meta.end_year,
            yearRange: _.range(calcsRes.calcs.table.meta.start_year, calcsRes.calcs.table.meta.end_year + 1)
          }
        });

        const dashBoard = await fetch(`/dashboard_view/info/?report_type=industry`, {
          headers: {
            'X-CSRF-Token': window.token,
          }
        })
        const dashBoardRes = await dashBoard.json()
        setIndMoneyDashboard(() => {
          return {
            id: dashBoardRes['money']['current_view'].id,
            selectedItems: dashBoardRes['money']['current_view'].selected_items,
            layout: dashBoardRes['money']['current_view'].dashboard_layout,
            noChangesMade: true
          }
        })
        setDashboardViewMoneyState((prevState) => {
          return {
            ...prevState,
            current_view: dashBoardRes['money']['current_view'],
            user_views: dashBoardRes['money']['user_views'],
            firms_views: dashBoardRes['money']['firms_views']
          }
        })
        setIndPeopleDashboard(() => {
          return {
            id: dashBoardRes['people']['current_view'].id,
            selectedItems: dashBoardRes['people']['current_view'].selected_items,
            layout: dashBoardRes['people']['current_view'].dashboard_layout,
            noChangesMade: true
          }
        })
        setDashboardViewPeopleState((prevState) => {
          return {
            ...prevState,
            current_view: dashBoardRes['people']['current_view'],
            user_views: dashBoardRes['people']['user_views'],
            firms_views: dashBoardRes['people']['firms_views']
          }
        })
        setIndCustomersDashboard(() => {
          return {
            id: dashBoardRes['customers']['current_view'].id,
            selectedItems: dashBoardRes['customers']['current_view'].selected_items,
            layout: dashBoardRes['customers']['current_view'].dashboard_layout,
            noChangesMade: true
          }
        })
        setDashboardViewCustomersState((prevState) => {
          return {
            ...prevState,
            current_view: dashBoardRes['customers']['current_view'],
            user_views: dashBoardRes['customers']['user_views'],
            firms_views: dashBoardRes['customers']['firms_views']
          }
        })
        setIsLoading(false)
        props.tracker_id && !props.tour_taken ? setIsOpen(true) : null
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        toast.error('Sorry something went wrong. Please try again later.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
    fetchAllData();
  }, []);

  const handleFilterSubmit = async (e, filters) => {
    e.preventDefault();
    setIsLoading(true);
    let code = filters && filters.hasOwnProperty('code') ? filters.code : '';
    let revenueBand = filters && filters.hasOwnProperty('revenue_band') ? filters.revenue_band : '';
    let region = filters && filters.hasOwnProperty('region') ? filters.region : '';
    let dataset = filters && filters.hasOwnProperty('dataset') ? filters.dataset : 'Peers';
    let datatype = filters && filters.hasOwnProperty('datatype') ? filters.datatype : 'Private';
    let year = filters && filters.hasOwnProperty('year') ? filters.year : '';

    try {
      const calculations = await fetch(`/api/v1/industry_reportable?code=${code}&revenue_band=${revenueBand}&region=${region}&datatype=${datatype}&dataset=${dataset}&year=${year}`, {
        headers: {
          'X-CSRF-Token': window.token
        }
      });
      const calcsRes = await calculations.json();
      setState(prevState => {
        return {
          ...prevState,
          filters: calcsRes.filters,
          filterOptions: calcsRes.filter_options,
          calcs: {
            you: calcsRes.calcs.table.avg,
            min: calcsRes.calcs.table.min,
            max: calcsRes.calcs.table.max,
            avg: calcsRes.calcs.table.avg,
            wagedata: calcsRes.calcs.table.wagedata
          },
          meta: calcsRes.calcs.table.meta,
          tree: calcsRes.tree,
          year: calcsRes.calcs.table.meta.end_year,
          yearRange: _.range(calcsRes.calcs.table.meta.start_year, calcsRes.calcs.table.meta.end_year + 1)
        }
      });
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      toast.error('Sorry something went wrong. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleDashEditFormSubmit = async (event, gridItemsSelected, page) => {
    event.preventDefault();
    let itemsCheckedTrue = [];
    let newLayout;
    gridItemsSelected.forEach((c) => {
      if (c.checked) {
        itemsCheckedTrue.push(c.key)
      }
    })

    const handleGridItems = (oldDashboard) => {
      return itemsCheckedTrue.map((c, i) => {
        let itemLayout = oldDashboard.layout.find((d) => d['i'] === c);
        if (itemLayout) {
          return itemLayout
        } else {
          if (c.includes('pie')) {
            return {
              i: c,
              isBounded: false,
              isDraggable: true,
              isResizable: true,
              x: ((i * 2) % (6)),
              y: ((i * 2) % (3)),
              w: 4,
              h: 3,
              minW: 1,
              minH: 1,
              maxW: 6,
              maxH: 12,
              resizeHandles: ['se']
            }
          } else if (c === 'wage_data_scaffold_table') {
            return { i: 'wage_data_scaffold_table', isBounded: false, isDraggable: true, isResizable: false, x: 0, y: 2, w: 6, h: 1, minW: 6, minH: 1, maxW: 6, maxH: 12, resizeHandles: ['se'] }
          } else {
            return {
              i: c,
              isBounded: false,
              isDraggable: true,
              isResizable: true,
              x: ((i * 2) % (6)),
              y: Infinity,
              w: 2,
              h: 1,
              minW: 1,
              minH: 1,
              maxW: 6,
              maxH: 12,
              resizeHandles: ['se']
            }
          }
        }
      })
    }

    if (page === 'money') {
      setIndMoneyDashboard((prevState) => {
        let oldDashboard = _.cloneDeep(prevState)
        newLayout = handleGridItems(oldDashboard)
        return { ...prevState, selectedItems: gridItemsSelected, layout: newLayout, noChangesMade: true }
      })
    }

    if (page === 'people') {
      setIndPeopleDashboard((prevState) => {
        let oldDashboard = _.cloneDeep(prevState)
        newLayout = handleGridItems(oldDashboard)
        return { ...prevState, selectedItems: gridItemsSelected, layout: newLayout, noChangesMade: true }
      })
    }

    if (page === 'customers') {
      setIndCustomersDashboard((prevState) => {
        let oldDashboard = _.cloneDeep(prevState)
        newLayout = handleGridItems(oldDashboard)
        return { ...prevState, selectedItems: gridItemsSelected, layout: newLayout, noChangesMade: true }
      })
    }
    try {
      // All dashboards for a company have the same id.
      const res = await fetch(`/dashboard_view/${indMoneyDashboard.id}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: indMoneyDashboard.id,
          report: 'industry',
          page: page,
          selected_items: gridItemsSelected,
          dashboard_layout: newLayout
        }),
      })

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        toast.success('Dashboard Changes Saved!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Changes were not saved properly.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleOnLayoutChange = (type, newLayout) => {
    switch (activeTab) {
      case 'money':
        setIndMoneyDashboard((prevState) => {
          let currentView = dashboardViewMoneyState['current_view']
          let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewMoneyState['user_views'].find(v => v.id === currentView.id)
          return { ...prevState, layout: newLayout, noChangesMade: noChangesVal }
        })
        break;
      case 'people':
        setIndPeopleDashboard((prevState) => {
          let currentView = dashboardViewPeopleState['current_view']
          let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewPeopleState['user_views'].find(v => v.id === currentView.id)
          return { ...prevState, layout: newLayout, noChangesMade: false }
        })
        break;
      case 'customers':
        setIndCustomersDashboard((prevState) => {
          let currentView = dashboardViewCustomersState['current_view']
          let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewCustomersState['user_views'].find(v => v.id === currentView.id)
          return { ...prevState, layout: newLayout, noChangesMade: false }
        })
        break;
    }
  }

  const updateDashBoardLayoutChange = async (activeTab, layout) => {
    let currentView = null;
    let selectedItems = []
    switch (activeTab) {
      case 'money':
        currentView = dashboardViewMoneyState['current_view']
        selectedItems = indMoneyDashboard.selectedItems
        break;
      case 'people':
        currentView = dashboardViewPeopleState['current_view']
        selectedItems = indPeopleDashboard.selectedItems
        break;
      case 'customers':
        currentView = dashboardViewCustomersState['current_view']
        selectedItems = indCustomersDashboard.selectedItems
        break;
    }

    try {
      const res = await fetch(`/dashboard_view/${indMoneyDashboard.id}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: currentView.id,
          report_type: `industry_${activeTab}`,
          dashboard_layout: layout,
          selected_items: selectedItems
        }),
      })
      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        if (activeTab === 'money') {
          setDashboardViewMoneyState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
          setIndMoneyDashboard((prevState) => {
            return {
              ...prevState,
              noChangesMade: true
            }
          })
        }
        if (activeTab === 'people') {
          setDashboardViewPeopleState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
          setIndPeopleDashboard((prevState) => {
            return {
              ...prevState,
              noChangesMade: true
            }
          })
        }
        if (activeTab === 'customers') {
          setDashboardViewCustomersState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
          setIndCustomersDashboard((prevState) => {
            return {
              ...prevState,
              noChangesMade: true
            }
          })
        }
        toast.success('Dashboard Changes Saved!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Changes were not saved properly.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleActiveTab = (section) => {
    // The Responsive Grid Layout does not work properly with tabs and should just be re-rendered.
    // This is in case someone is going back to a previous section and they had the dashboard displaying last.
    switch (section) {
      case 'money':
        if (moneyActiveTab === 'previousDashboard') {
          setMoneyActiveTab('dashboard')
        }
        if (peopleActiveTab === 'dashboard') {
          setPeopleActiveTab('previousDashboard')
        }
        if (customersActiveTab === 'dashboard') {
          setCustomersActiveTab('previousDashboard')
        }
        setActiveTab('money')
        break;
      case 'people':
        if (moneyActiveTab === 'dashboard') {
          setMoneyActiveTab('previousDashboard')
        }
        if (peopleActiveTab === 'previousDashboard') {
          setPeopleActiveTab('dashboard')
        }
        if (customersActiveTab === 'dashboard') {
          setCustomersActiveTab('previousDashboard')
        }
        setActiveTab('people')
        break;
      case 'customers':
        if (moneyActiveTab === 'dashboard') {
          setMoneyActiveTab('previousDashboard')
        }
        if (peopleActiveTab === 'dashboard') {
          setPeopleActiveTab('previousDashboard')
        }
        if (customersActiveTab === 'previousDashboard') {
          setCustomersActiveTab('dashboard')
        }
        setActiveTab('customers')
        break;
    }
  }

  const handleDashboardViewModal = (action, info = null) => {
    const dashViewModalCallback = (prevState, action, info) => {
      if (action === 'close') {
        return { ...prevState, isOpen: false, action: 'close' }
      } else if (action === 'edit_view') {
        let currentView = prevState.current_view
        let noViewEdit = currentView.permission_details !== 'view_only' || prevState.user_views.find(v => v.id === currentView.id)
        let currentInfo = !info ? currentView : info
        if (!noViewEdit) {
          toast.error(`Sorry You Are NOT Allowed to Edit this Dashboard View. You need to be the owner of this dashboard view in order to edit.`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return prevState
        } else {
          return { ...prevState, isOpen: true, action: action, edit_view: currentInfo }
        }
      } else {
        return { ...prevState, isOpen: true, action: action, edit_view: {} }
      }
    }

    if (activeTab === 'money') {
      setDashboardViewMoneyState(prevState => dashViewModalCallback(prevState, action, info))
    }
    if (activeTab === 'people') {
      setDashboardViewPeopleState(prevState => dashViewModalCallback(prevState, action, info))
    }
    if (activeTab === 'customers') {
      setDashboardViewCustomersState(prevState => dashViewModalCallback(prevState, action, info))
    }
  }

  const updateDashBoardView = async (e = false, dashViewId, dashViewInfo) => {
    let selectedItems = [];
    let layout = []
    if (activeTab === 'money') {
      selectedItems = indMoneyDashboard.selectedItems
      layout = indMoneyDashboard.layout
    }
    if (activeTab === 'people') {
      selectedItems = indPeopleDashboard.selectedItems
      layout = indPeopleDashboard.layout
    }
    if (activeTab === 'customers') {
      selectedItems = indCustomersDashboard.selectedItems
      layout = indCustomersDashboard.layout
    }

    const updatedView = {
      name: dashViewInfo.name,
      private: dashViewInfo.viewVisibilty === 'only_me',
      report_type: `industry_${activeTab}`,
      permission_details: dashViewInfo.viewPermission,
      selected_items: selectedItems,
      dashboard_layout: layout
    }

    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedView),
      })

      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        if (activeTab === 'money') {
          setDashboardViewMoneyState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
          setIndMoneyDashboard((prevState) => {
            return {
              ...prevState,
              noChangesMade: true
            }
          })
        }
        if (activeTab === 'people') {
          setDashboardViewPeopleState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
          setIndPeopleDashboard((prevState) => {
            return {
              ...prevState,
              noChangesMade: true
            }
          })
        }
        if (activeTab === 'customers') {
          setDashboardViewCustomersState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
          setIndCustomersDashboard((prevState) => {
            return {
              ...prevState,
              noChangesMade: true
            }
          })
        }

        toast.success('Changes to view saved!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Changes to view could not be saved.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const deleteDashBoardView = async (e, dashViewId) => {
    e.preventDefault()
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        if (activeTab === 'money') {
          let defaultView = dashboardViewMoneyState.firms_views.find(v => v.name === 'Default')
          let currentViewDeleted = dashViewId === dashboardViewMoneyState.current_view.id
          setDashboardViewMoneyState(prevState => {
            let oldUserViews = prevState.user_views
            let newUserViews = oldUserViews.filter(v => v.id !== dashViewId)
            let oldFirmsViews = prevState.firms_views
            let newFirmsViews = oldFirmsViews.filter(v => v.id !== dashViewId)

            if (currentViewDeleted) {
              return {
                ...prevState,
                current_view: defaultView,
                user_views: newUserViews,
                firms_views: newFirmsViews
              }
            } else {
              return {
                ...prevState,
                user_views: newUserViews,
                firms_views: newFirmsViews
              }
            }
          })

          setIndMoneyDashboard((prevState) => {
            if (currentViewDeleted) {
              return {
                id: defaultView.id,
                selectedItems: defaultView.selected_items,
                layout: defaultView.dashboard_layout,
                noChangesMade: true
              }
            } else {
              return prevState
            }
          })
        }

        if (activeTab === 'people') {
          let defaultView = dashboardViewPeopleState.firms_views.find(v => v.name === 'Default')
          let currentViewDeleted = dashViewId === dashboardViewPeopleState.current_view.id
          setDashboardViewPeopleState(prevState => {
            let oldUserViews = prevState.user_views
            let newUserViews = oldUserViews.filter(v => v.id !== dashViewId)
            let oldFirmsViews = prevState.firms_views
            let newFirmsViews = oldFirmsViews.filter(v => v.id !== dashViewId)

            if (currentViewDeleted) {
              return {
                ...prevState,
                current_view: defaultView,
                user_views: newUserViews,
                firms_views: newFirmsViews
              }
            } else {
              return {
                ...prevState,
                user_views: newUserViews,
                firms_views: newFirmsViews
              }
            }
          })

          setIndPeopleDashboard((prevState) => {
            if (currentViewDeleted) {
              return {
                id: defaultView.id,
                selectedItems: defaultView.selected_items,
                layout: defaultView.dashboard_layout,
                noChangesMade: true
              }
            } else {
              return prevState
            }
          })
        }
        if (activeTab === 'customers') {
          let defaultView = dashboardViewCustomersState.firms_views.find(v => v.name === 'Default')
          let currentViewDeleted = dashViewId === dashboardViewCustomersState.current_view.id
          setDashboardViewCustomersState(prevState => {
            let oldUserViews = prevState.user_views
            let newUserViews = oldUserViews.filter(v => v.id !== dashViewId)
            let oldFirmsViews = prevState.firms_views
            let newFirmsViews = oldFirmsViews.filter(v => v.id !== dashViewId)

            if (currentViewDeleted) {
              return {
                ...prevState,
                current_view: defaultView,
                user_views: newUserViews,
                firms_views: newFirmsViews
              }
            } else {
              return {
                ...prevState,
                user_views: newUserViews,
                firms_views: newFirmsViews
              }
            }
          })

          setIndCustomersDashboard((prevState) => {
            if (currentViewDeleted) {
              return {
                id: defaultView.id,
                selectedItems: defaultView.selected_items,
                layout: defaultView.dashboard_layout,
                noChangesMade: true
              }
            } else {
              return prevState
            }
          })
        }

        toast.success('View was deleted!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Try again later.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const createDashBoardView = async (newDashView) => {
    let selectedItems = [];
    let layout = []
    if (activeTab === 'money') {
      selectedItems = indMoneyDashboard.selectedItems
      layout = indMoneyDashboard.layout
    }
    if (activeTab === 'people') {
      selectedItems = indPeopleDashboard.selectedItems
      layout = indPeopleDashboard.layout
    }
    if (activeTab === 'customers') {
      selectedItems = indCustomersDashboard.selectedItems
      layout = indCustomersDashboard.layout
    }

    let newView = {
      name: newDashView.name,
      private: newDashView.viewVisibilty === 'only_me',
      report_type: `industry_${activeTab}`,
      permission_details: newDashView.viewPermission,
      view_apply: newDashView.viewApply,
      selected_items: selectedItems,
      dashboard_layout: layout
    }

    try {
      const res = await fetch(`/dashboard_view`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newView),
      })

      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        if (activeTab === 'money') {
          setIndMoneyDashboard((prevState) => {
            return {
              ...prevState,
              id: dashViewRes.current_view.id,
              noChangesMade: true
            }
          })
          setDashboardViewMoneyState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
        }
        if (activeTab === 'people') {
          setIndPeopleDashboard((prevState) => {
            return {
              ...prevState,
              id: dashViewRes.current_view.id,
              noChangesMade: true
            }
          })
          setDashboardViewPeopleState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
        }
        if (activeTab === 'customers') {
          setIndCustomersDashboard((prevState) => {
            return {
              ...prevState,
              id: dashViewRes.current_view.id,
              noChangesMade: true
            }
          })
          setDashboardViewCustomersState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
        }

        toast.success('New view created!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      let errorMessage = dashViewRes.name ? `Name ${dashViewRes.name}` : 'New view was not created properly'
      toast.error(`Sorry something went wrong. ${errorMessage}.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const getDashBoardView = async (dashViewId) => {
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })

      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        let currentDash = dashViewRes['current_view']
        if (activeTab === 'money') {
          setIndMoneyDashboard(() => {
            return {
              id: currentDash.id,
              selectedItems: currentDash.selected_items,
              layout: currentDash.dashboard_layout,
              noChangesMade: true
            }
          })
          setDashboardViewMoneyState(prevState => {
            return {
              ...prevState,
              current_view: currentDash
            }
          })
        }
        if (activeTab === 'people') {
          setIndPeopleDashboard(() => {
            return {
              id: currentDash.id,
              selectedItems: currentDash.selected_items,
              layout: currentDash.dashboard_layout,
              noChangesMade: true
            }
          })
          setDashboardViewPeopleState(prevState => {
            return {
              ...prevState,
              current_view: currentDash
            }
          })
        }
        if (activeTab === 'customers') {
          setIndCustomersDashboard(() => {
            return {
              id: currentDash.id,
              selectedItems: currentDash.selected_items,
              layout: currentDash.dashboard_layout,
              noChangesMade: true
            }
          })
          setDashboardViewCustomersState(prevState => {
            return {
              ...prevState,
              current_view: currentDash
            }
          })
        }

      }
    } catch (error) {
      console.log(error);
      toast.error(`Sorry something went wrong.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleDashboardSelections = (item, parentItemInfo = null) => {
    let itemsCheckedTrue = [];
    let newLayout = [];
    const selectionsCallback = (prevState, item, parentItemInfo) => {
      let oldDashInfo = _.cloneDeep(prevState);
      if (parentItemInfo) {
        let parentItem = oldDashInfo.selectedItems.find((c) => c.key === parentItemInfo.key);
        if (parentItem.children) {
          let childItem = parentItem.children.find((child) => child.key === item.key);
          childItem.checked = !childItem.checked
          let numberOfChildrenChecked = parentItem.children.filter(child => child.checked)
          parentItem.checked = numberOfChildrenChecked.length > 0
        }
      } else {
        let selectedChart = oldDashInfo.selectedItems.find((c) => c.key === item.key);
        let checkStatus = !selectedChart.checked
        if (selectedChart.children) {
          selectedChart.children = selectedChart.children.map((c) => {
            c.checked = checkStatus
            return c;
          })
        }
        selectedChart.checked = checkStatus;
      }

      oldDashInfo.selectedItems.forEach((c) => {
        if (c.checked) {
          if (c.children) {
            let clonedItem = _.cloneDeep(c);
            clonedItem.children = clonedItem.children.filter(child => child.checked)
            itemsCheckedTrue.push(clonedItem)
          } else {
            itemsCheckedTrue.push(c)
          }
        }
      })

      let width = 0
      itemsCheckedTrue.forEach((itemChecked, i) => {
        let oldItem = oldDashInfo.layout.find(d => d['i'] === itemChecked.key);
        if (oldItem) {
          newLayout.push(oldItem)
        } else {
          let key = itemChecked.key
          if (key.includes('bar_chart')) {
            let finalWidth = 0
            if (0 < width && width < 6) {
              finalWidth = width
              width += 3
            } else {
              width = 3
              finalWidth = 0
            }
            newLayout.push({
              i: key,
              isBounded: false,
              isDraggable: false,
              isResizable: true,
              x: finalWidth,
              y: Infinity,
              w: 2,
              h: 2,
              minW: 2,
              minH: 2,
              maxW: 6,
              maxH: 12,
              resizeHandles: ['se']
            })
          } else {
            let finalWidth = 0
            if (0 < width && width < 6) {
              finalWidth = width
              width += 6
            } else {
              width = 6
              finalWidth = 0
            }
            newLayout.push({
              i: key,
              isBounded: false,
              isDraggable: true,
              isResizable: true,
              x: finalWidth,
              y: Infinity,
              w: 2,
              h: 1,
              minW: 1,
              minH: 1,
              maxW: 6,
              maxH: 12,
              resizeHandles: ['se']
            })
          }
        }
      })

      return { ...prevState, selectedItems: oldDashInfo.selectedItems, layout: newLayout, noChangesMade: false }
    }

    if (activeTab === 'money') {
      setIndMoneyDashboard((prevState) => selectionsCallback(prevState, item, parentItemInfo))
    }
    if (activeTab === 'people') {
      setIndPeopleDashboard((prevState) => selectionsCallback(prevState, item, parentItemInfo))
    }
    if (activeTab === 'customers') {
      setIndCustomersDashboard((prevState) => selectionsCallback(prevState, item, parentItemInfo))
    }
  }

  const handleSelectAll = (select) => {
    const selectAllCallBack = (prevState, select) => {
      let newLayout = []
      let oldDashInfo = _.cloneDeep(prevState);
      let allItems = oldDashInfo.selectedItems.map((item) => {
        if (item.children) {
          item.children = item.children.map((c) => {
            c.checked = select
            return c;
          })
        }
        item.checked = select;
        return item;
      })
      let width = 0
      allItems.forEach((itemChecked, i) => {
        if (itemChecked.checked) {
          let oldItem = oldDashInfo.layout.find(d => d['i'] === itemChecked.key);
          if (oldItem) {
            newLayout.push(oldItem)
          } else {
            let key = itemChecked.key
            if (key.includes('bar_chart')) {
              let finalWidth = 0
              if (0 < width && width < 6) {
                finalWidth = width
                width += 3
              } else {
                width = 4
                finalWidth = 0
              }
              newLayout.push({
                i: key,
                isBounded: false,
                isDraggable: false,
                isResizable: true,
                x: finalWidth,
                y: Infinity,
                w: 2,
                h: 2,
                minW: 2,
                minH: 2,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            } else {
              let finalWidth = 0
              if (0 < width && width < 6) {
                finalWidth = width
                width += 6
              } else {
                width = 6
                finalWidth = 0
              }
              newLayout.push({
                i: key,
                isBounded: false,
                isDraggable: true,
                isResizable: true,
                x: finalWidth,
                y: Infinity,
                w: 2,
                h: 2,
                minW: 2,
                minH: 2,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            }
          }
        }
      })
      return { ...prevState, selectedItems: allItems, layout: newLayout, noChangesMade: false }
    }
    if (activeTab === 'money') {
      setIndMoneyDashboard((prevState) => selectAllCallBack(prevState, select))
    }
    if (activeTab === 'people') {
      setIndPeopleDashboard((prevState) => selectAllCallBack(prevState, select))
    }
    if (activeTab === 'customers') {
      setIndCustomersDashboard((prevState) => selectAllCallBack(prevState, select))
    }
  }

  const handlePDFandPrintChecked = (key, pageKey = null) => {
    setDisplayOptions((prevState) => {
      let newOptions = _.cloneDeep(prevState)

      if (pageKey) {
        newOptions[key]['pages'][pageKey]['checked'] = !newOptions[key]['pages'][pageKey]['checked']
        let anyPagesChecked = Object.keys(newOptions[key]['pages']).map(p => newOptions[key]['pages'][p]['checked'])
        newOptions[key]['checked'] = anyPagesChecked.includes(true)
      } else {
        newOptions[key]['checked'] = !newOptions[key]['checked']

        if (newOptions[key]['pages']) {
          Object.keys(newOptions[key]['pages']).forEach(p => {
            newOptions[key]['pages'][p]['checked'] = newOptions[key]['checked']
          })
        }
      }
      return newOptions
    })
  }

  const handleMouseEnter = (item) => {
    setActivePopover(item);
  };

  const handleMouseLeave = (item) => {
    if (item === 'leave') {
      setActivePopover(null);
    }
  };

  const openFiltersModal = () => {
    setFiltersModalIsOpen(true)
  }

  const closeFiltersModal = () => {
    setFiltersModalIsOpen(false)
  }

  const { filters, filterOptions, loading, calcs, meta, tree, year, yearRange } = state;

  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme='colored'
      />
      <div className='industry-report-tabs'>
        <ul>
          <li className={`${activeTab === 'money' ? 'money' : ''} report-link`} role='presentation' onClick={() => handleActiveTab('money')}>
            <a className={activeTab === 'money' ? 'active' : ''} >Money</a>
          </li>
          <li className={`${activeTab === 'people' ? 'people' : ''} report-link`} role='presentation' onClick={() => handleActiveTab('people')}>
            <a className={activeTab === 'people' ? 'active' : ''} >People</a>
          </li>
          <li className={`${activeTab === 'customers' ? 'customers' : ''} report-link`} role='presentation' onClick={() => handleActiveTab('customers')}>
            <a className={activeTab === 'customers' ? 'active' : ''} >Customers</a>
          </li>
        </ul>
      </div>
      <div className='industry-container'>
        <Modal
          className={{
            base: 'client-modal-content',
            afterOpen: 'client-modal-content_after-open',
            beforeClose: 'client-modal-content_before-close',
          }}
          overlayClassName={{
            base: 'overlay-base',
            afterOpen: 'overlay-base_after-open',
            beforeClose: 'overlay-base_before-close',
          }}
          isOpen={filtersModalIsOpen}
          onRequestClose={closeFiltersModal}
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={0}
          contentLabel='Industry Report Filters Modal'
        >
          <div>
            <header className='company-modal-header'>
              <h1 className='client-company-name'>Report Filters</h1>
            </header>
            <div className='report-filters-container'>
              <div className='client-info-div'>
                <p>NAICS: {meta.ind_naics}, {meta.ind_name}</p>
              </div>
              <DropdownFilter
                report={'industry'}
                calendarYearEnd={true}
                current={filters}
                options={filterOptions}
                onClick={handleFilterSubmit}
                clas={'money-dropdown-filters'}
              />
            </div>
          </div>
        </Modal>
        <div id='sticky-topnav-left-btns'>
          <IndustryPDFandPrint
            page={'industry'}
            naicsCode={meta.ind_naics}
            planAccess={{
              export_to_excel: false,
              print_and_pdf: true,
            }}
            componentRefs={componentRefs}
            displayPages={displayPages}
            PDFPrintModalOpen={PDFPrintModalOpen}
            setPDFPrintModalOpen={setPDFPrintModalOpen}
            handlePDFandPrintChecked={handlePDFandPrintChecked}
            xlsx={false}
            year={year}
            yearRange={yearRange}
          />
        </div>
        <div id='sticky-topnav-right-btns'>
          <button className='hide-on-print' onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faQuestionCircle} /></button>
        </div>
        <div id='sticky-side-btns'>
          <span
            className='filters-btn-span hide-on-print'
            onMouseEnter={() => handleMouseEnter('filters')}
            onMouseLeave={() => handleMouseLeave('leave')}
          >
            <button className='filters-btn'><FontAwesomeIcon icon={faAnglesLeft} onClick={() => openFiltersModal()} /></button>
            {activePopover === 'filters' && (
              <div className='filters-popover'>
                Display Filters
              </div>)}
          </span>
        </div>
        <Data.Provider value={{ calcs }} >
          {isLoading ? <LoaderGraphic /> :
            <IndustryReport
              ref={componentRefs}
              activeTab={activeTab}
              moneyActiveTab={moneyActiveTab}
              setMoneyActiveTab={setMoneyActiveTab}
              peopleActiveTab={peopleActiveTab}
              setPeopleActiveTab={setPeopleActiveTab}
              customersActiveTab={customersActiveTab}
              setCustomersActiveTab={setCustomersActiveTab}
              displayPages={displayPages}
              PDFPrintModalOpen={PDFPrintModalOpen}
              filters={filters}
              meta={meta}
              tree={tree}
              year={year}
              yearRange={yearRange}
              handleDashboardViewModal={handleDashboardViewModal}
              dashboardViewMoneyState={dashboardViewMoneyState}
              dashboardViewPeopleState={dashboardViewPeopleState}
              dashboardViewCustomersState={dashboardViewCustomersState}
              updateDashBoardView={updateDashBoardView}
              deleteDashBoardView={deleteDashBoardView}
              createDashBoardView={createDashBoardView}
              getDashBoardView={getDashBoardView}
              handleDashboardSelections={handleDashboardSelections}
              handleSelectAll={handleSelectAll}
              indMoneyDashboard={indMoneyDashboard}
              indPeopleDashboard={indPeopleDashboard}
              indCustomersDashboard={indCustomersDashboard}
              handleOnLayoutChange={handleOnLayoutChange}
              handleDashEditFormSubmit={handleDashEditFormSubmit}
              updateDashBoardLayoutChange={updateDashBoardLayoutChange}
            />}
        </Data.Provider>
      </div>
    </div>
  );
};

export default IndustryReportContainer;
