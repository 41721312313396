import React, { useState, useEffect } from 'react';
import { createMoneyFormat, parseMoney, percentify } from '../../utils';
import moment from 'moment';
import AuditScenarios from './AuditScenarios';

const defaultExpenseScenario = {
    id: 'expense-default',
    name: 'Default',
    report_type: 'expense-analytics',
    created_by: '',
    updated_by: '',
    updated_at: '',
    version: 1,
    data: {
        dollar_change: {
            integer: 10000,
            text: "$10,000"
        },
        percent_change: {
            integer: 10,
            text: "10%"
        },
        audit_data: [{
            sheet: "pnl",
            key: "total_compensation",
            expectation: 'Just as expected.',
            expectation_met: 'Yes',
            imm: '',
            comments: 'My comment here.'
        }
        ]
    }
}

const AuditExpenseAnalytics = (props) => {
    const [auditExpenseScenario, setAuditExpenseScenario] = useState(props.auditScenario)
    const [showDetails, setShowDetails] = useState(false)
    useEffect(() => {
        setAuditExpenseScenario(() => {
            return props.auditScenario
        })
    }, [props.auditScenario])

    const handleAuditInfo = (key, sheet, type, value) => {
        const auditDataExists = auditExpenseScenario['data']['audit_data'].find(a => a.key === key)

        if (auditDataExists) {
            const newAuditData = auditExpenseScenario['data']['audit_data'].map((a) => {
                if (a.key === key) {
                    a[type] = value
                }
                return a
            })

            setAuditExpenseScenario((prevState) => {
                let newAuditScenario = prevState.data
                newAuditScenario['audit_data'] = newAuditData
                return {
                    ...prevState,
                    data: newAuditScenario
                }
            })          
        } else {
            const newAuditDataObj = {
                sheet: sheet,
                key: key
            }
  
            newAuditDataObj['expectation'] = ''
            newAuditDataObj['expectation_met'] = ''
            newAuditDataObj['imm'] = ''
            newAuditDataObj['comments'] = ''
            
            newAuditDataObj[type] = value
            setAuditExpenseScenario((prevState) => {
                let newAuditScenario = prevState.data
                newAuditScenario['audit_data'] = [newAuditDataObj, ...newAuditScenario['audit_data']]
                return {
                    ...prevState,
                    data: newAuditScenario
                }
            })

        }
    }

    const deltaDollarValue = (row) => {
        let finalVal = null
        if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(row[props.yearRange[1]])) {
            finalVal = row[props.yearRange[1]] - row[props.yearRange[0]]
        }
        return finalVal
    }

    const expectedExpenseValue = (row) => {
        let totalRev = props.rows.find(r => r.key == 'total_revenue')
        let totalRevCurrentYear = totalRev ? [props.yearRange[1]] : 0
        let previousYearPercentOfRev = row[`you${props.yearRange[0]}`] / 100
        return previousYearPercentOfRev * totalRevCurrentYear
    }

    const varianceActualVsEstimated = (row, expectedExpenseVal = 0) => {
        return row[props.yearRange[1]] - expectedExpenseVal
    }

    const additionalScopingMet = (varianceVS, deltaDollarVal, deltaPercentVal) => {
        if (varianceVS) {
            let varianceVsVal = Math.abs(varianceVS);
            return (varianceVsVal > 0 && varianceVsVal > 39000) ? 'Yes' : 'No'
        } else {
            return (Math.abs(deltaPercentVal) > 0.10 && Math.abs(deltaDollarVal) > 39000) ? 'Yes' : 'No'
        }

    }

    const deltaFixedPercentValue = (row) => {
        let finalVal = null
        if (_.isNumber(row[props.yearRange[0]]) || _.isNumber(row[props.yearRange[1]])) {
            let deltaVal = row[props.yearRange[1]] - row[props.yearRange[0]]
            finalVal = row[props.yearRange[0]] ? ((deltaVal / row[props.yearRange[0]]) * 100) : 100;
        }
        return finalVal
    }

    const deltaPercentColumn = (row) => {
        let finalVal = null
        if (_.isNumber(row[`you${props.yearRange[0]}`]) || _.isNumber(row[`you${props.yearRange[1]}`])) {
            let deltaVal = row[`you${props.yearRange[1]}`] - row[`you${props.yearRange[0]}`]
            finalVal = deltaVal ? deltaVal.toFixed(0) + '%' : '100%';
        }
        return finalVal
    }

    function buildRows() {
        let previousYearTotalVal = props.rows.reduce((a, b) => b[props.yearRange[0]] ? a + b[props.yearRange[0]] : a + 0, 0)
        let currentYearTotalVal = props.rows.reduce((a, b) => b[props.yearRange[1]] ? a + b[props.yearRange[1]] : a + 0, 0)

        return props.rows.map((row, i) => {
            let underlineFormat, toplineFormat, backColor;
            if (row.key !== 'total_revenue') {
                if (row.header_key || row.key === 'total_all_expenses') {
                    backColor = '#bfe0ae';
                    toplineFormat = 'solid 1px';
                    underlineFormat = 'solid 1px';
                }

                const copyStyle = {
                    'backgroundColor': backColor,
                    borderBottom: underlineFormat,
                    borderTop: toplineFormat,
                    height: '30px'
                }

                let auditInfo = props.auditScenario['data']['audit_data'].find((a) => a.key === row.key);
                let expectation = auditInfo ? auditInfo.expectation : '';
                let expectationMet = auditInfo ? auditInfo.expectation_met : '';
                let immVal = auditInfo ? auditInfo.imm : '';
                let comments = auditInfo ? auditInfo.comments : '';
                let deltaPercentVal = deltaPercentColumn(row)
                let expectedExpenseVal = row.expenseType === 'variable' ? expectedExpenseValue(row) : null;
                let varianceVsVal = row.expenseType === 'variable' ? varianceActualVsEstimated(row, expectedExpenseVal) : null;
                let deltaDollarVal = row.expenseType === 'fixed' ? deltaDollarValue(row) : null
                let deltaFixedPercentVal = row.expenseType === 'fixed' ? deltaFixedPercentValue(row) : null
                let additionalScopeVal = additionalScopingMet(varianceVsVal, deltaDollarVal, deltaFixedPercentVal)
                let hideRow = '';
                let zeroYears = [];
                props.yearRange.forEach(y => { row[y] === 0 && Math.abs(row['you' + y]).toFixed(1) === '0.0' && Math.abs(row['avg' + y]).toFixed(1) === '0.0' ? zeroYears.push(y) : null })
                zeroYears.length === props.yearRange.length ? hideRow = row.copy : null
                const displayCellValue = row.key && row.key !== 'total_all_expenses'
                const rowKey = row.key ? `${row.sheet}-${row.key}-expense` : `${row.sheet}-${row.header_key}-expense`

                return (
                    <tr style={hideRow === row.copy ? { display: 'none' } : copyStyle} key={rowKey} id={rowKey}>
                        <td className='table-copy'>{row.copy}</td>
                        <td>
                            {displayCellValue ? parseMoney(row[props.yearRange[0]]) : row.key === 'total_all_expenses' ? parseMoney(previousYearTotalVal) : null}
                        </td>
                        <td className='normal-whitespace-cell money-table-cell'>
                            {displayCellValue ? percentify(row[`you${props.yearRange[0]}`]) : null}
                        </td>
                        <td>
                            {displayCellValue ? parseMoney(row[props.yearRange[1]]) : row.key === 'total_all_expenses' ? parseMoney(currentYearTotalVal) : null}
                        </td>
                        <td className='normal-whitespace-cell money-table-cell'>
                            {displayCellValue ? percentify(row[`you${props.yearRange[1]}`]) : null}
                        </td>
                        <td className='normal-whitespace-cell audit-textarea-cell'>
                            {displayCellValue
                                ? <textarea id={`${row.key}-expectation`} key={`${row.key}-expectation`} value={expectation} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'expectation', e.target.value)}></textarea>
                                : null}
                        </td>
                        <td className='normal-whitespace-cell money-table-cell'>
                            {displayCellValue ? deltaPercentVal : null}
                        </td>
                        <td className='normal-whitespace-cell yes-no-cell'>
                        <textarea key={`${row.key}-expectation-hide`} className='hide-section' value={`${expectationMet}xlsxVal`} readOnly></textarea>
                            {displayCellValue
                                ? (<select key={`${row.key}-expectation`} value={expectationMet} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'expectation_met', e.target.value)}>
                                    <option key={`${row.key}-expectation-blank`} value=''>-</option>
                                    <option key={`${row.key}-expectation-yes`} value='Yes'>Yes</option>
                                    <option key={`${row.key}-expectation-no`} value='No'>No</option>
                                </select>)
                                : null}
                        </td>
                        <td className='normal-whitespace-cell yes-no-cell'>
                        <textarea key={`${row.key}-imm-hide`} className='hide-section' value={`${immVal}xlsxVal`} readOnly></textarea>
                            {displayCellValue
                                ? (<select key={`${row.key}-imm-select`} value={immVal} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'imm', e.target.value)}>
                                    <option key={`${row.key}-imm-select-blank`} value=''>-</option>
                                    <option key={`${row.key}-imm-select-yes`} value='IMM'>IMM</option>
                                </select>)
                                : null}
                        </td>
                        <td>{parseMoney(expectedExpenseVal)}</td>
                        <td>{parseMoney(varianceVsVal)}</td>
                        <td>{parseMoney(deltaDollarVal)}</td>
                        <td className='normal-whitespace-cell'>{deltaFixedPercentVal ? deltaFixedPercentVal.toFixed(0) + '%' : null}</td>
                        <td className='normal-whitespace-cell yes-no-cell'>
                            {displayCellValue ? additionalScopeVal : null}
                        </td>
                        <td className='normal-whitespace-cell audit-textarea-cell'>
                            {displayCellValue
                                ? <textarea id={`${row.key}-comments`} key={`${row.key}-comments`} value={comments} onChange={(e) => handleAuditInfo(row.key, row.sheet, 'comments', e.target.value)}></textarea>
                                : null}
                        </td>
                    </tr>
                )
            }
        })
    }

    const renderYearHeaderColumns = (years) => {
        const currentYear = new Date().getFullYear();
        let youPeersRow = [];
        let formatedYearsRow = [];
        let backgroundStyle = { 'borderBottom': '2px solid black', 'maxHeight': '10px' }
        let commonSizeValCellClass = 'common-size-cell';
        let valueCellClass = '';

        let theads = {
            "position": "sticky",
            "top": "0px",
            "zIndex": "2",
            "backgroundColor": "white"
        }

        let borderBottomStyle = {
            "borderBottom": "2px solid black"
        }
        let previousYearDate;
        // let previousYearDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${years[0]}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${years[0] + 1}` : `12/31/${years[0]}`;
        if (props.compareWith === 'Final PY') {
            previousYearDate = props.calendarYearEnd ? `${props.fiscalYearEnd}/${years[0]}` : props.fiscalYearEnd ? `${props.fiscalYearEnd}/${years[0] + 1}` : `12/31/${years[0]}`;
        } else {
            let year = props.compareWithSplit ? Number(props.compareWithSplit[1]) : null;
            previousYearDate = year ? `12/31/${year}` : `12/31/${years[0]}`
        }
        let currentYearDate = props.companyYTDDate;

        return (
            <thead style={theads}>
                <tr key='audit-expense-first-header-row'>
                    <td></td>
                    <td></td>
                    <td className='normal-whitespace-cell'></td>
                    <td></td>
                    <td className='normal-whitespace-cell'></td>
                    <td>TM</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='normal-whitespace-cell'></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr key='audit-expense-input-header-row'>
                    <td></td>
                    <td>{props.compareWith}</td>
                    <td className='normal-whitespace-cell'></td>
                    <td></td>
                    <td className='normal-whitespace-cell'></td>
                    <td className='audit-change-input-cell'>
                    <textarea key='audit-expense-dollar-hide' className='hide-section' value={`${props.dollarChange.text}xlsxVal`} readOnly></textarea>
                        <input className='audit-dollar-input' type='text' value={props.dollarChange.text} onChange={(e) => props.dollarChangeInput(e.target.value)} />
                    </td>
                    <td className='audit-change-input-cell'>
                    <textarea key='audit-expense-percent-hide' className='hide-section' value={`${props.percentChange.text}xlsxVal`} readOnly></textarea>
                        <input className='audit-percent-input' type='text' value={props.percentChange.text} onChange={(e) => props.percentChangeInput(e.target.value)} />
                    </td>
                    <td></td>
                    <td></td>
                    <td colSpan="2">Variable Expenses</td>
                    <td colSpan="2">Fixed Expenses</td>
                    <td></td>
                    <td className='normal-whitespace-cell'></td>
                </tr>
                <tr key='audit-expense-fields-header-row'>
                    <td style={backgroundStyle}></td>
                    <td className={commonSizeValCellClass} style={backgroundStyle}>{previousYearDate}</td>
                    <td className='normal-whitespace-cell money-table-cell' style={backgroundStyle}><span className='audit-span'>Total as % of</span> Revenues</td>
                    <td className={commonSizeValCellClass} style={backgroundStyle}>{currentYearDate}</td>
                    <td className='normal-whitespace-cell money-table-cell' style={backgroundStyle}><span className='audit-span'>Total as % of</span> Revenues</td>
                    <td className='normal-whitespace-cell' style={backgroundStyle}>Expectation</td>
                    <td className='normal-whitespace-cell money-table-cell' style={backgroundStyle}><span className='audit-span'>Change in % of Total</span> as % of Revenues YOY</td>
                    <td style={backgroundStyle}>Expectation Met?</td>
                    <td style={backgroundStyle}></td>
                    <td className='normal-whitespace-cell' style={backgroundStyle}><span className='audit-span'>Expected Expense</span> (based on Total Revenues)</td>
                    <td className='normal-whitespace-cell' style={backgroundStyle}>Variance <span className='audit-span'>Actual vs Estimated</span></td>
                    <td style={backgroundStyle}>$ Change</td>
                    <td style={backgroundStyle}>% Change</td>
                    <td className='normal-whitespace-cell' style={backgroundStyle}><span className='audit-span'>Additional Scoping</span> Threshold Met?</td>
                    <td className='normal-whitespace-cell' style={backgroundStyle}>Comments</td>
                </tr>
            </thead>
        )
    }

    function buildTable() {
        const auditDetailClass = {
            'fontWeight': 600,
            'textAlign': 'right'
        }
        return (
            <div className='money-report-card money-report-pdf'>
                <h1 className='report-table-title hide-not-on-print'>{props.title} - {props.clientName}</h1>
                <button className="btn btn-primary audit-details-btn" type="button" onClick={() => setShowDetails(!showDetails)}>
                    {showDetails ? 'Hide Details' : 'Show Details'}
                </button>
                <div className={showDetails ? 'show-section' : 'hide-section'}>
                    <table id='audit-expense-details-table' className='audit-details-table'>
                        <tbody>
                        <tr key='expense-client-name' className='hide-section'>
                                <td>Client:</td>
                                <td>{props.clientName}</td>
                            </tr>
                            <tr key='expense-scenario-name' className='hide-section'>
                                <td>Scenario:</td>
                                <td>{props.auditScenario['name']}</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Purpose:</td>
                                <td>To compare the Current Year (CY) expenses to Previous Year (PY) and identify any significant variances.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Procedures:</td>
                                <td>Obtain explanations for changes above TM scope. Expense category expectations are split between either fixed or variable expenses.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Note:</td>
                                <td>1) Variable expenses are expenses that change depending on how much you use a product or service.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>2) Fixed costs stay the same over an extended period of time.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Expectation:</td>
                                <td>Any change in % of total as a % of revenues YOY that is less than 10% is deemed immaterial.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}><span className='audit-span'>Additional Scoping Threshold:</span></td>
                                <td>For expenses considered variable, the expectation of the expense incurred during the year in relation to changes in revenue. Any variance from the calculated expected expense greater than TM $Change will be investigated.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>For expenses considered fixed, any balance changes greater than TM of $Change and 10% wil be investigated.</td>
                            </tr>
                            <tr>
                                <td style={auditDetailClass}>Conclusion:</td>
                                <td>See below for results. Based on the results of the analytics, ...</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <AuditScenarios
                    key='audit-expense-analytics-scenario'
                    allAuditScenarios={props.allAuditScenarios}
                    auditScenario={auditExpenseScenario}
                    defaultScenario={props.defaultScenario}
                    handleNewScenario={props.handleNewScenario}
                    handleAuditScenarioSelect={props.handleAuditScenarioSelect}
                    handleUpdateScenario={props.handleUpdateScenario}
                    reportType={'expense-analytics'}
                />
                <div className='audit-report-container'>
                    <table id='audit-expense-main-table' className='report-table' >
                        {renderYearHeaderColumns(props.yearRange)}
                        <tbody>
                            {buildRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (buildTable())
}

export default AuditExpenseAnalytics;
