import React, { useState, useEffect, useRef } from 'react';
import LoaderGraphic from '../../utils/LoaderGraphic';
import ProgressBarModal from '../../progress_bar/ProgressBarModal';
import EnterDataBtn from '../utils/EnterDataBtn';
import ExportDropdown from '../utils/ExportDropdown';
import EditDashboard from '../utils/EditDashboard';
import { mergeItems } from '../charts';
import PRDropdownFilter from './PRDropdownFilter';
import PRAnalysis from './PRAnalysis';
import TrendChart from './TrendChart';
import MoneyReportSheets from '../report_components/MoneyReportSheets';
import DashboardViewControls from '../utils/DashboardViewControls';
import DashboardViewModal from '../utils/DashboardViewModal';
import ParentDashboard from '../ParentDashboard';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useTour } from '@reactour/tour';
import CoverReportPage from '../utils/CoverReportPage';
import Modal from 'react-modal';

const PracticeReport = (props) => {
  const componentRefs = useRef();
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();
  const [activeTab, setActiveTab] = useState('naics-analysis');
  const [isLoading, setIsLoading] = useState(false)
  const [practiceCalcsProgressBar, setPracticeCalcsProgressBar] = useState(false);
  const [tableState, setTableState] = useState({
    submitted: {
      sort: 'company_name',
      isDesc: false,
      pageCounter: 1
    },
    unsubmitted: {
      sort: 'company_name',
      isDesc: false,
      pageCounter: 1
    },

  })
  const [data, setData] = useState({
    naics_averages: '',
    average_of_all_submitted: '',
    dashboard_calculations: '',
    submitted_children: '',
    report_metrics: '',
    child_count: '',
    unsubmitted_child_count: 0,
    drillup: '',
    drillup_link: '',
    drillup_text: '',
    filterOptions: '',
    firm_name: '',
    firm_logo: '',
    focusedClientData: [],
    userFocusedClients: [],
    insufficientData: null,
    page: '',
    pieTopLevelArr: '',
    rankingData: '',
    tree: '',
    naics_trends_data: [],
    unsubmitted_children: '',
    user: 0,
    year: '',
    yearRange: ''
  })
  const [selectedFilters, setSelectedFilters] = useState({
    naics_string: 'All',
    revenue_band: 'All',
    region: 'All',
    partner: 'All',
    office: 'All',
    status: 'All',
    year: new Date().getFullYear() - 1,
  })
  const [dashboard, setDashboard] = useState({ id: null, selectedItems: [], layout: [], noChangesMade: true });
  const [dashboardViewModalState, setDashboardViewModalState] = useState({
    isOpen: false,
    action: 'close',
    edit_view: {},
    current_view: {},
    user_views: [],
    firms_views: []
  });
  const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)
  const [activePopover, setActivePopover] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    let allUrl = `/api/v1/practice_reportable?page=${tableState.submitted.pageCounter}&rows=${'25'}&sort=${tableState.submitted.sort}&direction=${tableState.submitted.isDesc ? 'desc' : 'asc'}&naics_string=${selectedFilters.naics_string === 'All' ? '' : selectedFilters.naics_string}&revenue_band=${selectedFilters.revenue_band === 'All' ? '' : selectedFilters.revenue_band}&region=${selectedFilters.region === 'All' ? '' : selectedFilters.region}&partner=${selectedFilters.partner === 'All' ? '' : selectedFilters.partner}&office=${selectedFilters.office === 'All' ? '' : selectedFilters.office}&status=${selectedFilters.status === 'All' ? '' : selectedFilters.status}&year=${selectedFilters.year}&commit=filter`
    const makeApiCall = async () => {
      const response = await fetch(allUrl);
      const json = await response.json();
      setData(prevState => {
        return {
          ...prevState,
          naics_averages: json.naics_averages,
          average_of_all_submitted: json.average_of_all_submitted_children,
          dashboard_calculations: json.dashboard_calculations,
          submitted_children: json.submitted_children,
          report_metrics: json.report_metrics,
          child_count: json.child_count,
          unsubmitted_child_count: json.unsubmitted_child_count,
          drillup: json.drillup,
          drillup_link: json.drillup_link,
          drillup_text: json.drillup_text,
          filterOptions: json.filter_options_obj,
          firm_name: json.firm_name,
          firm_logo: json.firm_logo,
          focusedClientData: json.user_focused_clients_info,
          userFocusedClients: json.user_focused_clients,
          insufficientData: json.insufficient_data,
          page: json.page,
          pieTopLevelArr: json.pie_top_level_arr,
          rankingData: json.snapshot_info,
          tree: json.tree,
          naics_trends_data: json.naics_trends_data,
          unsubmitted_children: json.unsubmitted_children,
          year: json.year,
          yearRange: json.yearRange,
          selected_filters: json.selected_filters,
          user: json.user
        }
      })
      setSelectedFilters(prevState => {
        return {
          ...prevState,
          naics_string: json.selected_filters.naics_string ||= 'All',
          revenue_band: json.selected_filters.revenue_band ||= 'All',
          region: json.selected_filters.region ||= 'All',
          partner: json.selected_filters.partner ||= 'All',
          office: json.selected_filters.office ||= 'All',
          status: json.selected_filters.status ||= 'All',
          year: json.year
        }
      })

      const dashBoard = await fetch(`/dashboard_view/info/?report_type=practice`, {
        headers: {
          'X-CSRF-Token': window.token,
        }
      })
      const dashBoardRes = await dashBoard.json();
      let currentDash = dashBoardRes['current_view']
      let { layout, selectedItems } = mergeItems('practice', currentDash.selected_items, currentDash.dashboard_layout)
      setDashboard(() => {
        return {
          id: currentDash.id,
          selectedItems: selectedItems,
          layout: layout,
          noChangesMade: true
        }
      })
      setDashboardViewModalState((prevState) => {
        return {
          ...prevState,
          current_view: dashBoardRes.current_view,
          user_views: dashBoardRes.user_views,
          firms_views: dashBoardRes.firms_views
        }
      })
      setIsLoading(false)
      props.tracker_id && !props.tour_taken ? setIsOpen(true) : null
    };
    makeApiCall();
  }, [])

  const handleDashboardSelections = (item, parentItemInfo = null) => {
    let itemsCheckedTrue = [];
    let newLayout = [];
    setDashboard((prevState) => {
      let oldDashInfo = _.cloneDeep(prevState);
      if (parentItemInfo) {
        let parentItem = oldDashInfo.selectedItems.find((c) => c.key === parentItemInfo.key);
        if (parentItem.children) {
          let childItem = parentItem.children.find((child) => child.key === item.key);
          childItem.checked = !childItem.checked
          let numberOfChildrenChecked = parentItem.children.filter(child => child.checked)
          parentItem.checked = numberOfChildrenChecked.length > 0
        }
      } else {
        let selectedChart = oldDashInfo.selectedItems.find((c) => c.key === item.key);
        let checkStatus = !selectedChart.checked
        if (selectedChart.children) {
          selectedChart.children = selectedChart.children.map((c) => {
            c.checked = checkStatus
            return c;
          })
        }
        selectedChart.checked = checkStatus;
      }

      oldDashInfo.selectedItems.forEach((c) => {
        if (c.checked) {
          if (c.children) {
            let clonedItem = _.cloneDeep(c);
            clonedItem.children = clonedItem.children.filter(child => child.checked)
            itemsCheckedTrue.push(clonedItem)
          } else {
            itemsCheckedTrue.push(c)
          }
        }
      })

      let width = 0
      itemsCheckedTrue.forEach((itemChecked, i) => {
        // Checked list will always have children array
        let oldItem = oldDashInfo.layout.find(d => d['i'] === itemChecked.key);
        if (oldItem) {
          if (itemChecked.children && itemChecked.children.length > 1) {
            let parentLayout = []
            // we found a parent and it has more than one child checked
            // Does the old layout have each child?
            // If it has a checked child return it, else create a new layout for the child
            itemChecked.children.forEach(child => {
              let newChildItem = oldItem.layout.find(o => o['i'] === child.key)
              // Can't find the child make a new one
              if (!newChildItem) {
                if (child.key.includes('ranking_table')) {
                  parentLayout.push({
                    i: child.key,
                    isBounded: false,
                    isDraggable: true,
                    isResizable: true,
                    x: ((i * 3) % (6)),
                    y: Infinity,
                    w: 2,
                    h: 2,
                    minW: 2,
                    minH: 2,
                    maxW: 4,
                    maxH: 4,
                    resizeHandles: ['se']
                  })
                } else {
                  parentLayout.push({
                    i: child.key,
                    isBounded: false,
                    isDraggable: true,
                    isResizable: true,
                    x: ((i * 3) % (6)),
                    y: Infinity,
                    w: 3,
                    h: 2,
                    minW: 3,
                    minH: 2,
                    maxW: 6,
                    maxH: 12,
                    resizeHandles: ['se']
                  })
                }
              } else {
                return parentLayout.push(newChildItem)
              }
            })
            oldItem.layout = parentLayout;
            newLayout.push(oldItem)
          } else if (itemChecked.children && itemChecked.children.length === 1) {
            let childKey = itemChecked.children[0].key
            let oldChildItem = oldDashInfo.layout.find(d => d['i'] === childKey);
            if (oldChildItem) {
              newLayout.push(oldChildItem)
            } else {
              if (childKey.includes('pie')) {
                newLayout.push({
                  i: childKey,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: ((i * 2) % (6)),
                  y: ((i * 2) % (3)),
                  w: 2,
                  h: 2,
                  minW: 1,
                  minH: 1,
                  maxW: 6,
                  maxH: 12,
                  resizeHandles: ['se']
                })
              } else if (childKey.includes('ranking_table')) {
                newLayout.push({
                  i: childKey,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: ((i * 3) % (6)),
                  y: Infinity,
                  w: 2,
                  h: 2,
                  minW: 2,
                  minH: 2,
                  maxW: 4,
                  maxH: 4,
                  resizeHandles: ['se']
                })
              } else {
                newLayout.push({
                  i: childKey,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: ((i * 3) % (6)),
                  y: Infinity,
                  w: 3,
                  h: 2,
                  minW: 3,
                  minH: 2,
                  maxW: 6,
                  maxH: 12,
                  resizeHandles: ['se']
                })
              }
            }
          } else {
            newLayout.push(oldItem)
          }
        } else {
          // can't find item then make a new one
          if (itemChecked.children && itemChecked.children.length > 1) {
            let parentLayout = []
            let parentHeight = 0;
            let parentWidth = 0;
            let parentDraggable = true;
            itemChecked.children.forEach((child, j) => {
              if (child.key.includes('ranking_table')) {
                parentWidth += 2
                if (parentWidth % 6 === 0) { parentHeight += 2 }
                parentLayout.push({
                  i: child.key,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: ((j * 4) % (6)),
                  y: Infinity,
                  w: 2,
                  h: 2,
                  minW: 2,
                  minH: 2,
                  maxW: 4,
                  maxH: 4,
                  resizeHandles: ['se']
                })
              } else {
                parentWidth += 4
                if (parentWidth % 6 === 0) { parentHeight += 2 }
                parentDraggable = false
                parentLayout.push({
                  i: child.key,
                  isBounded: false,
                  isDraggable: false,
                  isResizable: true,
                  x: ((j * 4) % (6)),
                  y: Infinity,
                  w: 4,
                  h: 2,
                  minW: 3,
                  minH: 2,
                  maxW: 6,
                  maxH: 12,
                  resizeHandles: ['se']
                })
              }
            })
            newLayout.push({
              i: itemChecked.key,
              layout: parentLayout,
              isBounded: false,
              isDraggable: parentDraggable,
              isResizable: true,
              x: ((i * 3) % (6)),
              y: Infinity,
              w: parentWidth > 6 ? 6 : parentWidth,
              h: parentHeight,
              minW: 2,
              minH: 2,
              maxW: 6,
              maxH: 18,
              resizeHandles: ['se']
            })
          } else {
            let key = itemChecked.children && itemChecked.children.length === 1 ? itemChecked.children[0].key : itemChecked.key
            if (key.includes('ranking_table')) {
              let finalWidth = 0
              if (0 < width && width < 6) {
                finalWidth = width
                width += 2
              } else {
                width = 2
                finalWidth = 0
              }
              newLayout.push({
                i: key,
                isBounded: false,
                isDraggable: true,
                isResizable: true,
                x: finalWidth,
                y: Infinity,
                w: 2,
                h: 2,
                minW: 2,
                minH: 2,
                maxW: 4,
                maxH: 4,
                resizeHandles: ['se']
              })
            } else if (key.includes('bar_chart')) {
              let finalWidth = 0
              if (0 < width && width < 6) {
                finalWidth = width
                width += 4
              } else {
                width = 4
                finalWidth = 0
              }
              newLayout.push({
                i: key,
                isBounded: false,
                isDraggable: false,
                isResizable: true,
                x: finalWidth,
                y: Infinity,
                w: 4,
                h: 2,
                minW: 2,
                minH: 2,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            } else if (key === 'practice_focused_clients') {
              newLayout.push({
                i: key,
                isBounded: false,
                isDraggable: true,
                isResizable: true,
                x: 0,
                y: Infinity,
                w: 3,
                h: 2,
                minW: 2,
                minH: 2,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            } else {
              let finalWidth = 0
              if (0 < width && width < 6) {
                finalWidth = width
                width += 6
              } else {
                width = 6
                finalWidth = 0
              }
              newLayout.push({
                i: key,
                isBounded: false,
                isDraggable: true,
                isResizable: true,
                x: finalWidth,
                y: Infinity,
                w: 6,
                h: 3,
                minW: 3,
                minH: 2,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            }
          }
        }
      })

      return { ...prevState, selectedItems: oldDashInfo.selectedItems, layout: newLayout, noChangesMade: false }
    })
  }

  const handleSelectAll = (select) => {
    setDashboard((prevState) => {
      let newLayout = []
      let oldDashInfo = _.cloneDeep(prevState);
      let allItems = oldDashInfo.selectedItems.map((item) => {
        if (item.children) {
          item.children = item.children.map((c) => {
            c.checked = select
            return c;
          })
        }
        item.checked = select;
        return item;
      })
      let width = 0
      allItems.forEach((itemChecked, i) => {
        // Checked list will always have children array
        if (itemChecked.checked) {
          let oldItem = oldDashInfo.layout.find(d => d['i'] === itemChecked.key);
          if (oldItem) {
            if (itemChecked.children && itemChecked.children.length > 1) {
              let parentLayout = []
              // we found a parent and it has more than one child checked
              // Does the old layout have each child?
              // If it has a checked child return it, else create a new layout for the child
              itemChecked.children.forEach(child => {
                let newChildItem = oldItem.layout.find(o => o['i'] === child.key)
                // Can't find the child make a new one
                if (!newChildItem) {
                  if (child.key.includes('ranking_table')) {
                    parentLayout.push({
                      i: child.key,
                      isBounded: false,
                      isDraggable: true,
                      isResizable: true,
                      x: ((i * 3) % (6)),
                      y: Infinity,
                      w: 2,
                      h: 2,
                      minW: 2,
                      minH: 2,
                      maxW: 4,
                      maxH: 4,
                      resizeHandles: ['se']
                    })
                  } else {
                    parentLayout.push({
                      i: child.key,
                      isBounded: false,
                      isDraggable: true,
                      isResizable: true,
                      x: ((i * 3) % (6)),
                      y: Infinity,
                      w: 3,
                      h: 2,
                      minW: 3,
                      minH: 2,
                      maxW: 6,
                      maxH: 12,
                      resizeHandles: ['se']
                    })
                  }
                } else {
                  return parentLayout.push(newChildItem)
                }
              })
              oldItem.layout = parentLayout;
              newLayout.push(oldItem)
            } else if (itemChecked.children && itemChecked.children.length === 1) {
              let childKey = itemChecked.children[0].key
              let oldChildItem = oldDashInfo.layout.find(d => d['i'] === childKey);
              if (oldChildItem) {
                newLayout.push(oldChildItem)
              } else {
                if (childKey.includes('pie')) {
                  newLayout.push({
                    i: childKey,
                    isBounded: false,
                    isDraggable: true,
                    isResizable: true,
                    x: ((i * 2) % (6)),
                    y: ((i * 2) % (3)),
                    w: 2,
                    h: 2,
                    minW: 1,
                    minH: 1,
                    maxW: 6,
                    maxH: 12,
                    resizeHandles: ['se']
                  })
                } else if (childKey.includes('ranking_table')) {
                  newLayout.push({
                    i: childKey,
                    isBounded: false,
                    isDraggable: true,
                    isResizable: true,
                    x: ((i * 3) % (6)),
                    y: Infinity,
                    w: 2,
                    h: 2,
                    minW: 2,
                    minH: 2,
                    maxW: 4,
                    maxH: 4,
                    resizeHandles: ['se']
                  })
                } else {
                  newLayout.push({
                    i: childKey,
                    isBounded: false,
                    isDraggable: true,
                    isResizable: true,
                    x: ((i * 3) % (6)),
                    y: Infinity,
                    w: 3,
                    h: 2,
                    minW: 3,
                    minH: 2,
                    maxW: 6,
                    maxH: 12,
                    resizeHandles: ['se']
                  })
                }
              }
            } else {
              newLayout.push(oldItem)
            }
          } else {
            // can't find item then make a new one
            if (itemChecked.children && itemChecked.children.length > 1) {
              let parentLayout = []
              let parentHeight = 0;
              let parentWidth = 0;
              let parentDraggable = true;
              itemChecked.children.forEach((child, j) => {
                if (child.key.includes('ranking_table')) {
                  parentWidth += 2
                  if (parentWidth % 6 === 0) { parentHeight += 2 }
                  parentLayout.push({
                    i: child.key,
                    isBounded: false,
                    isDraggable: true,
                    isResizable: true,
                    x: ((j * 4) % (6)),
                    y: Infinity,
                    w: 2,
                    h: 2,
                    minW: 2,
                    minH: 2,
                    maxW: 4,
                    maxH: 4,
                    resizeHandles: ['se']
                  })
                } else {
                  parentWidth += 4
                  if (parentWidth % 6 === 0) { parentHeight += 2 }
                  parentDraggable = false
                  parentLayout.push({
                    i: child.key,
                    isBounded: false,
                    isDraggable: false,
                    isResizable: true,
                    x: ((j * 4) % (6)),
                    y: Infinity,
                    w: 4,
                    h: 2,
                    minW: 3,
                    minH: 2,
                    maxW: 6,
                    maxH: 12,
                    resizeHandles: ['se']
                  })
                }
              })
              newLayout.push({
                i: itemChecked.key,
                layout: parentLayout,
                isBounded: false,
                isDraggable: parentDraggable,
                isResizable: true,
                x: ((i * 3) % (6)),
                y: Infinity,
                w: parentWidth > 6 ? 6 : parentWidth,
                h: parentHeight,
                minW: 2,
                minH: 2,
                maxW: 6,
                maxH: 18,
                resizeHandles: ['se']
              })
            } else {
              let key = itemChecked.children && itemChecked.children.length === 1 ? itemChecked.children[0].key : itemChecked.key
              if (key.includes('ranking_table')) {
                let finalWidth = 0
                if (0 < width && width < 6) {
                  finalWidth = width
                  width += 2
                } else {
                  width = 2
                  finalWidth = 0
                }
                newLayout.push({
                  i: key,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: finalWidth,
                  y: Infinity,
                  w: 2,
                  h: 2,
                  minW: 2,
                  minH: 2,
                  maxW: 4,
                  maxH: 4,
                  resizeHandles: ['se']
                })
              } else if (key.includes('bar_chart')) {
                let finalWidth = 0
                if (0 < width && width < 6) {
                  finalWidth = width
                  width += 4
                } else {
                  width = 4
                  finalWidth = 0
                }
                newLayout.push({
                  i: key,
                  isBounded: false,
                  isDraggable: false,
                  isResizable: true,
                  x: finalWidth,
                  y: Infinity,
                  w: 4,
                  h: 2,
                  minW: 2,
                  minH: 2,
                  maxW: 6,
                  maxH: 12,
                  resizeHandles: ['se']
                })
              } else if (key === 'practice_focused_clients') {
                newLayout.push({
                  i: key,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: 0,
                  y: Infinity,
                  w: 3,
                  h: 2,
                  minW: 2,
                  minH: 2,
                  maxW: 6,
                  maxH: 12,
                  resizeHandles: ['se']
                })
              } else {
                let finalWidth = 0
                if (0 < width && width < 6) {
                  finalWidth = width
                  width += 6
                } else {
                  width = 6
                  finalWidth = 0
                }
                newLayout.push({
                  i: key,
                  isBounded: false,
                  isDraggable: true,
                  isResizable: true,
                  x: finalWidth,
                  y: Infinity,
                  w: 6,
                  h: 3,
                  minW: 3,
                  minH: 2,
                  maxW: 6,
                  maxH: 12,
                  resizeHandles: ['se']
                })
              }
            }
          }
        }
      })

      return { ...prevState, selectedItems: allItems, layout: newLayout, noChangesMade: false }
    })
  }

  const createDashBoardView = async (newDashView) => {
    let newView = {
      name: newDashView.name,
      private: newDashView.viewVisibilty === 'only_me',
      report_type: 'practice',
      permission_details: newDashView.viewPermission,
      view_apply: newDashView.viewApply,
      selected_items: dashboard.selectedItems,
      dashboard_layout: dashboard.layout
    }

    try {
      const res = await fetch(`/dashboard_view`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newView),
      })

      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        setDashboard((prevState) => {
          return {
            ...prevState,
            id: dashViewRes.current_view.id,
            noChangesMade: true
          }
        })
        setDashboardViewModalState(prevState => {
          return {
            ...prevState,
            current_view: dashViewRes.current_view,
            user_views: dashViewRes.user_views,
            firms_views: dashViewRes.firms_views
          }
        })
        toast.success('New view created!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      let errorMessage = dashViewRes.name ? `Name ${dashViewRes.name}` : 'New view was not created properly'
      toast.error(`Sorry something went wrong. ${errorMessage}.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const getDashBoardView = async (dashViewId) => {
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })

      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        let currentDash = dashViewRes['current_view']
        setDashboard((prevState) => {
          return {
            ...prevState,
            id: currentDash.id,
            selectedItems: currentDash.selected_items,
            layout: currentDash.dashboard_layout,
            noChangesMade: true
          }
        })
        setDashboardViewModalState(prevState => {
          return {
            ...prevState,
            current_view: currentDash
          }
        })
      }
    } catch (error) {
      console.log(error);
      toast.error(`Sorry something went wrong.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const updateDashBoardView = async (e = false, dashId = null, dashViewInfo = null) => {
    if (e) { e.preventDefault() }
    let dashViewId = dashId
    const updatedView = {
      report_type: 'practice',
      selected_items: dashboard.selectedItems,
      dashboard_layout: dashboard.layout
    }
    if (!dashViewId) dashViewId = dashboard.id
    if (dashViewInfo) {
      updatedView['name'] = dashViewInfo.name
      updatedView['private'] = dashViewInfo.viewVisibilty === 'only_me'
      updatedView['permission_details'] = dashViewInfo.viewPermission
    }

    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedView),
      })

      const dashViewRes = await res.json()
      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        setDashboard((prevState) => {
          return {
            ...prevState,
            noChangesMade: true
          }
        })
        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: dashViewRes.current_view,
            user_views: dashViewRes.user_views,
            firms_views: dashViewRes.firms_views
          }
        })
        toast.success('Changes saved!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Changes to view could not be saved.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const deleteDashBoardView = async (e, dashViewId) => {
    e.preventDefault()
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        let defaultView = dashboardViewModalState.firms_views.find(v => v.name === 'Default')
        let currentViewDeleted = dashViewId === dashboardViewModalState.current_view.id
        setDashboardViewModalState(prevState => {
          let oldUserViews = prevState.user_views
          let newUserViews = oldUserViews.filter(v => v.id !== dashViewId)
          let oldFirmsViews = prevState.firms_views
          let newFirmsViews = oldFirmsViews.filter(v => v.id !== dashViewId)

          if (currentViewDeleted) {
            return {
              ...prevState,
              current_view: defaultView,
              user_views: newUserViews,
              firms_views: newFirmsViews
            }
          } else {
            return {
              ...prevState,
              user_views: newUserViews,
              firms_views: newFirmsViews
            }
          }
        })

        setDashboard((prevState) => {
          if (currentViewDeleted) {
            return {
              id: defaultView.id,
              selectedItems: defaultView.selected_items,
              layout: defaultView.dashboard_layout,
              noChangesMade: true
            }
          } else {
            return prevState
          }
        })

        toast.success('View was deleted!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Try again later.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleDashboardViewModal = (action, info = null) => {
    setDashboardViewModalState(prevState => {
      if (action === 'close') {
        return { ...prevState, isOpen: false, action: 'close' }
      } else if (action === 'edit_view') {
        let currentView = prevState.current_view
        let noViewEdit = currentView.permission_details !== 'view_only' || prevState.user_views.find(v => v.id === currentView.id)
        let currentInfo = !info ? currentView : info
        if (!noViewEdit) {
          toast.error(`Sorry You Are NOT Allowed to Edit this Dashboard View. You need to be the owner of this dashboard view in order to edit.`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return prevState
        } else {
          return { ...prevState, isOpen: true, action: action, edit_view: currentInfo }
        }
      } else {
        return { ...prevState, isOpen: true, action: action, edit_view: {} }
      }
    })
  }

  const handleFocusedClients = async (clientType, clientInfo) => {
    let clientFocused = !clientInfo.focused_client
    let allFocusedClients = data.userFocusedClients;
    // info will be changed with filters
    if (clientFocused) {
      let clientExists = allFocusedClients.find(c => c === clientInfo.company_id)
      if (!clientExists) { allFocusedClients.push(clientInfo.company_id) }
    } else {
      allFocusedClients = allFocusedClients.filter(c => c !== clientInfo.company_id)
    }
    let updatedFocus = { focused_clients: allFocusedClients }

    try {
      const res = await fetch(`/api/v1/users/${data.user}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFocus),
      })

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        const json = await res.json();
        setData((prevState) => {
          let focusedClients = clientType === 'clients-submitted' ? _.cloneDeep(prevState.submitted_children) : _.cloneDeep(prevState.unsubmitted_children)
          focusedClients.find(c => c.company_id === clientInfo.company_id).focused_client = !clientInfo.focused_client
          if (clientType === 'clients-submitted') {
            return ({
              ...prevState,
              focusedClientData: json.focused_clients,
              userFocusedClients: allFocusedClients,
              submitted_children: focusedClients
            })
          }

          if (clientType === 'clients-unsubmitted') {
            return ({
              ...prevState,
              focusedClientData: json.focused_clients,
              userFocusedClients: allFocusedClients,
              unsubmitted_children: focusedClients
            })
          }
        })

      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Changes were not saved properly.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleOnLayoutChange = (dashBoardType, newLayout, parentLayout = null) => {
    let currentView = dashboardViewModalState.current_view
    let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewModalState.user_views.find(v => v.id === currentView.id)

    if (dashBoardType === 'parentMyDashBoard') {
      setDashboard((prevState) => {
        let oldLayout = prevState.layout;
        let modifiedLayout = oldLayout.map((ol) => {
          let findNewItem = newLayout.find(item => item.i === ol.i)
          if (findNewItem) {
            return { ...ol, ...findNewItem }
          } else {
            return ol;
          }
        })
        return { ...prevState, layout: modifiedLayout, noChangesMade: noChangesVal }
      })
    }

    if (dashBoardType === 'childMyDashBoard') {
      setDashboard((prevState) => {
        let modifiedLayout = _.cloneDeep(prevState.layout);
        let findParentLayout = modifiedLayout.find(ol => ol.i === parentLayout.i)
        let findParentLayoutIndex = modifiedLayout.findIndex(ol => ol.i === parentLayout.i)
        if (findParentLayout) {
          findParentLayout.layout = findParentLayout.layout.map(pl => {
            let findNewItem = newLayout.find(item => item.i === pl.i)
            if (findNewItem) {
              return { ...pl, ...findNewItem }
            } else {
              return pl;
            }
          })

          modifiedLayout[findParentLayoutIndex] = findParentLayout
        }
        return { ...prevState, layout: modifiedLayout, noChangesMade: noChangesVal }
      })
    }

  }

  const handleToggleDraggable = (key, parent = '') => {
    let currentView = dashboardViewModalState.current_view
    let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewModalState.user_views.find(v => v.id === currentView.id)
    setDashboard((prevState) => {
      let newLayout = _.cloneDeep(prevState.layout);
      let item = newLayout.findIndex((l) => l.i === key);
      if (item > -1) {
        newLayout[item].isDraggable = !newLayout[item].isDraggable
      } else {
        let parentItem = newLayout.find((l) => l.i === parent.i);
        if (parentItem) {
          let childItem = parentItem.layout.find(c => c.i === key)
          if (childItem) { childItem.isDraggable = !childItem.isDraggable }
          parentItem.isDraggable = childItem.isDraggable
        }
      }
      return { ...prevState, layout: newLayout, noChangesMade: noChangesVal }
    })
  }

  const handleSubmit = async (e = false, isNaicsOnly = false, naicsString = '') => {
    e ? e.preventDefault() : null
    let res;
    setIsLoading(true)
    try {
      if (isNaicsOnly) {
        res = await fetch(`/api/v1/practice_reportable?page=${tableState.submitted.pageCounter}&rows=${'25'}&sort=${tableState.submitted.sort}&direction=${tableState.submitted.isDesc ? 'desc' : 'asc'}&naics_string=${naicsString}&revenue_band=${selectedFilters.revenue_band}&region=${selectedFilters.region}&partner=${selectedFilters.partner}&office=${selectedFilters.office}&status=${selectedFilters.status === 'All' ? '' : selectedFilters.status}&year=${selectedFilters.year}&commit=filter`, {
          headers: {
            'X-CSRF-Token': window.token,
          }
        })
        const json = await res.json();
        if (!json.insufficient_data) {
          setData(prevState => {
            return {
              ...prevState,
              naics_averages: json.naics_averages,
              average_of_all_submitted: json.average_of_all_submitted_children,
              dashboard_calculations: json.dashboard_calculations,
              submitted_children: json.submitted_children,
              report_metrics: json.report_metrics,
              child_count: json.child_count,
              unsubmitted_child_count: json.unsubmitted_child_count,
              drillup: json.drillup,
              drillup_link: json.drillup_link,
              drillup_text: json.drillup_text,
              filterOptions: json.filter_options_obj,
              insufficientData: json.insufficient_data,
              page: json.page,
              pieTopLevelArr: json.pie_top_level_arr,
              rankingData: json.snapshot_info,
              tree: json.tree,
              naics_trends_data: json.naics_trends_data,
              unsubmitted_children: json.unsubmitted_children,
              year: json.year,
              yearRange: json.yearRange,
              selected_filters: json.selected_filters
            }
          })
        } else {
          setData(prevState => {
            return {
              ...prevState,
              insufficientData: json.insufficient_data
            }
          })
        }
        setIsLoading(false)
      } else {
        res = await fetch(`/api/v1/practice_reportable?page=${tableState.submitted.pageCounter}&rows=${'25'}&sort=${tableState.submitted.sort}&direction=${tableState.submitted.isDesc ? 'desc' : 'asc'}&naics_string=${selectedFilters.naics_string}&revenue_band=${selectedFilters.revenue_band}&region=${selectedFilters.region}&partner=${selectedFilters.partner}&office=${selectedFilters.office}&status=${selectedFilters.status === 'All' ? '' : selectedFilters.status}&year=${selectedFilters.year}&commit=filter`, {
          headers: {
            'X-CSRF-Token': window.token,
          }
        })
        const json = await res.json();

        if (!json.insufficient_data) {
          setData(prevState => {
            return {
              ...prevState,
              naics_averages: json.naics_averages,
              average_of_all_submitted: json.average_of_all_submitted_children,
              dashboard_calculations: json.dashboard_calculations,
              submitted_children: json.submitted_children,
              report_metrics: json.report_metrics,
              child_count: json.child_count,
              unsubmitted_child_count: json.unsubmitted_child_count,
              drillup: json.drillup,
              drillup_link: json.drillup_link,
              drillup_text: json.drillup_text,
              filterOptions: json.filter_options_obj,
              focusedClientData: json.user_focused_clients_info,
              userFocusedClients: json.user_focused_clients,
              insufficientData: json.insufficient_data,
              page: json.page,
              pieTopLevelArr: json.pie_top_level_arr,
              rankingData: json.snapshot_info,
              tree: json.tree,
              naics_trends_data: json.naics_trends_data,
              unsubmitted_children: json.unsubmitted_children,
              year: json.year,
              yearRange: json.yearRange,
              selected_filters: json.selected_filters
            }
          })
        } else {
          setData(prevState => {
            return {
              ...prevState,
              insufficientData: json.insufficient_data
            }
          })
        }
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      toast.error('Sorry something went wrong. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setIsLoading(false)
    }
  };

  const handleTableChanges = async (action, clientType, data) => {
    setIsLoading(true)
    let sortTableUrl = '';
    if (action === 'changePage') {
      setTableState((prevState) => {
        if (clientType === 'submitted') {
          return {
            ...prevState,
            submitted: { ...prevState.submitted, pageCounter: data }
          }
        } else {
          return {
            ...prevState,
            unsubmitted: { ...prevState.unsubmitted, pageCounter: data }
          }
        }
      })
      sortTableUrl = `/api/v1/practice_reportable?client_type=${clientType}&page=${data}&rows=${'25'}&sort=${tableState[clientType].sort}&direction=${tableState[clientType].isDesc ? 'desc' : 'asc'}&naics_string=${selectedFilters.naics_string === 'All' ? '' : selectedFilters.naics_string}&revenue_band=${selectedFilters.revenue_band === 'All' ? '' : selectedFilters.revenue_band}&region=${selectedFilters.region === 'All' ? '' : selectedFilters.region}&partner=${selectedFilters.partner === 'All' ? '' : selectedFilters.partner}&office=${selectedFilters.office === 'All' ? '' : selectedFilters.office}&status=${selectedFilters.status === 'All' ? '' : selectedFilters.status}&year=${selectedFilters.year}&commit=filter`
    }

    if (action === 'sort') {
      setTableState((prevState) => {
        if (clientType === 'submitted') {
          return {
            ...prevState,
            submitted: {
              sort: data,
              isDesc: !prevState.submitted.isDesc,
              pageCounter: prevState.submitted.pageCounter
            }
          }
        } else {
          return {
            ...prevState,
            unsubmitted: {
              sort: data,
              isDesc: !prevState.unsubmitted.isDesc,
              pageCounter: prevState.unsubmitted.pageCounter
            }
          }
        }
      })
      sortTableUrl = `/api/v1/practice_reportable?client_type=${clientType}&page=${tableState[clientType].pageCounter}&rows=${'25'}&sort=${data}&direction=${!tableState[clientType].isDesc ? 'desc' : 'asc'}&naics_string=${selectedFilters.naics_string === 'All' ? '' : selectedFilters.naics_string}&revenue_band=${selectedFilters.revenue_band === 'All' ? '' : selectedFilters.revenue_band}&region=${selectedFilters.region === 'All' ? '' : selectedFilters.region}&partner=${selectedFilters.partner === 'All' ? '' : selectedFilters.partner}&office=${selectedFilters.office === 'All' ? '' : selectedFilters.office}&status=${selectedFilters.status === 'All' ? '' : selectedFilters.status}&year=${selectedFilters.year}&commit=filter`
    }

    const response = await fetch(sortTableUrl);
    const json = await response.json();
    setData(prevState => {
      if (clientType === 'submitted') {
        return {
          ...prevState,
          submitted_children: json.submitted_children,
        }
      } else {
        return {
          ...prevState,
          unsubmitted_children: json.unsubmitted_children,
        }
      }
    })
    setIsLoading(false)
  }

  const findSelectedIndustry = (who) => {
    let selectedIndustry = ''
    let selectedCode = ''
    let returnWho = ''
    if (data.drillup) {
      if (selectedFilters.naics_string.includes('-')) {
        data['naics_averages'].forEach((el, i) => {
          i > 0 ? selectedCode += ', ' + el.naics : selectedCode += el.naics
        })
        data.filterOptions['naics_string'].forEach(element => {
          element[0] === selectedFilters.naics_string ? selectedIndustry = element[1] : null
        });
      } else {
        selectedCode = data['naics_averages'][0]['naics']
        data.filterOptions['naics_string'].forEach(element => {
          element[0] === selectedCode ? selectedIndustry = element[1] : null
        });
      }
    } else selectedCode = 'All'
    selectedIndustry = selectedIndustry.split(' - ').join('')
    who === 'code' ? returnWho = selectedCode : returnWho = selectedIndustry
    return returnWho
  }

  const handleMouseEnter = (item) => {
    setActivePopover(item);
  };

  const handleMouseLeave = (item) => {
    if (item === 'leave') {
      setActivePopover(null);
    }
  };

  const openFiltersModal = () => {
    setFiltersModalIsOpen(true)
  }

  const closeFiltersModal = () => {
    setFiltersModalIsOpen(false)
  }

  if (data.year > 0) {
    let NAICSinfo = findSelectedIndustry('industry') ? `NAICS: ${findSelectedIndustry('code')}, ${findSelectedIndustry('industry')}` : `NAICS: ${findSelectedIndustry('code')}`
    return (
      <div style={{ opacity: (isLoading ? 0.6 : 1) }}>
        {practiceCalcsProgressBar ?
          <ProgressBarModal
            job={'PracticeCalculationWorker'}
            refresh={true}
            path={'/group_reports/practice'}
          /> : null}
        <div id='sticky-topnav-left-btns'>
          <ExportDropdown
            filters={selectedFilters}
            planAccess={{
              export_to_excel: true,
              print_and_pdf: true,
            }}
            printRef={componentRefs}
            page={'practice'}
            year={data.year}
            xlsx={true}
          />
        </div>
        <div id='sticky-topnav-right-btns'>
          <button className='hide-on-print' onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faQuestionCircle} /></button>
        </div>
        <div id='sticky-side-btns'>
          <span
            className='filters-btn-span hide-on-print'
            onMouseEnter={() => handleMouseEnter('filters')}
            onMouseLeave={() => handleMouseLeave('leave')}
          >
            <button className='filters-btn'><FontAwesomeIcon icon={faAnglesLeft} onClick={() => openFiltersModal()} /></button>
            {activePopover === 'filters' && (
              <div className='filters-popover'>
                Display Filters
              </div>)}
          </span>
        </div>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme='colored'
        />
        <Modal
          className={{
            base: 'client-modal-content',
            afterOpen: 'client-modal-content_after-open',
            beforeClose: 'client-modal-content_before-close',
          }}
          overlayClassName={{
            base: 'overlay-base',
            afterOpen: 'overlay-base_after-open',
            beforeClose: 'overlay-base_before-close',
          }}
          isOpen={filtersModalIsOpen}
          onRequestClose={closeFiltersModal}
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={0}
          contentLabel='Practice Report Filters Modal'
        >
          <div>
            <header className='company-modal-header'>
              <h1 className='client-company-name'>Report Filters</h1>
            </header>
            <div className='report-filters-container'>
              <div className='client-info-div'>
                <p>{NAICSinfo}</p>
              </div>
              <PRDropdownFilter filterOptions={data.filterOptions} handleSubmit={handleSubmit} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
            </div>
          </div>
        </Modal>
        <DashboardViewModal
          dashboardViewModalState={dashboardViewModalState}
          report={'practice'}
          handleDashboardViewModal={handleDashboardViewModal}
          createDashBoardView={createDashBoardView}
          deleteDashBoardView={deleteDashBoardView}
          updateDashBoardView={updateDashBoardView}
        />
        <div className='money-report-component money-content'>
          <ul className='report-tab-list' id='nav-tabs-money-report' role='tablist' >
            <li key='naics-analysis-li-item' id='naics-analysis-tab' className={activeTab === 'naics-analysis' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('naics-analysis')}>
              NAICS Analysis
            </li>
            <li key='naics-trend-number-li-item' id='naics-trend-number-tab' className={activeTab === 'naics-trend-number' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('naics-trend-number')}>
              NAICS Trends - #
            </li>
            <li key='naics-trend-dollar-li-item' id='naics-trend-dollar-tab' className={activeTab === 'naics-trend-dollars' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('naics-trend-dollars')}>
              NAICS Trends - $
            </li>
            <li key='client-analysis-li-item' id='client-analysis-tab' className={activeTab === 'client-analysis' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('client-analysis')}>
              Client Analysis
            </li>
            <li key='pnl-li-item' id='pnl-tab' className={activeTab === 'pnl' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('pnl')}>
              Profit &#38; Loss
            </li>
            <li key='balance-sheet-li-item' id='balance-sheet-tab' className={activeTab === 'bal-sheet' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('bal-sheet')}>
              Balance Sheet
            </li>
            <li key='dashboard-li-item' id='dashboard-tab' className={activeTab === 'dashboard' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('dashboard')}>
              Dashboard
            </li>
            <li key='edit-dashboard-li-item' id='edit-dashboard-tabs' className='edit-dashboard-section'>
              {dashboard.noChangesMade ? null : <button key='save-dashboard-changes-btn' className='save-dashboard-btn' onClick={() => updateDashBoardView()}>Save Changes</button>}
              {activeTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => handleDashboardViewModal('add_view')}>+ New View</button> : null}
              {activeTab === 'dashboard' ? <button className='save-dashboard-btn' onClick={() => handleDashboardViewModal('all_views')}>Edit My Views</button> : null}
              <EditDashboard
                key={'edit-dashboard-popup'}
                page={'practice'}
                dashboardLayoutType={''}
                gridItemsSelected={dashboard.selectedItems}
                noChangesMade={dashboard.noChangesMade}
                handleDashboardSelections={handleDashboardSelections}
                handleSelectAll={handleSelectAll}
                updateDashBoardView={updateDashBoardView}
              />
            </li>
          </ul>
          {data.insufficientData ?
            <h1>{data.insufficientData}</h1> :
            <div ref={componentRefs}>
              <div className='hide-cover-page-print'>
                <CoverReportPage
                  {...props}
                  displayPrintItems={true}
                  page={'practice report'}
                  industry={findSelectedIndustry('industry')}
                  naicsCode={findSelectedIndustry('code')}
                  n={data.child_count}
                />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'naics-analysis' ? 'show-section' : 'hide-section'}>
                <PRAnalysis {...data} tab='naics-analysis' selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} handleSubmit={handleSubmit} />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'naics-trend-number' ? 'show-section' : 'hide-section'}>
                <TrendChart
                  key='naics-trend-number'
                  type='number'
                  naics_trends_data={data.naics_trends_data}
                  year={data.year}
                />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'naics-trend-dollars' ? 'show-section' : 'hide-section'}>
                <TrendChart
                  key='naics-trend-dollars'
                  type='dollars'
                  naics_trends_data={data.naics_trends_data}
                  year={data.year}
                />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'client-analysis' ? 'show-section' : 'hide-section'}>
                <PRAnalysis
                  {...data}
                  tab='client-analysis'
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  handleSubmit={handleSubmit}
                  handleFocusedClients={handleFocusedClients}
                  tableState={tableState}
                  handleTableChanges={handleTableChanges}
                  setIsLoading={setIsLoading}
                  setTableState={setTableState}
                  userFocusedClients={data.userFocusedClients}
                />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'pnl' ? 'show-section' : 'hide-section'}>
                <MoneyReportSheets
                  clientName={'Practice Report'}
                  commonKey='revenue'
                  n={data.child_count}
                  page={data.page}
                  sheet='pnl'
                  title='Profit &#38; Loss'
                  tree={data.tree}
                  year={data.year}
                  yearRange={data.yearRange}
                  calcs={data.report_metrics}
                />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'bal-sheet' ? 'show-section' : 'hide-section'}>
                <MoneyReportSheets
                  clientName={'Practice Report'}
                  commonKey='assets'
                  n={data.child_count}
                  page={data.page}
                  sheet='balance_sheet'
                  title='Balance Sheet'
                  tree={data.tree}
                  year={data.year}
                  yearRange={data.yearRange}
                  calcs={data.report_metrics}
                />
              </div>
              <div className='page-break' />
              <div className={activeTab === 'dashboard' ? 'show-section' : 'hide-section'}>
                <DashboardViewControls
                  report={'practice'}
                  dashboardViewModalState={dashboardViewModalState}
                  getDashBoardView={getDashBoardView}
                  handleDashboardViewModal={handleDashboardViewModal}
                />
              </div>
              {activeTab === 'dashboard' ?
                <ParentDashboard
                  clientData={data.focusedClientData}
                  calculations={data.dashboard_calculations}
                  dashboardLayout={dashboard.layout}
                  handleOnLayoutChange={handleOnLayoutChange}
                  handleToggleDraggable={handleToggleDraggable}
                  page={'practice'}
                  rankingData={data.rankingData}
                  year={data.year}
                  yearRange={data.yearRange}
                /> : null}
            </div>
          }
        </div>
        {props.super_user
          ? (<form id='mapping-form' className='hide-on-print' action='/practice_calculations' acceptCharset='UTF-8' data-remote='true' method='post' >
            <input type='hidden' name='authenticity_token' value={window.token} />
            <input name='utf8' type='hidden' value='✓' />
            <input
              type='submit'
              value='Update Practice Calculations'
              className='submit-mapping-button btn btn-primary success pull-right'
              onClick={() => {
                document.querySelector('.money-report-component').style.zIndex = 0;
                setPracticeCalcsProgressBar(true);
              }}
            />
          </form>)
          : null
        }
      </div>
    )
  } else {
    return (
      <div style={{ 'height': '80vh', 'paddingTop': '30vh' }}>
        <LoaderGraphic />
      </div>
    )
  }
}

export default PracticeReport;