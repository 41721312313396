class MappingDragger {
  constructor({ onMouseUp }) {
    this.fdrs = [];
    this.value = "";
    this.dragging = false;
    this.finalClassName = "";

    document.addEventListener("mouseup", e => {
      document.querySelectorAll(".square-drag").forEach(m => {
        m.removeEventListener("mouseover", this.hoverListener);
      });

      if (this.dragging) {
        onMouseUp({ fdrs: this.fdrs, value: this.value });
        this.fdrs.length = 0;
      };

      this.dragging = false;
    });
  }

  dragListener = (event) => {
    this.dragging = true;
    event.preventDefault();
    this.value = event.target.parentElement.childNodes[0].value;
    // ['square-drag', 'liability-cell-selected'] 'parentElement classList'
    this.finalClassName = event.target.parentElement.classList[1]
    document.querySelectorAll('.square-drag').forEach(m => {
      m.addEventListener("mouseover", this.hoverListener);
    });
  };

  hoverListener = ({ target }) => {
    if (target.getAttribute('data-hover') && target.id !== "map-selector") {
      target.parentElement.childNodes.forEach((child) => {
        let childAccountTypeClass = child.classList[1]
        if (child.classList.contains('match-override') && child.textContent === this.value) {
          if (childAccountTypeClass && !childAccountTypeClass.includes('-selected')) { 
            child.classList.replace(child.classList[1], childAccountTypeClass + '-selected') 
          }
        } else if (child.classList.contains('match-override') && child.textContent !== this.value) {
          if (childAccountTypeClass && childAccountTypeClass.includes('-selected') && childAccountTypeClass.length > 9) {
            childAccountTypeClass = childAccountTypeClass.substring(0, childAccountTypeClass.length - 9)
            child.classList.replace(child.classList[1], childAccountTypeClass)
          }
        }
      })
      target.classList.replace(target.classList[1], this.finalClassName)
      target = target.childNodes[0];
      target.value = this.value;
      const id = target.getAttribute("fdr-id");
      if (!this.fdrs.includes(id)) this.fdrs.push(id);
    };
  };
};

export default MappingDragger;
