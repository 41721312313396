import React, { useState } from "react";

const Dropdown = ({ children, copy, onChange }) => {
  const [show, setShow] = useState(false);
  return(
    <div>
      <div className="dropdown-parent" onClick={() => {
          if (onChange) onChange(!show);
          setShow(prevShow => !prevShow);
        }}
      >
        <i className={`fa fa-caret-${show ? "up" : "down"}`}></i>
        <span>{copy}</span>
      </div>
      {show ?
        <div className="dropdown-children" style={{display: show ? "block" : "none"}}>
          {children}
        </div> : null}
    </div>
  );
}

export default Dropdown;
