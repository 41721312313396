const cashFlowStructure = [
  {
    cashFlowCategory: "operatingActivities",
    copy: "Cash flow from operating activities:",
    header: true,
    key: "current_assets_and_liabilities",
    padding: "",
    bold: true,
    order: 1,
    underline: false,
    money: true
  },
  {
    cashFlowCategory: "operatingActivities",
    header: false,
    bold: true,
    order: 197,
    topline: true,
    money: true,
    copy: "Net cash flow from operating activities",
    key: "current_assets_and_liabilities",
    padding: "",
    totalRow: true
  },
  {
    cashFlowCategory: "operatingActivities",
    header: true,
    bold: true,
    order: 198,
    topline: false,
    money: true,
    spacerRow: true,
    padding: "    ",
    copy: ""
  },
  {
    cashFlowCategory: "investingActivities",
    copy: "Cash flow from investing activities:",
    header: true,
    key: "long_term_assets",
    padding: "",
    bold: true,
    order: 199,
    underline: false,
    money: true
  },
  {
    cashFlowCategory: "investingActivities",
    header: false,
    bold: true,
    order: 297,
    topline: true,
    money: true,
    key: "long_term_assets",
    copy: "Net cash flow from investing activities",
    padding: "",
    totalRow: true
  },
  {
    cashFlowCategory: "investingActivities",
    header: true,
    bold: true,
    order: 298,
    topline: false,
    money: true,
    spacerRow: true,
    padding: "    ",
    copy: ""
  },
  {
    cashFlowCategory: "financingActivities",
    copy: "Cash flow from financing activities:",
    header: true,
    key: "long_term_debt_and_equity",
    padding: "",
    bold: true,
    order: 299,
    underline: false,
    money: true
  },
  {
    cashFlowCategory: "financingActivities",
    header: false,
    bold: true,
    order: 499,
    topline: true,
    money: true,
    key: "long_term_debt_and_equity",
    padding: "",
    copy: "Net cash flow from financing activities",
    totalRow: true
  },
  {
    cashFlowCategory: "financingActivities",
    header: true,
    bold: true,
    order: 500,
    topline: false,
    money: true,
    spacerRow: true,
    padding: "    ",
    copy: ""
  },
  {
    cashFlowCategory: "changeInCash",
    header: false,
    bold: true,
    order: 501,
    topline: false,
    money: true,
    copy: "Net change in cash and cash equivalents",
    padding: "",
    totalRow: true
  },
  {
    cashFlowCategory: "changeInCash",
    header: true,
    bold: true,
    order: 502,
    topline: false,
    money: true,
    spacerRow: true,
    padding: "    ",
    copy: ""
  },
  {
    cashFlowCategory: "beginCash",
    header: false,
    bold: true,
    order: 503,
    topline: false,
    money: true,
    copy: "Cash at beginning of period",
    padding: "",
    totalRow: true
  },
  {
    cashFlowCategory: "beginCash",
    header: true,
    bold: true,
    order: 504,
    topline: false,
    money: true,
    spacerRow: true,
    padding: "    ",
    copy: ""
  },
  {
    cashFlowCategory: "endCash",
    header: false,
    bold: true,
    order: 505,
    topline: false,
    money: true,
    copy: "Cash at end of period",
    padding: "",
    totalRow: true
  },
]

export default cashFlowStructure;