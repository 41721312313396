import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ApplyChangesToast from './ApplyChangesToast';

const ForecastScenarioToast = ({ closeToast, toastProps, fetchLiveData }) => {
  return (
    <div className='forecast-scenario-alert'>
      <p className='p1'>Do you want to save your changes before syncing your Live Data?</p>
      <p className='p2'>Give your scenario a new name and click <b>Save New Scenario</b>.</p>
      <div className='btn-section'><button onClick={closeToast}>Yes</button><button onClick={() => fetchLiveData()}>No</button></div>
    </div>
  )
}
const ForecastScenario = (props) => {
  const [state, setState] = useState({
    scenario_name: props.scenario_name || '',
    formErrors: false
  })

  const handleNavigateAwayAlert = (e) => {
    if (props.slidersYear1Touched || props.slidersYear2Touched || props.slidersYear3Touched) {
      e.preventDefault()
      // Unable to customize message because the browser's have a set default message.
      // But need e.returnValue for the message to appear.
      e.returnValue = ''
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleNavigateAwayAlert)
    setState(() => {
      return {
        scenario_name: props.scenario_name,
        formErrors: false
      }
    })
    return () => {
      window.removeEventListener('beforeunload', handleNavigateAwayAlert)
    }
  }, [props])

  const handleFormChange = (target) => {
    setState((prevState) => {
      return { ...prevState, scenario_name: target.value }
    })
  }

  const buildScenarioForm = () => {
    let errorCss, errorText;
    if (state.formErrors) {
      errorCss = {
        border: '#ED5932 2px solid',
        borderRadius: '2px'
      }
      errorText = (<div className='error-text'>{state.formErrors}</div>)
    }

    return (
      <form className='scenario-form' onSubmit={(e) => scenarioSubmit(e)} >
        <input style={errorCss} type='text' placeholder='New Scenario Name' className='name-input' onChange={({ target }) => handleFormChange(target)} />
        {errorText}
        <button className='btn scenario-btn'>Save New Scenario</button>
      </form>
    )
  }

  const scenarioSubmit = async (e) => {
    e.preventDefault();
    let scenarioLowerCase = state.scenario_name.toLowerCase()
    if (!state.scenario_name || scenarioLowerCase.startsWith('you') || scenarioLowerCase.startsWith('peer')) {
      setState({ formErrors: 'Scenario name cannot be blank. Please give it a name to call its own.' })
    } else {
      props.handleScenarioSubmit(state.scenario_name, false, true);
    }
  }

  const buildScenarioSelection = () => {
    let youScenario = props.scenarios.find(s => s.startsWith('You '))
    let peerScenario = props.scenarios.find(s => s.startsWith('Peers '))
    let allScenarios = props.scenarios;
    let savableScenarios = []
    props.scenarios.forEach(s => {
      if (s !== 'Straightline' && !s.startsWith('You ') && !s.startsWith('Peers ')) {
        savableScenarios.push(s)
      }
    })
    let displaySaveChangesBtn = savableScenarios.includes(state.scenario_name)

    if (props.youDataForChart.length > 0) {
      if (!youScenario) { allScenarios.push('You ') }
    }

    if (props.peerDataForChart.length > 0) {
      if (!peerScenario) { allScenarios.push('Peers ') }
    }

    const options = allScenarios.map((s, i) => {
      return (
        <option value={s} key={i}>{`${s}\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0`}</option>
      )
    })

    return (
      <div className='scenario-selection'>
        <div>
          <span> Load Scenario: </span>
          <select value={state.scenario_name} onChange={({ target }) => selectScenario(target)}>
            {options}
          </select>
        </div>
        {displaySaveChangesBtn ? <button className='btn scenario-btn' onClick={() => props.saveScenarioChanges()}>Save Changes</button> : null}
      </div>
    )
  }

  const selectScenario = (target) => {
    if (target.value.startsWith('You ') || target.value.startsWith('Peers ')) {
      if (props.actions.length > 0) {
        toast(<ApplyChangesToast handleScenarioChange={props.handleChartChange} liveData={false} setApplyActions={props.setApplyActions} value={target.value} />)
      } else {
        props.handleChartChange(target.value)
      }
    } else {
      if (props.actions.length > 0) {
        toast(<ApplyChangesToast handleScenarioChange={props.updateScenario} liveData={false} setApplyActions={props.setApplyActions} value={target.value} />)
      } else {
        props.updateScenario(target.value);
      }
    }
  }

  const handleSyncLiveData = () => {
    if (props.actions.length > 0) {
      toast(<ApplyChangesToast handleScenarioChange={props.fetchLiveData} liveData={true} setApplyActions={props.setApplyActions} />)
    } else {
      props.fetchLiveData()
    }
  }

  return (
    <div className='hide-on-print forecast-scenarios' >
      <ToastContainer
        position='top-center'
        autoClose={false}
        draggable={false}
      />
      {props.selectedYear === 'year1' ? <button className='btn revenue-model-btn revenue-model-form hide-on-print' onClick={() => props.setHideChart(!props.hideChart)}>{props.hideChart ? 'Show' : 'Hide'} Revenue Model</button> : null}
      {props.liveData && props.selectedYear === 'year1' ? <button className='btn sync-live-btn sync-live-form hide-on-print' onClick={() => handleSyncLiveData()}>Sync Live Data</button> : null}
      {(props.isLiveData && props.selectedYear === 'year1') || (props.rolling12Touched && props.selectedYear === 'year1') ? <button className='btn sync-live-btn sync-live-form hide-on-print' onClick={() => props.rolling12Months()}>Rolling 12 Months</button> : null}
      {buildScenarioForm()}
      {buildScenarioSelection()}
    </div>
  )
}

export default ForecastScenario;