import React, { useState, useEffect } from 'react';
import MappingTable from './MappingTable';
import MappingSheets from './MappingSheets';
import ProgressBarModal from '../progress_bar/ProgressBarModal';
import FduSelect from '../financial_data_uploads/FduSelect';
import MappingDragger from './MappingDragger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useTour } from '@reactour/tour';
window.selections = {};

const Mapper = (props) => {
  const { isOpen, currentStep, setIsOpen, setCurrentStep } = useTour();
  const [jobID, setJobID] = useState('')
  const [values, setValues] = useState(props.values);
  const [hideHistorics, setHideHistorics] = useState(true);
  const [reportsProgressBar, setReportsProgressBar] = useState(false);
  const [practiceCalcsProgressBar, setPracticeCalcsProgressBar] = useState(false);
  const [sameAccountsProgressBar, setSameAccountsProgressBar] = useState(false);
  const [tab, setTab] = useState('mapper')
  const [update, setUpdate] = useState(false);
  const lastYearValues = values[props.years[props.years.length - 1]];
  const balanced = Math.round(lastYearValues.total_assets) == Math.round(lastYearValues.total_liabilities_and_equity);

  useEffect(() => {
    props.tourData.tracker_id && !props.tourData.tour_taken ? setIsOpen(true) : null
  }, []);

  const TourBtn = () => {
    const questionCircle = <FontAwesomeIcon icon={faQuestionCircle} />
    return (
      <button className='hide-on-print' onClick={() => setIsOpen(true)}>{questionCircle}</button>
    )
  }

  async function updateRows(fdr_ids, account, drag = false, n_bayes_match = false) {
    const response = await fetch(`/mapping_approvals/row/${window.data.fdu_id}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        fdr_ids, account, n_bayes_match
      })
    });
    const { values } = await response.json();
    setValues(values);
    drag ? setUpdate(true) : setUpdate(false)
  };

  async function recalculate() {
    const response = await fetch(`/mapping_approvals/recalculate/${window.data.fdu_id}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
    const res = await response.json();
    if (res.ok) {
      window.location.reload();
    } else {
      console.log(res);
    }
  }

  const mappingDragger = new MappingDragger({
    onMouseUp: ({ fdrs, value }) => {
      fdrs.forEach(id => window.selections[id] = value);
      updateRows(fdrs, value, true, false);
    }
  });

  const handleGenerateReports = async (e) => {
    e.preventDefault()
    document.querySelector('.mapper-sticky').style.zIndex = 0;
    setReportsProgressBar(true);

    const response = await fetch(`/mapping_approvals`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ id: props.fdu_id })
    });
    const res = await response.json();

    if (res.ok) {
      setJobID(res['job_id'])
    } else {
      console.log(res);
    }
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        {reportsProgressBar ?
          <ProgressBarModal
            job={'CompanyCalculationWorker'}
            jobID={jobID}
            noActionCable={true}
            refresh={true}
            path={'/reports'}
          /> : null}
        {practiceCalcsProgressBar ?
          <ProgressBarModal
            job={'PracticeCalculationWorker'}
            refresh={true}
            path={'/reports'}
          /> : null}
        <div id="sticky-side-btns">
          <TourBtn />
        </div>
        <div className='mapper-sticky'>
          <div className='mapper-header'>
            <div className='company-header'>
              <h3 className='company-title'>Company: {props.company_name}</h3>
              <div className='mapper-company-info'>
                <h4 className='company-industry'>Industry: {props.industry}</h4>
                <h4 className='company-industry'>Fiscal Year End: {props.fiscal_year_end}</h4>
              </div>
            </div>
            <FduSelect list={props.fdu_list} path='/mapping_approvals/new?id=:id' />
          </div>
          {props.user_type === 'Admin' && props.fiscal_year_end !== '12/31'
            ? <div className='fiscal-year-warning-text'>
              <p style={{ 'textTransform': 'uppercase', 'fontWeight': 'bold' }}>This client has a different Fiscal Year End than 12/31!!!</p>
              <p>You do not need to worry about mapping the years to the previous year. They will be compared automatically with Peer data which is Calendar Year End (12/31).</p>
              <p>Ex: If the file has the years 2019, 2020, and 2021, then 2019 is compared to &#8594; 2018, 2020 &#8594; 2019, and 2021 &#8594; 2020.</p>
              <p>If you change the Fiscal Year End <b>after</b> uploading some files you will need to reupload those files so that the years will match up correctly.</p>
            </div>
            : null
          }
          <div>
            <div className='mapper-actions pull-right'>
              <h5 className='row-count '>Total Row Count: {props.row_count}</h5>
              <a id='recalculate-btn' className='' onClick={recalculate} href='#'>Recalculate</a>
              <div className='historic-button btn btn-primary ' id='hist-button' onClick={() => setHideHistorics(prev => !prev)} >
                {hideHistorics ? 'SHOW HISTORIC MATCHES' : 'HIDE HISTORIC MATCHES'}
              </div>
              <form id='mapping-form' action='/practice_calculations' acceptCharset='UTF-8' data-remote='true' method='post' >
                <input type='hidden' name='authenticity_token' value={window.token} />
                <input name='utf8' type='hidden' value='✓' />
                <input
                  type='submit'
                  value='Update Practice Calculations'
                  className='submit-mapping-button btn btn-primary success '
                  onClick={() => {
                    document.querySelector('.mapper-sticky').style.zIndex = 0;
                    setPracticeCalcsProgressBar(true);
                  }}
                />
              </form>
              <button className='submit-mapping-button btn btn-primary ' type='submit' onClick={(e) => handleGenerateReports(e)}>Generate Reports</button>
              <i className={` mapper-balance-sheet-check fa fa-check ${balanced ? 'balanced' : null}`} />
            </div>
            <ul id='mapper-nav-tabs' className='nav nav-tabs' role='tablist' >
              <li className={tab === 'mapper' ? 'active' : ''} role='presentation' onClick={() => setTab('mapper')}>
                <a aria-controls='home' role='tab'>
                  Mapping
                </a>
              </li>
              <li className={tab === 'balance-sheet' ? 'active' : ''} role='presentation' onClick={() => setTab('balance-sheet')}>
                <a aria-controls='balance-sheet' role='tab'>
                  Balance Sheet
                </a>
              </li>
              <li className={tab === 'pnl' ? 'active' : ''} role='presentation' onClick={() => setTab('pnl')}>
                <a aria-controls='pnl' role='tab'>
                  P & L
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='tab-content'>
          {tab === 'mapper'
            ? (<div className={`active ${hideHistorics ? 'hide-historics' : null}`} id='mapping'>
              <MappingTable
                fdu_id={props.fdu_id}
                fiscalYearEnd={props.fiscal_year_end}
                years={props.years}
                map_list={props.map_list}
                assets_list={props.assets_list}
                liabilities_list={props.liabilities_list}
                equity_list={props.equity_list}
                revenues_list={props.revenues_list}
                expenses_list={props.expenses_list}
                update={update}
                updateRows={updateRows}
                dragListener={mappingDragger.dragListener}
              />
            </div>)
            : null
          }
          {tab === 'balance-sheet'
            ? (<div className='' id='balance-sheet'>
              <h1>Balance Sheet</h1>
              <MappingSheets sheet='balance_sheet' values={values} years={props.years} tree={props.tree} />
            </div>)
            : null
          }
          {tab === 'pnl'
            ? (<div className='' id='pnl'>
              <h1>Profit & Loss Statement</h1>
              <MappingSheets sheet='pnl' values={values} years={props.years} tree={props.tree} />
            </div>)
            : null}
        </div>
      </div>
    </div>
  )
}

export default Mapper;
