import React, { useContext } from "react";
import { Data } from '../contexts';
import { pnlRows } from '../NAICSFocusedReport/NAICSFocusedReport';
import { parseMoney } from "../../utils";

const IndustryDoctorsPNL = (props) => {
    const { calcs } = useContext(Data)
    let commonSizeClass = props.pdf ? 'common-size-cell-pdf' : 'common-size-cell';

    const mappedTotal = (row, years, avg, key) => {
        let totalObj = {}
        totalObj['key'] = key
        years.forEach(year => {
            let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
            let avgSum = 0
            row.map_keys.forEach((c) => {
                avgSum += avg[c] ? avg[c][year] : 0
            })
            totalObj['avgVal' + year] = avgSum;
            totalObj['avg' + year] = (avgSum / compareTotalRevenue) * 100;
        })
        return totalObj
    }

    const totalNAICSFocused = (rows, years, avg, key) => {
        let childrenObjs = rows.filter(r => r.parentKey === key)
        let totalObj = {}
        let childrenValues = []
        childrenObjs.forEach((c) => {
            childrenValues.push(mappedTotal(c, years, avg, c.key))
        })

        years.forEach(year => {
            let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
            let avgSum = 0
            childrenValues.forEach((c) => {
                avgSum += c['avgVal' + year] ? c['avgVal' + year] : 0
            })
            totalObj['avgVal' + year] = avgSum;
            totalObj['avg' + year] = (avgSum / compareTotalRevenue) * 100;
        })
        return totalObj
    }


    const DentistDoctorPNLVals = (rows, calcs, years, row) => {
        const avg = calcs.docs_avg
        let rowObj = null;
        row.copyWithPadding = row.padding + row.copy
        let yearValues;

        if (avg) {
            if (row.total) {
                rowObj = totalNAICSFocused(rows, years, avg, row.key)
            } else if (row.key === 'gross_profit') {
                rowObj = {}
                let totalDirectCosts = totalNAICSFocused(rows, years, avg, 'total_direct_costs')
                years.forEach(year => {
                    let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
    
                    rowObj['avgVal' + year] = compareTotalRevenue - totalDirectCosts['avgVal' + year]
                    rowObj['avg' + year] = (rowObj['avgVal' + year] / compareTotalRevenue) * 100
                })
            } else if (row.key === 'operating_expenses_net_income') {
                rowObj = {}
                let totalDirectCosts = totalNAICSFocused(rows, years, avg, 'total_direct_costs')
                let totalAdminCosts = totalNAICSFocused(rows, years, avg, 'total_administrative_costs')
                years.forEach(year => {
                    let compareTotalRevenue = avg['total_revenue'] ? avg['total_revenue'][year] : 0
    
                    rowObj['avgVal' + year] = compareTotalRevenue - totalDirectCosts['avgVal' + year] - totalAdminCosts['avgVal' + year]
                    rowObj['avg' + year] = (rowObj['avgVal' + year] / compareTotalRevenue) * 100
                })
            } else {
                if (row.key) {
                    rowObj = mappedTotal(row, years, avg, row.key)
                } else {
                    return row;
                }
            }
        }

        return rowObj;
    }

    const buildRows = () => {
        let rows = pnlRows(props.naicsCode)
        let allRows = []
        if (rows) {
            rows.forEach((row) => {
                let rowValues = DentistDoctorPNLVals(rows, calcs, props.yearRange, row)
                allRows.push({ ...row, ...rowValues })
            })

            return allRows.map((row, i) => {

                if (row.emptyRow) {
                    return (
                        <tr>
                            <td className="industry-copy-cell"></td>
                            {props.yearRange.map((year, j) => {
                                let cellClasses = 'common-size-cell '

                                if (j % 2 === 0) {
                                    cellClasses += 'money-table-cell';
                                }
                                return (
                                    <>
                                        <td className={cellClasses}></td>
                                        <td className={cellClasses}></td>
                                        <td></td>
                                    </>
                                )
                            })}
                        </tr>
                    )
                } else {
                    let rowStyle = {}
                    if (row.topline) {
                        rowStyle['borderTop'] = '1px solid #000000'
                    }

                    return (
                        <tr style={rowStyle}>
                            <td className="industry-copy-cell" style={{ 'fontWeight': 'bold', 'textAlign': 'left' }}>{row.copyWithPadding}</td>
                            {props.yearRange.map((year, j) => {
                                let cellClasses = 'common-size-cell '

                                if (j % 2 === 0) {
                                    cellClasses += 'money-table-cell';
                                }
                                let firstVal = row["avgVal" + year] ? parseMoney(row["avgVal" + year]) : '';
                                let secondVal = row["avg" + year] ? row["avg" + year].toFixed(2) + '%' : '';
                                return (
                                    <>
                                        <td className={cellClasses}>{firstVal}</td>
                                        <td className={cellClasses}>{secondVal}</td>
                                        <td></td>
                                    </>
                                )
                            })}
                        </tr>
                    )
                }
            })
        }
    }


    const renderYearHeaderColumns = (years) => {
        let yearsRow = [];

        years.forEach((y, i) => {
            let cellClasses = 'common-size-cell ';

            if (i % 2 === 0) {
                cellClasses += 'money-table-cell';
              }

            yearsRow.push(
                <React.Fragment key={Math.random(i)}>
                    <td className={cellClasses} colSpan='2'>{y}</td>
                    <td className='space-cell'></td>
                </React.Fragment>
            )
        })

        return (
            <thead>
                <tr key={Math.random(20)}>
                    <td className="industry-copy-cell"></td>
                    {yearsRow}
                </tr>
            </thead>
        )
    }

    const buildNumOfCompaniesRow = () => {
        return (
            <tr key={`number-of-companies-${props.page}`} id={`${props.chart}-report-row-${props.page}`} >
                <td className="industry-copy-cell">Number of Companies</td>
                {props.yearRange.map((year, ii) => {
                    let backgroundStyle = '';
                    if (calcs['docs_avg']) {
                        let numOfCompanies = calcs['docs_avg']['number_of_companies'][year] || 0
    
                        if (ii % 2 === 0) {
                            backgroundStyle = 'money-table-cell';
                        }
    
                        return (
                            <React.Fragment key={Math.random(80)}>
                                <td key={`first-${ii}`} className={backgroundStyle}></td>
                                <td key={ii} className={backgroundStyle}>
                                    {`n = ${numOfCompanies}`}
                                </td>
                                <td></td>
                            </React.Fragment>
                        )
                    } else {
                        return null
                    }
                })}
            </tr>
        )
    }

    const buildTable = () => {
        return (
            <div>
                <div className='monthly-forecast-landscape report-card monthly-sheet'>
                    <div style={{ 'paddingTop': '10px', 'paddingLeft': '5px' }}>
                        <h1 className='report-table-title hide-not-on-print'>Doctor P&L</h1>
                        <div className='pdf-table-div industry-table-container'>
                            <table id='new-annual-table-naics_focused' style={{ whiteSpace: 'pre' }} className='industry-money-table' >
                                {renderYearHeaderColumns(props.yearRange)}
                                <tbody>
                                    {buildRows()}
                                    {buildNumOfCompaniesRow()}
                                </tbody>
                            </table>
                        </div>
                        <p className='pdf-disclaimer-text'>{`These financial statements have been prepared by ${props.firmName}, and have not been subjected to an audit or review or compilation engagement, and no assurance is provided on them. These financial statements are intended for management’s internal use only. Substantially all disclosures omitted.`}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {buildTable()}
        </div>
    )
}

export default IndustryDoctorsPNL