const COPY = {
  //peerviews
  wages_salaries: 'WAGES & SALARIES',
  mkt_spend: 'SPEND',
  mkt_roi: 'ROI',
  revenue_growth_rate_vs_net_profit3D: 'Total Revenue Growth Rate vs Net Profit',
  quadrant_growth_profitability: 'Growth & Profitability',
  quadrant_leverage_cash_flow: 'Leverage & Cash Flow',
  scatter_total_revenue_vs_growth_rate: 'Total Revenue Size vs Growth Rate',
  map_of_client_number: 'Where Your Clients Are Located',
  cash: 'Cash',
  total_revenue: 'Total Revenue',
  total_revenue_growth_rate: 'Total Revenue Growth Rate',
  total_costs: ' COGS',
  total_costs_common_size_revenue: 'COGS',
  total_people_costs: 'People',
  total_customer_costs: 'Customer',
  total_operating_expenses: 'OpEx',
  total_operating_expenses_common_size_revenue: 'OpEx',
  roa: 'ROA',
  roe: 'ROE',
  roce: 'ROCE',
  gross_margin: 'Gross Profit Margin',
  profit_margin: 'Net Profit Margin',
  profitability: 'Profitability',
  gross_profit: 'Gross Profit',
  current_ratio: 'Current Ratio',
  net_working_capital: 'Net Working Capital',
  net_working_capital_ratio: 'Net Working Capital Ratio',
  debt_to_equity: 'Debt-to-Equity',
  senior_debt_to_ebitda: 'Senior Debt/EBITDA',
  ebitda_to_annual_interest: 'EBITDA/Annual Interest',
  asset_turnover_ratio: 'Asset Turnover Ratio',
  inventory: 'Inventory',
  inventory_turnover_ratio: 'Inventory Turns',
  ar_days: 'Accounts Receivable Days',
  ap_days: 'Account Payable Days',
  ar_aging_summary: 'Accounts Receivable Aging Summary',
  ap_aging_summary: 'Accounts Payable Aging Summary',
  labor_costs: 'Labor',
  cogs_subcontractors: 'Subcontractors',
  materials_costs: 'COGS Materials',
  overhead: 'Overhead',
  transportation: 'Transportation',
  other_costs: 'Other',
  sales_and_marketing: 'Sales and Marketing',
  travel_entertainment_and_meals: 'Travel, Entertainment & Meals',
  customers_misc_professional_fees: 'Professional Fees - Customers',
  automobile_expenses: 'Automobile Expenses',
  bad_debt_expenses: 'Bad Debt Expenses',
  computer_related_costs: 'Computer Related Costs',
  contributions_and_donations: 'Contributions and Donations',
  insurance_expenses: 'Insurance Expense (non-health)',
  office_communications: 'Office Communications',
  office_expenses: 'Office Expense (non-facilities)',
  other_operating_expenses: 'Other Operating Expense',
  total_professional_fees: 'Professional Fees- Other',
  rent_and_facilities_costs: 'Rent and Facilities Costs',
  warranty_expense: 'Warranty Expense',
  operating_expenses_net_income: 'Net Income',
  total_compensation: 'Compensation',
  total_taxes_and_benefits: 'Taxes and Benefits',
  total_corporate_culture: 'Corporate Culture',
  subcontractor_expenses: 'Subcontractors',
  people_misc_professional_fees: 'Professional Fees (HR)',
  depreciation_and_amortization: 'Depreciation and Amoritization',
  interest_and_other_expenses: 'Interest and Other Expenses',
  total_revenue__0: 'Revenue Current Year',
  total_revenue__1: 'Revenue Previous Year',
  total_assets: 'Total Assets',
  total_current_assets: 'Current Assets',
  total_current_liabilities: 'Current Liabilities',
  net_worth: 'Net Worth',
  long_term_debt: 'Long Term Debt',
  current_portion_of_ltd: 'Current Portion LTD',
  line_of_credit: 'Line of Credit',
  other_income_expenses: 'Other Income Expenses',
  capital_stock: 'Capital Stock',
  owner_distributions: 'Owner Distributions',
  retained_earnings: 'Retained Earnings',
  inventory__0: 'Inventory Current Year',
  inventory__1: 'Inventory Previous Year',
  accounts_receivable: 'Accounts Receivable',
  accounts_payable: 'Accounts Payable',
  operating_profit: 'Operating Profit',

  // new accounts made from EditChartOfAccounts
  laboratory_fees: 'Laboratory Fees',
  medical_supplies: 'Medical Supplies',
  ['total_customer_costs_billings_& collections']: 'Billings and Collections',
  dental_supplies: 'Dental Supplies',
  ['total_operations_ops_- golf']: 'Ops Golf',
  ['total_operations_ops_- food & bev']: 'Ops Food and Beverage',
  ['total_operations_ops_- sporting activities']: 'Ops Sporting Activities',
  ['total_operations_ops_- clubhouse']: 'Ops Clubhouse',
  ['total_customer_costs_sales_& marketing']: 'Sales & Marketing',

  //people metrics
  operating_expenses_net_income_per_employee: 'Profit per Employee',
  sum_compensation_growth_rate: 'Compensation',
  sum_taxes_and_benefits_growth_rate: 'Benefits',
  sum_subcontractors_growth_rate: 'Use of Subcontractors',
  average_wages_and_salaries: 'Average Wages and Salaries Per Employee',
  average_benefits: 'Average Benefits Per Employee',
  cost_of_turnover: 'Cost of Turnover',
  total_number_of_employees: 'Number of Employees',
  total_compensation__people: 'OpEx Compensation',
  labor_cogs_compensation: 'COGS Compensation',
  labor_cogs_compensation__0: 'COGS Compensation Current Year',
  labor_cogs_compensation__1: 'COGS Compensation Previous Year',
  total_compensation__0: 'OpEx Compensation Current Year',
  total_compensation__1: 'OpEx Compensation Previous Year',
  total_taxes_and_benefits__0: 'OpEx Taxes and Benefits Current Year',
  total_taxes_and_benefits__1: 'OpEx Taxes and Benefits Previous Year',
  labor_cogs_taxes_and_benefits__0: 'COGS Taxes and Benefits Current Year',
  labor_cogs_taxes_and_benefits__1: 'COGS Taxes and Benefits Previous Year',
  cogs_subcontractors__0: 'COGS Subcontractors Current Year',
  cogs_subcontractors__1: 'COGS Subcontractors Previous Year',
  subcontractor_expenses__0: 'OpEx Subcontractors Current Year',
  subcontractor_expenses__1: 'OpEx Subcontractors Previous Year',
  other_corporate_culture: 'Other Corporate Culture',
  recruiting: 'Recruiting',
  training: 'Training',
  payroll_taxes: 'Payroll Tax',
  labor_cogs_taxes_and_benefits: 'COGS Taxes and Benefits',
  total_taxes_and_benefits__people: 'OpEx Taxes and Benefits',
  health_insurance: 'Health Insurance',
  retirement_plan: 'Retirement',
  other_taxes_and_benefits: 'Other',
  sum_compensation: 'Compensation',
  sum_retirement: 'Retirement',
  sum_payroll: 'Payroll Tax',
  sum_health_insurance: 'Healthcare',
  sum_subcontractors: 'Subcontractors',
  sum_other_benefits: 'Other Taxes and Benefits',
  total_people_expenses: 'Values Common Sized by Total People Costs',

  //turnover keys
  number_of_employees_who_left: 'Number of Employees Who Left',
  turnover_rate__you: 'Your Turnover Rate',
  turnover_rate__peers: 'Peer Turnover Rate',
  total_revenue_per_employee: 'Total Revenue Per Employee',
  revenue_per_employee: 'Revenue Per Employee',
  turnover_rate: 'Turnover Rate',
  lost_productivity__const: 'Lost Productivity',
  lost_productivity_time__const: 'Lost Productivity Time',
  lost_wages_time__const: 'Lost Wages Time',
  employee_productivity_decline__const: 'Employee Productivity Decline',
  employee_productivity_decline_time__const: 'Employee Productivity Decline Time',

  //customer keys
  total_number_of_customers: 'Number of Customers',
  total_customer_costs: 'Total Customer Costs',
  percent_of_sales_from_the_top_10_percent: 'Top 10%',
  percent_of_sales_from_the_top_25_percent: 'Top 25%',
  percent_of_sales_from_the_top_50_percent: 'Top 50%',
  number_of_new_customers_needed_to_maintain_growth: 'Growth Requirements',
  revenue_per_customer: 'Revenue Per Client',
  gross_profit_per_customer: 'Gross Profit Per Client',
  operating_expenses_net_income_per_customer: 'Net Profit Per Client',
  total_customer_costs_common_size_revenue: 'Marketing Spend',
  customer_roi: 'Marketing ROI',
  cltv_70: '70%',
  cltv_80: '80%',
  cltv_90: '90%',
}

function disableExportingAndCredits(highcharts_object) {
  highcharts_object.exporting = { enabled: false };
  highcharts_object.credits = { enabled: false };
  return highcharts_object;
}

export { COPY, disableExportingAndCredits };

