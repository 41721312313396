import React from 'react';

const LoaderGraphic = () => {
  return(
    <div className='text-center'>
      <span className='loading-spinner'></span>
    </div>
  )
}

export default LoaderGraphic;
