import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faPeopleGroup, faPerson, faPhone, faBriefcase, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import LoaderGraphic from './utils/LoaderGraphic';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const SignUp = (props) => {
    const [signUpPath, setSignUpPath] = useState(props.path || 'normal')
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        state: '',
        plan_type: props.plan_type && props.plan_type === 'Freemium' ? 'Freemium' : '',
        first_name: props.user ? props.user.first_name : '',
        last_name: props.user ? props.user.last_name : '',
        email: props.user ? props.user.email : '',
        position: '',
        phone_number: '',
        client_type: '',
        naics_id: props.naicsId,
        include_in_aggregate: true,
        primary_focus: '',
        firm_size: '1',
        invites: [
            {
                id: 1,
                email: '',
                first_name: '',
                last_name: ''
            },
            {
                id: 2,
                email: '',
                first_name: '',
                last_name: ''
            },
            {
                id: 3,
                email: '',
                first_name: '',
                last_name: ''
            },
            {
                id: 4,
                email: '',
                first_name: '',
                last_name: ''
            },
            {
                id: 5,
                email: '',
                first_name: '',
                last_name: ''
            },
        ]
    })

    const [formSteps, setFormSteps] = useState({
        errorMessage: '',
        currentStep: props.signup_part === 1 && props.path === 'normal' ? 'users_name' : props.signup_part === 1 && props.path === 'intuit' ? 'sso_firm_name' : 'user_primary_focus',
        steps: {
            users_name: {
                formLabel: 'Hello! Welcome to Peerview Data!',
                formQuestion: "Let's create your account!",
                imagePath: '../../assets/images/computers22.png',
                marketingMessage: '',
                required: false,
                step: 'users_name',
                goToStep: 'email_and_firm_name'
            },
            email_and_firm_name: {
                formLabel: "Nice to meet you!",
                formQuestion: "What is your email and the name of your firm?",
                imagePath: '',
                marketingMessage: "We've helped CPA firms nationwide amplify their expertise as advisors and build profitable new revenue streams.",
                required: false,
                step: 'email_and_firm_name',
                goToStep: 3
            },
            sso_firm_name: {
                formLabel: "Nice to meet you!",
                formQuestion: "What is the name of your firm?",
                imagePath: '',
                marketingMessage: "We've helped CPA firms nationwide amplify their expertise as advisors and build profitable new revenue streams.",
                required: false,
                step: 'sso_firm_name',
                goToStep: 3
            },
            join_existing_firm: {
                formLabel: "Hold the phone. It looks like a firm with that URL already exists.",
                formQuestion: "You can request to get added to that account (recommended) or, you can create a separate account.",
                formText3: "You will not be able to merge firms later, so choose this option carefully.",
                imagePath: '',
                marketingMessage: '',
                required: true,
                step: 'join_existing_firm',
            },
            firm_state_location: {
                formLabel: 'Where is your firm located?',
                imagePath: '',
                marketingMessage: '',
                required: false,
                step: 'firm_state_location'
            },
            account_created_static: {
                formLabel: 'Great! An email has been sent to you! Please check it out and continue from there.',
                imagePath: '',
                marketingMessage: '',
            },
            join_existing_firm_request_static: {
                formLabel: "Great! Someone within the firm will be notified of your request.",
                formQuestion: "Once they confirm it, you'll get email to finish setting up your account.",
                imagePath: '',
                marketingMessage: '',
            },
            // NEXT
            user_primary_focus: {
                formLabel: 'What is your primary focus?',
                imagePath: '',
                quote: `Something we found as an added benefit was using Peerview’s forecast in our year end client meetings. Our team was able to sync the forecast to real time data from QuickBooks Online, drive conversations from the screen, build multiple “what-if” scenarios, and to discuss tax implications.`,
                quotePosition: `Principal and Healthcare Practice Leader, WCS`,
                quoteUser: `- Helen Connolly, CPA`,
                required: true,
                goToStep: 'user_position',
                step: 'user_primary_focus'
            },
            user_position: {
                formLabel: 'What is your position?',
                imagePath: '',
                quote: `Peerview Data’s application was the first solution that provided us with views of all of our clients and client data. As a result, we’re able to see how our clients are doing compared to their peers and proactively connect with those who need assistance.`,
                quotePosition: `Managing Partner, Peachin, Schwartz & Weingardt`,
                quoteUser: `- Mike Potter, CPA`,
                required: true,
                goToStep: 'user_phone_number',
                step: 'user_position'
            },
            user_phone_number: {
                formLabel: 'What phone number is best for you?',
                imagePath: '',
                quote: `We’re really excited about how easy Peerview is to use. We’ve done analytical work in the past, but it always involved lots of manual input, lots of spreadsheets that we had to create & populate, etc. Now we can spend more time creating value and less time doing busywork.`,
                quotePosition: `Managing Partner, Cain Ellsworth`,
                quoteUser: `- Matt Heemstra, CPA`,
                required: true,
                goToStep: 'invites',
                step: 'user_phone_number'
            },
            firm_size: {
                formLabel: 'How many people work in your firm?',
                formQuestion: '',
                imagePath: '',
                required: true,
                goToStep: 'invites',
                step: 'firm_size'
            },
            invites: {
                formLabel: "Let's invite other users",
                formQuestion: 'Who else from your team needs to use Peerview with their clients? You can also add them later.',
                note: "*Intuit Users: Please use your users' Intuit email address if you want them to sign in with their Intuit Accounts.",
                imagePath: '',
                required: true,
                goToStep: '',
                step: 'invites'
            }
        }
    });

    const [joinFirmForm, setJoinFirmForm] = useState({
        selected_firm: '',
        matching_firms: []
    })

    const phoneNumberInputFormatter = (phoneNumber) => {
        const inputField = phoneNumber;

        const formatPhoneNumberValue = (value) => {
            // Handling the phone format as the user types in their phone number.
            if (!value) return value;
            value = value.length > 12 ? value.slice(0, 12) : value

            // Removing any non-digit values
            const phoneNumber = value.replace(/[^\d]/g, '');
            const phoneNumberLength = phoneNumber.length;

            // Return the value with no formatting if its less than four digits
            if (phoneNumberLength < 4) return phoneNumber;

            // If phoneNumberLength is greater than 4 and less the 7, then start returning the value with the format.
            if (phoneNumberLength < 7) {
                return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
            }
            // Final format return for the full phone number
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }

        const formattedInputValue = formatPhoneNumberValue(inputField);
        // Set the new input value
        return formattedInputValue;
    }

    let planType = 'Demo'
    let signupLink = '/demo_signup';
    let userType = 'DemoClient';
    let newUserLink = '/new_user_join'

    if (props.plan_type && props.plan_type === 'Freemium') {
        planType = 'Freemium'
        signupLink = '/freemium_signup'
        userType = 'Client'
        newUserLink = '/freemium_new_user_join'
    }

    const handleSignUpFormSubmit = async () => {
        let companyData = {
            name: formData.name,
            state: formData.state,
            plan_type: props.plan_type && props.plan_type === 'Freemium' ? 'Freemium' : '',
            naics_id: formData.naics_id,
            include_in_aggregate: formData.include_in_aggregate,
            client: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                position: formData.position,
                phone_number: formData.phone_number,
                type: formData.client_type,
                sso_provider: props.user ? props.user.sso_provider : '',
                sso_uid: props.user ? props.user.sso_uid : '',
                sso_only: props.user ? Boolean(props.user.sso_provider) : false
            }
        }

        try {
            const res = await fetch(signupLink, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company: companyData
                }),
            })
            const resJson = await res.json();

            if (!res.ok) {
                throw new Error('Network response was not ok.');
            } else {
                if (signUpPath !== "normal") {
                    window.location.href = resJson.redirect_to
                } else {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'account_created_static',
                            errorMessage: ''
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error);
            alert(error)
            setFormSteps(prevState => {
                return {
                    ...prevState,
                    errorMessage: error
                }
            })
        }

    }

    const updateUser = async () => {
        try {
            const res = await fetch(`/api/v1/users/${props.user.id}`, {
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    position: formData.position,
                    phone_number: formData.phone_number,
                    primary_focus: formData.primary_focus
                }),
            })

            if (!res.ok) {
                throw new Error('Network response was not ok.');
            } else {
                if (props.user.type === "Client" && formSteps.currentStep === 'user_phone_number') {
                    window.location = '/companies'
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateCompany = async () => {
        try {
            const res = await fetch(`/api/v1/companies/${props.user.company_id}`, {
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firm_size: formData.firm_size
                }),
            })

            if (!res.ok) {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSignUpForm = (input, value, inviteNum = null, inviteInput = null) => {
        if (input === 'first_name') {
            setFormSteps((prevState) => {
                let inputTitle = `Nice to meet you, ${value}!`
                const newSteps = prevState.steps
                newSteps['email_and_firm_name'].formLabel = inputTitle
                return {
                    ...prevState,
                    steps: newSteps
                }
            })
        }
        setFormData((prevState) => {
            switch (input) {
                case 'name':
                    return {
                        ...prevState,
                        name: value
                    }
                case 'state':
                    return {
                        ...prevState,
                        state: value
                    }
                case 'primary_focus':
                    return {
                        ...prevState,
                        primary_focus: value
                    }
                case 'firm_size':
                    return {
                        ...prevState,
                        firm_size: value
                    }
                case 'first_name':
                    return {
                        ...prevState,
                        first_name: value
                    }
                case 'last_name':
                    return {
                        ...prevState,
                        last_name: value
                    }
                case 'email':
                    return {
                        ...prevState,
                        email: value
                    }
                case 'position':
                    return {
                        ...prevState,
                        position: value
                    }
                case 'phone_number':
                    let phoneNum = phoneNumberInputFormatter(value)
                    return {
                        ...prevState,
                        phone_number: phoneNum
                    }
                case 'invites':
                    let newInvites = prevState.invites
                    newInvites[inviteNum][inviteInput] = value

                    if (inviteInput === 'email' && value.match(/^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: ''
                            }
                        })
                    } else if (inviteInput === 'email' && !value.match(/^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter a valid email.'
                            }
                        })
                    }
                    if (inviteInput === 'first_name' && value) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: ''
                            }
                        })
                    } else if (inviteInput === 'first_name' && !value) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter a first name.'
                            }
                        })
                    }

                    if (inviteInput === 'last_name' && value) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: ''
                            }
                        })
                    } else if (inviteInput === 'last_name' && !value) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter a last name.'
                            }
                        })
                    }

                    return {
                        ...prevState,
                        invites: newInvites
                    }
                default:
                    break;
            }
        })
    }

    const handleJoinFirmForm = (e) => {
        let selectedFirm = Number(e.target.value)
        setJoinFirmForm((prevState) => {
            return {
                ...prevState,
                selected_firm: selectedFirm
            }
        })
    }

    const handleNewUserJoinSubmit = async (e) => {
        e.preventDefault();
        let companyData = {
            selected_firm: joinFirmForm.selected_firm,
            client: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                type: 'PendingUser',
                sso_provider: props.user ? props.user.sso_provider : '',
                sso_uid: props.user ? props.user.sso_uid : '',
                sso_only: props.user ? Boolean(props.user.sso_provider) : false
            }
        }

        try {
            const res = await fetch(newUserLink, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company: companyData
                }),
            })
            const resJson = await res.json();
            if (!res.ok) {
                throw new Error('Network response was not ok.');
            } else {
                setFormSteps(prevState => {
                    return {
                        ...prevState,
                        currentStep: 'join_existing_firm_request_static'
                    }
                })
            }
        } catch (error) {
            console.log(error);
            alert(error);
            setFormSteps(prevState => {
                return {
                    ...prevState,
                    errorMessage: error
                }
            })
        }
    }

    const findMatchingFirms = async () => {
        try {
            const res = await fetch(`/check_firm_exists?email=${formData.email}&firm_name=${formData.name}`, {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                }
            })

            const resJson = await res.json();

            if (!res.ok) {
                setFormSteps(prevState => {
                    return {
                        ...prevState,
                        errorMessage: resJson.message
                    }
                })
            } else {
                if (resJson.matching_firms && resJson.matching_firms.length > 0) {
                    setJoinFirmForm((prevState) => {
                        return {
                            selected_firm: resJson.matching_firms[0][0],
                            matching_firms: resJson.matching_firms
                        }
                    })

                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'join_existing_firm',
                            errorMessage: ''
                        }
                    })

                } else {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'firm_state_location',
                            errorMessage: ''
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleUserInvites = async () => {
        try {
            const res = await fetch('/invite_users', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    invites: formData.invites,
                    sso_provider: props.user ? props.user.sso_provider : false
                }),
            })

            if (!res.ok) {
                const resJson = await res.json();
                setFormSteps(prevState => {
                    return {
                        ...prevState,
                        errorMessage: resJson.message
                    }
                })
            } else {
                window.location = '/subscriptions'
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleInputSteps = (stepValue, goBack = false) => {
        switch (stepValue) {
            case 'users_name':
                if (formData.first_name && formData.last_name) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'email_and_firm_name',
                            errorMessage: ''
                        }
                    })
                } else {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            errorMessage: 'Please enter your first and last name.'
                        }
                    })
                }
                break;
            case 'sso_firm_name':
                if (goBack) {
                    setSignUpPath('normal')
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'users_name',
                            errorMessage: ''
                        }
                    })
                } else {
                    if (!formData.name) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter a name for your firm.'
                            }
                        })
                    }
                }
            case 'email_and_firm_name':
                if (goBack) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'users_name',
                            errorMessage: ''
                        }
                    })
                } else {
                    if (formData.email.match(/^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) && formData.name) {
                        setIsLoading(true)
                        findMatchingFirms()
                        setIsLoading(false)
                    } else if (!formData.name) {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter a name for your firm.'
                            }
                        })
                    } else {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter a valid email.'
                            }
                        })
                    }
                }
                break;
            case 'join_existing_firm':
                if (goBack) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'email_and_firm_name',
                            errorMessage: ''
                        }
                    })
                } else {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'firm_state_location',
                            errorMessage: ''
                        }
                    })
                }
                break;
            case 'firm_state_location':
                if (goBack) {
                    setFormSteps(prevState => {
                        let backStep = signUpPath === 'normal' ? 'email_and_firm_name' : 'sso_firm_name';
                        return {
                            ...prevState,
                            currentStep: backStep,
                            errorMessage: ''
                        }
                    })
                } else {
                    if (formData.state) {
                        handleSignUpFormSubmit()
                    } else {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Select a state.'
                            }
                        })
                    }
                }
                break;
            case 'user_primary_focus':
                if (formData.primary_focus) {
                    updateUser()
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'user_position',
                            errorMessage: ''
                        }
                    })
                } else {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            errorMessage: 'Please select a primary focus.'
                        }
                    })
                }
                break;
            case 'user_position':
                if (goBack) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'user_primary_focus',
                            errorMessage: ''
                        }
                    })
                } else {
                    if (formData.position) {
                        updateUser()
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                currentStep: 'user_phone_number',
                                errorMessage: ''
                            }
                        })
                    } else {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter your position.'
                            }
                        })
                    }
                }
                break;
            case 'user_phone_number':
                if (goBack) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'user_position',
                            errorMessage: ''
                        }
                    })
                } else {
                    if (formData.phone_number) {
                        updateUser()
                    }

                    if (props.user && props.user.type !== "Client") {
                        setFormSteps(prevState => {
                            return {
                                ...prevState,
                                currentStep: 'firm_size',
                                errorMessage: ''
                            }
                        })
                    }
                }
                break;
            case 'firm_size':
                if (goBack) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'user_phone_number',
                            errorMessage: ''
                        }
                    })
                } else {
                    updateCompany()
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'invites',
                            errorMessage: ''
                        }
                    })
                }
                break;
            case 'invites':
                if (goBack) {
                    setFormSteps(prevState => {
                        return {
                            ...prevState,
                            currentStep: 'firm_size',
                            errorMessage: ''
                        }
                    })
                } else {
                    handleUserInvites()
                }
                break;
            default:
                break;
        }
    }

    const renderUsersNameInputs = () => {
        return (
            <>
                <p>What is your name?</p>
                <div className='signup-form-group string required company_client_first_name'>
                    <label className='control-label string required' htmlFor='company_client_first_name'>
                        <abbr title='required'>*</abbr> First name
                    </label>
                    <input className='form-control string required' value={formData.first_name} onChange={(e) => handleSignUpForm('first_name', e.target.value)} required='required' aria-required='true' type='text' name='company[client][first_name]' id='company_client_first_name' />
                </div>
                <div className='signup-form-group string required company_client_last_name'>
                    <label className='control-label string required' htmlFor='company_client_last_name'>
                        <abbr title='required'>*</abbr> Last name
                    </label>
                    <input className='form-control string required' value={formData.last_name} onChange={(e) => handleSignUpForm('last_name', e.target.value)} required='required' aria-required='true' type='text' name='company[client][last_name]' id='company_client_last_name' maxLength='20' />
                </div>
            </>
        )
    }

    const renderEmailAndFirmNameInput = (firmNameOnly = false) => {
        if (firmNameOnly) {
            return (
                <>
                    <div className='signup-form-group required company_name'>
                        <label className='control-label string required' htmlFor='company_name'>
                            <abbr title='required'>*</abbr> Firm Name
                        </label>
                        <input className='form-control string required' value={formData.name} onChange={(e) => handleSignUpForm('name', e.target.value)} required='required' aria-required='true' type='text' name='company[name]' id='company_name' />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className='signup-form-group required company_client_email'>
                        <label className='control-label string required' htmlFor='company_client_email'>
                            <abbr title='required'>*</abbr>Login Email
                        </label>
                        <input type='email' className='form-control string required' value={formData.email} onChange={(e) => handleSignUpForm('email', e.target.value)} required='required' aria-required='true' type='email' name='company[client][email]' id='company_client_email' />
                    </div>
                    <div className='signup-form-group required company_name'>
                        <label className='control-label string required' htmlFor='company_name'>
                            <abbr title='required'>*</abbr> Firm Name
                        </label>
                        <input className='form-control string required' value={formData.name} onChange={(e) => handleSignUpForm('name', e.target.value)} required='required' aria-required='true' type='text' name='company[name]' id='company_name' />
                    </div>
                </>
            )
        }
    }

    const renderExistingFirmsInput = () => {
        let imgPath = require("../../assets/images/AdobeStockmerge.jpeg")
        let stepData = formSteps['steps'][formSteps['currentStep']]
        return (
            <>
                <h1 style={{ 'textAlign': 'center', 'margin': '50px 0 0' }}>{stepData['formLabel']}</h1>
                <div className='demo-signup-form-container'>
                    {
                        isLoading
                            ? <LoaderGraphic />
                            : (<>
                                <div className='firm-panel'>
                                    <h2 style={{ 'textAlign': 'center', 'marginBottom': '20px' }}>{stepData['formQuestion']}</h2>
                                    {stepData['formText3'] ? <h2 style={{ 'fontStyle': 'italic', 'textAlign': 'center', 'marginBottom': '20px' }}>{stepData['formText3']}</h2> : null}
                                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                                    <div className='existing-firm-input'>
                                        <select value={joinFirmForm.selected_firm[0]} onChange={(e) => handleJoinFirmForm(e)}>
                                            {joinFirmForm.matching_firms.map((firm, i) => {
                                                return (<option key={`firm-${i}`} value={firm[0]}>{firm[1]}</option>)
                                            })}
                                        </select>
                                        <button className='btn btn-primary' type='submit' onClick={(e) => handleNewUserJoinSubmit(e)}>JOIN THIS FIRM</button>
                                    </div>
                                    <div className='signup-buttons-container'>
                                        <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                                        <button className='right-btn btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>Create A New Firm<FontAwesomeIcon icon={faArrowRight} /></button>
                                    </div>
                                </div>
                                <div className='pic-panel'>
                                    <img className='signup-pic' src={imgPath}></img>
                                </div>
                            </>)
                    }
                </div>
            </>
        )
    }

    const renderFirmSizeInput = () => {
        let stepData = formSteps['steps']['firm_size']
        return (
            <div className='no-title-signup-form-container'>
                <div className='firm-panel'>
                    <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                    <div className='signup-form-group string optional company_firm_size'>
                        <div className='firm-size-options-grid'>
                            <div className={formData.firm_size === '1' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '1')}>1</div>
                            <div className={formData.firm_size === '2 to 5' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '2 to 5')}>2 to 5</div>
                            <div className={formData.firm_size === '6 to 10' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '6 to 10')}>6 to 10</div>
                            <div className={formData.firm_size === '11 to 25' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '11 to 25')}>11 to 25</div>
                            <div className={formData.firm_size === '26 to 50' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '26 to 50')}>26 to 50</div>
                            <div className={formData.firm_size === '51 to 200' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '51 to 200')}>51 to 200</div>
                            <div className={formData.firm_size === '201 to 1,000' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '201 to 1,000')}>201 to 1,000</div>
                            <div className={formData.firm_size === '1,001 to 10,000' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '1,001 to 10,000')}>1,001 to 10,000</div>
                            <div className={formData.firm_size === '10,001 or more' ? 'firm-size-options selected' : 'firm-size-options'} onClick={() => handleSignUpForm('firm_size', '10,001 or more')}>10,001 or more</div>
                        </div>

                        <div className='signup-buttons-container'>
                            <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                            <button className='right-btn btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>Next <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                    </div>
                </div>
                <div className='you-panel'>
                    <img className='signup-custom-pic' src={require('../../assets/images/firmsize.png')}></img>
                </div>
            </div>
        )
    }

    const renderPositionInput = () => {
        let stepData = formSteps['steps']['user_position']
        return (
            <div className='no-title-signup-form-container'>
                <div className='firm-panel'>
                    <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                    <h2 style={{ 'textAlign': 'center' }}>{stepData['formQuestion']}</h2>
                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                    <div className='signup-form-group string optional company_client_position'>
                        <label className='control-label string required' htmlFor='company_client_position'>
                            <abbr title='required'>*</abbr> <FontAwesomeIcon icon={faBriefcase} /> Position
                        </label>
                        <input className='form-control string required' value={formData.position} onChange={(e) => handleSignUpForm('position', e.target.value)} required='required' aria-required='true' type='text' name='company[client][position]' id='company_client_position' maxLength='20' />
                        <div className='signup-buttons-container'>
                            <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                            <button className='right-btn btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>Next <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                    </div>
                </div>
                <div className='quote-panel'>
                    <p className='quote'><span className='end-quotation-marks'>“</span>{stepData['quote']}<span className='end-quotation-marks'>”</span></p>
                    <p style={{ 'color': '#69B144' }}>{stepData['quotePosition']}</p>
                    <p style={{ 'color': '#69B144' }}>{stepData['quoteUser']}</p>
                </div>
            </div>
        )
    }

    const createBeforeEmailConfirm = () => {
        let stepData = formSteps['steps'][formSteps['currentStep']]
        let stepInputs = null;
        let imgPath = ''
        let spacePanelClass = 'you-panel'
        if (formSteps.currentStep === 'users_name') {
            spacePanelClass = 'pic-panel'
            imgPath = require('../../assets/images/computers22.png')
            stepInputs = renderUsersNameInputs()
        }
        if (formSteps.currentStep === 'email_and_firm_name') {
            stepInputs = renderEmailAndFirmNameInput()
        }

        if (formSteps.currentStep === 'sso_firm_name') {
            stepInputs = renderEmailAndFirmNameInput(true)
        }

        if (formSteps.currentStep === 'join_existing_firm') {
            return renderExistingFirmsInput()
        }

        if (formSteps.currentStep === 'firm_state_location') {
            stepInputs = renderStateInput()
        }

        if (formSteps.currentStep === 'user_position') {
            return renderPositionInput()
        }

        return (
            <>
                <h1 style={{ 'textAlign': 'center', 'marginBottom': '30px' }}>{stepData['formLabel']}</h1>
                <div className={stepData['formLabel'] ? 'demo-signup-form-container' : 'no-title-signup-form-container'}>
                    {
                        isLoading
                            ? <LoaderGraphic />
                            : (<>
                                <div className='firm-panel'>
                                    {stepData['formQuestion'] ? <h2 style={{ 'textAlign': 'center', 'marginBottom': '20px' }}>{stepData['formQuestion']}</h2> : null}
                                    {stepData['formText3'] ? <h2 style={{ 'fontStyle': 'italic', 'textAlign': 'center', 'marginBottom': '20px' }}>{stepData['formText3']}</h2> : null}
                                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                                    {stepInputs}
                                    <div className='signup-buttons-container'>
                                        {formSteps.currentStep !== 'users_name' ? <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button> : null}
                                        <button className='right-btn btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], false)}>Next <FontAwesomeIcon icon={faArrowRight} /></button>
                                    </div>
                                </div>
                                <div className={spacePanelClass}>
                                    {stepData['marketingMessage'] ? <h2>{stepData['marketingMessage']}</h2> : null}
                                    {imgPath ? <img className='signup-pic' src={imgPath}></img> : null}
                                </div>
                            </>)
                    }
                </div>
            </>
        )
    }

    const renderStateInput = () => {
        let stepData = formSteps['steps'][formSteps['currentStep']]
        return (
            <div className='no-title-signup-form-container'>
                <div className='firm-panel'>
                    <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                    <h2 style={{ 'textAlign': 'center' }}>{stepData['formQuestion']}</h2>
                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                    <div className='signup-form-group select required company_state'>
                        <label className='control-label select required' htmlFor='company_state'>
                            <abbr title='required'>*</abbr> State
                        </label>
                        <select className='form-control select required' value={formData.state} onChange={(e) => handleSignUpForm('state', e.target.value)} required='required' aria-required='true' name='company[state]' id='company_state'>
                            <option key='empty-state' value=''></option>
                            <option key='AL' value='AL'>Alabama</option>
                            <option key='AK' value='AK'>Alaska</option>
                            <option key='AZ' value='AZ'>Arizona</option>
                            <option key='AR' value='AR'>Arkansas</option>
                            <option key='CA' value='CA'>California</option>
                            <option key='CO' value='CO'>Colorado</option>
                            <option key='CT' value='CT'>Connecticut</option>
                            <option key='DE' value='DE'>Delaware</option>
                            <option key='DC' value='DC'>District of Columbia</option>
                            <option key='FL' value='FL'>Florida</option>
                            <option key='GA' value='GA'>Georgia</option>
                            <option key='HI' value='HI'>Hawaii</option>
                            <option key='ID' value='ID'>Idaho</option>
                            <option key='IL' value='IL'>Illinois</option>
                            <option key='IN' value='IN'>Indiana</option>
                            <option key='IA' value='IA'>Iowa</option>
                            <option key='KS' value='KS'>Kansas</option>
                            <option key='KY' value='KY'>Kentucky</option>
                            <option key='LA' value='LA'>Louisiana</option>
                            <option key='ME' value='ME'>Maine</option>
                            <option key='MD' value='MD'>Maryland</option>
                            <option key='MA' value='MA'>Massachusetts</option>
                            <option key='MI' value='MI'>Michigan</option>
                            <option key='MN' value='MN'>Minnesota</option>
                            <option key='MS' value='MS'>Mississippi</option>
                            <option key='MO' value='MO'>Missouri</option>
                            <option key='MT' value='MT'>Montana</option>
                            <option key='NE' value='NE'>Nebraska</option>
                            <option key='NV' value='NV'>Nevada</option>
                            <option key='NH' value='NH'>New Hampshire</option>
                            <option key='NJ' value='NJ'>New Jersey</option>
                            <option key='NM' value='NM'>New Mexico</option>
                            <option key='NY' value='NY'>New York</option>
                            <option key='NC' value='NC'>North Carolina</option>
                            <option key='ND' value='ND'>North Dakota</option>
                            <option key='OH' value='OH'>Ohio</option>
                            <option key='OK' value='OK'>Oklahoma</option>
                            <option key='OR' value='OR'>Oregon</option>
                            <option key='PA' value='PA'>Pennsylvania</option>
                            <option key='PR' value='PR'>Puerto Rico</option>
                            <option key='RI' value='RI'>Rhode Island</option>
                            <option key='SC' value='SC'>South Carolina</option>
                            <option key='SD' value='SD'>South Dakota</option>
                            <option key='TB' value='TN'>Tennessee</option>
                            <option key='TX' value='TX'>Texas</option>
                            <option key='UT' value='UT'>Utah</option>
                            <option key='VT' value='VT'>Vermont</option>
                            <option key='VA' value='VA'>Virginia</option>
                            <option key='WA' value='WA'>Washington</option>
                            <option key='WV' value='WV'>West Virginia</option>
                            <option key='WI' value='WI'>Wisconsin</option>
                            <option key='WY' value='WY'>Wyoming</option>
                        </select>
                    </div>
                    <div className='signup-buttons-container'>
                        <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                        <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>Create Your Firm</button>
                    </div>
                </div>
                <div className='you-panel'>
                    <img className='signup-custom-pic' src={require('../../assets/images/location.png')}></img>
                </div>
            </div>
        )
    }

    const renderPrimaryFocusInput = () => {
        let stepData = formSteps['steps']['user_primary_focus']

        return (
            <div className='no-title-signup-form-container'>
                <div className='firm-panel'>
                    <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                    <h2 style={{ 'textAlign': 'center' }}>{stepData['formQuestion']}</h2>
                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                    <div className='signup-form-group select required company_primary_focus'>
                        <label className='control-label select required' htmlFor='company_primary_focus'>
                            <abbr title='required'>*</abbr> <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Primary Focus
                        </label>
                        <select className='form-control select required' value={formData.primary_focus} onChange={(e) => handleSignUpForm('primary_focus', e.target.value)} required='required' aria-required='true' name='company[primary_focus]' id='company_primary_focus'>
                            <option key='empty-focus' value=''></option>
                            <option key='audit' value='Audit'>Audit</option>
                            <option key='tax' value='Tax'>Tax</option>
                            <option key='caas' value='CAAS'>CAAS</option>
                        </select>
                    </div>
                    <div className='signup-buttons-container'>
                        <button className='right-btn btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>Next <FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                </div>
                <div className='quote-panel'>
                    <p className='quote'><span className='end-quotation-marks'>“</span>{stepData['quote']}<span className='end-quotation-marks'>”</span></p>
                    <p style={{ 'color': '#69B144' }}>{stepData['quotePosition']}</p>
                    <p style={{ 'color': '#69B144' }}>{stepData['quoteUser']}</p>
                </div>
            </div>
        )
    }

    const renderPhoneNumberInput = () => {
        let stepData = formSteps['steps']['user_phone_number']
        return (
            <div className='no-title-signup-form-container'>
                <div className='firm-panel'>
                    <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                    <h2 style={{ 'textAlign': 'center' }}>{stepData['formQuestion']}</h2>
                    {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                    <div className='signup-form-group tel optional company_client_phone_number'>
                        <label className='control-label tel optional' htmlFor='company_client_phone_number'>
                            <FontAwesomeIcon icon={faPhone} /> Phone number
                        </label>
                        <input className='form-control string tel optional' value={formData.phone_number} onChange={(e) => handleSignUpForm('phone_number', e.target.value)} pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' type='tel' name='company[client][phone_number]' id='company_client_phone_number' />
                    </div>
                    <div className='signup-buttons-container'>
                        <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                        <button className='right-btn btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>{props.user && props.user.type === 'Client' ? <span>Finish</span> : <span>Next <FontAwesomeIcon icon={faArrowRight} /></span>}</button>
                    </div>
                </div>
                <div className='quote-panel'>
                    <p className='quote'><span className='end-quotation-marks'>“</span>{stepData['quote']}<span className='end-quotation-marks'>”</span></p>
                    <p style={{ 'color': '#69B144' }}>{stepData['quotePosition']}</p>
                    <p style={{ 'color': '#69B144' }}>{stepData['quoteUser']}</p>
                </div>
            </div>
        )
    }

    const renderClientInviteInputs = () => {
        let stepData = formSteps['steps']['invites']
        return (
            <>
                <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                <div className='demo-signup-form-container'>
                    <div className='invite-panel'>
                        <h2 style={{ 'textAlign': 'center' }}>{stepData['formQuestion']}</h2>
                        <p>{stepData['note']}</p>
                        {formSteps['errorMessage'] ? <p className='error-message'>{formSteps['errorMessage']}</p> : null}
                        <div className='signup-form-group optional invite_user'>
                            <label className='control-label tel optional' htmlFor='invite_user'>
                                <FontAwesomeIcon icon={faPeopleGroup} /> Invite Users
                            </label>
                            {formData.invites.map((invite, i) => {
                                return (
                                    <div className='invite-user-inputs-container'>
                                        <span><FontAwesomeIcon icon={faPerson} /> {`User ${i + 1}`}</span>
                                        <div>
                                            <label htmlFor={`email-${i + 1}`}>Email</label>
                                            <input name={`email-${i + 1}`} required='required' aria-required='true' onChange={(e) => handleSignUpForm('invites', e.target.value, i, 'email')} />
                                        </div>
                                        <div>
                                            <label htmlFor={`first_name-${i + 1}`}>First Name</label>
                                            <input name={`first_name-${i + 1}`} required='required' aria-required='true' onChange={(e) => handleSignUpForm('invites', e.target.value, i, 'first_name')} />
                                        </div>
                                        <div>
                                            <label htmlFor={`last_name-${i + 1}`}>Last Name</label>
                                            <input name={`last_name-${i + 1}`} required='required' aria-required='true' onChange={(e) => handleSignUpForm('invites', e.target.value, i, 'last_name')} />
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className='signup-buttons-container'>
                            <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'], true)}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                            <button className='btn btn-primary' type='button' onClick={() => handleInputSteps(stepData['step'])}>Next <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                    </div>
                    <div className='you-panel'>
                        <img className='signup-custom-pic' src={require('../../assets/images/youreinvitedemail.png')}></img>
                    </div>
                </div>
            </>
        )
    }

    const renderStaticSuccessMessage = () => {
        let stepData = formSteps['steps'][formSteps['currentStep']]
        let staticPic = require('../../assets/images/computers22.png')
        return (
            <div className='demo-signup-static-container'>
                <h1 style={{ 'textAlign': 'center' }}>{stepData['formLabel']}</h1>
                {stepData['formQuestion'] ? <h2 style={{ 'textAlign': 'center' }}>{stepData['formQuestion']}</h2> : null}
                <img className='signup-static-pic' src={staticPic}></img>
            </div>
        )
    }

    const signUpSections = () => {
        if (formSteps.currentStep === 'users_name') {
            return createBeforeEmailConfirm()
        }
        if (formSteps.currentStep === 'sso_firm_name') {
            return createBeforeEmailConfirm()
        }
        if (formSteps.currentStep === 'email_and_firm_name') {
            return createBeforeEmailConfirm()
        }

        if (formSteps.currentStep === 'join_existing_firm') {
            return createBeforeEmailConfirm()
        }

        if (formSteps.currentStep === 'firm_state_location') {
            return renderStateInput()
        }

        if (formSteps.currentStep === 'account_created_static' || formSteps.currentStep === 'join_existing_firm_request_static') {
            return renderStaticSuccessMessage()
        }

        // After Login
        if (formSteps.currentStep === 'user_primary_focus') {
            return renderPrimaryFocusInput()
        }

        if (formSteps.currentStep === 'user_position') {
            return createBeforeEmailConfirm()
        }

        if (formSteps.currentStep === 'user_phone_number') {
            return renderPhoneNumberInput()
        }

        if (formSteps.currentStep === 'firm_size') {
            return renderFirmSizeInput()
        }

        if (formSteps.currentStep === 'invites') {
            return renderClientInviteInputs()
        }
    }

    return (
        <div className='signup-container'>
            {signUpSections()}
        </div>
    )
}

export default SignUp;