import React, { useContext, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-modal';

const ForecastPDFandPrint = (props) => {
    const naicsFocusedReport = props.naicsCode.startsWith('6212') ? 'Dental' : props.naicsCode.startsWith('6211') || props.naicsCode.startsWith('6213') ? 'Doctor' : null;
    const [PDFPrintModalOpen, setPDFPrintModalOpen] = useState(false);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const navbar = window.navbar.navbar;
    const pdfIcon = <FontAwesomeIcon icon={faFilePdf} />

    const openVideoModal = () => {
        setVideoModalOpen(true);
    }

    const closeVideoModal = () => {
        setVideoModalOpen(false);
    }

    const closePDFPrintModal = () => {
        setPDFPrintModalOpen(false);
    }

    const VideoModal = () => {
        let videoLink = null
        let modalPic = require('../../assets/images/PremiumFeaturePDF&Excel.jpeg')

        const hrefBuilder = (subdomain, path) => {
            const domainSplit = document.location.host.split('.');
            domainSplit[0] = subdomain;
            return location.protocol + '//' + domainSplit.join('.') + path;
        };

        const manageSubscriptionsURL = hrefBuilder(navbar.subdomain, '/subscriptions')
        const unlockReportsURL = hrefBuilder(navbar.subdomain, navbar.unlock_reports_url)
        const linkText = navbar.report_credits ? "Click Here to Go Unlock Reports" : "Click Here to Buy More Report Credits"
        const purchaseText = "Buy more report credits."

        return (
            <Modal
                className={{
                    base: 'video-modal-content hide-on-print',
                    afterOpen: 'video-modal-content_after-open hide-on-print',
                    beforeClose: 'video-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'overlay-base hide-on-print',
                    afterOpen: 'overlay-base_after-open hide-on-print',
                    beforeClose: 'overlay-base_before-close hide-on-print',
                }}
                isOpen={videoModalOpen}
                onRequestClose={closeVideoModal}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={0}
                contentLabel='Video About Report Features'
                ariaHideApp={false}
            >
                <div>
                    <h1>Upgrade to Access These Reports</h1>
                    <img className='premium-pic-popup' src={modalPic}></img>
                    {(props.planAccess.print_and_pdf && !navbar.reports_unlocked || props.planAccess.export_to_excel && !navbar.reports_unlocked)
                        ? (<div className='unlock-report-container'>
                            <h2>Unlock this Client's Reports?</h2>
                            <p>{`You have ${navbar.report_credits} Report Credits left.`} <a href={unlockReportsURL}>{linkText}</a></p>
                        </div>)
                        : null}
                    <a className='manage-subscription-link-popup' href={manageSubscriptionsURL}>Click Here to View Plans &#38; Manage Your Subscription</a>
                    <p>Click anywhere outside to close this pop-up.</p>
                </div>
            </Modal>
        )
    }



    let pdfButtonId = 'moneyPdfId';

    const getPDFButton = () => {
        if ((!props.planAccess.print_and_pdf && navbar.user_type !== 'Admin') || (!navbar.reports_unlocked && props.planAccess.print_and_pdf && navbar.user_type !== 'Admin')) {
            return (
                <span className='hide-on-print'>
                    <button id={pdfButtonId} className='print-pdf-btn' aria-hidden='true' onClick={() => openVideoModal()}>{pdfIcon}</button>
                </span>
            )
        }

        if (navbar.reports_unlocked && props.planAccess.print_and_pdf || navbar.user_type === 'Admin') {
            return (
                <span className='hide-on-print'>
                    <button id={pdfButtonId} className='print-pdf-btn' onClick={() => setPDFPrintModalOpen(true)} aria-hidden='true' >{pdfIcon}</button>
                </span>
            )
        }
    }

    const printPDFExport = () => {
        if (navbar.reports_unlocked && props.planAccess.print_and_pdf || navbar.user_type === 'Admin') {
            return (
                <Modal
                    className={{
                        base: 'pdf-print-modal-content hide-on-print',
                        afterOpen: 'pdf-print-modal-content_after-open hide-on-print',
                        beforeClose: 'pdf-print-modal-content_before-close hide-on-print',
                    }}
                    overlayClassName={{
                        base: 'overlay-base hide-on-print',
                        afterOpen: 'overlay-base_after-open hide-on-print',
                        beforeClose: 'overlay-base_before-close hide-on-print',
                    }}
                    isOpen={PDFPrintModalOpen}
                    onRequestClose={closePDFPrintModal}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={0}
                    contentLabel='PDF and Print Preview'
                    ariaHideApp={false}
                >
                    <div className='report-builder-container'>
                        <h2>Report Builder</h2>
                        <p>Include: </p>
                        <div className='report-builder-all-checkboxes'>
                            <div>
                                <div key={`cover_page-checkbox`} className='page-checkbox-input'>
                                    <input id={`cover_page-checked`} type='checkbox' name={'cover_page'} value={'cover_page'} checked={props.displayPages['cover_page']['checked']} onChange={(e) => props.handlePDFandPrintChecked(e.target.value)} />
                                    <label htmlFor={`cover_page-checked`}>{props.displayPages['cover_page']['label']}</label>
                                </div>
                            </div>
                            <div className='report-pages-checkboxes'>
                                {Object.keys(props.displayPages).map((key) => {
                                    if (key === 'naics_focused_report' && !naicsFocusedReport || key === 'cover_page') {
                                        return null
                                    } else if (props.displayPages[key]['pages'].includes(props.page)) {
                                        return (
                                            <div key={`${key}-checkbox`} className='page-checkbox-input'>
                                                <input id={`${key}-checked`} type='checkbox' name={key} value={key} checked={props.displayPages[key]['checked']} onChange={(e) => props.handlePDFandPrintChecked(e.target.value)} />
                                                <label htmlFor={`${key}-checked`}>{props.displayPages[key]['label']}</label>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <ReactToPrint
                            trigger={() => <button id={pdfButtonId} className='run-report-btn pdf-print-action-btn' aria-hidden='true' onClick={() => preparePDFPrint()}>Print to PDF Now!</button>}
                            content={() => props.componentRefs.current}
                            documentTitle={`${props.name}MonthlyForecast.pdf`}
                        />
                    </div>
                </Modal>
            )
        }
    }

    return (
        <>
            <VideoModal />
            {getPDFButton()}
            {printPDFExport()}
        </>
    )
};
export default ForecastPDFandPrint;
