import React, { useContext } from 'react';
import { Data } from '../contexts';
import { parseMoney } from '../../utils';
import { ratioCalculations, ratioCalculationsMoney, calculateEBITDA, netWorkingCapitalCalculation, netWorkingCapitalRatioCalculation, trailing12MonthCalculations } from './monthy_analysis_table_trailing12_calculations';

const MonthlyForecastKFITableBuilder = (props) => {
  const { calcs, previousYearCalcs, previous_yoy_calcs } = useContext(Data);
  let calcsUsed = calcs;
  let previousYearCalcsUsed = previousYearCalcs['you'];
  let numberOfMonths = 0
  let useableMonths;
  const months = props.months
  const rows = [];
  let forecastValuesAndCalcs = {}
  // Need for extra years on forecast 
  let newPreviousYearCalcs = {}
  const forecastClassNames = props.page === 'forecast' ? "year-rows cash-flow-column month-col " : '';
  const selectedMonth = (i) => { 
    if (props.editMultipleMonths) {
      return i >= props.month ? 'current-month-col' : null
    } else {
      return props.month == i ? 'current-month-col' : null 
    }
  }

  const backgroundChanger = (i) => i < (props.blendMonth - 1) && props.isLiveData && props.year === new Date(Date.now()).getFullYear() && i !== props.month ? 'live-data-col' : null

  function formatPercent(value) {
    if (value) {
      return (Math.round(value * 10000) / 100) + "%";
    } else {
      return "-"
    }
  };

  function formatDays(value) {
    if (value) {
      // We want to check if zero has a negative sign or not. If it does we want to get rid of the negative sign.
      // We don't want to see -0.0 days.
      let checkPositiveValue = value.toFixed(1)
      if (checkPositiveValue == 0) {
        return `${Math.abs(checkPositiveValue)} days`;
      } else {
        return `${checkPositiveValue} days`
      }
    } else {
      return "-"
    }
  };

  function formatTwoDecimal(value) {
    if (value) {
      return value.toFixed(2);
    } else {
      return "-"
    }
  };

  function undefinedToNull(value) {
    if (typeof value == "undefined") {
      return value = null
    } else {
      return value
    }
  };

  function rowFormatter(amount, key) {
    // Glenn wanted to add Taxes - Federal & State to the Analysis table
    const days = ["Accounts Receivable Days", "Accounts Payable Days"]
    const money = ["Net Working Capital", "Revenue Per Employee", "Revenue Per Customer", "Net Income", "Taxes - Federal & State", "Depreciation/Amortization", "Interest", "EBITDA"]
    const twoDecimal = ["Current Ratio", "Net Working Capital Ratio", "Debt / Equity", "Senior Debt / EBITDA", "EBITDA / Annual Interest", "Asset Turnover Ratio", "Inventory Turnover Ratio"]
    const percent = ["Return on Assets (ROA)", "Return on Equity (ROE)", "Return on Capital Employed (ROCE)", "Gross Profit", "Operating Profit"]

    if (days.includes(key)) {
      return formatDays(amount)
    } else if (money.includes(key)) {
      return parseMoney(amount);
    } else if (twoDecimal.includes(key)) {
      return formatTwoDecimal(amount)
    } else if (percent.includes(key)) {
      return formatPercent(amount)
    } else {
      return "-"
    };
  };

  function buildRow(key, value, trailing12MonthsKey, newMonthlyAnalysisCalculatedValues, i) {
    let you;
    let valueArr = []
    let ytdRowYou
    let ytdRowComparison

    if (newMonthlyAnalysisCalculatedValues) {
      // newMonthlyAnalysisCalculatedValues looks like { 1: 123, 2: 4321, ... }
      // Don't need to worry about getting property value here from newMonthlyAnalysisCalculatedValues
      you = newMonthlyAnalysisCalculatedValues
    } else {
      // calcs looks like { current_ratio: { 1: 100, 2: 300, ... }, roa: { 1: 0, 2: 10, ...} }
      // value is current_ratio
      if (forecastValuesAndCalcs[value]) {
        you = forecastValuesAndCalcs[value]
      } else {
        you = calcsUsed[value];
      }
    }


    if (you) {
      let monthArr = Object.keys(months);
      monthArr.forEach(month => {
        valueArr.push(undefinedToNull(you[month]))
      })      
    }

    let hideRow = '';
    let zeroMonths = [];

    Object.keys(months).forEach(m => {      
      if (valueArr[m] === 0 || valueArr[m] === null) { zeroMonths.push(m) }
    })

    zeroMonths.length === 12 ? hideRow = key : null

    let rowStyle, dataStyle = {}
    if (key === 'EBITDA') {
      rowStyle = { borderTop: '1px solid black' }
      dataStyle = { textAlign: 'right', paddingLeft: '0px' }
    } else if (key === hideRow) {
      rowStyle = {...rowStyle, display: 'none'}
    } else {
      dataStyle = { textAlign: 'right' }
    }

    // Glenn wants to add (Trailing 12 Months) to row labels in order to show trailing 12 months calculations are being used in the table.
    return (
      <React.Fragment key={Math.random(89)} >
        <tr style={rowStyle}>
          <td
            className={key === 'EBITDA' ? 'table-copy' : 'non-bold-table-copy'}
            style={dataStyle}
          >
            {trailing12MonthsKey ? `${key} (T12)` : key}
          </td>
          <td
            className={forecastClassNames + selectedMonth(0) + ' ' + backgroundChanger(0)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(0) : null}
          >
            {rowFormatter(valueArr[0], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(1) + ' ' + backgroundChanger(1)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(1) : null}
          >
            {rowFormatter(valueArr[1], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(2) + ' ' + backgroundChanger(2)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(2) : null}
          >
            {rowFormatter(valueArr[2], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(3) + ' ' + backgroundChanger(3)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(3) : null}
          >
            {rowFormatter(valueArr[3], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(4) + ' ' + backgroundChanger(4)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(4) : null}
          >
            {rowFormatter(valueArr[4], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(5) + ' ' + backgroundChanger(5)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(5) : null}
          >
            {rowFormatter(valueArr[5], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(6) + ' ' + backgroundChanger(6)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(6) : null}
          >
            {rowFormatter(valueArr[6], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(7) + ' ' + backgroundChanger(7)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(7) : null}
          >
            {rowFormatter(valueArr[7], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(8) + ' ' + backgroundChanger(8)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(8) : null}
          >
            {rowFormatter(valueArr[8], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(9) + ' ' + backgroundChanger(9)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(9) : null}
          >
            {rowFormatter(valueArr[9], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(10) + ' ' + backgroundChanger(10)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(10) : null}
          >
            {rowFormatter(valueArr[10], key)}
          </td>
          <td
            className={forecastClassNames + selectedMonth(11) + ' ' + backgroundChanger(11)}
            style={{ textAlign: 'right' }}
            onClick={props.page === 'forecast' ? () => props.setMonth(11) : null}
          >
            {rowFormatter(valueArr[11], key)}
          </td>
        </tr>
      </React.Fragment>
    )
  };

  // Adding Analysis Table to Forecast page
  if (props.page === "forecast") {
    let forecastPnlVals = props.pnlValueSet
    let forecastBalVals = props.balValueSet
    let newForecastPnlVals = {}
    let newForecastBalVals = {}
    let pnlObj = {}
    let balObj = {}
    let previousPnlKeys
    let previousBalKeys
    let previousYearPnlVals
    let previousYearBalVals
    let newPreviousYearPnlVals = {}
    let newPreviousYearBalVals = {}
    let previousPnlObj = {}
    let previousBalObj = {}

    let requiredKeysForAnalysisCalcs = [
      "accounts_payable",
      "accounts_receivable",
      "capital_stock",
      "current_portion_of_ltd",
      "depreciation_and_amortization",
      "gross_profit",
      "interest_and_other_expenses",
      "inventory",
      "labor_costs",
      "line_of_credit",
      "long_term_debt",
      "materials_costs",
      "operating_expenses_net_income",
      "operating_profit",
      "owner_distributions",
      "retained_earnings",
      "total_assets",
      "total_compensation",
      "total_costs",
      "total_current_assets",
      "total_current_liabilities",
      "total_operating_expenses",
      "total_other_costs_taxes_- federal & state",
      "total_revenue",
      "total_taxes_and_benefits"
    ]

    const formatForecastValuesIntoCalcs = (keys, newValues) => {
      let returnObj = {}

      for (let i = 0; i < keys.length; i++) {
        let monthValues = {}
        let key = keys[i]
        if (requiredKeysForAnalysisCalcs.includes(key)) {
          for (let j = 1; j <= 12; j++) {
            if (newValues[j] === undefined) {
              monthValues[j] = null
            } else {
              monthValues[j] = newValues[j][key]
            }
          }
          // { roa: { 1: 123, 2: 432, 3: 1234,... } }
          returnObj[keys[i]] = monthValues
        }
      }
      return returnObj
    }

    if (props.futureYear) {
      previousYearPnlVals = props.previousYearPnlSet
      previousYearBalVals = props.previousYearBalSet

      previousYearPnlVals.forEach((obj, i) => {
        newPreviousYearPnlVals[i + 1] = previousYearPnlVals[i]
      })

      previousYearBalVals.forEach((obj, i) => {
        newPreviousYearBalVals[i + 1] = previousYearBalVals[i]
      })

      previousPnlKeys = Object.keys(newPreviousYearPnlVals[1])
      previousBalKeys = Object.keys(newPreviousYearBalVals[1])

      previousPnlObj = formatForecastValuesIntoCalcs(previousPnlKeys, newPreviousYearPnlVals)
      previousBalObj = formatForecastValuesIntoCalcs(previousBalKeys, newPreviousYearBalVals)
      newPreviousYearCalcs = { ...newPreviousYearCalcs, ...previousBalObj, ...previousPnlObj }
      newPreviousYearCalcs.ebitda = calculateEBITDA(newPreviousYearCalcs)
    }

    forecastPnlVals.forEach((obj, i) => {
      newForecastPnlVals[i + 1] = forecastPnlVals[i]
    })

    forecastBalVals.forEach((obj, i) => {
      newForecastBalVals[i + 1] = forecastBalVals[i]
    })

    let pnlKeys = Object.keys(newForecastPnlVals[1])
    let balanceKeys = Object.keys(newForecastBalVals[1])

    pnlObj = formatForecastValuesIntoCalcs(pnlKeys, newForecastPnlVals)
    balObj = formatForecastValuesIntoCalcs(balanceKeys, newForecastBalVals)
    forecastValuesAndCalcs = { ...forecastValuesAndCalcs, ...balObj, ...pnlObj }
    forecastValuesAndCalcs.ebitda = calculateEBITDA(forecastValuesAndCalcs)

  }

  Object.entries(props.keys).forEach((entry, i) => {
    let key = entry[0]; // Ex. Current Ratio
    let value = entry[1]; // Ex. current_ratio
    // We need to get the sum of 12 trailing/previous month values for each of these keys.
    const trailing12MonthsKeys = ["Return on Assets (ROA)", "Return on Equity (ROE)", "Return on Capital Employed (ROCE)", "Asset Turnover Ratio", "Inventory Turnover Ratio", "Accounts Receivable Days", "Accounts Payable Days", "Senior Debt / EBITDA", "EBITDA / Annual Interest"];
    const trailing12MonthsKey = trailing12MonthsKeys.includes(key);
    let newMonthlyAnalysisCalculatedValues;
    let comparisonMonthlyAnalysisCalculatedValues;

    if (props.page === "forecast") {
      // Given net working capital ratio values are not correct and need to be recalculated
      if (key === "Current Ratio") {
        newMonthlyAnalysisCalculatedValues = ratioCalculations("total_current_assets", forecastValuesAndCalcs)
      }

      if (key === "Net Working Capital") {
        newMonthlyAnalysisCalculatedValues = netWorkingCapitalCalculation(forecastValuesAndCalcs)
      }

      if (key === "Net Working Capital Ratio") {
        newMonthlyAnalysisCalculatedValues = netWorkingCapitalRatioCalculation(forecastValuesAndCalcs)
      }

      if (key === "Gross Profit") {
        newMonthlyAnalysisCalculatedValues = ratioCalculations("gross_profit", forecastValuesAndCalcs)
      }

      if (key === "Operating Profit") {
        newMonthlyAnalysisCalculatedValues = ratioCalculations("operating_profit", forecastValuesAndCalcs)
      }

      if (trailing12MonthsKey || key === "Debt / Equity") {
        if (props.futureYear) {
          newMonthlyAnalysisCalculatedValues = trailing12MonthCalculations(key, forecastValuesAndCalcs, newPreviousYearCalcs)
        } else {
          newMonthlyAnalysisCalculatedValues = trailing12MonthCalculations(key, forecastValuesAndCalcs, previousYearCalcsUsed)
        }
      }

      if (key === "EBITDA") {
        newMonthlyAnalysisCalculatedValues = calculateEBITDA(forecastValuesAndCalcs)
      }
      rows.push(buildRow(key, value, trailing12MonthsKey, newMonthlyAnalysisCalculatedValues, i))
    }
  });

  return (
    <React.Fragment>
      <tr className="kfi-analysis-tr">
        <th style={props.title === "Liquidity" ? {} : { paddingTop: '20px' }} className={props.page === 'forecast' ? 'cashflow-row-name' : null}>
          {props.title}
        </th>
        <th
          className={forecastClassNames + selectedMonth(0) + ' ' + backgroundChanger(0)}
          onClick={props.page === 'forecast' ? () => props.setMonth(0) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(1) + ' ' + backgroundChanger(1)}
          onClick={props.page === 'forecast' ? () => props.setMonth(1) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(2) + ' ' + backgroundChanger(2)}
          onClick={props.page === 'forecast' ? () => props.setMonth(2) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(3) + ' ' + backgroundChanger(3)}
          onClick={props.page === 'forecast' ? () => props.setMonth(3) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(4) + ' ' + backgroundChanger(4)}
          onClick={props.page === 'forecast' ? () => props.setMonth(4) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(5) + ' ' + backgroundChanger(5)}
          onClick={props.page === 'forecast' ? () => props.setMonth(5) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(6) + ' ' + backgroundChanger(6)}
          onClick={props.page === 'forecast' ? () => props.setMonth(6) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(7) + ' ' + backgroundChanger(7)}
          onClick={props.page === 'forecast' ? () => props.setMonth(7) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(8) + ' ' + backgroundChanger(8)}
          onClick={props.page === 'forecast' ? () => props.setMonth(8) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(9) + ' ' + backgroundChanger(9)}
          onClick={props.page === 'forecast' ? () => props.setMonth(9) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(10) + ' ' + backgroundChanger(10)}
          onClick={props.page === 'forecast' ? () => props.setMonth(10) : null}
        ></th>
        <th
          className={forecastClassNames + selectedMonth(11) + ' ' + backgroundChanger(11)}
          onClick={props.page === 'forecast' ? () => props.setMonth(11) : null}
        ></th>
      </tr>
      {rows}
    </React.Fragment>
  )
};

export default MonthlyForecastKFITableBuilder;
