import React from 'react';
import { read } from 'xlsx';
import LoaderGraphic from '../utils/LoaderGraphic';
import Extractor from '../utils/Extractor';
import Header from './Header';
import ProgressBarModal from '../progress_bar/ProgressBarModal';

export default class EditFdu extends React.Component {
  constructor(props) {
    super(props);
    this.qbd = this.props.headers.qbd;
    // clean up headers
    delete this.props.headers['id'];
    delete this.props.headers[ 'created_at'];
    delete this.props.headers[ 'updated_at'];

    this.extractor = new Extractor({ headers: this.props.headers, clientId: this.props.client_id });

    this.fetchFile(this.props.attachment.url);

    this.state = {
      rows: null,
      extracting: false,
      headers: this.extractor.headerList(),
      headerError: null,
      progressBar: {},
      qbdYear: this.props.headers.qbd_year,
      editQbdYear: false,
    };

    this.state.options = this.setOptions(this.determineOptionDefaults());

  }

  yearArrMaker = () => {
    let currentYear = new Date().getFullYear()
    const years = [];
    const currentMonth = new Date().getMonth()
    let startYear = currentYear - 8;
    while (startYear <= currentYear) {
      years.unshift(startYear.toString());
      startYear++
    }
    return years;
  }

  determineOptionDefaults = () => {
    const elseOptionDefaultsNonYear = ['account_number', 'description', 'account_type', 'debit', 'credit']
    if (this.qbdYear) {
      return ['account_number', 'description', 'debit', 'credit'];
    } else {
      return (
        elseOptionDefaultsNonYear.concat(this.yearArrMaker())
      );
    }
  }

  handleComplete = (fdu) => {
    this.setState({
      progressBar: {
        finish: true
      }
    })
  }

  handleExtractError = (error) => {
    this.setState({
      progressBar: {
        error: true
      }
    })
  }

  handleHeaderError = (missingCols) => {
    this.setState({
      extracting: false,
      headerError: 'Still Missing Headers: ' + missingCols.join(', '),
    });
  }



  extract = (e) => {
    e.preventDefault();
    this.updateHeaders();

    this.extractor.fduId = this.props.id;

    this.extractor.postHeaders({
      headers: this.extractor.headers,
    })

    this.extractor.onHeaderError = this.handleHeaderError;
    this.extractor.onExtractError = this.handleExtractError;
    this.extractor.onComplete = this.handleComplete;

    if (this.extractor.extract()) {
      this.setState({ extracting: true })
    };
  }

  updateHeaders = () => {
    const { headers, qbdYear } = this.state;
    this.extractor.forEachHeader((key, header) => {
      if (headers.includes(key)) {
        header.column = headers.indexOf(key);
        header.found = true;
      } else {
        // header.found = false;
        header.column = null;
      }
    });
    if (qbdYear) {
      this.extractor.headers.qbd_year = qbdYear
      this.extractor.headers.years[qbdYear].found = true;
    }
  }

  fetchFile = (url) => {
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'arraybuffer';
    const comp = this;

    req.onload = function (e) {
      const data = new Uint8Array(req.response);
      const workbook = read(data, { type: 'array' });
      comp.extractor.workbookToSheet(workbook);

      comp.setState({
        rows: comp.extractor.sheet.slice(0, 10)
      })
    }

    req.send();
  }

  setOptions = (options) => {
    const selected = Object.keys(this.state.headers).map(key => this.state.headers[key]);
    return options.filter(option => !selected.includes(option))
  }

  buildHeaders = () => {
    const { headers } = this.state;
    const rowLength = this.findMaxRowLength(this.state.rows);
    const cols = [];
    for (let i = 0; i < rowLength; ++i) {
      cols.push(<Header
        header={headers[i]}
        options={this.state.options}
        handleSelection={this.handleSelection}
        removeHeader={this.removeHeader}
        column={i}
        key={i}
      />)
    }

    return (
      <tr>{cols}</tr>
    )
  }

  findMaxRowLength(rows) {
    const lengths = rows.map(row => row.length);
    return Math.max(...lengths);
  }

  handleSelection = (prevHeader, header, column) => {
    this.setState(prevState => {
      prevState.headers[column] = header;

      const index = prevState.options.indexOf(header);
      if (index > -1) {
        prevState.options.splice(index, 1);
      };

      if (prevHeader) {
        prevState.options.push(prevHeader);
      };

      return prevState;
    })
  }

  removeHeader = (header, column) => {
    this.setState(prevState => {
      prevState.headers[column] = null;
      prevState.options.push(header);
      return prevState;
    })
  }

  buildCols = (row, i) => {
    const cols = [];
    for (let ii = 0; ii != row.length; ++ii) {
      cols.push(<td key={ii} className='fdu-col'>{row[ii]}</td>)
    }

    return (
      <tr key={i}>{cols}</tr>
    )
  }

  buildRows = (rows) => {
    return (
      <tbody>
        {rows.map((row, i) => {
          return this.buildCols(row, i)
        })}
      </tbody>
    )
  }

  qdbYearInput = () => {
    if (this.state.editQbdYear) {
      return (
        <div className='qbd-year'>
          <h3>QBD Year:</h3>
          <form onSubmit={() => this.setState({ editQbdYear: false })}>
            <input type='text' value={this.state.qbdYear || ''} onChange={({ target }) => {
              this.setState({ qbdYear: target.value })
            }} />
          </form>
        </div>
      )
    } else {
      return (
        <div className='qbd-year'>
          <h3>QBD Year: {this.state.qbdYear || '(not found)'}</h3>
          <i className='edit-qbd-year fa fa-pencil' onClick={() => this.setState({ editQbdYear: true })} />
        </div>
      )
    }
  }

  renderOnFinish = () => {
    return (
      <div id='edit-fdu-pb-content'>
        <h2>Your File Has Been Uploaded</h2>
        <div className='fdu-links'>
          <a className='btn btn-primary' href={`/mapping_approvals/new?id=${this.props.id}`} >Map this File</a>
          <a className='btn btn-primary' href={this.props.url} >Manage Clients</a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id='edit-fdu'>
        {this.state.extracting ?
          <ProgressBarModal
            job={'ExtractWorker'}
            renderOnFinish={this.renderOnFinish}
            parentWillUpdate={true}
            updatedProps={this.state.progressBar}
            url={this.props.url}
            subscribed={this.extractor.subscribed}
          /> : null}
        {this.state.headerError ?
          <div className='alert alert-danger' role='alert'>
            {this.state.headerError}
            <button className='close' onClick={() => this.setState({ headerError: null })}> &times; </button>
          </div> : null}
        <div className='edit-fdu-header'>
          <div>
            <h3>Client: {this.props.client_name}</h3>
            <h3>File: {JSON.parse(this.props.attachment.filename)}</h3>
            <h3>Fiscal Year End: {this.props.fiscal_year_end}</h3>
            {this.qbd ? this.qdbYearInput() : null}
          </div>
          <form className='extract-options' onSubmit={this.extract} >
            <button className='btn btn-primary'>Extract File</button>
          </form>
        </div>
        {this.state.rows ?
          <table id='edit-fdu-table'>
            <thead>
              {this.buildHeaders(this.props.headers)}
            </thead>
            {this.buildRows(this.state.rows)}
          </table>
          : <LoaderGraphic />}
      </div>
    )
  }
}
