import React, { useState, useEffect, useRef } from 'react';
import workflows from './workflow_data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';

const Workflow = (props) => {
    const pageRefs = useRef();
    const [workflow, setWorkflow] = useState()

    useEffect(() => {
        let selectedWorkflow = workflows.find(w => w.id === Number(props.id))
        if (selectedWorkflow) {
            setWorkflow(selectedWorkflow)
        }
    }, [])

    const handleDisplays = (sectionTitle, header) => {
        const updatedSections = workflow.sections.map(s => {
            if (s.title === sectionTitle && header) {
                return {
                    ...s,
                    displayTasks: !s.displayTasks
                }
            } else {
                return s
            }
        });

        setWorkflow({
            ...workflow,
            sections: updatedSections
        });
    }

    if (workflow) {
        return (
            <div>
                <div id='sticky-topnav-left-btns'>
                    <ReactToPrint
                        trigger={() => <button id='printIcon' className='print-pdf-btn' aria-hidden='true'><FontAwesomeIcon icon={faFilePdf} /></button>}
                        content={() => pageRefs.current}
                        documentTitle={`Workflow ${workflow.title}.pdf`}
                    />
                </div>
                <div ref={pageRefs} className='workflow-container'>
                    <h1 className='workflow-title'>{workflow.title}</h1>
                    <ul className='workflow-list'>
                        {
                            workflow.sections.map((s, i) => {
                                return (
                                    <li className='workflow-section' key={`${i}-section`}>
                                        <h2 onClick={() => handleDisplays(s.title, true)}>{i + 1}. {s.title}</h2>
                                        {s.displayTasks && s.objective ? <h3><span>Objective:</span> {s.objective}</h3> : null}
                                        {s.displayTasks
                                            ? (
                                                <ul className='workflow-list-inner'>
                                                    {s.tasks.map((t, k) => {
                                                        if (t.description) {
                                                            return (
                                                                <li key={`${k}-task`}>
                                                                    <h4>{t.name}</h4>
                                                                    <div><FontAwesomeIcon icon={faSquare} /> {t.description}</div>
                                                                </li>
                                                            )
                                                        } else {
                                                            return (
                                                                <li>
                                                                    <h4>{t.name}</h4>
                                                                    <ul>
                                                                        {t.workflow.map((w, j) => {
                                                                            return <li><FontAwesomeIcon icon={faSquare} /> {j + 1}. {w}</li>
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                            )
                                            : null
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {
                        workflow.summary
                            ? <div className='workflow-summary'>{workflow.summary}</div>
                            : null
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div>Please select a workflow.</div>
        )
    }
}

export default Workflow;