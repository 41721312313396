import React, { useEffect, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import LoaderGraphic from '../utils/LoaderGraphic';
import Modal from 'react-modal';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
import { ToastContainer, toast } from 'react-toastify';
import UserPermissionsForm from '../UserPermissionsForm';

function initDatatable() {
  $('#manage-firm-unlocked-report-table').DataTable({
    searching: false,
    lengthChange: false
  });
};

function fduDatatable() {
  $('#admin-financial-data-uploads-table').DataTable({
    "order": [[4, 'desc']],
    "pageLength": 50,
    "lengthMenu": [25, 50, 100],
    "columnDefs": [
      { "orderable": true },
    ],
    searching: true,
    stateSave: true,
    processing: true,
    serverSide: true,
    ajax: { url: $(this).data('url') }
  });
}

const ManageFirm = (props) => {
  const filterOptions = props.filter_options
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    name: props.company.name,
    image: props.company.firm_logo.url,
    image_uploaded: false,
    scale: 1,
    error: ''
  })
  const [unlockedReports, setUnlockedReports] = useState(props.unlocked_reports)
  const [userModalIsOpen, setUserModalIsOpen] = useState(false)
  const [userModalData, setUserModalData] = useState({})
  const [userPermissions, setUserPermissions] = useState({
    isOpen: false,
    allCompanies: [],
    users: [],
    count: 0,
    excludedClients: [],
    includeAll: true,
    includeOptions: {},
    excludeOptions: {
      naics_code: [],
      partners: [],
      office: [],
      region: []
    },
    error: ''
  })

  const [userPermissionsTable, setUserPermissionsTable] = useState({
    page: 1,
    rowsPerPage: 25,
    sortOrder: { first_name: 'first_name', direction: 'asc' },
    searchText: ''
  })
  const [tab, setTab] = useState('firm-info')

  const editor = useRef(null);

  useEffect(() => {
    initDatatable();
    fduDatatable();
  }, [])

  const handleFormInputs = (type, value) => {
    setState(prevState => {
      let newData = { ...state, error: '' }
      newData[type] = value
      return newData
    })
  }

  const imageHandler = ({ target }) => {
    setState(prevState => {
      return {
        ...prevState,
        image: target.files[0],
        image_uploaded: true,
        error: ''
      }
    })
  }

  const scaleHandler = ({ target }) => {
    setState(prevState => {
      return {
        ...prevState,
        scale: parseFloat(target.value),
        error: ''
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData()
    formData.append("company[name]", state.name);

    if (state.image_uploaded) {
      const dataUrl = editor.current.getImage().toDataURL();
      const result = await fetch(dataUrl)
      const blob = await result.blob()
      if (blob) {
        formData.append('company[firm_logo]', blob, `${props.company.name}-logo.png`);
      } else {
        setIsLoading(false)
        setState(prevState => {
          return { ...prevState, error: 'Something went wrong please try again later.' }
        })
      }
    }

    const request = new XMLHttpRequest();
    request.open('POST', `/manage_firm/${props.company.id}`);
    request.send(formData);
    request.onerror = () => console.error();
    request.onload = () => location.reload();
  }

  const lockReport = async (id) => {
    const res = await fetch(`/lock_report/${id}`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.token,
        'Content-Type': 'application/json',
      }
    })
    if (!res.ok) {
      toast.error(`Sorry something went wrong.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      toast.success(`Report is now locked!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setUnlockedReports(prevState => {
        let newReports = unlockedReports.filter(u => u.id !== id)
        return newReports
      })
    }
  }

  const hrefBuilder = (subdomain, path) => {
    const domainSplit = document.location.host.split('.');
    domainSplit[0] = subdomain;
    return location.protocol + '//' + domainSplit.join('.') + path;
  };

  Modal.setAppElement(document.getElementById('edit-company-form'));
  const getMuiTheme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingRight: '20px',
            }
          },
          selectLabel: {
            fontSize: '12px',
            margin: 0
          },
          displayedRows: {
            fontSize: '12px',
            margin: 0
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important',
            display: 'flex',
            flexDirection: 'column-reverse',
            paddingLeft: '10px'
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            order: 2
          }
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            display: 'none'
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            order: 1
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '25px',
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '20px'
          }
        }
      },
      MUIDataTableResize: {
        styleOverrides: {
          root: {
            zIndex: 0,
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          contentWrapper: {
            justifyContent: 'center'
          },
          data: {
            fontSize: '14px',
            paddingBottom: 0,
            textTransform: 'none'
          },
          fixedHeader: {
            zIndex: 0,
          },
          toolButton: {
            padding: 'none',
            justifyContent: 'center'
          },
          tooltip: {
            fontSize: '14px',
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            zIndex: 1,
          },
          fixedLeft: {
            zIndex: 0,
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            zIndex: 1,
          },
          root: {
            fontSize: '14px',
            padding: '2px',
            textAlign: 'center',
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '3px',
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            zIndex: 0,
            minHeight: '20px',
            paddingLeft: '5px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#F5F5F5',
            },
            '&$hover:hover': {
              backgroundColor: '#BFE0AE',
            },
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '12px',
          },
          body1: {
            fontSize: '16px',
          },
          body2: {
            fontSize: '12px',
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '1.2em'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: '0.95em'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '1.1em'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '0.9em'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: '1em'
          }
        }
      }
    }
  });

  const openUserModal = (rowData) => {
    setUserModalIsOpen(true)
    setUserModalData(rowData)

    setUserPermissions(prevState => {
      let newExcludedClients = []
      let includeAllVal = true
      let newIncludeOptions = _.cloneDeep(filterOptions)
      let newExcludeOptions = rowData.excluded_client_options
      let allOptions = ['naics_code', 'partners', 'office', 'region']

      if (newExcludeOptions === null || rowData.excluded_clients.length === 0) {
        newExcludeOptions = {
          naics_code: [],
          partners: [],
          office: [],
          region: []
        }
      } else {
        newExcludedClients = rowData.excluded_clients.map(e => {
          let val = userPermissions.allCompanies.find(c => c.id == e)
          return val
        })
        includeAllVal = false
        allOptions.forEach(option => {
          let inclusions = []
          if (option === 'naics_code') {
            newIncludeOptions[option].forEach(i => {
              if (!newExcludeOptions['naics_code'].find(naics => naics[0] == i[0])) {
                inclusions.push(i)
              }
            })
          } else {
            newIncludeOptions[option].forEach(i => {
              if (!newExcludeOptions[option].includes(i)) {
                inclusions.push(i)
              }
            })
          }
          newIncludeOptions[option] = inclusions
        })
      }

      return {
        ...prevState,
        excludedClients: newExcludedClients,
        includeAll: includeAllVal,
        includeOptions: newIncludeOptions,
        excludeOptions: newExcludeOptions
      }
    })
  }

  const closeUserModal = () => {
    setUserModalIsOpen(false);
  };

  const deleteUser = async (id) => {
    let changes = confirm('Are you sure you want to delete this user?');
    if (changes === true) {
      const res = await fetch(`/delete_user/?id=${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })

      if (!res.ok) {
        toast.error('Sorry please try again later.')
      } else {
        toast.success('User had been deleted.')
      }
    }
  }

  const handleFirmAdminUser = async (id, userType) => {
    let changes = confirm('Are you sure you want to change this user?');
    if (userType !== 'FirmAdmin') {
      if (changes === true) {
        const res = await fetch(`/promote_admin/?id=${id}`, {
          method: 'GET',
          headers: {
            'X-CSRF-Token': window.token,
            'Content-Type': 'application/json',
          }
        })
        if (!res.ok) {
          toast.error('Sorry please try again later.')
        } else {
          toast.success('User had been promoted to Firm Admin.')
          setUserPermissions(prevState => {
            let newUsers = userPermissions.users.map(u => {
              if (u.id === id) {
                u.type = 'FirmAdmin'
                return u
              } else {
                return u
              }
            })
            return {
              ...prevState,
              users: newUsers
            }
          })
        }
      }
    } else {
      if (changes === true) {
        const res = await fetch(`/demote_admin/?id=${id}`, {
          method: 'GET',
          headers: {
            'X-CSRF-Token': window.token,
            'Content-Type': 'application/json',
          }
        })
        if (!res.ok) {
          toast.error('Sorry please try again later.')
        } else {
          toast.success('User had been demoted.')
          setUserPermissions(prevState => {
            let newUsers = userPermissions.users.map(u => {
              if (u.id === id) {
                u.type = 'Client'
                return u
              } else {
                return u
              }
            })

            return {
              ...prevState,
              users: newUsers
            }
          })
        }
      }
    }
  }


  const userColumns = [
    // column 0
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    // column 2
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, i) => {
          let client = userPermissions.users.find(user => user.id === value);
          if (client) {
            let c = i['rowIndex'] === 0 ? 'table-btn first-table-btn' : 'table-btn'
            return (
              <button className={c} onClick={() => openUserModal(client)}>
                Edit Permissions
              </button>
            );
          }
        },
      },
    },
    // column 1
    {
      name: 'type',
      label: 'User Type',
      options: {
        filter: false,
        sort: false,
      },
    },
    // column 2
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
      },
    },
    //column 3
    {
      name: 'created_at',
      label: 'Created',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) { return moment(value).calendar() }
        }
      }
    },
    {
      name: 'invitation_accepted_at',
      label: 'Invitation Accepted',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) { return moment(value).calendar() }
        }
      }
    },
    {
      name: 'current_sign_in_at',
      label: 'Last Active',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) { return moment(value).calendar() }
        }
      }
    },
    {
      name: 'excluded_clients',
      label: 'excluded_clients',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'excluded_client_options',
      label: 'excluded_client_options',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'id',
      label: 'Delete',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, i) => {
          return (
            <a className='fa fa-trash-o' onClick={() => deleteUser(value)}></a>
          );
        }
      },
    },
    {
      name: 'id',
      label: 'Promote to Admin',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, i) => {
          let user = userPermissions.users.find(user => user.id === value);
          if (user) {
            let words = user.type === 'FirmAdmin' ? 'Remove Admin' : 'Promote Admin'
            return (
              <a onClick={() => handleFirmAdminUser(value, user.type)}>{words}</a>
            );
          }
        }
      },
    }
  ]

  const handleSearch = async (page, text) => {
    setIsLoading(true)
    let queryText = `/api/v1/user_permissions/?page=${page}&rows=${userPermissionsTable.rowsPerPage}`
    if (text) {
      queryText += `&search=${text}`
    }
    const filtered = await fetch(queryText)
    const filteredRes = await filtered.json()
    if (page === 1 && filteredRes.users_count < userPermissionsTable.rowsPerPage) {
      setUserPermissionsTable(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setUserPermissions((prevState) => {
        return { ...prevState, users: filteredRes.users, count: filteredRes.users_count }
      })
    } else {
      setUserPermissionsTable(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setUserPermissions((prevState) => {
        return { ...prevState, users: filteredRes.users, count: filteredRes.users_count }
      })
    }
    setIsLoading(false)
  }

  const changePage = async (page, rowsPerPage) => {
    setIsLoading(true)
    const filtered = await fetch(`/api/v1/user_permissions/?page=${page}&rows=${rowsPerPage}&sort=${userPermissionsTable.sortOrder.first_name}&direction=${userPermissionsTable.sortOrder.direction}&search=${userPermissionsTable.searchText}`)
    const filteredRes = await filtered.json()
    if (page === 1 && filteredRes.users_count < rowsPerPage) {
      setUserPermissionsTable(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setUserPermissions((prevState) => {
        return { ...prevState, allCompanies: filteredRes.all_companies, users: filteredRes.users, count: filteredRes.users_count }
      })
    } else {
      setUserPermissionsTable(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setUserPermissions((prevState) => {
        return { ...prevState, allCompanies: filteredRes.all_companies, users: filteredRes.users, count: filteredRes.users_count }
      })
    }
    setIsLoading(false)
  }

  const userOptions = {
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    responsive: 'standard',
    resizableColumns: true,
    rowsPerPage: userPermissionsTable.rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    count: userPermissions['count'],
    confirmFilters: true,
    serverSide: true,
    searchPlaceholder: 'Search by User Name',
    customSearchRender: debounceSearchRender(1000),
    onTableChange: (action, userPermissionsTable) => {
      switch (action) {
        case 'changePage':
          changePage(userPermissionsTable.page + 1, userPermissionsTable.rowsPerPage);
          break;
        case 'changeRowsPerPage':
          setUserPermissionsTable(prevState => {
            return { ...prevState, rowsPerPage: userPermissionsTable.rowsPerPage }
          })
          changePage(userPermissionsTable.page + 1, userPermissionsTable.rowsPerPage);
          break;
        case 'search':
          setUserPermissionsTable(prevState => {
            return { ...prevState, searchText: userPermissionsTable.searchText }
          })
          handleSearch(userPermissionsTable.page + 1, userPermissionsTable.searchText)
          break;
        default:
          break;
      }
    }
  }

  const handleUserPermissions = async (tab) => {
    setTab('users')
    const res = await fetch(`/api/v1/user_permissions/?page=${userPermissionsTable.page}&rows=${userPermissionsTable.rowsPerPage}&sort=${userPermissionsTable.sortOrder.name}&direction=${userPermissionsTable.sortOrder.direction}&search=${userPermissionsTable.searchText}`, {
      headers: {
        'X-CSRF-Token': window.token,
      }
    })
    const json = await res.json();
    setUserPermissions(prevState => {
      return {
        ...prevState,
        allCompanies: json.all_companies,
        users: json.users,
        count: json.users_count,
      }
    })
  }

  const updateUserPermissions = async (e) => {
    e.preventDefault()
    let companyIds = userPermissions.excludedClients.map(c => c.id)
    try {
      const res = await fetch(`/api/v1/users/${userModalData.id}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          excluded_clients: companyIds,
          excluded_client_options: userPermissions.excludeOptions
        }),
      })
      const resJson = await res.json();

      if (resJson.ok) {
        toast.success('User Permissions Updated!')
      } else if (resJson.error) {
        toast.error(resJson.error)
        setFormData((prevState) => {
          return {
            ...prevState,
            error: resJson.error
          }
        })
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        theme='colored'
        pauseOnHover
      />
      <h1>{`Manage ${props.company.name}`}</h1>
      <ul id='manageFirmTabs' className='nav nav-tabs' role='tablist'>
        <li className={tab === 'firm-info' ? 'nav-item active' : 'nav-item'} role='presentation' onClick={() => setTab('firm-info')}>
          <a href='#firm-form' aria-controls='firm-form' role='tab' data-toggle='tab'>Firm Info</a>
        </li>
        <li className={tab === 'report-credits' ? 'nav-item active' : 'nav-item'} role='presentation' onClick={() => setTab('report-credits')}>
          <a href='#report-credits' aria-controls='report-credits' role='tab' data-toggle='tab'>Report Credits</a>
        </li>
        <li className={tab === 'users' ? 'nav-item active' : 'nav-item'} role='presentation' onClick={() => handleUserPermissions()}>
          <a href='#users-table' aria-controls='users-table' role='tab' data-toggle='tab'>Users</a>
        </li>
        <li className={tab === 'unlcoked-reports' ? 'nav-item active' : 'nav-item'} role='presentation' onClick={() => setTab('unlocked-reports')}>
          <a href='#unlocked-reports-table' aria-controls='unlocked-reports-table' role='tab' data-toggle='tab'>Unlocked Reports</a>
        </li>
        <li className={tab === 'fdus' ? 'nav-item active' : 'nav-item'} role='presentation' onClick={() => setTab('fdus')}>
          <a href='#financial-data-uploads-table' aria-controls='financial-uploads-table' role='tab' data-toggle='tab'>Uploads</a>
        </li>
      </ul>
      <div className='tab-content'>
        {tab === 'firm-info'
          ? (<div id='firm-form' role='tabpanel'>
            <form onSubmit={(e) => handleSubmit(e)}>
              <p className='error-message'>{props.company.error ? props.company.error : ''}</p>
              <div>
                <label htmlFor='company-name'>Firm Name</label>
                <input type='text' name='company-name' value={state.name} onChange={(e) => handleFormInputs('name', e.target.value)} required />
              </div>

              <div>
                <label htmlFor='file'>Firm Logo</label>
                <input type='file' name='file' onChange={(e) => imageHandler(e)} />
                <br />
                <p className='text-muted'>Logo will be used on printed reports. White background logos work best. (JPEG, JPG, or PNG)</p>
                {state.image
                  ? <div>
                    <AvatarEditor
                      className='firm-logo-window'
                      image={state.image}
                      crossOrigin={'anonymous'}
                      ref={editor}
                      width={500}
                      height={350}
                      border={50}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={state.scale}
                      rotate={0}
                    />
                    <input
                      id='manage-firm-slider'
                      className='manage-slider'
                      type='range'
                      min='.25'
                      max='1.75'
                      step='0.01'
                      value={state.scale}
                      onChange={(e) => scaleHandler(e)}
                    />
                  </div>
                  : null
                }
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className='btn btn-primary' type='submit' style={{ marginRight: '10px' }}>
                  Submit
                </button>
                {isLoading ? <LoaderGraphic /> : null}
              </div>
            </form>
          </div>)
          : null
        }
        {tab === 'report-credits'
          ? (<div id='report-credits' role='tabpanel'>
            <h2>{`Remaing Report Credits: ${props.company.report_credits}`}</h2>
            {props.company.no_funding_source ? null : <a className='btn btn-primary funding-btn' href='/funding_source' target='_blank'>$ Add Funding Source</a>}
          </div>)
          : null
        }
        {tab === 'users'
          ? (<>
            <div id='users-table' role='tabpanel'>
              {
                userPermissions.users.length > 0
                  ? <ThemeProvider theme={getMuiTheme}>
                    <MUIDataTable data={userPermissions.users} columns={userColumns} options={userOptions} />
                  </ThemeProvider>
                  : <p>Firm users will appear here.</p>
              }
            </div>
            <Modal
              className={{
                base: 'user-permission-modal-content',
                afterOpen: 'user-permission-modal-content_after-open',
                beforeClose: 'user-permission-modal-content_before-close',
              }}
              overlayClassName={{
                base: 'overlay-base',
                afterOpen: 'overlay-base_after-open',
                beforeClose: 'overlay-base_before-close',
              }}
              isOpen={userModalIsOpen}
              onRequestClose={closeUserModal}
              shouldCloseOnOverlayClick={true}
              closeTimeoutMS={0}
              contentLabel='User Permissions Modal'
            >
              <form onSubmit={(e) => updateUserPermissions(e)}>
                <UserPermissionsForm
                  closeUserModal={closeUserModal}
                  modal={true}
                  allFilterOptions={filterOptions}
                  userPermissions={userPermissions}
                  userModalData={userModalData}
                  setUserPermissions={setUserPermissions}
                />
                <p className='error-message'>{userPermissions.error}</p>
                <div className='form-actions'>
                  <button className='btn btn-primary' type='submit'>Update User Permissions</button>
                </div>
              </form>
            </Modal>
          </>)
          : null
        }
        {tab === 'unlocked-reports'
          ? (<div id='unlocked-reports-table' role='tabpanel'>
            <h2>Unlocked Reports</h2>
            <table id='manage-firm-unlocked-report-table' className='table table-striped'>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Unlocked By</th>
                  <th>Unlocked On</th>
                  {props.user_type === 'Admin' ? <th>Lock</th> : null}
                </tr>
              </thead>
              <tbody>
                {unlockedReports.map(u => {
                  let reportLink = hrefBuilder(u.purchased_company, '/reports')
                  let createdAt = moment(u.created_at).calendar()
                  return (
                    <tr>
                      <td>
                        <a href={reportLink}>{u.purchased_company}</a>
                      </td>
                      <td>{u.email}</td>
                      <td>{createdAt}</td>
                      {props.user_type === 'Admin' ? <td><a onClick={() => lockReport(u.id)} >Lock</a></td> : null}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>)
          : null
        }
        {tab === 'fdus'
          ? (<div id='financial-data-uploads-table' role='tabpanel'>
            <h2>Uploads</h2>
            <table id='admin-financial-data-uploads-table' className='table table-striped hover' style={{ 'width': '100%' }}>
              <thead>
                <tr>
                  <th>Firm</th>
                  <th>Client ID</th>
                  <th>File</th>
                  <th>Status</th>
                  <th>Uploaded</th>
                  <th>User</th>
                  <th>Notes</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {props.fdus.map(fdu => {
                  let companyReport = fdu[1] ? <a href={fdu[1]['reports_url']}>{fdu[1]['company_name']}</a> : ''
                  let file = fdu[2] ? <a href={fdu[2]['file_url']}>{fdu[2]['filename']}</a> : ''
                  let notes = fdu[6] === 'Check' ? '&#10003;' : fdu[6] === 'Review' ? 'Review' : ''
                  let viewFile = fdu[7] ? <a href={fdu[7]} className='fa fa-eye directory'></a> : ''
                  return (
                    <tr>
                      <td>{fdu[0]}</td>
                      <td>{companyReport}</td>
                      <td>{file}</td>
                      <td>{fdu[3]}</td>
                      <td>{fdu[4]}</td>
                      <td>{fdu[5]}</td>
                      <td>{notes}</td>
                      <td>{viewFile}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>)
          : null
        }
      </div>
    </div>
  )
}

export default ManageFirm;
