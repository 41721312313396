import React, { useState, useEffect } from 'react';
import LoaderGraphic from '../../utils/LoaderGraphic';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'

const RecentFdusInfo = (props) => {
  const [tableState, setTableState] = useState({ page: 1, rowsPerPage: 25 })
  const [tableData, setTableData] = useState({ fduData: [], fduCount: 25 })
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ type: null, message: null });
  const [form, setForm] = useState([]);
  useEffect(() => {
    setIsLoading(true)
    setTableData({
      fduData: props['recent_fdus_info'],
      fduCount: props['fdu_count']
    })
    handleForm(props['recent_fdus_info'])
    setIsLoading(false)
  }, [])

  const LoadingOverlay = () => {
    return (
      <div className='client-overlay'>
        <LoaderGraphic />
      </div>
    );
  };

  const handleForm = (fduData) => {
    setForm(() => {
      let final = [...form];
      fduData.forEach((fdu, i) => {
        if (fdu.status === 'Completed') {
          let finalObj = {}
          let naicsFound = final.find(f => f['NAICS'] === fdu.naics_code)
          if (naicsFound) {
            let inputMonths = fdu.monthly || !fdu.monthly && naicsFound['inputYears']['months'] === '0-12' ? naicsFound['inputYears']['months'] = '0-12' : naicsFound['inputYears']['months'] = '0'
            let naicsFoundYears = naicsFound['inputYears'].map(y => y.year)
            naicsFound['inputYears'].forEach(y => y.months = inputMonths);
            fdu.years.forEach(newYear => {
              let yearExists = naicsFoundYears.includes(newYear)
              if (yearExists === false) {
                let finalFormObj = { id: Math.random(4), year: newYear, months: inputMonths, reports: { 'money': true }, datatype: 'private' }
                if (fdu.naics_code.startsWith('62')) {
                  finalFormObj['reports']['include_in_doctors_aggregate'] = true
                  finalFormObj['reports']['nschbc'] = true
                }
                naicsFound['inputYears'].push(finalFormObj)
              }
            })

          } else {
            finalObj['id'] = i
            finalObj['NAICS'] = fdu.naics_code
            let inputMonths = fdu.monthly ? '0-12' : '0'
            let inputYears = fdu.years.map((y, j) => {
              let finalFormObj = { id: j, year: y, months: inputMonths, reports: { 'money': true }, datatype: 'private' }
              if (fdu.naics_code.startsWith('62')) {
                finalFormObj['reports']['include_in_doctors_aggregate'] = true
                finalFormObj['reports']['nschbc'] = true
              }
              return finalFormObj;
            })
            finalObj['inputYears'] = inputYears;
            final.push(finalObj)
          }
        }
      })
      return final;
    })
  }

  const handlePageRowChange = async (page, rowsPerPage) => {
    setIsLoading(true)
    try {
      const res = await fetch(`/admin/ops/fdus_info/?page=${page}&rows=${rowsPerPage}`, {
        headers: {
          'X-CSRF-Token': window.token,
        }
      })
      const jsonRes = await res.json()

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        setTableState(prevState => {
          return { page: page, rowsPerPage: rowsPerPage }
        })
        setTableData((prevState) => {
          return { ...prevState, fduData: jsonRes['recent_fdus_info'] }
        })
        handleForm(jsonRes['recent_fdus_info'])
        setIsLoading(false)
      }
    } catch (error) {
      setAlert({ type: 'error', message: 'Sorry something went wrong. Please try again later.' })
      setIsLoading(false)
    }
  };

  const handleSubmitCalcs = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const res = await fetch('/admin/naicses/calculate_custom', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ form_data: form }),
      })
      if (!res.ok) {
        let resJson = await res.json()
        throw new Error(resJson['message']);
      } else {
        setIsLoading(false)
        setAlert({ type: 'success', message: 'Working on the calcs!' })
      }

    } catch (error) {
      setAlert({ type: 'error', message: error.message })
      setIsLoading(false)
    }
  }

  const handleFormEdit = (inputType, naicsItemId, value, inputId = null) => {
    let newFormInputs = [...form];
    let itemIndex = newFormInputs.findIndex(item => item.id === naicsItemId);

    if (inputType === 'naics') {
      newFormInputs[itemIndex]['NAICS'] = value;
      setForm(newFormInputs)
    }

    if (inputType === 'years') {
      let findYearIndex = newFormInputs[itemIndex]['inputYears'].findIndex(y => y.id === inputId);
      newFormInputs[itemIndex]['inputYears'][findYearIndex].year = value;
      setForm(newFormInputs)
    }

    if (inputType === 'months') {
      let findMonthIndex = newFormInputs[itemIndex]['inputYears'].findIndex(y => y.id === inputId);
      newFormInputs[itemIndex]['inputYears'][findMonthIndex].months = value
      setForm(newFormInputs)
    }

    if (inputType === 'reports') {
      let findReportIndex = newFormInputs[itemIndex]['inputYears'].findIndex(y => y.id === inputId);
      newFormInputs[itemIndex]['inputYears'][findReportIndex]['reports'][value] = !newFormInputs[itemIndex]['inputYears'][findReportIndex]['reports'][value]
      setForm(newFormInputs)
    }

    if (inputType === 'datatype') {
      let findReportIndex = newFormInputs[itemIndex]['inputYears'].findIndex(y => y.id === inputId);
      newFormInputs[itemIndex]['inputYears'][findReportIndex]['datatype'] = value
      setForm(newFormInputs)
    }
  }

  const removeYearInput = (itemId, yearId, yearIndex) => {
    let newFormInputs = [...form];
    let itemIndex = newFormInputs.findIndex(item => item.id === itemId);
    let newInputYears = newFormInputs[itemIndex]['inputYears'].filter(year => year.id !== yearId);
    newFormInputs[itemIndex]['inputYears'] = newInputYears;
    setForm(newFormInputs)
  }

  const addYearInput = (itemId) => {
    let newFormInputs = [...form];
    let itemIndex = newFormInputs.findIndex(item => item.id === itemId);
    newFormInputs[itemIndex]['inputYears'].push({ id: Math.random(4), year: '20', months: '0', reports: { 'money': true, 'include_in_doctors_aggregate': false }, datatype: 'private' });
    setForm(newFormInputs)
  }

  const removeNaicsInput = (itemId) => {
    let newFormInputs = form.filter(item => item.id !== itemId);
    setForm(newFormInputs);
  }

  const addNaicsInput = () => {
    let newFormInputs = [...form];
    newFormInputs.push({ id: Math.random(8), NAICS: '', inputYears: [{ id: Math.random(4), year: '20', months: '0', reports: { 'money': true, 'include_in_doctors_aggregate': false }, datatype: 'private' }] })
    setForm(newFormInputs)
  }

  const renderNaicsFormInputs = () => {

    let naicsForm = form.map((naicsItem) => {
      return (
        <div className='naics-form-section'>
          <div className='naics-input-section'>
            <label>NAICS</label>
            <input type='text' value={naicsItem['NAICS']} onChange={(e) => handleFormEdit('naics', naicsItem['id'], e.target.value)} />
            <button type='button' onClick={() => addYearInput(naicsItem['id'])}><FontAwesomeIcon icon={faPlusSquare} /> Add Year</button>
            <button type='button' onClick={() => removeNaicsInput(naicsItem['id'])}><i className='fa fa-times'></i> Remove NAICS Code</button>
          </div>
          <div className='year-inputs-container'>
            {naicsItem['inputYears'].map((input, j) => {
              let randomId = Math.random(3);
              return (
                <div className='year-inputs-section'>
                  <label htmlFor={`year-${randomId}`}>Year</label>
                  <input id={`year-${randomId}`} type='text' value={input['year']} onChange={(e) => handleFormEdit('years', naicsItem['id'], e.target.value, input['id'])} />
                  <label htmlFor={`months-${randomId}`}>Months</label>
                  <input id={`months-${randomId}`} type='text' value={input['months']} onChange={(e) => handleFormEdit('months', naicsItem['id'], e.target.value, input['id'])} />
                  Reports:
                  <label htmlFor={`money-${randomId}`}>Money</label>
                  <input type='checkbox' id={`money-${randomId}`} name='money' value='money' checked={input['reports']['money']} onChange={(e) => handleFormEdit('reports', naicsItem['id'], e.target.value, input['id'])} />
                  <label htmlFor={`include_in_doctors_aggregate-${randomId}`}>Doctors Aggregate</label>
                  <input type='checkbox' id={`include_in_doctors_aggregate-${randomId}`} name='include_in_doctors_aggregate' value='include_in_doctors_aggregate' checked={input['reports']['include_in_doctors_aggregate']} onChange={(e) => handleFormEdit('reports', naicsItem['id'], e.target.value, input['id'])} />
                  <label htmlFor={`nschbc-${randomId}`}>NSCHBC</label>
                  <input type='checkbox' id={`nschbc-${randomId}`} name='nschbc' value='nschbc' checked={input['reports']['nschbc']} onChange={(e) => handleFormEdit('reports', naicsItem['id'], e.target.value, input['id'])} />
                  <label htmlFor={`datatype-${randomId}`}>Data Type: </label>
                  <select value={input['datatype']} onChange={(e) => handleFormEdit('datatype', naicsItem['id'], e.target.value, input['id'])}>
                    <option value='All'>All</option>
                    <option value='private'>Private</option>
                    <option value='public'>Public</option>
                  </select>
                  <button type='button' onClick={() => removeYearInput(naicsItem['id'], input['id'], j)}><i className='fa fa-times'></i></button>
                </div>
              )
            })}
          </div>
        </div>
      )

    })

    return (
      naicsForm
    )
  }

  const getMuiTheme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingRight: '20px',
            }
          },
          selectLabel: {
            fontSize: '12px',
            margin: 0
          },
          displayedRows: {
            fontSize: '12px',
            margin: 0
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column-reverse',
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            order: 2
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            order: 1,
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '25px',
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '20px'
          }
        }
      },
      MUIDataTableResize: {
        styleOverrides: {
          root: {
            zIndex: 0,
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          fixedHeader: {
            zIndex: 0,
          },
          toolButton: {
            justifyContent: 'center'
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            zIndex: 0,
          },
          fixedLeft: {
            zIndex: 0,
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            zIndex: 1,
          },
          root: {
            fontSize: '14px',
            padding: '2px',
            textAlign: 'center',
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            zIndex: 0,
            minHeight: '20px',
            paddingLeft: '5px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#F5F5F5',
            },
            '&$hover:hover': {
              backgroundColor: '#BFE0AE',
            },
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '12px',
          },
          body2: {
            fontSize: '12px',
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '1.2em'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: '0.95em'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '1.1em'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '0.9em'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: '1em'
          }
        }
      }
    }
  });

  const columns = [
    {
      name: 'company',
      label: 'Company',
      options: {
        filter: false,
        sort: false
      },
    },
    {
      name: 'naics_code',
      label: 'NAICS',
      options: {
        filter: false,
        sort: false
      },
    },
    {
      name: 'file_name',
      label: 'File',
      options: {
        filter: false,
        sort: false
      },
    },
    {
      name: 'monthly',
      label: 'Monthly',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: false,
        customBodyRender: (value) => {
          return value ? 'True' : 'False';
        },
      },
    },
    {
      name: 'years',
      label: 'Years',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let years = value.map(year => `${year} `)
          return years;
        }
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'uploaded_at',
      label: 'Uploaded',
      options: {
        filter: false,
        sort: false,
      },
    }
  ];

  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    filter: false,
    resizableColumns: true,
    rowsPerPage: tableState.rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    count: tableData.fduCount,
    confirmFilters: true,
    serverSide: true,
    search: false,
    searchPlaceholder: 'Search by Client ID/Name',
    customSearchRender: debounceSearchRender(1500),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handlePageRowChange(tableState.page + 1, tableState.rowsPerPage);
          break;
        case 'changeRowsPerPage':
          setTableState(prevState => {
            return { ...prevState, rowsPerPage: tableState.rowsPerPage }
          })
          handlePageRowChange(tableState.page + 1, tableState.rowsPerPage);
          break;
        default:
          break;
      }
    }
  };

  const renderAlertPopUp = () => {
    if (alert.type === 'success') {
      return (
        <div className='alert alert-success alert-div' role='alert'>
          {alert.message}
          <button onClick={() => setAlert({ type: null, message: null })}><i className='fa fa-times'></i></button>
        </div>
      )
    }
    if (alert.type === 'error') {
      return (
        <div className='alert alert-danger alert-div' role='alert'>
          {alert.message}
          <button onClick={() => setAlert({ type: null, message: null })}><i className='fa fa-times'></i></button>
        </div>
      )
    }
  }

  return (
    <div>
      {alert.message ? renderAlertPopUp() : null}
      <h1 className='text-center'>Recent Uploads Info and Custom Calcs</h1>
      <div className='custom-calcs-info-and-form'>
        <div className='custom-calcs-info'>
          <p>Customize Calcs for NAICS Codes by Years, Months, and Reports. At the moment, make sure to remember what you ran. Write it down somewhere!</p>
          <p>For Months, 0 means year. For example, 0-12 will run calcs for the year and all 12 months. Just use 0 if you want to run the year or any number for a specific month. Use a number range to run more months.</p>
          <p>The NAICS codes will get the child and parent codes and act just like calculate up and down for each code.</p>
          <p>The form will look any <b>Completed</b> files and grab their NAICS code and extracted years as you click to view more files in the table below.</p>
        </div>
        <form className='naics-form' onSubmit={(e) => handleSubmitCalcs(e)}>
          {renderNaicsFormInputs()}
          <div className='form-bottom-btns'>
            <button type='button' className='green-border' onClick={addNaicsInput}>
              <FontAwesomeIcon icon={faPlusSquare} /> Add New NAICS Code
            </button>
            <button type='submit' className='green-border'>
              CALCULATE AGGREGATES
            </button>
          </div>
        </form>
      </div>
      <div>
        {isLoading && <LoadingOverlay />}
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable data={tableData.fduData} columns={columns} options={options} />
        </ThemeProvider>
      </div>
    </div>
  )
}

export default RecentFdusInfo;