import React, { useState, useEffect } from 'react';
// import Modal from 'react-modal';
// import Filler from '../progress_bar/Filler';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { chart } from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import HC_exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { SurveyCharts, SurveyRadioAnswers, getChartColors, MoneySurvey, PeopleSurvey, CustomerSurvey, FutureSurvey } from './SurveyHelper';
import { capitalize } from '../utils';
highchartsMore(Highcharts);
HC_exporting(Highcharts);
offlineExporting(Highcharts);

const SurveyResponses = (props) => {

    const buildStackedBarChartData = (chartInfo) => {
        let optionKeys = Object.keys(chartInfo['answerData']);
        let colors = getChartColors(props.pageTitle, optionKeys.length)
        let formattedData = null;
        let surveyKeys = [];
        let surveyQuestions = [];
        chartInfo['questions'].forEach(q => {
            surveyKeys.push(q.key)
            surveyQuestions.push(q.questionTitle)
        })
        optionKeys = Object.keys(chartInfo['answerData'])
        formattedData = optionKeys.reverse().map((option, i) => {
            return {
                color: colors[i],
                data: [],
                name: option,
            }
        })

        surveyKeys.forEach((key) => {
            let results = props.surveyResults['filtered_results'][key];
            let resultCount = results ? Object.values(results).reduce((a, b) => a + b, 0) : null;
            if (resultCount && optionKeys.length === 5) {
                results['1'] ? formattedData[4]['data'].push(results['1'] / resultCount) : formattedData[4]['data'].push(0)
                results['2'] ? formattedData[3]['data'].push(results['2'] / resultCount) : formattedData[3]['data'].push(0)
                results['3'] ? formattedData[2]['data'].push(results['3'] / resultCount) : formattedData[2]['data'].push(0)
                results['4'] ? formattedData[1]['data'].push(results['4'] / resultCount) : formattedData[1]['data'].push(0)
                results['5'] ? formattedData[0]['data'].push(results['5'] / resultCount) : formattedData[0]['data'].push(0)
            }

            if (resultCount && optionKeys.length === 4) {
                results['1'] ? formattedData[3]['data'].push(results['1'] / resultCount) : formattedData[3]['data'].push(0)
                results['2'] ? formattedData[2]['data'].push(results['2'] / resultCount) : formattedData[2]['data'].push(0)
                results['3'] ? formattedData[1]['data'].push(results['3'] / resultCount) : formattedData[1]['data'].push(0)
                results['4'] ? formattedData[0]['data'].push(results['4'] / resultCount) : formattedData[0]['data'].push(0)
            }
        })
        // Not able to set specific width between bars/columns for Highcharts
        // Create some height space by calculating how many keys are present
        let chartHeight = 70 * Object.keys(surveyKeys).length
        let finalChartHeight = chartHeight < 300 ? 300 : chartHeight;

        return {
            chart: {
                type: 'bar',
                marginRight: 25,
                height: finalChartHeight,
                style: {
                    fontFamily: ["Lucida Grande", "Lucida Sans Unicode", "Arial", "sans-serif"]
                }
            },
            title: {
                text: chartInfo['questionTitle'] ? chartInfo['questionTitle'] : chartInfo['section'],
                style: {
                    fontSize: '18px',
                    fontWeight: 'normal'
                }
            },
            subtitle: {
                text: chartInfo['subtitle'],
                style: {
                    fontSize: '12px'
                }
            },
            xAxis: {
                categories: surveyQuestions,
                labels: {
                    step: 1,
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Percent',
                    style: {
                        fontSize: '12px'
                    }
                },
                labels: {
                    useHTML: true,
                    formatter: function () {
                        let point = `${this.value}%`
                        return point;
                    },
                    step: 1,
                },
            },
            tooltip: {
                enabled: false,
                style: {
                    fontSize: '12px'
                }
            },
            legend: {
                reversed: true,
                itemStyle: {
                    fontSize: '12px'
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                allowHTML: true,
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            plotOptions: {
                series: {
                    stacking: 'percent',
                },
                bar: {
                    dataLabels: {
                        enabled: true,
                        inside: true,
                        formatter: function () {
                            let percentValue = this.y ? `${Highcharts.numberFormat((this.y * 100), 0)}%` : null;
                            return percentValue;
                        },
                        style: {
                            fontSize: '12px'
                        }
                    },
                    pointWidth: 25
                },
            },
            series: formattedData
        }
    }

    const buildSurveyAnswerDisplay = (chart) => {
        if (chart.chartType === 'stackedBar') {
            let surveyKeys = []
            let categories = []
            chart['questions'].forEach(q => {
                surveyKeys.push(q.key)
                categories.push(q.questionTitle)
            });
            let surveyQuestions = surveyKeys.map((key, i) => {
                let answerValue = props.companyReportSurvey['survey']['answers'][key]
                let answerText = Object.keys(chart['answerData']).find(option => chart['answerData'][option] === answerValue);
                return <p key={key}><b>{`${categories[i]}: `}</b><span>{answerText}</span></p>
            })
            return (
                <div className='survey-results-answers'>
                    <h3>Your Answers</h3>
                    {surveyQuestions}
                </div>
            )
        }

        if (chart.chartType === 'stackedBarRanking') {
            let surveyKeys = []
            let categories = []
            let testArr = []
            chart['questions'].forEach(q => {
                surveyKeys.push(q.key)
                categories.push(q.questionTitle)
            });

            let surveyQuestions = surveyKeys.map((key, i) => {
                let answerValue = props.companyReportSurvey['survey']['answers'][key]
                let answerText = Object.keys(chart['answerData']).find(option => chart['answerData'][option] === answerValue);
                return <p key={key}><b>{`${categories[i]}: `}</b><span>{answerText}</span></p>
            })
            return (
                <div className='survey-results-answers'>
                    <h3>Your Answers</h3>
                    {surveyQuestions}
                </div>
            )
        }


    }

    const SurveyResultSection = ({ chartData }) => {
        if (chartData.chartType === 'stackedBar' || chartData.chartType === 'stackedBarRanking') {
            let chartOptions = buildStackedBarChartData(chartData);
            return (
                <div className='survey-results-section'>
                    <HighchartsReact highcharts={Highcharts} containerProps={{ style: { width: '70%', minHeight: '100%', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px', boxShadow: '0px 2px 8px 0px #63636333' }, key: chartData.key, id: chartData.key }} options={chartOptions} />
                    {buildSurveyAnswerDisplay(chartData)}
                </div>
            )
        } else if (chartData.chartType === 'text') {
            if (props.companyReportSurvey['survey']['answers'][chartData.key]) {
                return (
                    <div className='survey-results-text-section'>
                        <p><b>{chartData.questionTitle}</b> {props.companyReportSurvey['survey']['answers'][chartData.key]}</p>
                    </div>
                )
            } else {
                return null;
            }
        } else {
            return null
        }

    }

    return (
        <div className='hide-on-print'>
            {props.surveyTaken ?
                <div>
                    <div className='survey-tab-title'>
                        <h2>{props.pageTitle} Survey Results {props.year}</h2>
                        <h3>Number of Responses: {props.surveyResults['survey_taken_count']}</h3>
                    </div>
                    {
                        props.surveyCharts.map((chart) => {
                            if (chart.chartType) {
                                return (<SurveyResultSection key={chart.key} chartData={chart} />)
                            }
                        })
                    }
                </div> :
                <h2 className='take-survey-message text-center'>Please complete the survey for {props.year} to see how you compare to your peers.</h2>
            }
        </div>

    )
}

export default SurveyResponses;