import React from 'react'

const InsufficientReport = props => {

    const locationUrl = location.origin

    const triggerDropzone = () => {
        document.getElementById(`file-upload-${props.id}`).click()
    }

    const mapFileBtn = props.fdu.document ? <a className='green-btn upload-links' href={locationUrl + '/mapping_approvals/new'}>Map<br></br>File</a> : <a className='green-btn' onClick={() => alert("You must connect/upload a Trial Balance before you can access the Mapper.")}>Map<br></br>File</a>

    return (
        <div className='upload-btns'>
            {
                props.userType !== 'Freemium'
                    ? (
                        <button className='green-btn' onClick={triggerDropzone}>
                            Upload<br></br>File
                        </button>
                    )
                    : null
            }
            <a className='green-btn' href={locationUrl + '/connect'}>
                QuickBooks Connect
            </a>
            {mapFileBtn}
        </div>
    )
}

export default InsufficientReport