import React from 'react';

const DashboardNotes = (props) => {
    let defaultDetails = {
        id: Math.random(5).toString(),
        title: 'Notes',
        content: '',
        styles: {
            header: {
                backgroundColor: '#69b144',
                color: '#ffffff',
                textAlign: 'center'
            }
        },
        width: 2,
        height: 2,
        chartType: null
    }

    let noteDetails = props['noteState'] ? props['noteState'].details : defaultDetails;
    let noteTitle = noteDetails['title'] ? noteDetails['title'] : defaultDetails['title']
    let noteContent = noteDetails['content'] ? noteDetails['content'] : defaultDetails['content']

    return (
        <>
            <h3 className='user-dash-item-header' style={props.headerStyle}>{noteTitle}</h3>
            <h3 className='hidden-space' style={props.headerStyle}>{noteTitle}</h3>
            <textarea key='user-dash-notes-content' className='my-notes-dashboard' placeholder='Add Notes!' value={noteContent} onChange={(e) => props.editNotesDetails(e.target.value)} />
        </>
    )
}

export default DashboardNotes;