import React, { useState, useEffect, useContext } from 'react';
import { Data, Filters } from '../contexts';
import MoneyReportSheets from '../report_components/MoneyReportSheets';
import MoneyKFI from '../report_components/MoneyKFI';
import { createMoneyFormat, parseMoney, percentify } from '../../utils';
import { AverageMonthVals, BalanceSheetValues, PnlValues, Sum } from '../monthly/forecast/ForecastHelpers';
import moment from 'moment';
import { toast } from 'react-toastify';

const defaultWPScenario = {
    id: 'wp-default',
    name: 'Default',
    report_type: 'wp-analytics',
    created_by: '',
    updated_by: '',
    updated_at: '',
    version: 1,
    data: {
        overall_expectation: '',
        selected_month: 6,
        dollar_change: {
            integer: 10000,
            text: "$10,000"
        },
        percent_change: {
            integer: 10,
            text: "10%"
        },
        audit_data: [{
            sheet: "balance_sheet",
            key: "cash",
            preliminary_risk_assessment: 'Low',
            explaination_variances: 'Explain!',
            qualitatively_significant: 'Yes',
            cx7form: '',
            comment: 'My comment.'
        }
        ]
    }
}

const defaultExpenseScenario = {
    id: 'expense-default',
    name: 'Default',
    report_type: 'expense-analytics',
    created_by: '',
    updated_by: '',
    updated_at: '',
    version: 1,
    data: {
        dollar_change: {
            integer: 10000,
            text: "$10,000"
        },
        percent_change: {
            integer: 10,
            text: "10%"
        },
        audit_data: [{
            sheet: "pnl",
            key: "total_compensation",
            expectation: 'Just as expected.',
            expectation_met: 'Yes',
            imm: '',
            comments: 'My comment here.'
        }
        ]
    }
}

const currentMonth = new Date().getMonth();
const previousMonth = currentMonth

const AuditReport = React.forwardRef((props, ref) => {
    const { calcs, baseCalc, monthlyForecastCalcs } = useContext(Data);
    const allFilters = useContext(Filters);
    const [activeTab, setActiveTab] = useState('wp-analytics');
    const [auditScenario, setAuditScenario] = useState({
        'wp-analytics': defaultWPScenario,
        'expense-analytics': defaultExpenseScenario
    })
    const [allAuditScenarios, setAllAuditScenarios] = useState({
        'wp-analytics': [defaultWPScenario],
        'expense-analytics': [defaultExpenseScenario]
    })
    const [selectedMonth, setSelectedMonth] = useState(previousMonth || 1)
    const [overallExpectation, setOverallExpectation] = useState('')
    const [dollarChange, setDollarChange] = useState({
        integer: 10000,
        text: "$10,000"
    })
    const [percentChange, setPercentChange] = useState({
        integer: 10,
        text: "10%"
    })

    useEffect(() => {
        const getAllAuditScenarios = async () => {
            try {
                const res = await fetch(`/audit_scenarios`, {
                    method: 'GET',
                    headers: {
                        'X-CSRF-Token': window.token,
                        'Content-Type': 'application/json',
                    }
                })
                const resJson = await res.json()
                if (!res.ok) {
                    throw new Error(resJson['message']);
                } else {
                    setAllAuditScenarios((prevState) => {
                        let allScenarios = resJson['all_scenarios']
                        return {
                            "wp-analytics": [...allScenarios['wp-analytics'], ['wp-default', 'Default']],
                            "expense-analytics": [...allScenarios['expense-analytics'], ['expense-default', 'Default']]
                        }
                    })
                }

            } catch (error) {
                console.log(error, 'error')
            }

        }
        getAllAuditScenarios()
    }, [])

    const formatMonthlyForecast = () => {
        const pnlValueSet = [];
        const balValueSet = [];
        let pnlVals;
        let baseForecast = monthlyForecastCalcs[0]

        if (baseForecast) {
            let copyForecast = _.cloneDeep(monthlyForecastCalcs)
            if (monthlyForecastCalcs[0].scenario_name === 'Straightline') {
                
                let forecastGrowthRate = copyForecast[0].starting_growth_rate !== null ? copyForecast[0].starting_growth_rate : baseCalc.total_revenue_growth_rate;
  
                let forecastStateObj = new AverageMonthVals(copyForecast, forecastGrowthRate, baseCalc)
                
                for (let forecast of copyForecast) {
                    forecast.state = forecastStateObj.forecast
                }
            }

                let balanceSheetBase = new BalanceSheetValues(
                    baseForecast.state.balance_sheet,
                    baseForecast.state.balance_sheet.children.net_worth.operating_expenses_net_income,
                    baseForecast.state.pnl.children.total_other_costs.depreciation_and_amortization
                );

                let originalDepreciation = baseForecast.state.balance_sheet.children.total_fixed_assets.less_accumulated_depreciation

                copyForecast.forEach((forecast) => {
                    pnlVals = new PnlValues(forecast)
                    pnlValueSet.push(pnlVals)
                    const balVals = new BalanceSheetValues(
                        forecast.state.balance_sheet,
                        pnlVals.operating_expenses_net_income,
                        pnlVals.depreciation_and_amortization,
                        balanceSheetBase,
                        forecast.month,
                        originalDepreciation,
                        null,
                        'year1'
                    )
                    balValueSet.push(balVals)
                    balanceSheetBase = balVals;
                })
        }

        const pnlSum = pnlValueSet.length > 0 ? new Sum(pnlValueSet) : {};
        const balSum = balValueSet.length > 0 ? balValueSet[balValueSet.length - 1] : {};
        return { ...pnlSum, ...balSum }
    }

    const formattedForecastVals = formatMonthlyForecast()
    // OKKKKKKKKKK
    const dollarChangeInput = (value) => {
        let negative = value.includes('-')
        let numbersVal = value.replace(/\D+/g, '');
        let finalNumStr = negative ? '-' + numbersVal : numbersVal;
        let integerVal = parseFloat(finalNumStr)
        let formattedVal = createMoneyFormat(finalNumStr)
        setDollarChange(() => {
            return {
                integer: integerVal,
                text: formattedVal
            }
        })
    }
    // OKKKKKKKKKK
    const percentChangeInput = (value) => {
        let negative = value.includes('-')
        let numbersVal = value.replace(/\D+/g, '');
        let finalNumStr = negative ? '-' + numbersVal : numbersVal;
        let integerVal = finalNumStr ? parseFloat(finalNumStr) : 0;
        let formattedVal = percentify(finalNumStr)
        setPercentChange(() => {
            return {
                integer: integerVal,
                text: formattedVal
            }
        })
    }
    // OKKKKKKKKKK
    const handleAuditScenarioSelect = async (e, reportType) => {
        let selectedScenario = allAuditScenarios[reportType].find(s => s[0] == e.target.value)
        if (selectedScenario[0] !== 'default') {
            try {
                const res = await fetch(`/audit_scenarios/${selectedScenario[0]}`, {
                    method: 'GET',
                    headers: {
                        'X-CSRF-Token': window.token,
                        'Content-Type': 'application/json',
                    }
                })
                const resJson = await res.json()
                if (!res.ok) {
                    throw new Error(resJson['message']);
                } else {
                    setAuditScenario((prevState) => {
                        if (resJson['selected_scenario']['report_type'] === 'wp-analytics') {
                            return {
                                ...prevState,
                                'wp-analytics': resJson['selected_scenario']
                            }
                        } else {
                            return {
                                ...prevState,
                                'expense-analytics': resJson['selected_scenario']
                            }
                        }
                    })
                    setAllAuditScenarios((prevState) => {
                        let allScenarios = resJson['all_scenarios']
                        return {
                            "wp-analytics": [...allScenarios['wp-analytics'], ['wp-default', 'Default']],
                            "expense-analytics": [...allScenarios['expense-analytics'], ['expense-default', 'Default']]
                        }
                    })

                    if (resJson['selected_scenario']['report_type'] === 'wp-analytics') {
                        setSelectedMonth(() => {
                            return resJson['selected_scenario']['data']['selected_month']
                        })
                        setOverallExpectation(() => {
                            return resJson['selected_scenario']['data']['overall_expectation'] || ''
                        })
                    }

                    setDollarChange(() => {
                        return resJson['selected_scenario']['data']['dollar_change']
                    })

                    setPercentChange(() => {
                        return resJson['selected_scenario']['data']['percent_change']
                    })
                }

            } catch (error) {
                console.log(error, 'error')
                toast.error('Sorry, could not retrieve scenario. Please try again later.')
            }
        } else {
            setAuditScenario((prevState) => {
                if (reportType === 'wp-analytics') {
                    return {
                        ...prevState,
                        'wp-analytics': defaultWPScenario
                    }
                } else {
                    return {
                        ...prevState,
                        'expense-analytics': defaultExpenseScenario
                    }
                }
            })
        }
    }
    // OKKKKKKKKKK
    const handleNewScenario = async (e, reportType, newName, scenarioData) => {
        e.preventDefault()

        const newAuditScenario = {
            name: newName,
            report_type: reportType,
        }
        if (reportType === 'wp-analytics') {
            newAuditScenario['data'] = {
                overall_expectation: overallExpectation,
                selected_month: selectedMonth,
                dollar_change: dollarChange,
                percent_change: percentChange,
                audit_data: scenarioData['data']['audit_data']
            }
        } else {
            newAuditScenario['data'] = {
                dollar_change: dollarChange,
                percent_change: percentChange,
                audit_data: scenarioData['data']['audit_data']
            }
        }

        try {
            const res = await fetch('/audit_scenarios', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': window.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newAuditScenario)
            })

            const resJson = await res.json();
            if (!res.ok) {
                throw new Error(resJson['message'])
            } else {
                setAuditScenario((prevState) => {
                    if (resJson['selected_scenario']['report_type'] === 'wp-analytics') {
                        return {
                            ...prevState,
                            'wp-analytics': resJson['selected_scenario']
                        }
                    } else {
                        return {
                            ...prevState,
                            'expense-analytics': resJson['selected_scenario']
                        }
                    }
                })

                setAllAuditScenarios((prevState) => {
                    let allScenarios = resJson['all_scenarios']
                    return {
                        "wp-analytics": [...allScenarios['wp-analytics'], ['wp-default', 'Default']],
                        "expense-analytics": [...allScenarios['expense-analytics'], ['expense-default', 'Default']]
                    }
                })
                toast.success('New scenario created!')
            }

        } catch (error) {
            console.log(error, 'error')
            toast.error('Sorry, could not create new scenario. Please try again later.')
        }
    }
    // OKKKKKKKKKK
    const handleUpdateScenario = async (reportType, scenarioData) => {
        let changes = confirm('Are you sure you want to save new changes?');
        if (changes === true && auditScenario.name !== 'Default') {
            const newAuditScenario = {
                name: auditScenario[reportType]['name'],
                report_type: reportType,
            }

            if (reportType === 'wp-analytics') {
                newAuditScenario['data'] = {
                    overall_expectation: overallExpectation,
                    selected_month: selectedMonth,
                    dollar_change: dollarChange,
                    percent_change: percentChange,
                    audit_data: scenarioData['data']['audit_data']
                }
            } else {
                newAuditScenario['data'] = {
                    dollar_change: dollarChange,
                    percent_change: percentChange,
                    audit_data: scenarioData['data']['audit_data']
                }
            }

            try {
                const res = await fetch(`/audit_scenarios/${auditScenario[reportType]['id']}`, {
                    method: 'PATCH',
                    headers: {
                        'X-CSRF-Token': window.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newAuditScenario)
                })

                const resJson = await res.json();
                if (!res.ok) {
                    throw new Error(resJson['message'])
                } else {
                    setAuditScenario((prevState) => {
                        if (resJson['selected_scenario']['report_type'] === 'wp-analytics') {
                            return {
                                ...prevState,
                                'wp-analytics': resJson['selected_scenario']
                            }
                        } else {
                            return {
                                ...prevState,
                                'expense-analytics': resJson['selected_scenario']
                            }
                        }
                    })
                    setAllAuditScenarios((prevState) => {
                        let allScenarios = resJson['all_scenarios']
                        return {
                            "wp-analytics": [...allScenarios['wp-analytics'], ['wp-default', 'Default']],
                            "expense-analytics": [...allScenarios['expense-analytics'], ['expense-default', 'Default']]
                        }
                    })
                    toast.success('Changes saved!')
                }

            } catch (error) {
                console.log(error, 'error')
                toast.error('Sorry, could not save changes. Please try again later.')
            }
        }
    }

    const renderAnnualSheets = () => {
        return (
            <>
                <div className={activeTab === 'wp-analytics' ? 'show-section' : 'hide-section'}>
                    <MoneyReportSheets
                        key='wp-analytics-table'
                        allAuditScenarios={allAuditScenarios['wp-analytics']}
                        auditScenario={auditScenario['wp-analytics']}
                        clientName={props.clientName}
                        commonKey='revenue'
                        companyYTDDate={props.companyYTDDate}
                        defaultScenario={defaultWPScenario}
                        dollarChange={dollarChange}
                        dollarChangeInput={dollarChangeInput}
                        forecastVals={formattedForecastVals}
                        handleNewScenario={handleNewScenario}
                        calendarYearEnd={props.calendarYearEnd}
                        fiscalYearEnd={props.fiscalYearEnd}
                        handleAuditScenarioSelect={handleAuditScenarioSelect}
                        handleUpdateScenario={handleUpdateScenario}
                        n={props.n}
                        overallExpectation={overallExpectation}
                        setOverallExpectation={setOverallExpectation}
                        page={props.page}
                        balance_sheet_rows={props.reportMetrics['balance_sheet']}
                        balTree={props.balTree}
                        percentChange={percentChange}
                        percentChangeInput={percentChangeInput}
                        pnl_rows={props.reportMetrics['pnl']}
                        pnlTree={props.pnlTree}
                        reportType={'wp-analytics'}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        year={props.year}
                        yearRange={props.yearRange}
                    />
                </div>
                <div className='page-break' />
                <div className={activeTab === 'expense-analytics' ? 'show-section' : 'hide-section'}>
                    <MoneyReportSheets
                        key='expense-analytics-table'
                        allAuditScenarios={allAuditScenarios['expense-analytics']}
                        auditScenario={auditScenario['expense-analytics']}
                        clientName={props.clientName}
                        commonKey='revenue'
                        companyYTDDate={props.companyYTDDate}
                        defaultScenario={defaultExpenseScenario}
                        dollarChange={dollarChange}
                        dollarChangeInput={dollarChangeInput}
                        handleNewScenario={handleNewScenario}
                        calendarYearEnd={props.calendarYearEnd}
                        fiscalYearEnd={props.fiscalYearEnd}
                        forecastVals={formattedForecastVals}
                        handleAuditScenarioSelect={handleAuditScenarioSelect}
                        handleUpdateScenario={handleUpdateScenario}
                        n={props.n}
                        page={props.page}
                        percentChange={percentChange}
                        percentChangeInput={percentChangeInput}
                        pnl_rows={props.reportMetrics['pnl']}
                        pnlTree={props.pnlTree}
                        reportType={'expense-analytics'}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        year={props.year}
                        yearRange={props.yearRange}
                    />
                </div>
                <div className='page-break' />
                <div className={activeTab === 'kfis' ? 'show-section' : 'hide-section'}>
                    <MoneyKFI
                        clientName={props.clientName}
                        companyYTDDate={props.companyYTDDate}
                        calendarYearEnd={props.calendarYearEnd}
                        fiscalYearEnd={props.fiscalYearEnd}
                        reportType={'audit-kfis'}
                        rows={props.reportMetrics['kfis']}
                        title={`KFI's`}
                        year={props.year}
                        yearRange={props.yearRange}
                    />
                </div>
                <div className='page-break' />
            </>
        )
    };

    return (
        <div ref={ref} className='money-report-component money-content'>
            <ul id='nav-tabs-money-report' className='report-tab-list' role='tablist' >
                <li id='wp-analytics-tab' className={activeTab === 'wp-analytics' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('wp-analytics')}>
                    WP Analytics
                </li>
                <li id='expense-analytics-tab' className={activeTab === 'expense-analytics' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('expense-analytics')}>
                    Expense Analytics
                </li>
                <li id='kfis-tab' className={activeTab === 'kfis' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('kfis')}>
                    KFI's
                </li>
            </ul>
            {renderAnnualSheets()}
        </div>
    )
})

export default AuditReport;