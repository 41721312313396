import React from 'react';

const WageDataScaffold = (props) => {
  return (
    <div id="wage-data">
      <table className="table table-bordered" id="wageTableHeader" >
        <thead>
          <tr>
            <th>Wages and Salaries</th>
          </tr>
        </thead>
      </table>
      <table className="table table-bordered" id="wageDataTable">
        <thead>
          <tr>
            <th>Occupation</th>
            <th>Average</th>
            <th>10th</th>
            <th>25th</th>
            <th>Median</th>
            <th>75th</th>
            <th>90th</th>
          </tr>
        </thead>
        {props.wageData && props.wageData.length > 0 ?
          (<tbody>
            {props.wageData.map((data, i) => {
              return (
                <tr key={i} className="wageDataRow">
                  <td className="text-right">
                    {data.display_name}
                  </td>
                  <td className="text-right">
                    {data.annual_mean}
                  </td>
                  <td className="text-right">
                    {data.pct_ten}
                  </td>
                  <td className="text-right">
                    {data.pct_twenty_five}
                  </td>
                  <td className="text-right">
                    {data.annual_median}
                  </td>
                  <td className="text-right">
                    {data.pct_seventy_five}
                  </td>
                  <td className="text-right">
                    {data.pct_ninety}
                  </td>
                </tr>
              )
            })}
          </tbody>) : (<tbody><tr><td>No Data Available For Selected Year</td></tr></tbody>)
        }
      </table>
      {props.wageData && props.wageData.length > 0 && <span>¹ This value exceeds $208,000 annually, the upper limit recorded by the U.S. Bureau of Labor Stastics</span>}
    </div>
  )
}

export default WageDataScaffold;
