import React from 'react'

const Alert = (props) => {
  return (
    props.failure
      ? <div className="alert alert-danger" role="alert">
          {'Sorry it appears something went wrong.'}
          <button className='close' data-dismiss='alert'> &times; </button>
        </div>
      : <div className="alert alert-success" role="alert">
          {props.successMessage}
          {props.job === 'ExtractWorker'
            ? <a href='/mapping_approvals/new' className='alert-link'> Click here to Map Your File!</a>
            : null
          }
          <button className='close' data-dismiss='alert'> &times; </button>
        </div>
  );
}

export default Alert;
