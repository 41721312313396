import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer, toast } from 'react-toastify';

class Notifications extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      if (this.props.autoClose) {
        this.props.closeToast();
      }
    }, 10000)
  }

  render() {
    return (
      <div>
        <p>{this.props.message}</p>
      </div>

    )
  }
}

function mountNotifications(target = 'notification-container-target', autoClose = true) {
  let autoCloseToast = autoClose ? 10000 : false
  // const notificationTarget = document.getElementById(target)
  // const root = createRoot(notificationTarget)
  // root.render(
  //   <ToastContainer
  //     autoClose={autoCloseToast}
  //     closeButton={true}
  //     closeOnClick
  //     newestOnTop
  //   />
  // )

  return function (message) {
    toast(<Notifications message={message} />, {
      position: 'top-right',
      autoClose: autoCloseToast,
      draggable: false,
      pauseOnHover: true,
      closeButton: true,
      hideProgressBar: false
    })
  }
}

export default mountNotifications;
